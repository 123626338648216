import { RedeemPromoSuccessResponse, SubscriptionData } from 'model/types';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { DELAY_MS_FOR_CANCEL_SUBSCRIPTION } from '../../model/subscription';
import { api } from '../../services/api';
import * as fromSettingsActions from '../actions/settings.actions';
import * as fromSubscriptionActions from '../actions/subscription.actions';
import { userIsLoggedIn } from '../reducers/selectors/user.selectors';

export function* fetchSubscriptionData(
    action?:
        | ReturnType<typeof fromSubscriptionActions.Actions.fetchSubscriptionDataAfterCancel>
        | ReturnType<typeof fromSubscriptionActions.Actions.fetchSubscriptionData>,
) {
    try {
        if (action?.type === fromSubscriptionActions.ActionTypes.SUBSCRIPTION_FETCH_AFTER_CANCEL) {
            // Add a tiny delay to ensure that the backend is updated (since sometimes if fired immediately,
            // the response isn't correct)
            yield delay(DELAY_MS_FOR_CANCEL_SUBSCRIPTION);
        }

        const subscriptionData: SubscriptionData = yield call(api.getSubscriptionInformation);
        const { autoRenewing } = subscriptionData;

        if (
            action?.type === fromSubscriptionActions.ActionTypes.SUBSCRIPTION_FETCH_AFTER_CANCEL &&
            !autoRenewing
        ) {
            // We have to do our own check for successful cancellation because the Paddle modal (shown
            // in most modern browsers) will only fire the closeCallback, even when the cancellation is
            // successful. As we're already in an external callback, we just do a direct API call here
            // (rather than introduce Redux for this workaround) and show the Farewell modal if needed.
            const { hideFarewellModalAfterCancelling } = action.payload;
            if (hideFarewellModalAfterCancelling !== true) {
                yield put(fromSubscriptionActions.Actions.showFarewellModal());
            }
        }

        yield put(fromSubscriptionActions.Actions.subscriptionFetchSuccess(subscriptionData));
    } catch (e) {
        const message =
            e instanceof Error
                ? e.message
                : 'Error fetching subscription information, please refresh the page.';
        yield put(fromSubscriptionActions.Actions.subscriptionFetchFailure(message));
    }
}

function* changeMarketingSubscription(action: fromSubscriptionActions.Actions) {
    const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

    if (action.type === fromSubscriptionActions.ActionTypes.MARKETING_SUBSCRIBE_REQUEST) {
        try {
            if (isLoggedIn) {
                const newSettings: unknown = yield call(api.saveSettings, { marketingOptIn: true });
                yield put(fromSettingsActions.Actions.importSettings(newSettings));
            }
            yield put(fromSubscriptionActions.Actions.marketingSubscribeSuccess()); // Effectively a no-op
        } catch {
            yield put(fromSubscriptionActions.Actions.marketingSubscribeFailure());
        }
    } else if (action.type === fromSubscriptionActions.ActionTypes.MARKETING_UNSUBSCRIBE_REQUEST) {
        try {
            if (isLoggedIn) {
                const newSettings: unknown = yield call(api.saveSettings, {
                    marketingOptIn: false,
                });
                yield put(fromSettingsActions.Actions.importSettings(newSettings));
            }
            yield put(fromSubscriptionActions.Actions.marketingUnsubscribeSuccess()); // Effectively a no-op
        } catch {
            yield put(fromSubscriptionActions.Actions.marketingUnsubscribeFailure());
        }
    }
}

function* acknowledgeFreeGift() {
    const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

    if (!isLoggedIn) {
        return;
    }

    yield call(api.saveSettings, { freeGiftAcknowledgement: true });
}

function* redeemPromoCode(
    action: ReturnType<typeof fromSubscriptionActions.Actions.redeemPromoRequest>,
) {
    const { promoCode } = action.payload;
    try {
        const { status, data } = yield call(api.redeemPromoCode, promoCode);

        if (status === 200) {
            yield delay(100);
            yield put(fromSubscriptionActions.Actions.redeemPromoSuccess(data));

            // Now that the user has redeemed a promo code, we need to update their subscription data
            yield put(fromSubscriptionActions.Actions.fetchSubscriptionData());

            // If a user is signing up via the Web Player, we don't
            // need to show them the Free Gift welcome experience.
            yield put(fromSubscriptionActions.Actions.acknowledgeFreeGiftExperience());
        } else if (data.errorMessage) {
            yield put(fromSubscriptionActions.Actions.validatePromoCodeError(data.errorMessage));
        } else {
            throw new Error();
        }
    } catch {
        yield put(fromSubscriptionActions.Actions.validatePromoCodeError('Something went wrong'));
    }
}

function* validatePromoCode(
    action: ReturnType<typeof fromSubscriptionActions.Actions.validatePromoCodeRequest>,
) {
    try {
        const response: { status: number; data: RedeemPromoSuccessResponse } = yield call(
            api.validatePromoCode,
            action.payload.promoCode,
        );
        const { status, data } = response;
        if (status === 200) {
            yield put(fromSubscriptionActions.Actions.validatePromoSuccess(data));
        } else {
            yield put(fromSubscriptionActions.Actions.validatePromoCodeExpiredOrInvalid());
        }
    } catch {
        yield put(fromSubscriptionActions.Actions.validatePromoCodeError('Something went wrong'));
    }
}

export function* watchRedeemPromoRequest() {
    yield takeLatest([fromSubscriptionActions.ActionTypes.REDEEM_PROMO_REQUEST], redeemPromoCode);
}

export function* watchValidatePromoCode() {
    yield takeLatest(fromSubscriptionActions.ActionTypes.VALIDATE_PROMO_REQUEST, validatePromoCode);
}

export function* watchFetchSubscriptionData() {
    yield takeLatest(
        [
            fromSubscriptionActions.ActionTypes.SUBSCRIPTION_FETCH_REQUEST,
            fromSubscriptionActions.ActionTypes.SUBSCRIPTION_FETCH_AFTER_CANCEL,
        ],
        fetchSubscriptionData,
    );
}

export function* watchMarketingSubscribe() {
    yield takeLatest(
        fromSubscriptionActions.ActionTypes.MARKETING_SUBSCRIBE_REQUEST,
        changeMarketingSubscription,
    );
}

export function* watchMarketingUnsubscribe() {
    yield takeLatest(
        fromSubscriptionActions.ActionTypes.MARKETING_UNSUBSCRIBE_REQUEST,
        changeMarketingSubscription,
    );
}

export function* watchFreeGiftExperienceAcknowledge() {
    yield takeLatest(
        fromSubscriptionActions.ActionTypes.FREE_GIFT_EXPERIENCE_ACKNOWLEDGE,
        acknowledgeFreeGift,
    );
}
