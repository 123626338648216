import useTracks from 'hooks/useTracks';
import { TracksProperties } from 'model/types';
import { useEffect, useRef } from 'react';
import { DEPLOYMENT_ENV } from 'settings';

type Props = {
    event: string;
    properties?: TracksProperties;
};

/**
 * This is a non-visual component that can be placed in another component. When it mounts the given event will be recorded.
 */
const TrackOnMount = ({ event, properties }: Props) => {
    const { recordEvent } = useTracks();

    // On the first mount only, record the event
    useEffect(() => {
        recordEvent(event, properties);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const eventRef = useRef(event);

    // If the event prop changes, that's probably a mistake because it will not be re-recorded. Warn the developer in the console.
    useEffect(() => {
        // Only show the warning in development environments
        if (!DEPLOYMENT_ENV || !['development'].includes(DEPLOYMENT_ENV)) {
            return;
        }
        if (eventRef.current && eventRef.current !== event) {
            console.error(
                `The event prop sent to <TrackOnMount> has changed from ${eventRef.current} to ${event}. ` +
                    'This property should not change. If you need to track a new mount event, use the event ' +
                    'as the key: <TrackOnMount event={variableEvent} key={variableEvent} />.',
            );
        }
        eventRef.current = event;
    }, [event]);

    return null;
};

export default TrackOnMount;
