import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isMacApp } from '../../../helper/Browser';
import useFormatMessage from '../../../hooks/useFormatMessage';
import useTracks from '../../../hooks/useTracks';
import {
    AndroidDownloadIcon,
    DesktopDownloadIcon,
    IosDownloadIcon,
    SettingsAppsWrapper,
    SettingsOption,
    SettingsTitle,
} from './SettingsApps.styled';

const SettingsApps = () => {
    const { recordEvent } = useTracks();
    const formatMessage = useFormatMessage();

    return (
        <SettingsAppsWrapper>
            <h1>
                <FormattedMessage id="apps" />
            </h1>
            <SettingsOption>
                <SettingsTitle>iOS</SettingsTitle>
                <IosDownloadIcon
                    onClick={() => recordEvent('settings_apps_ios_download_clicked')}
                    href="https://itunes.apple.com/us/app/pocket-casts/id414834813"
                    target="_blank"
                    rel="noopener noreferrer"
                />
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle $android>Android</SettingsTitle>
                <AndroidDownloadIcon
                    onClick={() => recordEvent('settings_apps_android_download_clicked')}
                    href="https://play.google.com/store/apps/details?id=au.com.shiftyjelly.pocketcasts"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt={formatMessage('google-play-badge-alt')}
                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                    />
                </AndroidDownloadIcon>
            </SettingsOption>
            <SettingsOption>
                <SettingsTitle>Windows</SettingsTitle>
                <DesktopDownloadIcon
                    onClick={() => recordEvent('settings_apps_windows_download_clicked')}
                    href="https://pocketcasts.com/get/windows"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="download" />
                </DesktopDownloadIcon>
            </SettingsOption>
            {!isMacApp() && (
                <SettingsOption>
                    <SettingsTitle>Mac</SettingsTitle>
                    <DesktopDownloadIcon
                        onClick={() => recordEvent('settings_apps_mac_download_clicked')}
                        href="https://pocketcasts.com/get/mac"
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage id="download" />
                    </DesktopDownloadIcon>
                </SettingsOption>
            )}
        </SettingsAppsWrapper>
    );
};

export default SettingsApps;
