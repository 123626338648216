import { Episode, EpisodeSyncInfo } from 'model/types';
import { PlayingStatus } from './PlayingStatus';
import * as StringHelper from './StringHelper';

export function isPlayed(episode: Record<string, unknown>) {
    return episode && episode.playingStatus === PlayingStatus.COMPLETED;
}

interface EpisodeContentType {
    fileType?: string;
    contentType?: string;
    file_type?: string;
    content_type?: string;
}

// TODO: FIXME
// It seems that some of the episode models handle the file type and content type using camelcase
// and others use underscores. It would be wise to address this on both client/server
// since it will cause unforeseen bugs
export function isVideo(episode: EpisodeContentType) {
    return (
        episode?.fileType?.indexOf('video/') === 0 ||
        episode?.file_type?.indexOf('video/') === 0 ||
        episode?.contentType?.indexOf('video/') === 0 ||
        episode?.content_type?.indexOf('video/') === 0
    );
}

export function getContentType(episode: EpisodeContentType) {
    return isVideo(episode) ? 'video' : 'audio';
}

export function isArchived(episode: Partial<EpisodeSyncInfo>) {
    return episode && episode.isDeleted;
}

function sortByTitle(episodes: Episode[], asc: boolean) {
    episodes.sort((episodeOne, episodeTwo) =>
        StringHelper.compareCleaned(episodeOne.title, episodeTwo.title, asc),
    );
}

function sortByPublished(episodes: Episode[], asc: boolean) {
    episodes.sort((episodeOne, episodeTwo) =>
        StringHelper.compareDates(episodeOne.published, episodeTwo.published, asc),
    );
}

function sortByDuration(episodes: Episode[], asc: boolean) {
    episodes.sort((episodeOne, episodeTwo) => {
        const cleanedOne = episodeOne.duration === null ? 0 : episodeOne.duration;
        const cleanedTwo = episodeTwo.duration === null ? 0 : episodeTwo.duration;
        return cleanedOne < cleanedTwo
            ? asc
                ? 1
                : -1
            : cleanedOne > cleanedTwo
              ? asc
                  ? -1
                  : 1
              : 0;
    });
}

// 0: Title A -> Z
// 1: Title Z -> A
// 2: Date, oldest to newest
// 3: Date, newest to oldest
// 4: Episode duration descending (longest to shortest)
// 5: Episode duration ascending (shortest to longest)
export function sort(episodes: Episode[], order: number) {
    if (order === 0) {
        sortByTitle(episodes, true);
    } else if (order === 1) {
        sortByTitle(episodes, false);
    } else if (order === 2) {
        sortByPublished(episodes, false);
    } else if (order === 3) {
        sortByPublished(episodes, true);
    } else if (order === 4) {
        sortByDuration(episodes, true);
    } else if (order === 5) {
        sortByDuration(episodes, false);
    }
    return episodes;
}

export function getDefaultEpisodeSync() {
    return {
        playedUpTo: 0,
        playingStatus: PlayingStatus.NOT_PLAYED,
        starred: false,
        isDeleted: false,
        duration: 0,
    };
}

export function cleanEpisodeSync(episode: Partial<EpisodeSyncInfo>) {
    /* eslint-disable no-prototype-builtins */

    episode.playedUpTo = episode && episode.hasOwnProperty('playedUpTo') ? episode.playedUpTo : 0;
    episode.playingStatus =
        episode && episode.hasOwnProperty('playingStatus')
            ? episode.playingStatus
            : PlayingStatus.NOT_PLAYED;
    episode.starred = episode && episode.hasOwnProperty('starred') ? episode.starred : false;

    return episode;
}

export function cleanEpisode(episode: Episode) {
    const { url } = episode;

    // remove invalid characters
    if (url) {
        episode.url = url.replace(/ /g, '%20');
    }

    // Turn string into a number (Files API returns a string in this field)
    episode.duration = Number(episode.duration) || 0;

    return episode;
}
