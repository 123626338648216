import styled, { css } from 'styled-components';

export const StyledNavigationButton = styled.button<{
    direction: 'left' | 'right';
    disabled?: boolean;
}>`
    &:disabled {
        cursor: default;
    }
    color: ${props =>
        props.disabled
            ? props.theme.tokens['primary-icon-02-disabled']
            : props.theme.tokens['primary-icon-02-active']};

    ${props =>
        props.direction === 'left' &&
        css`
            svg {
                transform: rotate(180deg);
            }
        `}
    &:hover {
        color: ${props => (props.disabled ? '' : props.theme.tokens['primary-interactive-01'])};
    }

    svg {
        pointer-events: none;
    }
`;
