import React, {
    Children,
    ReactNode,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

type Props = {
    minWidth: number;
    onColumnCountCalc: (count: number) => void;
    gap?: number | string;
    children: ReactNode;
};

const ResponsiveGrid: React.FC<Props> = ({ minWidth, onColumnCountCalc, gap = 8, children }) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [columnCount, setColumnCount] = useState(-1);

    const handleResize = useCallback(() => {
        if (!gridRef.current) {
            return;
        }
        const newGridWidth = gridRef.current.offsetWidth;
        const newColumnCount = Math.max(1, Math.floor(newGridWidth / minWidth));

        if (newColumnCount !== columnCount) {
            onColumnCountCalc(newColumnCount);
            setColumnCount(newColumnCount);
        }
    }, [columnCount, minWidth, onColumnCountCalc]);

    useLayoutEffect(() => {
        handleResize(); // Initial calculation
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [minWidth, onColumnCountCalc, columnCount, handleResize]);

    const rowCount = columnCount > 0 ? Math.ceil(Children.count(children) / columnCount) : 0;

    const style = {
        gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
        gridTemplateRows: `repeat(${rowCount}, auto)`,
        gap,
    };

    return (
        <div className="responsive-grid" style={style} ref={gridRef}>
            {columnCount > 0 && children}
        </div>
    );
};

export { ResponsiveGrid };
