import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { MOBILE_WIDTH } from '../../../model/page';
import {
    A11Y,
    BC80,
    coolGrey60,
    gridSize,
    orange,
    popoverMixin,
    red,
    Typography,
} from '../../../styles';

export const BackLink = styled.button`
    ${Typography.Desktop.H60}
    display: flex;
    align-items: center;
    gap: ${gridSize}px;
    padding: ${gridSize}px ${2 * gridSize}px;
    width: 100%;
    color: ${coolGrey60};
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.legacyScss['theme-background-hover']};
    }

    svg {
        transform: rotate(180deg);
    }
`;

export const ProfileDroplist = styled.div<{ className?: string }>`
    ${popoverMixin}

    position: absolute;
    width: ${44 * gridSize}px;

    max-height: calc(100vh - 65px);
    overflow-y: auto;

    right: -8px;
    top: calc(100% + 8px);

    padding-top: ${gridSize}px;
    padding-bottom: ${gridSize}px;
    z-index: 3;
`;

const menuItemMixin = css`
    ${Typography.Desktop.H60}
    line-height: 1;
    display: flex;
    padding: ${2 * gridSize}px 0;
    width: 100%;

    align-items: center;
    text-align: left;

    &:hover {
        background-color: ${props => props.theme.legacyScss['theme-background-hover']};
    }

    &:focus-visible {
        ${A11Y.outlineInset}
    }

    @media (max-height: 600px) {
        ${Typography.Desktop.H65}
        line-height: 1;
        padding: ${1 * gridSize}px 0;
    }
`;

export const ProfileMenuItemLink = styled(Link)`
    ${menuItemMixin}

    text-decoration: none;
    &,
    &:visited,
    &:active,
    &:hover {
        color: inherit;
    }
`;

export const ProfileMenuItemButton = styled.button`
    ${menuItemMixin}
`;

export const ThemeMenuItemTopWrapper = styled.div`
    ${Typography.Desktop.H60}
    line-height: 1;
    display: flex;
    padding: ${1.5 * gridSize}px 0;
    width: 100%;
    padding-bottom: 5px;
    align-items: center;

    @media (max-height: 600px) {
        ${Typography.Desktop.H65}
        line-height: 1;
        padding: ${1 * gridSize}px 0;
    }
`;

export const ThemeMenuItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    padding-bottom: 8px;
`;

export const ProfileMenuItemImage = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${2 * gridSize}px;
    color: ${props => props.theme.tokens['primary-icon-02']};
`;

export const ProfileMenuItemText = styled.div`
    display: flex;
    align-items: baseline;
    height: 0.9em;
`;

export const FilesMenuItemSubTextWrapper = styled.div`
    ${Typography.Desktop['H80-B']}
    margin-left: ${2 * gridSize}px;
    display: flex;
    color: ${coolGrey60};
    transition: visibility 1s linear;
`;

export const FilesFullText = styled.span<{ $percentage: number }>`
    color: ${props =>
        props.$percentage < 90 ? coolGrey60 : props.$percentage < 99 ? orange : red};
`;

export const DecoratorWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${2 * gridSize}px;
`;

export const Separator = styled.div<{ className?: string }>`
    padding-top: ${gridSize}px;
    margin-bottom: ${gridSize}px;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
`;

export const ProfileMenuItemImageLarge = styled.div`
    display: flex;

    align-items: center;
    padding: 0 ${2 * gridSize}px;
    margin: ${-0.5 * gridSize}px 0;

    @media (max-height: 600px) {
        width: 70px;
    }
`;

export const ProfileItemTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ProfileSubtitleText = styled(BC80)`
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
`;

export const ProfileSubtitleWarn = styled(BC80)`
    color: ${red};
`;

export const ProfileChevronWrapper = styled.div`
    width: ${3 * gridSize}px;
    height: ${3 * gridSize}px;

    color: ${props => props.theme.legacyScss['theme-interactive-secondary']};
    margin-left: 16px;
    svg {
        transform: rotate(-90deg);
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const EmailText = styled.div`
    ${Typography.Desktop.H60}
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-height: 600px) {
        ${Typography.Desktop.H65}
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        max-width: 100%;
    }
`;
