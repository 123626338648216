// This file is for top-level styles requiring no other dependencies.
// See also /model/theme.js, /scss/config.scss.
import { css } from 'styled-components';

export const gridSize = 8;

export const userSelectNoneMixin = css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const preventFlexboxOverflow = css`
    // If a flex parent has children that are growing too large and overflowing, this weird trick
    // often helps. This is especially true if you're trying to force long text to truncate.
    // See: https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
    min-width: 0;
`;

export const visuallyHidden = css`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export * from './global-styles';

export * from './a11y';

export * from './typography';

export {
    black,
    blue,
    blue10,
    blue20,
    blue30,
    blue40,
    blue50,
    blue60,
    blue70,
    blue80,
    blue90,
    coolGrey,
    coolGrey10,
    coolGrey20,
    coolGrey30,
    coolGrey40,
    coolGrey50,
    coolGrey60,
    coolGrey70,
    coolGrey80,
    coolGrey90,
    darkGrey,
    darkGrey10,
    darkGrey20,
    darkGrey30,
    darkGrey40,
    darkGrey50,
    darkGrey60,
    darkGrey70,
    darkGrey80,
    darkGrey90,
    green,
    green10,
    green20,
    green30,
    green40,
    green50,
    green60,
    green70,
    green80,
    green90,
    orange,
    orange10,
    orange20,
    orange30,
    orange40,
    orange50,
    orange60,
    orange70,
    orange80,
    orange90,
    pink,
    pink10,
    pink20,
    pink30,
    pink40,
    pink50,
    pink60,
    pink70,
    pink80,
    pink90,
    purple,
    purple10,
    purple20,
    purple30,
    purple40,
    purple50,
    purple60,
    purple70,
    purple80,
    purple90,
    red,
    red10,
    red20,
    red30,
    red40,
    red50,
    red60,
    red70,
    red80,
    red90,
    warmGrey,
    warmGrey10,
    white,
    yellow,
    yellow10,
    yellow20,
    yellow30,
    yellow40,
    yellow50,
    yellow60,
    yellow70,
    yellow80,
    yellow90,
} from './colors';

export { blueTextLinkMixin } from './text-link';
export { hoverStateTransitionMixin } from './transitions';

const lightPopover = css`
    background-color: #fff;
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 0px 1px 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 2px,
        rgba(26, 4, 0, 0.12) 0px 3px 6px,
        rgba(48, 59, 64, 0.07) 0px 6px 9px;
`;
const darkPopover = css`
    background-color: #1e1e1f;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
`;

export const popoverMixin = css`
    ${props => (props.theme.name === 'light' ? lightPopover : darkPopover)}

    border-radius: 4px;
`;

export const plusGradient = 'linear-gradient(94.94deg, #FED745 8.22%, #FEB525 92.09%)';

export * from './transitions';
