import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Action, ActionType } from './actions';
import { RootState } from './reducers';

/**
 * action(...) is a simple helper function to ensure that Redux actions all have the correct shape,
 * with a `type` property and an optional `payload` property. Use it in Action Creators for consistency.
 *
 * Without payload:
 *      action('ACTION_TYPE')
 *          => returns { type: 'ACTION_TYPE' }
 *
 * With payload:
 *      action('ACTION_TYPE', { foo: 'bar' })
 *          => returns { type: 'ACTION_TYPE', payload: { foo: 'bar' } }
 */
export function action<T extends ActionType>(type: T): { type: T };
export function action<T extends ActionType, P>(type: T, payload: P): { type: T; payload: P };
export function action<T extends ActionType, P>(type: T, payload?: P) {
    return {
        type,
        payload,
    };
}

/**
 * registeredMiddleware is a manifest of all the middleware functions in the app, to be
 * included when the Redux store is created.
 */
export const registeredMiddleware: Middleware<unknown, RootState>[] = [];

/**
 * registerMiddleware(...) is a helper to create middleware functions. When you use it, your middleware is
 * automatically added to the registeredMiddleware manifest.
 *
 * You can register middleware that watches a single action:
 *      registerMiddleware('ACTION_TYPE', (action, store) => { ... });
 *
 * Or middleware that watches multiple actions:
 *      registerMiddleware(['ACTION_1', 'ACTION_2'], (action, store) => { ... });
 *
 * In both cases, the `action` your function receives will automatically be typed to an action object that
 * matches the watched action types.
 *
 * As a convenience (and to maintain consistency with Redux Saga), next(action) is automatically called
 * before your middleware is run — so your function doesn't receive next(). In rare cases where the order
 * of next(action) is important, you can add middleware without this helper (see: local-storage.middleware.ts).
 */
export const registerMiddleware = <
    T extends ActionType,
    RefinedAction extends Extract<Action, { type: T }>,
>(
    type: T | T[],
    callback: (action: RefinedAction, store: MiddlewareAPI<Dispatch<Action>, RootState>) => void,
) => {
    registeredMiddleware.push(store => next => action => {
        // To make the transition from Sagas simpler, we'll also run the middleware after the dispatch
        // has otherwise complete. That way we can port code over without restructuring it. To do this,
        // we call next(action), then execute the middleware function (if it matches an expected type).
        const result = next(action);

        // Run the middleware callback if this is an action we care about.
        const types = Array.isArray(type) ? type : [type];
        if (types.some(t => t === (action as RefinedAction).type)) {
            return callback(action as RefinedAction, store);
        }

        // Return the result of next(action)
        return result;
    });
};
