import { MARKETING_URL } from '../settings';

export const POCKET_CASTS_PLUS = `${MARKETING_URL}/plus`;

export const POCKET_CASTS_PODCAST_PLAYER = `${MARKETING_URL}/podcast-player/`;

export const POCKET_CASTS_SUPPORT = 'https://support.pocketcasts.com';

export const POCKET_CASTS_PRIVACY_POLICY = 'https://support.pocketcasts.com/article/privacy-policy';

export const POCKET_CASTS_TERMS_OF_USE = 'https://support.pocketcasts.com/article/terms-of-use';

export const POCKET_CASTS_SUPPORT_EMAIL = 'support@pocketcasts.com';
export const MAIL_TO_POCKET_CASTS_SUPPORT = 'mailto:support@pocketcasts.com';

export const POCKET_CASTS_PLUS_SUBSCRIPTION_INFO =
    'https://support.pocketcasts.com/article/subscription-info';

export const POCKET_CASTS_PLUS_LAUNCH_BLOG = 'https://blog.pocketcasts.com/major-new-update';

export const WEB_PLAYER_PLAYBACK_ERRORS =
    'https://support.pocketcasts.com/article/web-player-playback-error';

export const DESKTOP_PLAYBACK_ERRORS =
    'https://support.pocketcasts.com/article/web-player-playback-error#why-wont-the-episode-play-on-the-desktop-app';
