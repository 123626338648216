import styled, { css } from 'styled-components';
import { gridSize } from '../../styles';
import { PreviewRow } from './ListEpisodes.styled';
import { PrimaryLine } from './columns/EpisodeInfo';

interface UpNextRowProps {
    $isDragging?: boolean;
    $isSortable?: boolean;
    disabled?: boolean;
}

/**
 * Layout constants for drag behavior
 * ---
 * Rows are constructed to have an illusion of a full-width border,
 * until drag begins.
 *
 * We do this by moving the border to a pseudo-element,
 * and then rendering the row wider than the border appears.
 *
 * This allows the row to glow on drag with padding around the row,
 * and a solid background color, without collapsing on to the row elements.
 *
 * In short
 * 1 - Move entire row to the left
 * 2 - Create padding
 * 3 - Move the pseudo-element an additional 16px to keep it visually aligned.
 */

const dragPos = {
    // Normal state
    normal: {
        padding: '16px',
        width: 'calc(100% + 16px)',
        offset: '-16px',
    },
    // Drag state
    drag: {
        /**
         * It seems that drag overlay calculates the width based on the normal state width,
         * I'm not sure why, maybe because of padding (or offset) - but
         * we manage to lose 16px width in the process,
         * so to match our previous width, we need to add 16px.
         */
        width: 'calc(100% + 8px)',
        // This will re-balance the element to center once again,
        left: '-8px',
    },
} as const;

export const UpNextRow = styled(PreviewRow)<UpNextRowProps>`
    grid-template-columns: 46px minmax(320px, 1fr) 104px 114px 34px 34px 28px;
    width: ${dragPos.normal.width};
    gap: ${gridSize * 2}px;
    height: 80px;
    transition: transform 200ms ease;
    border: 0;
    padding: 0 ${dragPos.normal.padding};
    position: relative;
    left: ${dragPos.normal.offset};
    border-radius: 4px;
    color: ${props => props.theme.tokens['contrast-03']};

    // Prevents text selection when attempting to drag
    // while the drag timeout hasn't yet cleared.
    user-select: none;

    &:after {
        content: '';
        height: 0px;
        position: absolute;
        left: ${dragPos.normal.padding};
        right: ${dragPos.normal.padding};
        width: calc(100% - ${dragPos.normal.padding} * 2);
        bottom: -2px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
        pointer-events: none;
    }
    ${props =>
        props.$isDragging &&
        css`
            cursor: grabbing;
            width: ${dragPos.drag.width};
            left: ${dragPos.drag.left};
            background: ${props => props.theme.tokens['primary-ui-02-active']};
            box-shadow:
                0px 2px 8px rgba(255, 255, 255, 0.06),
                0px 0px 1px rgba(255, 255, 255, 0.55),
                0px 4px 16px rgba(255, 255, 255, 0.04),
                // Cover up the top border with a shadow ↓ without moving the row
                0px -3px 0px ${props => props.theme.tokens['primary-ui-04']};

            ${PrimaryLine} {
                color: ${props => props.theme.tokens['primary-interactive-01']};
            }
            &:after {
                display: none;
            }
        `}
`;

export const RemoveButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    color: ${props => props.theme.tokens['player-contrast-03']};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.tokens['contrast-02']};
    }
    ${UpNextRow}:not(:hover) & {
        visibility: hidden;
    }
`;

export const RowHandleWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.tokens['player-contrast-04']};
    position: absolute;
    right: -24px;
    // > If your draggable item is part of a scrollable list, we recommend you use
    // > a drag handle and set touch-action to none only for the drag handle, so that
    // > the contents of the list can still be scrolled, but that initiating a drag from
    // > the drag handle does not scroll the page.
    // > – https://docs.dndkit.com/api-documentation/sensors/pointer#touch-action
    touch-action: none;
    svg {
        cursor: row-resize;
    }
`;
