import styled from 'styled-components';
import { isMacApp } from '../../../helper/Browser';
import { FeatureLockTrigger } from '../../FeatureLock';

export const StyledActionButton = styled.button<{ hidden?: boolean; color?: string }>`
    width: 24px;
    height: 24px;
    ${props => props.hidden && 'visibility: hidden;'}
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.tokens['action-01']};

    ${!isMacApp() && 'transition: opacity 0.2s ease-in-out;'}

    &:hover, ${FeatureLockTrigger}:hover & {
        color: ${props => props.theme.tokens['action-01-active']};
    }

    svg {
        color: ${props => props.color};
    }
`;
