import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getIntl } from '../redux/reducers/selectors';

const IntlProvider = (props: React.ComponentProps<typeof ReactIntlProvider>) => {
    const intl = useSelector(getIntl);
    return <ReactIntlProvider key={intl.locale} {...intl} {...props} />;
};

export default IntlProvider;
