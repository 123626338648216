// Creates a pre-typed Redux hooks, so components don't need to import their own typing
// See: https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
import {
    TypedUseSelectorHook,
    useDispatch as reduxUseDispatch,
    useSelector as reduxUseSelector,
} from 'react-redux';
import { RootState } from '../redux/reducers';
import { AppDispatch } from '../store';

export const useDispatch = reduxUseDispatch.withTypes<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;
