import { AutoplayConfig, PodcastListPodcast, UpNextEpisode } from 'model/types';
import { Episode, EpisodeSyncInfo } from 'model/types/episode.types';
import React from 'react';
import { EpisodeEventSource } from '../../redux/actions/podcast.actions';
import ListEpisodes from './ListEpisodes';
import ListPodcastEpisodes from './ListPodcastEpisodes';
import { NoEpisodesMessage } from './ListEpisodes.styled';

/**
 * Accept `any`, but kind of pretend it's
 * this other type <T>.
 *
 * Remove `any` to see type errors and fix them.
 */
export type LegacyTypeTodo<ClosestType = any> = ClosestType & any;

export interface EpisodesTableProps {
    color?: string;
    emptyMessage?: string | React.ReactNode;
    episodes: LegacyTypeTodo<Episode>[];
    uuidToEpisodeSync?: Record<string, EpisodeSyncInfo>;
    podcast?: LegacyTypeTodo<PodcastListPodcast>;
    playerEpisodeUuid?: string;
    playerPlayedUpTo?: number;
    isPlaying: boolean;
    upNextEpisodes: Record<string, UpNextEpisode>;
    sortEnabled?: boolean;
    showSort?: boolean;
    inHistory?: boolean;
    sortOrder?: number;
    showSyncActions?: boolean;
    autoplay?: AutoplayConfig;
    eventSource: NonNullable<EpisodeEventSource>;
    onPodcastClick?: (params: { uuid: string }) => void;
    onSort?: (orderId: number) => void;
    onEpisodeClick: (episode: LegacyTypeTodo<Episode>) => void;
}

export const EpisodesTable: React.FC<EpisodesTableProps> = (props: EpisodesTableProps) => {
    if (!props.episodes || props.episodes.length === 0) {
        return <NoEpisodesMessage>{props.emptyMessage}</NoEpisodesMessage>;
    }
    if (props.podcast) {
        return <ListPodcastEpisodes {...props} />;
    }
    return <ListEpisodes {...props} />;
};

export default EpisodesTable;
