import styled from 'styled-components';
import { gridSize } from '../styles';

const HorizontalDivider = styled.hr`
    border: none;
    height: 1px;
    width: 100%;
    margin: ${3 * gridSize}px 0;
    background-color: ${props => props.theme.tokens['primary-ui-05']};
`;

export default HorizontalDivider;
