import styled from 'styled-components';
import { PopupChoiceItemBase, PopupWindowBase } from '../../../../styles/popups';

export const MenuPopupWindow = styled(PopupWindowBase)``;

export const PopupChoiceItem = styled(PopupChoiceItemBase)<{
    $itemIndex?: number;
    $checkboxMenuItem?: boolean;
}>`
    min-width: 100px;
    border: none;

    display: flex;
    align-items: center;

    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};

    svg {
        color: ${props => props.theme.tokens['primary-interactive-01']};
        margin: 0.75em 0.75em 0.9em -0.25em;
    }
`;
