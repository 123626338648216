import styled, { css } from 'styled-components';
import { Typography, black } from 'styles';
import { plusColor } from 'styles/colors';

export const InvitePageWrapper = styled.div`
    h1 {
        margin-top: 20px;
    }
`;

export const GuestPassWrapper = styled.div`
    width: 80%;
    min-width: fit-content;
    position: relative;
    top: 20px;
    border-radius: 8px;
    border: 0.66px solid ${props => props.theme.tokens['secondary-ui-02']};
    box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.25);
    ${props =>
        props.theme.name === 'dark' &&
        css`
            box-shadow: 0px 1px 2.5px 0px rgba(255, 255, 255, 0.15);
        `}
    padding: 40px 40px 0px 40px;
    display: flex;
    flex-basis: fit-content;
    flex-direction: row;

    @media only screen and (max-width: 1025px) {
        flex-direction: column;
    }
`;

export const GuestPassTitle = styled.h2`
    ${Typography.Desktop.H40};
    color: ${props => props.theme.tokens['primary-text-01']};
    text-transform: capitalize;
`;

export const GuestPassDescription = styled.p`
    ${Typography.Desktop.H65};
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-top: 4px;
`;

export const GuestPassImageWrapper = styled.div`
    margin-left: auto;
    margin-top: -8px;

    span {
        position: relative;
        top: -65px;
        left: 32px;
        width: 100%;
        text-transform: capitalize;
        white-space: nowrap;
        color: #fff;

        ${Typography.Desktop['H80-B']};
    }

    @media only screen and (max-width: 1025px) {
        margin-left: -20px;
        margin-top: 20px;

        span {
            top: -45px;
            left: -260px;
        }
    }
`;

export const ShareButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 66px;
`;

export const Actions = styled.div`
    display: flex;

    button,
    a {
        margin-right: 8px;
    }
`;

export const CopyLinkButton = styled.button`
    background: ${plusColor};
    color: ${black};
    ${Typography.Desktop['H70-B']};
    height: 48px;
    padding: 15px;
    border-radius: 58px;
    max-width: 120px;
    min-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        background: ${props => props.theme.tokens['primary-field-02']};
        color: ${props => props.theme.tokens['primary-text-02']};
        fill: ${props => props.theme.tokens['primary-text-02']};
        cursor: not-allowed;
        pointer-events: none;
    }
`;

export const LinkInputWrapper = styled.div`
    input {
        width: 265px;
        height: 48px;
        border-radius: 4px;
        outline: 0;
        padding: 8px;
        margin-right: 16px;
        color: ${props => props.theme.tokens['primary-text-02']};
        border: 1px solid ${props => props.theme.tokens['primary-field-03']};
        background-color: ${props => props.theme.tokens['primary-field-02']};
    }
`;

export const SocialButtons = styled.div`
    width: 168px;
    display: flex;

    .email {
        min-width: 48px;
        height: 48px;
        border-radius: 60px;
        background: ${plusColor};
        color: ${black};
        fill: ${black};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
            opacity: 0.8;
        }

        &[aria-disabled='true'] {
            background: ${props => props.theme.tokens['primary-field-02']};
            color: ${props => props.theme.tokens['primary-text-02']};
            fill: ${props => props.theme.tokens['primary-text-02']};
            cursor: not-allowed;
            pointer-events: none;
        }
    }
`;

export const ShareWrapper = styled.div`
    width: 90%;
`;
