import { connect } from 'react-redux';
import * as fromFlagsActions from '../../redux/actions/flags.actions';
import { AppDispatch } from '../../store';
import InvitePage from './InvitePage';

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    addFlag: (message: string) => dispatch(fromFlagsActions.Actions.addFlag(message)),
});

const InvitePageConnected = connect(mapStateToProps, mapDispatchToProps)(InvitePage);
export { InvitePageConnected as InvitePage };
