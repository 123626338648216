import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTracks from '../../hooks/useTracks';
import { Button } from '../Button';
import { PlanBadge } from '../PlanBadge';
import { SubscribeToPlanButton } from '../SubscribeToPlanButton';
import { TrackOnMount } from '../Tracks';
import SubscribeMessage from './SubscribeMessage';

const Wrapper = styled.div`
    display: flex;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.tokens['primary-ui-03']};
    border-radius: 10px;

    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 0;
    }
`;

const Plans = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 8px;
`;

const Subscribe = styled(SubscribeToPlanButton)`
    padding: 0 32px;
    white-space: nowrap;
`;

const Dismiss = styled(Button)`
    padding: 0 32px;
    font-size: 14px;
    font-weight: 500;
`;

type SubscribeBannerProps = {
    onDismiss: () => void;
    onSubscribe: () => void;
};

const SubscribeBanner = ({ onDismiss, onSubscribe }: SubscribeBannerProps) => {
    const { recordEvent, setDefaultEventSource, clearDefaultEventSource } = useTracks();

    const dismissClicked = () => {
        clearDefaultEventSource();
        recordEvent('subscribe_banner_dismissed');
        onDismiss();
    };

    const tryClicked = () => {
        setDefaultEventSource('subscribe_banner');
        recordEvent('subscribe_banner_try_clicked');
        onSubscribe();
    };

    return (
        <Wrapper>
            <TrackOnMount event="subscribe_banner_shown" />
            <Plans>
                <PlanBadge tier="Plus" />
                <SubscribeMessage />
            </Plans>
            <Buttons>
                <Dismiss onClick={dismissClicked} kind="text">
                    <FormattedMessage id="dismiss" />
                </Dismiss>
                <Subscribe onClick={tryClicked} tier="Plus">
                    <FormattedMessage id="try-plus" />
                </Subscribe>
            </Buttons>
        </Wrapper>
    );
};

export default SubscribeBanner;
