import classNames from 'classnames';
import { Tooltip } from 'components/Tooltip';
import React from 'react';
import { TimeText } from '../../../../components';
import { ResizeObserver } from '../../../../components/ResizeObserver';
import * as ColorHelper from '../../../../helper/ColorHelper';
import * as UiHelper from '../../../../helper/UiHelper';
import { EpisodeChapter } from '../../../../model/types';
import { SeekBarWrapper } from './SeekBar.styled';

type Props = {
    playedUpTo: number;
    duration: number;
    chapters: EpisodeChapter[];
    color: string;
    onUserSeeking: (time: number) => void;
    onUserSeeked: (time: number) => void;
    buffering: boolean;
    disabled?: boolean;
    className?: string;
};

type StateProps = {
    trackWidth: number;
    progress: string;
    userSeeking: boolean;
    trackPosition: number;
    chaptersPositions: number[];
    playedUpTo: number;
    timeRemaining: number;
    provisionalPlayedUpTo?: number;
    provisionalTimeRemaining?: number;
};

class SeekBar extends React.Component<Props, StateProps> {
    static defaultProps = {
        color: '#03a9f4',
        disabled: false,
    };

    knob: React.RefObject<HTMLDivElement> | null;

    trackBar: React.RefObject<HTMLDivElement> | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            trackWidth: 0,
            progress: '0',
            userSeeking: false,
            trackPosition: 0,
            chaptersPositions: [],
            playedUpTo: props.playedUpTo,
            timeRemaining: props.duration ? props.duration - props.playedUpTo : 0,
            provisionalPlayedUpTo: undefined,
            provisionalTimeRemaining: undefined,
        };

        this.trackBar = React.createRef();
        this.knob = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.playedUpTo !== this.props.playedUpTo ||
            prevProps.duration !== this.props.duration
        ) {
            const { playedUpTo, duration } = this.props;

            let timeRemaining = duration ? duration - playedUpTo : 0;

            if (timeRemaining < 0) {
                timeRemaining = 0;
            }

            this.setState({
                playedUpTo,
                timeRemaining,
            });

            if (!this.state.userSeeking) {
                this.calculatePositions(this.state.trackWidth, this.props);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.unbindEvents();
    }

    onResize = () => {
        const bar = this.trackBar?.current;
        if (bar) {
            const trackWidth = bar.offsetWidth;
            this.setState({ trackWidth });
            this.calculatePositions(trackWidth, this.props);
        }
    };

    calculatePositions(trackWidth: number, props: Props) {
        const { playedUpTo, duration, chapters } = props;

        const normalisedPlayedUpTo = Math.max(0, Math.min(playedUpTo, duration));

        const trackPosition =
            duration === 0 ? 0 : (normalisedPlayedUpTo / duration) * (trackWidth - 13);

        this.setState({ trackPosition });

        if (chapters) {
            const chaptersPositions: number[] = [];
            chapters.forEach((chapter, index) => {
                const secs = chapter.startTime;
                chaptersPositions[index] =
                    secs === 0 ? 0 : (secs / duration) * (trackWidth - 13) + 4;
            });
            this.setState({ chaptersPositions });
        }
    }

    render() {
        const { color, buffering, chapters, disabled } = this.props;
        const {
            playedUpTo,
            timeRemaining,
            trackPosition,
            provisionalPlayedUpTo,
            provisionalTimeRemaining,
        } = this.state;

        const progressStyles = {
            backgroundColor: color,
            width: `${trackPosition + 4}px`,
        };

        const knobStyles = {
            left: `${trackPosition}px`,
        };

        const currentTime = provisionalPlayedUpTo ?? playedUpTo;
        const timeLeft = provisionalTimeRemaining ?? timeRemaining;

        return (
            <ResizeObserver onResize={this.onResize}>
                <SeekBarWrapper
                    className={classNames('seek-bar', this.props.className)}
                    $isBuffering={
                        !disabled && (buffering || (playedUpTo === 0 && timeRemaining === 0))
                    }
                >
                    <button
                        disabled={disabled}
                        className="tracks"
                        onMouseDown={this.onSliderMouseDown}
                    >
                        <div className="track-bar" ref={this.trackBar} />
                        <div className="buffering-bar" />
                        <div className="played-bar" style={progressStyles} />
                        <div className="knob-bar">
                            <div className="knob" style={knobStyles} ref={this.knob} />
                        </div>
                    </button>
                    {chapters && this.renderChapters()}
                    <TimeText
                        timeSecs={currentTime}
                        className="current-time"
                        data-testid="current-time"
                    />
                    <TimeText timeSecs={timeLeft} className="time-remaining" />
                </SeekBarWrapper>
            </ResizeObserver>
        );
    }

    renderChapters() {
        const { chapters, color } = this.props;
        const { chaptersPositions, trackPosition } = this.state;
        const dotColor = ColorHelper.lightenColor(color, 0.3);
        return (
            <div className="chapters">
                {chapters.map((chapter, index) => {
                    const position = chaptersPositions[index];
                    const afterKnob = position > trackPosition;
                    const dotStyles = {
                        left: `${position}px`,
                        backgroundColor: afterKnob ? '#FFF' : dotColor,
                        opacity: afterKnob ? 0.4 : 1,
                    };
                    const hitLeft = position - 8;
                    const hitWidth = 20;
                    const hitStyles = { left: `${hitLeft}px`, width: `${hitWidth}px` };
                    const tipId = `chapter-${index}`;
                    return (
                        <div className="chapter" key={index}>
                            <button
                                className="hit"
                                style={hitStyles}
                                data-tooltip-id={tipId}
                                data-tooltip-delay-show={0}
                                data-tooltip-place="top"
                                data-tooltip-variant="light"
                                onClick={event => {
                                    this.chapterClick(chapter.startTime, event);
                                }}
                            />
                            <div className="dot" style={dotStyles} />
                            <Tooltip id={tipId}>{chapter.title}</Tooltip>
                        </div>
                    );
                })}
            </div>
        );
    }

    chapterClick = (time: number, event: MouseEvent | React.MouseEvent) => {
        UiHelper.stopPropagation(event);

        this.props.onUserSeeked(time);
    };

    onSliderMouseDown = (event: MouseEvent | React.MouseEvent) => {
        event.preventDefault();
        this.setState({ userSeeking: true });
        this.onMouseMove(event);
        this.bindEvents();
    };

    bindEvents() {
        this.unbindEvents();
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp, false);
    }

    unbindEvents() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp, false);
    }

    onMouseUp = (event: MouseEvent) => {
        this.setState({ userSeeking: false });
        this.unbindEvents();
        this.mouseMove(event, false, true);
        this.didProvisionallyScrubTo(undefined);
    };

    onMouseMove = (event: MouseEvent | React.MouseEvent) => {
        this.mouseMove(event, false, false);
    };

    didProvisionallyScrubTo(trackX?: number) {
        if (trackX != null) {
            const { duration } = this.props;
            const provisionalPlayedUpTo = trackX * duration;
            const provisionalTimeRemaining = duration ? duration - provisionalPlayedUpTo : 0;
            this.setState({ provisionalPlayedUpTo, provisionalTimeRemaining });
        } else {
            this.setState({
                provisionalPlayedUpTo: undefined,
                provisionalTimeRemaining: undefined,
            });
        }
    }

    mouseMove(event: MouseEvent | React.MouseEvent, fireSeeking: boolean, fireSeeked: boolean) {
        if (this.trackBar?.current && this.knob?.current) {
            const trackWidth = this.trackBar.current.offsetWidth - this.knob.current.offsetWidth;
            const trackX = this.trackBar.current.getBoundingClientRect().left;
            let knobX = event.pageX - trackX - this.knob.current.offsetWidth / 2;
            if (knobX < 0) {
                knobX = 0;
            } else if (knobX > trackWidth) {
                knobX = trackWidth;
            }

            this.setState({ trackPosition: knobX });

            // Provisionally scrub the current time and remaining duration
            if (!fireSeeking) {
                this.didProvisionallyScrubTo(knobX / trackWidth);
            }

            const { duration } = this.props;
            if (duration && duration > 0) {
                const time = (knobX / trackWidth) * duration;
                if (fireSeeking && this.props.onUserSeeking) {
                    this.props.onUserSeeking(time);
                }
                if (fireSeeked && this.props.onUserSeeked) {
                    this.props.onUserSeeked(time);
                }
            }
        }
    }
}

export default SeekBar;
