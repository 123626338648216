import { TextButton } from 'components/Button/Button.styled';
import styled, { css } from 'styled-components';
import { gridSize, Typography } from '../../../../styles';

export const flagSlideInTimeMs = 300;
export const flagSlideOutTimeMs = 300;

export const FlagWrapper = styled.div<{ $hiding: boolean }>`
    ${Typography.Desktop['H70-B']}
    background: ${props => props.theme.tokens['primary-text-01']};
    color: ${props => props.theme.tokens['primary-ui-01']};
    border-radius: 4px;
    padding: ${2 * gridSize}px ${3 * gridSize}px;

    margin-top: ${gridSize}px;

    ${TextButton}, a {
        color: inherit;
        text-decoration: underline;
        &:hover {
            opacity: 0.6;
        }
    }

    opacity: 1;
    transform: translate(0, 0);

    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    // We don't always need to fully hide an animation,
    // but can set a short enough duration that the motion itself is no longer noticable
    // https://www.a11yproject.com/posts/understanding-vestibular-disorders/
    @media (prefers-reduced-motion) {
        transition-duration: 0.01s;
    }

    ${props =>
        props.$hiding &&
        css`
            transform: translate(-420px, 0);
            opacity: 0;
        `}
`;
