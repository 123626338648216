import { Button } from 'components/Button';
import { Icon, IconId } from 'components/Icon';
import React, {
    FocusEventHandler,
    ForwardedRef,
    forwardRef,
    KeyboardEventHandler,
    useRef,
    useState,
} from 'react';
import { AuxIconWrapper, PrefixIcon, StyledInput, Wrapper } from './Input.styled';

export type InputStatus = 'ok' | 'error';

export type Props = {
    width?: number;
    icon?: IconId;
    status?: InputStatus;
    noAuxiliaryIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement | HTMLButtonElement>;

const iconMap: Record<string, IconId> = {
    password: 'key',
    email: 'mail',
    search: 'search',
};

const statusIconMap: Record<string, IconId> = {
    error: 'cancel-circle',
    ok: 'tick-circle',
};

export const Input = forwardRef<HTMLInputElement, Props>(
    (
        { width, icon, status, noAuxiliaryIcon, type = 'text', ...props }: Props,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const [focused, setFocused] = useState<boolean>(false);
        const [showPassword, setShowPassword] = useState<boolean>(false);
        const groupRef = useRef<HTMLLabelElement>(null);

        const handleGroupBlur: FocusEventHandler<HTMLInputElement | HTMLButtonElement> = e => {
            const relatedTarget = e.relatedTarget;

            // When the focus is lost but still within this element, do nothing
            if (groupRef.current?.contains(relatedTarget)) {
                return;
            }

            setFocused(false);
            setShowPassword(false);
            props.onBlur?.(e);
        };

        const handleGroupFocus: FocusEventHandler<HTMLInputElement | HTMLButtonElement> = e => {
            const relatedTarget = e.relatedTarget;

            // When the focus is gained but it's coming from within this element, do nothing
            if (groupRef.current?.contains(relatedTarget)) {
                return;
            }

            setFocused(true);
            props.onFocus?.(e);
        };

        const toggleShowPassword = () => setShowPassword(!showPassword);

        const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
            props.onKeyDown && props.onKeyDown(event);

            if (event.key === 'Escape' && type === 'password') {
                setShowPassword(false);
            }
        };

        const inputIcon = icon ?? iconMap[type];
        const statusIcon = !focused && !noAuxiliaryIcon && status && statusIconMap[status];

        return (
            <Wrapper ref={groupRef} $focused={focused} status={status} width={width}>
                <PrefixIcon $hasExplicitIcon={!!icon}>
                    {inputIcon && <Icon id={inputIcon} />}
                </PrefixIcon>

                <StyledInput
                    {...props}
                    type={type === 'password' && showPassword ? 'text' : type}
                    ref={ref}
                    onBlur={handleGroupBlur}
                    onFocus={handleGroupFocus}
                    onKeyDown={handleKeyDown}
                />

                {statusIcon && (
                    <AuxIconWrapper>
                        <Icon id={statusIcon} />
                    </AuxIconWrapper>
                )}

                <AuxIconWrapper $enabled={type === 'password'} aria-hidden>
                    <Button
                        style={{ visibility: focused ? 'visible' : 'hidden' }}
                        kind="text"
                        type="button"
                        onClick={() => {
                            // If we toggle during the click event, the cursor is moved to the start of the
                            // input. By delaying the toggle until the event callback completes, the cursor
                            // does not jump at all.
                            setTimeout(toggleShowPassword, 0);
                        }}
                        onMouseDown={e => {
                            // Prevent focus from being stolen from the input at the start of a click
                            e.preventDefault();
                        }}
                        onBlur={handleGroupBlur}
                        onFocus={handleGroupFocus}
                    >
                        <Icon id={showPassword ? 'eyeball-cross' : 'eyeball'} />
                    </Button>
                </AuxIconWrapper>
            </Wrapper>
        );
    },
);

export default Input;
