import { IconId } from 'components/Icon';
import { WebPlayerThemeToken } from 'model/theme';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const CATEGORIES: Record<
    number,
    {
        id: number;
        token: keyof WebPlayerThemeToken;
        key: string;
        label: string;
        aliasLabels?: string[];
        formattedLabel: ReactElement;
    }
> = {
    1: {
        id: 1,
        token: 'category-01',
        key: 'arts',
        label: 'Arts',
        formattedLabel: <FormattedMessage id="category-arts" />,
    },
    2: {
        id: 2,
        token: 'category-02',
        key: 'business',
        label: 'Business',
        formattedLabel: <FormattedMessage id="category-business" />,
    },
    3: {
        id: 3,
        token: 'category-03',
        key: 'comedy',
        label: 'Comedy',
        formattedLabel: <FormattedMessage id="category-comedy" />,
    },
    4: {
        id: 4,
        token: 'category-04',
        key: 'education',
        label: 'Education',
        formattedLabel: <FormattedMessage id="category-education" />,
    },
    5: {
        id: 5,
        token: 'category-10',
        key: 'leisure',
        label: 'Leisure',
        formattedLabel: <FormattedMessage id="category-leisure" />,
    },
    6: {
        id: 6,
        token: 'category-06',
        key: 'government',
        label: 'Government',
        formattedLabel: <FormattedMessage id="category-government" />,
    },
    7: {
        id: 7,
        token: 'category-08',
        key: 'health-fitness',
        label: 'Health & Fitness',
        formattedLabel: <FormattedMessage id="category-health-fitness" />,
    },
    8: {
        id: 8,
        token: 'category-09',
        key: 'kids-family',
        label: 'Kids & Family',
        formattedLabel: <FormattedMessage id="category-kids-family" />,
    },
    9: {
        id: 9,
        token: 'category-11',
        key: 'music',
        label: 'Music',
        formattedLabel: <FormattedMessage id="category-music" />,
    },
    10: {
        id: 10,
        token: 'category-12',
        key: 'news',
        label: 'News',
        formattedLabel: <FormattedMessage id="category-news" />,
    },
    11: {
        id: 11,
        token: 'category-13',
        key: 'spirituality',
        label: 'Spirituality',
        formattedLabel: <FormattedMessage id="category-spirituality" />,
    },
    12: {
        id: 12,
        token: 'category-14',
        key: 'science',
        label: 'Science',
        formattedLabel: <FormattedMessage id="category-science" />,
    },
    13: {
        id: 13,
        token: 'category-15',
        key: 'society-culture',
        label: 'Society & Culture',
        formattedLabel: <FormattedMessage id="category-society-culture" />,
    },
    14: {
        id: 14,
        token: 'category-16',
        key: 'sports',
        label: 'Sports',
        formattedLabel: <FormattedMessage id="category-sports" />,
    },
    15: {
        id: 15,
        token: 'category-17',
        key: 'tech',
        label: 'Tech',
        aliasLabels: ['Technology'],
        formattedLabel: <FormattedMessage id="category-tech" />,
    },
    16: {
        id: 16,
        token: 'category-19',
        key: 'tv-film',
        label: 'TV & Film',
        formattedLabel: <FormattedMessage id="category-tv-film" />,
    },
    17: {
        id: 17,
        token: 'category-05',
        key: 'fiction',
        label: 'Fiction',
        formattedLabel: <FormattedMessage id="category-fiction" />,
    },
    18: {
        id: 18,
        token: 'category-07',
        key: 'history',
        label: 'History',
        formattedLabel: <FormattedMessage id="category-history" />,
    },
    19: {
        id: 19,
        token: 'category-18',
        key: 'crime',
        label: 'True Crime',
        formattedLabel: <FormattedMessage id="category-true-crime" />,
    },
};

// CATEGORIES is indexed by numeric ID. This creates a mapping between the
// human-readable label and the category.
const CATEGORIES_BY_LABEL = Object.values(CATEGORIES).reduce<
    Record<string, (typeof CATEGORIES)[number]>
>((acc, category) => {
    acc[category.label] = category;
    if (category.aliasLabels) {
        category.aliasLabels.forEach(label => {
            acc[label] = category;
        });
    }
    return acc;
}, {});

export const getCategoryByLabel = (label: string) => CATEGORIES_BY_LABEL[label];

export const getToken = (id: number) => {
    const category = CATEGORIES[id] || CATEGORIES[19];
    return category.token;
};

export const Icon = ({ id }: { id: number }) => {
    const category = CATEGORIES[id] || CATEGORIES[19];
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            aria-hidden={true}
        >
            <use xlinkHref={`#category-${category.key}`} />
        </svg>
    );
};

export const getCategoryIcon = (id: number): IconId => {
    const category = CATEGORIES[id] || CATEGORIES[19];
    return `category-${category.key}-sm` as IconId;
};
