import { action } from '../api';

export const analyitcsLoaded = (maybeFireTrackers: () => void) =>
    action('ANALYTICS_LOADED', { maybeFireTrackers });

export const cookiesBannerShown = () => action('ANALYTICS_COOKIES_BANNER_SHOWN');

export const acceptAllClicked = () => action('ANALYTICS_ACCEPT_ALL_CLICKED');

export const acceptSelectionClicked = (analyticsOptIn: boolean | null) =>
    action('ANALYTICS_ACCEPT_SELECTION_CLICKED', { analyticsOptIn });
