import { CredentialNotification } from 'components/form/CredentialNotification';
import { LoginRegisterForm } from 'components/form/LoginRegisterForm';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParam } from 'react-use';
import { ModalTypes } from '../../helper/UiHelper';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import useIsSubscribedToPodcasts from '../../hooks/useIsSubscribedToPodcasts';
import useNavigateOrRedirect from '../../hooks/useNavigateOrRedirect';
import useQueryRedirect from '../../hooks/useQueryRedirect';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import * as fromUserActions from '../../redux/actions/user.actions';
import { getDefaultTracksSource } from '../../redux/reducers/selectors';
import { getJustSignedUp, userIsLoggedIn } from '../../redux/reducers/selectors/user.selectors';
import urls from '../../urls';
import { StyledTitle, Wrapper } from './LoginPage.styled';

const LoginPage = ({ allowSignup = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigateOrRedirect = useNavigateOrRedirect();
    const queryRedirect = useQueryRedirect();

    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const justSignedUp = useSelector(getJustSignedUp);
    const isLoggedIn = useSelector(userIsLoggedIn);
    const isSubscribedToPodcasts = useIsSubscribedToPodcasts();

    const [oauthUrl, setOauthUrl] = useState<string | null>(null);

    useEffect(() => {
        if (oauthUrl) {
            window.location.href = oauthUrl;
        }
    }, [oauthUrl]);

    // Clean up errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [dispatch],
    );

    const handleOAuthCallback = useCallback((evt: Event) => {
        setOauthUrl((evt as CustomEvent<{ url: string }>).detail.url);
    }, []);

    const handleOAuthError = useCallback(() => {
        // This fires even when the user intentionally closes OAuth from the Mac App.
        // So for now let's leave this as a no-op. Here's the message sent in case
        // we want to use it in the future:
    }, []);

    const handleOAuthUnsupported = useCallback((evt: Event) => {
        // The Desktop app does not support native OAuth, and will pass back the OAuth URL.
        // Redirect to that URL to start the downgraded redirect signin process.
        setOauthUrl((evt as CustomEvent<{ url: string }>).detail.url);
    }, []);

    useEffect(() => {
        document.addEventListener('oauth-callback', handleOAuthCallback);
        document.addEventListener('oauth-error', handleOAuthError);
        document.addEventListener('oauth-unsupported', handleOAuthUnsupported);

        return () => {
            document.removeEventListener('oauth-callback', handleOAuthCallback);
            document.removeEventListener('oauth-error', handleOAuthError);
            document.removeEventListener('oauth-unsupported', handleOAuthUnsupported);
        };
    }, [handleOAuthCallback, handleOAuthError, handleOAuthUnsupported]);

    const showResetPasswordSuccess =
        location && location.state && location.state.loginSuccessMessage;

    useEffect(() => {
        // Wait until the user is logged in
        if (!isLoggedIn) {
            return;
        }

        if (isSubscribedToPodcasts === undefined) {
            // Still loading, so we can't decide what to show yet
            return;
        }

        if (justSignedUp) {
            navigate(urls.welcomePath({ redirect: queryRedirect }));
            return;
        }

        navigateOrRedirect(isSubscribedToPodcasts ? urls.podcastsPath : urls.startPath);

        // If the user is registering but they already had an account, then we should just
        // log them in and show a message indicating the situation.
        if (allowSignup) {
            dispatch(
                fromSubscriptionActions.Actions.showModalGeneric(
                    ModalTypes.showAlreadyHaveAccountModal,
                ),
            );
        }
    }, [
        allowSignup,
        dispatch,
        isLoggedIn,
        isSubscribedToPodcasts,
        justSignedUp,
        navigate,
        navigateOrRedirect,
        queryRedirect,
    ]);

    const shownEvent = allowSignup ? 'signup_shown' : 'signin_shown';
    const dismissedEvent = allowSignup ? 'signup_dismissed' : 'signin_dismissed';
    const source = useSelector(getDefaultTracksSource);

    const variant = useSearchParam('variant');

    const signupTitle =
        variant === 'plus'
            ? formatMessage('sign-up-free-trial')
            : formatMessage('sign-up-and-enjoy');

    return (
        <Wrapper>
            <TrackOnMount event={shownEvent} key={shownEvent} properties={{ source }} />
            <TrackOnUnmount event={dismissedEvent} key={dismissedEvent} properties={{ source }} />
            <Helmet>
                <title>{formatMessage('log-in')}</title>
            </Helmet>

            {showResetPasswordSuccess && (
                <CredentialNotification variant="success">
                    {location.state.loginSuccessMessage}
                </CredentialNotification>
            )}

            <LoginRegisterForm
                allowSignup={allowSignup}
                title={
                    <StyledTitle>
                        {allowSignup ? signupTitle : formatMessage('log-in-to-pocket-casts')}
                    </StyledTitle>
                }
                submitButtonText={allowSignup ? formatMessage('sign-up') : formatMessage('log-in')}
            />
        </Wrapper>
    );
};

export default LoginPage;
