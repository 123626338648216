import { DiscoverEpisodeLarge, DiscoverPodcast, DiscoverSection } from 'components';
import { getPodcastListTint } from 'model/theme';
import {
    DiscoverExpandedStyle,
    DiscoverSummaryStyle,
    ListData,
    PodcastDiscover,
} from 'model/types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { LoaderSquare } from '../../components/LoaderSquare';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import { NavigationItems } from '../../helper/NavigationHelper';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import * as fromDiscoverActions from '../../redux/actions/discover.actions';
import { RootState } from '../../redux/reducers';
import { getDiscoverListPageDetails } from '../../redux/reducers/selectors';
import { ListPageHeader } from './ListPageHeader';
import { PodcastDescriptiveGrid } from './PodcastDescriptiveGrid';
import { ListTitle, PodcastListPageWrapper } from './PodcastListPage.styled';

const PodcastListDescription = ({
    pageHeaderDescription,
    summary,
    listId,
    title,
}: {
    pageHeaderDescription: {
        summaryType: DiscoverSummaryStyle;
        expandedType: DiscoverExpandedStyle;
        data?: ListData;
    };
    summary?: ListData;
    listId: string;
    title: string;
}) => {
    if (pageHeaderDescription) {
        switch (pageHeaderDescription.summaryType) {
            case DiscoverSummaryStyle.COLLECTION:
                if (summary != null) {
                    return (
                        <ListPageHeader
                            title={title}
                            subtitle={summary.subtitle}
                            imageUrl={summary.collection_image}
                            url={summary.web_url}
                            urlText={summary.web_title}
                            colors={summary.colors}
                            description={summary.description}
                            summaryStyle={pageHeaderDescription.summaryType}
                            listId={listId}
                        />
                    );
                }
                return null;
            default:
                return <ListTitle>{title}</ListTitle>;
        }
    }
    return null;
};

export function PodcastListPage() {
    const dispatch = useDispatch();
    const {
        discoverListImpressionEvent,
        discoverEpisodePlayEvent,
        discoverEpisodeTapEvent,
        discoverPodcastTapEvent,
        discoverPodcastSubscribeEvent,
    } = useAnalyticsContext();
    const navigate = useNavigate();
    const params = useParams();

    const {
        list,
        title,
        listId,
        ranked,
        pageHeaderDescription,
        discoverContentJsonLayout,
        region,
        curated,
    } = useSelector((state: RootState) =>
        getDiscoverListPageDetails(state, { listId: params.listId! }),
    );

    const isLoading = discoverContentJsonLayout == null || title == null || region == null;

    useScrollRestoration(!isLoading);

    useEffect(() => {
        // We have to do this check because a user can load a List without having gone through
        // the discover page, but we also don't want to load the discover content on every page.
        if (region && !discoverContentJsonLayout) {
            dispatch(fromDiscoverActions.Actions.getDiscoverContent());
        }
        dispatch(fromDiscoverActions.Actions.openDiscoverList(listId));
    }, [discoverContentJsonLayout, dispatch, listId, region]);

    useEffect(() => {
        if (!isLoading && curated) {
            discoverListImpressionEvent(listId);
        }
    }, [isLoading, curated, discoverListImpressionEvent, listId]);

    const summary = pageHeaderDescription ? pageHeaderDescription.data : undefined;
    const themeId = useSelector((state: RootState) => state.settings.theme);
    const accentColor = summary?.colors
        ? getPodcastListTint({ colors: summary.colors, themeId })
        : undefined;

    const podcastClicked = (podcast: { uuid: string }) => {
        discoverPodcastTapEvent(listId, podcast.uuid);

        navigate(`${NavigationItems.DISCOVER.path}/podcast/${podcast.uuid}`, {
            state: {
                curatedListId: listId,
            },
        });
    };

    const recordSubscribeEvent = (podcast: PodcastDiscover) => {
        discoverPodcastSubscribeEvent(listId, podcast.uuid);
    };

    const expandedType = pageHeaderDescription ? pageHeaderDescription.expandedType : null;

    return (
        <PodcastListPageWrapper>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {isLoading && <LoaderSquare />}
            {!isLoading && (
                <PodcastListDescription
                    listId={listId}
                    pageHeaderDescription={pageHeaderDescription}
                    summary={summary}
                    title={title}
                />
            )}
            {!isLoading && expandedType === DiscoverExpandedStyle.DESCRIPTIVE && (
                <PodcastDescriptiveGrid
                    podcasts={list?.podcasts}
                    onPodcastClick={podcastClicked}
                    onSubscribe={recordSubscribeEvent}
                />
            )}
            {!isLoading &&
                expandedType !== DiscoverExpandedStyle.DESCRIPTIVE &&
                list?.episodes?.length > 0 && (
                    <DiscoverSection discoverFormat="grid" minWidth={400} gap={'32px 16px'}>
                        {list.episodes.map(episode => (
                            <DiscoverEpisodeLarge
                                key={episode.uuid}
                                episode={episode}
                                color={accentColor || undefined}
                                onEpisodeClick={(podcastUuid, episodeUuid) =>
                                    discoverEpisodeTapEvent(listId, podcastUuid, episodeUuid)
                                }
                                onEpisodePlay={podcastUuid =>
                                    discoverEpisodePlayEvent(podcastUuid, listId)
                                }
                                onPodcastClick={() =>
                                    podcastClicked({ uuid: episode.podcast_uuid })
                                }
                            />
                        ))}
                    </DiscoverSection>
                )}
            {!isLoading &&
                expandedType !== DiscoverExpandedStyle.DESCRIPTIVE &&
                list?.podcasts?.length > 0 && (
                    <DiscoverSection discoverFormat="grid" minWidth={152}>
                        {list.podcasts.map((podcast, index) => (
                            <DiscoverPodcast
                                key={podcast.uuid}
                                discoverFormat="grid"
                                rank={ranked ? index + 1 : undefined}
                                podcast={podcast}
                                onClick={() => podcastClicked(podcast)}
                                onSubscribe={() =>
                                    discoverPodcastSubscribeEvent(listId, podcast.uuid)
                                }
                            />
                        ))}
                    </DiscoverSection>
                )}
        </PodcastListPageWrapper>
    );
}

export default PodcastListPage;
