import styled, { css } from 'styled-components';
import { gridSize, Typography } from 'styles';
import { Row } from '../ListEpisodes.styled';

export const ActionsColumn = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${gridSize * 1.5}px;
`;

const HideOnHover = css`
    ${Row}:hover & {
        display: none;
    }
`;

const ShowOnHover = css`
    ${Row}:focus-within & {
        visibility: visible;
    }
    ${Row}:not(:hover) & {
        visibility: hidden;
    }
`;

export const HoverButtons = styled.div`
    display: flex;
    gap: ${gridSize * 1.5}px;
    ${ShowOnHover}
`;

export const ArchivedStatus = styled.div`
    ${Typography.Desktop.H80}
    display: flex;
    align-items: center;
    padding-top: 3px;
    color: ${props => props.theme.legacyScss['theme-interactive-secondary']};

    svg {
        margin: -0.2em 0.5em 0 0;
        opacity: 0.6;
    }

    @media only screen and (max-width: 1030px) {
        svg {
            display: none;
        }
    }
    ${HideOnHover}
`;
