import { isBetaEnabled } from 'helper/BetaHelper';
import { getElectronAppVersion, isMacApp, isWindowsApp } from 'helper/Browser';
import semver from 'semver';

export const VERSION = '5.0.4';

export const MARKETING_URL = import.meta.env.VITE_MARKETING_URL;

export const APP_ENVIRONMENT = import.meta.env.MODE;

export const API_URL = import.meta.env.VITE_API_URL;

export const PODCAST_URL = import.meta.env.VITE_PODCAST_URL;

export const PODCAST_CACHE_URL = import.meta.env.VITE_PODCAST_CACHE_URL;
export const STATIC_URL = import.meta.env.VITE_STATIC_URL;

export const LISTS_URL = import.meta.env.VITE_PODCAST_LISTS_URL;

export const MOBILE_CACHE_URL = import.meta.env.VITE_MOBILE_CACHE_URL;

export const PADDLE_VENDOR_ID = Number(import.meta.env.VITE_PADDLE_VENDOR_ID);

export const PADDLE_SKU_PATRON_MONTHLY = Number(import.meta.env.VITE_PADDLE_SKU_PATRON_MONTHLY);

export const PADDLE_SKU_PATRON_YEARLY = Number(import.meta.env.VITE_PADDLE_SKU_PATRON_YEARLY);
export const APPLE_AUTH_CLIENT_ID = import.meta.env.VITE_APPLE_AUTH_CLIENT_ID;

export const GOOGLE_AUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID;

export const PADDLE_SKU_PLUS_MONTHLY = Number(import.meta.env.VITE_PADDLE_SKU_PLUS_MONTHLY);

export const PADDLE_SKU_PLUS_YEARLY = Number(import.meta.env.VITE_PADDLE_SKU_PLUS_YEARLY);

/*
This is currently unused, because we don't want to offer trials for monthly subscriptions at the moment.
This might change at any time, so we're keeping this around.

See also https://github.com/Automattic/pocket-casts-webplayer/pull/2734
*/
export const PADDLE_SKU_PLUS_MONTHLY_TRIAL = Number(
    import.meta.env.VITE_PADDLE_SKU_PLUS_MONTHLY_TRIAL,
);

/*
This will only be used in the case USE_50_PERCENT_BY_DEFAULT is false, as that offer has
preference over the trial.
*/
export const PADDLE_SKU_PLUS_YEARLY_TRIAL = Number(
    import.meta.env.VITE_PADDLE_SKU_PLUS_YEARLY_TRIAL,
);

export const PADDLE_ENVIRONMENT = import.meta.env.VITE_PADDLE_ENVIRONMENT;

export const ALL_PADDLE_SKUS = [
    PADDLE_SKU_PATRON_MONTHLY,
    PADDLE_SKU_PATRON_YEARLY,
    PADDLE_SKU_PLUS_MONTHLY,
    PADDLE_SKU_PLUS_YEARLY,
    PADDLE_SKU_PLUS_MONTHLY_TRIAL,
    PADDLE_SKU_PLUS_YEARLY_TRIAL,
];

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const DEPLOYMENT_ENV = import.meta.env.MODE;
export const RELEASE_ID = import.meta.env.VITE_RELEASE_ID;

export const API_VERSION = 2;
export const API_MODEL = 'webplayer';

export const MAX_PLAYBACK_SPEED = 5;
export const MIN_PLAYBACK_SPEED = 0.5;

export const FORM_ERRORS_DELAY = 1_000;

export const VALID_EMAIL_REGEX = /(.+)@(.+){1,}\.(.+){1,}/;

// FEATURE FLAGS

export const isNotProduction =
    DEPLOYMENT_ENV && ['development', 'staging', 'test'].includes(DEPLOYMENT_ENV);
const isDevelopment = DEPLOYMENT_ENV && ['development'].includes(DEPLOYMENT_ENV);

export const USE_PATRON =
    new Date().getTime() >= new Date('2023-10-30').getTime() || isNotProduction;

// Desktop apps require support from the native wrappers for this to work,
// which is not yet implemented.
export const USE_IMPORT_EXPORT = !isMacApp() && !isWindowsApp();

export const USE_GIVE_RATINGS = true;

export const USE_SHARING_CLIP_CREATION = true;

export const USE_ACCORDION_EXPERIMENT = isNotProduction && !isWindowsApp();

export const USE_SLUMBER_STUDIOS_PARTNERSHIP = true;

export const USE_CATALAN_TRANSLATION = isBetaEnabled() || isNotProduction;

export const USE_50_PERCENT_BY_DEFAULT = false;
export const USE_EXCLUDE_COUNTRIES_FROM_OFFER_DISCOUNTS = false;
export const excludeCountriesFromInfoOffers = ['IN', 'BR', 'ZA']; // https://wp.me/pdeCcb-4fM#comment-3591

export const USE_FOLLOW_NAMING = true;

export const USE_UP_NEXT_SHUFFLE = true;

export const USE_HTML_DESCRIPTIONS = true;

/**
 * For development only:
 * Set this to false to avoid dispatching network requests
 * when navigating from the DevTools console to the web player UI.
 */
export const USE_FOCUS_LISTENER = true;

export const USE_RESPONSIVE = false;

// Detect if there is a query striing parameter like _prerender=true
export const IS_PRERENDER =
    new URLSearchParams(window.location.search).get('_prerender') === 'true';

/**
 * Use Chromium media controls for the native player, version above 2.0.8
 */
const appVersion = getElectronAppVersion();
export const USE_CHROMIUM_MEDIA_CONTROLS =
    isDevelopment || (appVersion && semver.gt(appVersion, '2.0.8'));

export const USE_DISABLE_PRIVATE_FEED_SHARING = true;
