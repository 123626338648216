import styled from 'styled-components';
import { gridSize } from '../../styles';
import { ModernStyle } from '../../styles/ModernStyle.styled';

export const Wrapper = styled(ModernStyle).attrs({ as: 'div' })`
    width: 100%;
    max-width: 600px;
    min-height: 100vh;

    padding: ${6 * gridSize}px ${3 * gridSize}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
`;

export const TrialDescription = styled.section`
    text-align: center;
    padding: ${4 * gridSize}px;
    border: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-radius: 8px;
    width: 100%;
    margin-bottom: ${6 * gridSize}px;
    p {
        font-size: 24px;
        line-height: 1.5;
        margin: ${3 * gridSize}px 0 0;
        font-weight: 400;
    }
`;
