import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SubscriptionTier } from '../../model/types';
import { ModernStyle } from '../../styles/ModernStyle.styled';
import { subscribeToPlanButtonMixin } from '../Button/Button.styled';
import { ButtonLink } from '../ButtonLink';

const StyledSubscribeToPlanButtonLink = styled(ButtonLink)<{ $tier: SubscriptionTier }>`
    ${subscribeToPlanButtonMixin}
`;

type Props = Omit<ComponentProps<typeof StyledSubscribeToPlanButtonLink>, '$tier'> & {
    tier: SubscriptionTier;
};

const SubscribeToPlanButtonLink = ({ children, tier, ...props }: Props) => (
    <ModernStyle>
        <StyledSubscribeToPlanButtonLink $tier={tier} {...props}>
            {children ?? <FormattedMessage id="subscribe-to-tier" values={{ tier }} />}
        </StyledSubscribeToPlanButtonLink>
    </ModernStyle>
);

export default SubscribeToPlanButtonLink;
