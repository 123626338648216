import { PodcastImage, SubscribeButton } from 'components';
import { PodcastDiscover } from 'model/types';
import React from 'react';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import {
    DiscoverSingleDescription,
    DiscoverSingleText,
    DiscoverSingleTitle,
    DiscoverSingleWrapper,
    FreshPickBadge,
    ImageWrapper,
    Spons0redBadge,
} from './DiscoverSingle.styled';

type Props = {
    description: string;
    podcast: PodcastDiscover;
    onClick: () => void;
    onSubscribe: () => void;
    sponsored: boolean;
};

function DiscoverSingle({ description, podcast, onClick, onSubscribe, sponsored }: Props) {
    const onKeyDown = (e: React.KeyboardEvent) => {
        onKeyDownEnter(e, () => onClick?.());
    };
    return (
        <>
            <DiscoverSingleWrapper
                role="button"
                onClick={onClick}
                onKeyDown={onKeyDown}
                tabIndex={0}
            >
                <ImageWrapper>
                    <PodcastImage uuid={podcast.uuid} title={podcast.title} />
                    <SubscribeButton
                        circled
                        podcastUuid={podcast.uuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                </ImageWrapper>
                <DiscoverSingleText>
                    {sponsored ? (
                        <Spons0redBadge>SPONSORED</Spons0redBadge>
                    ) : (
                        <FreshPickBadge>FRESH PICK</FreshPickBadge>
                    )}
                    <DiscoverSingleTitle>{podcast.title || ''}</DiscoverSingleTitle>
                    <DiscoverSingleDescription>{description || ''}</DiscoverSingleDescription>
                </DiscoverSingleText>
            </DiscoverSingleWrapper>
        </>
    );
}

export default DiscoverSingle;
