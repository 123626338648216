import classNames from 'classnames';
import key from 'keymaster';
import qs from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import { useSearchParam } from 'react-use';
import { ThemeProvider } from 'styled-components';
import { OutsideAppAuthenticatorPage } from './OutsideAppAuthenticatorPage';
import { AuthenticationChrome } from './components/AuthenticationChrome';
import { ErrorBoundary } from './components/ErrorBoundary';
import GlobalA11yAnnouncements from './components/GlobalA11yAnnouncements/GlobalA11yAnnouncements';
import { MobileOverlay } from './components/MobileOverlay';
import { PodcastPromo, PromoChrome } from './components/PromoChrome';
import { Tooltip } from './components/Tooltip';
import { ErrorMessage, SvgDefinitions } from './components/index';
import { AnalyticsContextProvider } from './context/AnalyticsContext';
import { isMobile, isWindowsApp } from './helper/Browser';
import { NavigationItems as NavItems } from './helper/NavigationHelper';
import { firstParamValue, getRedirectQueryParam } from './helper/QueryStringHelper';
import { useDispatch } from './hooks/react-redux-typed';
import useQueryDiscounts from './hooks/useQueryDiscounts';
import { ESCAPE_KEY_PRESSED_EVENT } from './model/page';
import {
    getThemeFromId,
    prefersDarkThemeMediaQuery,
    prefersLightThemeMediaQuery,
    THEME,
} from './model/theme';
import { Podcast, PodcastTintColors } from './model/types';
import { BookmarksPage } from './pages/BookmarksPage';
import { CategoryPage } from './pages/CategoryPage';
import { DeleteAccountPage } from './pages/DeleteAccountPage';
import DiscoverPage from './pages/DiscoverPage';
import EpisodePage from './pages/EpisodePage/EpisodePage';
import FilterPage from './pages/FilterPage';
import { InvitePage } from './pages/InvitePage';
import LoggedInPageChrome from './pages/LoggedInPageChrome';
import { LoggedOutPageChrome } from './pages/LoggedOutPageChrome';
import { LoginPage } from './pages/LoginPage';
import { MiniPlayerWindow } from './pages/MiniPlayerWindow';
import { OAuthLoginPage } from './pages/OAuthLoginPage';
import { PasswordForgottenPage } from './pages/PasswordForgottenPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { PodcastListPage } from './pages/PodcastListPage';
import { PodcastPage } from './pages/PodcastPage';
import { PodcastSettingsPage } from './pages/PodcastSettingsPage';
import PodcastsPage from './pages/PodcastsPage';
import ProfilePage from './pages/ProfilePage';
import { RedeemPromoPage } from './pages/RedeemPromoPage';
import { RegisterPage } from './pages/RegisterPage';
import { SearchPage } from './pages/SearchPage';
import { SettingsPage } from './pages/SettingsPage';
import { StatsPage } from './pages/StatsPage';
import { SubscribePage } from './pages/SubscribePage';
import UploadedFilesPage from './pages/UploadedFilesPage';
import { WelcomePage } from './pages/WelcomePage';
import * as fromPodcastsActions from './redux/actions/podcasts.actions';
import * as fromSettingsActions from './redux/actions/settings.actions';
import * as fromUserActions from './redux/actions/user.actions';
import {
    getDisplayedTheme,
    getLanguage,
    getPreferredColorScheme,
    getTheme,
    getUuidToColors,
    getUuidToPodcast,
    hasPaidSubscription,
} from './redux/reducers/selectors';
import { userIsLoggedIn } from './redux/reducers/selectors/user.selectors';
import { GlobalAppStyles } from './styles';
import { getShortLanguage } from './translations';
import urls from './urls';

const App = () => {
    const location = useLocation();
    const intl = useIntl();
    const dispatch = useDispatch();

    const { podcastUuid, episodeUuid } = useMemo(
        () => getPodcastFromRedirect(location.search),
        [location.search],
    );

    const language = useSelector(getLanguage);
    const theme = useSelector(getTheme);
    const isSubscriber = useSelector(hasPaidSubscription);
    const isLoggedIn = useSelector(userIsLoggedIn);
    const uuidToPodcast = useSelector(getUuidToPodcast);
    const uuidToColors = useSelector(getUuidToColors);
    const { hasDiscount } = useQueryDiscounts();

    // Subscribe to this state so that the component re-renders when the theme changes
    useSelector(getPreferredColorScheme);

    const displayedTheme = getDisplayedTheme(theme);

    const redirectToPodcast = useMemo(
        () => ({
            podcastUuid,
            episodeUuid,
            podcast: podcastUuid ? uuidToPodcast[podcastUuid] : undefined,
            colors: podcastUuid ? uuidToColors[podcastUuid] : undefined,
        }),
        [podcastUuid, episodeUuid, uuidToPodcast, uuidToColors],
    );

    const fetchRedirectPodcast = useCallback(() => {
        if (podcastUuid) {
            dispatch(fromPodcastsActions.Actions.downloadPodcast(podcastUuid));
        }
    }, [dispatch, podcastUuid]);

    const getSanitisedSearch = useCallback(() => {
        if (location.pathname === urls.episodeSharePath) {
            return location.search.replace(/&amp;/g, '&');
        }
        return location.search;
    }, [location.pathname, location.search]);

    const onPreferredThemeUpdated = useCallback(() => {
        if (theme === THEME.system) {
            if (prefersLightThemeMediaQuery.matches) {
                dispatch(fromSettingsActions.Actions.updatePreferredColorScheme(THEME.light));
            } else if (prefersDarkThemeMediaQuery.matches) {
                dispatch(fromSettingsActions.Actions.updatePreferredColorScheme(THEME.dark));
            }
        }
    }, [theme, dispatch]);

    const watchBrowserSystemThemesChanges = useCallback(() => {
        if (!window.matchMedia) {
            return () => {};
        }
        prefersDarkThemeMediaQuery.addEventListener('change', onPreferredThemeUpdated);
        prefersLightThemeMediaQuery.addEventListener('change', onPreferredThemeUpdated);
        return () => {
            prefersDarkThemeMediaQuery.removeEventListener('change', onPreferredThemeUpdated);
            prefersLightThemeMediaQuery.removeEventListener('change', onPreferredThemeUpdated);
        };
    }, [onPreferredThemeUpdated]);

    const shortcutEsc = useCallback(() => {
        window.dispatchEvent(new CustomEvent(ESCAPE_KEY_PRESSED_EVENT, {}));
    }, []);

    useEffect(() => {
        key('escape', shortcutEsc);
        return () => {
            key.unbind('escape');
        };
    }, [shortcutEsc, watchBrowserSystemThemesChanges, fetchRedirectPodcast]);

    useEffect(() => {
        fetchRedirectPodcast();
    }, [fetchRedirectPodcast]);

    useEffect(() => {
        if (!isWindowsApp()) {
            watchBrowserSystemThemesChanges();
        }
    }, [watchBrowserSystemThemesChanges]);

    const styleClasses = classNames('app', `language-${getShortLanguage(language)}`);
    const currentTheme = getThemeFromId(displayedTheme);

    return (
        <ThemeProvider theme={currentTheme}>
            <MobileOverlay />
            <GlobalAppStyles />
            <GlobalA11yAnnouncements />
            <Helmet titleTemplate="%s - Pocket Casts">
                <title>{intl.formatMessage({ id: 'modal-web-player' })}</title>
                {!isMobile() && (
                    <meta
                        name="theme-color"
                        content={currentTheme.name === 'dark' ? '#303337' : '#F7F9FA'}
                    />
                )}
                {isMobile() && (
                    <meta name="theme-color" content={currentTheme.tokens['secondary-ui-01']} />
                )}
            </Helmet>
            <div className={styleClasses}>
                <SvgDefinitions />
                <AnalyticsContextProvider>
                    <ErrorBoundary logError fallback={<ErrorMessage />}>
                        <Routes>
                            <Route
                                path={urls.oAuthLoginPath}
                                element={<LoggedOutPageRoute Component={OAuthLoginPage} />}
                            />
                            <Route path="/user/delete_account" element={<DeleteAccountPage />} />
                            <Route
                                path={`${urls.oldWebPlayerPath}/*`}
                                element={<OldWebPlayerRedirect />}
                            />

                            {freeWebPlayerRoutes()}
                        </Routes>
                    </ErrorBoundary>
                </AnalyticsContextProvider>
                {createPortal(<Tooltip id="react-tooltip" />, document.body)}
            </div>
        </ThemeProvider>
    );

    function freeWebPlayerRoutes() {
        return (
            <>
                <Route
                    element={
                        <LoggedInPageChrome>
                            <Outlet />
                        </LoggedInPageChrome>
                    }
                >
                    {/* Routes for logged in users, within the main application layout */}
                    {isLoggedIn && (
                        <>
                            <Route path={`${NavItems.PODCASTS.path}`} element={<PodcastsPage />} />
                            <Route
                                path={`${NavItems.NEW_RELEASES.path}`}
                                element={
                                    <FilterPage
                                        id="new_releases"
                                        eventSource="filters"
                                        autoplay={{ source: 'new_releases' }}
                                    />
                                }
                            />
                            <Route
                                path={`${NavItems.IN_PROGRESS.path}`}
                                element={
                                    <FilterPage
                                        id="in_progress"
                                        eventSource="filters"
                                        autoplay={{ source: 'in_progress' }}
                                    />
                                }
                            />
                            <Route
                                path={`${NavItems.HISTORY.path}`}
                                element={
                                    <FilterPage id="history" eventSource="listening_history" />
                                }
                            />
                            <Route
                                path={`${NavItems.STARRED.path}`}
                                element={
                                    <FilterPage
                                        id="starred"
                                        eventSource="starred"
                                        autoplay={{ source: 'starred' }}
                                    />
                                }
                            />
                            <Route path={`${NavItems.PROFILE.path}`} element={<ProfilePage />} />
                            <Route path={`${NavItems.STATS.path}`} element={<StatsPage />} />
                            <Route
                                path={`${NavItems.SETTINGS.path}/*`}
                                element={<SettingsPage />}
                            />
                            <Route
                                path={`${NavItems.UPLOADED_FILES.path}`}
                                element={<UploadedFilesPage />}
                            />
                        </>
                    )}

                    {/* Routes for subscribers, within the main application layout */}
                    {isSubscriber && (
                        <>
                            <Route
                                path={`${NavItems.PODCASTS.path}/folders/:folderUuid`}
                                element={<PodcastsPage />}
                            />
                            <Route
                                path={`${NavItems.BOOKMARKS.path}`}
                                element={<BookmarksPage />}
                            />
                            <Route path={`${NavItems.INVITE.path}`} element={<InvitePage />} />
                        </>
                    )}

                    {!isSubscriber && (
                        <Route
                            path={`${NavItems.PODCASTS.path}/folders/:folderUuid`}
                            element={<Navigate to={urls.startPath} />}
                        />
                    )}

                    {/* Routes for all users, within the main application layout */}
                    <Route path="/search" element={<SearchPage />} />
                    <Route
                        path={`${NavItems.PODCASTS.path}/:uuid/:episode`}
                        element={<EpisodePage />}
                    />
                    <Route path={`${NavItems.PODCASTS.path}/:uuid`} element={<PodcastPage />} />
                    <Route
                        path={`${NavItems.SETTINGS.path}/podcast/:uuid`}
                        element={<PodcastSettingsPage />}
                    />

                    <Route
                        path={`${NavItems.DISCOVER.path}/podcast/:uuid`}
                        element={<PodcastPage />}
                    />
                    <Route
                        path={`${NavItems.DISCOVER.path}/list/:listId`}
                        element={<PodcastListPage />}
                    />
                    <Route
                        path={`${NavItems.DISCOVER.path}/category/:categoryId`}
                        element={<CategoryPage />}
                    />
                    <Route path={`${NavItems.DISCOVER.path}`} element={<DiscoverPage />} />
                    <Route
                        path={`${NavItems.PODCASTS.path}/index`}
                        element={<Navigate to={NavItems.PODCASTS.path} />}
                    />
                </Route>

                {/* Routes for logged in users, outside of the main application layout */}
                {isLoggedIn && (
                    <>
                        <Route
                            path={urls.forgottenPasswordPath}
                            element={
                                <SignOutAndReload
                                    signOut={() => dispatch(fromUserActions.Actions.signOut())}
                                />
                            }
                        />
                        <Route
                            path={urls.resetPasswordPath}
                            element={
                                <SignOutAndReload
                                    signOut={() => dispatch(fromUserActions.Actions.signOut())}
                                />
                            }
                        />
                        <Route path={urls.welcomePath()} element={<WelcomePage />} />
                    </>
                )}
                {!isLoggedIn && (
                    <>
                        <Route
                            path={urls.forgottenPasswordPath}
                            element={
                                <LoggedOutPageRouteWithPromoChrome
                                    Component={PasswordForgottenPage}
                                    redirectToPodcast={redirectToPodcast}
                                />
                            }
                        />
                        <Route
                            path={urls.resetPasswordPath}
                            element={
                                <LoggedOutPageRouteWithPromoChrome
                                    Component={PasswordResetPage}
                                    redirectToPodcast={redirectToPodcast}
                                />
                            }
                        />
                        <Route
                            path="/token-login"
                            element={<LoggedOutPageRoute Component={OutsideAppAuthenticatorPage} />}
                        />
                    </>
                )}
                <Route
                    path={urls.startPath}
                    element={<Navigate replace to={urls.discoverPath} />}
                />

                <Route
                    path={urls.subscribePath()}
                    element={
                        <RequiresLoggedInUser>
                            <SubscribePage />
                        </RequiresLoggedInUser>
                    }
                />

                {/* We have a special register page for discounted logins */}
                {hasDiscount && (
                    <Route
                        path={urls.registerPath()}
                        element={<LoggedOutPageRoute Component={RegisterPage} />}
                    />
                )}
                {!hasDiscount && (
                    <Route
                        path={urls.registerPath()}
                        element={
                            <AuthenticationChrome>
                                <LoginPage allowSignup />
                            </AuthenticationChrome>
                        }
                    />
                )}
                <Route
                    path={urls.signInPath()}
                    element={
                        <AuthenticationChrome>
                            <LoginPage />
                        </AuthenticationChrome>
                    }
                />
                <Route path={urls.podcastSharePath} element={<PodcastShareLink />} />
                <Route
                    path={urls.episodeSharePath}
                    element={<EpisodeSharePage sanitisedSearch={getSanitisedSearch()} />}
                />
                <Route path={`${urls.trialRedeem}/:id`} element={<RedeemPromoPage />} />
                <Route path="/miniplayer" element={<MiniPlayerWindow />} />
                <Route
                    path="*"
                    element={
                        <Navigate
                            replace
                            to={
                                getRedirectQueryParam() ??
                                (!isSubscriber ? urls.discoverPath : urls.podcastsPath)
                            }
                        />
                    }
                />
            </>
        );
    }
};

function RequiresLoggedInUser({ children }: { children: React.ReactNode; redirect?: string }) {
    const isLoggedIn = useSelector(userIsLoggedIn);
    const redirect = useSearchParam('redirect');
    const variant = useSearchParam('variant');

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate replace to={urls.registerPath({ redirect, variant })} />
    );
}

function LoggedOutPageRoute({ Component }: { Component: React.ComponentType }) {
    return (
        <LoggedOutPageChrome>
            <Component />
        </LoggedOutPageChrome>
    );
}

function OldWebPlayerRedirect() {
    const location = useLocation();
    return (
        <Navigate
            to={{
                ...location,
                pathname: location.pathname.replace(urls.oldWebPlayerPath, '') || '/',
            }}
        />
    );
}

function SignOutAndReload({ signOut }: { signOut: () => void }) {
    signOut();
    window.location.reload();
    return null;
}

// Podcast share link from pca.st
function PodcastShareLink() {
    const location = useLocation();
    const { id } = qs.parse(location.search);
    return <Navigate to={`${NavItems.PODCASTS.path}/${id || ''}`} />;
}

// Episode share link from pca.st
function EpisodeSharePage({ sanitisedSearch }: { sanitisedSearch: string }) {
    const { podcast, episode, t } = qs.parse(sanitisedSearch);
    if (podcast && !episode) {
        return <Navigate to={`${NavItems.PODCASTS.path}/${podcast}`} />;
    }
    if (!podcast && !episode) {
        return <Navigate to={`${NavItems.PODCASTS.path}`} />;
    }

    const episodePath = t
        ? `${NavItems.PODCASTS.path}/${podcast}/${episode}?t=${t}`
        : `${NavItems.PODCASTS.path}/${podcast}/${episode}`;

    return <Navigate to={episodePath} />;
}

function LoggedOutPageRouteWithPromoChrome({
    Component,
    redirectToPodcast,
}: {
    Component: React.ComponentType;
    redirectToPodcast: {
        podcastUuid: string | undefined;
        episodeUuid: string | undefined;
        podcast?: Pick<Podcast, 'title' | 'author' | 'episodes'>;
        colors?: Pick<PodcastTintColors, 'background' | 'lightTint'>;
    };
}) {
    const { episodeUuid, podcastUuid, podcast, colors } = redirectToPodcast;

    const customPromo = podcastUuid ? (
        <PodcastPromo
            podcastUuid={podcastUuid}
            podcast={podcast}
            episodeUuid={episodeUuid}
            colors={colors}
        />
    ) : undefined;

    return (
        <LoggedOutPageChrome>
            <PromoChrome promo={customPromo}>
                <Component />
            </PromoChrome>
        </LoggedOutPageChrome>
    );
}

export const getPodcastFromRedirect = (url: string) => {
    const {
        query: { redirect },
    } = qs.parseUrl(url);

    if (!redirect) {
        return {};
    }

    const firstRedirectUrl = firstParamValue(redirect);

    if (!firstRedirectUrl) {
        return {};
    }

    const parsedRedirect = qs.parseUrl(firstRedirectUrl);

    if (parsedRedirect.url === urls.podcastSharePath) {
        return { podcastUuid: firstParamValue(parsedRedirect.query.id) };
    }

    if (parsedRedirect.url === urls.episodeSharePath) {
        return {
            podcastUuid: firstParamValue(parsedRedirect.query.podcast),
            episodeUuid: firstParamValue(parsedRedirect.query.episode),
        };
    }

    // Matches:
    // - /podcasts/{podcastUUID}
    // - /podcasts/{podcastUUID}
    // - /discover/podcast/{podcastUUID}/{episodeUUID}
    // - /discover/podcast/{podcastUUID}/{episodeUUID}
    const matches = parsedRedirect.url.match(
        /^(\/podcasts|\/discover\/podcast)\/([a-zA-Z0-9-]+)\/?([a-zA-Z0-9-]+)?$/,
    );

    if (matches) {
        return {
            podcastUuid: matches[2],
            episodeUuid: matches[3],
        };
    }

    return {};
};

export default App;
