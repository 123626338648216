import { Icon } from 'components/Icon';
import { ModalMonitor } from 'components/ModalMonitor';
import React from 'react';
import { FocusOn } from 'react-focus-on';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { POCKET_CASTS_SUPPORT } from '../../../model/external-links';
import * as fromUserActions from '../../../redux/actions/user.actions';
import { RootState } from '../../../redux/reducers';
import { getDebugInfo } from '../../../redux/reducers/selectors/user.selectors';
import { AppDispatch } from '../../../store';
import { Button } from '../../Button';
import { CloseButton, Switch } from '../../index';
import {
    Content,
    DebugLogIntro,
    DebugLogWrapper,
    SendLogsToggle,
    SupportPopupWrapper,
} from './SupportPopup.styled';

type Props = {
    open: boolean;
    debugInfo: string;
    sending: boolean;
    sent: boolean;
    sendFailed: boolean;

    // action dispatching
    closeSupport: () => void;
    sendSupportMessage: (message: string, reason: string, debug: string) => void;
};

type StateProps = {
    message: string;
    reason: string;
    sendDebugLog: boolean;
    showDebugLog: boolean;
};

class SupportPopup extends React.Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);
        this.state = {
            message: '',
            reason: '',
            sendDebugLog: true,
            showDebugLog: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.sent && !prevProps.sent) {
            // After a message is successfully sent, clear the text and reason
            this.setState({
                message: '',
                reason: '',
            });
        }
    }

    render() {
        const { sent, sendFailed, open } = this.props;
        const { showDebugLog } = this.state;
        return open ? (
            <SupportPopupWrapper
                isOpen={true}
                width={500}
                height={sendFailed ? 565 : 525}
                onClosed={this.close}
            >
                <ModalMonitor onCloseModals={this.props.closeSupport} />
                <Content $isBlurred={showDebugLog}>
                    <CloseButton onClick={this.close} />
                    {sent ? this.renderSent() : this.renderForm()}
                </Content>
                {showDebugLog && (
                    <FocusOn
                        enabled={true}
                        scrollLock={false}
                        noIsolation
                        onClickOutside={this.closeDebugLog}
                        onEscapeKey={this.closeDebugLog}
                    >
                        {this.renderDebugLog()}
                    </FocusOn>
                )}
            </SupportPopupWrapper>
        ) : null;
    }

    renderSent() {
        return (
            <div className="sent">
                <div className="mail-sent" />
                <h1>
                    <FormattedMessage id="thanks-for-your-feedback" />
                </h1>
            </div>
        );
    }

    renderForm() {
        const { sending, sendFailed } = this.props;
        const { message, reason, sendDebugLog } = this.state;

        return (
            <div className="support-form-wrapper">
                <h1>
                    <FormattedMessage id="send-feedback" />
                </h1>
                <p>
                    <FormattedMessage id="feedback-form-description" />
                </p>
                <div className="support-form">
                    <label className="reason">
                        <input
                            type="radio"
                            name="feature"
                            value="Feature Request"
                            onChange={this.onFeatureClick}
                            checked={reason === 'Feature Request'}
                        />{' '}
                        <FormattedMessage id="feature-request" />
                    </label>
                    <label className="reason">
                        <input
                            type="radio"
                            name="feature"
                            value="Bug Report"
                            onChange={this.onFeatureClick}
                            checked={reason === 'Bug Report'}
                        />{' '}
                        <FormattedMessage id="bug-report" />
                    </label>
                    <label className="reason">
                        <input
                            type="radio"
                            name="feature"
                            value="General Feedback"
                            onChange={this.onFeatureClick}
                            checked={reason === 'General Feedback'}
                        />{' '}
                        <FormattedMessage id="general-feedback" />
                    </label>
                </div>
                <textarea rows={8} onChange={this.updateMessage} value={message} />
                <SendLogsToggle>
                    <Button type="button" kind="text" onClick={this.showDebugLog}>
                        <FormattedMessage id="include-debug-logs" />
                        <Icon id="info-circle" size={18} />
                    </Button>
                    <Switch
                        onChange={() => {
                            this.setState({ sendDebugLog: !sendDebugLog });
                        }}
                        on={sendDebugLog}
                    />
                </SendLogsToggle>
                <Button width={200} loading={sending} disabled={sendFailed} onClick={this.send}>
                    <FormattedMessage id="send" />
                </Button>
                {sendFailed && (
                    <p className="send-failed">
                        <FormattedMessage
                            id="feedback-send-failed"
                            values={{
                                link: <a href={POCKET_CASTS_SUPPORT}>{POCKET_CASTS_SUPPORT}</a>,
                            }}
                        />
                    </p>
                )}
            </div>
        );
    }

    renderDebugLog = () => {
        return (
            <DebugLogWrapper>
                <DebugLogIntro>
                    <FormattedMessage id="debug-logs-explanation" />
                </DebugLogIntro>
                <textarea readOnly={true} value={this.props.debugInfo} />
                <Button
                    className="close-button"
                    type="button"
                    kind="text"
                    onClick={this.closeDebugLog}
                >
                    <Icon id="cancel" size={18} />
                </Button>
            </DebugLogWrapper>
        );
    };

    close = () => {
        if (this.state.showDebugLog) {
            return;
        }
        this.props.closeSupport();
    };

    showDebugLog = () => {
        this.setState({ showDebugLog: true });
    };

    closeDebugLog = () => {
        // We defer the setState until after this call stack, so that other click/keyboard handlers can finish executing
        // before state is updated. This helps, for example, so that pressing ESC will not close the overall popup when
        // the Debug Log is open.
        setTimeout(() => this.setState({ showDebugLog: false }), 0);
    };

    updateMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            message: event.target.value,
        });
    };

    onFeatureClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            reason: event.target.value,
        });
    };

    send = () => {
        this.props.sendSupportMessage(
            this.state.message,
            this.state.reason || 'Support',
            this.state.sendDebugLog ? this.props.debugInfo : '',
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    open: state.user.supportOpen,
    debugInfo: getDebugInfo(state),
    sending: state.user.supportSending,
    sent: state.user.supportSent,
    sendFailed: state.user.supportSendFailed,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    closeSupport: () => dispatch(fromUserActions.Actions.closeSupport()),
    sendSupportMessage: (message: string, reason: string, debug: string) =>
        dispatch(fromUserActions.Actions.sendSupportMessage(message, reason, debug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportPopup);
