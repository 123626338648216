import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    INTL_UPDATE = '@@intl/UPDATE',
}

export const Actions = {
    updateIntl: ({
        locale,
        formats,
        messages,
    }: {
        locale: string;
        messages: { [x: string]: string | null };
        formats?: { [x: string]: { [x: string]: string } };
    }) =>
        createAction(ActionTypes.INTL_UPDATE, {
            locale,
            formats,
            messages,
        }),
};

export type Actions = ActionsUnion<typeof Actions>;
