import { RouteMatchMonitor } from 'components/RouteMatchMonitor';
import React, { Component } from 'react';
import { clearTokenData } from 'services/auth';
import { InvalidGuestCodeModal } from '../LoggedInPageChrome/Modals/InvalidGuestCodeModal';
import { LoggedOutPageWrapper } from './LoggedOutPageChrome.styled';

type Props = {
    unAuthenticate: () => void;
    updateIntl: (locale: string) => void;
    shouldShowInvalidGuestCodeModal: boolean;
    children: React.ReactNode;
};

class LoggedOutPageChrome extends Component<Props> {
    componentDidMount() {
        // We're loading a logged-out page, let's just make sure we're not storing
        // sensitive user data in localStorage.
        clearTokenData();
        this.props.unAuthenticate();

        this.updateDocumentTagLanguage();
    }

    updateDocumentTagLanguage = () => {
        document.documentElement.lang = window.navigator.language;
        this.props.updateIntl(window.navigator.language);
    };

    render() {
        const { shouldShowInvalidGuestCodeModal } = this.props;
        return (
            <LoggedOutPageWrapper>
                {this.props.children}
                <RouteMatchMonitor />
                {shouldShowInvalidGuestCodeModal && <InvalidGuestCodeModal />}
            </LoggedOutPageWrapper>
        );
    }
}

export default LoggedOutPageChrome;
