import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { gridSize, visuallyHidden } from '../../../styles';
import { UnplayedBadge } from '../PodcastsPage.styled';

export const AssistiveText = styled.div`
    ${visuallyHidden}
`;

export const List = styled.ol<{ $isSorting: boolean }>`
    margin: 0;
    padding: 0;
    width: 100%;

    ${props => props.$isSorting && `&, ${RowLink} { cursor: grabbing; }`}
    ${props =>
        !props.$isSorting &&
        `
        ${RowLink}:hover,
        ${RowLink}:focus,
        ${RowLink}.is-dragging {
            ${Title} {
                color: ${props.theme.legacyScss['theme-text-link']};
            }
        }
    `}
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${gridSize * 2}px;
    padding-right: 60px;
    max-width: 100%;
`;

export const Subtitle = styled.div`
    white-space: nowrap;
    font-size: 14.4px;
    font-weight: 400;
    padding-top: 2px;
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ImageWrapper = styled.div`
    position: relative;
    width: 52px;
    flex-shrink: 0;
    ${UnplayedBadge} {
        width: 20px;
        height: 20px;
    }
`;

export const Title = styled.div`
    font-size: 17.28px;
    font-weight: 400;
    padding-bottom: 2px;
    color: ${props => props.theme.legacyScss['theme-text-primary']};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const RowWrapper = styled.li<{ $isDragging?: boolean }>`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    border: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-width: 1px 0;
    position: relative;
    ${props =>
        props.$isDragging &&
        css`
            z-index: 1000;
            background: ${props => props.theme.legacyScss['theme-background']};
        `}
`;

export const RowLink = styled(Link)<{ $isDragging: boolean }>`
    height: 68px;
    margin: 2px 0;
    z-index: 1;
    text-decoration: none;
    display: flex;
    align-items: center;
    background: ${props => props.theme.legacyScss['theme-background']};
    width: 100%;
    * {
        pointer-events: none;
    }
    ${props =>
        props.$isDragging &&
        css`
            z-index: 1000;
            pointer-events: none;
        `}
`;

export const RowHandle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    margin-left: 10px;
    color: ${props => props.theme.tokens['player-contrast-04']};
    svg {
        cursor: row-resize;
    }
`;
