import Gravatar from 'components/Gravatar';
import { SubscriptionTier } from 'model/types';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { patronColor, plusColor } from 'styles/colors';
import { WebPlayerTheme } from '../../../model/theme';
import { RoundedGravatar } from './DynamicAccountIcon.styled';
import { SimpleAccountIcon } from './SimpleAccountIcon';
import { UploadingFilesAccountIcon } from './UploadingFilesAccountIcon';
import { calculatePartialRingPath } from './helper';

type Props = {
    email?: string | null;
    percentDepleted: number;
    shouldShowBasicAccountIcon: boolean;
    small?: boolean;
    large?: boolean;
    isUploadingFiles?: boolean;
    hasUploadError?: boolean;
    uploadsAreAllComplete?: boolean;
    uploadProgressPercent?: number;
    tier?: SubscriptionTier;
    theme: WebPlayerTheme;
};

class DynamicAccountIcon extends Component<Props> {
    static defaultProps = {
        small: false,
        large: false,
    };

    getTierColor() {
        switch (this.props.tier) {
            case 'Patron':
                return patronColor;
            case 'Plus':
                return plusColor;
            default:
                return this.props.theme.legacyScss['theme-interactive-secondary'];
        }
    }

    renderLargeRing() {
        const { percentDepleted, shouldShowBasicAccountIcon } = this.props;
        const percent = shouldShowBasicAccountIcon ? 0 : percentDepleted;

        // This is to allow a percent of 0 and not have the SVG optimise away the ring render.
        const start = Math.max(3.6 * percent, 0.01);
        const ring = calculatePartialRingPath(24, 24, 22.5, start, 360);

        return (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={ring}
                stroke={this.getTierColor()}
                fill="none"
                strokeWidth="3"
            />
        );
    }

    renderLargeIcon() {
        return (
            <svg width="50" height="50" viewBox="0 0 48 48" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.2001 24C43.2001 34.6039 34.6039 43.2 24 43.2C13.3962 43.2 4.80005 34.6039 4.80005 24C4.80005 13.3961 13.3962 4.79999 24 4.79999C34.6039 4.79999 43.2001 13.3961 43.2001 24ZM24.4 19.5143C24.4 21.1711 23.0569 22.5143 21.4 22.5143C19.7432 22.5143 18.4 21.1711 18.4 19.5143C18.4 17.8574 19.7432 16.5143 21.4 16.5143C23.0569 16.5143 24.4 17.8574 24.4 19.5143ZM25.0832 23.4633C26.1396 22.4776 26.8 21.0731 26.8 19.5143C26.8 16.5319 24.3824 14.1143 21.4 14.1143C18.4177 14.1143 16 16.5319 16 19.5143C16 21.0731 16.6605 22.4776 17.7169 23.4633C14.356 24.896 12 28.23 12 32.1143C12 32.777 12.5373 33.3143 13.2 33.3143C13.8628 33.3143 14.4 32.777 14.4 32.1143C14.4 28.2483 17.5341 25.1143 21.4 25.1143C25.266 25.1143 28.4 28.2483 28.4 32.1143C28.4 32.777 28.9373 33.3143 29.6 33.3143C30.2628 33.3143 30.8001 32.777 30.8001 32.1143C30.8001 28.23 28.4441 24.896 25.0832 23.4633ZM34.4 20.4H37.9L37.3 22.8H34.4V26H32V22.8H28.5L29.1 20.4H32V17.2H34.4V20.4Z"
                    fill={this.getTierColor()}
                />
                {this.renderLargeRing()}
            </svg>
        );
    }

    renderGravatar() {
        const { email } = this.props;

        return (
            <RoundedGravatar width="50" height="50" viewBox="0 0 48 48" fill="none">
                <Gravatar
                    asSvgImage
                    email={email || ''}
                    fallback={
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M43.2001 24C43.2001 34.6039 34.6039 43.2 24 43.2C13.3962 43.2 4.80005 34.6039 4.80005 24C4.80005 13.3961 13.3962 4.79999 24 4.79999C34.6039 4.79999 43.2001 13.3961 43.2001 24ZM24.4 19.5143C24.4 21.1711 23.0569 22.5143 21.4 22.5143C19.7432 22.5143 18.4 21.1711 18.4 19.5143C18.4 17.8574 19.7432 16.5143 21.4 16.5143C23.0569 16.5143 24.4 17.8574 24.4 19.5143ZM25.0832 23.4633C26.1396 22.4776 26.8 21.0731 26.8 19.5143C26.8 16.5319 24.3824 14.1143 21.4 14.1143C18.4177 14.1143 16 16.5319 16 19.5143C16 21.0731 16.6605 22.4776 17.7169 23.4633C14.356 24.896 12 28.23 12 32.1143C12 32.777 12.5373 33.3143 13.2 33.3143C13.8628 33.3143 14.4 32.777 14.4 32.1143C14.4 28.2483 17.5341 25.1143 21.4 25.1143C25.266 25.1143 28.4 28.2483 28.4 32.1143C28.4 32.777 28.9373 33.3143 29.6 33.3143C30.2628 33.3143 30.8001 32.777 30.8001 32.1143C30.8001 28.23 28.4441 24.896 25.0832 23.4633ZM34.4 20.4H37.9L37.3 22.8H34.4V26H32V22.8H28.5L29.1 20.4H32V17.2H34.4V20.4Z"
                            fill={this.getTierColor()}
                        />
                    }
                    size={48}
                />
                {this.renderLargeRing()}
            </RoundedGravatar>
        );
    }

    renderSmallRing() {
        const { percentDepleted: percent } = this.props;

        // This is to allow a percent of 0 and not have the SVG optimise away the ring render.
        const start = Math.max(3.6 * percent, 0.01);
        const ring = calculatePartialRingPath(15, 15, 14, start, 360);

        return (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={ring}
                stroke={this.getTierColor()}
                fill="none"
                strokeWidth="2"
            />
        );
    }

    renderSmallIcon() {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M22.4543 23.0892C20.4943 24.8962 17.8761 26 15 26C12.1242 26 9.50614 24.8964 7.54623 23.0897C8.71029 20.1104 11.6089 18 15.0004 18C18.3917 18 21.2901 20.1102 22.4543 23.0892Z"
                    fill={this.getTierColor()}
                />
                <path
                    d="M23.9093 21.4531C22.7713 19.228 20.8243 17.4853 18.4561 16.6132C19.4075 15.7029 20 14.4207 20 13C20 10.2386 17.7614 8 15 8C12.2386 8 10 10.2386 10 13C10 14.4208 10.5926 15.7031 11.5441 16.6134C9.17598 17.4857 7.22899 19.2285 6.09118 21.4537C4.77571 19.641 4 17.4111 4 15C4 8.92487 8.92487 4 15 4C21.0751 4 26 8.92487 26 15C26 17.4108 25.2245 19.6405 23.9093 21.4531Z"
                    fill={this.getTierColor()}
                />
                <path
                    d="M15 16C16.6569 16 18 14.6569 18 13C18 11.3431 16.6569 10 15 10C13.3431 10 12 11.3431 12 13C12 14.6569 13.3431 16 15 16Z"
                    fill={this.getTierColor()}
                />
                {this.renderSmallRing()}
            </svg>
        );
    }

    render() {
        const {
            large,
            isUploadingFiles,
            hasUploadError,
            uploadsAreAllComplete,
            uploadProgressPercent,
            shouldShowBasicAccountIcon,
            email,
        } = this.props;

        if (email) {
            return this.renderGravatar();
        }

        if (large) {
            return this.renderLargeIcon();
        }

        if (isUploadingFiles) {
            return (
                <UploadingFilesAccountIcon
                    uploadProgressPercent={uploadProgressPercent ?? 0}
                    uploadsAreAllComplete={uploadsAreAllComplete}
                    hasUploadError={hasUploadError}
                />
            );
        }

        if (!shouldShowBasicAccountIcon) {
            return this.renderSmallIcon();
        }

        // Show the basic account icon by default
        return <SimpleAccountIcon />;
    }
}

export default withTheme(DynamicAccountIcon) as React.ComponentType<Omit<Props, 'theme'>>;
