import qs from 'query-string';

const withQueryParams = (path: string, queryParams: Record<string, any>) => {
    const queryString = qs.stringify(queryParams);
    if (queryString) {
        return `${path}?${queryString}`;
    }
    return path;
};

type RedirectParam = {
    redirect?: string | null;
};

type VariantParam = {
    variant?: string | null;
};

const urls = {
    /**
     * The web player was previously hosted under the /web path. With the TTF work, the web player
     * now exists at the root of its own domain but people may have old links to /web/* URLs.
     * We'll keep redirects around for these for a while until we indicate that old links will die.
     */
    oldWebPlayerPath: '/web',
    startPath: '/',
    signInPath: ({ redirect }: RedirectParam & VariantParam = {}) =>
        withQueryParams('/user/login', { redirect }),
    registerPath: ({ redirect, variant }: RedirectParam & VariantParam = {}) =>
        withQueryParams(`/user/register`, { redirect, variant }),
    oAuthLoginPath: '/oauth_login',
    trialRedeem: '/redeem',
    forgottenPasswordPath: '/user/forgotten_password',
    resetPasswordPath: '/account/reset_password',
    // This path is uncharacteristically prefixed with /user, but is unrelated to normal /user paths.
    episodeSharePath: '/user/share',
    podcastSharePath: `/podcasts/share`,
    podcastsPath: '/podcasts',
    discoverPath: '/discover',
    newReleasesPath: '/new-releases',
    inProgressPath: '/in-progress',
    starredPath: '/starred',
    bookmarksPath: '/bookmarks',
    historyPath: '/history',
    settingsPath: '/settings',
    profilePath: '/profile',
    statsPath: '/stats',
    uploadedFilesPath: '/uploaded-files',
    invitePath: '/invite',
    welcomePath: ({ redirect }: RedirectParam = {}) => withQueryParams('/welcome', { redirect }),
    subscribePath: ({ redirect, variant }: RedirectParam & VariantParam = {}) =>
        withQueryParams('/subscribe', { redirect, variant }),
    episodePath: (podcastUuid: string, episodeUuid: string) =>
        `/podcasts/${podcastUuid}/${episodeUuid}`,

    plansLandingPage: 'https://pocketcasts.com/plans',

    searchPath: (term: string, expanded = false) =>
        `/search?q=${encodeURIComponent(term)}${expanded ? '&expanded=true' : ''}`,
};

export default urls;
