import { EpisodeImage } from 'components/EpisodeImage';
import { ModalMonitor } from 'components/ModalMonitor';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { EpisodeTypeText } from 'components/format/EpisodeTypeText';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { getSeekToSecondsFromHref } from 'helper/TimeHelper';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { FocusOn } from 'react-focus-on';
import { Portal } from 'react-portal';
import { useLocation } from 'react-router';
import { NavigationItems } from '../../../helper/NavigationHelper';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../model/uploaded-files';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import { CloseButton } from '../../buttons/CloseButton';
import { ShowNotes } from './ShowNotes';

import {
    EpisodeDialog,
    EpisodePopupContainer,
    EpisodeSeason,
    EpisodeTitle,
    Header,
    ImageLink,
    PodcastLink,
    ScrollBox,
    TitleBox,
} from './EpisodePopup.styled';
import { EpisodePopupToolbar } from './EpisodePopupToolbar';

export function EpisodePopup() {
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();
    const dispatch = useDispatch();
    const { openEpisodeEvent } = useAnalyticsContext();
    const { episode, podcastTitle, colors, unplayableFile } = useSelector(state => {
        const { episode } = state;
        return {
            episode,
            podcastTitle: state.podcasts.uuidToPodcast[episode.podcastUuid]?.title,
            colors: state.podcasts.uuidToColors[episode.podcastUuid],
            unplayableFile: state.player.unplayableFile,
        };
    });

    const isUploadedFile = episode?.podcastUuid === UPLOADED_FILES_PODCAST_UUID;

    useEffect(() => {
        if (episode?.uuid) {
            openEpisodeEvent({ podcastUuid: episode.podcastUuid, episodeUuid: episode.uuid });
        }
    }, [openEpisodeEvent, episode?.podcastUuid, episode?.uuid]);

    const closePopup = useCallback(
        () => dispatch(fromEpisodeActions.Actions.closeEpisode()),
        [dispatch],
    );

    const location = useLocation();

    const handleShowNotesClick: MouseEventHandler = e => {
        if (e.target instanceof Element && e.target.closest?.('a')) {
            const seekTo = getSeekToSecondsFromHref(e.target.getAttribute('href'));

            if (seekTo !== null) {
                // Prevent seeking past the last 5 seconds of the episode
                const maxSeekTo = episode.duration - 5;

                e.preventDefault();

                dispatch(
                    fromPlayerActions.Actions.playEpisode(
                        episode.uuid,
                        episode.podcastUuid,
                        { eventSource: episode.eventSource },
                        { seekTo: Math.min(seekTo, maxSeekTo) },
                    ),
                );
            }

            recordEvent('episode_detail_show_notes_link_tapped', {
                episode_uuid: episode.uuid,
                source: episode.eventSource,
            });
        }
    };

    const podcastUrl =
        episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID
            ? `${NavigationItems.UPLOADED_FILES.path}`
            : `${NavigationItems.PODCASTS.path}/${episode.podcastUuid}`;

    return (
        <Portal node={document && document.getElementById('modal-root')}>
            {isUploadedFile ? (
                <>
                    <TrackOnMount event="user_file_detail_shown" />
                    <TrackOnUnmount event="user_file_detail_dismissed" />
                </>
            ) : (
                <>
                    <TrackOnMount
                        event="episode_detail_shown"
                        properties={{ source: episode.eventSource }}
                    />
                    <TrackOnUnmount
                        event="episode_detail_dismissed"
                        properties={{ source: episode.eventSource }}
                    />
                </>
            )}
            <ModalMonitor onCloseModals={closePopup} />
            <EpisodePopupContainer $colors={colors}>
                <FocusOn
                    scrollLock={false}
                    noIsolation
                    onEscapeKey={closePopup}
                    onClickOutside={closePopup}
                    style={{ maxHeight: '100%' }}
                >
                    <EpisodeDialog
                        aria-labelledby="dialog_title"
                        role="dialog"
                        aria-modal="true"
                        aria-label={episode.title}
                    >
                        <CloseButton onClick={closePopup} />
                        <Header>
                            <ImageLink to={podcastUrl}>
                                <EpisodeImage episode={episode} borderRadius={8} />
                            </ImageLink>
                            <TitleBox className="titleBox">
                                <PodcastLink
                                    to={podcastUrl}
                                    state={{ curatedListId: location.state?.curatedListId }}
                                    data-testid="podcast-title"
                                    onClick={() => {
                                        recordEvent('episode_detail_podcast_name_tapped', {
                                            source: episode.eventSource,
                                        });
                                    }}
                                >
                                    {isUploadedFile ? formatMessage('uploaded-file') : podcastTitle}
                                </PodcastLink>
                                <EpisodeTitle
                                    $titleLength={episode.title?.length}
                                    id="dialog_title"
                                >
                                    {episode.title}
                                </EpisodeTitle>
                                <EpisodeSeason>
                                    <EpisodeTypeText
                                        season={episode.season}
                                        number={episode.number}
                                    />
                                </EpisodeSeason>
                            </TitleBox>
                        </Header>

                        {!isUploadedFile && (
                            <EpisodePopupToolbar
                                onClose={closePopup}
                                episode={episode}
                                isUploadedFile={isUploadedFile}
                                showPlayButton
                            />
                        )}

                        {isUploadedFile && !unplayableFile && (
                            <EpisodePopupToolbar
                                onClose={closePopup}
                                episode={episode}
                                isUploadedFile={isUploadedFile}
                                showPlayButton
                            />
                        )}

                        {!isUploadedFile && (
                            <ScrollBox onClick={handleShowNotesClick}>
                                <ShowNotes episodeUuid={episode.uuid} />
                            </ScrollBox>
                        )}
                    </EpisodeDialog>
                </FocusOn>
            </EpisodePopupContainer>
        </Portal>
    );
}

export default EpisodePopup;
