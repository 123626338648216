import { chain } from 'icepick';
import { ContentSpec, DiscoverCategory, ListData } from 'model/types';
import { ActionTypes, Actions } from '../actions/discover.actions';

// eslint-disable-next-line
type TodoFixmeType = any;

export interface DiscoverState {
    lists: Record<string, ListData>;
    categories: Record<string, DiscoverCategory>;
    contentSpec?: ContentSpec;
}

export const INITIAL_STATE: DiscoverState = {
    lists: {},
    categories: {},
};

export const updateList = (state: DiscoverState, name: string, list: ListData) => {
    return chain(state)
        .setIn(['lists'], { ...state.lists, [name]: list })
        .value();
};

export default (state = INITIAL_STATE, action: Actions): DiscoverState => {
    switch (action.type) {
        case ActionTypes.SET_DISCOVER_CONTENT:
            return {
                ...state,
                contentSpec: action.payload,
            };
        case ActionTypes.OPEN_DISCOVER_LIST_SUCCESS:
            return updateList(state, action.payload.name, action.payload.list);
        case ActionTypes.CLEAR_CATEGORY:
            return {
                ...state,
                categories: {
                    ...(state.categories as { [key: string]: DiscoverCategory }),
                    [action.payload.id]: undefined,
                },
            };
        case ActionTypes.OPEN_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categories: {
                    ...(state.categories as { [key: string]: DiscoverCategory }),
                    [action.payload.name]: {
                        id: action.payload.name,
                        title: action.payload.title,
                        podcasts: action.payload.list,
                        region: action.payload.region,
                    },
                },
            };
        default:
            return state;
    }
};
