import useTracks from 'hooks/useTracks';
import { FilterId } from 'model/types';
import { UPLOADED_FILES_PODCAST_UUID } from 'model/uploaded-files';
import React, { useCallback, useContext } from 'react';
import { Location, useLocation } from 'react-router';
import { AnalyticsEvents, logEvent } from '../helper/ListAnalyticsHelper';

export type AnalyticsContextProps = {
    discoverEpisodePlayEvent: (podcastUuid: string, curatedListId?: string) => void;
    discoverEpisodeTapEvent: (
        curatedListId: string,
        podcastUuid: string,
        episodeUuid: string,
    ) => void;
    discoverPodcastSubscribeEvent: (curatedListId?: string, podcastUuid?: string) => void;
    discoverPodcastPageSubscribeEvent: (podcastUuid?: string) => void;
    discoverPodcastTapEvent: (curatedListId?: string, podcastUuid?: string) => void;
    discoverOpenEvent: () => void;
    discoverListImpressionEvent: (curatedListId: string) => void;
    discoverShowAllClickedEvent: (curatedListId: string) => void;
    discoverCategoryPageOpen: (categoryId: string | number) => void;
    discoverCategoryOpen: (categoryId: string | number, region: string) => void;
    podcastsWebOpenEvent: () => void;
    discoverWebOpenEvent: () => void;
    filterWebOpenEvent: (filterId: FilterId) => void;
    filesWebOpenEvent: () => void;
    profileMenuWebOpenEvent: () => void;
    openUpNextEvent: () => void;
    openPodcastEvent: (podcastUuid: string) => void;
    openEpisodeEvent: ({
        episodeUuid,
        podcastUuid,
    }: {
        episodeUuid: string;
        podcastUuid: string;
    }) => void;
};

export const AnalyticsContext = React.createContext({} as AnalyticsContextProps);

type AnalyticsContextProviderProps = { children: React.ReactNode };

export function AnalyticsContextProvider({ children }: AnalyticsContextProviderProps) {
    const { recordEvent } = useTracks();
    const location = useLocation();

    const getReferringDiscoverListId = useCallback(
        (location: Location) => location?.state?.curatedListId,
        [],
    );

    const discoverEpisodePlayEvent = useCallback(
        (podcastUuid: string, curatedListId?: string) => {
            const listId = getReferringDiscoverListId(location) || curatedListId;
            if (listId) {
                logEvent(AnalyticsEvents.DiscoverEpisodePlay, {
                    list_id: listId,
                    podcast_uuid: podcastUuid,
                });
                recordEvent('discover_list_episode_play', {
                    list_id: listId,
                    podcast_uuid: podcastUuid,
                });
            }
        },
        [location, recordEvent],
    );

    const discoverPodcastSubscribeEvent = useCallback(
        (curatedListId?: string, podcastUuid?: string) => {
            if (curatedListId && podcastUuid) {
                logEvent(AnalyticsEvents.DiscoverPodcastSubscribe, {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                });
                recordEvent('discover_list_podcast_subscribed', {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                });
            }
        },
        [recordEvent],
    );

    const discoverPodcastPageSubscribeEvent = useCallback(
        (podcastUuid?: string) => {
            const curatedListId = getReferringDiscoverListId(location);
            if (curatedListId) {
                discoverPodcastSubscribeEvent(curatedListId, podcastUuid);
            }
        },
        [location],
    );

    const discoverPodcastTapEvent = useCallback(
        (curatedListId?: string, podcastUuid?: string) => {
            if (curatedListId && podcastUuid) {
                logEvent(AnalyticsEvents.DiscoverListPodcastTap, {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                });
                recordEvent('discover_list_podcast_tapped', {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                });
            }
        },
        [recordEvent],
    );

    const discoverEpisodeTapEvent = useCallback(
        (curatedListId: string, podcastUuid: string, episodeUuid: string) => {
            if (curatedListId && podcastUuid) {
                logEvent(AnalyticsEvents.DiscoverListEpisodeTap, {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                    episode_uuid: episodeUuid,
                });
                recordEvent('discover_list_episode_tapped', {
                    list_id: curatedListId,
                    podcast_uuid: podcastUuid,
                    episode_uuid: episodeUuid,
                });
            }
        },
        [recordEvent],
    );

    const discoverCategoryOpen = useCallback((categoryId?: string | number, region?: string) => {
        if (categoryId != null && region) {
            logEvent(AnalyticsEvents.DiscoverCategoryOpen, {
                id: categoryId,
                region,
            });
        }
    }, []);

    const discoverCategoryPageOpen = useCallback((categoryId: string | number) => {
        if (categoryId != null) {
            logEvent(AnalyticsEvents.DiscoverCategoryPageOpen + categoryId);
        }
    }, []);

    const discoverOpenEvent = useCallback(() => {
        logEvent(AnalyticsEvents.DiscoverOpen);
    }, []);

    const discoverShowAllClickedEvent = useCallback(
        (curatedListId: string) => {
            if (curatedListId) {
                logEvent(AnalyticsEvents.DiscoverShowAll, { list_id: curatedListId });
                recordEvent('discover_list_show_all_tapped', { list_id: curatedListId });
            }
        },
        [recordEvent],
    );

    const discoverListImpressionEvent = useCallback(
        (curatedListId: string) => {
            if (curatedListId) {
                logEvent(AnalyticsEvents.DiscoverListImpression, { list_id: curatedListId });
                recordEvent('discover_list_impression', { list_id: curatedListId });
            }
        },
        [recordEvent],
    );

    const podcastsWebOpenEvent = useCallback(() => {
        logEvent(AnalyticsEvents.PodcastsWebOpen);
    }, []);

    const discoverWebOpenEvent = useCallback(() => {
        logEvent(AnalyticsEvents.DiscoverWebOpen);
    }, []);

    const filterWebOpenEvent = useCallback((filterId: FilterId) => {
        if (filterId === 'new_releases') {
            logEvent(AnalyticsEvents.NewReleasesWebOpen);
        } else if (filterId === 'in_progress') {
            logEvent(AnalyticsEvents.InProgressWebOpen);
        } else if (filterId === 'starred') {
            logEvent(AnalyticsEvents.StarredWebOpen);
        }
    }, []);

    const filesWebOpenEvent = useCallback(() => {
        logEvent(AnalyticsEvents.FilesWebOpen);
    }, []);

    const profileMenuWebOpenEvent = useCallback(() => {
        logEvent(AnalyticsEvents.ProfileMenuWebOpen);
    }, []);

    const openUpNextEvent = useCallback(() => {
        logEvent(AnalyticsEvents.UpNextOpen);
    }, []);

    const openPodcastEvent = useCallback((podcastUuid: string) => {
        logEvent(AnalyticsEvents.PodcastOpen, { podcastUuid });
    }, []);

    const openEpisodeEvent = useCallback(
        ({ episodeUuid, podcastUuid }: { episodeUuid: string; podcastUuid: string }) => {
            if (episodeUuid && podcastUuid && podcastUuid !== UPLOADED_FILES_PODCAST_UUID) {
                logEvent(AnalyticsEvents.EpisodeOpen, { episodeUuid, podcastUuid });
            }
        },
        [],
    );

    return (
        <AnalyticsContext.Provider
            value={{
                discoverEpisodePlayEvent,
                discoverEpisodeTapEvent,
                discoverPodcastSubscribeEvent,
                discoverPodcastPageSubscribeEvent,
                discoverPodcastTapEvent,
                discoverOpenEvent,
                discoverListImpressionEvent,
                discoverShowAllClickedEvent,
                discoverCategoryPageOpen,
                discoverCategoryOpen,
                podcastsWebOpenEvent,
                discoverWebOpenEvent,
                filterWebOpenEvent,
                filesWebOpenEvent,
                profileMenuWebOpenEvent,
                openUpNextEvent,
                openPodcastEvent,
                openEpisodeEvent,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
}

// HOC for class components

/**
 *
 * Some notes on withAnalyticsContext types:
 *
 * (a) The comma in <P,> is only needed to prevents the TS Generic <P> from being interpreted
 *     as a JSX tag. It has no functional purpose.
 *
 * (b) The returned component omits props that are already provided by the HOC. This was,
 *     <MyComponent> can require `discoverOpenEvent` as a prop, but when wrapped with the HOC
 *     you don't need to provide that prop from other components.
 *
 */
export const withAnalyticsContext =
    <P,>(
        // (a) --------------------------^
        Component: React.ComponentType<any>,
    ): React.FunctionComponent<Omit<P, keyof AnalyticsContextProps>> =>
    (
        // (b) -----------------^
        props,
    ) => (
        <AnalyticsContext.Consumer>
            {context => <Component {...(props as P)} {...context} />}
        </AnalyticsContext.Consumer>
    );

// Hook for function components
export function useAnalyticsContext() {
    return useContext(AnalyticsContext);
}
