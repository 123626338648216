import { TrackOnMount } from 'components/Tracks';
import { MCStatsGroup, bumpStat } from 'helper/MCStatsHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { Podcast, PodcastTintColors } from 'model/types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PlanFeatureList from '../PlanFeatureList';
import {
    BlurryBackground,
    Content,
    PlayPauseButton,
    PodcastImage,
    PodcastTitle,
    Preview,
    Text,
    Wrapper,
} from './PodcastPromo.styled';

// Whether this promo is for a full podcast or a single episode
type ShareType = 'podcast' | 'episode';

export type Props = {
    podcastUuid: string;
    podcast?: Pick<Podcast, 'title' | 'author' | 'episodes'>;
    episodeUuid?: string;
    colors?: Pick<PodcastTintColors, 'background' | 'lightTint'>;
};

const Player = ({ episodeUrl, shareType }: { episodeUrl: string; shareType: ShareType }) => {
    const { recordEvent } = useTracks();
    const formatMessage = useFormatMessage();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);

    const interval = useRef<ReturnType<typeof setInterval>>();
    const msPlayed = useRef<number>(0);
    const lastRecordedTime = useRef<number | null>(null);

    useEffect(() => {
        const audio = audioRef.current;
        const stopPlaying = () => setIsPlaying(false);
        audio?.addEventListener('ended', stopPlaying);
        return () => {
            audio?.removeEventListener('ended', stopPlaying);
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, []);

    const updateTimePlayed = () => {
        if (lastRecordedTime.current) {
            const now = Date.now();
            const timePlayed = now - lastRecordedTime.current;
            msPlayed.current += timePlayed;
            lastRecordedTime.current = now;
        }
    };

    const saveStats = () => {
        updateTimePlayed();
        if (msPlayed.current < 1000) {
            return;
        }

        const secondsPlayed = Math.floor(msPlayed.current / 1000);
        bumpStat(MCStatsGroup.PODCAST_PROMO_SECONDS_PLAYED, 'player', secondsPlayed.toString());

        msPlayed.current -= secondsPlayed * 1000;
    };

    useEffect(() => {
        if (isPlaying) {
            lastRecordedTime.current = Date.now();
            if (!interval.current) {
                interval.current = setInterval(() => {
                    saveStats();
                }, 5000);
            }
        } else {
            updateTimePlayed();
            lastRecordedTime.current = null;
        }
    }, [isPlaying]);

    const handlePlayPauseClick = () => {
        if (isPlaying) {
            audioRef.current?.pause();
            recordEvent('promo_chrome_podcast_pause_button_tapped', { share_type: shareType });
        } else {
            audioRef.current?.play();
            recordEvent('promo_chrome_podcast_play_button_tapped', { share_type: shareType });
        }

        setIsPlaying(!isPlaying);
    };

    return (
        <>
            <PlayPauseButton onClick={handlePlayPauseClick}>
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="white"
                    aria-label={formatMessage(isPlaying ? 'pause' : 'play')}
                >
                    {isPlaying ? (
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM27 16C27 14.8954 27.8954 14 29 14H31C32.1046 14 33 14.8954 33 16V32C33 33.1046 32.1046 34 31 34H29C27.8954 34 27 33.1046 27 32V16ZM17 14C15.8954 14 15 14.8954 15 16V32C15 33.1046 15.8954 34 17 34H19C20.1046 34 21 33.1046 21 32V16C21 14.8954 20.1046 14 19 14H17Z"
                        />
                    ) : (
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM33.0709 22.6376L19.7851 14.4082C19.5459 14.2601 19.2723 14.1818 18.9934 14.1818C18.1435 14.1818 17.4545 14.8931 17.4545 15.7704V32.2293C17.4545 32.5173 17.5303 32.7998 17.6738 33.0467C18.1111 33.799 19.0563 34.043 19.7851 33.5916L33.0709 25.3621C33.2874 25.228 33.4687 25.0408 33.5987 24.8172C34.0359 24.0649 33.7996 23.0891 33.0709 22.6376Z"
                        />
                    )}
                </svg>
            </PlayPauseButton>
            <audio src={episodeUrl} ref={audioRef} />
        </>
    );
};

const PodcastPromo = ({ podcastUuid, podcast, episodeUuid, colors }: Props) => {
    const episode =
        podcast?.episodes.find(episode => episode.uuid === episodeUuid) ?? podcast?.episodes[0];
    const episodeUrl = episode?.url;

    const shareType = episodeUuid ? 'episode' : 'podcast';

    return (
        <Wrapper>
            <TrackOnMount
                event="promo_chrome_shown"
                properties={{ variant: 'podcast_promo', share_type: shareType }}
            />
            {colors && <BlurryBackground color={colors.background} highlight={colors.lightTint} />}
            <Content>
                <Preview>
                    <PodcastImage uuid={podcastUuid} />
                    <PodcastTitle hasContent={!!podcast}>
                        <h1>{podcast?.title}</h1>
                        <span>{podcast?.author}</span>
                    </PodcastTitle>
                    {episodeUrl && <Player episodeUrl={episodeUrl} shareType={shareType} />}
                </Preview>

                <Text>
                    <h1>
                        <FormattedMessage id="everything-you-love" />
                    </h1>
                    <p>
                        <FormattedMessage id="subscribe-for-exclusive-access" />
                    </p>
                    <PlanFeatureList tier="Plus" plan="plus-yearly" />
                </Text>
            </Content>
        </Wrapper>
    );
};

export default PodcastPromo;
