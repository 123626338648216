import { connect } from 'react-redux';
import { normalisedUpdateIntlAction } from 'translations';
import * as fromUserActions from '../../redux/actions/user.actions';
import { RootState } from '../../redux/reducers';
import { AppDispatch } from '../../store';
import LoggedOutPageChrome from './LoggedOutPageChrome';

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
    shouldShowInvalidGuestCodeModal: state.subscription.modals.showInvalidGuestCodeModal,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    unAuthenticate: () => dispatch(fromUserActions.Actions.unAuthenticate()),
    updateIntl: (locale: string) => dispatch(normalisedUpdateIntlAction(locale)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(LoggedOutPageChrome);

export { ConnectedComponent as LoggedOutPageChrome };
