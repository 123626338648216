import { PlayingStatus } from 'helper/PlayingStatus';
import useFormatMessage from 'hooks/useFormatMessage';
import { TodoFixmeMigrationType, TracksProperties } from 'model/types';
import React, { useCallback } from 'react';

import FeatureLock from '../../../../components/FeatureLock';
import { MoreMenuImageTextOption, StyledPopupMenu } from './MoreActionsMenu.styled';

interface Props {
    episodeTitle: string;
    episodeUuid: string;
    podcastUuid: string;
    episodeDuration: number;
    audioUrl: string;
    isEpisodeStarred: boolean;
    isUploadedFile: boolean;
    deleteFile: (episodeUuid: string, episodeTitle: string) => void;
    starEpisode: (episodeUuid: string, podcastUuid: string) => void;
    unstarEpisode: (episodeUuid: string, podcastUuid: string) => void;
    markAsPlayed: (episodeUuid: string, podcastUuid: string) => void;
    requestUpdateFile: (episodeUuid: string, data: TodoFixmeMigrationType) => void;
    recordEvent: (eventName: string, data?: TracksProperties) => void;
    openShare: (
        episodeUuid: string,
        podcastUuid: string,
        title: string,
        duration: number,
        audioUrl: string,
    ) => void;
    archive: (episodeUuid: string, podcastUuid: string) => void;
}

export default function MoreActionsMenu({
    episodeTitle,
    episodeDuration,
    episodeUuid,
    podcastUuid,
    isEpisodeStarred,
    audioUrl,
    isUploadedFile,
    starEpisode,
    unstarEpisode,
    recordEvent,
    markAsPlayed,
    openShare,
    requestUpdateFile,
    deleteFile,
    archive,
}: Props) {
    const formatMessage = useFormatMessage();

    const onMarkAsPlayedClick = useCallback(() => {
        if (isUploadedFile) {
            requestUpdateFile(episodeUuid, {
                playingStatus: PlayingStatus.COMPLETED,
            });
            recordEvent('user_file_option_tapped', {
                option: 'mark_played',
            });
        } else {
            markAsPlayed(episodeUuid, podcastUuid);
        }
    }, [episodeUuid, isUploadedFile, markAsPlayed, podcastUuid, recordEvent, requestUpdateFile]);

    const onStarEpisodeClick = useCallback(() => {
        if (isEpisodeStarred) {
            unstarEpisode(episodeUuid, podcastUuid);
        } else {
            starEpisode(episodeUuid, podcastUuid);
        }
    }, [episodeUuid, isEpisodeStarred, podcastUuid, starEpisode, unstarEpisode]);

    const offsetTop = isUploadedFile ? -145 : -270;

    return (
        <StyledPopupMenu
            id="more-actions-menu"
            offsets={{ top: offsetTop, left: 200 }}
            horizontalPosition="center"
            menuTitle={formatMessage('more-actions')}
            aria-label={formatMessage('more-actions')}
            itemClassName="more-actions-menu-item"
        >
            {!isUploadedFile && (
                <MoreMenuImageTextOption
                    icon="share-sm"
                    text={formatMessage('player-share-episode')}
                    onClick={() =>
                        openShare(episodeUuid, podcastUuid, episodeTitle, episodeDuration, audioUrl)
                    }
                />
            )}
            <FeatureLock source="more_menu_mark_as_played">
                <MoreMenuImageTextOption
                    icon="mark-as-played"
                    iconSize={16}
                    text={formatMessage('mark-as-played')}
                    onClick={onMarkAsPlayedClick}
                />
            </FeatureLock>
            {!isUploadedFile && (
                <FeatureLock source="more_menu_archive">
                    <MoreMenuImageTextOption
                        icon="archive-sm"
                        text={formatMessage('archive')}
                        onClick={() => archive(episodeUuid, podcastUuid)}
                    />
                </FeatureLock>
            )}
            {!isUploadedFile && (
                <FeatureLock
                    title={formatMessage('feature-starred-unavailable-title')}
                    description={formatMessage('feature-starred-unavailable-description')}
                    source="more_menu_star"
                >
                    <MoreMenuImageTextOption
                        icon={isEpisodeStarred ? 'star-full-sm' : 'star-empty-sm'}
                        text={
                            isEpisodeStarred
                                ? formatMessage('player-unstar-episode')
                                : formatMessage('player-star-episode')
                        }
                        onClick={onStarEpisodeClick}
                    />
                </FeatureLock>
            )}
            {isUploadedFile && (
                <MoreMenuImageTextOption
                    icon="delete"
                    iconSize={16}
                    text={formatMessage('delete')}
                    onClick={() => deleteFile(episodeUuid, episodeTitle)}
                />
            )}
        </StyledPopupMenu>
    );
}
