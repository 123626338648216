import { Icon } from 'components/Icon';
import QRCodeSrcWhite from 'components/PlanSelector/mobile-download-qr-white.png';
import QRCodeSrc from 'components/PlanSelector/mobile-download-qr.png';
import { MarketingRegistrationToggle } from 'components/form/MarketingRegistrationToggle';
import { isElectronApp, isMac, isMacApp, isMobile, isWindows, isWindowsApp } from 'helper/Browser';
import useTracks from 'hooks/useTracks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Logo } from '../../components/Logo';
import { isShowingLightTheme } from '../../redux/reducers/selectors';
import {
    Bubble,
    BubbleLink,
    NewsletterText,
    QRCodeWrapper,
    ThankYouHeading,
    ThankYouWrapper,
} from './WelcomePage.styled';

const DesktopAppBubble = () => {
    const { recordEvent } = useTracks();

    if (isMac() && !isMacApp() && !isElectronApp()) {
        return (
            <Bubble>
                <FormattedMessage id="desktop-apps" tagName="h2" />
                <FormattedMessage
                    id="download-desktop-upsell"
                    values={{ operatingSystem: 'MacOS' }}
                />
                <BubbleLink
                    href="https://pocketcasts.com/get/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => recordEvent('welcome_link_tapped', { link: 'download_mac_app' })}
                >
                    <Icon id="download" size={18} />
                    &nbsp; <FormattedMessage id="download-for-macos" />
                </BubbleLink>
            </Bubble>
        );
    }
    if (isWindows() && !isWindowsApp() && !isElectronApp()) {
        return (
            <Bubble>
                <FormattedMessage id="desktop-apps" tagName="h2" />
                <FormattedMessage
                    id="download-desktop-upsell"
                    values={{ operatingSystem: 'Windows' }}
                />
                <BubbleLink
                    href="https://pocketcasts.com/get/windows"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                        recordEvent('welcome_link_tapped', { link: 'download_windows_app' })
                    }
                >
                    <Icon id="arrow-right" size={18} style={{ transform: 'rotate(-45deg)' }} />
                    &nbsp; <FormattedMessage id="get-on-the-microsoft-store" />
                </BubbleLink>
            </Bubble>
        );
    }
    return null;
};

const ThankYouScreen = () => {
    const { recordEvent } = useTracks();
    const isLightTheme = useSelector(isShowingLightTheme);

    return (
        <ThankYouWrapper>
            <ThankYouHeading>
                <Logo />
                <FormattedMessage id={'welcome-get-you-listening'} tagName="h1" />
            </ThankYouHeading>

            {<DesktopAppBubble />}

            {isMobile() ? (
                <Bubble>
                    <FormattedMessage id="pocket-casts-mobile-apps" tagName="h2" />
                    <FormattedMessage id="download-mobile-app-instructions" />
                    <BubbleLink
                        href="https://pocketcasts.com/get"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            recordEvent('welcome_link_tapped', { link: 'download_mobile_app' })
                        }
                    >
                        <Icon id="download" size={18} />
                        &nbsp; <FormattedMessage id="download-the-app" />
                    </BubbleLink>
                </Bubble>
            ) : (
                <Bubble>
                    <QRCodeWrapper>
                        <div>
                            <FormattedMessage id="pocket-casts-mobile-apps" tagName="h2" />
                            <FormattedMessage id="mobile-qr-instructions" />
                        </div>
                        <img src={isLightTheme ? QRCodeSrc : QRCodeSrcWhite} alt="QR Code" />
                    </QRCodeWrapper>
                </Bubble>
            )}

            <MarketingRegistrationToggle
                text={
                    <NewsletterText>
                        <FormattedMessage id="get-the-newsletter" tagName="h2" />
                        <FormattedMessage id="newsletter-upsell" />
                    </NewsletterText>
                }
            />
        </ThankYouWrapper>
    );
};
export default ThankYouScreen;
