import { Icon } from 'components/Icon';
import { getCategoryIcon, getToken } from 'helper/CategoriesHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { DiscoverCategory } from 'model/types';
import React from 'react';
import { useTheme } from 'styled-components';
import { getDiscoverRegion } from '../../../redux/reducers/selectors';
import { CategoryOption } from './CategoryOption';

import { useSelector } from '../../../hooks/react-redux-typed';
import {
    Button,
    ClearSelection,
    SelectedChip,
    SelectedWrapper,
    StyledAllCategoriesMenu,
    Wrapper,
} from './DiscoverCategoriesNav.styled';

type Props = {
    categories: DiscoverCategory[];
    onCategoryClicked: (id: number) => void;
    onClearSelection?: () => void;
    selectedCategory?: number;
    popularCategories?: number[];
};

function DiscoverCategoriesNav({
    categories,
    popularCategories,
    onCategoryClicked,
    onClearSelection,
    selectedCategory,
}: Props) {
    const formatMessage = useFormatMessage();
    const theme = useTheme();
    const { recordEvent } = useTracks();
    const region = useSelector(getDiscoverRegion);

    const onCategoriesMenuItemClicked = (category: DiscoverCategory) => {
        recordEvent('discover_categories_picker_pick', {
            id: category?.id,
            name: category?.name,
            region: region?.code,
        });
        onCategoryClicked(category.id);
    };

    const onPopularCategoryPillClicked = (category: DiscoverCategory) => {
        recordEvent('discover_categories_pill_tapped', {
            id: category?.id,
            name: category?.name,
            region: region?.code,
        });
        onCategoryClicked(category.id);
    };

    const onClearSelectedCategoryClick = (category: DiscoverCategory) => {
        recordEvent('discover_category_close_button_tapped', {
            id: category?.id,
            name: category?.name,
            region: region?.code,
        });
        onClearSelection?.();
    };

    const getMostPopularCategories = (): DiscoverCategory[] => {
        if (!popularCategories) {
            return [];
        }

        return categories
            .filter(category => popularCategories.includes(category.id))
            .sort((a, b) => popularCategories.indexOf(a.id) - popularCategories.indexOf(b.id));
    };

    if (selectedCategory) {
        const category = categories.find(category => category.id === selectedCategory);

        if (!category) {
            return null;
        }

        return (
            <SelectedWrapper>
                <ClearSelection onClick={() => onClearSelectedCategoryClick(category)}>
                    <Icon id="cancel-sm" />
                </ClearSelection>
                <SelectedChip>{category.name}</SelectedChip>
            </SelectedWrapper>
        );
    }

    return (
        <Wrapper>
            <StyledAllCategoriesMenu
                id="all-categories-menu"
                wrapperClassName="all-categories-menu"
                itemClassName="all-categories-menu-item"
                aria-label={formatMessage('show-sort-options')}
                text={formatMessage('all-categories')}
                iconName="chevron-down-sm"
                iconNameOpen="chevron-up-sm"
                horizontalPosition={'center'}
                offsets={{ top: 15 }}
                maxHeight={365}
                onOpen={() =>
                    recordEvent('discover_categories_picker_shown', { region: region?.code })
                }
                onClose={() =>
                    recordEvent('discover_categories_picker_closed', { region: region?.code })
                }
            >
                {categories.map(category => (
                    <CategoryOption
                        key={`${category.id}-dropdown`}
                        text={category.name}
                        icon={getCategoryIcon(category.id)}
                        onClick={() => onCategoriesMenuItemClicked(category)}
                        token={theme.tokens[getToken(category.id)]}
                    />
                ))}
            </StyledAllCategoriesMenu>

            {getMostPopularCategories().map(category => (
                <Button key={category.id} onClick={() => onPopularCategoryPillClicked(category)}>
                    {category.name}
                </Button>
            ))}
        </Wrapper>
    );
}

export default DiscoverCategoriesNav;
