import React, { useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';

interface ResizeObserverProps {
    children: React.ReactNode;
    onResize?: (size: { width: number; height: number }) => void;
}

/**
 * Measures the size of the children element and calls onResize with the new width and height.
 */
export const ResizeObserver = ({ children, onResize }: ResizeObserverProps) => {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    // Prevent stale closure without needing to add onResize to the dependency array.
    const onResizeRef = useRef(onResize);

    useEffect(() => {
        onResizeRef.current?.({ width, height });
    }, [width, height]);

    return <div ref={ref}>{children}</div>;
};
