import styled, { css } from 'styled-components';
import { gridSize } from 'styles';
import BackgroundSrc from './background.jpg';

export const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
    background: #121212;
    @media (min-width: 800px) {
        height: 100%;
        grid-template-columns: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }
`;

export const PageContent = styled.section`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: auto 0;
    padding: ${8 * gridSize}px 6vw 8vw;
    background: ${props => props.theme.legacyScss['theme-background']};
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 800px) {
        padding: 4vw;
    }
    @media (min-width: 1100px) {
        padding: 6vw;
    }
    @media (min-width: 1400px) {
        padding: 8.3vw;
    }
`;

export const PromoContent = styled.section`
    color: #fff;
    background: #121212;
    background-image: url(${BackgroundSrc});
    background-repeat: no-repeat;
    background-size: 100% max(800px, 100%);
    overflow: hidden;
    @media (min-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;

export const PlanBadgeWrapper = styled.div`
    font-size: 22px;
`;

export const Text = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    max-width: 32em;
    padding: 40px 20px 50px;
    margin: 0 auto;
    h1 {
        font-size: 20px;
        letter-spacing: -0.02em;
        line-height: 1.2;
        max-width: 15em;
        margin: 24px auto 8px;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    p {
        opacity: 0.8;
        margin: 0 auto;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    @media (min-width: 600px) {
        font-size: 16px;
        padding: 50px 40px 50px;

        h1 {
            font-size: 24px;
        }
    }
    @media (min-width: 800px) {
        margin-top: auto;
    }
    @media (min-width: 1280px) {
        font-size: 20px;

        h1 {
            font-size: 32px;
        }
    }
    @media (min-width: 1440px) {
        padding-top: 3.5vw;
        padding-bottom: 3.5vw;
    }
`;

export const Images = styled.div<{ $isLoaded: boolean }>`
    position: relative;
    margin-bottom: auto;

    @keyframes fade-in {
        0% {
            opacity: 0;
            transform: translateY(-6px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    ${props =>
        props.$isLoaded &&
        css`
            ${DevicesScreenshot} {
                @media (prefers-reduced-motion: no-preference) {
                    animation: fade-in 500ms ease;
                    animation-fill-mode: forwards;
                }

                @media (prefers-reduced-motion: reduce) {
                    opacity: 1;
                }
            }
        `}
`;

export const DevicesScreenshot = styled.img`
    width: 94%;
    height: auto;
    position: relative;
    right: -6%;
    opacity: 0;
`;
