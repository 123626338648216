import {
    CategoryList,
    ContentSpec,
    DiscoverCategory,
    DiscoverLayout,
    DiscoverRegion,
    Episode,
    ListData,
} from 'model/types';
import { buffers, Channel } from 'redux-saga';
import { actionChannel, call, put, select, take, takeLeading } from 'redux-saga/effects';
import { getCategoryPodcasts, getPodcastList } from '../../services/listsApi';
import staticApi from '../../services/staticApi';
import * as fromDiscoverActions from '../actions/discover.actions';
import * as fromSettingsActions from '../actions/settings.actions';
import {
    getCategoryList,
    getDiscoverCategory,
    getDiscoverList,
    getRegionCodeToken,
    getSettings,
} from '../reducers/selectors';
import { userIsLoggedIn } from '../reducers/selectors/user.selectors';
import { logSagaError } from './saga-helper';

// TODO: FIXME - should be removed and replace with the correct type

type TodoFixmeType = any;

function replaceRegionTokens(
    selectedRegion: DiscoverRegion,
    tokens: {
        region_name_token: string;
        region_code_token: string;
    },
    str: string,
) {
    const { region_name_token, region_code_token } = tokens;
    if (str.includes(region_name_token)) {
        return str.replace(region_name_token, selectedRegion.name);
    }
    if (str.includes(region_code_token)) {
        return str.replace(region_code_token, selectedRegion.code);
    }
    return str;
}

function cleanDiscoverContent(discoverContent: ContentSpec, regionCode: string) {
    const { layout, region_code_token, region_name_token } = discoverContent;
    const selectedRegion = discoverContent.regions[regionCode];
    const cleanLayout = layout.map(layoutItem => {
        const currentLayout: DiscoverLayout = layoutItem;
        const updatedKeys: Record<string, unknown> = {};
        for (const key of ['listName', 'source', 'title']) {
            const layoutKey = key as keyof DiscoverLayout;
            if (key in currentLayout) {
                updatedKeys[key] = replaceRegionTokens(
                    selectedRegion,
                    { region_code_token, region_name_token },
                    currentLayout[layoutKey] as string,
                );
            }
        }
        return {
            ...currentLayout,
            ...updatedKeys,
        };
    });
    return {
        ...discoverContent,
        layout: cleanLayout,
    };
}

function* getRegionForLocale() {
    const discoverContent: { regions: Record<string, { code: string }> } = yield call(
        staticApi.getDiscoverContent,
    );
    const { regions } = discoverContent;
    const locale = window.navigator.language ? window.navigator.language.toLowerCase() : 'global';
    const region =
        Object.keys(regions)
            .map(key => regions[key] && regions[key].code)
            .find(region => {
                if (locale.includes(region) || region === locale) {
                    return true;
                }
                return false;
            }) || 'global';
    return region;
}

function* getDiscoverContentJson(action: fromDiscoverActions.Actions | TodoFixmeType) {
    const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

    try {
        // Load discover data
        const settings: ReturnType<typeof getSettings> = yield select(getSettings);
        let regionCode = settings.region;

        if (action.type === fromSettingsActions.ActionTypes.SAVE_REGION) {
            regionCode = action.region || settings.region;
        } else if (settings.changed?.region === false || !isLoggedIn) {
            regionCode = yield getRegionForLocale();
            if (regionCode) {
                yield put(fromSettingsActions.Actions.saveRegion(regionCode));
            }
        }

        regionCode = regionCode ?? 'global';

        const discoverContent: ContentSpec | null = yield call(
            staticApi.getWebPlayerDiscoverContent,
            regionCode,
        );
        if (discoverContent) {
            const cleanContent = cleanDiscoverContent(discoverContent, regionCode);
            yield put(fromDiscoverActions.Actions.setDiscoverContent(cleanContent));
        }
    } catch (error) {
        logSagaError('Failed in fetch Discover content data', error);
    }
}

function* openList(name: string) {
    try {
        const list: string[] = yield select(getDiscoverList, name);
        if (!list || list.length === 0) {
            if (name === 'trending-episodes') {
                const json: { episodes: Episode[] } = yield call(staticApi.getTrendingEpisodes);
                const episodes = json.episodes.map((episode: TodoFixmeType) => ({
                    ...episode,
                    podcastUuid: episode.podcast.uuid,
                }));
                yield put(
                    fromDiscoverActions.Actions.openDiscoverListSuccess(name, {
                        title: 'Trending Episodes',
                        episodes,
                        // The underlying state expects ListData so we need to stub in the
                        // other expected values
                        podcasts: [],
                        description: '',
                        datetime: '',
                        list_id: '',
                        collection_image: '',
                        colors: { onLightBackground: '', onDarkBackground: '' },
                    }),
                );
            } else {
                const json: ListData = yield call(getPodcastList, name);
                yield put(fromDiscoverActions.Actions.openDiscoverListSuccess(name, json));
            }
        }
    } catch (error) {
        logSagaError('Failed opening list', error);
    }
}

function* openCategoryList(id: string) {
    try {
        const settings: ReturnType<typeof getSettings> = yield select(getSettings);
        const existingList: CategoryList = yield select(getCategoryList, id);
        const currentRegionCode = settings.region || 'global';
        if (
            !existingList ||
            existingList.podcasts.length === 0 ||
            existingList.region !== currentRegionCode
        ) {
            const category: DiscoverCategory = yield select(getDiscoverCategory, id);
            if (category) {
                yield put(fromDiscoverActions.Actions.clearCategory(category.id));
                const regionToken: string = yield select(getRegionCodeToken);
                const json: Awaited<ReturnType<typeof getCategoryPodcasts>> = yield call(
                    getCategoryPodcasts,
                    {
                        source: category.source,
                        regionToken,
                        regionCode: currentRegionCode,
                    },
                );
                yield put(
                    fromDiscoverActions.Actions.openCategoryListSuccess({
                        name: category.id,
                        title: json.title,
                        list: json.podcasts,
                        region: currentRegionCode,
                    }),
                );
            }
        }
    } catch (error) {
        logSagaError('Failed opening category', error);
    }
}

export function* watchOpenDiscoverList() {
    const listsChannel: Channel<any> = yield actionChannel(
        [
            fromDiscoverActions.ActionTypes.OPEN_DISCOVER_LIST,
            fromDiscoverActions.ActionTypes.OPEN_CATEGORY_LIST,
        ],
        buffers.sliding(10),
    );
    while (true) {
        const action: ReturnType<
            | typeof fromDiscoverActions.Actions.openDiscoverList
            | typeof fromDiscoverActions.Actions.openCategoryList
        > = yield take(listsChannel);
        if (action.type === fromDiscoverActions.ActionTypes.OPEN_DISCOVER_LIST) {
            yield openList(action.payload.name);
        }
        if (action.type === fromDiscoverActions.ActionTypes.OPEN_CATEGORY_LIST) {
            yield openCategoryList(action.payload.id);
        }
    }
}

export function* watchGetDiscoverContent() {
    yield takeLeading(
        [
            fromDiscoverActions.ActionTypes.GET_DISCOVER_CONTENT,
            fromSettingsActions.ActionTypes.SAVE_REGION,
        ],
        getDiscoverContentJson,
    );
}
