import { useDispatch } from 'hooks/react-redux-typed';
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import * as fromFlagsActions from '../../../../redux/actions/flags.actions';
import { flagSlideInTimeMs, flagSlideOutTimeMs, FlagWrapper } from './Flag.styled';

export type Props = {
    id: string;
    message: ReactNode;
    persistent: boolean;
};

const Flag = ({ id, message, persistent }: Props) => {
    const dispatch = useDispatch();
    const [hiding, setHiding] = useState(true);

    useLayoutEffect(() => {
        requestAnimationFrame(() => setHiding(false));
    }, []);

    useEffect(() => {
        if (persistent) {
            return () => null;
        }

        const displayTime = flagSlideInTimeMs + 5000;

        let timeout = setTimeout(() => {
            setHiding(true);

            timeout = setTimeout(() => {
                dispatch(fromFlagsActions.Actions.removeFlag(id));
            }, flagSlideOutTimeMs);
        }, displayTime);

        return () => clearTimeout(timeout);
    }, [persistent, id, dispatch]);

    return <FlagWrapper $hiding={hiding}>{message}</FlagWrapper>;
};

export default Flag;
