import { Button } from 'components/Button';
import { useDispatch } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { Bookmark } from 'model/types';
import React from 'react';
import styled from 'styled-components';
import { gridSize } from 'styles';
import useTracks from '../../../../hooks/useTracks';
import { deleteBookmark } from '../../../../redux/actions/episode-sync.actions';
import { TrackOnMount, TrackOnUnmount } from '../../../Tracks';

export type Props = {
    bookmark: Bookmark;
    onSubmit?: () => void;
    eventSource: string;
};

const Form = styled.form`
    button {
        width: 100%;
        margin-top: ${gridSize}px;
    }
`;

const DeleteBookmarkForm = ({ bookmark, onSubmit, eventSource }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();

    const handleSubmit = () => {
        recordEvent('bookmark_delete_form_submitted', { source: eventSource });
        dispatch(deleteBookmark(bookmark));
        onSubmit?.();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <TrackOnMount event="bookmark_delete_form_shown" properties={{ source: eventSource }} />
            <TrackOnUnmount
                event="bookmark_delete_form_dismissed"
                properties={{ source: eventSource }}
            />
            <p>{formatMessage('delete-bookmark-confirmation')}</p>
            <Button kind="primary-red" type="submit">
                {formatMessage('delete-bookmark')}
            </Button>
        </Form>
    );
};

export default DeleteBookmarkForm;
