import styled, { css } from 'styled-components';
import { isFirefox, isSafari } from '../../../helper/Browser';

export const ChaptersList = styled.div`
    height: 100%;
    padding-right: 60px;
    overflow-y: auto;

    /* Scrollbar-gutter: stable doesn't seem to have any effect in Safari, so we workaround the
    layout shift caused by scrollbars by setting overflow-y: scroll. The scrollbars still don't
    show up in Safari, but the layout shift is avoided. */
    scrollbar-gutter: stable;
    ${isSafari() &&
    css`
        overflow-y: scroll;
    `}

    ${isFirefox() &&
    css`
        padding-right: calc(60px + var(--scrollbar-width));
    `}
`;
