import { SubscriptionTier } from 'model/types';
import React from 'react';
import { patronColor, plusColor } from 'styles/colors';
import { Wrapper } from './PlanBadge.styled';

export type Props = {
    tier: SubscriptionTier;
    size?: number;
};

const PatronLogo = () => (
    <svg viewBox="-1 -1.5 14 13.5">
        <path
            d="M11.6498 2.53543C10.8148 0.91543 8.83485 0.28043 7.21485 1.11043C6.91985 1.25543 6.65485 1.45043 6.41985 1.67543L5.98985 2.06543L5.57635 1.68543C4.82635 0.94043 3.76635 0.60493 2.73135 0.77993C1.69635 0.93993 0.806349 1.58993 0.336349 2.51993C-0.298651 3.75993 -0.0536507 5.26993 0.941349 6.24993L5.62785 11.0749C5.81785 11.2699 6.13285 11.2749 6.33285 11.0799C6.33285 11.0749 6.33785 11.0699 6.33785 11.0699L11.0178 6.24993C12.0128 5.26493 12.2628 3.74993 11.6278 2.50693L11.6498 2.53543Z"
            fill={patronColor}
        />
    </svg>
);

const PlusLogo = () => (
    <svg viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1H10V6H15.5L14.5 10H10V15H6V10H0.5L1.5 6H6V1Z"
            fill={plusColor}
        />
    </svg>
);

const PlanBadge = ({ tier, size = 16 }: Props) => {
    return (
        <Wrapper $tier={tier} $width={size} $height={size}>
            {tier === 'Patron' ? <PatronLogo /> : <PlusLogo />}
            {tier}
        </Wrapper>
    );
};

export default PlanBadge;
