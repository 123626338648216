import React, { useCallback } from 'react';
import useFormatMessage from '../../../hooks/useFormatMessage';
import { TranslatableStringKey } from '../../../translations/glotpress';
import { tooltipAndLabel } from '../../../util/tooltip';
import { Icon, IconId } from '../../Icon';
import { StyledActionButton } from './ActionButton.styled';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: IconId;
    size?: number;
    label: TranslatableStringKey;
}
export const ActionButton: React.FC<Props> = ({ icon, size = 24, onClick, ...props }) => {
    const formatMessage = useFormatMessage();
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onClick?.(e);
        },
        [onClick],
    );

    return (
        <StyledActionButton
            onClick={handleClick}
            {...props}
            {...tooltipAndLabel(formatMessage(props.label))}
        >
            <Icon id={icon} size={size} />
        </StyledActionButton>
    );
};
