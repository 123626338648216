import React from 'react';
import { useDispatch } from '../../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import { VideoCollapseButtonWrapper } from './VideoCollapseButton.styled';

function VideoCollapseButton() {
    const dispatch = useDispatch();
    const onCollapseClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        dispatch(fromPlayerActions.Actions.updateVideoMode(1));
    };

    return (
        <VideoCollapseButtonWrapper className="video-collapse-button" onClick={onCollapseClick}>
            <div className="frame">
                <div className="arrow" />
            </div>
        </VideoCollapseButtonWrapper>
    );
}

export default VideoCollapseButton;
