import React from 'react';

const PlusUploadFilesIcon = () => (
    <svg
        width="152"
        height="152"
        viewBox="0 0 152 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M75.9961 54.377L102.641 81.022C105.114 83.4953 105.114 87.5053 102.641 89.9786C100.168 92.452 96.1577 92.452 93.6844 89.9786L82.3294 78.6237V126.667C82.3294 130.165 79.4939 133 75.9961 133C72.4983 133 69.6628 130.165 69.6628 126.667V78.6237L58.3078 89.9786C55.8345 92.452 51.8244 92.452 49.3511 89.9786C46.8778 87.5053 46.8778 83.4953 49.3511 81.022L75.9961 54.377Z"
            fill="url(#paint0_linear_801_8253)"
        />
        <path
            opacity="0.5"
            d="M32.1716 41.5481C34.9982 25.1446 49.2891 12.6689 66.4974 12.6689C80.0432 12.6689 91.771 20.3979 97.5331 31.6737C97.7431 31.6705 97.9534 31.6689 98.1641 31.6689C120.9 31.6689 139.331 50.0999 139.331 72.8356V76.0023C139.331 79.5001 136.495 82.3356 132.997 82.3356C129.5 82.3356 126.664 79.5001 126.664 76.0023V72.8356C126.664 57.0955 113.904 44.3356 98.1641 44.3356C96.8597 44.3356 95.579 44.4228 94.3268 44.591L89.1661 45.284L87.4883 40.3548C84.5122 31.6112 76.2294 25.3356 66.4974 25.3356C54.6642 25.3356 44.9931 34.611 44.3635 46.2836L44.1354 50.5122L40.1405 51.9172C31.5056 54.9539 25.3307 63.1818 25.3307 72.8356V76.0023C25.3307 79.5001 22.4952 82.3356 18.9974 82.3356C15.4996 82.3356 12.6641 79.5001 12.6641 76.0023V72.8356C12.6641 59.0891 20.6239 47.215 32.1716 41.5481Z"
            fill="url(#paint1_linear_801_8253)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_801_8253"
                x1="52.5862"
                y1="54.377"
                x2="104.323"
                y2="57.6221"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FED745" />
                <stop offset="1" stopColor="#FEB525" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_801_8253"
                x1="23.9754"
                y1="12.669"
                x2="136.612"
                y2="30.3873"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FED745" />
                <stop offset="1" stopColor="#FEB525" />
            </linearGradient>
        </defs>
    </svg>
);

export default PlusUploadFilesIcon;
