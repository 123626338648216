import { Button } from 'components/Button';
import { GenericErrorMessage } from 'components/GenericErrorMessage';
import { Icon } from 'components/Icon';
import { TrackOnMount } from 'components/Tracks';
import { ModalTypes } from 'helper/UiHelper';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useTracks from 'hooks/useTracks';
import { FormStatus } from 'model/types';
import React, { FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { api } from 'services/api';
import * as fromFoldersActions from '../../../../redux/actions/folders.actions';
import * as fromModalActions from '../../../../redux/actions/modal.actions';
import * as fromPodcastsActions from '../../../../redux/actions/podcasts.actions';
import {
    getFolder,
    getFolderForPodcast,
    getPodcastUuidsByFolderUuid,
} from '../../../../redux/reducers/selectors';
import { FolderSelector } from '../FolderSelector';
import { ErrorMessage, Footer, Form, FormSection, TextButton } from './FolderSelectForm.styled';

export type Props = {
    podcastUuid: string;
    onDelete: () => void;
    onSuccess: () => void;
};

enum SUBMIT_STATES {
    saving = 'saving',
    removing = 'removing',
}

const FolderSelectForm = ({ podcastUuid, onSuccess }: Props) => {
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const currentFolder = useSelector(state => getFolderForPodcast(state, podcastUuid));
    const podcastUuidsByFolderUuid = useSelector(getPodcastUuidsByFolderUuid);

    const [folderUuid, setFolderUuid] = useState(currentFolder?.uuid);
    const [submitState, setSubmitState] = useState<SUBMIT_STATES | null>(null);
    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);

    const chosenFolder = useSelector(state => getFolder(state, folderUuid));

    const submitForm = (e: FormEvent) => {
        e.preventDefault();

        if (formStatus === FormStatus.SUBMITTING) {
            return;
        }

        if (!chosenFolder) {
            handleRemoveClick(e);
            return;
        }

        setSubmitState(SUBMIT_STATES.saving);
        setFormStatus(FormStatus.SUBMITTING);

        const podcasts = [...podcastUuidsByFolderUuid[chosenFolder.uuid], podcastUuid];
        api.updateFolder(chosenFolder, podcasts).then(
            updatedFolder => {
                dispatch(fromFoldersActions.Actions.updateFolder(updatedFolder, podcasts));

                dispatch(
                    fromPodcastsActions.Actions.rearrangePodcastList([
                        ['PUSH', podcastUuid, chosenFolder.uuid],
                    ]),
                );

                setSubmitState(null);
                setFormStatus(FormStatus.READY);
                recordEvent('folder_choose_folder_tapped');
                onSuccess();
            },
            () => {
                setSubmitState(null);
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    const handleRemoveClick = (e: FormEvent) => {
        e.preventDefault();

        if (formStatus === FormStatus.SUBMITTING) {
            return;
        }

        if (!currentFolder) {
            onSuccess();
            return;
        }

        setSubmitState(SUBMIT_STATES.removing);
        setFormStatus(FormStatus.SUBMITTING);

        const podcasts = podcastUuidsByFolderUuid[currentFolder.uuid].filter(
            uuid => uuid !== podcastUuid,
        );
        api.updateFolder(currentFolder, podcasts).then(
            updatedFolder => {
                dispatch(fromFoldersActions.Actions.updateFolder(updatedFolder, podcasts));

                dispatch(
                    fromPodcastsActions.Actions.rearrangePodcastList([
                        ['PUSH', podcastUuid, 'home'],
                    ]),
                );

                setSubmitState(null);
                setFormStatus(FormStatus.READY);
                recordEvent('folder_choose_folder_removed_from_folder');
                onSuccess();
            },
            () => {
                setSubmitState(null);
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    const handleNewFolderClick = (e: FormEvent) => {
        e.preventDefault();
        // TODO: Pass pre-loaded podcasts into createFolder form
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.createFolder, {
                defaultPodcasts: [podcastUuid],
                eventSource: 'choose_folder',
            }),
        );
        return false;
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            onSuccess();
        }
    };

    return (
        <Form onSubmit={submitForm}>
            <TrackOnMount event="folder_choose_shown" />
            <FormSection>
                <FolderSelector
                    selected={folderUuid}
                    onChange={setFolderUuid}
                    onKeyDown={onKeyDown}
                />
            </FormSection>
            {currentFolder && (
                <FormSection>
                    <TextButton
                        kind="text"
                        type="button"
                        colorToken="support-05"
                        onClick={handleRemoveClick}
                    >
                        <Icon id="folder-remove" size={20} />
                        <FormattedMessage
                            id={
                                submitState === SUBMIT_STATES.removing
                                    ? 'removing'
                                    : 'remove-from-folder'
                            }
                        />
                    </TextButton>
                </FormSection>
            )}
            <FormSection>
                <TextButton
                    kind="text"
                    type="button"
                    colorToken="primary-interactive-01"
                    onClick={handleNewFolderClick}
                >
                    <Icon id="folder-add" size={20} />
                    <FormattedMessage id="new-folder" />
                </TextButton>
            </FormSection>

            <Footer>
                {formStatus === FormStatus.ERROR && (
                    <ErrorMessage>
                        <GenericErrorMessage />
                    </ErrorMessage>
                )}
                <Button loading={submitState === SUBMIT_STATES.saving} type="submit">
                    <FormattedMessage id="choose-folder" />
                </Button>
            </Footer>
        </Form>
    );
};

export default FolderSelectForm;
