import styled from 'styled-components';
import { MOBILE_WIDTH } from '../../model/page';
import { gridSize } from '../../styles';

export const NavigationWrapper = styled.div`
    display: flex;
    gap: ${1 * gridSize}px;

    // Remove the gap between the begging of the container and the first item and then
    // compensate for the transparent space in the arrow SVG image
    margin-left: ${-1 * gridSize - 5}px;

    justify-content: space-between;
    flex-direction: row;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;
