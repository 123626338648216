import { LoaderSquare } from 'components';
import { Button } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import useIsSubscribedToPodcasts from '../../hooks/useIsSubscribedToPodcasts';
import useQueryRedirect from '../../hooks/useQueryRedirect';
import * as fromDiscoverActions from '../../redux/actions/discover.actions';
import { getDefaultTracksSource } from '../../redux/reducers/selectors';
import urls from '../../urls';
import DiscoverScreen from './DiscoverScreen';
import ThankYouScreen from './ThankYouScreen';
import { Content, ContentWrapper, Footer, FooterButton, Wrapper } from './WelcomePage.styled';

/**
 * This hook gathers all the data needed to decide what onboarding steps to show, and
 * returns the steps. Once steps are chosen, they will not be changed again.
 *
 * @returns steps — null if loading, or array of components
 */
const useOnboardingSteps = () => {
    const dispatch = useDispatch();
    const isSubscribedToPodcasts = useIsSubscribedToPodcasts();
    const [steps, setSteps] = useState<FunctionComponent[] | null>(null);

    // Once the user's podcasts have loaded, decide which steps to show and fetch related data
    useEffect(() => {
        // We don't want the current screen to change unexpectedly, so let's bail if steps are already chosen
        if (steps) {
            return;
        }

        if (isSubscribedToPodcasts === undefined) {
            // Still loading, so we can't decide what to show yet
            return;
        }

        if (!isSubscribedToPodcasts) {
            // This user has no podcasts — let's show them the DiscoverScreen and fetch Discover data
            setSteps([ThankYouScreen, DiscoverScreen]);
            dispatch(fromDiscoverActions.Actions.getDiscoverContent());
        } else {
            // This user already has podcasts so they're an upgrading existing user and probably
            // don't need to see the DiscoverScreen
            setSteps([ThankYouScreen]);
        }
    }, [dispatch, isSubscribedToPodcasts, steps]);

    return steps;
};

const WelcomePage = () => {
    const queryRedirect = useQueryRedirect();
    const [currentStep, setCurrentStep] = useState(0);
    const steps = useOnboardingSteps();

    const source = useSelector(getDefaultTracksSource);

    if (!steps) {
        return (
            <Wrapper>
                <ContentWrapper>
                    <LoaderSquare />
                </ContentWrapper>
            </Wrapper>
        );
    }

    const ContentComponent = steps[Math.min(currentStep, steps.length - 1)];

    const freeWebPlayerFinalStepRedirect = urls.subscribePath({
        redirect: queryRedirect,
    });

    const finalStepRedirect = freeWebPlayerFinalStepRedirect;

    return (
        <Wrapper>
            <TrackOnMount event="welcome_shown" properties={{ source }} />
            <TrackOnUnmount event="welcome_dismissed" properties={{ source }} />
            <ContentWrapper>
                <Content>
                    <ContentComponent />
                </Content>
            </ContentWrapper>
            <Footer>
                <FooterButton>
                    {currentStep < steps.length - 1 ? (
                        // Show "Continue" button when there's more steps
                        <Button onClick={() => setCurrentStep(currentStep + 1)} kind="primary">
                            <FormattedMessage id="continue" />
                        </Button>
                    ) : (
                        // Show "Done" button when this is the last step
                        <ButtonLink to={finalStepRedirect} kind="primary">
                            <FormattedMessage id="done" />
                        </ButtonLink>
                    )}
                </FooterButton>
            </Footer>
        </Wrapper>
    );
};

export default WelcomePage;
