import { Icon } from 'components/Icon';
import { ModalMonitor } from 'components/ModalMonitor';
import React, { Component, ReactNode } from 'react';
import { FocusOn } from 'react-focus-on';
import { Portal } from 'react-portal';
import { ModernStyle } from 'styles/ModernStyle.styled';
import {
    ChildrenWrapper,
    Cross,
    Header,
    ModalCoverBackground,
    ModalDialog,
    ModalWrapper,
    Title,
} from './Modal.styled';

export const ARIA_DESCRIBEDBY_ID = 'modal--aria-describedby';

export type Props = {
    children: ReactNode;
    onClose: () => void;
    horizontalInsetPx?: number;
    blurred?: boolean;
    hideCross?: boolean;
    className?: string;
    'data-testid'?: string;
    title?: string;
    useModernStyle?: boolean;
};

export class Modal extends Component<Props> {
    static defaultProps = {
        blurred: false,
        hideCross: false,
    };

    dialogRef: React.RefObject<HTMLDivElement> | null;

    constructor(props: Props) {
        super(props);
        this.dialogRef = React.createRef();
    }

    onCloseWrapper = () => this.props.onClose();

    shouldIgnoreFocusLock = (candidate: HTMLElement) => {
        // react-focus-on is an accessibility library that locks focus on the modal for accessibility.
        // Unfortunately this will cause the paddle iframe that pops up to not be useable. This
        // logic will ensure that only the paddle iframe and this modal are whitelisted.
        if (candidate.className.includes('paddle')) {
            return false;
        }
        const modalRoot = document.getElementById('modal-root');
        if (!modalRoot) {
            return true;
        }
        return modalRoot?.getAttribute('aria-modal') !== 'true';
    };

    render() {
        const { horizontalInsetPx, blurred, hideCross, className, onClose, title, useModernStyle } =
            this.props;
        const showHeader = title || !hideCross;

        const Wrapper = useModernStyle ? ModernStyle : React.Fragment;

        return (
            <Portal node={document && document.getElementById('modal-root')}>
                <Wrapper>
                    <ModalWrapper data-testid={this.props['data-testid'] || null}>
                        <ModalMonitor onCloseModals={this.onCloseWrapper} />
                        <FocusOn
                            onEscapeKey={this.onCloseWrapper}
                            scrollLock={false}
                            shouldIgnore={this.shouldIgnoreFocusLock}
                        >
                            <ModalDialog
                                className={className}
                                aria-modal="true"
                                role="dialog"
                                aria-label={title}
                                aria-describedby={ARIA_DESCRIBEDBY_ID}
                                $blurred={blurred}
                                ref={this.dialogRef}
                            >
                                {showHeader && (
                                    <Header>
                                        {title && (
                                            <Title $horizontalInset={horizontalInsetPx ?? 0}>
                                                {title}
                                            </Title>
                                        )}
                                    </Header>
                                )}
                                <ChildrenWrapper $horizontalInset={horizontalInsetPx ?? 0}>
                                    {typeof this.props.children === 'function'
                                        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
                                          (this.props.children as Function)({ closeModal: onClose })
                                        : this.props.children}
                                </ChildrenWrapper>
                                {!hideCross && (
                                    <Cross aria-label="Close" onClick={this.props.onClose}>
                                        <Icon id="cancel" />
                                    </Cross>
                                )}
                            </ModalDialog>
                            <ModalCoverBackground onClick={this.props.onClose} />
                        </FocusOn>
                    </ModalWrapper>
                </Wrapper>
            </Portal>
        );
    }
}

export default Modal;
