import { isMacApp } from 'helper/Browser';
import styled from 'styled-components';

export const RowButtonStyled = styled.button`
    width: 24px;
    height: 24px;
    ${props => props.hidden && 'visibility: hidden;'}
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.tokens['primary-icon-02']};
    opacity: 0.5;

    ${
        // This transition causes layout shifts in the Mac app, so we disable it there.
        !isMacApp() && 'transition: opacity 0.2s ease-in-out;'
    }

    &:hover {
        opacity: 1;
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }

    svg {
        color: ${props => props.color};
    }
`;
