import styled, { css } from 'styled-components';
import { MOBILE_WIDTH } from '../../model/page';
import { gridSize } from '../../styles';
import { dialogButtonMixin } from '../Button/Button.styled';
import { ButtonLink } from '../ButtonLink';

export const NavBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    height: ${11 * gridSize}px;
    width: calc(100% - var(--scrollbar-width));

    min-height: ${11 * gridSize}px;
    max-height: ${11 * gridSize}px;

    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};

    .logo-link {
        display: none;
    }

    .drag-handle {
        display: none;
    }

    z-index: 2;

    @media (max-width: ${MOBILE_WIDTH}px) {
        .logo-link,
        .drag-handle {
            display: flex;
        }

        border-bottom: none;
        min-height: auto;
        max-height: none;
        height: auto;
        padding: 0;
        z-index: 250;
        gap: 5px;
    }
`;

export const RightSideContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    flex: 1;

    @media (max-width: ${MOBILE_WIDTH}px) {
        gap: 20px;
    }
`;

export const CreateAccountButton = styled(ButtonLink)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
    min-width: 170px;
`;

export const SearchWrapper = styled.div<{ $isLoggedIn: boolean; $isExpanded: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    position: relative;
    height: ${8 * gridSize}px;

    ${props => props.$isLoggedIn && `margin-right: ${2 * gridSize}px;`}
    flex: 1;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex: 0;
        ${props =>
            props.$isExpanded &&
            css`
                flex: 1;
            `}
    }
`;

export const ProfileWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    flex-grow: 0;

    cursor: pointer;
`;

export const ProfileIconWrapper = styled.button`
    display: flex;
    width: 48px;
    height: 48px;

    align-items: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const RoundedGravatar = styled.svg`
    image {
        clip-path: inset(0% round 50%);
        border: 1px solid red;
    }
`;

export const NavButtonLink = styled(ButtonLink)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
`;

export const SecondaryNavLinks = styled.div`
    display: flex;
    gap: ${gridSize * 2}px;
    padding-left: ${gridSize * 2}px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const HamburgerButton = styled.button<{ $isOpen?: boolean }>`
    display: none;
    color: ${props => props.theme.tokens['primary-text-02']};
    position: relative;
    transition: transform 0.3s ease-in-out;
    padding: 10px;

    svg {
        transition: transform 0.3s ease-in-out;
        transform: ${props => (props.$isOpen ? 'rotate(90deg)' : 'rotate(0)')};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: flex;
    }
`;

export const GetTheAppButton = styled(ButtonLink)`
    display: none;
    min-width: 120px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: block;
    }
`;
