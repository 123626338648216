import styled from 'styled-components';
import { ModernStyle } from '../../styles/ModernStyle.styled';

const StyledCenteredPageChrome = styled(ModernStyle).attrs({ as: 'div' })`
    padding: 80px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scroll-behavior: smooth;
`;

export const CenteredPageChrome = StyledCenteredPageChrome;
