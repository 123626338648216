import React from 'react';
import { EpisodesTableProps } from '.';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { Episode } from '../../model/types';
import { StarButton } from '../buttons/StarButton';
import VirtualTable from '../VirtualTable/VirtualTable';
import { EpisodeButtons } from './columns/EpisodeButtons';
import { EpisodeDate } from './columns/EpisodeDate';
import { EpisodeDuration } from './columns/EpisodeDuration';
import {
    EpisodePodcastLink,
    EpisodeTitle,
    PrimaryLine,
    SecondaryLine,
    UpNextIcon,
} from './columns/EpisodeInfo';
import { EpisodeInfo } from './columns/EpisodeInfo.styled';
import { EpisodeThumbnail, PreviewRow, TableWrapper } from './ListEpisodes.styled';
import { useEpisodeActions } from './useEpisodeActions';
import { getFauxEpisodeSync, getPercentComplete } from './utils';

const ListEpisodes = ({ episodes, eventSource: eventSourceProp, ...props }: EpisodesTableProps) => {
    const eventSource = eventSourceProp ?? 'podcast_screen';
    const actions = useEpisodeActions(eventSource);

    const {
        autoplay,
        color,
        upNextEpisodes,
        showSyncActions = true,
        uuidToEpisodeSync,
        isPlaying,
        playerEpisodeUuid,
        playerPlayedUpTo,
        inHistory = false,
        onEpisodeClick,
        onPodcastClick = () => {},
    } = props;
    return (
        <TableWrapper>
            <VirtualTable
                items={episodes}
                renderItem={(episode: Episode) => {
                    const episodeSync = getFauxEpisodeSync(episode, uuidToEpisodeSync);
                    const isInUpNext = !!upNextEpisodes[episode.uuid];
                    const isInactive =
                        episodeSync.isDeleted ||
                        episodeSync.playingStatus === PlayingStatus.COMPLETED;
                    return (
                        <PreviewRow onClick={() => onEpisodeClick(episode)}>
                            <EpisodeThumbnail title={episode.title} uuid={episode.podcastUuid} />
                            <EpisodeInfo>
                                <PrimaryLine>
                                    <EpisodeTitle $inactive={isInactive} $tintColor={color}>
                                        {episode.title}
                                    </EpisodeTitle>
                                    {showSyncActions && episodeSync && (
                                        <StarButton
                                            on={episodeSync.starred}
                                            onClick={e => {
                                                e.stopPropagation();
                                                actions.handleStarEpisode(
                                                    episode,
                                                    !episodeSync.starred,
                                                );
                                            }}
                                        />
                                    )}
                                </PrimaryLine>
                                <SecondaryLine>
                                    {isInUpNext && (
                                        <UpNextIcon id="up-next" size={16} aria-label="Up Next" />
                                    )}
                                    <EpisodePodcastLink
                                        isInactive={isInactive}
                                        podcastUuid={episode.podcastUuid}
                                        onPodcastClick={onPodcastClick}
                                    >
                                        {/*
                                            @TODO:
                                            ListEpisodes is receiving wrong episode type.
                                            podcastTitle is actually available.
                                        */}
                                        {(episode as any).podcastTitle as string}
                                    </EpisodePodcastLink>
                                </SecondaryLine>
                            </EpisodeInfo>
                            <EpisodeDate inactive={isInactive} published={episode.published} />
                            <EpisodeDuration
                                inactive={isInactive}
                                duration={episodeSync.duration}
                                playedUpToSecs={episodeSync.playedUpTo}
                                playingStatus={episodeSync.playingStatus}
                            />
                            <EpisodeButtons
                                episodeUuid={episode.uuid}
                                podcastUuid={'podcastUuid' in episode ? episode.podcastUuid : ''}
                                title={episode.title}
                                duration={episodeSync.duration}
                                url={episode.url}
                                isDeleted={episodeSync.isDeleted}
                                playingStatus={episodeSync.playingStatus}
                                isPlaying={isPlaying}
                                autoplay={autoplay}
                                actions={actions}
                                upNextEpisodes={upNextEpisodes}
                                showSyncActions={showSyncActions}
                                color={color}
                                isPlayerEpisode={playerEpisodeUuid === episode.uuid}
                                inHistory={inHistory}
                                percentComplete={getPercentComplete(
                                    playerPlayedUpTo ?? 0,
                                    episodeSync.playedUpTo,
                                    episode.uuid,
                                    episodeSync.duration,
                                    playerEpisodeUuid,
                                )}
                            />
                        </PreviewRow>
                    );
                }}
            />
        </TableWrapper>
    );
};

export default ListEpisodes;
