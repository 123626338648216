import React from 'react';
import { useIntl } from 'react-intl';
import FeatureLock from '../../FeatureLock';
import { ActionButton } from '../buttons/ActionButton';
import { EpisodeActionProps, getEpisodeActions } from './episodeActions';

interface Props extends EpisodeActionProps {
    showSyncActions: boolean;
}

export const EpisodeActionButtons: React.FC<Props> = ({ showSyncActions, ...actionProps }) => {
    const actions = getEpisodeActions(showSyncActions);
    const intl = useIntl();

    return (
        <>
            {actions.map(action => {
                if (!action.isVisible(actionProps)) {
                    return null;
                }

                return (
                    <FeatureLock
                        key={action.id}
                        source={action.featureLockSource}
                        title={
                            action.featureLockTitle
                                ? intl.formatMessage({ id: action.featureLockTitle })
                                : undefined
                        }
                        description={
                            action.featureLockDescription
                                ? intl.formatMessage({ id: action.featureLockDescription })
                                : undefined
                        }
                        enabled={action.featureLockSource !== undefined}
                    >
                        <ActionButton
                            key={action.id}
                            icon={action.icon}
                            label={action.label}
                            onClick={() => action.onClick(actionProps)}
                        />
                    </FeatureLock>
                );
            })}
        </>
    );
};
