import { Button } from 'components/Button';
import { FolderImage } from 'components/FolderImage';
import { GenericErrorMessage } from 'components/GenericErrorMessage';
import { ARIA_DESCRIBEDBY_ID } from 'components/Modal/Modal';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useTracks from 'hooks/useTracks';
import { FormStatus } from 'model/types';
import React, { FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { api } from 'services/api';
import * as fromFoldersActions from '../../../../redux/actions/folders.actions';
import * as fromPodcastsActions from '../../../../redux/actions/podcasts.actions';
import { getFolder, getPodcastUuidsByFolderUuid } from '../../../../redux/reducers/selectors';
import { ErrorMessage, Footer, FormSection } from './FolderDeleteForm.styled';

export type Props = {
    folderUuid: string;
    onSuccess: () => void;
};

const FolderDeleteForm = ({ folderUuid, onSuccess }: Props) => {
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const { folder } = useSelector(state => ({
        folder: getFolder(state, folderUuid),
    }));

    const podcastUuidsByFolderUuid = useSelector(getPodcastUuidsByFolderUuid) as Record<
        string,
        string[]
    >;

    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);

    const submitForm = (e: FormEvent) => {
        e.preventDefault();

        setFormStatus(FormStatus.SUBMITTING);

        api.deleteFolder(folderUuid).then(
            () => {
                setFormStatus(FormStatus.READY);
                onSuccess();

                dispatch(
                    fromPodcastsActions.Actions.rearrangePodcastList([['REMOVE', folderUuid]]),
                );
                dispatch(fromFoldersActions.Actions.removeFolder(folderUuid));
                recordEvent('folder_deleted');
            },
            () => {
                setFormStatus(FormStatus.ERROR);
            },
        );
    };

    if (!folder) {
        return (
            <p>
                <GenericErrorMessage />
            </p>
        );
    }

    return (
        <form onSubmit={submitForm}>
            <FormSection aria-hidden>
                <FolderImage
                    color={folder.color}
                    name={folder.name}
                    podcastUuids={podcastUuidsByFolderUuid[folderUuid]}
                    sortType={folder.sortType}
                    size={120}
                />
            </FormSection>
            <FormSection id={ARIA_DESCRIBEDBY_ID}>
                <FormattedMessage id="confirm-delete-folder" />
            </FormSection>
            <Footer>
                {formStatus === FormStatus.ERROR && (
                    <ErrorMessage>
                        <GenericErrorMessage />
                    </ErrorMessage>
                )}
                <Button
                    loading={formStatus === FormStatus.SUBMITTING}
                    type="submit"
                    kind="primary-red"
                >
                    <FormattedMessage id="delete-folder" />
                </Button>
            </Footer>
        </form>
    );
};

export default FolderDeleteForm;
