import * as Sentry from '@sentry/browser';
import { safeSpeed } from 'helper/SpeedHelper';
import { EpisodeChapter } from 'model/types';
import { createSelector } from 'reselect';
import { RootState } from '..';
import { getAllEpisodesShowNotes, getEpisodeShowNotes } from './episode-show-notes.selector';
import { getEpisodeSyncByUuid } from './episode-sync.selectors';
import { getSettings } from './settings.selectors';

// stats
export const getStats = (state: RootState) => state.stats;

// user
export const getEmail = (state: RootState) => state.user.email || '';

// player
export const isLoadingRecommendedEpisodes = (state: RootState) =>
    state.player.loadingRecommendedEpisodes;
export const getCurrentRecommendations = (state: RootState) => state.player.recommendedEpisodes;
export const getPlayingEpisode = (state: RootState) =>
    getPlayerIsPlaying(state) ? getPlayerEpisode(state) : undefined;
export const getPlayerIsPlaying = (state: RootState) => state.player.isPlaying;
export const getPlayerEpisode = (state: RootState) => state.player.episode;
export const getChaptersOpen = (state: RootState) => state.player.chaptersOpen;
export const getDeselectedChapters = (state: RootState) => state.player.deselectedChapters;

const chaptersWithEndTime = (chapters: EpisodeChapter[], duration?: number) =>
    chapters.map((chapter, i) => {
        let endTime;

        // The chapter already has an end time
        if (chapter.endTime) {
            endTime = chapter.endTime;
        }
        // The chapter is not the last one and the next chapter has a start time
        else if (i < chapters.length - 1) {
            endTime = chapters[i + 1].startTime;
        }
        // The chapter is the last one and there's a total duration
        else if (i === chapters.length - 1 && duration) {
            endTime = duration;
        }

        return {
            ...chapter,
            endTime,
        };
    });

export const getPlayerEpisodeUuid = (state: RootState) => state.player.episode?.uuid;

export const getIsAccordionEpisode = (state: RootState) => state.player.episode?.isAccordionEpisode;

export const getPlayerChapters = (state: RootState) => state.player.chapters;

/**
 * Selects the chapters for the episode based on three possible sources:
 * - Podcast Index (state.player.chapters)
 * - PodLove (state.episodeShowNotes[UUID].chapters)
 * - Embedded in media (state.player.chapters)
 *
 * Spec here: https://pocketcastsp2.wordpress.com/2023/09/26/podcast-standard-tags-chapters/
 */
const rawGetChaptersForPlayerEpisode = createSelector(
    [getAllEpisodesShowNotes, getPlayerEpisodeUuid, getIsAccordionEpisode, getPlayerChapters],
    (allEpisodesShowNotes, playerEpisodeUuid, isAccordionEpisode, playerChapters) => {
        const episodeShowNotes = allEpisodesShowNotes[playerEpisodeUuid];

        // Chapter markers will be incorrect when playing an accordion episode
        if (isAccordionEpisode) {
            return [];
        }

        // When there's no chapters on the show notes, use the player chapters
        if (!episodeShowNotes?.chapters) {
            return formatChapters(playerChapters);
        }

        // When chapters exist both places, use the one with the most chapters
        if (episodeShowNotes.chapters.length > playerChapters.length) {
            return formatChapters(episodeShowNotes.chapters);
        }
        if (playerChapters.length > episodeShowNotes.chapters.length) {
            return formatChapters(playerChapters);
        }

        // When both have equal number of chapters, we pick in order Podcast Index, PodLove,
        // Embedded media. We know state.player.chapters is from Podcast Index when the
        // show notes has a chaptersUrl property.
        return formatChapters(
            episodeShowNotes.chaptersUrl ? playerChapters : episodeShowNotes.chapters,
        );
    },
);

// Format the chapters to include a realIndex property and filter out any chapters that have toc: false
const formatChapters = (chapters: EpisodeChapter[]) => {
    return chapters
        .map((chapter, i) => {
            chapter.realIndex = i;
            return chapter;
        })
        .filter(chapter => chapter.toc !== false);
};

export const getChaptersForPlayerEpisode = createSelector(
    [rawGetChaptersForPlayerEpisode, getPlayerEpisode],
    (chapters, episode) => {
        return chaptersWithEndTime(chapters, episode?.duration);
    },
);

export const getDeselectedChaptersForPlayerEpisode = (state: RootState) =>
    state.player.deselectedChapters;

// Get the chapter index for the current playing episode, either at the passed in seekTo time or the current playedUpTo
export const getCurrentPlayingChapterPosition = (state: RootState, seekTo?: number) => {
    const playingEpisode = getPlayerEpisode(state);

    const playedUpTo = seekTo ?? playingEpisode?.playedUpTo;
    const chapters = getChaptersForPlayerEpisode(state);

    if (!chapters || playedUpTo < 0) {
        return 0;
    }
    for (let i = chapters.length - 1; i >= 0; i -= 1) {
        const chapter = chapters[i];
        if (playedUpTo >= chapter.startTime) {
            return i;
        }
    }
    return 0;
};

export const getEpisodeImageUrl = (state: RootState, episodeUuid: string) =>
    getEpisodeShowNotes(state, episodeUuid)?.image;

export const getPlayerChapterIndex = (state: RootState) => {
    const { playedUpTo } = getEpisodeSyncByUuid(state, state.player.episode?.uuid) ?? {};
    const chapters = getChaptersForPlayerEpisode(state);

    if (!chapters || playedUpTo < 0) {
        return -1;
    }
    for (let i = chapters.length - 1; i >= 0; i -= 1) {
        const chapter = chapters[i];
        if (playedUpTo >= chapter.startTime) {
            return i;
        }
    }
    return -1;
};

const getRawSpeedForPodcast = (state: RootState) => {
    const { episode } = state.player;
    const podcastUuid = episode?.podcastUuid;
    const settings = getSettings(state);

    if (!episode || !podcastUuid) {
        return settings.playbackSpeed;
    }

    const podcastSettings = state.podcasts.uuidToPodcast[podcastUuid]?.settings;

    if (
        !podcastSettings ||
        !podcastSettings.playbackEffects ||
        !podcastSettings.playbackEffects.value
    ) {
        return settings.playbackSpeed;
    }

    return podcastSettings?.playbackSpeed?.value ?? settings.playbackSpeed;
};

// See https://github.com/Automattic/pocket-casts-webplayer/issues/2617
export const getSpeedForPodcast = (state: RootState) => {
    const rawSpeed = getRawSpeedForPodcast(state);
    const speed = safeSpeed(rawSpeed);

    // This code is here to help us debug the source of '0x' speed values
    if (speed !== rawSpeed) {
        const { episode } = state.player;
        const podcastUuid = episode?.podcastUuid;
        const settings = getSettings(state);
        const podcastSettings = state.podcasts.uuidToPodcast[podcastUuid]?.settings;

        // Log to sentry some debugging information
        Sentry.captureMessage(
            `Speed for podcast ${state.player.episode?.podcastUuid} was adjusted from ${rawSpeed} to ${speed}`,
            {
                extra: {
                    rawSpeed,
                    speed,
                    settingsPlaybackSpeed: settings.playbackSpeed,
                    podcastSettingsPlaybackEffectsValue: podcastSettings?.playbackEffects?.value,
                    podcastSettingsPlaybackSpeedValue: podcastSettings?.playbackSpeed?.value,
                },
            },
        );
    }

    return speed;
};

export const getPlayerPlaybackEffects = (state: RootState) => {
    const { episode } = state.player;
    const podcastUuid = episode?.podcastUuid;

    if (!episode || !podcastUuid) {
        return false;
    }

    const podcastSettings = state.podcasts.uuidToPodcast[podcastUuid]?.settings;
    return podcastSettings?.playbackEffects?.value ?? false;
};

// podcast
export const getOpenPodcastData = (state: RootState) => state.podcast;

// uploadManager
export const getUploadManagerFilesOrder = (state: RootState) => state.uploadManager.filesOrder;
export const getUploadManagerFiles = (state: RootState) => state.uploadManager.files;
export const getUploadManagerImages = (state: RootState) => state.uploadManager.images;
