import { ErrorMessageOnPlayFileNotUploaded } from 'components/messages';
import { PlayerState } from 'model/types';
import { PlayingStatus } from '../../helper/PlayingStatus';
import {
    LOCAL_STORAGE_KEY_MUTED,
    LOCAL_STORAGE_KEY_SPEED,
    LOCAL_STORAGE_KEY_VOLUME,
} from '../../model/local-storage';
import * as fromPlayerActions from '../actions/player.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromUpNextActions from '../actions/up-next.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';

const getLocalStorageSpeed = () =>
    localStorage.getItem(LOCAL_STORAGE_KEY_SPEED) === null
        ? 1
        : parseFloat(localStorage.getItem(LOCAL_STORAGE_KEY_SPEED) ?? '');

const getLocalStorageVolume = () =>
    localStorage.getItem(LOCAL_STORAGE_KEY_VOLUME) === null
        ? 1
        : parseFloat(localStorage.getItem(LOCAL_STORAGE_KEY_VOLUME) ?? '');

const getLocalStorageMuted = () =>
    localStorage.getItem(LOCAL_STORAGE_KEY_MUTED) === null
        ? false
        : localStorage.getItem(LOCAL_STORAGE_KEY_MUTED) === 'true';

export const INITIAL_STATE = {
    episode: null,
    buffering: false,
    isPlaying: false,
    lastSeekTo: null,
    speed: getLocalStorageSpeed(),
    lastSentPlayedUpTo: -1,
    lastSentPlayingStatus: null,
    errorMessage: '',
    volume: getLocalStorageVolume(),
    muted: getLocalStorageMuted(),
    videoMode: 1,
    recommendedEpisodes: [],
    loadingRecommendedEpisodes: true,
    videoWidth: 0,
    videoHeight: 0,
    isAccordionEpisode: false,
    chapters: [],
    chaptersOpen: false,
    deselectedChapters: [],
};

export default (
    state: PlayerState = INITIAL_STATE,
    action:
        | fromPlayerActions.Actions
        | fromPodcastActions.Actions
        | fromUploadedFilesActions.Actions
        | fromUpNextActions.Actions,
): PlayerState => {
    switch (action.type) {
        case fromPlayerActions.ActionTypes.CLOSE_PLAYER: {
            return {
                ...INITIAL_STATE,
                recommendedEpisodes: state.recommendedEpisodes,
                loadingRecommendedEpisodes: state.loadingRecommendedEpisodes,
                // use the most recent local storage settings
                speed: getLocalStorageSpeed(),
                volume: getLocalStorageVolume(),
            };
        }
        case fromPlayerActions.ActionTypes.LOAD_EPISODE: {
            const playing = action.payload.isPlaying || state.isPlaying;
            const data = {
                isPlaying: playing,
                buffering: playing,
                episode: action.payload.episode,
                errorMessage: '',
                recommendedEpisodes: [],
                chapters: [],
                unplayableFile: false,
            };
            return { ...state, ...data };
        }
        case fromPlayerActions.ActionTypes.LOAD_UNPLAYABLE_FILE: {
            return {
                ...state,
                isPlaying: false,
                buffering: false,
                episode: action.payload.episode,
                errorMessage: ErrorMessageOnPlayFileNotUploaded(),
                recommendedEpisodes: [],
                chapters: [],
                unplayableFile: true,
            };
        }
        case fromPlayerActions.ActionTypes.PLAYER_FAILED: {
            return { ...state, errorMessage: action.payload.errorMessage };
        }
        case fromPlayerActions.ActionTypes.UPDATE_BUFFERING: {
            return { ...state, buffering: action.payload.buffering };
        }
        case fromPlayerActions.ActionTypes.PAUSE: {
            return { ...state, isPlaying: false };
        }
        case fromPlayerActions.ActionTypes.PLAY: {
            return { ...state, isPlaying: true, errorMessage: '' };
        }
        case fromPlayerActions.ActionTypes.SEEK_TO: {
            return { ...state, lastSeekTo: action.payload.seekTo };
        }
        case fromPlayerActions.ActionTypes.SAVE_PROGRESS: {
            if (state.episode && state.episode.uuid === action.payload.episodeUuid) {
                return {
                    ...state,
                    episode: {
                        ...state.episode,
                        playedUpTo: action.payload.playedUpTo,
                    },
                };
            }
            return state;
        }
        case fromPlayerActions.ActionTypes.EPISODE_FINISHED: {
            if (state.episode && state.episode.uuid === action.payload.episode.uuid) {
                return {
                    ...state,
                    episode: {
                        ...state.episode,
                        playingStatus: PlayingStatus.COMPLETED,
                    },
                };
            }
            return state;
        }
        case fromPlayerActions.ActionTypes.UPDATE_SPEED: {
            return { ...state, speed: action.payload.speed };
        }
        case fromPlayerActions.ActionTypes.UPDATE_VOLUME: {
            return { ...state, volume: action.payload.volume };
        }
        case fromPlayerActions.ActionTypes.UPDATE_MUTED: {
            return { ...state, muted: action.payload.muted };
        }
        case fromPlayerActions.ActionTypes.UPDATE_VIDEO_MODE: {
            return { ...state, videoMode: action.payload.mode };
        }
        case fromPlayerActions.ActionTypes.UPDATE_VIDEO_SIZE: {
            return {
                ...state,
                videoWidth: action.payload.width,
                videoHeight: action.payload.height,
            };
        }
        case fromPlayerActions.ActionTypes.UPDATE_CHAPTERS: {
            return { ...state, chapters: action.payload.chapters };
        }

        case fromPlayerActions.ActionTypes.UPDATE_CHAPTERS_DESELECTION: {
            return { ...state, deselectedChapters: action.payload.deselectedChapters };
        }

        case fromPlayerActions.ActionTypes.OPEN_CHAPTERS: {
            return { ...state, chaptersOpen: true };
        }

        case fromPlayerActions.ActionTypes.CLOSE_CHAPTERS: {
            return { ...state, chaptersOpen: false };
        }

        case fromPlayerActions.ActionTypes.LOADING_RECOMMENDED_EPISODES: {
            return { ...state, recommendedEpisodes: [], loadingRecommendedEpisodes: true };
        }
        case fromPlayerActions.ActionTypes.LOADED_RECOMMENDED_EPISODES: {
            return {
                ...state,
                recommendedEpisodes: action.payload.episodes,
                loadingRecommendedEpisodes: false,
            };
        }
        case fromPlayerActions.ActionTypes.UPDATE_PLAYED_UP_TO: {
            if (state.episode) {
                return {
                    ...state,
                    episode: {
                        ...state.episode,
                        playedUpTo: action.payload.playedUpTo,
                    },
                };
            }
            return state;
        }
        case fromPlayerActions.ActionTypes.UPDATE_DURATION: {
            return {
                ...state,
                ...state,
                episode: {
                    ...state.episode,
                    duration: action.payload.duration,
                },
            };
        }
        case fromPlayerActions.ActionTypes.UPDATE_PLAYING_STATUS: {
            return {
                ...state,
                ...state,
                episode: {
                    ...state.episode,
                    playingStatus: action.payload.playingStatus,
                },
            };
        }
        case fromPlayerActions.ActionTypes.SAVE_PROGRESS_SUCCESS: {
            if (state.episode && state.episode.uuid === action.payload.episodeUuid) {
                return {
                    ...state,
                    ...state,
                    episode: {
                        ...state.episode,
                        lastSentPlayedUpTo: action.payload.lastSentPlayedUpTo,
                        lastSentPlayingStatus: action.payload.lastSentPlayingStatus,
                    },
                };
            }
            return state;
        }
        case fromPodcastActions.ActionTypes.STAR_EPISODE: {
            const { episodeUuid, starred } = action.payload;
            if (state.episode && state.episode.uuid === episodeUuid) {
                return {
                    ...state,
                    ...state,
                    episode: {
                        ...state.episode,
                        starred,
                    },
                };
            }
            return state;
        }
        case fromUpNextActions.ActionTypes.UP_NEXT_SET_FILE_DETAILS: {
            const { fileUuid, title, imageUrl, exists } = action.payload;

            if (!state.episode) {
                return state;
            }

            if (state.episode.uuid !== fileUuid) {
                return state;
            }

            return {
                ...state,
                episode: {
                    ...state.episode,
                    title,
                    imageUrl,
                    exists,
                },
            };
        }
        case fromUploadedFilesActions.ActionTypes.FILE_FETCH_SUCCESS: {
            const { uuid } = action.payload;

            if (!state.episode) {
                return state;
            }

            if (state.episode.uuid !== uuid) {
                return state;
            }

            const { title, imageUrl, color } = action.payload;

            return {
                ...state,
                ...state,
                episode: {
                    ...state.episode,
                    title,
                    imageUrl,
                    color,
                },
            };
        }
        case fromPlayerActions.ActionTypes.UPDATE_AUDIO_URL: {
            return {
                ...state,
                episode: {
                    ...state.episode,
                    url: action.payload.audioUrl,
                },
            };
        }
        case fromPlayerActions.ActionTypes.UPDATE_IS_ACCORDION_EPISODE: {
            return {
                ...state,
                episode: {
                    ...state.episode,
                    isAccordionEpisode: action.payload.isAccordionEpisode,
                },
            };
        }
        default:
            return state;
    }
};
