import { PodcastTintColors } from 'model/types';
import { rgba } from 'polished';

export const DARK_THEME = {
    name: 'dark',

    getOnBackgroundTint: (colors?: { onLightBackground?: string; onDarkBackground?: string }) => {
        if (colors?.onDarkBackground) {
            return colors?.onDarkBackground;
        }
        // primary-text-01
        return '#FFFFFF';
    },
    getLightOrDarkTint: (colors?: PodcastTintColors) => {
        if (colors?.darkTint) {
            return colors.darkTint;
        }
        return '#03A9F4';
    },

    // These are one to one mappings with what was previously defined as scss variables (without the
    // light/dark prefix)  as well as those that were already custom theme variables. I've put them in
    // a legacyScss structure to make it easy to refactor existing scss without problems
    // and then to replace these with the correct tokens once all scss is replaced.
    legacyScss: {
        'fixme-popup-background': rgba('#000000', 0.7),
        'theme-background': '#060606',
        'theme-background-hover': '#1a1a1a',
        'theme-popup-background': '#1f1f21',
        'theme-text-primary': '#ffffff',
        'theme-text-secondary': '#7f858f',
        'theme-text-disabled': '#5f6568',
        'theme-text-link': '#03a9f4',
        'theme-interactive': '#03a9f4',
        'theme-interactive-secondary': '#5f6568',
        'theme-text-link-warning': '#f44336',
        'theme-line': '#242527',
        'theme-icon-hover': '#3f3f3f',
        'theme-paragraph': '#fff',
        'theme-button-disabled': '#29292b',
        'theme-button-disabled-text': '#b8c3c9',
        'theme-success': '#47c705',
        'navigation-background': '#121213',
        'navigation-background-hover': '#3f403f',
        'navigation-text': '#ffffff',
    },

    modernStyle: {
        border: '#7e7e7e',
    },

    tokens: {
        'primary-ui-01': '#292B2E',
        'primary-ui-01-active': '#383A3D',
        'primary-ui-02': '#1A1B1D',
        'primary-ui-02-selected': '#33B8F4',
        'primary-ui-02-active': '#222427',
        'primary-ui-03': '#161718',
        'primary-ui-04': '#161718',
        'primary-ui-05': '#393A3C',
        'primary-ui-05-selected': '#8F97A4',
        'primary-ui-06': '#303337',
        'primary-ui-07': '#B3B3B3',
        'primary-icon-01': '#33B8F4',
        'primary-icon-01-active': '#64C7F4',
        'primary-icon-02': '#8F97A4',
        'primary-icon-02-selected': '#33B8F4',
        'primary-icon-02-active': '#EFF3F5',
        'primary-icon-02-disabled': '#29292b',
        'primary-icon-03': '#686C74',
        'primary-icon-03-active': '#33B8F4',
        'primary-text-01': '#FFFFFF',
        'primary-text-02': '#9C9FA4',
        'primary-text-02-selected': '#33B8F4',
        'primary-field-01': rgba('#EFF3F5', 0.1),
        'primary-field-01-active': rgba('#FBFBFC', 0.15),
        'primary-field-02': rgba('#FBFBFC', 0.11),
        'primary-field-02-active': rgba('#FBFBFC', 0.15),
        'primary-field-03': rgba('#B8C3C9', 0.5),
        'primary-field-03-active': '#33B8F4',
        'primary-interactive-01': '#33B8F4',
        'primary-interactive-01-hover': '#50D0F1',
        'primary-interactive-01-active': '#8AE9FF',
        'primary-interactive-01-disabled': rgba('#33B8F4', 0.25),
        'primary-interactive-02': '#292B2E',
        'primary-interactive-02-hover': rgba('#33B8F4', 0.95),
        'primary-interactive-02-active': rgba('#33B8F4', 0.9),
        'primary-interactive-03': '#595959',

        'secondary-ui-01': '#292B2E',
        'secondary-ui-02': '#383A3C',
        'secondary-icon-01': '#33B8F4',
        'secondary-icon-02': '#686C74',
        'secondary-text-01': '#FFFFFF',
        'secondary-text-02': '#686C74',
        'secondary-field-01': '#FFFFFF',
        'secondary-field-01-active': '#FBFBFC',
        'secondary-interactive-01': '#33B8F4',
        'secondary-interactive-01-hover': '#64C7F4',
        'secondary-interactive-01-active': '#8AE9FF',

        'podcast-ui-01': '#292B2E',
        'podcast-ui-02': '',
        'podcast-ui-03': '',
        'podcast-ui-04': '#383A3C',
        'podcast-ui-05': '',
        'podcast-ui-06': '',
        'podcast-icon-01': '',
        'podcast-icon-02': '',
        'podcast-icon-03': '#FFFFFF',
        'podcast-text-01': '',
        'podcast-text-02': '',
        'podcast-interactive-01': '',
        'podcast-interactive-01-active': '',
        'podcast-interactive-02': '#292B2E',
        'podcast-interactive-03': '',
        'podcast-interactive-03-active': '',
        'podcast-interactive-04': '#000000',
        'podcast-interactive-05': '',
        'podcast-onDark': '',
        'podcast-onLight': '',

        'filter-ui-01': '#292B2E',
        'filter-ui-02': '',
        'filter-ui-03': '',
        'filter-ui-04': '#383A3C',
        'filter-icon-01': '',
        'filter-icon-02': '',
        'filter-text-01': '',
        'filter-text-02': '',
        'filter-interactive-01': '',
        'filter-interactive-01-active': '',
        'filter-interactive-02': '#292B2E',
        'filter-interactive-03': '',
        'filter-interactive-03-active': '',
        'filter-interactive-04': '#292B2E',
        'filter-interactive-05': '#686C74',
        'filter-interactive-06': '#686C74',

        'support-01': '#33B8F4',
        'support-02': '#78D549',
        'support-03': '#03A9F4',
        'support-04': '#4490DC',
        'support-05': '#F43E37',
        'support-06': '#7C8A94',
        'support-07': '#292B2E',
        'support-08': '#EB9D4F',
        'support-09': '#161718',
        'support-10': '#F5CA5B',

        'player-background-01': '',
        'player-background-02': '',
        'player-highlight-01': '',
        'player-highlight-02': '',
        'player-highlight-03': '',
        'player-highlight-04': '',
        'player-highlight-05': '',
        'player-highlight-06': '',
        'player-highlight-07': '',
        'player-contrast-01': '#FFFFFF',
        'player-contrast-02': rgba('#FFFFFF', 0.5),
        'player-contrast-03': rgba('#FFFFFF', 0.4),
        'player-contrast-04': rgba('#FFFFFF', 0.3),
        'player-contrast-05': rgba('#FFFFFF', 0.2),
        'player-contrast-06': rgba('#FFFFFF', 0.1),

        'contrast-01': '#FFFFFF',
        'contrast-02': rgba('#FFFFFF', 0.7),
        'contrast-03': rgba('#FFFFFF', 0.5),
        'contrast-04': rgba('#FFFFFF', 0.1),

        'filter-01': '#DF514C', // Red
        'filter-02': '#EB9D4F', // Orange
        'filter-03': '#EABD49', // Yellow
        'filter-04': '#7FBF5F', // Green
        'filter-05': '#1BA0DC', // Blue
        'filter-06': '#9D79F2', // Purple
        'filter-07': '#D24D7A', // Pink
        'filter-08': '#BDC2C4', // Grey
        'filter-09': '#FAA5C1', // Lilac
        'filter-10': '#03CEA4', // Teal
        'filter-11': '#5584FA', // Navy Blue
        'filter-12': '#A3863B', // Brown

        veil: rgba(0, 0, 0, 0.5),

        'gradient-01-a': '#D7B32E',
        'gradient-01-e': '#C18000',
        'gradient-02-a': '#3993AA',
        'gradient-02-e': '#008CCB',
        'gradient-03-a': '#C9522E',
        'gradient-03-e': '#B82E3C',
        'gradient-04-a': '#7FB74F',
        'gradient-04-e': '#508D32',
        'gradient-05-a': '#BC4E7B',
        'gradient-05-e': '#6B59C7',

        'image-filter-01': '#FFFFFF',
        'image-filter-02': '#FFFFFF',
        'image-filter-03': '#FFFFFF',
        'image-filter-04': '#FFFFFF',

        'category-01': '#F8574D',
        'category-02': '#09B7DF',
        'category-03': '#FEDD4A',
        'category-04': '#22ABEA',
        'category-05': '#53B63F',
        'category-06': '#BCC0C8',
        'category-07': '#D17D00',
        'category-08': '#A8E666',
        'category-09': '#FDBBE1',
        'category-10': '#CB8FE9',
        'category-11': '#E62B7D',
        'category-12': '#9297A2',
        'category-13': '#FFC453',
        'category-14': '#70E3F7',
        'category-15': '#E62B7D',
        'category-16': '#007DD1',
        'category-17': '#E0C1F6',
        'category-18': '#E82D27',
        'category-19': '#8245DC',

        'action-01': rgba('#9C9FA4', 0.7),
        'action-01-active': rgba('#9C9FA4', 1),
    },
};
