import { FilterEpisode, FilterId } from 'model/types';
import { RootState } from '../../reducers';
import { getEpisodeSyncByUuid } from './episode-sync.selectors';

export const getFilterEpisodesWithSync = (
    state: RootState,
    id: FilterId,
): FilterEpisode[] | undefined =>
    state.filter.episodes[id]?.map(episode => ({
        ...episode,
        ...getEpisodeSyncByUuid(state, episode.uuid),
    }));
