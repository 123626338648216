import { Icon } from 'components/Icon';
import { isThisYear, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { DynamicPlayPauseCircle } from '../../../components/DynamicPlayPauseCircle';
import { DurationStringFromSeconds } from '../../../helper/DurationHelper';
import { PlayingStatus } from '../../../helper/PlayingStatus';
import { getSizeStringFromBytes } from '../../../helper/UploadedFilesHelper';
import {
    LOCAL_STORAGE_KEY_FILES,
    LOCAL_STORAGE_KEY_FILES_SORT_COLUMN,
    LOCAL_STORAGE_KEY_FILES_SORT_ORDER,
} from '../../../model/local-storage';
import { getThemeFromId } from '../../../model/theme';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../model/uploaded-files';
import { blue } from '../../../styles';
import FilePlayCircle from './FilePlayCircle';
import FileUploadButton from './FileUploadButton';
import UploadedFileRowActions from './UploadedFileRowActions';
import UsageTooltip from './UsageTooltip';
import {
    ActionsHeading,
    ActionsTh,
    Dot,
    FilesListWrapper,
    FinalColumnWrapper,
    FirstTd,
    Heading,
    ImageWrapper,
    LastTd,
    LightText,
    NameCell,
    NameWithIcon,
    NameWrapper,
    Percentage,
    PlayCircleWrapper,
    StyledFilesTable,
    Td,
    Th,
    Tr,
    TrHeader,
    UpNextIndicationWrapper,
} from './styled';

export class FilesList extends Component {
    constructor(props) {
        super(props);

        const sortColumn =
            localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_COLUMN) ||
            LOCAL_STORAGE_KEY_FILES.COLUMN_NAME;
        const sortOrder =
            localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_ORDER) ||
            LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING;

        this.state = {
            usageHover: false,
            sortColumn,
            sortOrder,
        };
    }

    headingClick = column => {
        const currentSortColumn =
            localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_COLUMN) ||
            LOCAL_STORAGE_KEY_FILES.COLUMN_NAME;

        const currentSortOrder =
            localStorage.getItem(LOCAL_STORAGE_KEY_FILES_SORT_ORDER) ||
            LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING;

        // If we click the same column, reverse the direction
        // Otherwise, sort the new column descending
        if (column === currentSortColumn) {
            const newSortOrder =
                currentSortOrder === LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING
                    ? LOCAL_STORAGE_KEY_FILES.ORDER_ASCENDING
                    : LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING;

            localStorage.setItem(LOCAL_STORAGE_KEY_FILES_SORT_ORDER, newSortOrder);

            this.props.sortFiles(currentSortColumn, newSortOrder);

            this.setState({
                sortOrder: newSortOrder,
            });
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_FILES_SORT_COLUMN, column);
            localStorage.setItem(
                LOCAL_STORAGE_KEY_FILES_SORT_ORDER,
                LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING,
            );

            this.props.sortFiles(column, LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING);

            this.setState({
                sortColumn: column,
                sortOrder: LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING,
            });
        }
    };

    calculateSortInfo = () => {
        const { sortColumn, sortOrder } = this.state;

        return {
            sortingName: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_NAME,
            sortingSize: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_SIZE,
            sortingDuration: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_DURATION,
            sortingDate: sortColumn === LOCAL_STORAGE_KEY_FILES.COLUMN_DATE,
            ascending: sortOrder === LOCAL_STORAGE_KEY_FILES.ORDER_ASCENDING,
            descending: sortOrder === LOCAL_STORAGE_KEY_FILES.ORDER_DESCENDING,
        };
    };

    onPlayClick = uuid => {
        this.props.playFile(uuid);
    };

    onPauseClick = () => {
        this.props.pause();
    };

    onMouseEnterPercentage = () => {
        this.setState({
            usageHover: true,
        });
    };

    onMouseLeavePercentage = () => {
        this.setState({
            usageHover: false,
        });
    };

    openEpisodeCard = file => () => {
        this.props.openFileAsEpisode({
            ...file,
            podcastUuid: UPLOADED_FILES_PODCAST_UUID,
        });
    };

    renderHeader() {
        const {
            account: { totalSize, usedSize },
        } = this.props;
        const { usageHover } = this.state;

        const sortInfo = this.calculateSortInfo();
        const { ascending } = sortInfo;

        const percentageFullPrecise = (100 * usedSize) / totalSize;
        const decimalPlaces = percentageFullPrecise < 1 ? 2 : 0;
        const percentageFull = percentageFullPrecise.toFixed(decimalPlaces);

        return (
            <TrHeader>
                <Th>
                    <Heading
                        $leftAlign
                        $isSortColumn={sortInfo.sortingName}
                        $ascending={ascending}
                        onClick={() => this.headingClick(LOCAL_STORAGE_KEY_FILES.COLUMN_NAME)}
                    >
                        <FormattedMessage id="name" />
                        &nbsp;&nbsp;
                        {sortInfo.sortingName && ascending && '▲'}
                        {sortInfo.sortingName && !ascending && '▼'}
                    </Heading>
                </Th>
                <Th>
                    <Heading
                        $leftAlign
                        $isSortColumn={sortInfo.sortingSize}
                        $ascending={ascending}
                        onClick={() => this.headingClick(LOCAL_STORAGE_KEY_FILES.COLUMN_SIZE)}
                    >
                        <FormattedMessage id="size" />
                        &nbsp;&nbsp;
                        {sortInfo.sortingSize && ascending && '▲'}
                        {sortInfo.sortingSize && !ascending && '▼'}
                    </Heading>
                </Th>
                <Th>
                    <Heading
                        $leftAlign
                        $isSortColumn={sortInfo.sortingDuration}
                        $ascending={ascending}
                        onClick={() => this.headingClick(LOCAL_STORAGE_KEY_FILES.COLUMN_DURATION)}
                    >
                        <FormattedMessage id="duration" />
                        &nbsp;&nbsp;
                        {sortInfo.sortingDuration && ascending && '▲'}
                        {sortInfo.sortingDuration && !ascending && '▼'}
                    </Heading>
                </Th>
                <Th>
                    <Heading
                        $leftAlign
                        $isSortColumn={sortInfo.sortingDate}
                        $ascending={ascending}
                        onClick={() => this.headingClick(LOCAL_STORAGE_KEY_FILES.COLUMN_DATE)}
                    >
                        <FormattedMessage id="upload-date" />
                        &nbsp;&nbsp;
                        {sortInfo.sortingDate && ascending && '▲'}
                        {sortInfo.sortingDate && !ascending && '▼'}
                    </Heading>
                </Th>
                <ActionsTh>
                    <ActionsHeading>
                        <Percentage
                            onMouseEnter={this.onMouseEnterPercentage}
                            onMouseLeave={this.onMouseLeavePercentage}
                            $percentage={percentageFullPrecise}
                        >
                            <span>
                                {percentageFull}% <FormattedMessage id="full" />
                            </span>
                            {usageHover && <UsageTooltip />}
                        </Percentage>
                        <Dot>•</Dot>
                        <FileUploadButton />
                    </ActionsHeading>
                </ActionsTh>
            </TrHeader>
        );
    }

    renderBody() {
        const { theme, playerIsPlaying, playerEpisodeUuid, uuidsInUpNextMap, files } = this.props;

        return files.map(file => {
            const { uuid, title, size, duration, published, playedUpTo, playingStatus, imageUrl } =
                file;

            const sizeString = getSizeStringFromBytes(size);

            // We don't know the URL yet, it will be fetched when needed.
            const fileAsEpisode = {
                uuid,
                title,
                podcast: UPLOADED_FILES_PODCAST_UUID,
                published,
            };

            const isPlayed = playingStatus === PlayingStatus.COMPLETED;

            return (
                <Tr key={`tr-${uuid}`} $played={isPlayed} onClick={this.openEpisodeCard(file)}>
                    <FirstTd>
                        <NameCell>
                            <ImageWrapper>
                                <img width="56" height="56" src={imageUrl} alt="" />
                            </ImageWrapper>
                            <NameWithIcon>
                                {uuidsInUpNextMap[uuid] && (
                                    <UpNextIndicationWrapper>
                                        <Icon id="up-next" />
                                    </UpNextIndicationWrapper>
                                )}
                                <NameWrapper>{title}</NameWrapper>
                            </NameWithIcon>
                        </NameCell>
                    </FirstTd>
                    <Td>
                        <LightText $played={isPlayed}>{sizeString}</LightText>
                    </Td>
                    <Td>
                        {
                            <LightText $played={isPlayed}>
                                {duration > 0 ? (
                                    <DurationStringFromSeconds
                                        durationSecsStrOrNum={duration}
                                        remaining={false}
                                        short={true}
                                    />
                                ) : (
                                    <FormattedMessage id="uploading-files-processing" />
                                )}
                            </LightText>
                        }
                    </Td>
                    <Td>
                        <LightText $played={isPlayed}>
                            {/* This matches the date format of Podcast episodes */}
                            {isThisYear(parseISO(published)) ? (
                                <FormattedDate value={published} day="numeric" month="long" />
                            ) : (
                                <FormattedDate
                                    value={published}
                                    day="numeric"
                                    month="short"
                                    year="numeric"
                                />
                            )}
                        </LightText>
                    </Td>
                    <LastTd>
                        <FinalColumnWrapper>
                            <PlayCircleWrapper>
                                {uuid === playerEpisodeUuid ? (
                                    <FilePlayCircle
                                        size={28}
                                        color={blue}
                                        playedColor={
                                            getThemeFromId(theme).legacyScss[
                                                'theme-button-disabled'
                                            ]
                                        }
                                        isPlayed={false}
                                        isPlaying={playerIsPlaying}
                                        duration={Number(duration)}
                                        onPauseClick={this.onPauseClick}
                                        onPlayClick={() => this.onPlayClick(uuid)}
                                    />
                                ) : (
                                    <DynamicPlayPauseCircle
                                        size={28}
                                        color={blue}
                                        playedColor={
                                            getThemeFromId(theme).legacyScss[
                                                'theme-button-disabled'
                                            ]
                                        }
                                        isPlayed={isPlayed}
                                        isPlaying={playerIsPlaying && playerEpisodeUuid === uuid}
                                        percent={(100 * playedUpTo) / Math.max(1, duration)}
                                        onPauseClick={this.onPauseClick}
                                        onPlayClick={() => this.onPlayClick(uuid)}
                                    />
                                )}
                            </PlayCircleWrapper>
                            <UploadedFileRowActions
                                theme={theme}
                                isInUpNext={!!uuidsInUpNextMap[uuid]}
                                isPlayed={isPlayed}
                                onPlayNextClick={() => {
                                    if (isPlayed) {
                                        this.props.markFileAsUnplayed(uuid);
                                    }
                                    return this.props.upNextPlayNext(fileAsEpisode);
                                }}
                                onPlayLastClick={() => {
                                    if (isPlayed) {
                                        this.props.markFileAsUnplayed(uuid);
                                    }
                                    return this.props.upNextPlayLast(fileAsEpisode);
                                }}
                                onRemoveFromUpNextClick={() => this.props.removeFromUpNext(uuid)}
                                onMarkAsPlayedClick={() => this.props.markFileAsPlayed(uuid)}
                                onMarkAsUnplayedClick={() => this.props.markFileAsUnplayed(uuid)}
                                onEditClick={() => this.props.editFile(uuid)}
                                onDownloadFileClick={() => this.props.downloadFile(uuid, title)}
                                onDeleteClick={() => this.props.deleteFile(uuid, title)}
                            />
                        </FinalColumnWrapper>
                    </LastTd>
                </Tr>
            );
        });
    }

    render() {
        return (
            <FilesListWrapper>
                <StyledFilesTable>
                    <tbody>
                        {this.renderHeader()}
                        {this.renderBody()}
                    </tbody>
                </StyledFilesTable>
            </FilesListWrapper>
        );
    }
}

export default FilesList;
