import React, {
    createContext,
    forwardRef,
    RefObject,
    useCallback,
    useContext,
    useImperativeHandle,
    useRef,
} from 'react';

interface ScrollingContextValue {
    ref: RefObject<HTMLDivElement>;
    scrollTo: (position: number) => void;
}

/**
 * This context is used to "mark" a scrolling container so that it can be scrolled to a specific position.
 * It will provide to consumers a ref to the scrolling container and a function to scroll to a specific position.
 */
const ScrollingContext = createContext<ScrollingContextValue | null>(null);

export const ScrollingContextProvider: React.FC = forwardRef(({ ...props }, ref) => {
    const scrollingRef = useRef<HTMLDivElement>(null);

    // Expose the internal ref to the parent component
    useImperativeHandle(ref, () => scrollingRef.current, [scrollingRef]);

    const scrollTo = useCallback((position: number) => {
        if (!scrollingRef.current) {
            return;
        }
        scrollingRef.current.scrollTop = position;
    }, []);

    return (
        <ScrollingContext.Provider value={{ ref: scrollingRef, scrollTo }} data-potato="potato">
            <div {...props} ref={scrollingRef} data-potato="potato2" />
        </ScrollingContext.Provider>
    );
});

export const useScrollingContext = (): ScrollingContextValue => {
    const context = useContext(ScrollingContext);
    if (!context) {
        throw new Error('useScrollingContext must be used within a ScrollingContextProvider');
    }
    return context;
};
