import { Icon } from 'components/Icon';
import { NavigationItems } from 'helper/NavigationHelper';
import { Location } from 'history';
import React, { MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Arrow, BackButtonStyled } from './BackButton.styled';

const getBackButtonFallbackUrl = (location: Location) => {
    const pathname = location ? location.pathname : null;
    if (pathname) {
        if (pathname.includes(NavigationItems.DISCOVER.path)) {
            return NavigationItems.DISCOVER.path;
        }
        if (pathname.includes(NavigationItems.PODCASTS.path)) {
            return NavigationItems.PODCASTS.path;
        }
        if (pathname.includes(NavigationItems.PROFILE.path)) {
            return NavigationItems.PROFILE.path;
        }
    }
    return '/';
};

export type Props = {
    buttonText: string | null;
    className?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

function BackButton({ className, buttonText, onClick }: Props) {
    const navigate = useNavigate();
    const location = useLocation();

    const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
            return;
        }

        if (location.state?.canGoBack) {
            navigate(-1);
        } else {
            const fallbackUrl = getBackButtonFallbackUrl(location);
            navigate(fallbackUrl);
        }
    };

    return (
        <BackButtonStyled className={className} onClick={onButtonClick} type="button">
            <Arrow>
                <Icon id="arrow-right" />
            </Arrow>
            {!!buttonText && buttonText}
        </BackButtonStyled>
    );
}

export default BackButton;
