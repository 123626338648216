import { Icon } from 'components/Icon';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { ReactNode, useState } from 'react';
import { Switch } from '../..';
import { useDispatch } from '../../../hooks/react-redux-typed';
import * as fromSubscriptionActions from '../../../redux/actions/subscription.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import { green } from '../../../styles';
import {
    IconWrapper,
    MarketingWrapper,
    SwitchWrapper,
    TextWrapper,
    VerticalSpace,
} from './MarketingRegistrationToggle.styled';

export type Props = {
    text?: ReactNode;
};

const MarketingRegistrationToggle = ({ text }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const [isOn, setIsOn] = useState(true);

    const handleToggle = () => {
        if (isOn) {
            dispatch(fromSubscriptionActions.Actions.marketingUnsubscribeRequest());
        } else {
            dispatch(fromSubscriptionActions.Actions.marketingSubscribeRequest());
        }
        setIsOn(!isOn);
        dispatch(
            fromTracksActions.Actions.recordEvent('newsletter_opt_in_changed', {
                enabled: !isOn,
                source: 'welcome_new_account',
            }),
        );
    };

    return (
        <MarketingWrapper>
            <IconWrapper>
                <Icon id="news" />
            </IconWrapper>
            <TextWrapper id="marketing-switch">
                {text ?? formatMessage('email-list-opt-in')}
            </TextWrapper>
            <VerticalSpace />
            <SwitchWrapper>
                <Switch
                    ariaLabelledBy="marketing-switch"
                    on={isOn}
                    onChange={handleToggle}
                    color={green}
                />
            </SwitchWrapper>
        </MarketingWrapper>
    );
};

export default MarketingRegistrationToggle;
