import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import classNames from 'classnames';
import { SlidingModal } from 'components/SlidingModal';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import ScreenReaderText from 'components/format/ScreenReaderText';
import { pauseKeyboardShortcuts, resumeKeyboardShortcuts } from 'helper/UiHelper';
import key from 'keymaster';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useNavigate } from 'react-router';
import { useDebounce } from 'react-use';
import { ListUpNextEpisodes } from '../../../components/EpisodesTable/ListUpNextEpisodes';
import { withAnalyticsContext } from '../../../context/AnalyticsContext';
import { useDispatch, useSelector } from '../../../hooks/react-redux-typed';
import useFormatMessage from '../../../hooks/useFormatMessage';
import useTracks from '../../../hooks/useTracks';
import { Episode, UpNextEpisode } from '../../../model/types';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import * as fromUpNextActions from '../../../redux/actions/up-next.actions';
import {
    getQueuedEpisodeUuids,
    getUpNextEpisodes,
    getUuidToPodcast,
} from '../../../redux/reducers/selectors';
import { getEpisodeSyncForUpNext } from '../../../redux/reducers/selectors/episode-sync.selectors';
import urls from '../../../urls';
import { UpNextEmptyMessage } from './UpNext.styled';
import UpNextEmptyIcon from './UpNextEmptyIcon';
import { UpNextToolbar } from './UpNextToolbar';

interface UpNextProps {
    isOpen: boolean;
    openUpNextEvent: () => void;
}

/**
 * When shuffle is enabled, multiple requests are sent to the server, and
 * the state updates multiple times in a row resulting in a flicker of the Up Next UI.
 *
 * This hook debounces the state updates and returns a stable array of uuids.
 */
function useDebouncedUuids(sorting: boolean) {
    const rawUuids = useSelector(getQueuedEpisodeUuids);
    const [stabilizedIds, setStabilizedIds] = useState<string[]>(rawUuids);
    const [debouncedSorting, setDebouncedSorting] = useState(sorting);
    const rawUuidsKey = rawUuids.join();

    /**
     * UUIDS debounce time has to be shorter than the sorting debounce time
     * to prevent excess updates to the debounced uuids array.
     */
    const SORTING_DEBOUNCE_MS = 500;
    const UUIDS_DEBOUNCE_MS = 300;

    /**
     * When user starts sorting: allow direct `debouncedUuids` updates via useLayoutEffect.
     * This allows for quickly ordering when user interaction is less than SORTING_DEBOUNCE_MS.
     */
    useEffect(() => {
        sorting && setDebouncedSorting(true);
    }, [sorting]);

    /**
     * When the user stops sorting, delay unsetting the sorting state to:
     * - Make sure `useLayoutEffect` has time to update the `stabilizedIds` array
     * - The regular debounced update doesn't override the `stabilizedIds` array
     */
    useDebounce(
        () => {
            if (!sorting) {
                setDebouncedSorting(false);
            }
        },
        SORTING_DEBOUNCE_MS,
        [sorting],
    );

    /**
     * Debounce all updates coming from the useSelector hook
     */
    useDebounce(
        () => {
            if (!sorting) {
                setStabilizedIds(rawUuids);
            }
        },
        UUIDS_DEBOUNCE_MS,
        [sorting, rawUuidsKey],
    );

    /**
     * When the user is sorting, immediately update the stable uuids array to avoid UI flicker on mouse release.
     */
    useLayoutEffect(
        () => {
            setStabilizedIds(uuids => {
                if (debouncedSorting) {
                    return rawUuids;
                }
                return uuids;
            });
        },
        // Don't re-render when the array reference changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [debouncedSorting, setStabilizedIds, rawUuidsKey],
    );

    return stabilizedIds;
}

const UpNext = ({ isOpen, openUpNextEvent }: UpNextProps) => {
    const [liveText, setLiveText] = useState('');
    const [sorting, setSorting] = useState(false);
    const formatMessage = useFormatMessage();
    const uuidToPodcast = useSelector(getUuidToPodcast);

    const episodes = useSelector(getUpNextEpisodes);
    const episodeSync = useSelector(getEpisodeSyncForUpNext);
    const uuids = useDebouncedUuids(sorting);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const playEpisode = (episodeUuid: string, podcastUuid: string) =>
        dispatch(
            fromPlayerActions.Actions.playEpisode(episodeUuid, podcastUuid, {
                eventSource: 'up_next',
            }),
        );

    const moveUpNextEpisode = (oldIndex: number, newIndex: number) =>
        dispatch(fromUpNextActions.Actions.moveUpNextEpisode(oldIndex, newIndex));

    const removeFromUpNext = (episodeUuid: string) =>
        dispatch(
            fromUpNextActions.Actions.removeFromUpNext(episodeUuid, {
                eventSource: 'up_next',
            }),
        );

    const closeUpNext = useCallback(() => dispatch(fromUpNextActions.Actions.closeUpNext()), []);

    const upNextPlayNext = (podcastUuid: string, episode: UpNextEpisode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayNext(podcastUuid, episode, { eventSource: null }),
        );

    const upNextPlayLast = (podcastUuid: string, episode: Episode) =>
        dispatch(
            fromUpNextActions.Actions.upNextPlayLast(podcastUuid, episode, { eventSource: null }),
        );

    const closeUnlessPopupsOpen = useCallback(() => {
        if (!isOpen) {
            return;
        }

        const modalRoot = document.getElementById('modal-root');
        if (modalRoot && modalRoot.childNodes.length > 0) {
            return;
        }

        closeUpNext();
    }, [closeUpNext, isOpen]);

    useEffect(() => {
        key('escape', closeUnlessPopupsOpen);

        return () => {
            key.unbind('escape');
            resumeKeyboardShortcuts();
        };
    }, [closeUnlessPopupsOpen]);

    useEffect(() => {
        if (isOpen) {
            openUpNextEvent();
        }
    }, [isOpen, openUpNextEvent]);

    const { recordEvent } = useTracks();

    const recordReorderEvent = (oldIndex: number, newIndex: number) => {
        recordEvent('up_next_queue_reordered', {
            direction: newIndex < oldIndex ? 'up' : 'down',
            slots: Math.abs(newIndex - oldIndex),
            is_next: newIndex === 0,
        });
    };

    const onSortStart = (event: DragStartEvent) => {
        const { active } = event;
        const index = uuids.findIndex(uuid => uuid === active.id);

        pauseKeyboardShortcuts(); // So that keyboard sorting doesn't trigger shortcuts — spacebar drops the episode but also plays/pauses the player
        const liveText = `${episodes[uuids[index]].title}, ${formatMessage(
            'grabbed',
        )}. ${formatMessage('current-position', {
            position: index + 1,
            count: uuids.length,
        })}. ${formatMessage('reorder-move-instructions')}`;
        setLiveText(liveText);
        setSorting(true);
    };

    const onSortOver = (event: DragOverEvent) => {
        const { over } = event;
        const newIndex = uuids.findIndex(uuid => uuid === over?.id);

        const liveText = `${formatMessage('moved')}. ${formatMessage('current-position', {
            position: newIndex + 1,
            count: uuids.length,
        })}.`;
        setLiveText(liveText);
    };

    const onSortEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        const oldIndex = uuids.findIndex(uuid => uuid === active.id);
        const newIndex = uuids.findIndex(uuid => uuid === over?.id);

        const episodeTitle = episodes[uuids[oldIndex]].title;
        let liveText;
        if (oldIndex === newIndex) {
            liveText = `${episodeTitle}, ${formatMessage('dropped-in-original-position')}.`;
        } else {
            liveText = `${episodeTitle}, ${formatMessage('dropped')}. ${formatMessage(
                'final-position',
                { position: newIndex + 1, count: uuids.length },
            )}.`;
        }
        setLiveText(liveText);
        setSorting(false);

        if (oldIndex === newIndex) {
            return;
        }
        recordReorderEvent(oldIndex, newIndex);
        moveUpNextEpisode(oldIndex, newIndex);
        resumeKeyboardShortcuts();
    };

    const handleOpenEpisode = (episode: Episode) => {
        recordEvent('up_next_queue_episode_tapped');
        navigate(urls.episodePath(episode.podcastUuid, episode.uuid));
    };

    const handlePlayNext = (podcastUuid: string, episode: Episode) => {
        upNextPlayNext(podcastUuid, episode);
        const oldIndex = uuids.indexOf(episode.uuid);
        const newIndex = 0;
        recordReorderEvent(oldIndex, newIndex);
    };

    const handlePlayLast = (podcastUuid: string, episode: Episode) => {
        upNextPlayLast(podcastUuid, episode);
        const oldIndex = uuids.indexOf(episode.uuid);
        const newIndex = uuids.length - 1;
        recordReorderEvent(oldIndex, newIndex);
    };

    const upNextEmpty = uuids.length === 0;

    const rows = useMemo(() => {
        return uuids
            .filter(uuid => !!episodes[uuid])
            .map(uuid => ({
                uuid,
                id: uuid,
                episode: episodes[uuid],
                episodeSync: episodeSync[uuid],
                podcast: episodes[uuid].podcast ? uuidToPodcast[episodes[uuid].podcast] : undefined,
            }));
    }, [episodeSync, episodes, uuidToPodcast, uuids]);

    return (
        <SlidingModal
            isOpen={isOpen}
            onClose={closeUpNext}
            className={classNames('centered-toolbar up-next', {
                sorting,
            })}
            title={formatMessage('up-next')}
            toolbar={<UpNextToolbar uuids={uuids} episodeSync={episodeSync} />}
        >
            {isOpen && (
                <>
                    <TrackOnMount event="up_next_shown" />
                    <TrackOnUnmount event="up_next_dismissed" />
                </>
            )}
            {upNextEmpty ? (
                <UpNextEmptyMessage>
                    <UpNextEmptyIcon />
                    <h2>
                        <FormattedMessage id="nothing-in-up-next" />
                    </h2>
                    <p>
                        <FormattedMessage id="add-to-up-next-instructions" />
                    </p>
                </UpNextEmptyMessage>
            ) : (
                <ListUpNextEpisodes
                    // @TODO: EpisodeTypes are incomplete,
                    // I don't know which type to use or if there
                    // even is a matching type.
                    // Fix this later, right now getting this to build.
                    // @ts-expect-error: TODO: fix this
                    rows={rows}
                    onSortStart={onSortStart}
                    onSortOver={onSortOver}
                    onSortEnd={onSortEnd}
                    openEpisode={handleOpenEpisode}
                    removeFromUpNext={removeFromUpNext}
                    playEpisode={playEpisode}
                    upNextPlayNext={handlePlayNext}
                    upNextPlayLast={handlePlayLast}
                />
            )}
            <ScreenReaderText>
                <p id="up-next-dnd-instructions">{formatMessage('reorder-start-instructions')}</p>
                <p aria-live="assertive">
                    <span key={liveText}>{liveText}</span>
                </p>
            </ScreenReaderText>
        </SlidingModal>
    );
};

export default withAnalyticsContext(UpNext);
