import {
    DynamicPlayPauseCircle,
    Props as DynamicPlayPauseCircleProps,
} from 'components/DynamicPlayPauseCircle';
import { Icon } from 'components/Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'styles';

export const RowContent = styled.div`
    border: 1px solid ${props => props.theme.tokens['player-contrast-06']};
    border-width: 1px 0;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
`;

export const RowCell = styled.div`
    margin: 0 10px;
`;

export const RowImage = styled.div`
    width: 46px;
    margin: 12px 0;
`;

export const RowTitle = styled.div<{ inactive?: boolean }>`
    flex: 1;
    overflow: hidden;
    margin: 0 20px 0 16px;
    h2 {
        ${Typography.Desktop.H65};
        color: ${props =>
            props.inactive
                ? props.theme.legacyScss['theme-text-secondary']
                : props.theme.tokens['player-contrast-01']};
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const RowAction = styled.button`
    color: ${props => props.theme.tokens['contrast-03']};
    padding: 10px;
    align-self: stretch;
    svg {
        visibility: hidden;
    }
    &:hover {
        color: ${props => props.theme.tokens['contrast-02']};
    }
`;

export const RowLink = styled.a<{ alwaysVisible?: boolean }>`
    color: ${props => props.theme.tokens['contrast-03']};
    padding: 10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    &:hover {
        color: ${props => props.theme.tokens['primary-interactive-01']};
    }
    ${props =>
        !props.alwaysVisible &&
        css`
            svg {
                visibility: hidden;
            }
            &:hover {
                color: ${props => props.theme.tokens['contrast-02']};
            }
        `}
`;

export const RowLinkPlaceholder = styled.span`
    width: 44px;
    content: ' ';
`;

export const RowPlayPauseButton = styled(DynamicPlayPauseCircle)<DynamicPlayPauseCircleProps>`
    color: ${props => props.theme.tokens['player-contrast-02']};
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover {
        color: ${props => props.theme.tokens['primary-interactive-01']};
    }
`;

const RowHandleWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 50px;
    margin-left: 10px;
    color: ${props => props.theme.tokens['player-contrast-04']};
    svg {
        cursor: row-resize;
    }
`;

export const RowHandle = () => (
    <RowHandleWrapper>
        <Icon id="drag-handle" />
    </RowHandleWrapper>
);

export const Row = styled.div<{
    $height?: number;
    $isDragging?: boolean;
    $isSortable?: boolean;
    disabled?: boolean;
}>`
    height: ${props => props.$height || 73}px;
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['player-contrast-02']};
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    user-select: none;

    // This padding is needed to make the dragged row look a bit nicer
    margin-left: -30px;
    padding-left: 30px;
    box-sizing: content-box;

    // Rows have top and bottom borders, this makes them intersect so only 1px border is seen
    margin-top: -1px;

    img {
        user-drag: none;
    }

    ${props =>
        props.$isDragging &&
        css`
            z-index: 31;
            background: ${props => props.theme.tokens['primary-ui-02-active']};
            box-shadow:
                0px 2px 8px rgba(255, 255, 255, 0.08),
                0px 0px 1px rgba(255, 255, 255, 0.55),
                0px 4px 16px rgba(255, 255, 255, 0.04);
            border-radius: 4px;
            ${RowContent} {
                border-width: 0;
            }
            ${RowTitle} h2 {
                color: ${props => props.theme.tokens['primary-interactive-01']};
            }
        `}

    ${props =>
        !props.$isDragging &&
        !props.disabled &&
        props.onClick &&
        `&:hover {
            cursor: pointer;
        }`}

    ${props =>
        !props.$isDragging &&
        props.disabled &&
        `
        opacity: 0.5;
        &:hover {
            cursor: default;
        }`}

    ${props =>
        !props.$isDragging &&
        `&:hover {
            ${RowTitle} h2 {
                color: ${props.theme.tokens['primary-interactive-01']};
            }
            ${RowAction} {
                svg {
                    visibility: visible;
                }
            }
            ${RowLink} {
                svg {
                    visibility: visible;
                }
            }
        }`}
`;
