import { PlayingStatus } from '../../helper/PlayingStatus';
import { EpisodeSyncInfo } from '../../model/types';

export const getPercentComplete = (
    playerPlayedUpTo: number,
    playedUpTo: number,
    episodeUuid: string,
    duration: number,
    playerEpisodeUuid?: string,
) => {
    const isCurrentlyPlaying = playerEpisodeUuid === episodeUuid;
    const currentPlayedTime =
        playerPlayedUpTo && isCurrentlyPlaying ? playerPlayedUpTo : playedUpTo;
    const safeDuration = duration || 1;
    return (currentPlayedTime * 100) / safeDuration;
};

type FauxEpisodeSync = {
    playedUpTo: number;
    playingStatus: number;
    starred: boolean;
    isDeleted: boolean;
    duration: number;
};
/**
 * The types are currently a mess,
 * this is a hopefully temporary solution while we figure
 * correct types and data flow.
 *
 * For now - this will take an episode and UUID Map and try
 * to build a EpisodeSyncInfo type gradually falling back to
 * either the episode or a default value.
 */
export const getFauxEpisodeSync = (
    episode: {
        uuid: string;
        playedUpTo?: number;
        playingStatus?: number;
        starred?: boolean;
        isDeleted?: boolean;
        duration?: number;
    },
    uuidMap?: Record<string, EpisodeSyncInfo>,
): FauxEpisodeSync => {
    const episodeSync = uuidMap?.[episode.uuid];

    return {
        playedUpTo: episodeSync?.playedUpTo ?? episode.playedUpTo ?? 0,
        playingStatus:
            episodeSync?.playingStatus ?? episode.playingStatus ?? PlayingStatus.NOT_PLAYED,
        starred: episodeSync?.starred ?? episode.starred ?? false,
        isDeleted: episodeSync?.isDeleted ?? episode.isDeleted ?? false,
        duration: episodeSync?.duration ?? episode.duration ?? 0,
    };
};
