import { EpisodeDiscover } from 'model/types';
import { connect } from 'react-redux';
import * as fromEpisodeActions from '../../../redux/actions/episode.actions';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import { RootState } from '../../../redux/reducers';
import { getPlayingEpisode } from '../../../redux/reducers/selectors';
import DiscoverSingleEpisodeComponent from './DiscoverSingleEpisode';

export const DiscoverSingleEpisode = connect(
    (state: RootState) => ({
        playingEpisodeUuid: getPlayingEpisode(state)?.uuid,
    }),
    {
        playEpisode: fromPlayerActions.Actions.playEpisode,
        pauseEpisode: fromPlayerActions.Actions.pause,
        openEpisode: (episode: EpisodeDiscover) =>
            fromEpisodeActions.Actions.openEpisode(
                {
                    // Episode data from Discover lists have a couple params different from
                    // what the action expects, so we'll add them in here:
                    ...episode,
                    file_size: episode.size,
                    podcastUuid: episode.podcast_uuid,
                },
                { eventSource: 'discover' },
            ),
    },
)(DiscoverSingleEpisodeComponent);
