import React from 'react';
import styled from 'styled-components';
import { PlayingStatus } from '../../../helper/PlayingStatus';
import { MOBILE_WIDTH, VIEWPORT_MINIMUM_WIDTH } from '../../../model/page';
import { AutoplayConfig, UpNextEpisode } from '../../../model/types';
import { isUploadedFile } from '../../../model/uploaded-files';
import { EpisodeActionButtons } from '../actions/EpisodeActionButtons';
import { EpisodePlayButton } from '../buttons/EpisodePlayButton';
import { MobileEpisodeActions } from '../buttons/MobileEpisodeActions';
import { EpisodeActions } from '../useEpisodeActions';
import { HoverActions } from './HoverActions';

type Props = {
    episodeUuid: string;
    podcastUuid: string;
    color?: string;
    isPlaying: boolean;
    autoplay?: AutoplayConfig;
    actions: EpisodeActions;
    showSyncActions: boolean;
    upNextEpisodes: Record<string, UpNextEpisode>;
    inHistory: boolean;
    isDeleted: boolean;
    playingStatus: number;
    title: string;
    duration: number;
    url: string;
    percentComplete: number;
    isPlayerEpisode: boolean;
    isStarred?: boolean;
};

const EpisodeActionsWrapper = styled.div`
    display: none;

    .episode-row & {
        @media (min-width: ${MOBILE_WIDTH}px) and (max-width: ${VIEWPORT_MINIMUM_WIDTH}px) {
            display: grid;
            grid-column: 5 / 5;
            grid-row: 1;
        }

        @media (max-width: ${MOBILE_WIDTH}px) {
            grid-column: 3 / 3;
            grid-row: 1 / span 3;
            display: flex;
            align-items: center;
            justify-self: end;
            align-self: center;
            padding-left: 4px;
        }
    }
`;

export const EpisodeButtons: React.FC<Props> = ({
    episodeUuid,
    podcastUuid,
    color,
    isPlaying,
    autoplay,
    actions,
    showSyncActions,
    upNextEpisodes,
    inHistory,
    isDeleted,
    playingStatus,
    title,
    duration,
    url,
    percentComplete,
    isPlayerEpisode,
    isStarred,
}) => {
    const isInUpNext = !!upNextEpisodes[episodeUuid];
    const isUploadedFileEpisode = isUploadedFile({ podcastUuid });

    return (
        <>
            <HoverActions isDeleted={showSyncActions && isDeleted}>
                <EpisodeActionButtons
                    episodeUuid={episodeUuid}
                    podcastUuid={podcastUuid}
                    title={title}
                    duration={duration}
                    url={url}
                    isDeleted={isDeleted}
                    playingStatus={playingStatus}
                    isInUpNext={isInUpNext}
                    inHistory={inHistory}
                    isUploadedFile={isUploadedFileEpisode}
                    actions={actions}
                    onRemoveFromHistory={actions.deleteHistory}
                    showSyncActions={showSyncActions}
                    isStarred={isStarred}
                />
            </HoverActions>
            <EpisodeActionsWrapper>
                <MobileEpisodeActions
                    episodeUuid={episodeUuid}
                    podcastUuid={podcastUuid}
                    title={title}
                    duration={duration}
                    url={url}
                    isDeleted={isDeleted}
                    playingStatus={playingStatus}
                    isInUpNext={isInUpNext}
                    inHistory={inHistory}
                    isUploadedFile={isUploadedFileEpisode}
                    actions={actions}
                    onRemoveFromHistory={actions.deleteHistory}
                    showSyncActions={showSyncActions}
                    episodePageOnlyActions={true}
                    isStarred={isStarred}
                />
            </EpisodeActionsWrapper>
            <EpisodePlayButton
                color={color}
                isPlayerEpisode={isPlayerEpisode}
                isPlaying={isPlaying}
                isPlayed={playingStatus === PlayingStatus.COMPLETED}
                percentComplete={percentComplete}
                onPlayClick={() => {
                    actions.handlePlayClick({
                        uuid: episodeUuid,
                        podcastUuid,
                        isDeleted,
                        autoplay,
                    });
                }}
                onPauseClick={actions.handlePauseClick}
            />
        </>
    );
};
