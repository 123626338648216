import { LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG } from 'model/local-storage';
import { AutoplayConfig } from 'model/types';
import { Action } from '../actions';

const restoredConfig: AutoplayConfig | null = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG) ?? 'null',
);

export type AutoplayState = {
    config: AutoplayConfig | null;
};

const INITIAL_STATE: AutoplayState = {
    config: restoredConfig,
};

export default (state: AutoplayState = INITIAL_STATE, action: Action): AutoplayState => {
    switch (action.type) {
        case 'AUTOPLAY_CLEAR':
            return { ...state, config: null };
        case 'AUTOPLAY_START':
            return {
                config: action.payload.config,
            };
        default:
            return state;
    }
};
