import { createSelector } from 'reselect';
import { RootState } from '..';

export const getUpNext = (state: RootState) => state.upNext || {};

export const getUpNextServerModified = (state: RootState) =>
    state.upNext && state.upNext.serverModified ? state.upNext.serverModified : 0;

/**
 * Returns a random number between 0 and "arrayLength - 1"
 */
export const getUpNextTopEpisode = (state: RootState) => {
    if (
        state.upNext &&
        state.upNext.order &&
        state.upNext.order.length > 0 &&
        state.upNext.episodes
    ) {
        const episodeUuid = state.upNext.order[0];
        const episode = (state.upNext.episodes || {})[episodeUuid];
        return episode ? Object.assign(episode, { uuid: episodeUuid }) : null;
    }
    return null;
};

export const getUpNextEpisodeWithUuid = (state: RootState, episodeUuid: string) =>
    state.upNext && state.upNext.episodes && state.upNext.episodes[episodeUuid];

export const getUpNextOrder = createSelector([getUpNext], upNext => upNext.order);

export const getQueuedEpisodeUuids = createSelector([getUpNextOrder], order =>
    order.slice(1, order.length),
);

export const isEpisodeInUpNext = (state: RootState, episodeUuid: string) =>
    !!getUpNextEpisodeWithUuid(state, episodeUuid);

export const getUpNextIsOpen = (state: RootState) => state.upNext.open;

export const getUpNextEpisodes = (state: RootState) => state.upNext.episodes;
