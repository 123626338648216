import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { ModalTypes } from '../../../../helper/UiHelper';
import { useDispatch } from '../../../../hooks/react-redux-typed';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import { MessageModal } from '../MessageModal';

function AlreadyHaveAccountModal() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();

    const onCloseClicked = () => {
        dispatch(
            fromSubscriptionActions.Actions.hideModalGeneric(
                ModalTypes.showAlreadyHaveAccountModal,
            ),
        );
    };

    return (
        <MessageModal
            title={formatMessage('modal-already-account-title')}
            message={formatMessage('modal-already-account-message')}
            onClose={onCloseClicked}
        ></MessageModal>
    );
}

export default AlreadyHaveAccountModal;
