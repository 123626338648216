import { UpNextIcon } from 'components/UpNextIcon';
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from '../../../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromUpNextActions from '../../../../redux/actions/up-next.actions';
import { getQueuedEpisodeUuids, getUpNextIsOpen } from '../../../../redux/reducers/selectors';

const Button = styled.button`
    background: none;
    border: none;
    padding: 10px 8px 6px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }
`;

export const FullPlayerUpNextButton = ({ ...props }) => {
    const dispatch = useDispatch();

    const queuedEpisodeUids = useSelector(getQueuedEpisodeUuids);
    const episodeCount = queuedEpisodeUids.length;
    const isOpen = useSelector(getUpNextIsOpen);

    const onOpen = () => {
        dispatch(fromPlayerActions.Actions.closeChapters());
        dispatch(fromUpNextActions.Actions.openUpNext());
    };

    const onClose = () => {
        dispatch(fromUpNextActions.Actions.closeUpNext());
    };

    return (
        <Button {...props} onClick={isOpen ? onClose : onOpen} aria-label="Show Up Next">
            <UpNextIcon count={episodeCount} />
        </Button>
    );
};
