import { connect } from 'react-redux';
import * as fromUserActions from '../../../../redux/actions/user.actions';
import { RootState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../store';
import ChangePasswordModal from './ChangePasswordModal';

const mapStateToProps = (state: RootState) => ({
    requestInProgress: state.user.passwordChangeResult.inProgress as boolean,
    requestSucceeded: state.user.passwordChangeResult.success as boolean,
    responseMessage: state.user.passwordChangeResult.message as string,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onClose: () => {
        dispatch(fromUserActions.Actions.dismissChangePasswordModal());
        dispatch(fromUserActions.Actions.cancelPasswordChange());
    },
    requestPasswordChange: (oldPassword: string, newPassword: string) =>
        dispatch(fromUserActions.Actions.requestPasswordChange(oldPassword, newPassword)),
});

const ChangePasswordModalComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChangePasswordModal);

export { ChangePasswordModalComponent as ChangePasswordModal };
