import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FolderImage } from 'components/FolderImage';
import { NavigationItems } from 'helper/NavigationHelper';
import { pauseKeyboardShortcuts, resumeKeyboardShortcuts } from 'helper/UiHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useMemo, useState } from 'react';
import { PodcastImage } from '../../../components';
import { PodcastGridLayouts, PodcastListItem } from '../model';
import { UnplayedBadge } from '../PodcastsPage.styled';
import { AssistiveText, Grid, TileLink, TileWrapper } from './GridOfPodcasts.styled';

type TileProps = {
    item: PodcastListItem;
    onItemClick: (item: PodcastListItem) => void;
    isOverlay?: boolean;
};

const SortableTile = ({ item, onItemClick, isOverlay = false }: TileProps) => {
    let title;
    let image;
    let href = '';

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: item.uuid,
    });

    if ('podcast' in item) {
        title = item.podcast.title;
        image = <PodcastImage uuid={item.podcast.uuid} title={title} />;
        href = `${NavigationItems.PODCASTS.path}/${item.podcast.uuid}`;
    } else if ('folder' in item) {
        title = item.folder.name;
        image = (
            <FolderImage
                color={item.folder.color}
                name={item.folder.name}
                podcastUuids={item.podcastUuids}
                sortType={item.folder.sortType}
            />
        );
        href = `${NavigationItems.PODCASTS.path}/folders/${item.folder.uuid}`;
    }

    const style = {
        opacity: isDragging ? 0 : 1,
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <TileWrapper {...attributes} {...listeners} style={style} ref={setNodeRef}>
            <TileLink
                to={href}
                onClick={() => onItemClick(item)}
                aria-label={title}
                aria-describedby="list-of-podcasts-dnd-instructions"
                $isDragging={isOverlay}
            >
                {image}
                {item.showUnplayedBadge && <UnplayedBadge />}
            </TileLink>
        </TileWrapper>
    );
};

type Props = {
    gridLayout: PodcastGridLayouts;
    items: PodcastListItem[];
    onItemClick: (item: PodcastListItem) => void;
    onSortEnd: (oldPosition: number, newPosition: number) => void;
};

const GridOfPodcasts = ({ gridLayout, items, onItemClick, onSortEnd }: Props) => {
    const formatMessage = useFormatMessage();
    const [isSorting, setIsSorting] = useState(false);
    const [liveText, setLiveText] = useState('');
    const [activeItem, setActiveItem] = useState<PodcastListItem | null>(null);

    // Make sure on unmount, we always resume keyboard shortcuts
    useEffect(() => resumeKeyboardShortcuts, []);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 200,
                tolerance: 1000,
            },
        }),
    );

    const onDragStart = (event: DragStartEvent) => {
        const { active } = event;

        const index = items.findIndex(item => item.uuid === active.id);

        setActiveItem(items[index]);

        pauseKeyboardShortcuts();
        setIsSorting(true);
        setLiveText(
            `${items[index].title}, ${formatMessage('grabbed')}. ${formatMessage(
                'current-position',

                { position: index + 1, count: items.length },
            )}. ${formatMessage('reorder-move-instructions')}`,
        );
    };

    const onDragOver = (event: DragOverEvent) => {
        const { over } = event;
        const newIndex = items.findIndex(item => item.uuid === over?.id);

        setLiveText(
            `${formatMessage('moved')}. ${formatMessage('current-position', {
                position: newIndex + 1,
                count: items.length,
            })}.`,
        );
    };

    const onDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        // This handles a corner case where the user intends to click on a tile, but the click is
        // interpreted as a quick drag & drop in the same place.
        // See https://github.com/Automattic/pocket-casts-webplayer/issues/3049
        const isQuickDrop = over === null && event.delta.x === 0 && event.delta.y === 0;

        const oldIndex = items.findIndex(item => item.uuid === active.id);
        const newIndex = isQuickDrop ? oldIndex : items.findIndex(item => item.uuid === over?.id);

        // Ensure that when items are dropped far behind the last item, they are placed at the end
        const boundedNewIndex = newIndex < 0 ? items.length - 1 : newIndex;

        setActiveItem(null);

        setIsSorting(false);
        onSortEnd(oldIndex, boundedNewIndex);
        resumeKeyboardShortcuts();
        if (oldIndex === boundedNewIndex) {
            setLiveText(
                `${items[oldIndex].title}, ${formatMessage('dropped-in-original-position')}.`,
            );
        } else {
            setLiveText(
                `${items[oldIndex].title}, ${formatMessage('dropped')}. ${formatMessage(
                    'final-position',
                    {
                        position: boundedNewIndex + 1,
                        count: items.length,
                    },
                )}.`,
            );
        }
    };

    const itemsWithIds = useMemo(() => items.map(item => ({ ...item, id: item.uuid })), [items]);

    return (
        <>
            <AssistiveText>
                <p id="list-of-podcasts-dnd-instructions">
                    {formatMessage('reorder-start-instructions')}
                </p>
                <p aria-live="assertive">
                    <span key={liveText}>{liveText}</span>
                </p>
            </AssistiveText>
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                modifiers={[restrictToParentElement]}
            >
                <SortableContext items={itemsWithIds} strategy={rectSortingStrategy}>
                    <Grid
                        $isLarge={gridLayout === PodcastGridLayouts.GRID_LARGE}
                        $isSorting={isSorting}
                    >
                        {itemsWithIds.map(item => (
                            <SortableTile
                                key={'podcast' in item ? item.podcast.uuid : item.folder.uuid}
                                item={item}
                                onItemClick={onItemClick}
                            />
                        ))}
                    </Grid>
                </SortableContext>
                <DragOverlay>
                    {activeItem ? (
                        <SortableTile
                            isOverlay
                            key={
                                'podcast' in activeItem
                                    ? activeItem.podcast.uuid
                                    : activeItem.folder.uuid
                            }
                            item={activeItem}
                            onItemClick={onItemClick}
                        />
                    ) : null}
                </DragOverlay>
            </DndContext>
        </>
    );
};

export default GridOfPodcasts;
