import styled from 'styled-components';
import { Typography } from '../../styles';

export const RoundedButton = styled.button`
    background: ${props => props.theme.legacyScss['theme-text-link']};
    color: ${props => props.theme.tokens['primary-ui-01']};
    ${Typography.Desktop['H70-B']};
    height: 48px;
    padding: 15px 32px;
    border-radius: 58px;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        opacity: 0.8;
    }
`;

export default RoundedButton;
