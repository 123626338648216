import styled from 'styled-components';
import {
    BC80,
    blue,
    coolGrey40,
    coolGrey50,
    coolGrey60,
    coolGrey70,
    fontFamilyRetina,
    gridSize,
    H60,
    H70,
    red,
    userSelectNoneMixin,
} from '../../../styles';
import { orange } from '../../../styles/colors';

export const FilesListWrapper = styled.div``;

export const StyledFilesTable = styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse; // Required for styling tr with borders

    margin-bottom: ${4 * gridSize}px;
`;

export const TrHeader = styled.tr``;

export const Th = styled.th`
    border-top: ${coolGrey40};
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    padding: 12px ${gridSize}px;
`;

export const ActionsTh = styled(Th)`
    min-width: 160px;
    width: 160px;
`;

export const Heading = styled(BC80)`
    ${props => props.$leftAlign && 'text-align: left;'}
    color: ${props => (props.$isSortColumn ? coolGrey70 : coolGrey50)};

    cursor: pointer;
    ${userSelectNoneMixin};
`;

export const ActionsHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

// B80
export const Percentage = styled.div`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    padding: 0;
    margin: 0;

    color: ${props =>
        props.$percentage < 90 ? coolGrey60 : props.$percentage < 99 ? orange : red};
    padding-right: ${gridSize}px;
    cursor: pointer;

    ${userSelectNoneMixin};
    position: relative;
`;

export const Dot = styled(BC80)`
    color: ${coolGrey50};
    padding-right: ${gridSize}px;
`;

export const Tr = styled.tr`
    height: ${9 * gridSize}px;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};

    color: ${props =>
        props.$played
            ? props.theme.legacyScss['theme-text-disabled']
            : props.theme.legacyScss['theme-text-primary']};

    &:hover {
        color: ${blue};
    }
    &:hover {
        .hide-on-hover {
            visibility: visible; //TODO: hidden
        }
        .show-on-hover {
            visibility: visible;
        }
    }
    &:focus-within {
        .show-on-hover {
            visibility: visible;
        }
        .hide-on-hover {
            visibility: hidden;
        }
    }
`;

export const NameWithIcon = styled.div`
    display: flex;
`;

export const FirstTd = styled.td`
    min-width: ${50 * gridSize}px;
    max-width: ${70 * gridSize}px;
`;

export const NameCell = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const ImageWrapper = styled.div`
    width: ${7 * gridSize}px;
    height: ${7 * gridSize}px;

    border-radius: 4px;
    margin-right: ${2 * gridSize}px;
    min-width: ${7 * gridSize}px;
    overflow: hidden;
`;

export const UpNextIndicationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${3 * gridSize}px;
    height: ${3 * gridSize}px;

    min-width: ${3 * gridSize}px;
    min-height: ${3 * gridSize}px;

    margin-right: ${gridSize}px;

    color: ${props => props.theme.tokens['primary-icon-01']};
`;

export const NameWrapper = styled(H60)`
    display: block;
    text-align: left;
    word-break: break-word;
`;

export const Td = styled.td`
    text-align: center;
    padding: ${2 * gridSize}px ${gridSize}px;

    ${props => (props.centered ? 'text-align: center' : '')};
`;

export const LastTd = styled.td`
    padding: ${2 * gridSize}px 0;
`;

export const FinalColumnWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

export const PlayCircleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: ${2 * gridSize}px;
`;

export const LightText = styled(H70)`
    color: ${props => (props.$played ? props.theme.legacyScss['theme-text-disabled'] : coolGrey60)};
    text-align: left;
`;
