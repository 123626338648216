import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { TracksEventSource } from 'model/types';
import React, { MouseEventHandler } from 'react';
import { useDispatch, useSelector } from '../../../hooks/react-redux-typed';
import useFormatMessage from '../../../hooks/useFormatMessage';
import * as fromPodcastsActions from '../../../redux/actions/podcasts.actions';
import { getSubscribedPodcastUuids } from '../../../redux/reducers/selectors';
import FeatureLock from '../../FeatureLock';
import { SubscribeButtonIconWrapper, SubscribeButtonWrapper } from './SubscribeButton.styled';

type Props = {
    podcastUuid: string;
    circled?: boolean;
    className?: string;
    eventSource: TracksEventSource;
    onSubscribe?: () => void;
};

const SubscribeButton = ({
    podcastUuid,
    onSubscribe,
    circled = false,
    eventSource,
    className,
}: Props) => {
    const dispatch = useDispatch();
    const subscribedUuids = useSelector(getSubscribedPodcastUuids);
    const isSubscribed = subscribedUuids.includes(podcastUuid);
    const formatMessage = useFormatMessage();

    const styleClasses = classNames('sub5cribe-button', className, {
        sub5cribed: isSubscribed,
    });

    const handleClick: MouseEventHandler = event => {
        event.stopPropagation();
        event.preventDefault();
        if (isSubscribed) {
            dispatch(
                fromPodcastsActions.Actions.unsubscribeFromPodcast(podcastUuid, { eventSource }),
            );
        } else {
            dispatch(
                fromPodcastsActions.Actions.subscribeToPodcast(
                    { uuid: podcastUuid },
                    { eventSource },
                ),
            );
            onSubscribe?.();
        }
    };

    return (
        <SubscribeButtonWrapper
            data-testid={`subscribe-button-${podcastUuid}`}
            className={styleClasses}
            onClick={handleClick}
            $circled={circled}
        >
            <FeatureLock
                title={formatMessage('feature-subscribe-unavailable-title')}
                description={formatMessage('feature-subscribe-unavailable-description')}
                source="podcast_subscribe_button"
            >
                <SubscribeButtonIconWrapper $circled={circled}>
                    <Icon id={isSubscribed ? 'tick' : 'plus'} size={20} />
                </SubscribeButtonIconWrapper>
            </FeatureLock>
        </SubscribeButtonWrapper>
    );
};
export default SubscribeButton;
