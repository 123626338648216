import { connect } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { getEpisodeImageUrl } from '../../../../redux/reducers/selectors';
import MiniPlayer from './MiniPlayer';

const mapStateToProps = (state: RootState) => {
    const { episode } = state.player;

    if (!episode) {
        return { episode: null, isPlaying: false };
    }

    // Makes sure we don't flash the podcast image when the episode image is loading
    const showNotesImage = getEpisodeImageUrl(state, episode.uuid);
    const imageUrl = episode.imageUrl ?? showNotesImage;

    return {
        episode: {
            ...episode,
            imageUrl,
        },
        isPlaying: state.player.isPlaying,
    };
};

const MiniPlayerConnected = connect(mapStateToProps)(MiniPlayer);

export { MiniPlayerConnected as MiniPlayer };
