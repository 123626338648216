import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useRef, useState } from 'react';
import { VirtualList } from './VirtualTable.styled';

interface VirtualTableProps<T> {
    items: T[];
    renderItem: (item: T, virtualRow: any) => React.ReactNode;
}

const VirtualTable = <T,>({ items, renderItem }: VirtualTableProps<T>) => {
    const listRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        parentRef.current = (window as any).contentElement as HTMLDivElement;
    }, []);

    useEffect(() => {
        if (listRef.current && parentRef.current) {
            const listRect = listRef.current.getBoundingClientRect();
            const parentRect = parentRef.current.getBoundingClientRect();
            const calculatedOffset = listRect.top - parentRect.top;
            setOffset(Math.ceil(Math.max(calculatedOffset, 0)));
        }
    }, []);

    const virtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 72,
        overscan: 5,
        scrollMargin: offset,
    });

    return (
        <VirtualList ref={listRef} $height={virtualizer.getTotalSize()}>
            {virtualizer.getVirtualItems().map(virtualRow => (
                <div
                    key={virtualRow.key}
                    ref={virtualizer.measureElement}
                    data-index={virtualRow.index}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: `translateY(${virtualRow.start - offset}px)`,
                        width: '100%',
                    }}
                >
                    {renderItem(items[virtualRow.index], virtualRow)}
                </div>
            ))}
        </VirtualList>
    );
};

export default VirtualTable;
