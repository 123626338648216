import { connect } from 'react-redux';
import { MAX_DAYS_FOR_EXPIRY_COUNTDOWN } from '../../model/subscription';
import { RootState } from '../../redux/reducers';
import { getIsSubscriptionLoading, getPlusSubscription } from '../../redux/reducers/selectors';
import ProfilePage from './ProfilePage';

const mapStateToProps = (state: RootState) => {
    const currentDate = new Date();
    const subscriptionData = getPlusSubscription(state);
    const expiryDateString = subscriptionData && subscriptionData.expiryDate;
    const subscriptionErrorOccurred = state.payments.subscriptionPurchaseFailed;

    let withinExpiryCountdown = false;
    if (expiryDateString) {
        const expiryDate = new Date(expiryDateString);
        const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        withinExpiryCountdown = diffDays <= MAX_DAYS_FOR_EXPIRY_COUNTDOWN;
    }

    return {
        isLoading: getIsSubscriptionLoading(state),
        serverError: state.subscription.serverError,
        subscriptionData,
        withinExpiryCountdown,
        subscriptionErrorOccurred,
        plusSubscription: subscriptionData,
    };
};

export default connect(mapStateToProps)(ProfilePage);
