import { LOCAL_STORAGE_KEY_SHUFFLED } from 'model/local-storage';
import { UpNextState } from 'model/types';
import { ActionTypes, Actions } from '../actions/up-next.actions';
import * as fromUploadedFilesActions from '../actions/uploaded-files.actions';
import * as fromUserActions from '../actions/user.actions';

export const INITIAL_STATE: UpNextState = {
    order: [],
    episodes: {},
    serverModified: 0,
    loadFailed: false,
    isLoading: false,
    isLoaded: false,
    open: false,
    isShuffleEnabled: localStorage.getItem(LOCAL_STORAGE_KEY_SHUFFLED) === 'true',
};

export default (
    state = INITIAL_STATE,
    action: Actions | fromUploadedFilesActions.Actions | fromUserActions.Actions,
): UpNextState => {
    switch (action.type) {
        case fromUserActions.ActionTypes.SIGN_UP:
            return INITIAL_STATE;
        case ActionTypes.OPEN_UP_NEXT:
            return { ...state, open: true };
        case ActionTypes.CLOSE_UP_NEXT:
            return { ...state, open: false };
        case ActionTypes.UP_NEXT_CHANGED: {
            if (action.payload.data.serverModified && !('order' in action.payload.data)) {
                return {
                    ...state,
                    serverModified: action.payload.data.serverModified,
                    order: [],
                    episodes: {},
                    isLoaded: true,
                    isLoading: false,
                };
            }

            const episodes = action.payload.data.episodes || {};
            // make sure we have data for each of the Up Next episodes otherwise remove it
            const order = action.payload.data.order
                ? action.payload.data.order.filter((uuid: string) => !!episodes[uuid])
                : [];
            const {
                data: { serverModified },
            } = action.payload;

            return { ...state, episodes, order, serverModified, isLoaded: true, isLoading: false };
        }
        case ActionTypes.UP_NEXT_DOWNLOAD_FAILED:
            return { ...state, loadFailed: true, isLoading: false };
        case ActionTypes.REMOVE_EPISODE_FROM_UP_NEXT:
            return {
                ...state,
                order: state.order.filter(uuid => uuid !== action.payload.episodeUuid),
                episodes: Object.keys(state.episodes)
                    .filter(uuid => uuid !== action.payload.episodeUuid)
                    .reduce(
                        (result, current) => ({
                            ...result,
                            [current]: state.episodes[current],
                        }),
                        {},
                    ),
            };
        case ActionTypes.CLEAR_UP_NEXT: {
            const order = state.order.slice(0, 1);
            const topUuid = order[0];
            return {
                ...state,
                order,
                episodes: Object.keys(state.episodes)
                    .filter(uuid => uuid === topUuid)
                    .reduce(
                        (result, current) => ({
                            ...result,
                            [current]: state.episodes[current],
                        }),
                        {},
                    ),
            };
        }
        case ActionTypes.MOVE_UP_NEXT_EPISODE: {
            const clone = state.order.slice();
            const removedUuid = clone.splice(action.payload.oldIndex + 1, 1)[0];
            clone.splice(action.payload.newIndex + 1, 0, removedUuid);
            return { ...state, order: clone };
        }
        case ActionTypes.UP_NEXT_SET_FILE_DETAILS: {
            return {
                ...state,
                episodes: {
                    ...state.episodes,
                    [action.payload.fileUuid]: {
                        ...state.episodes[action.payload.fileUuid],
                        title: action.payload.title,
                        imageUrl: action.payload.imageUrl,
                        url: state.episodes[action.payload.fileUuid].url || '',

                        // Some files in Up Next may be on-device only. `exists` indicates that the
                        // file is actually in our cloud and we can access it.
                        exists: action.payload.exists,
                    },
                },
            };
        }
        case fromUploadedFilesActions.ActionTypes.FILE_FETCH_SUCCESS: {
            const { uuid } = action.payload;

            if (!state.order.includes(uuid)) {
                return state;
            }

            const { title, imageUrl } = action.payload;

            return {
                ...state,
                episodes: {
                    ...state.episodes,
                    [uuid]: {
                        ...state.episodes[uuid],
                        title,
                        imageUrl,
                    },
                },
            };
        }
        case ActionTypes.TOGGLE_SHUFFLE:
            return { ...state, isShuffleEnabled: action.payload.isShuffleEnabled };
        default:
            return state;
    }
};
