import { useCallback } from 'react';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { useDispatch } from '../../hooks/react-redux-typed';
import { AutoplayConfig, Episode, EpisodeSyncInfo, TracksEventSource } from '../../model/types';
import {
    useEpisodeHistoryAction,
    useEpisodePlayerAction,
    useEpisodePodcastAction,
    useEpisodeShareAction,
    useEpisodeUpNextAction,
} from '../../redux/action-hooks';
import * as fromPodcastActions from '../../redux/actions/podcast.actions';
import { EpisodeEventSource } from '../../redux/actions/podcast.actions';
import * as fromTracksActions from '../../redux/actions/tracks.actions';
import * as fromUploadedFilesActions from '../../redux/actions/uploaded-files.actions';

interface ArchiveParams {
    uuid: string;
    podcastUuid: string;
    isDeleted?: boolean;
}

interface PlayStatusParams {
    uuid: string;
    podcastUuid: string;
    playingStatus?: number;
    isUploadedFile?: boolean;
}

interface PlayParams {
    uuid: string;
    podcastUuid: string;
    isDeleted?: boolean;
    autoplay?: AutoplayConfig;
}

interface ShareParams {
    uuid: string;
    podcastUuid: string;
    title: string;
    duration: number;
    url: string;
}

export const useEpisodeActions = (eventSource: NonNullable<EpisodeEventSource>) => {
    const dispatch = useDispatch();
    // Action hooks
    const markAsPlayed = useEpisodePodcastAction('markAsPlayed');
    const markAsUnplayed = useEpisodePodcastAction('markAsUnplayed');
    const archive = useEpisodePodcastAction('archive');
    const unarchive = useEpisodePodcastAction('unarchive');
    const starEpisode = useEpisodePodcastAction('starEpisode');
    const pausePlayer = useEpisodePlayerAction('pause');
    const upNextPlayNext = useEpisodeUpNextAction('upNextPlayNext');
    const upNextPlayLast = useEpisodeUpNextAction('upNextPlayLast');
    const removeFromUpNext = useEpisodeUpNextAction('removeFromUpNext');
    const deleteHistory = useEpisodeHistoryAction('deleteHistory');
    const onShare = useEpisodeShareAction('openEpisodeShare');
    const playEpisode = useEpisodePlayerAction('playEpisode');

    const { discoverEpisodePlayEvent } = useAnalyticsContext();

    const handleShare = useCallback(
        (params: ShareParams) => {
            const { uuid, podcastUuid, title, duration, url } = params;
            onShare(uuid, podcastUuid, title, duration, url, { eventSource });
        },
        [onShare, eventSource],
    );

    const handleArchiveClick = useCallback(
        (params: ArchiveParams) => {
            const { uuid, podcastUuid, isDeleted } = params;
            isDeleted
                ? unarchive(uuid, podcastUuid, { eventSource })
                : archive(uuid, podcastUuid, { eventSource });
        },
        [archive, unarchive, eventSource],
    );

    const handlePlayStatusClick = useCallback(
        (params: PlayStatusParams) => {
            const { uuid, podcastUuid, playingStatus, isUploadedFile } = params;
            playingStatus === PlayingStatus.COMPLETED
                ? markAsUnplayed(uuid, podcastUuid, { eventSource })
                : markAsPlayed(uuid, podcastUuid, { eventSource });

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                        option:
                            playingStatus === PlayingStatus.COMPLETED
                                ? 'mark_unplayed'
                                : 'mark_played',
                    }),
                );
            }
        },
        [markAsPlayed, markAsUnplayed, eventSource, dispatch],
    );

    const handlePlayClick = useCallback(
        (params: PlayParams) => {
            const { uuid, podcastUuid, isDeleted, autoplay } = params;
            discoverEpisodePlayEvent(podcastUuid);

            if (isDeleted) {
                unarchive(uuid, podcastUuid, { eventSource });
            }

            playEpisode(
                uuid,
                podcastUuid,
                { eventSource: eventSource as TracksEventSource },
                { autoplay },
            );
        },
        [playEpisode, unarchive, eventSource, discoverEpisodePlayEvent],
    );

    const handlePauseClick = useCallback(() => {
        pausePlayer({ eventSource: eventSource as TracksEventSource });
    }, [pausePlayer, eventSource]);

    const handlePlayNextClick = useCallback(
        (
            uuid: string,
            podcastUuid: string,
            title: string,
            url: string,
            isUploadedFile?: boolean,
        ) => {
            upNextPlayNext(podcastUuid, { uuid, podcastUuid, title, url }, { eventSource });

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                        option: 'up_next_add_top',
                    }),
                );
            }
        },
        [upNextPlayNext, eventSource, dispatch],
    );

    const handlePlayLastClick = useCallback(
        (
            uuid: string,
            podcastUuid: string,
            title: string,
            url: string,
            isUploadedFile?: boolean,
        ) => {
            upNextPlayLast(podcastUuid, { uuid, podcastUuid, title, url }, { eventSource });

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                        option: 'up_next_add_bottom',
                    }),
                );
            }
        },
        [upNextPlayLast, eventSource, dispatch],
    );

    const handleRemoveFromUpNext = useCallback(
        (uuid: string, isUploadedFile?: boolean) => {
            removeFromUpNext(uuid, { eventSource });

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                        option: 'up_next_remove',
                    }),
                );
            }
        },
        [removeFromUpNext, eventSource, dispatch],
    );

    const handleStarEpisode = useCallback(
        (episode: Episode, starred: boolean) => {
            if ('podcastUuid' in episode) {
                starEpisode(episode.uuid, episode.podcastUuid, starred, { eventSource });
            }
        },
        [starEpisode, eventSource],
    );

    const handleEpisodeClick = useCallback(
        (
            episode: Episode,
            uuidToEpisodeSync: Record<string, EpisodeSyncInfo> | undefined,
            onEpisodeClick: (episode: Episode) => void,
        ) => {
            if (uuidToEpisodeSync) {
                const episodeSync = uuidToEpisodeSync[episode.uuid];
                const enrichedEpisode = { ...episode, ...episodeSync };
                onEpisodeClick(enrichedEpisode);
            } else {
                onEpisodeClick(episode);
            }
        },
        [],
    );

    const handleStarClick = useCallback(
        ({
            uuid,
            podcastUuid,
            isStarred,
        }: {
            uuid: string;
            podcastUuid: string;
            isStarred: boolean;
        }) => {
            dispatch(
                fromPodcastActions.Actions.starEpisode(uuid, podcastUuid, !isStarred, {
                    eventSource,
                }),
            );
        },
        [dispatch, eventSource],
    );

    const handleDownloadClick = useCallback(
        ({
            uuid,
            title,
            isUploadedFile,
        }: {
            uuid: string;
            title: string;
            isUploadedFile: boolean;
        }) => {
            if (isUploadedFile) {
                dispatch(fromUploadedFilesActions.Actions.downloadFile(uuid, title));
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                        option: 'download',
                    }),
                );
            }
        },
        [dispatch],
    );

    const handleEditClick = useCallback(
        (uuid: string) => {
            dispatch(fromUploadedFilesActions.Actions.editFile(uuid));
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'edit',
                }),
            );
        },
        [dispatch],
    );

    const handleDeleteClick = useCallback(
        ({ uuid, title }: { uuid: string; title: string }) => {
            dispatch(fromUploadedFilesActions.Actions.showDeleteFileConfirmation(uuid, title));
            dispatch(
                fromTracksActions.Actions.recordEvent('user_file_option_tapped', {
                    option: 'delete',
                }),
            );
        },
        [dispatch],
    );

    return {
        handleShare,
        handleArchiveClick,
        handlePlayStatusClick,
        handlePlayClick,
        handlePauseClick,
        handlePlayNextClick,
        handlePlayLastClick,
        handleRemoveFromUpNext,
        deleteHistory,
        handleStarEpisode,
        handleEpisodeClick,
        handleStarClick,
        handleDownloadClick,
        handleEditClick,
        handleDeleteClick,
    };
};

export type EpisodeActions = ReturnType<typeof useEpisodeActions>;
