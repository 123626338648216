export const stopPropagation = (event: Event | React.SyntheticEvent) => {
    if (event.stopPropagation) {
        event.stopPropagation();
    }
    if (event.preventDefault) {
        event.preventDefault();
    }
};

export const warnUserBeforeLeavingPage = (event: Event) => {
    event.preventDefault();

    event.returnValue = false;
};

export const onKeyDownEnter = (
    event: React.KeyboardEvent,
    callback: (e: React.KeyboardEvent) => void,
) => {
    if (event.key === 'Enter' || event.key === 'Space') {
        callback(event);
    }
};

export const shouldCancelDragEvent = (event: Event | React.SyntheticEvent) => {
    const { type } = event;
    const { tagName } = event.target as HTMLElement;
    if (type === 'touchstart' && !(tagName === 'svg' || tagName === 'use')) {
        return true;
    }
    return false;
};

const PAUSE_KEYBOARD_SHORTCUTS_KEY = 'pauseKeyboardShortcuts';
export const pauseKeyboardShortcuts = () => {
    document.body.dataset[PAUSE_KEYBOARD_SHORTCUTS_KEY] = 'true';
};
export const resumeKeyboardShortcuts = () => {
    delete document.body.dataset[PAUSE_KEYBOARD_SHORTCUTS_KEY];
};
export const canUseKeyboardShortcuts = () =>
    document.body.dataset[PAUSE_KEYBOARD_SHORTCUTS_KEY] !== 'true';

export enum ModalTypes {
    contentRegionSelection = 'contentRegionSelection',
    createFolder = 'createFolder',
    editFolderDetails = 'editFolderDetails',
    editFolderPodcasts = 'editFolderPodcasts',
    deleteFolder = 'deleteFolder',
    changePodcastFolder = 'changePodcastFolder',
    listBookmarks = 'listBookmarks',
    ratePodcast = 'ratePodcast',

    // TODO: The modals below haven't been ported over yet, but should be
    showFarewellModal = 'showFarewellModal',
    showCheckoutModal = 'showCheckoutModal',
    showThankYouModalWelcomeBack = 'showThankYouModalWelcomeBack',
    showAlreadyPlusSubscriberModal = 'showAlreadyPlusSubscriberModal',
    showAlreadyHaveAccountModal = 'showAlreadyHaveAccountModal',
    showCancelModal = 'showCancelModal',
    showChangeBillingDetailsModal = 'showChangeBillingDetailsModal',

    shouldShowEmailChangeModal = 'shouldShowEmailChangeModal',
    shouldShowPasswordChangeModal = 'shouldShowPasswordChangeModal',
    shouldShowAccountDeleteModal = 'shouldShowAccountDeleteModal',

    confirmClearUpNext = 'confirmClearUpNext',
}
