import styled from 'styled-components';
import { blueTextLinkMixin, gridSize, H40, H50, H70 } from '../../../../styles/index';

export const ImageWrapper = styled.div`
    display: flex;
    width: ${14 * gridSize}px;
    height: ${14 * gridSize}px;

    align-items: center;
`;

export const HeaderWrapper = styled(H40)`
    text-align: center;
    width: 460px;
`;

export const SubtitleWrapper = styled(H70)`
    text-align: center;
    width: 460px;
`;

export const Spaces = styled.div<{ $n: number }>`
    height: ${props => gridSize * (props.$n || 1)}px;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;

export const AllHopeWrapper = styled(H50)`
    text-align: center;
`;

export const HelpLink = styled.a`
    ${blueTextLinkMixin};
`;

export const HelpWrapper = styled(H70)`
    text-align: center;
`;
