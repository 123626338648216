import { getPreferredSystemTheme, THEME } from 'model/theme';
import { RootState } from '../../reducers';

export const getSettings = (state: RootState) => state.settings || {};

export const getSettingsRegion = (state: RootState) => {
    const region = getSettings(state).region;
    return region ?? 'global';
};

export const getGridLayout = (state: RootState) => getSettings(state).gridLayout;

export const getGridOrder = (state: RootState) => getSettings(state).gridOrder;

export const getBookmarksSortOrder = (state: RootState) => getSettings(state).bookmarksSortOrder;

export const getLanguage = (state: RootState) => getSettings(state).language;

export const isAnalyticsOptOutEnabled = (state: RootState) => !getSettings(state).privacyAnalytics;

export const isAutoplayEnabled = (state: RootState) => getSettings(state).autoPlayEnabled;

/* Gets the actual theme set in the server (light (0), dark (1) or system (100)) */
export const getTheme = (state: RootState) => state.settings.theme;

export const getPreferredColorScheme = (state: RootState) =>
    getSettings(state).preferredColorScheme;

/* If the theme is system - return which color scheme the user prefers */
export const getDisplayedTheme = (theme: THEME) => {
    if (theme === THEME.system) {
        return getPreferredSystemTheme(theme);
    }
    return theme;
};

export const isShowingLightTheme = (state: RootState) =>
    getDisplayedTheme(getTheme(state)) === THEME.light;
