import { AutoplayConfig } from 'model/types';
import { createAction } from './action-creators';

export const setAutoplayEnabled = (isEnabled: boolean) =>
    createAction('AUTOPLAY_SET_ENABLED', { isEnabled });

export const clearAutoplay = () => createAction('AUTOPLAY_CLEAR');
export const startAutoplay = (config: AutoplayConfig) => createAction('AUTOPLAY_START', { config });

export const refreshAutoplayEpisodes = () => createAction('AUTOPLAY_REFRESH_EPISODES');
