import styled from 'styled-components';

const FinePrintText = styled.p`
    font-size: 16px;
    margin: 0;
    a,
    button {
        text-decoration: underline;
        color: ${props => (props.theme.name === 'dark' ? '#fff' : '#000')};
        font-weight: 700;
    }
`;

export default FinePrintText;
