import useFormatMessage from 'hooks/useFormatMessage';
import { Subscription } from 'model/types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Header, LoaderSquare } from '../../components';
import { getEmail } from '../../redux/reducers/selectors';

import { ActionsColumn } from './ActionsColumn';
import { ProfileInformation } from './ProfileInformation';
import {
    ErrorWrapper,
    ProfileInformationColumnWrapper,
    ProfilePageWrapper,
    Space,
    ThreeColumns,
} from './ProfilePage.styled';
import { SmallLinks } from './SmallLinks';
import { SubscriptionErrorBubble } from './SubscriptionErrorBubble';

type Props = {
    serverError: string;
    subscriptionData: Subscription;
    withinExpiryCountdown: boolean;
    subscriptionErrorOccurred: boolean;
    isLoading: boolean;
    plusSubscription: Subscription;
};

export function ProfilePage({
    serverError,
    subscriptionData,
    withinExpiryCountdown,
    subscriptionErrorOccurred,
    isLoading,
    plusSubscription,
}: Props) {
    const formatMessage = useFormatMessage();
    const email = useSelector(getEmail);

    if (serverError) {
        return (
            <ProfilePageWrapper>
                <Helmet>
                    <title>{formatMessage('profile')}</title>
                </Helmet>
                <Header title={formatMessage('profile')} />
                <ErrorWrapper>{serverError}</ErrorWrapper>
            </ProfilePageWrapper>
        );
    }

    if (isLoading) {
        return (
            <ProfilePageWrapper>
                <Helmet>
                    <title>{formatMessage('profile')}</title>
                </Helmet>
                <Header title={formatMessage('profile')} />
                <LoaderSquare />
            </ProfilePageWrapper>
        );
    }

    return (
        <ProfilePageWrapper>
            <Helmet>
                <title>{formatMessage('profile')}</title>
            </Helmet>
            <Header title={formatMessage('profile')} />
            {subscriptionErrorOccurred && <SubscriptionErrorBubble />}
            <ThreeColumns>
                <ProfileInformationColumnWrapper>
                    <ProfileInformation
                        email={email}
                        subscription={subscriptionData}
                        withinExpiryCountdown={withinExpiryCountdown}
                    />
                    <Space />
                    <SmallLinks
                        autoRenewing={subscriptionData?.autoRenewing}
                        platform={subscriptionData?.platform}
                    />
                </ProfileInformationColumnWrapper>
                <ActionsColumn
                    withinExpiryCountdown={withinExpiryCountdown}
                    subscriptionErrorOccurred={subscriptionErrorOccurred}
                    plusSubscription={plusSubscription}
                />
            </ThreeColumns>
        </ProfilePageWrapper>
    );
}

export default ProfilePage;
