import { Icon } from 'components/Icon';
import { ModalMonitor } from 'components/ModalMonitor';
import React, { TransitionEvent, useRef } from 'react';
import { FocusOn } from 'react-focus-on';
import {
    SlidingModalBody,
    SlidingModalContent,
    SlidingModalHeader,
    SlidingModalHeaderWrapper,
    SlidingModalPlacement,
    SlidingModalTableActions,
    SlidingModalWrapper,
} from './SlidingModal.styled';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    title: string;
    toolbar?: React.ReactNode;
    className?: string;
}

export default function SlidingModal({
    isOpen,
    onClose,
    children,
    title,
    toolbar,
    className,
}: Props) {
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const handleTransitionEnd = (evt: TransitionEvent) => {
        // Focus the "close" button when Up Next is opened.
        // Even though we're using <FocusOn>, we can't use its auto-focusing features because
        // the focus lock is enabled before Up Next scrolls onto screen. If we focus an element
        // while it's still off-screen, the browser attempts to bring the focused element into
        // view and the entire layout breaks in a way that can't be fixed without a refresh.
        // So we'll wait for the transition to finish, and _then_ focus on the button.
        if (!(evt.target as HTMLElement).classList.contains('up-next')) {
            return;
        }
        if (evt.propertyName !== 'transform') {
            return;
        }
        if (!isOpen) {
            return;
        }
        closeButtonRef.current?.focus();
    };

    return (
        <FocusOn onEscapeKey={onClose} enabled={isOpen} scrollLock={false} noIsolation>
            {isOpen && <ModalMonitor onCloseModals={onClose} />}
            <SlidingModalPlacement
                $isOpen={isOpen}
                className={className}
                role="dialog"
                aria-modal={true}
                aria-labelledby="slide-up-modal-title"
                onTransitionEnd={handleTransitionEnd}
            >
                <SlidingModalWrapper>
                    <SlidingModalContent>
                        <SlidingModalHeaderWrapper>
                            <SlidingModalHeader>
                                <h1 id="slide-up-modal-title">{title}</h1>
                                <button onClick={onClose} ref={closeButtonRef} aria-label="Close">
                                    <Icon id="cancel" />
                                </button>
                                {toolbar && (
                                    <SlidingModalTableActions>{toolbar}</SlidingModalTableActions>
                                )}
                            </SlidingModalHeader>
                        </SlidingModalHeaderWrapper>
                        <SlidingModalBody>{children}</SlidingModalBody>
                    </SlidingModalContent>
                </SlidingModalWrapper>
            </SlidingModalPlacement>
        </FocusOn>
    );
}
