import { Bookmark, EpisodeSyncInfo } from 'model/types';
import { createSelector } from 'reselect';
import { RootState } from '..';
import { getSortedEpisodesForPodcast } from './podcasts.selectors';
import { getUpNextOrder } from './up-next.selectors';
import { getUploadedFile } from './uploaded-files.selectors';

export const getAllEpisodesSync = (state: RootState) => state.episodeSync;

export const getEpisodeSyncByUuid = createSelector(
    [getAllEpisodesSync, (state: RootState, uuid: string) => uuid],
    (episodeSync, uuid) => episodeSync[uuid] || {},
);

export const getEpisodeSyncForUpNext = createSelector(
    [getUpNextOrder, getAllEpisodesSync],
    (upNextOrder, episodesSyncByUuid) => {
        const episodeSync = {} as Record<string, EpisodeSyncInfo>;
        upNextOrder.forEach(uuid => {
            episodeSync[uuid] = episodesSyncByUuid[uuid] || {};
        });
        return episodeSync;
    },
);

export const getEpisodeSyncForPodcast = (state: RootState, podcastUuid: string) => {
    const episodes = getSortedEpisodesForPodcast(state, podcastUuid) ?? [];
    const episodeSync: Record<string, EpisodeSyncInfo> = {};
    episodes.forEach(episode => {
        episodeSync[episode.uuid] = getEpisodeSyncByUuid(state, episode.uuid);
    });
    return episodeSync;
};

export const getBookmarksForPodcast = (state: RootState, podcastUuid: string) =>
    Object.values(getEpisodeSyncForPodcast(state, podcastUuid))
        .reduce<Bookmark[]>(
            (bookmarks, episodeSync) => bookmarks.concat(episodeSync.bookmarks ?? []),
            [],
        )
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

export const getBookmarksForEpisode = createSelector(
    [
        (state, episodeUuid) => getUploadedFile(state, episodeUuid),
        (state, episodeUuid) => getEpisodeSyncByUuid(state, episodeUuid),
    ],
    (file, episodeSync) => {
        const bookmarks = file?.bookmarks ?? episodeSync.bookmarks ?? [];
        return [...bookmarks].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    },
);

// Return "Bookmark %n" where %n is the first number that doesn't already exist as a bookmark title
export const getDefaultBookmarkTitleForEpisode = createSelector(
    [
        (state, episodeUuid) => getBookmarksForEpisode(state, episodeUuid),
        (state, episodeUuid, bookmarkText) => bookmarkText,
    ],
    (bookmarksForEpisode, bookmarkText) => {
        const titles = bookmarksForEpisode.map(b => b.title);

        let defaultTitle = bookmarkText;
        let count = 0;

        while (titles.includes(defaultTitle)) {
            count += 1;
            defaultTitle = `${bookmarkText} ${count}`;
        }

        return defaultTitle;
    },
);
