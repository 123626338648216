import { LoaderSquare } from 'components';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from './hooks/react-redux-typed';
import * as fromUserActions from './redux/actions/user.actions';

/**
 * This page allows users to log in to the app using their email and auth token. This could be useful
 * in cases where we need Desktop apps to open a link to the browser version, with the user still
 * signed in.
 *
 * It relies on the email and token being in the URL query string:
 *
 * http://play.pocketcasts.com/token-login?email=example@example.com&token=eYz...
 *
 */
export function OutsideAppAuthenticatorPage() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { email, token } = qs.parse(location.search) as { email?: string; token?: string };

    useEffect(() => {
        if (token && email) {
            dispatch(
                fromUserActions.Actions.signInWithAccessToken({
                    token,
                    email,
                }),
            );
        } else {
            window.location.href = '/';
        }
    }, [token, email, dispatch]);

    return <LoaderSquare />;
}
