import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { ModalTypes } from '../../../helper/UiHelper';
import { useDispatch, useSelector } from '../../../hooks/react-redux-typed';
import * as fromModalActions from '../../../redux/actions/modal.actions';
import { getDiscoverRegion } from '../../../redux/reducers/selectors';
import {
    ContentRegionHeading,
    ContentRegionSelectorWrapper,
    CountryFlag,
    CountryTitle,
    SelectInputCard,
} from './ContentRegionSelector.styled';

function ContentRegionSelector() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const region = useSelector(getDiscoverRegion);
    const name = region?.name || '';
    const flag = region?.flag || 'https://static.pocketcasts.net/discover/flags/global.png';

    const onChangeRegionClicked = () => {
        dispatch(fromModalActions.Actions.showModal(ModalTypes.contentRegionSelection));
    };

    return (
        <ContentRegionSelectorWrapper>
            <ContentRegionHeading>{formatMessage('discover-select-region')}</ContentRegionHeading>
            <SelectInputCard aria-haspopup="true" onClick={onChangeRegionClicked}>
                <CountryFlag alt="" src={flag} />
                <CountryTitle>{name}</CountryTitle>
            </SelectInputCard>
        </ContentRegionSelectorWrapper>
    );
}

export default ContentRegionSelector;
