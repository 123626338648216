import styled from 'styled-components';

export const NumberButton = styled.button`
    width: 36px;
    height: 18px;
    margin: 9px 0;
    text-align: center;
    border: 2px solid #ffffff;
    border-radius: 2px;
    color: #ffffff;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.5;

    display: flex;
    align-items: center;
    justify-content: center;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* These are old styles that can probably be removed in future*/
    .speed-number {
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
    }

    .speed-x {
        ${({ $isChromium }) =>
            $isChromium &&
            `font-size: 12px;
    font-weight: 500;
    padding-top: 1px;
    `}

        ${({ $isChromium }) =>
            !$isChromium &&
            `font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    padding-top: 1px;
    `}
    }

    ${({ $active }) =>
        $active &&
        `
        opacity: 1;
        background-color: #fff;
        color: #000;
    `}
`;

export const PlusButton = styled.div`
    background-color: #fff;
    mask-image: url('data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%2F%3E%3Cpath%20d%3D%22M8%208H5.997C5.453%208%205%208.448%205%209c0%20.556.446%201%20.997%201H8v2.003c0%20.544.448.997%201%20.997.556%200%201-.446%201-.997V10h2.003c.544%200%20.997-.448.997-1%200-.556-.446-1-.997-1H10V5.997C10%205.453%209.552%205%209%205c-.556%200-1%20.446-1%20.997V8zm1%2010c4.97%200%209-4.03%209-9s-4.03-9-9-9-9%204.03-9%209%204.03%209%209%209z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    mask-repeat: no-repeat;
    mask-position: center;
    background-repeat: no-repeat;
    background-position: 9px 0;
    width: 36px;
    height: 19px;
    cursor: pointer;
    opacity: 0;
    position: relative;
    top: 5px;
    transition:
        opacity 150ms,
        top 150ms;
`;
export const MinusButton = styled.div`
    background-color: #fff;
    mask-image: url('data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%2F%3E%3Cpath%20d%3D%22M9%2018c4.97%200%209-4.03%209-9s-4.03-9-9-9-9%204.03-9%209%204.03%209%209%209zM5%209c0-.552.453-1%20.997-1h6.006c.55%200%20.997.444.997%201%200%20.552-.453%201-.997%201H5.997C5.447%2010%205%209.556%205%209z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    mask-repeat: no-repeat;
    mask-position: center;
    background-repeat: no-repeat;
    background-position: 10px 0;
    width: 36px;
    height: 19px;
    cursor: pointer;
    opacity: 0;
    position: relative;
    bottom: 5px;
    transition:
        opacity 150ms,
        bottom 150ms;
`;

export const SpeedControlContainer = styled.div`
    height: 75px;

    &.large {
        ${NumberButton} {
            height: 30px;
        }

        ${PlusButton} {
            margin-top: -12px;
        }
    }

    &:hover,
    &:focus {
        ${PlusButton} {
            opacity: 0.5;
            top: 0;

            &:hover {
                opacity: 1;
            }
        }
        ${NumberButton} {
            opacity: 1;
        }
        ${MinusButton} {
            opacity: 0.5;
            bottom: 0;
            &:hover {
                opacity: 1;
            }
        }
    }
`;
