import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import { useEffect } from 'react';
import * as fromPodcastActions from '../redux/actions/podcast.actions';
import { getColorsByUuid } from '../redux/reducers/selectors';

/**
 * This hook takes a podcast UUID and returns the podcast's colors, fetching them if necessary.
 */
const usePodcastColors = (uuid: string) => {
    const colors = useSelector(state => getColorsByUuid(state, uuid));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!colors) {
            // The colors aren't in state, let's fetch them:
            dispatch(fromPodcastActions.Actions.downloadPodcastColor(uuid));
        }
    }, [uuid]);

    return colors;
};

export default usePodcastColors;
