import { ModalTypes } from 'helper/UiHelper';
import { Folder, TracksProperties } from 'model/types';
import { connect } from 'react-redux';
import * as fromFoldersActions from '../../redux/actions/folders.actions';
import * as fromModalActions from '../../redux/actions/modal.actions';
import * as fromPodcastsActions from '../../redux/actions/podcasts.actions';
import * as fromSettingsActions from '../../redux/actions/settings.actions';
import * as fromTracksActions from '../../redux/actions/tracks.actions';
import { RootState } from '../../redux/reducers';
import { AppDispatch } from '../../store';
import PodcastsPage from './PodcastsPage';
import {
    PodcastBadges,
    PodcastGridLayouts,
    PodcastGridOrder,
    PodcastListRearrangeAction,
} from './model';

const mapStateToProps = (state: RootState) => {
    return {
        loadFailed: state.podcasts.loadFailed,
        isLoading: state.podcasts.isLoading || !state.podcasts.isLoaded,
        gridLayout: state.settings.gridLayout,
        gridOrder: state.settings.gridOrder,
        badges: state.settings.badges,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    downloadSubscribedPodcasts: () =>
        dispatch(fromPodcastsActions.Actions.downloadSubscribedPodcasts()),
    savePodcastGridLayout: (layout: PodcastGridLayouts) =>
        dispatch(fromSettingsActions.Actions.savePodcastGridLayout(layout)),
    savePodcastGridBadgesOn: (badges: PodcastBadges) =>
        dispatch(fromSettingsActions.Actions.savePodcastGridBadgesOn(badges)),
    savePodcastGridOrder: (order: PodcastGridOrder) =>
        dispatch(fromSettingsActions.Actions.savePodcastGridOrder(order)),
    showModal: (modalType: ModalTypes, data: Record<string, unknown> | undefined) =>
        dispatch(fromModalActions.Actions.showModal(modalType, data)),
    updateFolder: (folder: Folder, podcastUuids: string[]) =>
        dispatch(fromFoldersActions.Actions.updateFolder(folder, podcastUuids)),
    rearrangePodcastList: (actions: PodcastListRearrangeAction[]) =>
        dispatch(fromPodcastsActions.Actions.rearrangePodcastList(actions)),
    recordEvent: (event: string, properties?: TracksProperties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PodcastsPage);
