import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import {
    ImageWrapper,
    ModalMessage,
    ModalTitle,
    Spaces,
    TextContainer,
} from './MessageModal.styled';

interface Props {
    title: string;
    message: string;
    buttonLabel?: string;
    image?: React.ReactNode;
    onClose: () => void;
}

function MessageModal({ image, message, title, buttonLabel, onClose }: Props) {
    const formatMessage = useFormatMessage();

    return (
        <Modal horizontalInsetPx={32} onClose={onClose}>
            {image && <ImageWrapper>{image}</ImageWrapper>}
            <TextContainer>
                <ModalTitle>{title}</ModalTitle>
                <Spaces $n={2} />
                <ModalMessage>{message}</ModalMessage>
                <Spaces $n={4} />
            </TextContainer>
            <Button onClick={onClose} width={355}>
                {buttonLabel ?? formatMessage('done')}
            </Button>
            <Spaces $n={4} />
        </Modal>
    );
}

export default MessageModal;
