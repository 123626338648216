import styled, { css } from 'styled-components';
import { Typography } from '../../../styles';

const HoverMixin = css`
    background-color: ${props => props.theme.tokens['primary-field-01']};
    border-radius: 4px;
`;

export const SettingNavigationWrapper = styled.div<{ $isSelected: boolean }>`
    a {
        ${Typography.Desktop.H70};
        text-decoration: none;
        user-select: none;
        color: ${props => props.theme.tokens['primary-text-01']};

        width: 179px;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        line-height: 1.2;
        margin-bottom: 1px;
        ${props => props.$isSelected && HoverMixin}
    }
    &:hover {
        a {
            ${HoverMixin};
        }
    }
`;
