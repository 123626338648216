import { detect, detectOS } from 'detect-browser';
import { doSignOut } from 'helper/SignOutHelper';
import capitalize from 'lodash/capitalize';
import {
    SubscriptionData,
    TodoFixmeMigrationType,
    UserStats,
    globalSettingsToMigrate,
} from 'model/types';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { setTokenData } from 'services/auth';
import { normalisedUpdateIntlAction } from 'translations';
import { getElectronAppVersion, isElectronApp, isMacApp, isWindowsApp } from '../../helper/Browser';
import { api } from '../../services/api';
import * as Settings from '../../settings';
import * as fromSettingsActions from '../actions/settings.actions';
import * as fromSubscriptionActions from '../actions/subscription.actions';
import * as fromTracksActions from '../actions/tracks.actions';
import * as fromUserActions from '../actions/user.actions';
import { getDefaultTracksSource, getEmail, getSettings, getTheme } from '../reducers/selectors';
import { userIsLoggedIn } from '../reducers/selectors/user.selectors';
import { logSagaError } from './saga-helper';

function* forgottenPassword(action: ReturnType<typeof fromUserActions.Actions.forgottenPassword>) {
    const { email } = action.payload;

    try {
        const response: { message: string; success: boolean } = yield call(
            api.sendPasswordResetEmail,
            email,
        );
        if (response.success) {
            yield put(fromTracksActions.Actions.recordEvent('user_password_reset'));
            yield put(fromUserActions.Actions.forgottenPasswordSent());
        } else {
            yield put(fromUserActions.Actions.forgottenPasswordError(response.message));
        }
    } catch (error) {
        if (!(error instanceof Error)) {
            throw error;
        }
        yield put(fromUserActions.Actions.forgottenPasswordError(error.message));
    }
}

function* signUp(action: ReturnType<typeof fromUserActions.Actions.signUp>) {
    // Password validation and confirmation is done in the form component.
    const { email, password } = action.payload;

    try {
        const response: {
            message: string;
            success: boolean;
            errorMessageId: string;
            errorMessage: string;
            errors: { message: string }[];
        } = yield call(api.createUserWithEmailAndPassword, email, password);

        if (response.success) {
            const source: string | undefined = yield select(getDefaultTracksSource);
            yield put(
                fromTracksActions.Actions.recordEvent('user_account_created', {
                    source: 'password',
                    source_from_code: source,
                }),
            );

            // Save the user theme preference
            const theme: ReturnType<typeof getTheme> = yield select(getTheme);
            const initialTheme = theme;
            yield put(fromSettingsActions.Actions.updateTheme(initialTheme));
            yield call(api.saveSettings, { theme: initialTheme });

            yield put(fromUserActions.Actions.signUpSuccess(email));

            // Fetch subscription data so it's populated in Redux
            yield put(fromSubscriptionActions.Actions.fetchSubscriptionData());

            // If a user is signing up via the Web Player, we don't
            // need to show them the Free Gift welcome experience.
            yield put(fromSubscriptionActions.Actions.acknowledgeFreeGiftExperience());

            // Save the user's browser language to their settings
            yield put(fromSettingsActions.Actions.saveLanguage(window.navigator.language));

            return;
        }

        yield put(
            fromTracksActions.Actions.recordEvent('user_account_creation_failed', {
                error_code: response.errorMessageId,
            }),
        );

        // If the user creation failed because the email already exists, try signing the user in instead
        if (response.errorMessageId === 'login_email_taken') {
            yield put(fromUserActions.Actions.signIn({ email, password }));
            return;
        }

        // Some error was hit that we didn't handle. Show an error message.
        const messagePrefix =
            response.message || response.errorMessage || "We couldn't set up that account, sorry.";
        const errorsArray = response.errors || [];
        yield put(fromUserActions.Actions.signUpError(`${messagePrefix} ${errorsArray.join(' ')}`));
    } catch (error) {
        if (!(error instanceof Error)) {
            throw error;
        }
        yield put(fromUserActions.Actions.signUpError(error.message));
    }
}

function* downloadStats() {
    try {
        const stats: UserStats = yield call(api.fetchStats);
        yield put(fromUserActions.Actions.downloadStatsSuccess(stats));
    } catch (error) {
        logSagaError('Download stats failed', error);
    }
}

export function* downloadSettings() {
    const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

    if (!isLoggedIn) {
        return;
    }

    try {
        const settings: ReturnType<typeof getSettings> = yield call(api.fetchSettings);
        yield put(fromSettingsActions.Actions.importSettings(settings));

        // https://pocketcastsp2.wordpress.com/2024/03/07/changing-the-scope-of-named-settings
        const settingsToMigrate = globalSettingsToMigrate.filter(
            setting => settings.changed[setting] && !settings.changed[`${setting}Global`],
        );
        if (settingsToMigrate.length) {
            yield put(fromTracksActions.Actions.recordEvent('settings_migrated'));
            yield put(fromSettingsActions.Actions.migrateGlobalSettings(settingsToMigrate));
        }

        yield put(normalisedUpdateIntlAction(settings.language));
    } catch (error) {
        logSagaError('Download settings failed', error);
    }
}

function* signIn(
    action: ReturnType<
        | typeof fromUserActions.Actions.signIn
        | typeof fromUserActions.Actions.signInWithOAuth
        | typeof fromUserActions.Actions.signInWithAccessToken
    >,
) {
    let logInSource;
    try {
        let userEmail;
        let userIsNew = false;

        // Select logIn call based on type of login
        if (action.type === fromUserActions.ActionTypes.SIGN_IN_WITH_ACCESS_TOKEN) {
            const { email, token } = action.payload;
            setTokenData({ accessToken: token });
            userEmail = email;
            logInSource = 'token';
        } else if (action.type === fromUserActions.ActionTypes.SIGN_IN_WITH_OAUTH) {
            const { provider, idToken } = action.payload;
            logInSource = provider;
            const loginResponse: Awaited<ReturnType<typeof api.signInWithOAuth>> = yield call(
                api.signInWithOAuth,
                provider,
                idToken,
            );
            userEmail = loginResponse.email;
            userIsNew = loginResponse.isNew;
        } else {
            const { email, password } = action.payload;
            logInSource = 'password';
            const loginResponse: { email: string; userIsNew: boolean } = yield call(
                api.signInWithPocketCasts,
                email,
                password,
            );
            userEmail = loginResponse.email;
            userIsNew = loginResponse.userIsNew;
        }

        const source: string | undefined = yield select(getDefaultTracksSource);
        yield put(
            fromTracksActions.Actions.recordEvent('user_signed_in', {
                source: logInSource,
                source_in_code: source,
            }),
        );
        if (userIsNew) {
            // Save the user's browser language to their settings
            yield put(fromSettingsActions.Actions.saveLanguage(window.navigator.language));

            // Save the user theme preference
            const theme: ReturnType<typeof getTheme> = yield select(getTheme);
            const initialTheme = theme;
            yield put(fromSettingsActions.Actions.updateTheme(initialTheme));
            yield call(api.saveSettings, { theme: initialTheme });

            yield put(
                fromTracksActions.Actions.recordEvent('user_account_created', {
                    source: logInSource,
                }),
            );
        }

        yield put(fromUserActions.Actions.signInSuccess({ email: userEmail, isNew: userIsNew }));
        yield put(fromSubscriptionActions.Actions.fetchSubscriptionData());
        yield put(fromUserActions.Actions.userReturned());
    } catch (error) {
        if (logInSource) {
            yield put(
                fromTracksActions.Actions.recordEvent('user_signin_failed', {
                    source: logInSource,
                }),
            );
        }

        if (!(error instanceof Error)) {
            throw error;
        }

        yield put(fromUserActions.Actions.signInFailure(error.message));
    }
}

function* signOut() {
    yield put(
        fromTracksActions.Actions.recordEvent('user_signed_out', {
            user_initiated: true,
        }),
    );
    yield call(doSignOut);
}

function* sendSupportMessage(
    action: ReturnType<typeof fromUserActions.Actions.sendSupportMessage>,
) {
    try {
        yield put(fromUserActions.Actions.sendingSupportMessage());

        const { debug, message } = action.payload;
        const supportReason = action.payload.reason;

        const email: ReturnType<typeof getEmail> = yield select(getEmail);

        const browser = detect();
        const os = detectOS(navigator.userAgent);

        let platform;
        if (!browser) {
            platform = `${os || ''} ${navigator.userAgent}`;
        } else if (isElectronApp()) {
            const electronVersion = getElectronAppVersion();
            platform = `Pocket Casts Desktop ${electronVersion} (${os}). Web Player ${Settings.VERSION}.`;
        } else {
            platform = `Version ${Settings.VERSION}. ${capitalize(browser.name)} on ${browser.os}.`;
        }

        let subject = `Web Player - Web Browser - ${supportReason}`;
        if (isElectronApp()) {
            subject = `Web Player - Electron App - ${browser.os} - ${supportReason}`;
        } else if (isMacApp()) {
            subject = `Web Player - Mac App - ${supportReason}`;
        } else if (isWindowsApp()) {
            subject = `Web Player - Windows App - ${supportReason}`;
        }

        yield call(api.sendSupportFeedback, email, subject, `${platform}\n${message}`, debug);
        yield put(fromUserActions.Actions.sentSupportMessage());
    } catch (error) {
        logSagaError('Send support message failed', error);
        yield put(fromUserActions.Actions.sendingSupportMessageFailed());
    }
}

function* saveSettings(action: TodoFixmeMigrationType) {
    const settings: Record<string, unknown> = action.payload ?? {};
    const isLoggedIn: ReturnType<typeof userIsLoggedIn> = yield select(userIsLoggedIn);

    // as theme changes don't pass in the value get it out of the state
    if (
        action.type === fromSettingsActions.ActionTypes.TOGGLE_THEME ||
        action.type === fromSettingsActions.ActionTypes.UPDATE_THEME
    ) {
        settings.theme = yield select(getTheme);
    } else if (action.type === fromSettingsActions.ActionTypes.TOGGLE_RECOMMENDATIONS) {
        const stateSettings: ReturnType<typeof getSettings> = yield select(getSettings);
        settings.recommendationsOn = stateSettings.recommendationsOn;
    } else if (action.type === fromSettingsActions.ActionTypes.SAVE_AUTO_ARCHIVE_PLAYED) {
        const stateSettings: ReturnType<typeof getSettings> = yield select(getSettings);
        // Web only supports true and false while the API supports timed values as well.
        settings.autoArchivePlayed = stateSettings.autoArchivePlayed ? '1' : '0';
    }

    try {
        if (!isLoggedIn) {
            return;
        }
        yield call(api.saveSettings, settings);
    } catch (error) {
        logSagaError('Save settings failed', error);
    }
}

function* changeEmail(action: ReturnType<typeof fromUserActions.Actions.requestEmailChange>) {
    const response: { success: boolean; message: string } = yield call(
        api.changeEmail,
        action.payload.newEmail,
        action.payload.password,
    );

    if (response.success) {
        yield put(fromTracksActions.Actions.recordEvent('user_email_updated'));
    }

    yield put(
        fromUserActions.Actions.emailChangeResponse(
            response.success,
            response.message,
            action.payload.newEmail,
        ),
    );
}

function* deleteAccount() {
    try {
        const response: { success: boolean; message: string } = yield call(api.deleteAccount);

        yield put(
            fromUserActions.Actions.accountDeleteResponse(response.success, response.message),
        );

        if (response.success) {
            yield put(fromTracksActions.Actions.recordEvent('user_account_deleted'));
            yield call(doSignOut);
        }
    } catch (error) {
        if (!(error instanceof Error)) {
            throw error;
        }
        yield put(fromUserActions.Actions.accountDeleteResponse(false, error.message));
    }
}

function* changePassword(action: ReturnType<typeof fromUserActions.Actions.requestPasswordChange>) {
    const response: { success: boolean; message: string } = yield call(
        api.changePassword,
        action.payload.oldPassword,
        action.payload.newPassword,
    );

    if (response.success) {
        yield put(fromTracksActions.Actions.recordEvent('user_password_updated'));
    }
    yield put(fromUserActions.Actions.passwordChangeResponse(response.success, response.message));
}

function* resetPassword(action: ReturnType<typeof fromUserActions.Actions.requestResetPassword>) {
    try {
        const response: { success: boolean; message: string } = yield call(
            api.resetPassword,
            action.payload.newPassword,
            action.payload.resetPasswordToken,
        );
        yield put(
            fromUserActions.Actions.resetPasswordResponse(response.success, response.message),
        );
    } catch (error) {
        if (!(error instanceof Error)) {
            throw error;
        }
        yield put(fromUserActions.Actions.resetPasswordResponse(false, error.message));
    }
}

export function* calculateSettings() {
    // https://stackoverflow.com/a/27232658
    const isWebpSupported = () => {
        const elem = document.createElement('canvas');

        if (elem.getContext && elem.getContext('2d')) {
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }

        return false;
    };

    yield put(fromSettingsActions.Actions.saveWebpSupported(isWebpSupported()));
}

function* purchaseWebPlayer(
    action: ReturnType<typeof fromUserActions.Actions.requestPurchaseWebPlayer>,
) {
    const { transactionId, email, productId, subscriptionId } = action.payload;

    try {
        const payload: SubscriptionData = yield call(api.purchaseWebPlayer, {
            transactionId,
            email,
            productId,
            subscriptionId,
        });

        const source: string | undefined = yield select(getDefaultTracksSource);

        yield put(
            fromTracksActions.Actions.recordEvent('purchase_successful', {
                product: action.payload.plan,
                offer_type: action.payload.offerType,
                // TODO [https://github.com/Automattic/pocket-casts-webplayer/issues/2466]: remove this once we've updated our reports to only rely on offer_type
                is_free_trial: action.payload.trialDays > 0,
                source,
            }),
        );

        yield put(fromUserActions.Actions.purchaseWebPlayerSuccess(payload));
    } catch (error) {
        yield put(
            fromUserActions.Actions.purchaseWebPlayerFailure({
                ...action.payload,
                error: error instanceof Error ? error.message : String(error),
            }),
        );
    }
}

function* purchaseWebPlayerFailure(
    action: ReturnType<typeof fromUserActions.Actions.purchaseWebPlayerFailure>,
) {
    const source: string | undefined = yield select(getDefaultTracksSource);
    const eventProperties = {
        ...('plan' in action.payload ? { product: action.payload.plan } : {}),
        ...('offerType' in action.payload ? { offer_type: action.payload.offerType } : {}),
        // TODO [https://github.com/Automattic/pocket-casts-webplayer/issues/2466]: remove this once we've updated our reports to only rely on offer_type
        ...('trialDays' in action.payload ? { is_free_trial: action.payload.trialDays > 0 } : {}),
        error_code: action.payload?.error,
        source,
    };

    yield put(fromTracksActions.Actions.recordEvent('purchase_failed', eventProperties));
}

export function* watchSignIn() {
    yield takeLatest(
        [
            fromUserActions.ActionTypes.SIGN_IN,
            fromUserActions.ActionTypes.SIGN_IN_WITH_OAUTH,
            fromUserActions.ActionTypes.SIGN_IN_WITH_ACCESS_TOKEN,
        ],
        signIn,
    );
}

export function* watchSignUp() {
    yield takeLatest(fromUserActions.ActionTypes.SIGN_UP, signUp);
}

export function* watchSignOut() {
    yield takeLatest(fromUserActions.ActionTypes.SIGN_OUT, signOut);
}

export function* watchForgottenPassword() {
    yield takeLatest(fromUserActions.ActionTypes.FORGOTTEN_PASSWORD, forgottenPassword);
}

export function* watchSettingsChanges() {
    yield takeEvery(
        [
            fromSettingsActions.ActionTypes.SAVE_INTELLIGENT_RESUMPTION,
            fromSettingsActions.ActionTypes.SAVE_PRIVACY_ANALYTICS,
            fromSettingsActions.ActionTypes.SAVE_SHOW_ARCHIVED,
            fromSettingsActions.ActionTypes.SAVE_FILES_AFTER_PLAYING_DELETE_CLOUD,
            fromSettingsActions.ActionTypes.SAVE_REMOTE_SKIP_CHAPTERS,
            fromSettingsActions.ActionTypes.SAVE_FILES_AUTO_UP_NEXT,
            fromSettingsActions.ActionTypes.SAVE_PODCAST_GRID_ORDER,
            fromSettingsActions.ActionTypes.SAVE_PODCAST_GRID_LAYOUT,
            fromSettingsActions.ActionTypes.SAVE_PODCAST_GRID_BADGES_ON,
            fromSettingsActions.ActionTypes.SAVE_BOOKMARKS_SORT_ORDER,
            fromSettingsActions.ActionTypes.SAVE_LANGUAGE,
            fromSettingsActions.ActionTypes.SAVE_REGION,
            fromSettingsActions.ActionTypes.TOGGLE_THEME,
            fromSettingsActions.ActionTypes.UPDATE_THEME,
            fromSettingsActions.ActionTypes.SAVE_SKIP_FORWARD,
            fromSettingsActions.ActionTypes.SAVE_SKIP_BACK,
            fromSettingsActions.ActionTypes.TOGGLE_RECOMMENDATIONS,
            fromSettingsActions.ActionTypes.SAVE_AUTO_ARCHIVE_PLAYED,
            fromSettingsActions.ActionTypes.SAVE_AUTO_ARCHIVE_INCLUDES_STARRED,
            fromSettingsActions.ActionTypes.SAVE_PLAYBACK_SPEED,
            fromSettingsActions.ActionTypes.SAVE_AUTOPLAY_ENABLED,
        ],
        saveSettings,
    );
}

export function* watchDownloadStats() {
    yield takeLatest(fromUserActions.ActionTypes.DOWNLOAD_STATS, downloadStats);
}

export function* watchSendSupportMessage() {
    yield takeLatest(fromUserActions.ActionTypes.SEND_SUPPORT_MESSAGE, sendSupportMessage);
}

export function* watchChangeEmail() {
    yield takeLatest(fromUserActions.ActionTypes.EMAIL_CHANGE_REQUEST, changeEmail);
}

export function* watchDeleteAccount() {
    yield takeLatest(fromUserActions.ActionTypes.ACCOUNT_DELETE_REQUEST, deleteAccount);
}

export function* watchChangePassword() {
    yield takeLatest(fromUserActions.ActionTypes.PASSWORD_CHANGE_REQUEST, changePassword);
}

export function* watchResetPassword() {
    yield takeLatest(fromUserActions.ActionTypes.PASSWORD_RESET_REQUEST, resetPassword);
}

export function* watchPurchaseWebPlayer() {
    yield takeLatest(fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_REQUEST, purchaseWebPlayer);
}

export function* watchPurchaseWebPlayerFailure() {
    yield takeLatest(
        fromUserActions.ActionTypes.PURCHASE_WEB_PLAYER_FAILURE,
        purchaseWebPlayerFailure,
    );
}
