import { PaddleDiscountedPlan, PaddlePlan } from '../model/types';
import { usePaddleProducts } from './usePaddleProducts';

const usePaddleProduct = (
    plan: PaddlePlan | PaddleDiscountedPlan,
    { code }: { code?: string } = {},
) => {
    const paddleProducts = usePaddleProducts({ code });
    return paddleProducts[plan];
};

export default usePaddleProduct;
