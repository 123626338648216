import styled from 'styled-components';
import { gridSize, H70 } from '../../../../styles';

export const ImageWrapper = styled.div`
    display: flex;
    width: ${14 * gridSize}px;
    height: ${14 * gridSize}px;

    align-items: center;
    margin-left: 20px;
`;

export const SubtitleWrapper = styled(H70)`
    text-align: center;
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
`;

export const Spaces = styled.div<{ $n: number }>`
    height: ${props => gridSize * (props.$n || 1)}px;
`;
