import { PodcastDiscover } from 'model/types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PodcastImage from '../../PodcastImage';
import {
    Card,
    CardAuthor,
    CardDescription,
    CardFeaturedBadge,
    CardImageWrapper,
    CardPodcastTitle,
    CardSubscribeButton,
    CardText,
    DiscoverCarouselCardWrapper,
} from './DiscoverCarousel.styled';

type Props = {
    podcast: PodcastDiscover;
    onPodcastClick: (podcast: PodcastDiscover, sponsoredListId?: string) => void;
    onSubscribe: (podcast: PodcastDiscover, sponsoredListId?: string) => void;
};

const DiscoverCarouselCard: React.FC<Props> = ({ podcast, onPodcastClick, onSubscribe }) => {
    const [mouseDownXCoordinate, setMouseDownXCoordinate] = useState<number | undefined>(undefined);

    const handleOnMouseDown = (e: React.MouseEvent) => {
        setMouseDownXCoordinate(e.clientX);
        e.preventDefault(); // stops weird link dragging effect
    };

    const didClickPodcast = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (mouseDownXCoordinate === e.clientX) {
            onPodcastClick(podcast, podcast.sponsoredListId);
        }
    };

    const carouselCardAriaLabel = podcast.sponsoredListId
        ? 'Sponsored podcast'
        : 'Featured podcast';

    return (
        <DiscoverCarouselCardWrapper>
            <Card
                role="button"
                aria-label={carouselCardAriaLabel}
                onMouseDown={handleOnMouseDown}
                onClick={didClickPodcast}
            >
                <CardImageWrapper>
                    <PodcastImage title={podcast.title || ''} uuid={podcast.uuid} />
                </CardImageWrapper>
                <CardText>
                    {podcast.sponsoredListId ? (
                        <CardFeaturedBadge $isSponsored>
                            <FormattedMessage id="sponsored" />
                        </CardFeaturedBadge>
                    ) : (
                        <CardFeaturedBadge>
                            <FormattedMessage id="featured-all-caps" />
                        </CardFeaturedBadge>
                    )}
                    <CardPodcastTitle>{podcast.title || ''}</CardPodcastTitle>
                    <CardAuthor $long={podcast.author != null && podcast.author.length > 30}>
                        {podcast.author || ''}
                    </CardAuthor>
                    <CardDescription>{podcast.description}</CardDescription>
                </CardText>
                <CardSubscribeButton
                    podcastUuid={podcast.uuid}
                    onSubscribe={() => onSubscribe(podcast, podcast.sponsoredListId)}
                    eventSource="discover"
                    circled
                />
            </Card>
        </DiscoverCarouselCardWrapper>
    );
};

export default DiscoverCarouselCard;
