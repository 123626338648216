import ModalComponent from 'components/Modal';
import { ChildrenWrapper } from 'components/Modal/Modal.styled';
import { CredentialNotificationWrapper } from 'components/form/CredentialNotification/CredentialNotification.styled';
import styled from 'styled-components';
import { gridSize } from 'styles';

export const Wrapper = styled.div`
    form,
    ${CredentialNotificationWrapper} {
        margin-top: ${2 * gridSize}px;
    }
`;

export const Modal = styled(ModalComponent)`
    width: 95vw;
    max-width: 564px;
    margin-top: 0 !important;
    text-align: center;
    ${ChildrenWrapper} {
        margin-top: 24px;
    }
    h1 {
        max-width: 340px;
        margin: ${7 * gridSize}px 0 ${gridSize}px;
        letter-spacing: -0.02em;
    }
    p {
        margin: 0 0 ${3 * gridSize}px;
        line-height: 1.5;
    }
    a {
        margin-bottom: ${2 * gridSize}px;
    }
    small {
        display: block;
        margin-top: ${2 * gridSize}px;
        font-size: 14px;
        line-height: 1.5;

        a {
            display: inline-block;
            color: inherit;
            font-weight: 700;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
`;

export const FeaturesListContainer = styled.ul`
    list-style-type: none;
    margin: ${gridSize}px 0 ${3 * gridSize}px;
    padding: 0;
    text-align: left;
    width: 100%;
`;

export const FeaturesListItem = styled.li`
    font-size: 14px;
    display: flex;
    line-height: 1.5;
    margin-bottom: ${2 * gridSize}px;
    img {
        min-width: 24px !important;
        height: 24px;
        margin: -1px ${2 * gridSize}px 0 0;
    }
`;

export const StyledTitle = styled.h1`
    text-align: center;
`;
