import LiquidMetal from 'liquidmetal';
import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import {
    getFolders,
    getPodcastUuidsByFolderUuid,
    getSubscribedPodcasts,
    getSubscribedPodcastUuids,
} from './podcasts.selectors';

export const getSearch = (state: RootState) => state.search;

export const getRawSearchResponse = (state: RootState, term: string) =>
    state.search.responses[term];

export const getSearchResponse = createSelector(
    [
        getRawSearchResponse,
        (state, term) => term,
        (state, term) => getMatchingSubscriptions(state, term),
    ],
    (response, term: string, matchingSubscription) => {
        if (!response) {
            return response;
        }

        const matchingSubscriptionUuids = matchingSubscription.map(item => item.uuid);
        const dedupedPodcasts = response?.podcasts?.filter(
            podcast => !matchingSubscriptionUuids.includes(podcast.uuid),
        );

        return { ...response, podcasts: dedupedPodcasts };
    },
);

export const getMatchingSubscriptions = createSelector(
    [getPodcastUuidsByFolderUuid, (state, term) => term, getFolders, getSubscribedPodcasts],
    (podcastUuidsByFolderUuid, term: string, folders, podcasts) => {
        const termLower = term.toLowerCase();
        const detailFolders = folders.map(folder => ({
            uuid: folder.uuid,
            name: folder.name,
            sortType: folder.sortType,
            color: folder.color,
            podcastUuids: podcastUuidsByFolderUuid[folder.uuid],
        }));

        // Gather match scores for each Folder/Podcast. We require that the search term exactly appears,
        // and then we use LiquidMetal to score by best match.
        const matchScores: Record<string, number> = {};
        detailFolders.forEach(folder => {
            matchScores[folder.uuid] =
                folder.name.toLowerCase().indexOf(termLower) === -1
                    ? 0
                    : LiquidMetal.score(folder.name, term);
        });
        podcasts.forEach(podcast => {
            const title = typeof podcast.title === 'string' ? podcast.title : '';
            const author = typeof podcast.author === 'string' ? podcast.author : '';
            matchScores[podcast.uuid] = Math.max(
                title.toLowerCase().indexOf(termLower) === -1 ? 0 : LiquidMetal.score(title, term),
                author.toLowerCase().indexOf(termLower) === -1
                    ? 0
                    : LiquidMetal.score(author, term),
            );
        });

        // Return mixed list of Folders and Podcasts, sorted by best match first
        return [...detailFolders, ...podcasts]
            .filter(item => matchScores[item.uuid] > 0)
            .sort((a, b) => (matchScores[a.uuid] > matchScores[b.uuid] ? -1 : 1));
    },
);

export const getSearchHistory = createSelector(
    [getSubscribedPodcastUuids, getSearch],
    (subscribedPodcastUuids, search) => {
        return search.history.map(item => {
            if ('podcast' in item) {
                // Attach current subscribed value to podcasts
                return {
                    podcast: item.podcast,
                    subscribed: subscribedPodcastUuids.includes(item.podcast.uuid),
                };
            }
            return item;
        });
    },
);
