import { zodResolver } from '@hookform/resolvers/zod';
import { BackButton } from 'components/BackButton';
import { Button } from 'components/Button';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { StyledForm, StyledFormError } from 'components/form/form.styled';
import Input from 'components/Input';
import useFormatMessage from 'hooks/useFormatMessage';
import { POCKET_CASTS_SUPPORT } from 'model/external-links';
import qs from 'query-string';
import React, { useEffect, useId } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router';
import { z } from 'zod';
import { FORM_ERRORS_DELAY } from '../../settings';

type Props = {
    requestInProgress: boolean;
    requestSucceeded?: boolean;
    responseMessage?: string;
    requestResetPassword: (newPassword: string, token: string) => void;
};

type PasswordResetInputs = {
    password: string;
    passwordConfirmation: string;
};

const schema = (intl: IntlShape) =>
    z
        .object({
            password: z
                .string({ required_error: intl.formatMessage({ id: 'field-required' }) })
                .min(6, intl.formatMessage({ id: 'password-restriction' }))
                .max(128, intl.formatMessage({ id: 'password-restriction-max' })),
            passwordConfirmation: z.string({
                required_error: intl.formatMessage({ id: 'field-required' }),
            }),
        })
        .required()
        .refine(data => data.password === data.passwordConfirmation, {
            message: intl.formatMessage({ id: 'passwords-must-match' }),
            path: ['passwordConfirmation'],
        });

function PasswordResetPage({
    requestResetPassword,
    requestInProgress,
    requestSucceeded,
    responseMessage,
}: Props) {
    const navigate = useNavigate();
    const formatMessage = useFormatMessage();

    const {
        register,
        handleSubmit,
        getFieldState,
        formState: { errors, isValid },
    } = useForm<PasswordResetInputs>({
        resolver: zodResolver(schema(useIntl())),
        mode: 'onChange',
        delayError: FORM_ERRORS_DELAY,
    });

    const onSubmit: SubmitHandler<PasswordResetInputs> = data => {
        const { password } = data;

        const resetPasswordToken = qs.parse(location.search).reset_password_token as string;

        requestResetPassword(password, resetPasswordToken);
    };

    useEffect(() => {
        if (requestSucceeded) {
            navigate('/user/login', {
                state: {
                    loginSuccessMessage: formatMessage('password-reset-success'),
                },
            });
        }
    }, [formatMessage, navigate, requestSucceeded]);

    const showError = !requestSucceeded && responseMessage;
    const isInvalidTokenError = showError && responseMessage.includes('Token not valid');

    const passwordInputId = useId();
    const passwordConfirmationInputId = useId();

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Helmet>
                <title>{formatMessage('reset-password')}</title>
            </Helmet>
            <BackButton buttonText={formatMessage('back')} />
            <h1>{formatMessage('reset-password')}</h1>
            {!showError && <p>{formatMessage('please-enter-new-password')}</p>}
            {showError && (
                <CredentialNotification>
                    {isInvalidTokenError && (
                        <>
                            <p>
                                This reset link has expired or you have already used it to change
                                your password.
                            </p>
                            <Link to="/user/forgotten_password">
                                You can reset your password again.
                            </Link>
                        </>
                    )}
                    {!isInvalidTokenError && (
                        <>
                            <p>{formatMessage('password-reset-failure')}</p>
                            <p>{`(Error: ${responseMessage})`}</p>
                            <a
                                target="_blank"
                                href={POCKET_CASTS_SUPPORT}
                                rel="noopener noreferrer"
                            >
                                {formatMessage('if-you-still-have-issues-please-let-us-know')}
                            </a>
                        </>
                    )}
                </CredentialNotification>
            )}

            <label htmlFor={passwordInputId}>{formatMessage('new-password')}</label>
            <Input
                id={passwordInputId}
                type="password"
                status={
                    getFieldState('password').isDirty
                        ? errors.password
                            ? 'error'
                            : 'ok'
                        : undefined
                }
                {...register('password')}
            />
            <StyledFormError>{errors.password?.message}</StyledFormError>
            <label htmlFor={passwordConfirmationInputId}>
                {formatMessage('confirm-new-password')}
            </label>
            <Input
                id={passwordConfirmationInputId}
                aria-label="Password Again"
                type="password"
                status={
                    getFieldState('passwordConfirmation').isDirty
                        ? errors.passwordConfirmation
                            ? 'error'
                            : 'ok'
                        : undefined
                }
                {...register('passwordConfirmation')}
            />
            <StyledFormError>{errors.passwordConfirmation?.message}</StyledFormError>
            <Button
                type="submit"
                kind="primary"
                className="submit-button"
                data-testid="submit-button"
                loading={requestInProgress}
                disabled={isValid === false}
            >
                {formatMessage('reset-password')}
            </Button>
        </StyledForm>
    );
}

export default PasswordResetPage;
