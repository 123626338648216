import { BlurryBackground as BlurryBackgroundComponent } from 'components/BlurryBackground';
import PodcastImageComponent from 'components/PodcastImage';
import styled from 'styled-components';
import { gridSize } from 'styles';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const BlurryBackground = styled(BlurryBackgroundComponent)`
    position: absolute;
    left: 0;
    top: 0;
    width: '100%';
    height: '100%';

    // Animate the blurry background in, for a smoother transition
    @keyframes appear {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    animation: 300ms appear ease-in-out;
    animation-fill-mode: both;
`;

export const Content = styled.div`
    color: #fff;
    margin: auto;
    padding: ${4 * gridSize}px 6vw;
    overflow-y: auto;
    position: relative;
`;

export const Preview = styled.section`
    background: rgba(0, 0, 0, 0.24);
    box-shadow:
        0px 29px 11px rgba(0, 0, 0, 0.01),
        0px 16px 10px rgba(0, 0, 0, 0.05),
        0px 7px 7px rgba(0, 0, 0, 0.09),
        0px 2px 4px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 16px;
    padding: ${3 * gridSize}px ${7 * gridSize}px ${3 * gridSize}px ${2 * gridSize}px;
    display: flex;
    align-items: center;
    margin-bottom: ${3 * gridSize}px;
    position: relative;

    @media (min-width: 800px) {
        max-width: 480px;
        margin-bottom: ${5 * gridSize}px;
    }
    @media (min-width: 1100px) {
        padding: ${3 * gridSize}px ${9 * gridSize}px ${3 * gridSize}px ${3 * gridSize}px;
    }
`;

export const PodcastImage = styled(PodcastImageComponent)`
    width: 80px;
    margin-right: ${2 * gridSize}px;
    flex-shrink: 0;
    @media (min-width: 1100px) and (min-height: 600px) {
        width: 120px;
        margin-right: ${3 * gridSize}px;
    }
`;

export const PodcastTitle = styled.span<{ hasContent: boolean }>`
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    opacity: ${props => (props.hasContent ? 1 : 0)};
    transition: opacity 300ms ease-in-out;
    h1 {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 ${0.5 * gridSize}px;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @media (min-width: 1100px) and (min-height: 600px) {
            font-size: 24px;
        }
    }
    span {
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

export const PlayPauseButton = styled.button`
    position: absolute;
    right: ${2 * gridSize}px;
    bottom: ${2 * gridSize}px;
    height: 32px;
    width: 32px;
    svg {
        width: 100%;
        height: auto;
    }
    @media (min-width: 1100px) {
        height: 40px;
        width: 40px;
        right: ${3 * gridSize}px;
        bottom: ${3 * gridSize}px;
    }
`;

export const Text = styled.section`
    margin-bottom: auto;
    z-index: 1;
    @media (min-width: 800px) {
        max-width: 480px;
        h1 {
            max-width: 15em;
        }
    }
    h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.33;
    }
    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin: ${gridSize}px 0 ${4 * gridSize}px;
        opacity: 0.8;
    }
`;
