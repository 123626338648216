import styled from 'styled-components';
import { PLAYER_HEIGHT } from '../../../model/page';
import { gridSize } from '../../../styles';

export const NotificationContainerWrapper = styled.div`
    position: absolute;
    bottom: ${props =>
        props.$playerOpen ? `${PLAYER_HEIGHT + 2 * gridSize}px` : `${2 * gridSize}px`};
    left: ${2 * gridSize}px;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 380px;
    z-index: 101;
`;
