import classNames from 'classnames';
import React from 'react';
import { FocusOn } from 'react-focus-on';
import { Portal } from 'react-portal';
import { isMobile } from '../../../helper/Browser';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import { ESCAPE_KEY_CODE } from '../../../model/page';
import { PopupWrapper } from './Popup.styled';

type Props = {
    isOpen: boolean;
    onClosed: (e: Event | React.UIEvent | React.KeyboardEvent) => void;
    className?: string;
    closeOnScroll?: boolean;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    width: number;
    height: number;
    center?: boolean;
    position?: string;
    'aria-label'?: string;
    children: React.ReactNode;
};

class Popup extends React.Component<Props> {
    static defaultProps = {
        width: 0,
        height: 0,
        center: true,
        position: 'fixed',
        closeOnScroll: false,
    };

    frameRef: React.RefObject<HTMLDivElement> | undefined;

    popupRef: React.RefObject<HTMLDivElement> | undefined;

    constructor(props: Props) {
        super(props);
        this.popupRef = React.createRef();
        this.frameRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.closeOnScroll && this.frameRef?.current) {
            this.frameRef.current.addEventListener('wheel', this.onScroll);
        }

        window.addEventListener('keydown', this.shortcutEsc);
    }

    componentWillUnmount() {
        if (this.props.closeOnScroll && this.frameRef?.current) {
            this.frameRef?.current.removeEventListener('wheel', this.onScroll);
        }
        window.removeEventListener('keydown', this.shortcutEsc);
    }

    shortcutEsc = (event: KeyboardEvent) => {
        if (event && event.keyCode === ESCAPE_KEY_CODE) {
            this.props.onClosed && this.props.onClosed(event);
        }
    };

    onScroll = (event: MouseEvent) => {
        if (this.props.closeOnScroll && this.props.onClosed) {
            this.props.onClosed(event);
        }
    };

    render() {
        const {
            onClosed,
            isOpen,
            className,
            top,
            bottom,
            left,
            right,
            width,
            height,
            center,
            position,
            ...props
        } = this.props;
        const popupClasses = classNames('popup', className, { open: isOpen });

        let styles: Record<string, unknown>;
        if (center) {
            styles = {
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        } else {
            styles = { position };
            if (left) {
                styles.left = left;
            } else if (right) {
                styles.right = right;
            }
            if (top) {
                styles.top = top;
            } else if (bottom) {
                styles.bottom = bottom;
            }
        }
        if (width > 0) {
            styles.width = width;
        }
        if (height > 0) {
            styles.height = height;
        }

        // This is a workaround to make popups responsive on mobile devices.
        // For more context, see https://pocketcastsp2.wordpress.com/2025/02/20/project-thread-free-web-player-app-clips-and-sharing-on-ios-android/#comment-6852
        if (isMobile()) {
            styles = {
                position: 'absolute',
                inset: '0',
                width: '100%',
                transform: 'none',
                margin: '17vw 0',
            };
        }

        return (
            <Portal node={document && document.getElementById('modal-root')}>
                <FocusOn scrollLock={false} noIsolation>
                    <PopupWrapper {...props} className={popupClasses} ref={this.popupRef}>
                        <div
                            aria-label={this.props['aria-label']}
                            tabIndex={-1}
                            className="popup-window"
                            style={styles}
                        >
                            {this.props.children}
                        </div>
                        <div
                            className="popup-background"
                            onKeyPress={event => onKeyDownEnter(event, onClosed.bind(this))}
                            onClick={onClosed}
                            ref={this.frameRef}
                        />
                    </PopupWrapper>
                </FocusOn>
            </Portal>
        );
    }
}

export default Popup;
