import { Logger } from 'helper/Logger';
import { api } from 'services/api';
import * as firebase from 'services/firebase';
import * as tracks from 'services/tracks';
import { DEPLOYMENT_ENV, IS_PRERENDER } from 'settings';
import * as fromSettingsActions from '../actions/settings.actions';
import * as fromTracksActions from '../actions/tracks.actions';
import { registerMiddleware } from '../api';
import { hasPaidSubscription, isAnalyticsOptOutEnabled } from '../reducers/selectors';

registerMiddleware('ANALYTICS_LOADED', async (action, store) => {
    const { kit } = window.a8cAnalytics;
    const state = store.getState();
    const { maybeFireTrackers } = action.payload;

    if (!kit) {
        return;
    }

    // If it's a prerendering process, we don't want to load analytics
    if (IS_PRERENDER) {
        return;
    }

    // If user has enabled Do Not Track then shutdown all third-party tracking
    if (kit.doNotTrack()) {
        return;
    }

    // If user has opted out of analytics then shutdown all third-party tracking
    const userHasSubscription = hasPaidSubscription(state);
    if (userHasSubscription) {
        // Settings are not available in the state yet, so we fetch them
        const settings = await api.fetchSettings();
        fromSettingsActions.Actions.importSettings(settings);

        if (isAnalyticsOptOutEnabled(state)) {
            Logger.log('User has opted out of analytics');
            return;
        }
    }

    // Don't send analytics in development/staging
    if (DEPLOYMENT_ENV !== 'production') {
        Logger.log('Analytics disabled in development/staging');
        return;
    }

    kit.addTracker('analytics', true, () => {
        kit.loadGtag('G-YD7DY74T46');

        gtag('config', 'G-NJWP83ZCCX');

        firebase.initialize();
        tracks.initialize();
    });

    maybeFireTrackers();
});

registerMiddleware('ANALYTICS_COOKIES_BANNER_SHOWN', async (action, store) => {
    store.dispatch(fromTracksActions.Actions.recordEvent('privacy_shown'));
});

registerMiddleware('ANALYTICS_ACCEPT_ALL_CLICKED', async (action, store) => {
    store.dispatch(fromTracksActions.Actions.recordEvent('analytics_opt_in'));
});

registerMiddleware('ANALYTICS_ACCEPT_SELECTION_CLICKED', async (action, store) => {
    const { analyticsOptIn } = action.payload;
    store.dispatch(
        fromTracksActions.Actions.recordEvent(
            analyticsOptIn ? 'analytics_opt_in' : 'analytics_opt_out',
        ),
    );
    store.dispatch(fromSettingsActions.Actions.savePrivacyAnalytics(!analyticsOptIn));
});
