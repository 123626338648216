import { Icon } from 'components/Icon';
import { TextWithIcon } from 'components/TextWithIcon';
import { ModalTypes } from 'helper/UiHelper';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { FullEpisode } from 'model/types';
import qs from 'query-string';
import React, { useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { getEpisodeActions } from '../../../../components/EpisodesTable/actions/episodeActions';
import { useEpisodeActions } from '../../../../components/EpisodesTable/useEpisodeActions';
import { useAnalyticsContext } from '../../../../context/AnalyticsContext';
import { cleanForFileName } from '../../../../helper/StringHelper';
import useTracks from '../../../../hooks/useTracks';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../../model/uploaded-files';
import * as fromModalActions from '../../../../redux/actions/modal.actions';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromTracksActions from '../../../../redux/actions/tracks.actions';
import { getPlayingEpisode, getPodcastByUuid } from '../../../../redux/reducers/selectors';
import FeatureLock from '../../../FeatureLock';
import DateText from '../../../format/DateText';
import DurationText from '../../../format/DurationText';
import { CalendarIcon, HourGlassIcon } from '../icons';
import {
    BookmarksButton,
    EpisodeActions,
    EpisodePopupToolbarContainer,
    PlayButtonContainer,
    PopupMenuBarContent,
} from './EpisodePopupToolbar.styled';
import { SvgImageButton } from './SvgImageButton';

type Props = {
    episode: FullEpisode;
    onClose?: () => void;
    isUploadedFile: boolean;
    className?: string;
    showPlayButton?: boolean;
};

function EpisodePopupToolbar({
    episode,
    isUploadedFile,
    onClose,
    className,
    showPlayButton,
}: Props) {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { discoverEpisodePlayEvent } = useAnalyticsContext();
    const location = useLocation();
    const bookmarks = episode.bookmarks || [];
    const isPlaying = useSelector(state => getPlayingEpisode(state)?.uuid === episode.uuid);
    const autoplayConfig = useSelector(state => state.episode?.autoplayConfig);
    const podcast = useSelector(state => getPodcastByUuid(state, episode.podcastUuid));
    const didShareSync = useRef(false);
    const { recordEvent } = useTracks();

    const inUpNext = useSelector(
        state =>
            episode &&
            state.upNext &&
            state.upNext.episodes &&
            !!state.upNext.episodes[episode.uuid],
    );

    const isEpisodeStarred = episode.starred;

    const onBookmarksClick = useCallback(() => {
        recordEvent('podcast_screen_bookmarks_count_tapped', {
            count: bookmarks.length,
        });
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                podcastUuid: episode.podcastUuid,
                episodeUuid: episode.uuid,
            }),
        );
        onClose?.();
    }, [episode.podcastUuid, episode.uuid, dispatch, onClose, bookmarks.length, recordEvent]);

    const onPlayClick = useCallback(() => {
        if (isPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'episode_detail' }));
            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                        option: 'pause',
                    }),
                );
            }
        } else {
            const queryParts = qs.parse(location.search);
            const seekTo = queryParts.t
                ? Array.isArray(queryParts.t)
                    ? parseInt(queryParts.t[0] as string, 10)
                    : parseInt(queryParts.t, 10)
                : undefined;
            // Prevent seeking past the last 5 seconds of the episode
            const maxSeekTo = episode.duration - 5;

            const { podcastUuid } = episode;
            discoverEpisodePlayEvent(podcastUuid);
            dispatch(
                fromPlayerActions.Actions.playEpisode(
                    episode.uuid,
                    podcastUuid,
                    {
                        eventSource: 'episode_detail',
                    },
                    {
                        autoplay: autoplayConfig,
                        seekTo:
                            seekTo !== null && seekTo !== undefined && !didShareSync.current
                                ? Math.min(seekTo, maxSeekTo)
                                : undefined,
                    },
                ),
            );

            // Don't seek more then once if the user pauses and then plays again
            if (seekTo && !didShareSync.current) {
                didShareSync.current = true;
            }

            if (isUploadedFile) {
                dispatch(
                    fromTracksActions.Actions.recordEvent('user_file_play_pause_button_tapped', {
                        option: 'play',
                    }),
                );
            }
        }
    }, [
        isPlaying,
        dispatch,
        isUploadedFile,
        episode,
        discoverEpisodePlayEvent,
        autoplayConfig,
        location.search,
    ]);

    const eventSource = isUploadedFile ? 'user_file' : 'episode_detail';
    const actions = useEpisodeActions(eventSource);

    const episodeActions = getEpisodeActions(true, true).filter(action =>
        action.isVisible({
            episodeUuid: episode.uuid,
            podcastUuid: episode.podcastUuid,
            title: episode.title,
            duration: episode.duration,
            url: episode.url,
            isDeleted: false,
            playingStatus: episode.playingStatus,
            isInUpNext: inUpNext,
            inHistory: false,
            isUploadedFile,
            isStarred: isEpisodeStarred,
            actions,
        }),
    );

    const PlayButton = React.useMemo(() => {
        if (!showPlayButton) {
            return null;
        }
        return (
            <PlayButtonContainer
                aria-label={isPlaying ? 'Pause' : 'Play'}
                aria-pressed={isPlaying}
                onClick={onPlayClick}
                className={isPlaying ? 'play' : 'pause'}
                onKeyUp={e => {
                    e.preventDefault();
                }}
            >
                <svg width="46" height="46" viewBox="0 0 46 46">
                    <use xlinkHref={isPlaying ? '#pause' : '#play'} />
                </svg>
            </PlayButtonContainer>
        );
    }, [isPlaying, onPlayClick]);

    const downloadFileName =
        episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID || !episode.title
            ? null // File name is instead set by downloadUploadedFile() or we depend on basic one set by the app
            : `${podcast ? `${cleanForFileName(podcast.title)}_` : ''}${cleanForFileName(
                  episode.title,
              )}`;

    return (
        <EpisodePopupToolbarContainer className={className}>
            {PlayButton}
            <PopupMenuBarContent>
                <TextWithIcon
                    icon={<CalendarIcon />}
                    text={<DateText date={episode.published} showToday={false} />}
                />

                {episode.duration > 0 && (
                    <TextWithIcon
                        icon={<HourGlassIcon />}
                        text={
                            <DurationText
                                durationSecs={Number(episode.duration)}
                                playedUpToSecs={episode.playedUpTo}
                                playingStatus={episode.playingStatus}
                            />
                        }
                    />
                )}

                <FeatureLock
                    requires="subscription"
                    title={formatMessage('feature-bookmarks-unavailable-title')}
                    description={formatMessage('feature-bookmarks-unavailable-description')}
                    source="episode_popup_bookmark"
                >
                    <BookmarksButton kind="text" onClick={onBookmarksClick}>
                        <TextWithIcon
                            icon={<Icon id="bookmark" size={20} />}
                            text={
                                bookmarks.length > 1
                                    ? formatMessage('bookmark-count-plural', {
                                          count: bookmarks.length,
                                      })
                                    : bookmarks.length === 1
                                      ? formatMessage('bookmark-count-singular')
                                      : formatMessage('bookmarks')
                            }
                        />
                    </BookmarksButton>
                </FeatureLock>

                <EpisodeActions>
                    {episodeActions.map(action => (
                        <FeatureLock
                            key={action.id}
                            title={action.featureLockTitle}
                            description={action.featureLockDescription}
                            source={action.featureLockSource}
                            enabled={action.featureLockSource !== undefined}
                        >
                            {action.id === 'download-file' ? (
                                <a
                                    href={episode.url}
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    tabIndex={0}
                                    data-filename={downloadFileName}
                                >
                                    <SvgImageButton
                                        svg={action.icon}
                                        title={formatMessage(action.label)}
                                        onClick={() => {
                                            action.onClick({
                                                episodeUuid: episode.uuid,
                                                podcastUuid: episode.podcastUuid,
                                                title: episode.title,
                                                duration: episode.duration,
                                                url: episode.url,
                                                isDeleted: false,
                                                playingStatus: episode.playingStatus,
                                                isInUpNext: inUpNext,
                                                inHistory: false,
                                                isUploadedFile,
                                                isStarred: isEpisodeStarred,
                                                actions,
                                            });
                                            if (action.id === 'delete' || action.id === 'edit') {
                                                onClose?.();
                                            }
                                        }}
                                        ariaAttributes={{
                                            'aria-label': formatMessage(action.label),
                                            'aria-pressed': undefined,
                                        }}
                                    />
                                </a>
                            ) : (
                                <SvgImageButton
                                    svg={action.icon}
                                    title={formatMessage(action.label)}
                                    color={action.id === 'delete' ? 'red' : undefined}
                                    onClick={() => {
                                        action.onClick({
                                            episodeUuid: episode.uuid,
                                            podcastUuid: episode.podcastUuid,
                                            title: episode.title,
                                            duration: episode.duration,
                                            url: episode.url,
                                            isDeleted: false,
                                            playingStatus: episode.playingStatus,
                                            isInUpNext: inUpNext,
                                            inHistory: false,
                                            isUploadedFile,
                                            isStarred: isEpisodeStarred,
                                            actions,
                                        });
                                        if (action.id === 'delete' || action.id === 'edit') {
                                            onClose?.();
                                        }
                                    }}
                                    ariaAttributes={{
                                        'aria-label': formatMessage(action.label),
                                        'aria-pressed':
                                            action.id === 'star' || action.id === 'unstar'
                                                ? isEpisodeStarred
                                                : undefined,
                                    }}
                                />
                            )}
                        </FeatureLock>
                    ))}
                </EpisodeActions>
            </PopupMenuBarContent>
        </EpisodePopupToolbarContainer>
    );
}

export default EpisodePopupToolbar;
