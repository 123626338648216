import { isWindowsApp } from 'helper/Browser';
import { MOBILE_WIDTH, PLAYER_HEIGHT } from 'model/page';
import { DARK_THEME } from 'model/theme/theme-dark';
import { rgba } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';
import { IconButton } from './AddBookmarkButton/AddBookmarkButton.styled';

export const SLIDING_MODAL_ANIMATION_TIME_IN_MS = 200;

export const PlayerControlsGlobalStyles = createGlobalStyle`
    /* 
    Overriding global-styles.js: Note that for firefox only - for some reason the color 
    contrast does not get detected properly by firefox and the outline is hidden for
    buttons in the playback controls. So we need to manually set the outline 
    color here for accessibility
    */

    body:hover {
        .video-large {
            opacity: 1;
        }
    }
`;

export const PlayerActions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: ${MOBILE_WIDTH - 200}px) {
        gap: 8px;
    }

    ${isWindowsApp() &&
    css`
        & > * {
            margin-right: 24px;
        }

        & > *:last-child {
            margin-right: 0;
        }

        & > *:first-child {
            margin-top: 2px;
            margin-right: -10px;
        }
    `}
`;

export const FullPlayerTopIcons = styled.div`
    display: flex;
    width: 100%;
    color: ${props => props.theme.tokens['primary-icon-02']};
    justify-content: space-between;
    margin: 0 -16px;
`;

export const ChevronDownButton = styled(IconButton)`
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }
`;

export const Shelf = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    background-color: ${props => props.theme.tokens['contrast-04']};

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    ${IconButton} {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: ${props => props.theme.tokens['primary-icon-02']};
    }
`;

export const PlayerControlsWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 -2px 2px 0 ${rgba(DARK_THEME.tokens['primary-ui-04'], 0.25)};
    background-color: black;

    transition: background-color 0.2s ease;
    ${props =>
        props.$isUpNextOpen &&
        `
            background-color: ${DARK_THEME.tokens['primary-ui-04']};
            .color-gradient, .player-gradient {
                opacity: 0 !important;
            }
        `}

    @media (prefers-reduced-motion: no-preference) {
        transition: transform ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms
            cubic-bezier(0.25, 0.09, 0.3, 1);
    }

    &.full-player {
        transform: translateY(0);
    }

    &:not(.full-player) {
        transform: translateY(calc(100% - ${PLAYER_HEIGHT}px));
    }

    ${props =>
        props.$isExpanded &&
        `
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 400;
            height: 100%;

            ${FullPlayerTopIcons} {
                display: flex;
                width: 100%;
            }

            .full-player-episode-artwork {
                flex: 1 0 auto;
                order: 1;
                width: min(70%, 400px);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .player-image {
                display: none;
            }

            .controls-left {
                flex: 0 0 auto !important;
                order: 3;
                margin-bottom: 40px;
                scale: 1.3;
            }

            .controls-center {
                display: block !important;
                width: 100%;
                flex: 0 0 auto !important;
                order: 2;
                padding: 0 !important;
                margin-bottom: 0 !important;
            }

            .controls-right {
                display: none !important;
            }

            ${Shelf} {
                order: 4;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 6px 0;
            }
        `}

    .color-gradient {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: radial-gradient(
            closest-corner,
            currentColor 0,
            currentColor 30%,
            transparent 100%
        );
        opacity: 0.2;
        transform: scale(1.4);
    }
    .player-gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: ${PLAYER_HEIGHT}px;

        // This blur is huge and expensive, so to make it run fast we need to put it on its own composite rendering layer
        // using the "translateZ(0)" trick. Without that, any rendering change to the page will also cause the gradient
        // to re-calc the blur, eating CPU faster than a can of Pringles.
        filter: blur(150px);
        transform: translateZ(0); // Enable GPU hardware acceleration

        opacity: 0.8;
        @-moz-document url-prefix() {
            opacity: 0.4;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        transition: opacity 0.2s ease;

        @supports (-ms-ime-align: auto) {
            opacity: 0.2;
            filter: none;
            transform: none;
            perspective: none;
        }
    }
    .player-gradient-loader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }

    &.video-large {
        .color-gradient,
        .player-gradient {
            display: none;
        }
    }
    .audio {
        position: absolute;
        top: 0;
        left: 0;
    }
    .player-controls-background {
        display: none;

        @media (max-width: ${MOBILE_WIDTH}px) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }
    }

    .controls {
        position: relative;
        height: ${props => (props.$isExpanded ? '100%' : PLAYER_HEIGHT + 'px')};
        width: 100%;
        display: flex;
        align-items: center;

        .controls-left {
            flex: 0 0 333px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 34px;
            height: ${PLAYER_HEIGHT}px;
            .player-image {
                position: relative;
                width: 65px;
                .video-collapse-button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                margin-right: 30px;
                &.grow-on-hover {
                    transition: transform 100ms ease-out;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .controls-center {
            min-width: 150px;
            flex: 10 1 auto;
            align-self: flex-start;
            padding-top: 22px;
            text-align: center;
            position: relative;
            font-size: 17.28px;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: -0.2px;
            line-height: 22px;
            height: ${PLAYER_HEIGHT}px;
            margin-bottom: 22px;
            overflow: hidden;
            .episode {
                overflow: hidden;
                height: 22px;
                margin-bottom: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                .episode-title {
                    cursor: pointer;
                    color: inherit;
                    user-select: none;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:focus-visible {
                        outline-width: 1px;
                        outline-offset: -1px;
                        padding-inline: 5px;
                        margin-inline: -5px;
                    }
                }
            }
            .podcast-title-date,
            .podcast-title {
                opacity: 0.5;
                font-size: 14.4px;
                font-weight: 500;
                line-height: 14px;
                color: #ffffff;
                letter-spacing: -0.18px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                user-select: none;
            }

            .podcast-title {
                text-decoration: none;
                opacity: 1;
                &:hover {
                    text-decoration: underline;
                }
                &:focus-visible {
                    outline: none;
                    text-decoration: underline;
                }
            }

            .seek-bar {
                position: absolute;
                top: 72px;
                left: 0;
            }
            .chapter-previous,
            .chapter-next {
                position: absolute;
                top: 39px;
                width: 11px;
                height: 12px;
                opacity: 0.5;
                &.enabled {
                    opacity: 1;
                    cursor: pointer;
                }
            }
            .chapter-previous {
                left: 0;
                transform: scaleX(-1);
                background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2212%22%20viewBox%3D%220%200%2011%2012%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M4.293%2010.293c-.39.39-.39%201.024%200%201.414.39.39%201.024.39%201.414%200l5-5c.39-.39.39-1.024%200-1.414l-5-5c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414L8.586%206l-4.293%204.293z%22%2F%3E%3C%2Fsvg%3E');
            }
            .chapter-next {
                right: 0;
                background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2212%22%20viewBox%3D%220%200%2011%2012%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M4.293%2010.293c-.39.39-.39%201.024%200%201.414.39.39%201.024.39%201.414%200l5-5c.39-.39.39-1.024%200-1.414l-5-5c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414L8.586%206l-4.293%204.293z%22%2F%3E%3C%2Fsvg%3E');
            }
            .error-title {
                display: inline-block;
                font-size: 14px;
                padding-top: 34px;
                color: #ffc12e;

                a {
                    color: #03a9f4;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        color: #50d0f1;
                    }

                    transition: all 0.1s ease;
                }
            }
        }
        .controls-right {
            flex: 0 0 310px;
            &.large {
                flex: 0 0 350px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 30px;
            height: ${PLAYER_HEIGHT}px;
            .chrome-cast-button {
                button {
                    margin-top: 3px;
                }
            }
            .speed-control {
                margin-top: 1px;
                z-index: 1;
            }
        }

        .mobile-episode-artwork {
            display: none;
        }

        ${props =>
            props.$isExpanded &&
            `
                flex-direction: column;
                justify-content: center;
                padding: 16px;

                .full-player-top-icons {
                    display: flex;
                    width: 100%;
                    color: ${props => props.theme.tokens['primary-icon-02']};
                    svg {
                        color: ${props => props.theme.tokens['primary-icon-02']};
                    }
                }

                .mobile-episode-artwork {
                    flex: 1 0 auto;
                    order: 1;
                }

                .controls-left {
                    flex: 0 0 auto;
                    order: 3;
                }

                .controls-center {
                    display: block;
                    width: 100%;
                    flex: 0 0 auto;
                    order: 2;
                    padding: 0;
                    margin-bottom: 0;
                }

                .controls-right {
                    display: none;
                }
            `}

        @media (max-width: 960px) {
            .controls-left,
            .controls-right {
                padding: 0 20px;
                flex: 0;
            }
            .player-image {
                width: 48px !important;
                margin-right: 20px !important;
            }
            .play_pause_button {
                width: 80px;
            }
            .skip-back-button,
            .skip-forward-button {
                width: 35px;
                height: 35px;
                background-size: 35px 35px;
                p {
                    font-size: 11px;
                    width: 35px;
                    height: 30px;
                    line-height: 30px;
                }
            }
            .speed-control {
                margin-right: 15px !important;
            }
            .volume-slider {
                margin-right: 5px !important;
            }
        }

        @media (max-width: ${MOBILE_WIDTH}px) {
            .controls-right {
                flex: 0;

                &.large {
                    flex: 0;
                }
            }

            .speed-control {
                margin-right: 0 !important;
            }
        }

        @media (max-width: ${MOBILE_WIDTH - 100}px) {
            .speed-control {
                display: none;
            }
        }

        @media (max-width: ${MOBILE_WIDTH - 200}px) {
            .controls-center {
                display: none;
            }

            .controls-left {
                flex: 1;
                justify-content: flex-start;

                .player-image {
                    display: none;
                }
            }

            .controls-right {
                &.large {
                    padding: 0 16px 0 10px;
                }
            }

            .mobile-episode-artwork {
                display: flex;
                margin-left: 16px;

                .player-image {
                    margin: 0 !important;
                }
            }

            .skip-back-button {
                margin-left: auto;
            }

            .skip-forward-button {
                margin-right: auto;
            }

            .skip-back-button,
            .skip-forward-button {
                width: 50px;
                height: 50px;
                background-size: 50px 50px;
                p {
                    font-size: 13px;
                    width: 50px;
                }
            }

            .play_pause_button {
                width: 90px;

                svg {
                    scale: 1.4;
                }
            }
        }
    }
    &.video-small {
        .controls {
            .controls-left {
                .player-image {
                    opacity: 0;
                }
            }
        }
    }
`;

export const PlayerBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

export const MoreActionsMenuWrapper = styled.div`
    z-index: 1;

    @media (max-width: ${MOBILE_WIDTH - 200}px) {
        display: none;
    }
`;
