import styled from 'styled-components';
import { coolGrey50 } from '../../../styles';
import { PopupMenuVariant } from './PopupMenu';
interface PopupMenuWrapperProps {
    $popupVariant?: PopupMenuVariant;
}

export const PopupMenuWrapper = styled.div<PopupMenuWrapperProps>`
    cursor: pointer;
    height: 30px;
    width: 30px;
    ${props =>
        props.$popupVariant === 'bordered' &&
        `
        width: auto;
        height: auto;
    `}

    .symbol {
        height: 30px;
        width: 30px;
    }
    .popup {
        .separator {
            position: relative;
            width: 100%;
            top: 0;
        }
    }
    button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        ${props =>
            props.$popupVariant === 'bordered' &&
            `
        padding: 10px;
        border: 2px solid ${coolGrey50};
        border-radius: 8px;
        width: auto;
        height: auto;

        svg {
            color: ${props.theme.tokens['primary-icon-03']};
        }
    `}
    }
`;
