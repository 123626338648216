import { BookmarkList } from 'components/BookmarkList';
import { Icon } from 'components/Icon';
import { SortOptions } from 'components/SortOptions';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import { BookmarksSortOrder } from 'model/types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LoaderRound, LoaderSquare } from '../../components/index';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import { fetchBookmarks } from '../../redux/actions/bookmarks.actions';
import * as fromSettingsActions from '../../redux/actions/settings.actions';
import {
    getBookmarks,
    isBookmarksFetching,
} from '../../redux/reducers/selectors/bookmarks.selectors';
import { getBookmarksSortOrder } from '../../redux/reducers/selectors/settings.selectors';
import { SearchBox, Title } from './BookmarksPage.styled';

const BookmarksPage = () => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState<string>('');

    const sortOrder = useSelector(getBookmarksSortOrder);
    const bookmarks = useSelector(getBookmarks);
    const isFetching = useSelector(isBookmarksFetching);
    const isLoading = !bookmarks;

    useScrollRestoration(!isFetching);

    useEffect(() => {
        if (!bookmarks && !isFetching) {
            dispatch(fetchBookmarks());
        }
    }, [dispatch, bookmarks, isFetching]);

    const onOrderChanged = (value: number) => {
        dispatch(fromSettingsActions.Actions.saveBookmarksSortOrder(value));
    };

    const clearSearch = () => setSearchTerm('');
    const searchInputChanged = ({ target: { value: newTerm } }: { target: { value: string } }) =>
        setSearchTerm(newTerm);
    const renderSearchIcon = () => {
        if (isFetching) {
            return (
                <div className="loading-icon-wrapper">
                    <LoaderRound />
                </div>
            );
        }

        if (searchTerm) {
            return (
                <button id="clear-search-bar" onClick={clearSearch}>
                    <Icon id="cancel" />
                </button>
            );
        }

        return (
            <div className="icon-search-bookmarks">
                <Icon id="search" />
            </div>
        );
    };

    return (
        <div>
            <TrackOnMount event="bookmarks_shown" />
            <TrackOnUnmount event="bookmarks_dismissed" />
            <Helmet>
                <title>{formatMessage('bookmarks')}</title>
            </Helmet>

            <Title>{formatMessage('bookmarks')}</Title>
            <SearchBox>
                {renderSearchIcon()}
                <input
                    placeholder={
                        isLoading
                            ? formatMessage('loading-bookmarks')
                            : formatMessage('search-bookmarks')
                    }
                    value={searchTerm}
                    onChange={searchInputChanged}
                />
                <SortOptions
                    id="bookmarks-sort-options"
                    onSelect={(sortId: number) => onOrderChanged(sortId)}
                    selectedOption={sortOrder}
                    options={[
                        {
                            id: BookmarksSortOrder.NEWEST_TO_OLDEST,
                            label: formatMessage('newest-to-oldest'),
                        },
                        {
                            id: BookmarksSortOrder.OLDEST_TO_NEWEST,
                            label: formatMessage('oldest-to-newest'),
                        },
                        {
                            id: BookmarksSortOrder.PODCAST_AND_EPISODE,
                            label: formatMessage('podcast-and-episode'),
                        },
                    ]}
                />
            </SearchBox>

            {isLoading && <LoaderSquare />}
            {!isLoading && (
                <BookmarkList
                    eventSource="bookmarks_page"
                    sort={sortOrder}
                    searchTerm={searchTerm}
                    inlineActions
                />
            )}
        </div>
    );
};

export default BookmarksPage;
