import styled from 'styled-components';
import { Typography } from '../../../styles';

export const DiscoverCategoriesWrapper = styled.section`
    display: flex;
    flex-direction: column;
`;

export const CategoryItemWrapper = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
    grid-gap: 9px;
    justify-content: space-between;

    list-item-style: none;
    margin: 0;
    padding: 0;
`;

export const SectionTitle = styled.h2`
    ${Typography.Desktop.H20}
    padding-bottom: 34px;
`;

export const StyledSvg = styled.svg``;

export const CategoryListItem = styled.li`
    list-style-type: none;
    height: 160px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: ${props => props.theme.tokens['primary-ui-02']};
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    box-shadow:
        0px 2px 6px rgba(0, 0, 0, 0.06),
        0px 1px 2px rgba(0, 0, 0, 0.04);

    border-radius: 8px;
`;

export const CategoryButton = styled.button.attrs({
    type: 'button',
})<{ token: string }>`
    cursor: pointer;
    height: 100%;
    width: 100%;

    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
    }

    svg {
        padding: 0;
        margin: 0;
        color: ${props => props.token};
    }
`;

export const CategoryItemTitle = styled.p`
    ${Typography.Desktop['H65-B']}
    margin: 0;
    margin-top: 18px;
    color: ${props => props.theme.tokens['primary-text-01']};
`;
