import { getCurrentMatchPath } from 'components/RouteMatchMonitor';
import { isBetaEnabled } from 'helper/BetaHelper';
import { getElectronAppVersion, getPlatformName } from 'helper/Browser';
import { PaymentFrequency, SubscriptionPlatform, TracksProperties } from 'model/types';
import { select, takeEvery } from 'redux-saga/effects';
import { recordRawEvent } from 'services/tracks';
import { DEPLOYMENT_ENV } from 'settings';
import { THEME } from '../../model/theme';
import * as fromTracksActions from '../actions/tracks.actions';
import { RootState } from '../reducers';
import {
    getDisplayedTheme,
    getLanguage,
    getSubscriptionFrequency,
    getSubscriptionPlatform,
    getSubscriptionTier,
    getTheme,
    hasLifetimePlus,
    hasPaidSubscription,
} from '../reducers/selectors';
import { userIsLoggedIn } from '../reducers/selectors/user.selectors';

const getSubscriptionPlatformName = (platform: SubscriptionPlatform) => {
    if (!platform) {
        return 'none';
    }
    if (platform === SubscriptionPlatform.Paddle) {
        return 'web';
    }
    return SubscriptionPlatform[platform].toLowerCase();
};

const tracksThemeName = (theme: THEME) => {
    switch (theme) {
        case THEME.light:
            return 'default_light';
        case THEME.dark:
            return 'default_dark';
        default:
            return 'unknown';
    }
};

function* tracksRecordEvent(action: ReturnType<typeof fromTracksActions.Actions.recordEvent>) {
    const state: RootState = yield select((state: RootState) => state);
    const language = getLanguage(state);
    const userHasSubscription = hasPaidSubscription(state);
    const userHasLifetimePlus = hasLifetimePlus(state);
    const subscriptionPlatform = getSubscriptionPlatform(state);
    const subscriptionFrequency = getSubscriptionFrequency(state);
    const themeId = getTheme(state);
    const isLoggedIn = userIsLoggedIn(state);

    // Attach some properties that we want bundled with every event
    const globalProperties: TracksProperties = {
        user_lang: language,
        web_platform: getPlatformName(),
        web_environment: DEPLOYMENT_ENV,
        electron_app_version: getElectronAppVersion(),
        plus_has_subscription: userHasSubscription,
        plus_has_lifetime: userHasLifetimePlus,
        plus_subscription_platform: getSubscriptionPlatformName(subscriptionPlatform),
        plus_subscription_frequency: PaymentFrequency[subscriptionFrequency] ?? 'none',
        route: getCurrentMatchPath(),
        plus_subscription_tier: getSubscriptionTier(state)?.toLowerCase() ?? 'none',
        beta_features_enabled: isBetaEnabled(),
        theme_selected: tracksThemeName(getDisplayedTheme(themeId)),
        theme_use_system_settings: themeId === THEME.system,
        user_is_logged_in: isLoggedIn,
    };

    // NOTE: We don't currently have the user's UUID in Redux state. It's returned from the /user/login API
    // call, but we discard it. There are also other paths to authenticate (OutsideAppAuthenticatorPage),
    // where adding UUID may involve desktop app changes. So this will be a small project.
    //
    // For now, Tracks will record `anon` IDs by default (which is fine).
    //
    // if (uuid) {
    //     // eslint-disable-next-line no-underscore-dangle
    //     globalProperties._ut = 'pocketcasts:user_id';
    //     // eslint-disable-next-line no-underscore-dangle
    //     globalProperties._ui = uuid;
    // }
    recordRawEvent(`pcweb_${action.payload.name}`, {
        ...action.payload.properties,
        ...globalProperties,
    });

    if (DEPLOYMENT_ENV !== 'production') {
        // Uncomment next line for logging in Desktop Apps (view in Settings->Help->Send feedback->info icon)
        // Logger.log(`TRACKS:  ${action.payload.name} ${JSON.stringify(action.payload.properties)}`);
        console.info('TRACKS:', action.payload.name, action.payload.properties, {
            global: globalProperties,
        });
    }
}

export function* watchTracksRecordEvent() {
    yield takeEvery(fromTracksActions.ActionTypes.TRACKS_RECORD_EVENT, tracksRecordEvent);
}
