import { LoaderSquare } from 'components';
import { SplitPlanSelector } from 'components/PlanSelector';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import { usePaddleProducts } from 'hooks/usePaddleProducts';
import { PaddlePlan } from 'model/types';
import { default as React, ReactNode } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getDefaultTracksSource } from '../../redux/reducers/selectors';
import { gridSize } from '../../styles';

const StyledChooseYourPlan = styled.div`
    gap: ${4 * gridSize}px;
    width: 100%;
    max-width: 920px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    @media (min-width: 768px) {
        justify-content: flex-start;
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledHeading = styled.h1`
    max-width: 460px;
    margin: 0;
    text-align: center;
`;

type ChooseYourPlanProps = {
    title?: ReactNode;
    plusButtonTitle?: ReactNode;
    onSubscribe: (plan: PaddlePlan) => void;
};

function ChooseYourPlan({ title, onSubscribe }: ChooseYourPlanProps) {
    const formatMessage = useFormatMessage();
    const products = usePaddleProducts();

    const planNames: PaddlePlan[] = [
        'plus-monthly',
        'plus-yearly',
        'patron-monthly',
        'patron-yearly',
    ];
    const hasProducts = planNames.every(plan => products[plan]);

    const source = useSelector(getDefaultTracksSource);

    if (!hasProducts) {
        return <LoaderSquare />;
    }

    return (
        <StyledChooseYourPlan>
            <TrackOnMount event="select_payment_frequency_shown" properties={{ source }} />
            <TrackOnUnmount event="select_payment_frequency_dismissed" properties={{ source }} />
            <HeadingContainer>
                <StyledHeading>{title ?? formatMessage('everything-you-love')}</StyledHeading>
            </HeadingContainer>
            <SplitPlanSelector products={products} onSubscribe={onSubscribe} />
        </StyledChooseYourPlan>
    );
}

export default ChooseYourPlan;
