import { SubscriptionTier } from 'model/types';
import styled from 'styled-components';
import { patronColor, plusColor } from 'styles/colors';
import PlanFeatureList from '../../../components/PlanFeatureList';
import { Typography, coolGrey60, gridSize, orange } from '../../../styles';

export const ProfileInformationWrapper = styled.div`
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

export const ProfileInformationBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
`;

export const PictureBox = styled.div<{ $tier?: SubscriptionTier }>`
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => {
        switch (props.$tier) {
            case 'Patron':
                return patronColor;
            case 'Plus':
                return plusColor;
            default:
                return props.theme.tokens['primary-ui-03'];
        }
    }};
    border-radius: 4px 4px 0px 0px;
    padding: 30px 0 18px;
`;

export const GravatarWrapper = styled.div<{ $tier?: SubscriptionTier }>`
    height: 120px;
    width: 120px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;

export const NonSubscriberSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 24px;
    gap: 16px;
`;

export const UnlockFeaturesWrapper = styled.span`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const ProfileInformationPlanFeatureList = styled(PlanFeatureList)`
    font-size: 0.875rem;
    font-weight: 500;
    gap: 10px;
    min-height: auto;
`;

export const EmailWrapper = styled.span<{ $tier?: SubscriptionTier }>`
    ${Typography.Desktop['H70-B']};
    color: ${props => {
        if (props.$tier) {
            return props.$tier === 'Patron' ? '#fff' : '#000';
        }
        return props.theme.tokens['player-contrast-01'];
    }};
    text-align: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    display: inline-block;
    width: 90%;
`;

export const DetailsBox = styled.div`
    width: 320px;
    padding-left: ${2 * gridSize}px;
    padding-right: ${2 * gridSize}px;
`;

export const LogoWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ${gridSize}px;
    padding-top: ${2 * gridSize}px;
    padding-bottom: ${2 * gridSize}px;
    transition: transform 75ms ease-out;
    &:hover {
        transform: scale(1.03);
    }
`;

export const FreeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BulletWrapper = styled.span`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const CombinedSubscriptionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${2 * gridSize}px;
`;

export const PriceWrapper = styled.p`
    ${Typography.Desktop['H70-B']};
    color: ${coolGrey60};
`;

export const PlatformWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const CombinedDateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${2 * gridSize}px;
    flex-wrap: wrap;
`;

export const NextBillingDateWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${orange};
`;

export const DateWrapper = styled.p`
    ${Typography.Desktop['H70-B']};
    color: ${orange};
`;

export const FirstPaymentDateWrapper = styled.p`
    ${Typography.Desktop.H70};
    color: ${coolGrey60};
`;

export const PlanBadge = styled.div<{ $tier?: SubscriptionTier }>`
    background: ${props => (props.$tier === 'Patron' ? patronColor : 'transparent')};
    color: ${props => (props.$tier === 'Patron' ? '#fff' : props.theme.tokens['primary-text-01'])};
    font-weight: 600;
    padding: ${1 * gridSize}px ${1.75 * gridSize}px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    svg {
        margin-right: ${0.75 * gridSize}px;
        color: ${props => (props.$tier === 'Patron' ? '#fff' : plusColor)};
    }
`;
