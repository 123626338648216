import { Icon } from 'components/Icon';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { useDispatch } from '../../../hooks/react-redux-typed';
import * as fromHistoryActions from '../../../redux/actions/history.actions';
import { Button } from '../../Button';
import { ClearHistoryPopupWrapper } from './ClearHistoryPopup.styled';

function ClearHistoryPopup() {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const onCancel = () => dispatch(fromHistoryActions.Actions.dismissClearHistoryConfirmation());

    const onConfirm = () => {
        dispatch(fromHistoryActions.Actions.clearHistory());
        dispatch(fromHistoryActions.Actions.dismissClearHistoryConfirmation());
    };

    return (
        <ClearHistoryPopupWrapper onClose={onCancel}>
            <TrackOnMount event="listening_history_clear_confirmation_shown" />
            <TrackOnUnmount event="listening_history_clear_confirmation_dismissed" />
            <div className="dialog-content">
                <div className="icon-wrapper">
                    <Icon id="history" />
                </div>
                <h2>{formatMessage('clear-history-question')}</h2>
                <div className="body-text">{formatMessage('clear-history-description')}</div>
                <div className="buttons">
                    <Button kind="secondary" width={163} onClick={onCancel}>
                        {formatMessage('cancel')}
                    </Button>
                    <div style={{ width: '16px' }} />
                    <Button width={163} onClick={onConfirm}>
                        {formatMessage('confirm')}
                    </Button>
                </div>
            </div>
        </ClearHistoryPopupWrapper>
    );
}

export default ClearHistoryPopup;
