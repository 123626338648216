import { Icon } from 'components/Icon';
import { ModalMonitor } from 'components/ModalMonitor';
import { SearchResults } from 'components/SearchResults';
import { SEARCH_RESULT_ITEM_HEIGHT } from 'components/SearchResults/SearchResults.styled';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { isUrl } from 'helper/StringHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import useResize from 'hooks/useResize';
import useTracks from 'hooks/useTracks';
import key from 'keymaster';
import { PLAYER_HEIGHT } from 'model/page';
import { SearchHistoryItem } from 'model/types';
import React, { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from '../../../hooks/react-redux-typed';
import * as fromSearchActions from '../../../redux/actions/search.actions';
import { RootState } from '../../../redux/reducers';
import {
    getMatchingSubscriptions,
    getSearch,
    getSearchHistory,
    getSearchResponse,
} from '../../../redux/reducers/selectors/search.selectors';
import urls from '../../../urls';
import {
    AssistiveText,
    CancelButton,
    SearchContainer,
    SearchIconWrapper,
    SearchResultsWrapper,
    SearchWidgetWrapper,
} from './SearchBar.styled';

const getItemEventProperties = (item: SearchHistoryItem) => {
    let type = 'search_term';
    let uuid;
    if ('podcast' in item) {
        type = 'podcast';
        uuid = item.podcast.uuid;
    } else if ('episode' in item) {
        type = 'episode';
        uuid = item.episode.uuid;
    } else if ('folder' in item) {
        type = 'folder';
        uuid = item.folder.uuid;
    }
    return { uuid, type };
};

interface SearchBarProps {
    onSearchVisibilityChange?: (show: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchVisibilityChange }) => {
    const formatMessage = useFormatMessage();
    const { recordEvent, recordSearchResultTapped } = useTracks();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [liveText, setLiveText] = useState('');
    const location = useLocation();

    const wrapperRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { windowHeight } = useResize();

    const { isSearching, term } = useSelector(getSearch);
    const searchHistory = useSelector(getSearchHistory);
    const searchResponse = useSelector((state: RootState) => getSearchResponse(state, term));
    const matchingSubscriptions = useSelector((state: RootState) =>
        getMatchingSubscriptions(state, term),
    );

    const setFocus = (event?: KeyboardEvent) => {
        event?.stopPropagation();
        event?.preventDefault();
        inputRef.current?.focus();
    };

    // We want the search bar to show as expanded when it's focused
    const isExpanded = isFocused;

    useEffect(() => {
        key('s', setFocus);
        return () => {
            resetSearch();
            key.unbind('s');
        };
    }, []);

    useEffect(() => {
        if (location.pathname !== '/search') {
            dispatch(fromSearchActions.Actions.setSearchTerm(''));
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        // Live text announcements, based on search status and results found
        if (!isExpanded) {
            setLiveText('');
            return;
        }

        if (isSearching) {
            setLiveText(formatMessage('searching'));
            return;
        }

        if (!term) {
            setLiveText('');
            return;
        }

        const count =
            matchingSubscriptions.length +
            (searchResponse?.podcasts?.length ?? 0) +
            (searchResponse?.episodes?.length ?? 0);

        if (count === 1) {
            setLiveText(formatMessage('results-found-singular'));
        } else if (count > 1) {
            setLiveText(formatMessage('results-found-plural', { count }));
        } else if (isUrl(term)) {
            setLiveText(formatMessage('press-enter-to-search-url'));
        } else {
            setLiveText(formatMessage('find-podcasts-empty'));
        }
    }, [isExpanded, isSearching, term]);

    const handleBlur: FocusEventHandler = e => {
        if (!(e.relatedTarget instanceof Node) || wrapperRef.current?.contains(e.relatedTarget)) {
            return;
        }
        setIsFocused(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(fromSearchActions.Actions.setSearchTerm(event.target.value));
    };

    const handleSearchIconClick = () => {
        setShowMobileSearch(true);
        onSearchVisibilityChange?.(true);
    };

    useEffect(() => {
        if (showMobileSearch && inputRef.current) {
            setFocus();
        }
    }, [showMobileSearch]);

    const closeSearch = () => {
        inputRef.current?.blur();
        setIsFocused(false);
        setShowMobileSearch(false);
        onSearchVisibilityChange?.(false);
    };

    const resetSearch = () => {
        dispatch(fromSearchActions.Actions.setSearchTerm(''));
        closeSearch();
    };

    const selectNextItem = () => {
        const results: HTMLAnchorElement[] = Array.from(
            document.querySelectorAll('a.search-result-item'),
        );

        const selectedIndex = results.findIndex(item => item === document.activeElement);
        if (results.length > selectedIndex + 1) {
            results[selectedIndex + 1].focus();
        }
    };

    const selectPreviousItem = () => {
        const results: HTMLAnchorElement[] = Array.from(
            document.querySelectorAll('a.search-result-item'),
        );
        const selectedIndex = results.findIndex(item => item === document.activeElement);
        if (selectedIndex > 0) {
            results[selectedIndex - 1].focus();
        } else {
            inputRef.current?.focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 && inputRef.current === document.activeElement) {
            // Enter key inside input field
            if (isUrl(term)) {
                event.preventDefault();
                dispatch(fromSearchActions.Actions.addPodcastByUrl(term));
            } else if (term) {
                navigate(urls.searchPath(term));
                closeSearch();
            }
        } else if (event.keyCode === 40) {
            // Down Arrow
            event.preventDefault();
            selectNextItem();
        } else if (event.keyCode === 38) {
            // Up Arrow
            event.preventDefault();
            selectPreviousItem();
        } else if (event.keyCode === 27) {
            // Escape
            resetSearch();
        }
    };

    const handleItemClick = (item: SearchHistoryItem) => {
        dispatch(fromSearchActions.Actions.addHistoryItem({ term }));
        dispatch(fromSearchActions.Actions.addHistoryItem(item));
        recordSearchResultTapped(item);
    };

    const handleHistoryItemClick = (item: SearchHistoryItem) => {
        recordEvent('search_history_item_tapped', getItemEventProperties(item));
    };

    const handleClearHistoryItem = (item: SearchHistoryItem) => {
        dispatch(fromSearchActions.Actions.removeHistoryItem(item));
        recordEvent('search_history_item_delete_button_tapped', getItemEventProperties(item));
    };

    // Adjust how many results show based on window height, accounting for player height (bottom of screen) and
    // a semi-magic number for how far the results pane is from the top of the screen
    const maxResults = Math.floor((windowHeight - PLAYER_HEIGHT - 210) / SEARCH_RESULT_ITEM_HEIGHT);

    return (
        <>
            <SearchIconWrapper
                $isVisible={!showMobileSearch}
                onClick={handleSearchIconClick}
                aria-label={formatMessage('search')}
            >
                <Icon id="search" viewBox="3 3 17 17" width={20} height={20} />
            </SearchIconWrapper>

            <SearchContainer $showMobileSearch={showMobileSearch}>
                <FocusOn
                    enabled={isExpanded}
                    scrollLock={false}
                    onClickOutside={closeSearch}
                    returnFocus={false}
                    style={{ width: '100%' }}
                >
                    <ModalMonitor onCloseModals={closeSearch} />
                    <AssistiveText>
                        <p id="search-bar-input-instructions">
                            {!term && searchHistory.length > 0
                                ? formatMessage('search-history-navigation-instructions')
                                : ''}
                        </p>
                        <p aria-live="polite">
                            <span key={liveText}>{liveText}</span>
                        </p>
                    </AssistiveText>
                    <SearchWidgetWrapper
                        $isExpanded={isExpanded}
                        role="search"
                        ref={wrapperRef}
                        onSubmit={e => e.preventDefault()}
                    >
                        <input
                            role="combobox"
                            aria-expanded={isExpanded}
                            aria-haspopup="dialog"
                            aria-controls="search-bar-results"
                            aria-describedby="search-bar-input-instructions"
                            type="text"
                            aria-label={formatMessage('search-or-enter-url')}
                            placeholder={formatMessage('search-or-enter-url')}
                            value={term}
                            onKeyDown={handleKeyDown}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onFocus={() => setIsFocused(true)}
                            ref={inputRef}
                            spellCheck="false"
                        />
                        {!!term && (
                            <CancelButton onClick={resetSearch}>
                                <Icon id="cancel" size={16} aria-label={formatMessage('cancel')} />
                            </CancelButton>
                        )}
                        {isExpanded && (
                            <SearchResultsWrapper
                                role="dialog"
                                id="search-bar-results"
                                aria-busy={isSearching}
                                aria-label={formatMessage('results')}
                            >
                                <TrackOnMount event="search_shown" />
                                <TrackOnUnmount event="search_dismissed" />
                                <SearchResults
                                    term={term}
                                    isSearching={isSearching}
                                    subscriptions={term ? matchingSubscriptions : undefined}
                                    podcasts={searchResponse?.podcasts}
                                    episodes={searchResponse?.episodes}
                                    history={searchHistory.length > 0 ? searchHistory : undefined}
                                    onItemClick={handleItemClick}
                                    onHistoryItemClick={handleHistoryItemClick}
                                    onClearAllHistory={() => {
                                        dispatch(fromSearchActions.Actions.clearHistory());
                                        setFocus();
                                    }}
                                    onClearHistoryItem={handleClearHistoryItem}
                                    maxResults={Math.max(2, maxResults)}
                                />
                            </SearchResultsWrapper>
                        )}
                    </SearchWidgetWrapper>
                </FocusOn>
            </SearchContainer>
        </>
    );
};

export default SearchBar;
