import { MutableRefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootState } from '../../redux/reducers';
import { getChaptersOpen } from '../../redux/reducers/selectors';

type Props = {
    onCloseModals?: () => void;
};

export function ModalMonitor({ onCloseModals = () => undefined }: Props) {
    const isFirstRender = useRef(true) as MutableRefObject<boolean>;
    const location = useLocation();
    const isUpNextOpen = useSelector((state: RootState) => state.upNext.open);
    const isChaptersOpen = useSelector(getChaptersOpen);

    const isSlidingModalOpen = isUpNextOpen || isChaptersOpen;

    // We should close any open modals whenever:
    // - Up Next or Chapters is opened or closed
    // - Page location changes
    useEffect(() => {
        if (isFirstRender.current) {
            // This must be the initial effect run, on component mount. We don't want to
            // close modals until dependencies _change_ so we'll ignore this run, but change
            // the flag so subsequent runs will close modals.
            isFirstRender.current = false;
            return;
        }
        onCloseModals();
    }, [isSlidingModalOpen, location]);

    return null;
}
