import { TrackOnMount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useState } from 'react';
import { Switch } from '../../../components/index';
import useTracks from '../../../hooks/useTracks';
import {
    SettingsDescription,
    SettingsOption,
    SettingsTitle,
    SwitchWrapper,
} from '../SettingsPage.styled';

interface Props {
    savePrivacyAnalytics: (enabled: boolean) => void;
    privacyAnalytics: boolean;
}

const SettingsPrivacy = ({ savePrivacyAnalytics, privacyAnalytics }: Props) => {
    const formatMessage = useFormatMessage();
    const [trackingOptOut, setTrackingOptOut] = useState(privacyAnalytics);
    const { recordEvent } = useTracks();

    const handleToggleOptOutToggle = () => {
        recordEvent(trackingOptOut ? 'analytics_opt_out' : 'analytics_opt_in');
        setTrackingOptOut(value => !value);
    };

    useEffect(() => {
        savePrivacyAnalytics(trackingOptOut);
    }, [savePrivacyAnalytics, trackingOptOut]);

    return (
        <div>
            <TrackOnMount event="settings_privacy_shown" />
            <h1>{formatMessage('privacy')}</h1>
            <SettingsOption>
                <SettingsTitle>{formatMessage('tracking-opt-out')}</SettingsTitle>
                <SwitchWrapper>
                    <Switch
                        on={trackingOptOut}
                        onChange={handleToggleOptOutToggle}
                        color="#03A9F4"
                    />
                </SwitchWrapper>
                <SettingsDescription>{formatMessage('tracking-opt-out-desc')}</SettingsDescription>
            </SettingsOption>
        </div>
    );
};

export default SettingsPrivacy;
