import * as Sentry from '@sentry/browser';
import DOMPurify from 'dompurify';
import {
    EpisodeChapter,
    EpisodeShowNotes,
    PodcastCacheJson,
    PodcastCacheParsed,
    PodcastRating,
} from 'model/types';
import { isEdge, isIE } from '../helper/Browser';
import { signOutIfHttpResponseIs401402403 } from '../helper/SignOutHelper';
import * as Settings from '../settings';
import { getAccessToken } from './auth';

const sanitizeShowNotesHTML = (showNotes: string) =>
    DOMPurify.sanitize(showNotes, {
        ALLOWED_TAGS: [
            'h3',
            'h4',
            'h5',
            'h6',
            'blockquote',
            'p',
            'a',
            'ul',
            'ol',
            'nl',
            'li',
            'b',
            'i',
            'strong',
            'em',
            'strike',
            'code',
            'hr',
            'br',
            'div',
            'table',
            'thead',
            'caption',
            'tbody',
            'tr',
            'th',
            'td',
            'pre',
            'img',
        ],
    }).replace(new RegExp('<a ', 'g'), "<a target='_blank' ");

export const processPodcastJson = async (json: PodcastCacheJson) => {
    try {
        // The raw JSON includes snake_case properties that we want to camelCase...
        const {
            podcast,
            episode_frequency: episodeFrequency,
            estimated_next_episode_at: estimatedNextEpisodeAt,
            episode_count: episodeCount,
            has_seasons: hasSeasons,
            season_count: seasonCount,
        } = json;

        const episodes = podcast.episodes.map(episode => ({
            ...episode,
            podcastUuid: podcast.uuid,
        }));

        // ...and these top-level properties that are specific to the podcast will get
        // tucked inside the podcast object, so we have them all in one place
        const processedPodcast: PodcastCacheParsed = {
            ...podcast,
            episodes,
            episodeFrequency,
            estimatedNextEpisodeAt,
            episodeCount,
            hasSeasons,
            seasonCount,
        };

        return processedPodcast;
    } catch (e) {
        Sentry.captureException(e);
        throw e;
    }
};

export default {
    getPodcast: async (uuid: string) => {
        const accessToken = await getAccessToken();
        const edgeOrIE = isEdge() || isIE();
        const url = edgeOrIE
            ? `${Settings.PODCAST_CACHE_URL}/podcast/full/${uuid}?disableredirect=true`
            : `${Settings.PODCAST_CACHE_URL}/podcast/full/${uuid}`;

        // Edge cannot follow redirects from CORS requests, so we perform a manual redirect with
        // help from the server - the redirect url is returned in the initial response payload.
        // (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13752654/)
        if (edgeOrIE) {
            return fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then(signOutIfHttpResponseIs401402403)
                .then(response => {
                    if (response.status !== 200) {
                        const e = new Error(
                            `HTTP ${response.status} for podcast fetch from ${url}`,
                        );

                        if (response.status !== 404 && response.status !== 401) {
                            Sentry.captureException(e);
                        }

                        throw e;
                    }
                    return response;
                })
                .then(response => response.json())
                .then(json => {
                    // Redirect required
                    if (json.url) {
                        return fetch(json.url, { method: 'GET' })
                            .then(response => {
                                if (response.status !== 200) {
                                    const e = new Error(
                                        `HTTP ${response.status} for manual redirect of ${url}`,
                                    );

                                    if (response.status !== 404) {
                                        Sentry.captureException(e);
                                    }

                                    throw e;
                                }

                                return response;
                            })
                            .then(response => response.json())
                            .then(processPodcastJson);
                    }

                    // Redirect not required
                    return processPodcastJson(json);
                });
        }
        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(signOutIfHttpResponseIs401402403)
            .then(response => {
                if (response.status !== 200) {
                    const e = new Error(`HTTP ${response.status} for podcast fetch from ${url}`);

                    if (response.status !== 404 && response.status !== 401) {
                        Sentry.captureException(e);
                    }

                    throw e;
                }

                return response;
            })
            .then(response => response.json())
            .then(processPodcastJson);
    },

    /**
     * Search episodes
     */
    searchPodcastEpisodes: async (term: string, podcastUuid: string) => {
        const accessToken = await getAccessToken();
        const url = `${Settings.PODCAST_CACHE_URL}/podcast/episode/search`;
        const form = new FormData();
        form.append('podcastuuid', podcastUuid);
        form.append('searchterm', term);
        return fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: form,
        })
            .then(signOutIfHttpResponseIs401402403)
            .then(response => {
                if (response.status !== 200) {
                    const e = new Error(
                        `HTTP ${response.status} for episode search from ${url} with term ${term}`,
                    );
                    Sentry.captureException(e);
                    return Promise.reject(Error(response.statusText));
                }
                return response;
            })
            .then(response => response.json())
            .then(json => json.episodes);
    },

    /**
     * Show notes
     * https://github.com/shiftyjelly/pocketcasts-info-cache#show-notes
     */
    getShowNotes: async (episodeUuid: string) => {
        const accessToken = await getAccessToken();
        const url = `${Settings.PODCAST_CACHE_URL}/episode/show_notes/${episodeUuid}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        // S3 returns 403s when the show notes file does not exist
        if (response.status === 404 || response.status === 403) {
            return [];
        }

        if (response.status !== 200) {
            throw new Error(`HTTP ${response.status} for show notes fetch from ${url}`);
        }

        const json = await response.json();

        return sanitizeShowNotesHTML(json.show_notes);
    },

    fetchPodcastShowNotes: async (podcastUuid: string) => {
        type Response = {
            podcast: {
                uuid: string;
                episodes: Array<{
                    uuid: string;
                    title: string;
                    url: string;
                    published: string;
                    show_notes: string;
                    hash: string;
                    modified: number;
                    image?: string;
                    chapters_url?: string;
                    chapters?: EpisodeChapter[];
                }>;
            };
        };
        const url = `${Settings.PODCAST_CACHE_URL}/mobile/show_notes/full/${podcastUuid}`;

        const response = await fetch(url);

        // S3 returns 403s when the show notes file does not exist
        if (response.status === 404 || response.status === 403) {
            return [];
        }

        if (response.status !== 200) {
            throw new Error(`HTTP ${response.status} for show notes fetch from ${url}`);
        }

        const json = (await response.json()) as Response;

        return json.podcast.episodes.map(
            ({ show_notes, chapters_url, ...episode }): EpisodeShowNotes => ({
                ...episode,
                chaptersUrl: chapters_url,
                showNotes: sanitizeShowNotesHTML(show_notes),
            }),
        );
    },

    fetchPodcastSearchResults: async (term: string) => {
        const url = `${Settings.PODCAST_CACHE_URL}/discover/search`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ term }),
        });

        if (response.status !== 200) {
            throw new Error(
                `HTTP ${response.status} for podcast search from ${url} with term ${term}`,
            );
        }

        const podcasts = await response.json();

        return { podcasts: podcasts };
    },

    fetchEpisodeSearchResults: async (term: string) => {
        const url = `${Settings.PODCAST_CACHE_URL}/episode/search`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ term }),
        })
            .then(response => {
                if (response.status !== 200) {
                    const e = new Error(
                        `HTTP ${response.status} for episode search from ${url} with term ${term}`,
                    );
                    Sentry.captureException(e);
                    return Promise.reject(Error(response.statusText));
                }
                return response;
            })
            .then(response => response.json())
            .then(json => json.episodes);
    },

    fetchPodcastRating: async (podcastUuid: string) => {
        const url = `${Settings.PODCAST_CACHE_URL}/podcast/rating/${podcastUuid}?t=${Date.now()}`;

        return fetch(url).then(response => {
            if (response.status === 404) {
                // 404 indicates the podcast has no rating, return null to signal that
                return null;
            }
            if (response.status !== 200) {
                const e = new Error(
                    `HTTP ${response.status} for fetchPodcastRating for UUID ${podcastUuid}`,
                );
                Sentry.captureException(e);
                return Promise.reject(Error(response.statusText));
            }
            return response.json() as Promise<PodcastRating>;
        });
    },
};
