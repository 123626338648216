import { api } from 'services/api';
import {
    fetchBookmarksFailed,
    fetchBookmarksSuccess,
    saveBookmarks,
} from '../actions/bookmarks.actions';
import { registerMiddleware } from '../api';
import { getBookmarks } from '../reducers/selectors/bookmarks.selectors';

registerMiddleware('FETCH_BOOKMARKS', async (action, store) => {
    try {
        const response = await api.fetchBookmarks();
        store.dispatch(fetchBookmarksSuccess(response));
    } catch {
        store.dispatch(fetchBookmarksFailed());
    }
});

registerMiddleware('BOOKMARK_ADD_SUCCEEDED', (action, store) => {
    const bookmarks = getBookmarks(store.getState());

    if (bookmarks) {
        bookmarks.unshift(action.payload.bookmark);
        store.dispatch(saveBookmarks(bookmarks));
    }
});

registerMiddleware('BOOKMARK_DELETE_SUCCEEDED', (action, store) => {
    const bookmarks = getBookmarks(store.getState());

    if (bookmarks) {
        const index = bookmarks.findIndex(
            b => b.bookmarkUuid === action.payload.bookmark.bookmarkUuid,
        );
        bookmarks.splice(index, 1);
        store.dispatch(saveBookmarks(bookmarks));
    }
});

registerMiddleware('BOOKMARK_EDIT_SUCCEEDED', (action, store) => {
    const bookmarks = getBookmarks(store.getState());

    if (bookmarks) {
        const index = bookmarks.findIndex(
            b => b.bookmarkUuid === action.payload.bookmark.bookmarkUuid,
        );
        bookmarks[index] = action.payload.bookmark;
        store.dispatch(saveBookmarks(bookmarks));
    }
});
