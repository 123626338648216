import { PlayingStatus } from '../../../helper/PlayingStatus';
import { UpNextEpisode } from '../../../model/types';
import { TranslatableStringKey } from '../../../translations/glotpress';
import { IconId } from '../../Icon';
import { EpisodeActions as BaseEpisodeActions } from '../useEpisodeActions';

export interface EpisodeAction {
    id: string;
    icon: IconId;
    label: TranslatableStringKey;
    isVisible: (props: EpisodeActionProps) => boolean;
    onClick: (props: EpisodeActionProps) => void;
    featureLockSource?: string;
    featureLockTitle?: string;
    featureLockDescription?: string;
}

export interface EpisodeActionProps {
    episodeUuid: string;
    podcastUuid: string;
    title: string;
    duration: number;
    url: string;
    isDeleted: boolean;
    playingStatus: number;
    isInUpNext: boolean;
    inHistory: boolean;
    isUploadedFile: boolean;
    isStarred?: boolean;
    actions: EpisodeActions;
    onRemoveFromHistory?: (episode: UpNextEpisode) => void;
}

export interface EpisodeActions extends BaseEpisodeActions {
    handleStarClick: (params: { uuid: string; podcastUuid: string; isStarred: boolean }) => void;
    handleDownloadClick: (params: { uuid: string; title: string; isUploadedFile: boolean }) => void;
    handleEditClick: (uuid: string) => void;
    handleDeleteClick: (params: { uuid: string; title: string }) => void;
    handleRemoveFromUpNext: (episodeUuid: string, isUploadedFile: boolean | undefined) => void;
}

export const getEpisodeActions = (
    showSyncActions: boolean = true,
    episodePageOnlyActions: boolean = false,
): EpisodeAction[] => {
    const allActions: EpisodeAction[] = [
        {
            id: 'share',
            icon: 'share',
            label: 'share',
            isVisible: ({ isUploadedFile }) => !isUploadedFile,
            onClick: ({ episodeUuid, podcastUuid, title, duration, url, actions }) => {
                actions.handleShare({ uuid: episodeUuid, podcastUuid, title, duration, url });
            },
        },
        {
            id: 'star',
            icon: 'star-empty',
            label: 'star',
            isVisible: ({ isUploadedFile, isStarred }) =>
                episodePageOnlyActions && !isUploadedFile && !isStarred,
            onClick: ({ episodeUuid, podcastUuid, actions }) => {
                actions.handleStarClick({ uuid: episodeUuid, podcastUuid, isStarred: false });
            },
            featureLockSource: 'star',
            featureLockTitle: 'feature-starred-unavailable-title',
            featureLockDescription: 'feature-starred-unavailable-description',
        },
        {
            id: 'unstar',
            icon: 'star-full',
            label: 'unstar',
            isVisible: ({ isUploadedFile, isStarred }) =>
                episodePageOnlyActions && !isUploadedFile && isStarred === true,
            onClick: ({ episodeUuid, podcastUuid, actions }) => {
                actions.handleStarClick({ uuid: episodeUuid, podcastUuid, isStarred: true });
            },
            featureLockSource: 'unstar',
            featureLockTitle: 'feature-starred-unavailable-title',
            featureLockDescription: 'feature-starred-unavailable-description',
        },
        {
            id: 'archive',
            icon: 'archive',
            label: 'archive',
            isVisible: ({ isDeleted }) => !episodePageOnlyActions && !isDeleted,
            onClick: ({ episodeUuid, podcastUuid, isDeleted, actions }) => {
                actions.handleArchiveClick({ uuid: episodeUuid, podcastUuid, isDeleted });
            },
            featureLockSource: 'archive',
        },
        {
            id: 'unarchive',
            icon: 'unarchive',
            label: 'unarchive',
            isVisible: ({ isDeleted }) => isDeleted,
            onClick: ({ episodeUuid, podcastUuid, isDeleted, actions }) => {
                actions.handleArchiveClick({ uuid: episodeUuid, podcastUuid, isDeleted });
            },
            featureLockSource: 'unarchive',
        },
        {
            id: 'mark-as-played',
            icon: 'mark-as-played',
            label: 'mark-as-played',
            isVisible: ({ playingStatus }) => playingStatus !== PlayingStatus.COMPLETED,
            onClick: ({ episodeUuid, podcastUuid, playingStatus, actions, isUploadedFile }) => {
                actions.handlePlayStatusClick({
                    uuid: episodeUuid,
                    podcastUuid,
                    playingStatus,
                    isUploadedFile,
                });
            },
            featureLockSource: 'mark-as-played',
        },
        {
            id: 'mark-as-unplayed',
            icon: 'mark-as-unplayed',
            label: 'mark-as-unplayed',
            isVisible: ({ playingStatus }) => playingStatus === PlayingStatus.COMPLETED,
            onClick: ({ episodeUuid, podcastUuid, playingStatus, actions, isUploadedFile }) => {
                actions.handlePlayStatusClick({
                    uuid: episodeUuid,
                    podcastUuid,
                    playingStatus,
                    isUploadedFile,
                });
            },
            featureLockSource: 'mark-as-unplayed',
        },
        {
            id: 'play-next',
            icon: 'play-next',
            label: 'play-next',
            isVisible: ({ isInUpNext }) => !isInUpNext,
            onClick: ({ episodeUuid, podcastUuid, title, url, actions, isUploadedFile }) => {
                actions.handlePlayNextClick(episodeUuid, podcastUuid, title, url, isUploadedFile);
            },
            featureLockSource: 'up_next_play_next',
            featureLockTitle: 'feature-up-next-unavailable-title',
            featureLockDescription: 'feature-up-next-unavailable-description',
        },
        {
            id: 'play-last',
            icon: 'play-last',
            label: 'play-last',
            isVisible: ({ isInUpNext }) => !isInUpNext,
            onClick: ({ episodeUuid, podcastUuid, title, url, actions, isUploadedFile }) => {
                actions.handlePlayLastClick(episodeUuid, podcastUuid, title, url, isUploadedFile);
            },
            featureLockSource: 'up_next_play_last',
            featureLockTitle: 'feature-up-next-unavailable-title',
            featureLockDescription: 'feature-up-next-unavailable-description',
        },
        {
            id: 'remove-up-next',
            icon: 'remove-from-upnext',
            label: 'remove-up-next',
            isVisible: ({ isInUpNext }) => isInUpNext,
            onClick: ({ episodeUuid, actions, isUploadedFile }) => {
                actions.handleRemoveFromUpNext(episodeUuid, isUploadedFile);
            },
        },
        {
            id: 'remove-history',
            icon: 'cancel',
            label: 'remove-history',
            isVisible: ({ inHistory }) => inHistory,
            onClick: ({ episodeUuid, podcastUuid, title, duration, url, onRemoveFromHistory }) => {
                onRemoveFromHistory?.({
                    uuid: episodeUuid,
                    podcastUuid,
                    title,
                    duration,
                    url,
                } as UpNextEpisode);
            },
        },
        // Episode page-only actions
        {
            id: 'download-file',
            icon: 'download-file',
            label: 'download-file',
            isVisible: () => episodePageOnlyActions,
            onClick: ({ episodeUuid, title, isUploadedFile, actions }) => {
                actions.handleDownloadClick({ uuid: episodeUuid, title, isUploadedFile });
            },
        },
        {
            id: 'edit',
            icon: 'edit',
            label: 'edit',
            isVisible: ({ isUploadedFile }) => episodePageOnlyActions && isUploadedFile,
            onClick: ({ episodeUuid, actions }) => {
                actions.handleEditClick(episodeUuid);
            },
        },
        {
            id: 'delete',
            icon: 'delete',
            label: 'delete',
            isVisible: ({ isUploadedFile }) => episodePageOnlyActions && isUploadedFile,
            onClick: ({ episodeUuid, title, actions }) => {
                actions.handleDeleteClick({ uuid: episodeUuid, title });
            },
        },
    ];

    return allActions.filter(action => {
        if (
            !showSyncActions &&
            ['archive', 'unarchive', 'mark-as-played', 'mark-as-unplayed'].includes(action.id)
        ) {
            return false;
        }
        return true;
    });
};
