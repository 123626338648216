import PropTypes from 'prop-types';
import React from 'react';
import Audio from './Audio';
import ChromeCast from './ChromeCast';
import NativePlayer from './NativePlayer';
import { Video } from './Video';

class Player extends React.Component {
    render() {
        return (
            <div>
                {this.props.isNativeApp && this.renderNativePlayer()}
                {!(this.props.isNativeApp || this.props.isCastConnected) &&
                    this.renderLocalPlayer()}
                {this.renderCast()}
            </div>
        );
    }

    renderNativePlayer() {
        return (
            <NativePlayer
                ref={ref => {
                    this.nativePlayer = ref;
                }}
                {...this.props}
            />
        );
    }

    renderLocalPlayer() {
        return this.props.isVideo ? this.renderVideo() : this.renderAudio();
    }

    renderCast() {
        return (
            <ChromeCast
                ref={ref => {
                    this.chromeCast = ref;
                }}
                {...this.props}
            />
        );
    }

    renderVideo() {
        return (
            <Video
                ref={ref => {
                    this.player = ref;
                }}
                {...this.props}
            />
        );
    }

    renderAudio() {
        return (
            <Audio
                ref={ref => {
                    this.player = ref;
                }}
                {...this.props}
            />
        );
    }

    seekTo(secs) {
        if (!this.isSeekable()) {
            this.props.onCantSeek();
        }

        if (this.chromeCast && this.props.isCastConnected) {
            this.chromeCast.seekTo(secs);
        }
        const player = this.player || (this.nativePlayer && this.nativePlayer);
        if (player) {
            player.seekTo(secs);
            player.saveProgressWhilePaused();
        }
    }

    skip(amount) {
        if (!this.isSeekable()) {
            this.props.onCantSeek?.();
        }

        this.player?.skip?.(amount);
        this.chromeCast?.skip?.(amount);
        if (this.nativePlayer) {
            if (amount > 0) {
                this.nativePlayer.skipForward(amount);
            } else {
                this.nativePlayer.skipBack(amount);
            }
        }
        if (this.player) {
            this.player.saveProgressWhilePaused();
        }
    }

    isSeekable() {
        const player = this.player.player ?? this.nativePlayer.player;
        if (player && player.seekable) {
            return player.seekable.length > 0 && player.seekable.end(0) > 0;
        }

        return true;
    }
}

Player.propTypes = {
    url: PropTypes.string,
    seekTo: PropTypes.number,
    speed: PropTypes.number,
    playedUpTo: PropTypes.number,
    lastSentPlayedUpTo: PropTypes.number,
    lastSentPlayingStatus: PropTypes.number,
    playing: PropTypes.bool,
    muted: PropTypes.bool,
    volume: PropTypes.number,
    theme: PropTypes.number,
    isVideo: PropTypes.bool,
    isNativeApp: PropTypes.bool,
    videoMode: PropTypes.number,
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    isCastConnected: PropTypes.bool,
    podcast: PropTypes.object,
    episode: PropTypes.object,
    onProgress: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,
    onError: PropTypes.func,
    onSaveProgress: PropTypes.func,
    onBuffering: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onVolumeChanged: PropTypes.func,
    onVideoModeChanged: PropTypes.func,
    onVideoSizeChanged: PropTypes.func,
    onCantSeek: PropTypes.func,
};

Player.defaultProps = {
    playing: false,
    playedUpTo: 0,
    seekTo: null,
    speed: 1,
    volume: 1,
    muted: false,
    isVideo: false,
    videoMode: 1,
};

export default Player;
