import { BookmarkList } from 'components/BookmarkList';
import { ConnectedModal } from 'components/Modal';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import { Podcast } from 'model/types';
import React from 'react';
import { TrackOnMount, TrackOnUnmount } from '../../../../components/Tracks';
import { getPodcastByUuid } from '../../../../redux/reducers/selectors';
import { Content, Heading, SourceTitle, Wrapper } from './BookmarksModal.styled';

export type Props = {
    podcastUuid: string;
    episodeUuid?: string;
};

const BookmarksModal = ({ podcastUuid, episodeUuid }: Props) => {
    const formatMessage = useFormatMessage();

    const podcast = useSelector<Podcast | undefined>(state => getPodcastByUuid(state, podcastUuid));
    const episode = podcast?.episodes?.find(e => e.uuid === episodeUuid);

    return (
        <ConnectedModal horizontalInsetPx={48}>
            <TrackOnMount event="bookmarks_modal_shown" />
            <TrackOnUnmount event="bookmarks_modal_dismissed" />
            <Wrapper>
                <SourceTitle>{episode ? episode.title : podcast?.title}</SourceTitle>
                <Heading>{formatMessage('bookmarks')}</Heading>
                <Content>
                    <BookmarkList
                        podcastUuid={podcastUuid}
                        episodeUuid={episodeUuid}
                        eventSource="bookmarks_modal"
                    />
                </Content>
            </Wrapper>
        </ConnectedModal>
    );
};

export default BookmarksModal;
