import { Icon } from 'components/Icon';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { MouseEventHandler } from 'react';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import * as fromSearchActions from '../../redux/actions/search.actions';
import { RootState } from '../../redux/reducers';
import SearchResultItem from './SearchResultItem';
import { AssistiveText } from './SearchResultItem.styled';

type Props = {
    url: string;
};

const URLSearchResultItem = ({ url }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const { addUrlLoading, addUrlErrorMessage } = useSelector((state: RootState) => state.search);

    const handleClick: MouseEventHandler = e => {
        e.preventDefault();
        dispatch(fromSearchActions.Actions.addPodcastByUrl(url));
    };

    const title =
        addUrlErrorMessage || formatMessage(addUrlLoading ? 'loading-feed-url' : 'open-feed-url');

    return (
        <>
            <AssistiveText>
                <p aria-live="assertive" key={title}>
                    {title}
                </p>
            </AssistiveText>
            <SearchResultItem
                image={<Icon id="clipboard" />}
                title={title}
                description={url}
                onClick={handleClick}
                aria-label={title}
            />
        </>
    );
};

export default URLSearchResultItem;
