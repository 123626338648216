import { Button } from 'components/Button';
import styled from 'styled-components';
import { ShareButton, TextWithReadMore } from '../../components';
import { FeatureLockTrigger } from '../../components/FeatureLock';
import { TextWithIcon } from '../../components/TextWithIcon';
import { MOBILE_WIDTH } from '../../model/page';
import { A11Y, fadeInKeyFrames, fontFamilyRetina, gridSize, Typography } from '../../styles';

const authorDetailsBottomPadding = 12;

export const PagePodcast = styled.div`
    height: 100%;
    position: relative;
    min-width: 0 !important;
`;

export const PodcastInformation = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 30px 0 15px 0;
    isolation: isolate;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: block;
    }
`;

export const PodcastBlurredImage = styled.div<{ background?: string }>`
    @media (max-width: ${MOBILE_WIDTH}px) {
        position: absolute;

        filter: blur(75px);
        inset: 0;

        // Move the image the image up by the blur radius, so that the blurring ends where this element ends.
        // The 30px is to compensate for the top padding of the container.
        top: calc(-75px - 30px);
        bottom: calc(75px + 30px);

        background: ${props => (props.background ? `url('${props.background}')` : 'none')} no-repeat
            center center;
        background-size: cover;
    }
`;

export const PodcastHeader = styled.div`
    position: relative;
`;

export const PodcastImageWrapper = styled.div`
    position: relative;
    width: 200px;
    flex-shrink: 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0 auto 16px;
        width: 160px;
    }
`;

export const TitleAndActions = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
`;

export const PodcastTitle = styled.h1`
    display: block;
    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    word-break: break-word;
    letter-spacing: -0.8px;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    margin: 0;
    margin-right: 40px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        text-align: center;
        margin: 0 0 -4px;
    }
`;

export const NextEpisodeDetails = styled.div`
    animation: ${fadeInKeyFrames} 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const PodcastAuthorSection = styled.div`
    ${Typography.Desktop['H70-B']};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 35px;
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-top: ${1.5 * gridSize}px;
    border-top: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    padding-top: ${1.5 * gridSize}px;
    & > * {
        margin-right: 19px;
        padding-bottom: ${authorDetailsBottomPadding}px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        column-gap: 16px;

        ${NextEpisodeDetails} {
            display: none;
        }

        & > * {
            margin-right: 0;
        }
    }
`;

export const StyledShareButton = styled(ShareButton)`
    position: absolute;
    display: inline-block;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.3s;
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-top: 4px;
    svg {
        margin: 4px;
    }
    &:focus {
        opacity: 1;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
        width: 0;
        overflow: hidden;
    }
`;

export const PodcastActionsWrapper = styled.div`
    margin-top: 5px;
    flex-shrink: 0;
    display: flex;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const PodcastInlineActionsWrapper = styled.div<{ $isSubscribed: boolean }>`
    display: none;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: ${props => (props.$isSubscribed ? 'inline-flex' : 'block')};
        gap: ${props => (props.$isSubscribed ? '6px' : '0')};
        margin-left: ${props => (props.$isSubscribed ? '6px' : '0')};
    }
`;

export const PodcastDetails = styled.div`
    width: 100%;
    padding-left: 30px;

    &:hover {
        ${StyledShareButton} {
            display: inline-block;
            opacity: 1;
        }
    }
    .text-with-read-more {
        padding-top: 6px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding-left: 0;
    }
`;

export const EpisodeSummary = styled.div`
    text-transform: uppercase;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    padding-bottom: 15px;
    color: #b8c3c9;

    @media (max-width: ${MOBILE_WIDTH}px) {
        text-align: left;
    }
`;

export const BookmarksButton = styled(Button)<{ color: string }>`
    text-transform: inherit;

    color: ${props => props.color};
    &:hover,
    ${FeatureLockTrigger}:hover & {
        opacity: 0.8;
    }
`;

export const EpisodeRows = styled.div``;

export const PodcastLink = styled(TextWithIcon)`
    @media (max-width: ${MOBILE_WIDTH}px) {
        overflow: hidden;

        svg {
            overflow: initial;
        }

        a {
            overflow-wrap: break-word;
            word-break: break-word;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`;

export const SearchBox = styled.div`
    display: flex;
    height: 57px;
    align-items: center;

    border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};

    @media (max-width: ${MOBILE_WIDTH}px) {
        border-bottom: 0;
    }

    color: ${props => props.theme.tokens['primary-text-02']};

    .icon-search-episodes {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
    }

    input {
        font-size: 16px;
        border: 0;
        outline: none;
        width: 100%;
        margin-left: 5px;
        margin-right: 30px;
        background-color: ${props => props.theme.legacyScss['theme-background']};
        color: ${props => props.theme.legacyScss['theme-text-primary']};

        @media (min-width: ${MOBILE_WIDTH + 1}px) {
            &:focus-visible {
                ${A11Y.outlineOutset}
                outline-offset: 4px;
            }
        }
    }

    #episode-options-search-bar {
        margin-left: 5px;
    }

    #sort-options-search-bar {
        margin-left: 5px;
    }

    #clear-search-bar {
        width: 30px;
        height: 30px;
        margin-right: -4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-icon-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: -4px;
        padding-left: 5px;
    }
`;

export const SearchBoxMobile = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    padding: 8px 16px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        background-color: ${props => props.theme.tokens['primary-field-01']};

        input {
            background-color: transparent;
        }

        &:focus-within {
            ${A11Y.outlineOutset}
            outline-offset: 4px;
        }
    }
`;

export const ReadMoreText = styled(TextWithReadMore)`
    padding-top: 7px;
    padding-bottom: 19px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        border-top: 1px solid ${props => props.theme.legacyScss['theme-line']};
        border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    }
`;

export const PodcastMeta = styled.div`
    display: flex;
    align-items: center;

    > * + * {
        margin-left: ${3 * gridSize}px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
`;

export const CategoryLinks = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const RateButton = styled(Button)<{ color: string }>`
    ${Typography.Desktop['H70-B']}
    color: ${props => props.color};
    line-height: 1;
    margin-left: ${1.5 * gridSize}px;
    &:hover,
    &:focus-visible,
    ${FeatureLockTrigger}:hover &,
    ${FeatureLockTrigger}:focus-visible & {
        opacity: 0.8;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;
