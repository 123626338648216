import { milliseconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

function useDismiss(key: string, expiryTime: number = milliseconds({ days: 14 })) {
    const namespacedKey = `dismissed:${key}`;

    const [show, setShow] = useState(true);

    useEffect(() => {
        const storedData = localStorage.getItem(namespacedKey);
        if (storedData) {
            try {
                const dismissedAt = parseInt(storedData, 10);
                const isExpired = Date.now() - dismissedAt > expiryTime;
                setShow(isExpired);
            } catch {
                // If JSON parsing fails, we reset the show state
                setShow(true);
            }
        }
    }, [namespacedKey, expiryTime]);

    const dismiss = useCallback(() => {
        setShow(false);
        localStorage.setItem(namespacedKey, Date.now().toString());
    }, [namespacedKey]);

    return { show, dismiss };
}

export default useDismiss;
