/**
 * This file should eventually be split up across components.
 */

import useFormatMessage from 'hooks/useFormatMessage';
import { DESKTOP_PLAYBACK_ERRORS, WEB_PLAYER_PLAYBACK_ERRORS } from 'model/external-links';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedPlural } from 'react-intl';
import { isElectronApp } from '../helper/Browser';
import { isDateInPast } from '../helper/TimeHelper';

const link = isElectronApp() ? DESKTOP_PLAYBACK_ERRORS : WEB_PLAYER_PLAYBACK_ERRORS;

export const MatchCount = ({ count }: { count: number }) =>
    count === 1 ? (
        <FormattedMessage id="match-count-singular" />
    ) : (
        <FormattedMessage id="match-count-plural" values={{ count }} />
    );

export const EpisodeCount = ({ count }: { count: number }) =>
    count === 1 ? (
        <FormattedMessage id="episode-count-singular" />
    ) : (
        <FormattedMessage id="episode-count-plural" values={{ count }} />
    );

export const ChapterCount = ({ count }: { count: number }) => (
    <FormattedPlural
        one={<FormattedMessage id="chapter-count-singular" />}
        other={<FormattedMessage id="chapter-count-plural" values={{ count }} />}
        value={count}
    />
);

export const formattedPodcastCount = (
    count: number,
    formatMessage: ReturnType<typeof useFormatMessage>,
) => formatMessage(count === 1 ? 'podcast-count-singular' : 'podcast-count-plural', { count });

export const PodcastCount = ({ count }: { count: number }) =>
    count === 1 ? (
        <FormattedMessage id="podcast-count-singular" />
    ) : (
        <FormattedMessage id="podcast-count-plural" values={{ count }} />
    );

export const NextEpisodePredictionOnDayOrDateMessage = ({
    date,
    dayOnly,
}: {
    date: Date;
    dayOnly: boolean;
}) => (
    <FormattedMessage
        id="episode-prediction-on-date-or-day"
        values={{
            date: dayOnly ? (
                <FormattedDate value={date} weekday="long" />
            ) : (
                <FormattedDate value={date} weekday="long" day="numeric" month="long" />
            ),
        }}
    />
);

export const ErrorMessageFileNotUploaded = () => <FormattedMessage id="file-not-uploaded" />;

export const UploadedFileMessage = () => <FormattedMessage id="uploaded-file" />;

export const ModalSubscriptionActiveUntilDateMessage = ({ expiryDate }: { expiryDate: Date }) => (
    <FormattedMessage
        id="modal-subscription-active-until"
        values={{
            date: <FormattedDate value={expiryDate} day="numeric" month="long" year="numeric" />,
        }}
    />
);

export const BillingWillResumeOnDateMessage = ({ resumeDate }: { resumeDate: Date }) => (
    <FormattedMessage
        id="modal-billing-will-resume-on"
        values={{
            date: <FormattedDate value={resumeDate} day="numeric" month="long" year="numeric" />,
        }}
    />
);

export const NextBillingDateMessage = ({ billingDate }: { billingDate: Date | null }) => (
    <FormattedMessage
        id="next-billing-date"
        values={{
            date: billingDate && (
                <span style={{ display: 'inline', fontWeight: 500 }}>
                    <FormattedDate value={billingDate} day="numeric" month="long" year="numeric" />
                </span>
            ),
        }}
    />
);

/*
 * Specially formatted FormattedMessages.
 */

export const NextPaymentMessage = ({ expiryDate }: { expiryDate: Date | null }) => (
    <FormattedMessage
        id="next-payment"
        values={{
            date: expiryDate ? (
                <FormattedDate value={expiryDate} day="numeric" month="long" year="numeric" />
            ) : (
                'N/A'
            ),
        }}
    />
);

export const ExpiresOnDateMessage = ({ expiryDate }: { expiryDate: Date }) => (
    <FormattedMessage
        id={isDateInPast(expiryDate) ? 'expired-on' : 'expires-on'}
        values={{
            date: <FormattedDate value={expiryDate} day="numeric" month="short" year="numeric" />,
        }}
    />
);

export const FirstPaymentOnMessage = ({ expiryDate }: { expiryDate: Date }) => (
    <FormattedMessage
        id="first-payment-on"
        values={{
            date: <FormattedDate value={expiryDate} day="numeric" month="short" year="numeric" />,
        }}
    />
);

export const ExpiresDateMessage = ({ expiryDate }: { expiryDate: Date }) => (
    <FormattedMessage
        id={isDateInPast(expiryDate) ? 'expired-on' : 'expires'}
        values={{
            date: <FormattedDate value={expiryDate} day="numeric" month="long" year="numeric" />,
        }}
    />
);

export const StatsPageSinceMessage = ({ timesStartedAt }: { timesStartedAt: Date | string }) => (
    <FormattedMessage
        id="stats-page-since"
        values={{
            date: (
                <FormattedDate value={timesStartedAt} day="numeric" month="long" year="numeric" />
            ),
        }}
    />
);

export const ErrorMessageOnPlayFileNotUploaded = () => (
    <FormattedMessage id="error-on-play-file-not-uploaded" />
);

export const MediaErrorMessageAborted = (linkMessage: string) => (
    <FormattedMessage
        id="error-media-aborted"
        values={{
            link: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {linkMessage}
                </a>
            ),
        }}
    />
);

export const MediaErrorMessageDecode = (linkMessage: string) => (
    <FormattedMessage
        id="error-media-decode"
        values={{
            link: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {linkMessage}
                </a>
            ),
        }}
    />
);

export const MediaErrorAccessExpired = () => <FormattedMessage id="error-media-access-expired" />;

export const MediaErrorMessageNotSupported = (linkMessage: string) => (
    <FormattedMessage
        id="error-media-not-supported"
        values={{
            link: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {linkMessage}
                </a>
            ),
        }}
    />
);

export const MediaErrorMessageNetwork = (linkMessage: string) => (
    <FormattedMessage
        id="error-media-network"
        values={{
            link: (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {linkMessage}
                </a>
            ),
        }}
    />
);

export const MediaErrorMessageOther = (linkMessage: string, code: string) => (
    <FormattedMessage
        id="error-media-other"
        values={{
            link: <a href={link}>{linkMessage}</a>,
            code,
        }}
    />
);

export const MediaErrorLinkMessage = () => <FormattedMessage id="error-media-link" />;

export const AccountCreatedMessage = (email: string) => (
    <FormattedMessage
        id="account-created"
        values={{
            email,
        }}
    />
);

/* **** Seasons, episodes and trailers **** */

export const SeasonTrailerMessage = ({ season }: { season: string | number }) => (
    <FormattedMessage
        id="season-x-trailer"
        values={{
            number: season,
        }}
    />
);

export const SeasonXShortMessage = ({ season }: { season: string | number }) => (
    <FormattedMessage
        id="season-x-short"
        values={{
            seasonNumber: season,
        }}
    />
);

export const EpisodeYShortMessage = ({ episode }: { episode: string | number }) => (
    <FormattedMessage
        id="episode-y-short"
        values={{
            episodeNumber: episode,
        }}
    />
);

export const SeasonXEpisodeYShortMessage = ({
    season,
    episode,
}: {
    season: string | number;
    episode: string | number;
}) => (
    <FormattedMessage
        id="season-x-episode-y-short"
        values={{
            seasonNumber: season,
            episodeNumber: episode,
        }}
    />
);
