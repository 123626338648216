import styled from 'styled-components';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { blueTextLinkMixin, coolGrey60, gridSize, H40, H70, H80 } from '../../../../styles';

export const HeadingWrapper = styled(H40)`
    text-align: center;
`;

export const Subtitle = styled(H70)`
    max-width: 480px;
    text-align: center;
`;

export const Spaces = styled.div<{ $n?: number }>`
    height: ${props => gridSize * (props.$n || 1)}px;
`;

export const CancelTextWrapper = styled(H70)`
    ${blueTextLinkMixin};
`;

export const CheckoutFooterWrapper = styled(H80)`
    width: 464px;
    color: ${coolGrey60};
    text-align: center;
`;

export const PlanSelectorWrapper = styled(ModernStyle)`
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
`;
