import { Actions, ActionTypes } from '../actions/tracks.actions';

interface TracksState {
    source?: string;
}

const INITIAL_STATE: TracksState = {
    source: undefined,
};

/*
 * This "noop" reducer is necessary so that TypeScript can infer the correct type for the
 * tracks actions.
 */
export default (state: TracksState = INITIAL_STATE, action: Actions) => {
    switch (action.type) {
        case ActionTypes.TRACKS_SET_SOURCE: {
            return {
                ...state,
                source: action.payload.source,
            };
        }
        case ActionTypes.TRACKS_CLEAR_SOURCE: {
            return {
                ...state,
                source: undefined,
            };
        }
        default:
            return state;
    }
};
