import React from 'react';
import styled from 'styled-components';
import { gridSize } from '../../styles';

type FeaturesShowcaseCardProps = {
    icon: React.ReactNode;
    title: string;
    description: string;
    image: React.ReactNode;
};

const StyledCardWrapper = styled.section`
    display: flex;
    min-height: inherit;
    justify-content: center;
    gap: ${gridSize * 4}px;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
    }
`;

const FeaturesShowcaseCardHeader = styled.header`
    display: flex;
    flex-direction: column;
    gap: ${gridSize * 2}px;
    justify-content: center;
    text-align: center;
    flex: 1;

    @media (min-width: 768px) {
        text-align: inherit;
        gap: ${gridSize * 4}px;
    }
`;

const StyledFeatureTitle = styled.h2`
    margin: 0;
    font-size: 32px;
    font-weight: 700;
`;

const StyledFeatureDescription = styled.p`
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    text-wrap: balance;
    color: ${props => props.theme.tokens['secondary-text-02']};
`;

const FeaturedShowcaseCardImageWrapper = styled.div`
    display: flex;
    overflow: hidden;

    @media (min-width: 768px) {
        min-height: inherit;
    }
    flex: 1;
    align-items: center;
    justify-content: flex-end;
`;

const StyledFeatureIconWrapper = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const FeaturesShowcaseCard = ({ icon, title, description, image }: FeaturesShowcaseCardProps) => {
    return (
        <StyledCardWrapper>
            <FeaturesShowcaseCardHeader>
                <StyledFeatureIconWrapper>{icon}</StyledFeatureIconWrapper>
                <StyledFeatureTitle>{title}</StyledFeatureTitle>
                <StyledFeatureDescription>{description}</StyledFeatureDescription>
            </FeaturesShowcaseCardHeader>
            <FeaturedShowcaseCardImageWrapper>{image}</FeaturedShowcaseCardImageWrapper>
        </StyledCardWrapper>
    );
};

export default FeaturesShowcaseCard;
