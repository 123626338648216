import React from 'react';
import { FormattedMessage } from 'react-intl';

const DurationOneSecMessage = () => <FormattedMessage id="duration-one-second-shorthand" />;

const DurationOneSecondMessage = () => <FormattedMessage id="duration-one-second" />;

const DurationNSecsMessage = ({ secs }: { secs: number }) => (
    <FormattedMessage
        id="duration-n-seconds-shorthand"
        values={{
            number: secs,
        }}
    />
);

const DurationNSecondsMessage = ({ seconds }: { seconds: number }) => (
    <FormattedMessage
        id="duration-n-seconds"
        values={{
            number: seconds,
        }}
    />
);

const DurationOneMinMessage = () => <FormattedMessage id="duration-one-minute-shorthand" />;

const DurationOneMinuteMessage = () => <FormattedMessage id="duration-one-minute" />;

const DurationYMinsMessage = ({ mins }: { mins: number }) => (
    <FormattedMessage
        id="duration-n-minutes-shorthand"
        values={{
            number: mins,
        }}
    />
);

const DurationYMinutesMessage = ({ minutes }: { minutes: number }) => (
    <FormattedMessage
        id="duration-n-minutes"
        values={{
            number: minutes,
        }}
    />
);

const DurationOneHrMessage = () => <FormattedMessage id="duration-one-hour-shorthand" />;

const DurationOneHourMessage = () => <FormattedMessage id="duration-one-hour" />;

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const DurationXHrsMessage = ({ hrs }: { hrs: number }) => (
    <FormattedMessage
        id="duration-n-hours-shorthand"
        values={{
            number: hrs,
        }}
    />
);

const DurationXHoursMessage = ({ hours }: { hours: number }) => (
    <FormattedMessage
        id="duration-n-hours"
        values={{
            number: hours,
        }}
    />
);

const DurationOneHrOneMinMessage = () => (
    <FormattedMessage id="duration-one-hour-one-minute-shorthand" />
);

const DurationOneHrYMinsMessage = ({ mins }: { mins: number }) => (
    <FormattedMessage
        id="duration-one-hour-n-minutes-shorthand"
        values={{
            number: mins,
        }}
    />
);

const DurationXHrsOneMinMessage = ({ hrs }: { hrs: number }) => (
    <FormattedMessage
        id="duration-n-hours-one-minute-shorthand"
        values={{
            number: hrs,
        }}
    />
);

const DurationXHrsYMinsMessage = ({ hrs, mins }: { mins: number; hrs: number }) => (
    <FormattedMessage
        id="duration-n-hours-m-minutes-shorthand"
        values={{
            numHours: hrs,
            numMins: mins,
        }}
    />
);

const DurationOneHourOneMinuteMessage = () => (
    <FormattedMessage id="duration-one-hour-one-minute" />
);

const DurationOneHourYMinutesMessage = ({ minutes }: { minutes: number }) => (
    <FormattedMessage
        id="duration-one-hour-n-minutes"
        values={{
            number: minutes,
        }}
    />
);

const DurationXHoursOneMinuteMessage = ({ hours }: { hours: number }) => (
    <FormattedMessage
        id="duration-n-hours-one-minute"
        values={{
            number: hours,
        }}
    />
);

const DurationXHoursYMinutesMessage = ({ hours, minutes }: { hours: number; minutes: number }) => (
    <FormattedMessage
        id="duration-n-hours-m-minutes"
        values={{
            numHours: hours,
            numMinutes: minutes,
        }}
    />
);

const RemainingDurationMessage = ({ Message }: { Message: JSX.Element }) => (
    <FormattedMessage
        id="remaining-duration"
        values={{
            duration: Message,
        }}
    />
);

const calculate1SecMessage = (short: boolean) =>
    short ? <DurationOneSecMessage /> : <DurationOneSecondMessage />;

const calculateLessThan60SecsMessage = (duration: number, short: boolean) =>
    short ? (
        <DurationNSecsMessage secs={Math.floor(duration)} />
    ) : (
        <DurationNSecondsMessage seconds={Math.floor(duration)} />
    );

const calculateOneMinMessage = (short: boolean) =>
    short ? <DurationOneMinMessage /> : <DurationOneMinuteMessage />;

const calculateLessThanOneHourMessage = (duration: number, short: boolean) =>
    short ? (
        <DurationYMinsMessage mins={Math.floor(duration / 60)} />
    ) : (
        <DurationYMinutesMessage minutes={Math.floor(duration / 60)} />
    );

const calculateOneHourMessage = (short: boolean) =>
    short ? <DurationOneHrMessage /> : <DurationOneHourMessage />;

const calculateOneHourOneMinuteMessage = (short: boolean) =>
    short ? <DurationOneHrOneMinMessage /> : <DurationOneHourOneMinuteMessage />;

const calculateOneHourXMinsMessage = (duration: number, short: boolean) =>
    short ? (
        <DurationOneHrYMinsMessage mins={Math.floor((duration - 3600) / 60)} />
    ) : (
        <DurationOneHourYMinutesMessage minutes={Math.floor((duration - 3600) / 60)} />
    );

// This is intentional, but I've left DurationXHrsMessage
// available for translators in case we ever want to use it.
const calculateXHoursMessage = (duration: number, short: boolean) =>
    short ? (
        <DurationXHoursMessage hours={Math.floor(duration / 3600)} />
    ) : (
        <DurationXHoursMessage hours={Math.floor(duration / 3600)} />
    );

const calculateXHoursOneMinuteMessage = (duration: number, short: boolean) =>
    short ? (
        <DurationXHrsOneMinMessage hrs={Math.floor(duration / 3600)} />
    ) : (
        <DurationXHoursOneMinuteMessage hours={Math.floor(duration / 3600)} />
    );

const calculateXHoursYMinutesMessage = (duration: number, short: boolean) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);

    return short ? (
        <DurationXHrsYMinsMessage hrs={hours} mins={minutes} />
    ) : (
        <DurationXHoursYMinutesMessage hours={hours} minutes={minutes} />
    );
};

export const DurationStringFromSeconds = ({
    durationSecsStrOrNum,
    short,
    remaining,
}: {
    durationSecsStrOrNum: number;
    short: boolean;
    remaining?: boolean;
}) => {
    const durationSecs = Number(durationSecsStrOrNum);

    const DurationMessage =
        durationSecs === 1
            ? calculate1SecMessage(short)
            : durationSecs < 60
              ? calculateLessThan60SecsMessage(durationSecs, short)
              : durationSecs < 120
                ? calculateOneMinMessage(short)
                : durationSecs < 3600
                  ? calculateLessThanOneHourMessage(durationSecs, short)
                  : durationSecs < 3660
                    ? calculateOneHourMessage(short)
                    : durationSecs < 3720
                      ? calculateOneHourOneMinuteMessage(short)
                      : durationSecs < 7200
                        ? calculateOneHourXMinsMessage(durationSecs, short)
                        : durationSecs % 3600 < 60
                          ? calculateXHoursMessage(durationSecs, short)
                          : durationSecs % 3600 < 120
                            ? calculateXHoursOneMinuteMessage(durationSecs, short)
                            : calculateXHoursYMinutesMessage(durationSecs, short);

    if (remaining) {
        return <RemainingDurationMessage Message={DurationMessage} />;
    }

    return DurationMessage;
};
