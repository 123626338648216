import { LoaderSquare } from 'components/LoaderSquare';
import { Logo } from 'components/Logo';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useState } from 'react';
import { LoaderWrapper, Wrapper } from './OAuthOpenInApp.styled';

const OAuthOpenInApp = () => {
    const [loading, setLoading] = useState(true);
    const formatMessage = useFormatMessage();
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Wrapper>
            <Logo />
            <h1>{formatMessage('launching-pocket-casts')}</h1>
            <p>
                {formatMessage('finish-desktop-login', {
                    openLink: (text: string) => <strong>&quot;{text}&quot;</strong>,
                })}
            </p>
            <LoaderWrapper>{loading && <LoaderSquare />}</LoaderWrapper>
        </Wrapper>
    );
};

export default OAuthOpenInApp;
