import React from 'react';
import { Link, useLocation } from 'react-router';
import { SettingNavigationWrapper } from './SettingNavigation.styled';

type Props = {
    className?: string;
    to: string;
    title: string;
};

function SettingNavigation({ className, to, title }: Props) {
    const location = useLocation();
    const path = location.pathname;
    const navPath = to;
    const isRoot = path === '/';
    const selected = (isRoot && navPath === '/podcasts') || (!isRoot && navPath.startsWith(path));

    return (
        <SettingNavigationWrapper className={className} $isSelected={selected}>
            <Link to={to} state={{ from: location.pathname }} replace={true}>
                {title}
            </Link>
        </SettingNavigationWrapper>
    );
}

export default SettingNavigation;
