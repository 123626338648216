import { UpNextIcon } from 'components/UpNextIcon';
import React from 'react';
import { useDispatch, useSelector } from '../../../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import * as fromUpNextActions from '../../../../redux/actions/up-next.actions';
import { getQueuedEpisodeUuids, getUpNextIsOpen } from '../../../../redux/reducers/selectors';
import { SlideUpButton } from '../SlideUpButton';

export default function UpNextButton({ ...props }) {
    const dispatch = useDispatch();

    const queuedEpisodeUids = useSelector(getQueuedEpisodeUuids);
    const episodeCount = queuedEpisodeUids.length;
    const isOpen = useSelector(getUpNextIsOpen);

    const onOpen = () => {
        dispatch(fromPlayerActions.Actions.closeChapters());
        dispatch(fromUpNextActions.Actions.openUpNext());
    };

    const onClose = () => {
        dispatch(fromUpNextActions.Actions.closeUpNext());
    };

    return (
        <SlideUpButton
            {...props}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            aria-label="Show Up Next"
            icon={<UpNextIcon count={episodeCount} />}
        />
    );
}
