import classNames from 'classnames';
import { Icon } from 'components/Icon';
import React from 'react';
import useFormatMessage from '../../../hooks/useFormatMessage';
import FeatureLock from '../../FeatureLock';
import { StarButtonWrapper } from './StarButton.styled';

type Props = {
    on?: boolean;
    onClick: (event: React.MouseEvent) => void;
};

function StarButton({ on = false, onClick = () => ({}) }: Props) {
    const classes = classNames('star-button', { on });
    const formatMessage = useFormatMessage();
    return (
        <FeatureLock
            title={formatMessage('feature-starred-unavailable-title')}
            description={formatMessage('feature-starred-unavailable-description')}
            source="episode_star"
        >
            <StarButtonWrapper className={classes} onClick={onClick}>
                <Icon id="star-full" size={16} />
            </StarButtonWrapper>
        </FeatureLock>
    );
}

export default StarButton;
