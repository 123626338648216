import {
    PodcastListPodcast,
    PodcastRating,
    PodcastTintColors,
    TodoFixmeMigrationType,
} from 'model/types';
import * as fromFoldersActions from '../actions/folders.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import { ActionTypes, Actions } from '../actions/podcasts.actions';

// TODO: Update folderUuid on FOLDER_ADD/FOLDER_UPDATE/FOLDER_REMOVE (both adding and removing podcasts in folders)

export interface PodcastsState {
    subscribedUuids: string[];
    uuidToPodcast: Record<string, TodoFixmeMigrationType>;
    uuidToColors: Record<string, PodcastTintColors>;
    uuidToRating: Partial<Record<string, PodcastRating | null>>; // null means the podcast has no rating, and should not be re-fetched
    uuidToUserRating: Partial<Record<string, number | null>>; // null means the podcast has no rating from this user, and should not be re-fetched
    loadFailed: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    lastLoadedTimeMs: number | null;
}

export const INITIAL_STATE: PodcastsState = {
    subscribedUuids: [],
    uuidToPodcast: {},
    uuidToColors: {},
    uuidToRating: {},
    uuidToUserRating: {},
    loadFailed: false,
    isLoading: false,
    isLoaded: false,
    lastLoadedTimeMs: null,
};

const removeUnplayed = (
    state: PodcastsState,
    payload: { podcastUuid: string; episodeUuid: string },
) => {
    const foundPodcast = state.uuidToPodcast[payload.podcastUuid] || {};
    if (foundPodcast.lastEpisodeUuid === payload.episodeUuid) {
        const podcastUpdated = {} as Record<string, PodcastListPodcast>;
        podcastUpdated[payload.podcastUuid] = { ...foundPodcast, unplayed: false };
        return { ...state, uuidToPodcast: { ...state.uuidToPodcast, ...podcastUpdated } };
    }

    return state;
};

const putPodcastsInFolder = (state: PodcastsState, folderUuid: string, podcastUuids: string[]) => {
    const uuidToPodcast = { ...state.uuidToPodcast };
    Object.keys(uuidToPodcast).forEach(uuid => {
        if (podcastUuids.includes(uuid)) {
            uuidToPodcast[uuid] = { ...uuidToPodcast[uuid], folderUuid };
        } else if (uuidToPodcast[uuid].folderUuid === folderUuid) {
            uuidToPodcast[uuid] = { ...uuidToPodcast[uuid], folderUuid: '' };
        }
    });
    return { ...state, uuidToPodcast };
};

const updatePodcastSetting = (
    state: PodcastsState,
    podcastUuid: string,
    settingKey: string,
    value: boolean | number | string,
) => {
    if (value != null) {
        const foundPodcast = {
            ...state.uuidToPodcast[podcastUuid],
            settings: {
                ...state.uuidToPodcast[podcastUuid]?.settings,
                [settingKey]: {
                    ...state.uuidToPodcast[podcastUuid]?.settings?.[settingKey],
                    value,
                    changed: true,
                },
            },
        };
        return {
            ...state,
            uuidToPodcast: {
                ...state.uuidToPodcast,
                [podcastUuid]: foundPodcast,
            },
        };
    }
    return state;
};

export default (
    state: PodcastsState = INITIAL_STATE,
    action: Actions | fromPodcastActions.Actions | fromFoldersActions.Actions,
): PodcastsState => {
    switch (action.type) {
        case ActionTypes.ADD_PODCAST: {
            const { uuid } = action.payload.podcastCache;
            const changes = {} as Record<string, string>;
            changes[uuid] = {
                ...(state.uuidToPodcast[uuid] || {}),
                ...action.payload.podcastSync,
                ...action.payload.podcastCache,
            };
            return { ...state, uuidToPodcast: { ...state.uuidToPodcast, ...changes } };
        }
        case ActionTypes.SUBSCRIBE_TO_PODCAST: {
            const subscribedUuids = state.subscribedUuids.slice();
            if (subscribedUuids.includes(action.payload.podcast.uuid)) {
                return state;
            }
            subscribedUuids.push(action.payload.podcast.uuid);
            return {
                ...state,
                subscribedUuids,
            };
        }
        case ActionTypes.UNSUBSCRIBE_FROM_PODCAST: {
            return {
                ...state,
                subscribedUuids: state.subscribedUuids.filter(
                    uuid => uuid !== action.payload.podcastUuid,
                ),
            };
        }
        case ActionTypes.UPDATE_PODCAST_COLORS: {
            const uuidToColors = {} as Record<string, PodcastTintColors>;
            uuidToColors[action.payload.uuid] = action.payload.colors;
            return { ...state, uuidToColors: { ...state.uuidToColors, ...uuidToColors } };
        }
        case ActionTypes.UPDATE_PODCASTS_COLORS: {
            return {
                ...state,
                uuidToColors: { ...state.uuidToColors, ...action.payload.uuidToColors },
            };
        }
        case fromPodcastActions.ActionTypes.UPDATE_EPISODE_ORDER: {
            const foundPodcast = state.uuidToPodcast[action.payload.podcastUuid] || {};
            const podcastWithSort = {} as Record<string, PodcastListPodcast>;
            podcastWithSort[action.payload.podcastUuid] = {
                ...foundPodcast,
                episodesSortOrder: action.payload.episodesSortOrder,
            };
            return {
                ...state,
                uuidToPodcast: { ...state.uuidToPodcast, ...podcastWithSort },
            };
        }
        case fromPodcastActions.ActionTypes.UPDATE_AUTO_START_FROM: {
            const foundPodcast = state.uuidToPodcast[action.payload.podcastUuid] || {};
            if (action.payload.autoStartFrom != null && foundPodcast) {
                foundPodcast.autoStartFrom = action.payload.autoStartFrom;
                return {
                    ...state,
                    uuidToPodcast: {
                        ...state.uuidToPodcast,
                        [action.payload.podcastUuid]: foundPodcast,
                    },
                };
            }
            return state;
        }

        case fromPodcastActions.ActionTypes.UPDATE_PLAYBACK_EFFECTS: {
            // If no settings for this podcast exist yet, set the default time value
            const foundPodcast = state.uuidToPodcast[action.payload.podcastUuid] || {};
            const updatedState = foundPodcast.settings
                ? state
                : updatePodcastSetting(state, action.payload.podcastUuid, 'playbackSpeed', 1);

            return updatePodcastSetting(
                updatedState,
                action.payload.podcastUuid,
                'playbackEffects',
                action.payload.playbackEffects,
            );
        }

        case fromPodcastActions.ActionTypes.UPDATE_PLAYBACK_SPEED: {
            // If no settings for this podcast exist yet, set the default playback effects value
            const foundPodcast = state.uuidToPodcast[action.payload.podcastUuid] || {};
            const updatedState = foundPodcast.settings
                ? state
                : updatePodcastSetting(state, action.payload.podcastUuid, 'playbackEffects', true);

            return updatePodcastSetting(
                updatedState,
                action.payload.podcastUuid,
                'playbackSpeed',
                action.payload.playbackSpeed,
            );
        }

        case fromPodcastActions.ActionTypes.SHOW_ARCHIVED: {
            return updatePodcastSetting(
                state,
                action.payload.podcastUuid,
                'showArchived',
                action.payload.showArchived,
            );
        }

        case fromPodcastActions.ActionTypes.UPDATE_AUTO_SKIP_LAST: {
            const foundPodcast = state.uuidToPodcast[action.payload.podcastUuid] || {};
            if (action.payload.autoSkipLast != null && foundPodcast) {
                foundPodcast.autoSkipLast = action.payload.autoSkipLast;
                return {
                    ...state,
                    uuidToPodcast: {
                        ...state.uuidToPodcast,
                        [action.payload.podcastUuid]: foundPodcast,
                    },
                };
            }
            return state;
        }

        case fromPodcastActions.ActionTypes.UPDATE_AUTO_ARCHIVE: {
            return updatePodcastSetting(
                state,
                action.payload.podcastUuid,
                'autoArchive',
                action.payload.autoArchive,
            );
        }

        case fromPodcastActions.ActionTypes.UPDATE_AUTO_ARCHIVE_PLAYED: {
            return updatePodcastSetting(
                state,
                action.payload.podcastUuid,
                'autoArchivePlayed',
                action.payload.autoArchivePlayed,
            );
        }

        case fromPodcastActions.ActionTypes.MARK_AS_PLAYED: {
            return removeUnplayed(state, action.payload);
        }
        case fromPodcastActions.ActionTypes.MARK_AS_IN_PROGRESS: {
            return removeUnplayed(state, action.payload);
        }
        case ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS: {
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                lastLoadedTimeMs: new Date().getTime(),
            };
        }
        case ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS_SUCCESS: {
            const { uuidToPodcast } = state;
            action.payload.podcasts.forEach(podcast => {
                uuidToPodcast[podcast.uuid] = {
                    ...uuidToPodcast[podcast.uuid],
                    ...podcast,
                };
            });
            return {
                ...state,
                subscribedUuids: action.payload.podcasts.map(podcast => podcast.uuid),
                uuidToPodcast,
                isLoading: false,
                isLoaded: true,
                loadFailed: false,
            };
        }
        case ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS_FAILED: {
            return { ...state, loadFailed: true };
        }

        case ActionTypes.PODCAST_LIST_UPDATE_POSITIONS: {
            const newState = { ...state };
            Object.keys(action.payload.positions.podcasts).forEach(uuid => {
                if (uuid in newState.uuidToPodcast) {
                    newState.uuidToPodcast[uuid].sortPosition =
                        action.payload.positions.podcasts[uuid];
                }
            });
            return newState;
        }

        case fromFoldersActions.ActionTypes.FOLDER_ADD:
        case fromFoldersActions.ActionTypes.FOLDER_UPDATE:
            return putPodcastsInFolder(
                state,
                action.payload.folder.uuid,
                action.payload.podcastUuids,
            );
        case fromFoldersActions.ActionTypes.FOLDER_REMOVE:
            return putPodcastsInFolder(state, action.payload.uuid, []);

        case 'PODCAST_FETCH_RATING_SUCCEEDED':
            return {
                ...state,
                uuidToRating: {
                    ...state.uuidToRating,
                    [action.payload.uuid]: action.payload.rating,
                },
            };

        case 'PODCAST_FETCH_RATING_FAILED':
            // On failure, set the rating to null so we don't try to fetch it again
            return {
                ...state,
                uuidToRating: {
                    ...state.uuidToRating,
                    [action.payload.uuid]: null,
                },
            };

        case fromPodcastActions.ActionTypes.RATE_PODCAST:
            return {
                ...state,
                uuidToUserRating: {
                    ...state.uuidToUserRating,
                    [action.payload.uuid]: action.payload.userRating,
                },
            };

        case fromPodcastActions.ActionTypes.FETCH_USER_RATING_SUCCEEDED:
            return {
                ...state,
                uuidToUserRating: {
                    ...state.uuidToUserRating,
                    [action.payload.uuid]: action.payload.userRating,
                },
            };

        case fromPodcastActions.ActionTypes.FETCH_USER_RATING_FAILED:
            // On failure, set the rating to null so we don't try to fetch it again
            return {
                ...state,
                uuidToUserRating: {
                    ...state.uuidToUserRating,
                    [action.payload.uuid]: null,
                },
            };

        default:
            return state;
    }
};
