import { TracksProperties } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    TRACKS_RECORD_EVENT = '@tracks/TRACKS_RECORD_EVENT',
    TRACKS_SET_SOURCE = '@tracks/TRACKS_SET_SOURCE',
    TRACKS_CLEAR_SOURCE = '@tracks/TRACKS_CLEAR_SOURCE',
}

export const Actions = {
    recordEvent: (name: string, properties?: TracksProperties) =>
        createAction(ActionTypes.TRACKS_RECORD_EVENT, { name, properties }),
    setSource: (source: string) => createAction(ActionTypes.TRACKS_SET_SOURCE, { source }),
    clearSource: () => createAction(ActionTypes.TRACKS_CLEAR_SOURCE),
};

export type Actions = ActionsUnion<typeof Actions>;
