import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect } from 'react';
import { useDispatch } from '../../../../hooks/react-redux-typed';
import useTracks from '../../../../hooks/useTracks';
import PlusErrorIcon from '../../../../icons/plus-error-icon.png';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import { MessageModal } from '../MessageModal';

function InvalidGuestCodeModal() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();

    const onCloseClicked = () => {
        dispatch(fromSubscriptionActions.Actions.hideModalGeneric('showInvalidGuestCodeModal'));
    };

    const { recordEvent } = useTracks();

    useEffect(() => {
        recordEvent('referral_used_screen_shown');
    }, [recordEvent]);

    return (
        <MessageModal
            title={formatMessage('modal-invalid-guest-pass-title')}
            message={formatMessage('modal-invalid-guest-pass-description')}
            onClose={onCloseClicked}
            image={<img alt="" width={112} height={112} src={PlusErrorIcon} />}
        />
    );
}

export default InvalidGuestCodeModal;
