import { PageTitle } from 'components/PageHeader/PageHeader.styled';
import { PopupMenuWrapper } from 'components/popup/PopupMenu/PopupMenu.styled';
import React from 'react';
import styled from 'styled-components';
import { gridSize, red50 } from '../../../styles';

export const FolderIcon = styled.span<{ color: string }>`
    background: ${props => props.color};
    color: #fff;
    border-radius: 50%;
    padding: 7px;
    margin-right: ${gridSize}px;
    svg {
        display: block;
    }
`;

export const OptionsHeaderWrapper = styled.section`
    display: flex;
    height: ${8 * gridSize}px;

    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
`;

export const OptionSpacer = styled.span`
    width: 1px;
    background: ${props => props.theme.legacyScss['theme-line']};
    padding: 20px 0;
    margin: 0 ${gridSize / 2}px;
`;

export const OptionWrapper = styled.div<{ $forceHighlight?: boolean }>`
    display: flex;
    align-self: stretch;
    align-items: stretch;
    padding: 4px;
    &:last-child {
        margin-right: -4px;
    }

    color: ${props => props.theme.legacyScss['theme-text-primary']};
    svg {
        display: block;
    }
    svg > path,
    svg > use {
        opacity: ${props => (props.$forceHighlight ? 0.7 : 0.35)};
        transition: opacity 0.1s ease;
    }

    & #always-opacity-1 {
        opacity: 1;
    }

    &:hover {
        svg > path,
        svg > use {
            opacity: 0.7;
        }
        & #always-opacity-1 {
            opacity: 1;
        }
    }

    ${PopupMenuWrapper} {
        height: 100%;
    }
`;

export const Title = styled(PageTitle)`
    margin-right: auto;
    padding-right: ${gridSize}px;
`;

export const badgeSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.17157 4H5.5C4.67157 4 4 4.67157 4 5.5V18.5C4 19.3284 4.67157 20 5.5 20H18.5C19.3284 20 20 19.3284 20 18.5V16.8284L18 14.8284V18H6V6H9.17157L7.17157 4Z"
            fill="currentColor"
        />
        <path d="M10 4H19C19.5523 4 20 4.44772 20 5V14L10 4Z" fill="currentColor" />
    </svg>
);

export const badgeRedSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.17157 4H5.5C4.67157 4 4 4.67157 4 5.5V18.5C4 19.3284 4.67157 20 5.5 20H18.5C19.3284 20 20 19.3284 20 18.5V16.8284L18 14.8284V18H6V6H9.17157L7.17157 4Z"
            fill="currentColor"
        />
        <path id="always-opacity-1" d="M10 4H19C19.5523 4 20 4.44772 20 5V14L10 4Z" fill={red50} />
    </svg>
);
