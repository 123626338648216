import DOMPurify from 'dompurify';
const allowedTags = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'blockquote',
    'dl',
    'dt',
    'dd',
    'hr',
    'ul',
    'ol',
    'li',
    'pre',
    'menu',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'u',
    'var',
    'wbr',
    'div',
];

export const sanitizeReadMoreHTML = (text: string) => {
    return DOMPurify.sanitize(text, {
        ALLOWED_TAGS: allowedTags,
        ALLOWED_ATTR: ['href', 'title', 'dir'],
    });
};
