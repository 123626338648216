import PodcastImage from 'components/PodcastImage';
import { PodcastDiscover } from 'model/types';
import React from 'react';
import { GridItem } from '../GridItem';
import { ListItem } from '../ListItem';

export type Props = {
    podcast: Pick<PodcastDiscover, 'uuid' | 'title' | 'author'>;
    onSubscribe?: () => void;
    onClick?: () => void;
    rank?: number;
    discoverFormat?: 'list' | 'grid';
};

const DiscoverPodcast = ({
    podcast,
    onClick,
    onSubscribe,
    rank,
    discoverFormat = 'grid',
}: Props) => {
    const Component = discoverFormat === 'list' ? ListItem : GridItem;

    return (
        <Component
            onClick={onClick}
            image={<PodcastImage uuid={podcast.uuid} title={podcast.title} />}
            title={podcast.title}
            subtitle={podcast.author}
            onSubscribe={onSubscribe}
            podcastUuid={podcast.uuid}
            rank={rank}
        />
    );
};

export default DiscoverPodcast;
