import React from 'react';
import { ContentWrapper, CredentialNotificationWrapper } from './CredentialNotification.styled';

export type Props = {
    children: React.ReactNode;
    className?: string;
    variant?: 'success' | 'warning';
    height?: number;
};

function CredentialNotification({ children, className, variant = 'warning', height = 100 }: Props) {
    return (
        <CredentialNotificationWrapper className={className} variant={variant} height={height}>
            <ContentWrapper>{children}</ContentWrapper>
        </CredentialNotificationWrapper>
    );
}

export default CredentialNotification;
