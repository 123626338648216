import { TrackOnMount } from 'components/Tracks';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { H40, H70 } from '../../../../styles/index';
import { ImageWrapper, Spaces, SubtitleWrapper } from './ThankYouModal.styled';
import ThankYouImage from './thank-you-image.svg';

type Props = {
    isWelcomeBack: boolean;
    source?: string;
    hideThankYouModal: () => void;
};

export class ThankYouModal extends Component<Props> {
    onCloseClicked = () => {
        this.props.hideThankYouModal();
    };

    renderTitle() {
        const { isWelcomeBack } = this.props;

        return isWelcomeBack ? (
            <H40>
                <FormattedMessage id="modal-thank-you-and-welcome-back" />
            </H40>
        ) : (
            <H40>
                <FormattedMessage id="modal-thank-you-and-welcome" />
            </H40>
        );
    }

    render() {
        const { isWelcomeBack, source } = this.props;

        return (
            <Modal horizontalInsetPx={32} onClose={this.onCloseClicked}>
                <TrackOnMount event="welcome_shown" properties={{ source }} />
                <ImageWrapper>
                    <img width={112} height={112} src={ThankYouImage} alt="" />
                </ImageWrapper>
                <Spaces $n={2} />
                {this.renderTitle()}
                <Spaces $n={2} />
                <H70>
                    <FormattedMessage id="modal-set-up-and-ready" />
                </H70>

                {!isWelcomeBack && (
                    <SubtitleWrapper>
                        <FormattedMessage id="modal-extra-mobile-features" />
                    </SubtitleWrapper>
                )}

                <Spaces $n={2} />

                <Button width={355} onClick={this.onCloseClicked}>
                    <FormattedMessage id="modal-lets-get-cracking" />
                </Button>
                <Spaces $n={4} />
            </Modal>
        );
    }
}

export default ThankYouModal;
