import { Button } from 'components/Button';
import Modal from 'components/Modal';
import { isWindowsApp } from 'helper/Browser';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { gridSize, visuallyHidden } from 'styles';
import { patronColor, plusColor } from 'styles/colors';
import { SubscriptionTier } from '../../model/types';

export const Wrapper = styled.section<{ $variant?: 'mini' | 'regular'; $isPatron: boolean }>`
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    background: ${props => props.theme.legacyScss['theme-background']};
    gap: ${3 * gridSize}px;

    box-shadow:
        0px 11px 4px rgba(0, 0, 0, 0.01),
        0px 6px 4px rgba(0, 0, 0, 0.05),
        0px 3px 3px rgba(0, 0, 0, 0.09),
        0px 1px 1px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    border-radius: 24px;
    position: relative;
    padding: ${4 * gridSize}px ${3 * gridSize}px;
    border: 1px solid ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.12)};
    width: calc(100vw - ${2 * gridSize}px);
    max-width: 500px;

    @media (min-width: 768px) {
        width: 100%;
    }

    ${props =>
        props.$variant === 'mini' &&
        css`
            align-items: center;
            justify-content: space-between;
            padding: ${3 * gridSize}px;
            border-radius: 0;
            border: none;
            ${PlanBadgeWrapper} {
                order: 0;
            }
            ${FreeTrialBadge} {
                order: 1;
            }
            ${Price} {
                order: 2;
            }
            ${TextButton} {
                order: 3;
            }
            ${FlexLineBreak} {
                display: none;
            }
        `}
`;

export const PlanBadgeWrapper = styled.span`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 10px;
`;

export const Price = styled.div<{ $variant: 'mini' | 'regular' }>`
    font-weight: 400;
    font-size: 14px;
    color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
    display: flex;
    align-items: baseline;
    gap: 6px;

    ${isWindowsApp() &&
    css`
        & > *:not(:last-child) {
            margin-right: 6px;
        }
    `}

    strong {
        font-size: 24px;
        font-weight: 700;
        color: ${props => props.theme.legacyScss['theme-text-primary']};
    }
    ${props =>
        props.$variant === 'regular' &&
        css`
            font-size: 16px;
            strong {
                font-size: 32px;
            }
        `}
`;

export const DaysFreeWrapper = styled.span`
    text-transform: lowercase;
`;

export const TextButton = styled(Button)`
    font-weight: 400;
    font-size: 16px;
    color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.8)};
`;

export const FreeTrialBadge = styled.span<{ $tier: SubscriptionTier }>`
    color: ${props => (props.$tier === 'Patron' ? '#fff' : '#000')};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    padding: ${gridSize}px ${1.5 * gridSize}px;
    background-color: ${props => (props.$tier === 'Patron' ? patronColor : plusColor)};
    border-radius: 100px;
    display: inline-block;
`;

export const PlanPanelDiscount = styled.div`
    margin-top: ${-1 * gridSize}px;
    margin-bottom: ${2 * gridSize}px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`;

export const FlexLineBreak = styled.div`
    flex-basis: 100%;
    height: 0;
`;

export const SplitPlanWrapper = styled.div`
    display: flex;
    gap: ${5 * gridSize}px;
    flex-direction: column;
    align-items: center;
`;

export const SplitPlans = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${3 * gridSize}px;

    ${Wrapper} {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: ${3 * gridSize}px;
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        ${Price} strong {
            font-size: 48px;
        }
    }
`;

export const IntervalSelector = styled.fieldset`
    background: ${props => rgba(props.theme.tokens['primary-text-01'], 0.08)};
    font-weight: 500;
    font-size: 16px;
    padding: 4px;
    border-radius: 100px;
    input {
        ${visuallyHidden}
    }
`;

export const Interval = styled.span`
    display: inline-block;
    padding: ${gridSize}px ${2 * gridSize}px;
    border-radius: 100px;
    cursor: pointer;
    input:checked + & {
        background: ${props => props.theme.tokens['primary-text-01']};
        color: ${props => props.theme.legacyScss['theme-background']};
        box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1);
    }
    input:focus-visible + & {
        outline: 2px solid ${props => props.theme.tokens['primary-interactive-01']};
    }
`;

export const FullPriceStrikethrough = styled.span`
    text-decoration: line-through;
    color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
    text-decoration-color: ${props => rgba(props.theme.legacyScss['theme-text-primary'], 0.6)};
`;

export const PriceRowContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

export const PlanSelectorItemsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px 10px;
    margin-right: 10px;
    flex-wrap: wrap;
    ${isWindowsApp() &&
    css`
        & > *:not(:last-child) {
            margin-right: 10px;
        }
    `}
`;

export const PlanSelectorTermsWrapper = styled.div`
    padding-top: ${3 * gridSize}px;
`;

export const SplitPlanSelectorFooter = styled.div`
    width: 100%;
    margin-top: auto;
`;

export const DownloadModal = styled(Modal)`
    max-width: 440px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    img {
        display: block;
        width: 100%;
        max-width: 172px;
        margin: 0 auto ${3 * gridSize}px;
    }
`;
