import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { Typography } from '../../../../styles';

export const CardBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 5px;
    background: #ffffff;
    z-index: 0;
    opacity: 0.06;
`;

export const EpisodeTitle = styled.p`
    ${Typography.Mobile['H70-B']};

    color: #ffffff;
    cursor: pointer;
    text-decoration: none;

    max-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;

    opacity: 0.8;
    &:hover {
        opacity: 1;
    }

    transition: all 0.1s ease;
    span {
        cursor: pointer;
    }
`;

export const EpisodeAuthor = styled.p`
    ${Typography.Mobile['H70-B']};
    color: #ffffff;
    opacity: 0.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        opacity: 1;
    }
`;

export const EpisodeLink = styled(Link)`
    ${Typography.Mobile['H70-B']};
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.1s ease;
`;

export const EpisodeTextWrapper = styled.div`
    position: absolute;
    top: 12px;
    left: 77px;
    right: 12px;
    height: 57px;
    text-align: left;
    .author {
        span {
            cursor: pointer;
        }
    }
`;

export const PlayButtonHover = css``;

export const PlayEpisodeButton = styled.button`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 57px;
    svg {
        display: none;
        position: absolute;
        box-sizing: content-box;
        padding: 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
`;

export const RecommendEpisodeWrapper = styled.div`
    position: relative;
    margin-right: 12px;
    height: 77px;
    width: 280px;
    @media screen and (max-width: 1200px) {
        width: 230px;
    }
    &:hover {
        ${PlayEpisodeButton} svg {
            display: block;
        }
    }
`;
