import { DiscoverPodcast, DiscoverSection, DiscoverSingle, Header } from 'components';

import OnMount from 'components/OnMount/OnMount';
import { TrackOnMount } from 'components/Tracks';
import { DiscoverCategoriesNav } from 'components/discover/DiscoverCategoriesNav';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import {
    DiscoverCategory,
    DiscoverLayout,
    DiscoverSummaryStyle,
    ListData,
    Podcast,
    PodcastDiscover,
} from 'model/types';
import { PodcastListPageWrapper } from 'pages/PodcastListPage/PodcastListPage.styled';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { LoaderSquare } from '../../components/LoaderSquare';
import { useAnalyticsContext } from '../../context/AnalyticsContext';
import { NavigationItems } from '../../helper/NavigationHelper';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import * as fromDiscoverActions from '../../redux/actions/discover.actions';
import { getDiscoverCategoryPageDetails } from '../../redux/reducers/selectors';

export function CategoryPage() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const {
        discoverListImpressionEvent,
        discoverPodcastTapEvent,
        discoverPodcastSubscribeEvent,
        discoverCategoryPageOpen,
        discoverCategoryOpen,
    } = useAnalyticsContext();

    const { list, title, listId, ranked, discoverContentJsonLayout, category, region } =
        useSelector(state => getDiscoverCategoryPageDetails(state, params.categoryId));

    const isLoading = discoverContentJsonLayout == null || title == null || region == null;

    const [contentMounted, setContentMounted] = React.useState(false);

    const isReady = contentMounted && !isLoading;

    useScrollRestoration(isReady);

    const { recordEvent } = useTracks();

    const categories =
        discoverContentJsonLayout &&
        (discoverContentJsonLayout?.find((layout: DiscoverLayout) => layout.type === 'categories')
            ?.data as DiscoverCategory[]);

    const hasPodcasts = list?.podcasts?.length && list.podcasts.length > 0;

    const categoryPlacement = discoverContentJsonLayout?.find(
        (layout: DiscoverLayout) =>
            layout.summary_style === DiscoverSummaryStyle.SINGLE_PODCAST &&
            layout.category_id === category?.id,
    );

    const categoryPlacementData = categoryPlacement?.data as ListData;

    const categoryPlacementPodcast = categoryPlacement
        ? categoryPlacementData?.podcasts?.[0]
        : null;

    const categoryPlacementCuratedListId = categoryPlacement?.curated
        ? categoryPlacement?.id
        : undefined;

    useEffect(() => {
        // We have to do this check because a user can load a List without having gone through
        // the discover page, but we also don't want to load the discover content on every page.
        if (region && !discoverContentJsonLayout) {
            dispatch(fromDiscoverActions.Actions.getDiscoverContent());
        }
    }, [region, discoverContentJsonLayout, dispatch]);

    useEffect(() => {
        if (discoverContentJsonLayout != null && params.categoryId) {
            dispatch(fromDiscoverActions.Actions.openCategoryList(params.categoryId));
        }
    }, [discoverContentJsonLayout, dispatch, params.categoryId]);

    useEffect(() => {
        if (!isLoading && categoryPlacementCuratedListId) {
            discoverListImpressionEvent(categoryPlacementCuratedListId);
        }
    }, [isLoading, categoryPlacementCuratedListId, discoverListImpressionEvent]);

    useEffect(() => {
        if (!isLoading && region && category) {
            const categoryId = category.id;
            discoverCategoryOpen(categoryId, region);
            discoverCategoryPageOpen(categoryId);
        }
    }, [isLoading, region, category, discoverCategoryPageOpen, discoverCategoryOpen]);

    const podcastClicked = (podcast: { uuid: string }) => {
        discoverPodcastTapEvent(categoryPlacementCuratedListId, podcast.uuid);

        navigate(`${NavigationItems.DISCOVER.path}/podcast/${podcast.uuid}`, {
            state: {
                curatedListId: categoryPlacementCuratedListId,
            },
        });
    };

    const categoryClicked = useCallback((categoryId: number) => {
        navigate(`${NavigationItems.DISCOVER.path}/category/${categoryId}`);
    }, []);

    const categoryClearSelectionClicked = useCallback(() => {
        navigate(NavigationItems.DISCOVER.path);
    }, []);

    const recordSubscribeEvent = useCallback(
        (podcast: PodcastDiscover, curatedListId?: string) => {
            if (curatedListId) {
                discoverPodcastSubscribeEvent(curatedListId, podcast.uuid);
            }
        },
        [discoverPodcastSubscribeEvent],
    );

    if (!category || !categories) {
        return null;
    }

    return (
        <PodcastListPageWrapper>
            <TrackOnMount
                event="discover_category_shown"
                properties={{ id: category.id, name: title ?? '', region: region ?? '' }}
            />
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Header title={formatMessage('discover')} />
            <DiscoverCategoriesNav
                categories={categories}
                onCategoryClicked={categoryClicked}
                selectedCategory={category.id}
                onClearSelection={categoryClearSelectionClicked}
            />
            {isLoading && <LoaderSquare />}
            {!isLoading && hasPodcasts && (
                <>
                    <DiscoverSection
                        discoverFormat="grid"
                        minWidth={152}
                        renderPlacement={
                            categoryPlacementPodcast && (
                                <DiscoverSingle
                                    description={categoryPlacementData.description}
                                    podcast={categoryPlacementPodcast}
                                    sponsored={true}
                                    onClick={() => {
                                        recordEvent('discover_ad_category_tapped', {
                                            id: category.id,
                                            name: category.title,
                                            region,
                                            podcast_id: categoryPlacementPodcast.uuid,
                                        });
                                        podcastClicked(categoryPlacementPodcast);
                                    }}
                                    onSubscribe={() => {
                                        recordEvent('discover_ad_category_subscribed', {
                                            id: category.id,
                                            name: category.title,
                                            region,
                                            podcast_id: categoryPlacementPodcast.uuid,
                                        });
                                        recordSubscribeEvent(
                                            categoryPlacementPodcast,
                                            categoryPlacementCuratedListId,
                                        );
                                    }}
                                />
                            )
                        }
                        title={formatMessage('most-popular-in-category', {
                            category: category?.title,
                        })}
                    >
                        {list.podcasts?.map((podcast: Podcast, index: number) => (
                            <DiscoverPodcast
                                key={podcast.uuid}
                                discoverFormat="grid"
                                rank={ranked ? index + 1 : undefined}
                                podcast={podcast}
                                onClick={() => podcastClicked(podcast)}
                                onSubscribe={() =>
                                    discoverPodcastSubscribeEvent(listId, podcast.uuid)
                                }
                            />
                        ))}
                    </DiscoverSection>
                    <OnMount callback={() => setContentMounted(true)} />
                </>
            )}
        </PodcastListPageWrapper>
    );
}

export default CategoryPage;
