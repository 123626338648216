import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { useDispatch, useSelector } from '../../../../hooks/react-redux-typed';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import { RootState } from '../../../../redux/reducers';
import { MessageModal } from '../MessageModal';
import SetupAccountImage from './setup-account.svg';

function AlreadyPlusSubscriberModal() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const isPromo = useSelector((state: RootState) => {
        return state.subscription.modalData.showAlreadyPlusSubscriberModal?.promo === true;
    });

    const onCloseClicked = () => {
        dispatch(
            fromSubscriptionActions.Actions.hideModalGeneric('showAlreadyPlusSubscriberModal'),
        );
    };

    return (
        <MessageModal
            title={formatMessage('modal-already-plus-title')}
            message={
                isPromo
                    ? formatMessage('modal-already-plus-message-promo')
                    : formatMessage('modal-already-plus-message')
            }
            onClose={onCloseClicked}
            image={<img alt="" width={112} height={112} src={SetupAccountImage} />}
        ></MessageModal>
    );
}

export default AlreadyPlusSubscriberModal;
