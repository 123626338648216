import React, { ForwardedRef, ReactElement, forwardRef, useEffect, useState } from 'react';
import { isImageCached } from '../../helper/ImageHelper';
import {
    Img,
    MissingArtworkAlt,
    MissingArtworkImg,
    PlaceholderImage,
    Wrapper,
} from './Image.styled';

type Props = {
    src: string | undefined;
    alt?: string | ReactElement;
    borderRadius?: number;
    className?: string;
    placeholder?: JSX.Element;
};

enum LOAD_STATE {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
}

const Image = forwardRef(
    (
        { src, borderRadius, alt, className, placeholder }: Props,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const [loadState, setLoadState] = useState<LOAD_STATE>(LOAD_STATE.LOADING);

        // If src changes and the image has errored, let the image re-fetch
        useEffect(() => {
            if (!src) {
                return;
            }
            if (isImageCached(src)) {
                setLoadState(LOAD_STATE.LOADED);
            } else {
                setLoadState(LOAD_STATE.LOADING);
            }
        }, [src]);

        const handleImageLoad = () => {
            setLoadState(LOAD_STATE.LOADED);
        };

        const onError = () => {
            setLoadState(LOAD_STATE.ERROR);
        };

        return (
            <Wrapper className={className} $borderRadius={borderRadius} ref={ref}>
                {placeholder && (
                    <PlaceholderImage $isLoading={loadState === LOAD_STATE.LOADING}>
                        {placeholder}
                    </PlaceholderImage>
                )}

                {src && (
                    <Img
                        src={src}
                        $isLoaded={loadState === LOAD_STATE.LOADED || isImageCached(src)}
                        onError={onError}
                        onLoad={handleImageLoad}
                        alt=""
                    />
                )}

                {loadState === LOAD_STATE.ERROR && (
                    <MissingArtworkImg>
                        {alt && <MissingArtworkAlt>{alt}</MissingArtworkAlt>}
                    </MissingArtworkImg>
                )}
            </Wrapper>
        );
    },
);

export default Image;
