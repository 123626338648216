import { Icon } from 'components/Icon';
import { Link } from 'react-router';
import styled from 'styled-components';
import {
    MOBILE_WIDTH,
    PAGE_MAX_WIDTH,
    PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION,
} from '../../../model/page';
import {
    Typography,
    black,
    coolGrey50,
    darkGrey10,
    darkGrey30,
    gridSize,
    red50,
    white,
} from '../../../styles';

export const NavigationWrapper = styled.nav<{ $isVisible?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: ${2 * gridSize}px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${props => props.theme.legacyScss['theme-background']};
    box-shadow: inset -1px 0 0 ${props => props.theme.legacyScss['theme-line']};

    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) and (max-width: ${PAGE_MAX_WIDTH}px) {
        box-shadow: none;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        position: absolute;
        width: 100%;
        z-index: 250;
        opacity: ${props => (props.$isVisible ? 1 : 0)};
        visibility: ${props => (props.$isVisible ? 'visible' : 'hidden')};
        transition:
            opacity 0.2s ease-in-out,
            visibility 0.2s ease-in-out;

        .profile-menu {
            top: 0;
            bottom: 0;
            left: auto;
            right: 0;
            width: 100%;
            height: 100%;
            box-shadow: none;
            padding: 0;
            margin-top: 65px;
            border-radius: 0;
            transform: translateX(100%);
            animation: slideIn 0.3s forwards;

            &.exiting {
                animation: slideOut 0.3s forwards;
            }

            @keyframes slideIn {
                from {
                    transform: translateX(100%);
                }
                to {
                    transform: translateX(0);
                }
            }

            @keyframes slideOut {
                from {
                    transform: translateX(0);
                }
                to {
                    transform: translateX(100%);
                }
            }

            .separator {
                border: none;
            }
        }
    }
`;

export const Spaces = styled.div<{ $n?: number }>`
    height: ${props => gridSize * (props.$n || 1)}px;
`;

export const NavigationLinkWrapper = styled.div<{ $selected: boolean }>`
    height: ${6 * gridSize}px;
    /* width: calc(${10 * gridSize}px - 8px); */
    width: ${10 * gridSize}px;
    display: flex;
    padding: 0 ${0.5 * gridSize}px;

    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) or (max-width: ${MOBILE_WIDTH}px) {
        width: ${30 * gridSize}px;
        justify-content: flex-start;
        transition: transform 0.1s ease-in-out;
        transform: translateX(${props => (props.$selected ? gridSize : 0)}px);
        &:hover {
            transform: translateX(${gridSize}px);
        }
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 0;
        transform: translateX(0);
    }
`;

export const NavigationLink = styled(Link)<{ $selected: boolean }>`
    ${props => (props.$selected ? Typography.Desktop['H60-B'] : Typography.Desktop.H60)}
    line-height: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    color: ${props =>
        props.$selected ? (props.theme.name === 'dark' ? white : darkGrey30) : coolGrey50};

    &:hover {
        color: ${red50};
        cursor: pointer;
    }

    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) or (max-width: ${MOBILE_WIDTH}px) {
        justify-content: flex-start;
        width: calc(100% - 8px);
        color: ${props => {
            if (props.theme.name === 'dark') {
                return props.$selected ? white : coolGrey50;
            }
            return props.$selected ? black : darkGrey10;
        }} !important;
    }
`;

export const LinkIcon = styled(Icon)`
    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) or (max-width: ${MOBILE_WIDTH}px) {
        width: 26px;
        height: 26px;
        margin-left: ${2.5 * gridSize}px;
        margin-right: ${2 * gridSize}px;
    }
`;

export const LinkNotch = styled.svg<{ $selected: boolean }>`
    position: absolute;
    right: 100%;
    fill: ${props => (props.$selected ? red50 : coolGrey50)};

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const LinkText = styled.span`
    height: 0.9em;
    display: none;
    @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) or (max-width: ${MOBILE_WIDTH}px) {
        display: block;
    }
`;

export const ScrollingCover = styled.div`
    position: sticky;
    width: calc(100% - 1px); // Leave pixel on right so we don't cover the border line
    height: ${8 * gridSize}px;
    margin-top: -${8 * gridSize}px;
    background: blue;
    top: 0;
    left: 0;
    z-index: 1;
    background: ${props => props.theme.legacyScss['theme-background']};
    opacity: 0.8;
`;
