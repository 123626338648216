import { TodoFixmeMigrationType } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    FILE_ADD_TO_UPLOAD_MANAGER = '@upload-manager/FILE_ADD_TO_UPLOAD_MANAGER',
    FILE_REMOVE_FROM_UPLOAD_MANAGER = '@upload-manager/FILE_REMOVE_FROM_UPLOAD_MANAGER',

    FILE_ABORT_IN_UPLOAD_MANAGER = '@upload-manager/FILE_ABORT_IN_UPLOAD_MANAGER',

    FILE_UPDATE_IN_UPLOAD_MANAGER = '@upload-manager/FILE_UPDATE_IN_UPLOAD_MANAGER',
    FILE_COMPLETE_IN_UPLOAD_MANAGER = '@upload-manager/FILE_COMPLETE_IN_UPLOAD_MANAGER',
    FILE_FAIL_IN_UPLOAD_MANAGER = '@upload-manager/FILE_FAIL_IN_UPLOAD_MANAGER',

    FILE_UPLOAD_MANAGER_CLEAR = '@upload-manager/FILE_UPLOAD_MANAGER_CLEAR',

    IMAGE_ADD_TO_UPLOAD_MANAGER = '@upload-manager/IMAGE_ADD_TO_UPLOAD_MANAGER',
    IMAGE_REMOVE_FROM_UPLOAD_MANAGER = '@upload-manager/IMAGE_REMOVE_FROM_UPLOAD_MANAGER',

    IMAGE_ABORT_IN_UPLOAD_MANAGER = '@upload-manager/IMAGE_ABORT_IN_UPLOAD_MANAGER',
    IMAGE_UPDATE_IN_UPLOAD_MANAGER = '@upload-manager/IMAGE_UPDATE_IN_UPLOAD_MANAGER',
    IMAGE_COMPLETE_IN_UPLOAD_MANAGER = '@upload-manager/IMAGE_COMPLETE_IN_UPLOAD_MANAGER',
    IMAGE_FAIL_IN_UPLOAD_MANAGER = '@upload-manager/IMAGE_FAIL_IN_UPLOAD_MANAGER',
}

export const Actions = {
    addFileToUploadManager: (
        file: TodoFixmeMigrationType,
        uuid: string,
        url: string,
        errorIfPresent?: string,
    ) =>
        createAction(ActionTypes.FILE_ADD_TO_UPLOAD_MANAGER, {
            file,
            uuid,
            url,
            errorIfPresent,
        }),

    removeFileFromUploadManager: (uuid: string) =>
        createAction(ActionTypes.FILE_REMOVE_FROM_UPLOAD_MANAGER, {
            uuid,
        }),

    abortFileInUploadManager: (uuid: string) =>
        createAction(ActionTypes.FILE_ABORT_IN_UPLOAD_MANAGER, {
            uuid,
        }),

    updateFileInUploadManager: (
        uuid: string,
        progress: number,
        total: number,
        error: string,
        xhr: TodoFixmeMigrationType,
    ) =>
        createAction(ActionTypes.FILE_UPDATE_IN_UPLOAD_MANAGER, {
            uuid,
            progress,
            total,
            error,
            xhr,
        }),

    completeFileInUploadManager: (uuid: string) =>
        createAction(ActionTypes.FILE_COMPLETE_IN_UPLOAD_MANAGER, {
            uuid,
        }),

    // In some cases the error will already be set in state,
    // only provide an error here if new information is available.
    failFileInUploadManager: (uuid: string, error?: string) =>
        createAction(ActionTypes.FILE_FAIL_IN_UPLOAD_MANAGER, {
            uuid,
            error,
        }),

    clearFilesFromUploadManager: () => createAction(ActionTypes.FILE_UPLOAD_MANAGER_CLEAR),

    addImageToUploadManager: (file: TodoFixmeMigrationType, uuid: string, url: string | null) =>
        createAction(ActionTypes.IMAGE_ADD_TO_UPLOAD_MANAGER, {
            file,
            uuid,
            url,
        }),

    removeImageFromUploadManager: (uuid: string) =>
        createAction(ActionTypes.IMAGE_REMOVE_FROM_UPLOAD_MANAGER, {
            uuid,
        }),

    abortImageInUploadManager: (uuid: string) =>
        createAction(ActionTypes.IMAGE_ABORT_IN_UPLOAD_MANAGER, {
            uuid,
        }),

    updateImageInUploadManager: (
        uuid: string,
        progress: number,
        total: number,
        error: string,
        xhr: TodoFixmeMigrationType,
    ) =>
        createAction(ActionTypes.IMAGE_UPDATE_IN_UPLOAD_MANAGER, {
            uuid,
            progress,
            total,
            error,
            xhr,
        }),

    completeImageInUploadManager: (uuid: string) =>
        createAction(ActionTypes.IMAGE_COMPLETE_IN_UPLOAD_MANAGER, {
            uuid,
        }),

    failImageInUploadManager: (uuid: string) =>
        createAction(ActionTypes.IMAGE_FAIL_IN_UPLOAD_MANAGER, {
            uuid,
        }),
};

export type Actions = ActionsUnion<typeof Actions>;
