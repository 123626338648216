import { CategoryLinks } from 'components/CategoryLinks';
import { Icon } from 'components/Icon';
import { SortOptions } from 'components/SortOptions';
import { StarRating } from 'components/StarRating';
import { TextWithIcon } from 'components/TextWithIcon';
import { TrackOnMount } from 'components/Tracks';
import { EpisodeCount } from 'components/messages';
import * as PodcastHelper from 'helper/PodcastHelper';
import { EpisodeSortOrder } from 'helper/PodcastHelper';
import {
    Bookmark,
    EpisodeAndPodcastUuidsArray,
    EpisodeSyncInfo,
    EpisodeWithSyncInfo,
    PlayerState,
    PodcastCacheParsed,
    PodcastRating,
    TracksProperties,
    UpNextState,
} from 'model/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { USE_DISABLE_PRIVATE_FEED_SHARING, USE_GIVE_RATINGS } from 'settings';
import EpisodesTable from '../../components/EpisodesTable';
import FeatureLock from '../../components/FeatureLock';
import { LoaderSquare } from '../../components/LoaderSquare';
import {
    FriendlyUrl,
    Loader,
    LoaderRound,
    NextEpisodeText,
    PodcastImage,
} from '../../components/index';
import HideOnMobile from '../../components/responsive/HideOnMobile';
import { withAnalyticsContext } from '../../context/AnalyticsContext';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import * as fromEpisodeActions from '../../redux/actions/episode.actions';
import { RootState } from '../../redux/reducers';
import { USE_FOCUS_LISTENER } from '../../settings';
import urls from '../../urls';
import { EpisodesOptions } from './EpisodesOptions';
import { PodcastActions } from './PodcastActions';
import {
    BookmarksButton,
    EpisodeRows,
    EpisodeSummary,
    NextEpisodeDetails,
    PagePodcast,
    PodcastActionsWrapper,
    PodcastAuthorSection,
    PodcastBlurredImage,
    PodcastDetails,
    PodcastHeader,
    PodcastImageWrapper,
    PodcastInformation,
    PodcastInlineActionsWrapper,
    PodcastLink,
    PodcastMeta,
    PodcastTitle,
    RateButton,
    ReadMoreText,
    SearchBox,
    SearchBoxMobile,
    StyledShareButton,
    TitleAndActions,
} from './PodcastPage.styled';

export type Props = {
    uuid: string;
    loadFailed: boolean;
    isLoadingEpisodes: boolean;
    episodeSortOrder: number;
    noEpisodes: boolean;
    showArchived: boolean;
    isSubscribed: boolean;
    episodeCount: number;
    archivedCount: number;
    allArchived: boolean;
    resetJustPurchasedPodcast: () => void;
    anyPlayedButNotArchived: boolean;
    hideNextEpisodeDetails: boolean;
    isSearching: boolean;
    uuidToEpisodeSync: Record<string, EpisodeSyncInfo>;
    episodes: EpisodeWithSyncInfo[];
    allEpisodes: EpisodeWithSyncInfo[];
    theme: number;
    podcast: PodcastCacheParsed;
    upNext: UpNextState;
    player: PlayerState;
    color: string;
    searchTerm: string;
    bookmarks: Bookmark[];
    rating?: PodcastRating | null;
    openPodcast: (uuid: string) => void;
    unsubscribeFromPodcast: (uuid: string) => void;
    openPodcastShare: (uuid: string) => void;
    recordEvent: (event: string, properties?: TracksProperties) => void;
    searchEpisodes: (term: string, uuid: string) => void;
    updateEpisodeOrder: (uuid: string, orderId: number) => void;
    showArchivedEpisodes: (uuid: string, showArchived: boolean) => void;
    showArchiveAllConfirmation: (
        episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray,
        show?: boolean,
    ) => void;
    unarchiveAll: (episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray) => void;
    openEpisode: (...args: Parameters<typeof fromEpisodeActions.Actions.openEpisode>) => void;
    discoverPodcastPageSubscribeEvent: (uuid: string) => void;
    subscribeToPodcast: (podcast: PodcastCacheParsed) => void;
    openPodcastEvent: (uuid: string) => void;
    fetchPodcastRating: (uuid: string) => void;
    openBookmarks: (uuid: string) => void;
    openRatingModal: (uuid: string) => void;
    params: RouteParams;
    navigate: ReturnType<typeof useNavigate>;
    addFlag: (message: string) => void;
    clearJustSignedUp: () => void;
    refreshEpisodeList: (uuid: string, lastEpisodeUuid: string) => void;
} & WrappedComponentProps;

type RouteParams = {
    episode?: string;
    uuid: string;
};

const PodcastPage: React.FC<Props> = props => {
    const {
        uuid,
        loadFailed,
        isLoadingEpisodes,
        episodeSortOrder,
        noEpisodes,
        showArchived,
        isSubscribed,
        episodeCount,
        archivedCount,
        allArchived,
        anyPlayedButNotArchived,
        uuidToEpisodeSync,
        episodes,
        allEpisodes,
        podcast,
        upNext,
        player,
        color,
        bookmarks,
        rating,
        openPodcast,
        unsubscribeFromPodcast,
        openPodcastShare,
        recordEvent,
        searchEpisodes,
        updateEpisodeOrder,
        showArchivedEpisodes,
        showArchiveAllConfirmation,
        unarchiveAll,
        discoverPodcastPageSubscribeEvent,
        subscribeToPodcast,
        openPodcastEvent,
        fetchPodcastRating,
        openBookmarks,
        addFlag,
        clearJustSignedUp,
        refreshEpisodeList,
        intl,
    } = props;

    const params = useParams<RouteParams>();
    const navigate = useNavigate();

    const [isReadyToScroll, setIsReadyToScroll] = useState(false);
    useScrollRestoration(isReadyToScroll);

    const [searchTerm, setSearchTerm] = useState('');
    const [showedEpisodeModalForSharedEpisode, setShowedEpisodeModalForSharedEpisode] =
        useState(false);

    const maybeFetchRating = useCallback(() => {
        if (rating === undefined) {
            fetchPodcastRating(uuid);
        }
    }, [fetchPodcastRating, rating, uuid]);

    const clearSearch = useCallback(() => {
        searchEpisodes('', uuid);
        setSearchTerm('');
    }, [searchEpisodes, uuid]);

    useEffect(() => {
        openPodcast(uuid);
        openPodcastEvent(uuid);
        maybeFetchRating();

        const escFunction = (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                clearSearch();
            }
        };

        const windowFocus = () => {
            if (USE_FOCUS_LISTENER) {
                openPodcast(uuid);
            }
        };

        document.addEventListener('keydown', escFunction);
        window.addEventListener('focus', windowFocus);
        clearJustSignedUp();

        return () => {
            clearSearch();
            document.removeEventListener('keydown', escFunction);
            window.removeEventListener('focus', windowFocus);
        };
    }, [clearJustSignedUp, clearSearch, maybeFetchRating, openPodcast, openPodcastEvent, uuid]);

    const onEpisodeClick = useCallback(
        (episode: any) => {
            navigate(urls.episodePath(podcast.uuid, episode.uuid));
        },
        [navigate, podcast],
    );

    useEffect(() => {
        if (params.episode && !showedEpisodeModalForSharedEpisode) {
            if (!isLoadingEpisodes) {
                const sharedEpisode = allEpisodes.find(ep => ep.uuid === params.episode);
                if (sharedEpisode) {
                    onEpisodeClick(sharedEpisode);
                    setShowedEpisodeModalForSharedEpisode(true);
                }
            }
        }
    }, [
        params.episode,
        isLoadingEpisodes,
        showedEpisodeModalForSharedEpisode,
        allEpisodes,
        onEpisodeClick,
    ]);

    const searchInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTerm = event.target.value.trim();
        if (newTerm.length !== 1) {
            searchEpisodes(newTerm, uuid);
        }
        setSearchTerm(event.target.value);
    };

    const renderSearchIcon = () => {
        if (props.isSearching || isLoadingEpisodes) {
            return (
                <div className="loading-icon-wrapper">
                    <LoaderRound />
                </div>
            );
        }
        if (searchTerm) {
            return (
                <button id="clear-search-bar" onClick={clearSearch}>
                    <Icon id="cancel" />
                </button>
            );
        }
        return (
            <div className="icon-search-episodes">
                <Icon id="search" />
            </div>
        );
    };

    const emptyMessage = () => {
        if (isLoadingEpisodes) {
            return '';
        }
        if (loadFailed) {
            return <FormattedMessage id="episodes-load-error" />;
        }
        if (!noEpisodes && !showArchived && !searchTerm) {
            return <FormattedMessage id="all-episodes-listened" />;
        }
        return <FormattedMessage id="no-episodes-found" />;
    };

    const episodesToShow = useMemo(
        () => (showArchived ? episodes : episodes.filter(ep => !ep.isDeleted)),
        [episodes, showArchived],
    );

    useEffect(() => {
        setIsReadyToScroll(episodesToShow.length > 0);
    }, [episodesToShow]);

    return (
        <PagePodcast>
            <TrackOnMount event="podcast_screen_shown" />
            <Helmet>
                <title>{podcast ? podcast.title : ''}</title>
            </Helmet>
            {loadFailed && <p>{<FormattedMessage id="podcast-load-error" />}</p>}
            {!podcast && !loadFailed && <LoaderSquare />}
            {podcast && (
                <>
                    {isLoadingEpisodes && !episodesToShow && <Loader color={color} />}
                    <PodcastPageHeader
                        {...props}
                        subscribeClick={() => {
                            if (isSubscribed) {
                                unsubscribeFromPodcast(podcast.uuid);
                            } else {
                                discoverPodcastPageSubscribeEvent(podcast.uuid);
                                subscribeToPodcast(podcast);
                            }
                        }}
                        onShare={() => {
                            const isPrivate = podcast.isPrivate || podcast.is_private || false;
                            recordEvent('podcast_screen_share_tapped', {
                                podcast_uuid: podcast.uuid,
                                is_private: isPrivate,
                            });
                            if (USE_DISABLE_PRIVATE_FEED_SHARING && isPrivate) {
                                addFlag(
                                    intl.formatMessage({
                                        id: 'sharing-disabled-for-private-feeds',
                                    }),
                                );
                                return;
                            }
                            openPodcastShare(podcast.uuid);
                        }}
                    />
                    <EpisodeSummary>
                        <EpisodeCount count={episodeCount} />
                        &nbsp;&nbsp;•&nbsp;&nbsp;
                        <FormattedMessage
                            id="archived-count"
                            values={{
                                count: archivedCount,
                            }}
                        />
                        &nbsp;&nbsp;•&nbsp;&nbsp;
                        <FeatureLock
                            style={{ display: 'inline' }}
                            source="bookmarks"
                            title={<FormattedMessage id="feature-bookmarks-unavailable-title" />}
                            description={
                                <FormattedMessage id="feature-bookmarks-unavailable-description" />
                            }
                            requires="subscription"
                        >
                            <BookmarksButton
                                kind="text"
                                onClick={() => {
                                    recordEvent('podcast_screen_bookmarks_count_tapped', {
                                        count: bookmarks.length,
                                    });
                                    openBookmarks?.(uuid);
                                }}
                                color={color}
                            >
                                <FormattedMessage
                                    id={
                                        bookmarks.length > 1
                                            ? 'bookmark-count-plural'
                                            : bookmarks.length === 1
                                              ? 'bookmark-count-singular'
                                              : 'bookmarks'
                                    }
                                    values={{
                                        count: bookmarks.length,
                                    }}
                                />
                            </BookmarksButton>
                        </FeatureLock>
                    </EpisodeSummary>
                    <EpisodeRows>
                        <SearchBox>
                            <SearchBoxMobile>
                                {renderSearchIcon()}
                                <input
                                    className="episode-search-input"
                                    placeholder={
                                        isLoadingEpisodes
                                            ? intl.formatMessage({ id: 'loading-episodes' })
                                            : intl.formatMessage({ id: 'search-episodes' })
                                    }
                                    value={searchTerm}
                                    onChange={searchInputChanged}
                                    spellCheck="false"
                                />
                            </SearchBoxMobile>
                            <SortOptions
                                id="sort-options-search-bar"
                                color={color}
                                onSelect={orderId => {
                                    recordEvent('podcasts_screen_sort_order_changed', {
                                        sort_order:
                                            Object.entries(EpisodeSortOrder)
                                                .filter(([, value]) => value === orderId)[0][0]
                                                .toLowerCase() ?? 'unknown',
                                    });
                                    updateEpisodeOrder(uuid, orderId);
                                }}
                                selectedOption={episodeSortOrder}
                                options={[
                                    {
                                        id: EpisodeSortOrder.NAME_A_TO_Z,
                                        label: intl.formatMessage({ id: 'alphabetical' }),
                                    },
                                    {
                                        id: EpisodeSortOrder.NAME_Z_TO_A,
                                        label: intl.formatMessage({
                                            id: 'alphabetical-reversed',
                                        }),
                                    },
                                    {
                                        id: EpisodeSortOrder.OLDEST_TO_NEWEST,
                                        label: intl.formatMessage({
                                            id: 'release-date-reversed',
                                        }),
                                    },
                                    {
                                        id: EpisodeSortOrder.NEWEST_TO_OLDEST,
                                        label: intl.formatMessage({ id: 'release-date' }),
                                    },
                                    {
                                        id: EpisodeSortOrder.LENGTH_DESC,
                                        label: intl.formatMessage({ id: 'duration-order' }),
                                    },
                                    {
                                        id: EpisodeSortOrder.LENGTH_ASC,
                                        label: intl.formatMessage({
                                            id: 'duration-reversed',
                                        }),
                                    },
                                ]}
                            />
                            <EpisodesOptions
                                id="episode-options-search-bar"
                                showArchived={showArchived}
                                onPodcastSettings={
                                    isSubscribed
                                        ? () => {
                                              recordEvent('podcast_screen_settings_tapped');
                                              navigate(`/settings/podcast/${podcast.uuid}`);
                                          }
                                        : undefined
                                }
                                onHideArchived={() => {
                                    recordEvent('podcast_screen_toggle_archived', {
                                        show_archived: false,
                                    });
                                    showArchivedEpisodes(uuid, false);
                                }}
                                onShowArchived={() => {
                                    recordEvent('podcast_screen_toggle_archived', {
                                        show_archived: true,
                                    });
                                    showArchivedEpisodes(uuid, true);
                                }}
                                allArchived={allArchived}
                                onArchiveAll={() => {
                                    const episodesToArchive = episodes.filter(episode => {
                                        const episodeSync = uuidToEpisodeSync[episode.uuid];
                                        return episodeSync == null || !episodeSync.isDeleted;
                                    });

                                    const episodeUuidAndPodcastUuids = episodesToArchive.map(
                                        episode => ({
                                            uuid: episode.uuid,
                                            podcast: podcast.uuid,
                                        }),
                                    );

                                    showArchiveAllConfirmation(episodeUuidAndPodcastUuids);
                                }}
                                onUnarchiveAll={() => {
                                    const episodesToUnarchive = allEpisodes.filter(episode => {
                                        const episodeSync = uuidToEpisodeSync[episode.uuid];
                                        return episodeSync == null || episodeSync.isDeleted;
                                    });

                                    const episodeUuidAndPodcastUuids = episodesToUnarchive.map(
                                        episode => ({
                                            uuid: episode.uuid,
                                            podcast: podcast.uuid,
                                        }),
                                    );
                                    unarchiveAll(episodeUuidAndPodcastUuids);
                                }}
                                onArchiveAllPlayed={() => {
                                    const episodesToArchive = allEpisodes.filter(episode => {
                                        const episodeSync = uuidToEpisodeSync[episode.uuid];
                                        return (
                                            episodeSync &&
                                            episodeSync.playingStatus === PlayingStatus.COMPLETED &&
                                            !episodeSync.isDeleted
                                        );
                                    });

                                    const episodeUuidsAndPodcastUuids = episodesToArchive.map(
                                        episode => ({
                                            uuid: episode.uuid,
                                            podcast: podcast.uuid,
                                        }),
                                    );

                                    showArchiveAllConfirmation(episodeUuidsAndPodcastUuids, true);
                                }}
                                playedAreAllArchived={!anyPlayedButNotArchived}
                                onRefreshEpisodeList={() => {
                                    const lastEpisode = podcast.episodes.reduce((max, item) =>
                                        new Date(item.published) > new Date(max.published)
                                            ? item
                                            : max,
                                    );

                                    refreshEpisodeList(podcast.uuid, lastEpisode.uuid);
                                }}
                            />
                        </SearchBox>
                        <EpisodesTable
                            onEpisodeClick={onEpisodeClick}
                            emptyMessage={emptyMessage()}
                            episodes={episodesToShow}
                            uuidToEpisodeSync={uuidToEpisodeSync}
                            podcast={podcast}
                            sortEnabled={true}
                            showSort={false}
                            sortOrder={podcast.episodesSortOrder}
                            color={color}
                            playerEpisodeUuid={
                                player && player.episode ? player.episode.uuid : null
                            }
                            isPlaying={player.isPlaying}
                            playerPlayedUpTo={
                                player && player.episode ? player.episode.playedUpTo : 0
                            }
                            upNextEpisodes={upNext.episodes}
                            eventSource="podcast_screen"
                            autoplay={{ source: 'podcast', uuid: podcast.uuid }}
                        />
                    </EpisodeRows>
                </>
            )}
        </PagePodcast>
    );
};

const BlurredPodcastImage = ({ uuid }: { uuid: string }) => {
    const webp = useSelector((state: RootState) => state.settings.webpSupported);

    // This will be used only on mobile, so the small image should be enough for the blur effect
    const url = PodcastHelper.getImageUrl(200, uuid, webp);

    return <PodcastBlurredImage background={url} />;
};

function PodcastPageHeader({
    podcast,
    color,
    onShare,
    openRatingModal,
    subscribeClick,
    rating,
    recordEvent,
    isSubscribed,
}: Props & { subscribeClick: () => void; onShare: () => void }) {
    const { title, author, description, description_html, url } = podcast;

    const onOpenRatingModal = (source: 'button' | 'stars') => {
        recordEvent('rating_stars_tapped', { uuid: podcast.uuid, source });
        openRatingModal?.(podcast.uuid);
    };

    return (
        <PodcastInformation>
            <PodcastHeader>
                <BlurredPodcastImage uuid={podcast.uuid} />
                <PodcastImageWrapper>
                    <PodcastImage
                        className="podcast-image"
                        title={title}
                        uuid={podcast?.uuid}
                        borderRadius={8}
                    />
                </PodcastImageWrapper>
            </PodcastHeader>
            <PodcastDetails>
                <TitleAndActions>
                    <PodcastTitle>
                        {title}
                        <StyledShareButton color={color} onClick={onShare} />
                        <PodcastInlineActionsWrapper $isSubscribed={isSubscribed}>
                            <PodcastActions
                                podcastUuid={podcast.uuid}
                                onSubscribeClick={subscribeClick}
                            />
                        </PodcastInlineActionsWrapper>
                    </PodcastTitle>
                    <PodcastActionsWrapper>
                        <PodcastActions
                            podcastUuid={podcast.uuid}
                            onSubscribeClick={subscribeClick}
                        />
                    </PodcastActionsWrapper>
                </TitleAndActions>

                <PodcastMeta>
                    {USE_GIVE_RATINGS ? (
                        <>
                            <FeatureLock source="rate">
                                <StarRating
                                    onClick={() => onOpenRatingModal('stars')}
                                    rating={rating ? rating.average : 0}
                                    reviewCount={rating ? rating.total : 0}
                                />
                            </FeatureLock>
                            <FeatureLock source="rate">
                                <RateButton
                                    kind="text"
                                    onClick={() => onOpenRatingModal('button')}
                                    color={color}
                                >
                                    <FormattedMessage id="rate" />
                                </RateButton>
                            </FeatureLock>
                        </>
                    ) : (
                        <>
                            {rating && (
                                <StarRating rating={rating.average} reviewCount={rating.total} />
                            )}
                        </>
                    )}

                    <HideOnMobile>
                        {podcast.category && (
                            <CategoryLinks
                                categories={podcast.category}
                                color={color}
                                onClick={category =>
                                    recordEvent('podcast_screen_category_tapped', {
                                        category: category.label,
                                    })
                                }
                            />
                        )}
                    </HideOnMobile>
                </PodcastMeta>

                <PodcastAuthorSection>
                    {author && author !== '' && (
                        <TextWithIcon
                            text={author}
                            icon={
                                <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.00001 3C5.00001 1.34315 6.34315 0 8.00001 0C9.65686 0 11 1.34315 11 3V7C11 8.65685 9.65686 10 8.00001 10C6.34315 10 5.00001 8.65685 5.00001 7V3ZM9.00001 3V7C9.00001 7.55228 8.55229 8 8.00001 8C7.44772 8 7.00001 7.55228 7.00001 7V3C7.00001 2.44772 7.44772 2 8.00001 2C8.55229 2 9.00001 2.44772 9.00001 3Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M3.01565 8.08365C3.52174 7.86253 4.11126 8.09354 4.33238 8.59963C4.95054 10.0144 6.36128 11 7.99998 11C9.63868 11 11.0494 10.0144 11.6676 8.59963C11.8887 8.09354 12.4782 7.86253 12.9843 8.08365C13.4904 8.30477 13.7214 8.89428 13.5003 9.40037C12.7029 11.2254 11.0223 12.5789 8.99666 12.9176C8.99888 12.9448 9.00001 12.9723 9.00001 13V14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H5.00001C4.44772 16 4.00001 15.5523 4.00001 15C4.00001 14.4477 4.44772 14 5.00001 14H7.00001V13C7.00001 12.9723 7.00114 12.9448 7.00336 12.9176C4.97765 12.5789 3.29705 11.2254 2.49967 9.40037C2.27855 8.89428 2.50957 8.30477 3.01565 8.08365Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            }
                        />
                    )}

                    {url && (
                        <PodcastLink
                            icon={
                                <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.68159 4.11221C7.29689 4.4969 6.67318 4.4969 6.28849 4.11221C5.9038 3.72751 5.9038 3.1038 6.28849 2.71911L7.48257 1.52503C9.51595 -0.508344 12.8127 -0.508344 14.8461 1.52503C16.8794 3.55841 16.8794 6.85516 14.8461 8.88853L13.652 10.0826C13.2673 10.4673 12.6436 10.4673 12.2589 10.0826C11.8742 9.69792 11.8742 9.07421 12.2589 8.68952L13.453 7.49544C14.717 6.23145 14.717 4.18212 13.453 2.91813C12.189 1.65414 10.1397 1.65414 8.87567 2.91813L7.68159 4.11221ZM3.09021 5.91081C3.4749 5.52612 4.09861 5.52612 4.4833 5.91081C4.86799 6.29551 4.86799 6.91922 4.4833 7.30391L3.28922 8.49799C2.02523 9.76198 2.02523 11.8113 3.28922 13.0753C4.55321 14.3393 6.60254 14.3393 7.86653 13.0753L9.06061 11.8812C9.4453 11.4965 10.069 11.4965 10.4537 11.8812C10.8384 12.2659 10.8384 12.8896 10.4537 13.2743L9.25963 14.4684C7.22625 16.5018 3.9295 16.5018 1.89612 14.4684C-0.13725 12.435 -0.13725 9.13827 1.89612 7.10489L3.09021 5.91081ZM5.58787 9.39355C5.20318 9.77824 5.20318 10.402 5.58787 10.7866C5.97256 11.1713 6.59627 11.1713 6.98096 10.7866L11.1602 6.60736C11.5449 6.22267 11.5449 5.59896 11.1602 5.21427C10.7756 4.82957 10.1518 4.82957 9.76715 5.21426L5.58787 9.39355Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            }
                            text={<FriendlyUrl url={url} color={color} />}
                        />
                    )}

                    {podcast.estimatedNextEpisodeAt && podcast.episodeFrequency && (
                        <NextEpisodeDetails>
                            <TextWithIcon
                                icon={
                                    <svg
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.95708 0C5.40479 0 4.95708 0.447715 4.95708 1C4.95708 1.55228 5.40479 2 5.95708 2H9.95708C10.5094 2 10.9571 1.55228 10.9571 1C10.9571 0.447715 10.5094 0 9.95708 0H5.95708ZM7.95708 3C4.36723 3 1.45708 5.91015 1.45708 9.5C1.45708 13.0899 4.36723 16 7.95708 16C11.5469 16 14.4571 13.0899 14.4571 9.5C14.4571 8.06589 13.9926 6.74025 13.206 5.66529L13.9675 4.90378C14.358 4.51325 14.358 3.88009 13.9675 3.48956C13.577 3.09904 12.9438 3.09904 12.5533 3.48956L11.7917 4.25109C10.7168 3.46443 9.39117 3 7.95708 3ZM3.45708 9.5C3.45708 7.01472 5.4718 5 7.95708 5C10.4424 5 12.4571 7.01472 12.4571 9.5C12.4571 11.9853 10.4424 14 7.95708 14C5.4718 14 3.45708 11.9853 3.45708 9.5ZM8.95708 7C8.95708 6.44772 8.50936 6 7.95708 6C7.40479 6 6.95708 6.44772 6.95708 7V10C6.95708 10.5523 7.40479 11 7.95708 11C8.50936 11 8.95708 10.5523 8.95708 10V7Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                }
                                text={
                                    <NextEpisodeText
                                        estimatedNextEpisodeAt={podcast.estimatedNextEpisodeAt}
                                        episodeFrequency={podcast.episodeFrequency}
                                    />
                                }
                            />
                        </NextEpisodeDetails>
                    )}
                </PodcastAuthorSection>
                <ReadMoreText
                    text={description}
                    html={description_html}
                    lines={2}
                    color={color}
                    onExpand={() =>
                        recordEvent('podcast_screen_toggle_summary', {
                            is_expanded: true,
                        })
                    }
                />
            </PodcastDetails>
        </PodcastInformation>
    );
}

export const withRouter =
    <T,>(Component: React.ComponentType<T>): React.FunctionComponent<Omit<T, keyof Props>> =>
    props => {
        const params = useParams<keyof RouteParams>() as RouteParams;
        const navigate = useNavigate();

        return <Component {...(props as T)} params={params} navigate={navigate} />;
    };

export default injectIntl(withAnalyticsContext(PodcastPage));
