import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useScrollingContext } from '../context/ScrollingContext';
import { getBrowserHistory } from '../customHistory';

/**
 * This hook is used to save and restore the scroll position of a page when the user navigates away and back to the page.
 * It uses the browser's session storage to save the scroll position.
 * @param isReady A boolean value that indicates if the page is ready have it's scroll restored, for example, in situations
 * where data has to be loaded and awaited for before restoring it.
 */
export function useScrollRestoration(isReady: boolean) {
    const location = useLocation();
    const history = getBrowserHistory();
    const initialLocation = useRef(location);
    const { ref, scrollTo } = useScrollingContext();
    const [isScrollRestored, setIsScrollRestored] = useState(false);
    const scrollKey = location?.state?.scrollKey || location?.key || '/';

    const saveScrollPosition = useCallback(
        (position: number) => {
            sessionStorage.setItem(scrollKey, position.toString());
        },
        [scrollKey],
    );

    const restoreScroll = useCallback(() => {
        if (!ref.current) {
            return;
        }
        const item = sessionStorage.getItem(scrollKey) ?? '0';
        const position = parseInt(item, 10) || 0;
        scrollTo(position);
        sessionStorage.removeItem(scrollKey);
    }, [ref, scrollKey, scrollTo]);

    // This effect is used to save the scroll position when the user navigates away from the page
    useEffect(() => {
        return history.listen(({ location }) => {
            if (
                location.pathname + location.search !==
                initialLocation.current.pathname + initialLocation.current.search
            ) {
                if (!ref.current) {
                    return;
                }
                const position = ref.current.scrollTop;

                saveScrollPosition(position);
            }
        });
    }, [history, location, saveScrollPosition, ref]);

    // This effect is used to restore the scroll position when the user navigates back to the page
    useEffect(() => {
        if (!isScrollRestored && isReady) {
            restoreScroll();
            setIsScrollRestored(true);
        }
    }, [isReady, isScrollRestored, restoreScroll]);
}
