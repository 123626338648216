import styled from 'styled-components';
import { MOBILE_WIDTH } from '../../../model/page';

export const PopupWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    &.open {
        display: block;
    }
    .popup-background {
        background: ${props => props.theme.legacyScss['fixme-popup-background']};
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
    }
    .popup-window {
        position: fixed;
        min-width: 100px;
        border-radius: 4px;
        overflow: hidden;
        box-shadow:
            0 0 1px 0 rgba(0, 0, 0, 0.3),
            0 8px 16px rgba(0, 0, 0, 0.2);
        background: ${props => props.theme.legacyScss['theme-popup-background']};
        z-index: 102;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        z-index: 400;
        padding: 0 16px;

        > div {
            width: 100% !important;
        }
    }
`;
