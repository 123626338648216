import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { useTheme } from 'styled-components';
import { DurationStringFromSeconds } from '../../helper/DurationHelper';
import { PlayingStatus } from '../../helper/PlayingStatus';
import useFormatMessage from '../../hooks/useFormatMessage';
import { Episode, EpisodeSyncInfo, PodcastListPodcast } from '../../model/types';
import { Icon } from '../Icon';
import { ImageTextOption } from '../popup/ImageTextOption';
import { PopupMenu } from '../popup/PopupMenu';
import VirtualSortableTable from '../VirtualTable/VirtualSortableTable';
import { EpisodePlayButton } from './buttons/EpisodePlayButton';
import { EpisodeDate } from './columns/EpisodeDate';
import { EpisodeTitle, PrimaryLine, SecondaryLine } from './columns/EpisodeInfo';
import { EpisodeInfo } from './columns/EpisodeInfo.styled';
import { EpisodeThumbnail, TextColumn } from './ListEpisodes.styled';
import { RemoveButton, RowHandleWrapper, UpNextRow } from './ListUpNextEpisodes.styled';
import { getFauxEpisodeSync, getPercentComplete } from './utils';

interface Props {
    rows: {
        uuid: string;
        id: string;
        episode: Episode;
        episodeSync: EpisodeSyncInfo;
        podcast?: PodcastListPodcast;
    }[];
    openEpisode: (episode: Episode) => void;
    playEpisode: (episodeUuid: string, podcastUuid: string) => void;
    removeFromUpNext: (episodeUuid: string) => void;
    onSortStart: (event: DragStartEvent) => void;
    onSortOver: (event: DragOverEvent) => void;
    onSortEnd: (event: DragEndEvent) => void;
    upNextPlayNext: (podcastUuid: string, episode: Episode) => void;
    upNextPlayLast: (podcastUuid: string, episode: Episode) => void;
}

interface UpNextRowContentProps {
    episode: Episode;
    podcast?: PodcastListPodcast;
    episodeSync: EpisodeSyncInfo;
    playEpisode: (episodeUuid: string, podcastUuid: string) => void;
    removeFromUpNext: (episodeUuid: string) => void;
    upNextPlayNext: Props['upNextPlayNext'];
    upNextPlayLast: Props['upNextPlayLast'];
}

const UpNextRowContent = ({
    episode,
    podcast,
    episodeSync,
    playEpisode,
    removeFromUpNext,
    upNextPlayNext,
    upNextPlayLast,
}: UpNextRowContentProps) => {
    const syncInfo = getFauxEpisodeSync(episode, { [episode.uuid]: episodeSync });
    const percentComplete = getPercentComplete(
        0,
        syncInfo.playedUpTo ?? 0,
        episode.uuid,
        syncInfo.duration,
        undefined,
    );
    const theme = useTheme();
    const formatMessage = useFormatMessage();
    return (
        <>
            <EpisodeThumbnail $small title={episode.title} uuid={episode.podcastUuid} />
            <EpisodeInfo>
                <PrimaryLine>
                    <EpisodeTitle>{episode.title}</EpisodeTitle>
                </PrimaryLine>
                {podcast && episode.podcastUuid && <SecondaryLine>{podcast.title}</SecondaryLine>}
            </EpisodeInfo>
            <EpisodeDate published={episode.published} alignRight />
            <TextColumn>
                {!!episodeSync.duration && (
                    <DurationStringFromSeconds
                        durationSecsStrOrNum={Math.max(
                            0,
                            (episodeSync.duration || 0) - (episodeSync.playedUpTo || 0),
                        )}
                        remaining={!!episodeSync.playedUpTo}
                        short={true}
                    />
                )}
            </TextColumn>
            <RemoveButton
                onClick={e => {
                    e.stopPropagation();
                    removeFromUpNext(episode.uuid);
                }}
            >
                <Icon id="remove-from-upnext" size={24} />
            </RemoveButton>
            <PopupMenu aria-label="Options">
                <ImageTextOption
                    icon="move-to-top"
                    color={theme.tokens['primary-icon-01']}
                    text={formatMessage('play-next')}
                    onClick={() => upNextPlayNext(episode.podcastUuid, episode)}
                />
                <ImageTextOption
                    icon="move-to-bottom"
                    color={theme.tokens['primary-icon-01']}
                    text={formatMessage('play-last')}
                    onClick={() => upNextPlayLast(episode.podcastUuid, episode)}
                />

                <ImageTextOption
                    icon="remove-from-upnext"
                    color={theme.tokens['support-05']}
                    text={formatMessage('remove-up-next')}
                    onClick={() => removeFromUpNext(episode.uuid)}
                />
            </PopupMenu>
            <EpisodePlayButton
                variant="upNext"
                size={28}
                color="currentColor"
                isPlaying={false}
                isPlayerEpisode={false}
                percentComplete={percentComplete}
                isPlayed={syncInfo.playingStatus === PlayingStatus.COMPLETED}
                onPlayClick={() => playEpisode(episode.uuid, episode.podcastUuid)}
                onPauseClick={() => {}}
            />
            <RowHandleWrapper>
                <Icon id="drag-handle" />
            </RowHandleWrapper>
        </>
    );
};

interface SortableUpNextRowProps {
    episode: Episode;
    podcast?: PodcastListPodcast;
    episodeSync: EpisodeSyncInfo;
    playEpisode: (episodeUuid: string, podcastUuid: string) => void;
    removeFromUpNext: (episodeUuid: string) => void;
    upNextPlayNext: Props['upNextPlayNext'];
    upNextPlayLast: Props['upNextPlayLast'];
    openEpisode: Props['openEpisode'];
}

const SortableUpNextRow = ({
    episode,
    podcast,
    episodeSync,
    playEpisode,
    removeFromUpNext,
    upNextPlayNext,
    upNextPlayLast,
    openEpisode,
}: SortableUpNextRowProps) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
        id: episode.uuid,
        transition: {
            duration: 200,
            easing: 'ease',
        },
    });

    const safeAttributes = {
        ...attributes,
        // Setting a tab index triggers a bug in Chrome that causes a "jump" back to the start
        // when scrolling through a virtualized list.
        // See https://github.com/Automattic/pocket-casts-webplayer/issues/3039
        tabIndex: undefined,
    };

    return (
        <UpNextRow
            ref={setNodeRef}
            $isDragging={isDragging}
            $isSortable
            onClick={() => openEpisode(episode)}
            style={{
                transform: transform ? CSS.Transform.toString(transform) : undefined,
                opacity: isDragging ? 0 : 1,
            }}
            {...safeAttributes}
            {...listeners}
        >
            <UpNextRowContent
                episode={episode}
                podcast={podcast}
                episodeSync={episodeSync}
                playEpisode={playEpisode}
                removeFromUpNext={removeFromUpNext}
                upNextPlayNext={upNextPlayNext}
                upNextPlayLast={upNextPlayLast}
            />
        </UpNextRow>
    );
};

export const ListUpNextEpisodes: React.FC<Props> = ({
    rows,
    playEpisode,
    removeFromUpNext,
    onSortStart,
    onSortOver,
    onSortEnd,
    upNextPlayNext,
    upNextPlayLast,
    openEpisode,
}) => {
    return (
        <VirtualSortableTable
            items={rows}
            getItemId={row => row.uuid}
            renderItem={row => (
                <SortableUpNextRow
                    key={row.uuid}
                    episode={row.episode}
                    podcast={row.podcast}
                    episodeSync={row.episodeSync}
                    playEpisode={playEpisode}
                    removeFromUpNext={removeFromUpNext}
                    upNextPlayNext={upNextPlayNext}
                    upNextPlayLast={upNextPlayLast}
                    openEpisode={openEpisode}
                />
            )}
            renderDragOverlay={row => (
                <UpNextRow $isDragging onClick={() => openEpisode(row.episode)}>
                    <UpNextRowContent
                        episode={row.episode}
                        podcast={row.podcast}
                        episodeSync={row.episodeSync}
                        playEpisode={playEpisode}
                        removeFromUpNext={removeFromUpNext}
                        upNextPlayNext={upNextPlayNext}
                        upNextPlayLast={upNextPlayLast}
                    />
                </UpNextRow>
            )}
            onSortStart={onSortStart}
            onSortOver={onSortOver}
            onSortEnd={onSortEnd}
        />
    );
};
