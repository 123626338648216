import { useSelector } from 'hooks/react-redux-typed';
import jsonp from 'jsonp';
import isEmpty from 'lodash/isEmpty';
import { PaddleProduct } from 'model/types';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { ALL_PADDLE_SKUS } from 'settings';
import { getPaddleSKUs } from '../redux/reducers/selectors';

type PaddlePriceFetchData = {
    success: boolean;
    response: {
        customer_country: string;
        products: PaddleProduct[];
    };
};

type PaddleProductMap = Partial<Record<number, PaddleProduct>>;

let cachedProducts: PaddleProductMap = {};

const fetchProducts = (
    skus: number[],
    code: string,
    callback: (products: PaddleProductMap) => void,
) => {
    const domain =
        window.Paddle.Environment.get() === 'sandbox'
            ? 'https://sandbox-checkout.paddle.com'
            : 'https://checkout.paddle.com';

    const url = qs.stringifyUrl({
        url: `${domain}/api/2.0/prices`,
        query: {
            product_ids: skus.join(','),
            calculate_recurring_discounts: !!code,
            coupons: code,
        },
    });

    jsonp(url, (err, data: PaddlePriceFetchData) => {
        const productsBySKU = data.response.products.reduce<PaddleProductMap>(
            (products, product) => ({ ...products, [product.product_id]: product }),
            {},
        );
        callback(productsBySKU);
    });
};

interface Props {
    code?: string;
}

/**
 * Fetches and returns Paddle product information for all plans. When the plan SKUs change in state
 * (e.g. when a user logs in who's previously had a trial) products will be re-fetched for the new SKUs.
 */
export const usePaddleProducts = ({ code = '' }: Props = {}) => {
    const paddleSkus = useSelector(getPaddleSKUs);
    const [products, setProducts] = useState(cachedProducts);

    // isMounted guards against calling setState when the async Paddle callback fires after the component is unmounted
    useEffect(() => {
        let isMounted = true;

        // Only fetch products if we don't already have them cached
        if (isEmpty(products)) {
            fetchProducts(ALL_PADDLE_SKUS, code, products => {
                // Cache the products always...
                cachedProducts = products;
                // ... but only update state if the component is still mounted
                if (isMounted) {
                    setProducts(products);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [code, products]);

    return {
        'patron-monthly': products[paddleSkus['patron-monthly']],
        'patron-yearly': products[paddleSkus['patron-yearly']],
        'plus-monthly': products[paddleSkus['plus-monthly']],
        'plus-yearly': products[paddleSkus['plus-yearly']],
        'switch-to-pocket-casts': products[paddleSkus['switch-to-pocket-casts']],
    };
};
