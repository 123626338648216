import { connect } from 'react-redux';
import * as fromSubscriptionActions from '../../../../redux/actions/subscription.actions';
import { RootState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../store';
import ChangeBillingDetailsModal from './ChangeBillingDetailsModal';

const mapStateToProps = (state: RootState) => {
    const subscriptionData = state.subscription.modalData.showChangeBillingDetailsModal;
    return {
        platform: subscriptionData?.platform,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onClose: () =>
        dispatch(fromSubscriptionActions.Actions.hideModalGeneric('showChangeBillingDetailsModal')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBillingDetailsModal);
