import styled from 'styled-components';

export const CloseButtonWrapper = styled.button<{ $left?: boolean }>`
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${props => (props.theme.name === 'dark' ? 'rgba(255, 255, 255, 0.5)' : '#B8C3C9')};
    &:hover {
        color: ${props => (props.theme.name === 'dark' ? 'rgba(255, 255, 255, 1.0)' : '#8F97A4')};
    }
    ${props =>
        props.$left &&
        `right: auto;
        left: 10px;`}
`;
