import classNames from 'classnames';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { PopupMenuClass } from '../../../../components/popup/PopupMenu/PopupMenu';
import { APP_ENVIRONMENT } from '../../../../settings';
import {
    AccordionContainer,
    CurrentSelection,
    OriginalLength,
    PoweredBy,
    SetDurationButton,
    StyledPopupMenu,
} from './Accordion.styled';
import { AccordionLogo } from './AccordionLogo';
import { Slider } from './Slider';
import InfoJSON from './info.json';

interface Props {
    episodeUuid: string;
    podcastUuid: string;
    updateEpisode: (
        episodeUuid: string,
        podcastUuid: string,
        audioUrl: string,
        duration: number,
        isFullEpisode?: boolean,
    ) => void;
}

interface AccordionConfig {
    [podcastUuid: string]: {
        [episodeUuid: string]: string;
    };
}

interface AccordionConfigByEnv {
    development: AccordionConfig;
    staging: AccordionConfig;
    production: AccordionConfig;
}

interface AccordionInfo {
    podcasts: {
        id: number;
        name: string;
        mainThumb: string;
        squareThumb: string;
        descriptionParas: string[];
        credits: string;
        episodes: {
            id: number;
            name: string;
            shortDesc: string;
            descriptionParas: string[];
            audio: {
                durationSeconds: number;
                url: string;
            }[];
        }[];
    }[];
}

// This is a temporary solution to set the accordion info for different podcasts and episodes
// If we decide to continue building out this feature, information should be fetched from the backend
const envConfigs: AccordionConfigByEnv = {
    development: {
        '70d13d50-9efe-0130-1b90-723c91aeae46': {
            '0ef8de27-263c-4dcf-8575-e95236ef0683':
                'https://www.accordion.live/content/c376thx98t3/info.json',
        },
    },
    staging: {
        '70d13d50-9efe-0130-1b90-723c91aeae46': {
            '0ef8de27-263c-4dcf-8575-e95236ef0683':
                'https://www.accordion.live/content/c376thx98t3/info.json',
        },
    },
    production: {
        '70d13d50-9efe-0130-1b90-723c91aeae46': {
            'e1e78457-ab48-4204-b829-0cfa0ab7f107':
                'https://www.accordion.live/content/c376thx98t3/info.json',
        },
    },
};

const accordionConfig =
    envConfigs[APP_ENVIRONMENT as keyof AccordionConfigByEnv] || envConfigs.development;

function formatAccordionTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}m${remainingSeconds}s`;
}

const Accordion = ({ episodeUuid, podcastUuid, updateEpisode }: Props) => {
    const [selectedDurationIndex, setSelectedDurationIndex] = useState(0);
    const [isSelectionMade, setIsSelectionMade] = useState(false);
    const formatMessage = useFormatMessage();
    const popupMenuRef = useRef<PopupMenuClass | null>(null);

    // We can't fetch due to CORS
    // See https://a8c.slack.com/archives/C028JBJJ1N3/p1715787269368249?thread_ts=1714112009.355279&cid=C028JBJJ1N3
    // We will hardcode the accordion info for now since this is a single test
    const accordionInfo: AccordionInfo = InfoJSON;

    const handleSliderChange = useCallback((value: number) => {
        setSelectedDurationIndex(value);
        setIsSelectionMade(true);

        // Reset isSelectionMade to false after a delay to avoid animation replaying
        setTimeout(() => {
            setIsSelectionMade(false);
        }, 1000);
    }, []);

    useEffect(() => {
        if (accordionInfo) {
            setSelectedDurationIndex(accordionInfo.podcasts[0].episodes[0].audio.length - 1);
        }
    }, [accordionInfo]);

    const handleSetDuration = useCallback(() => {
        if (!accordionInfo) {
            return;
        }

        const fileName = accordionInfo.podcasts[0].episodes[0].audio[selectedDurationIndex].url;
        const audioUrl = `https://accordion.live/audio/${fileName}`;

        const isFullEpisode =
            selectedDurationIndex === accordionInfo.podcasts[0].episodes[0].audio.length - 1;

        const { durationSeconds } =
            accordionInfo.podcasts[0].episodes[0].audio[selectedDurationIndex];

        updateEpisode(episodeUuid, podcastUuid, audioUrl, durationSeconds, isFullEpisode);

        popupMenuRef?.current?.closeMenu();
    }, [accordionInfo, selectedDurationIndex, updateEpisode, episodeUuid, podcastUuid]);

    if (!accordionConfig[podcastUuid] || !accordionConfig[podcastUuid][episodeUuid]) {
        return null;
    }

    if (!accordionInfo) {
        return null;
    }

    const durations = accordionInfo.podcasts[0].episodes[0].audio
        .map(a => a.durationSeconds)
        .map(formatAccordionTime);

    // TODO: It would be nice if we got a single duration in seconds
    // and handled converting it to various formats ourselves. We should
    // check with the Accordion team to see if this is possible.
    const formatDurationLabel = (duration: string) => {
        const hoursMatch = duration.match(/(\d+)h/);
        const minutesMatch = duration.match(/(\d+)m/);
        const formattedDuration: JSX.Element[] = [];

        const formatUnit = (value: number, unit: 'hours' | 'minutes') => {
            if (unit === 'minutes' && !hoursMatch) {
                return (
                    <Fragment key={`fmt-${value}-${unit}`}>
                        <span className="big-time">{value}</span>
                        <span className="little-time">
                            <FormattedPlural
                                key={unit}
                                value={value}
                                one={<FormattedMessage id={'minute'} />}
                                other={<FormattedMessage id={'minutes'} />}
                            />
                        </span>
                    </Fragment>
                );
            }

            return (
                <span
                    className={classNames(
                        { 'big-time': unit === 'hours' },
                        { 'little-time has-hours': unit === 'minutes' },
                    )}
                >
                    <FormattedPlural
                        key={unit}
                        value={value}
                        one={
                            unit === 'hours' ? (
                                <FormattedMessage
                                    id={`duration-n-hour-shorthand`}
                                    values={{
                                        number: value,
                                    }}
                                />
                            ) : (
                                <FormattedMessage id={`duration-one-${unit.slice(0, -1)}`} />
                            )
                        }
                        other={
                            unit === 'hours' ? (
                                <FormattedMessage
                                    id={`duration-n-hour-shorthand`}
                                    values={{
                                        number: value,
                                    }}
                                />
                            ) : (
                                <FormattedMessage
                                    id={`duration-n-${unit}`}
                                    values={{
                                        number: value,
                                    }}
                                />
                            )
                        }
                    />
                </span>
            );
        };

        if (hoursMatch) {
            const hours = parseInt(hoursMatch[1], 10);
            formattedDuration.push(formatUnit(hours, 'hours'));
        }

        if (minutesMatch) {
            const minutes = parseInt(minutesMatch[1], 10);
            formattedDuration.push(formatUnit(minutes, 'minutes'));
        } else if (!hoursMatch) {
            // If there are no hours or minutes specified, assume the duration is in minutes
            const minutes = parseInt(duration, 10);
            formattedDuration.push(formatUnit(minutes, 'minutes'));
        }

        return formattedDuration;
    };

    const isOriginalLength = selectedDurationIndex === durations.length - 1;

    const PopupContent = () => (
        <AccordionContainer>
            <CurrentSelection
                $isSelectionMade={isSelectionMade}
                $isOriginalLength={isOriginalLength}
            >
                {isOriginalLength && (
                    <OriginalLength>{formatMessage('original-length')}</OriginalLength>
                )}
                {formatDurationLabel(durations[selectedDurationIndex])}
            </CurrentSelection>
            <Slider
                durations={durations}
                selectedDurationIndex={selectedDurationIndex}
                handleSliderChange={handleSliderChange}
            />
            <PoweredBy href="https://accordion.live/" target="_blank" rel="noopener noreferrer">
                <span>
                    <FormattedMessage id="powered-by" />
                </span>
                <AccordionLogo />
            </PoweredBy>
            <SetDurationButton onClick={handleSetDuration}>
                {formatMessage('choose-duration')}
            </SetDurationButton>
        </AccordionContainer>
    );

    return (
        <StyledPopupMenu
            ref={popupMenuRef}
            id="accordion-popup-menu"
            itemClassName="accordion-popup-menu-item"
            iconName="sparkle-sm"
            menuTitle={formatMessage('choose-your-duration')}
            offsets={{ top: -435, left: 150 }}
            horizontalPosition="center"
            maxHeight={402}
        >
            <PopupContent />
        </StyledPopupMenu>
    );
};

export default Accordion;
