import { Icon } from 'components/Icon';
import { SortOptions } from 'components/SortOptions';
import { LOCAL_STORAGE_KEY_ENABLE_FOLDERS } from 'model/local-storage';
import { WebPlayerTheme } from 'model/theme';
import { Folder, TracksProperties } from 'model/types';
import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';
import { tooltipAndLabel } from 'util/tooltip';
import FeatureLock from '../../../components/FeatureLock';
import { PAGE_MAX_WIDTH } from '../../../model/page';
import { PodcastBadges, PodcastGridLayouts, PodcastGridOrder } from '../model';
import { EditFolderContextMenu } from './EditFolderContextMenu';
import {
    FolderIcon,
    OptionSpacer,
    OptionWrapper,
    OptionsHeaderWrapper,
    Title,
    badgeRedSvg,
    badgeSvg,
} from './OptionsHeader.styled';

if (window.location.search === '?enable-folders') {
    localStorage.setItem(LOCAL_STORAGE_KEY_ENABLE_FOLDERS, 'true');
} else if (window.location.search === '?disable-folders') {
    localStorage.removeItem(LOCAL_STORAGE_KEY_ENABLE_FOLDERS);
}

const calculatePopupRightPx = () =>
    window.innerWidth < PAGE_MAX_WIDTH ? 40 : 48 + (window.innerWidth - PAGE_MAX_WIDTH) / 2;

type Props = {
    folder?: Folder;
    folderColorValues: string[];
    layout: PodcastGridLayouts;
    badges: PodcastBadges;
    sort: PodcastGridOrder;
    title?: string;
    theme: WebPlayerTheme;
    onBadgesChanged: (badge: PodcastBadges) => void;
    onOrderChanged: (newOrder: PodcastGridOrder) => void;
    onLayoutChanged: (newLayout: PodcastGridLayouts) => void;
    onCreateFolderClick: () => void;
    onEditFolderDetailsClick: (uuid: string) => void;
    onEditFolderPodcastsClick: (uuid: string) => void;
    recordEvent: (name: string, properties?: TracksProperties) => void;
} & WrappedComponentProps;

type StateProps = {
    editFolderOptionsOpen: boolean;
    popupTopPx: number;
    popupRightPx: number;
    hoveringBadges: boolean;
};

export class GridOptions extends Component<Props, StateProps> {
    editFolderIconRef: React.MutableRefObject<HTMLButtonElement> | null;

    sortIconRef: React.MutableRefObject<HTMLButtonElement> | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            editFolderOptionsOpen: false,
            popupTopPx: 123,
            popupRightPx: calculatePopupRightPx(),
            hoveringBadges: false,
        };
        this.editFolderIconRef = React.createRef() as React.MutableRefObject<HTMLButtonElement>;
        this.sortIconRef = React.createRef() as React.MutableRefObject<HTMLButtonElement>;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({
            popupRightPx: calculatePopupRightPx(),
        });
    };

    toggleEditFolderMenu = () => {
        this.props.recordEvent('folder_options_button_tapped');
        this.setState({ editFolderOptionsOpen: !this.state.editFolderOptionsOpen });
    };

    closeEditFolderMenu = () => {
        this.setState({ editFolderOptionsOpen: false });
    };

    switchLayout = () => {
        const { layout } = this.props;

        if (layout === PodcastGridLayouts.GRID_SMALL) {
            this.props.onLayoutChanged(PodcastGridLayouts.LIST);
        } else if (layout === PodcastGridLayouts.GRID_LARGE) {
            this.props.onLayoutChanged(PodcastGridLayouts.GRID_SMALL);
        } else {
            this.props.onLayoutChanged(PodcastGridLayouts.GRID_LARGE);
        }
    };

    onBadgesClick = () => {
        const { badges, onBadgesChanged } = this.props;

        onBadgesChanged(
            badges === PodcastBadges.NEWEST_EPISODE
                ? PodcastBadges.OFF
                : PodcastBadges.NEWEST_EPISODE,
        );
    };

    getLayoutIcon = () => {
        const { layout } = this.props;

        if (layout === PodcastGridLayouts.LIST) {
            return <Icon id="list" />;
        }

        if (layout === PodcastGridLayouts.GRID_SMALL) {
            return <Icon id="grid-small" />;
        }

        return <Icon id="grid-large" />;
    };

    getLayoutTypeAsString = () => {
        const { intl, layout } = this.props;

        if (layout === PodcastGridLayouts.LIST) {
            return intl.formatMessage({ id: 'list-view' });
        }

        if (layout === PodcastGridLayouts.GRID_SMALL) {
            return intl.formatMessage({ id: 'small-grid' });
        }

        return intl.formatMessage({ id: 'large-grid' });
    };

    showFolderButton() {
        const { folder, intl } = this.props;
        const { editFolderOptionsOpen } = this.state;

        if (folder) {
            return (
                <>
                    <OptionWrapper $forceHighlight={editFolderOptionsOpen}>
                        <button
                            {...tooltipAndLabel(intl.formatMessage({ id: 'edit-folder' }))}
                            aria-haspopup={true}
                            aria-expanded={editFolderOptionsOpen}
                            onClick={this.toggleEditFolderMenu}
                            ref={
                                this.editFolderIconRef as React.MutableRefObject<HTMLButtonElement>
                            }
                            id="edit-folder-button"
                        >
                            <Icon id="pencil" />
                            {this.state.editFolderOptionsOpen && (
                                <EditFolderContextMenu
                                    ariaAttributes={{
                                        'aria-labelledby': 'edit-folder-button',
                                    }}
                                    anchorEl={this.editFolderIconRef?.current as Element}
                                    onEditDetailsClick={() => {
                                        this.props.recordEvent(
                                            'folder_options_modal_option_tapped',
                                            {
                                                option: 'edit_folder',
                                            },
                                        );
                                        this.props.onEditFolderDetailsClick(folder.uuid);
                                    }}
                                    onEditPodcastsClick={() => {
                                        this.props.recordEvent(
                                            'folder_options_modal_option_tapped',
                                            {
                                                option: 'add_or_remove_podcasts',
                                            },
                                        );
                                        this.props.onEditFolderPodcastsClick(folder.uuid);
                                    }}
                                    onClosed={this.closeEditFolderMenu}
                                />
                            )}
                        </button>
                    </OptionWrapper>
                </>
            );
        }
        return (
            <OptionWrapper>
                <FeatureLock
                    source="create_folder"
                    title={intl.formatMessage({ id: 'feature-folders-unavailable-title' })}
                    description={intl.formatMessage({
                        id: 'feature-folders-unavailable-description',
                    })}
                    requires="subscription"
                >
                    <button
                        {...tooltipAndLabel(intl.formatMessage({ id: 'new-folder' }))}
                        onClick={() => this.props.onCreateFolderClick()}
                    >
                        <Icon id="folder-add" />
                    </button>
                </FeatureLock>
            </OptionWrapper>
        );
    }

    render() {
        const { badges, folder, folderColorValues, intl, title, theme } = this.props;

        return (
            <OptionsHeaderWrapper>
                {folder && (
                    <FolderIcon color={folderColorValues[folder.color]}>
                        <Icon id="folder" size={18} />
                    </FolderIcon>
                )}
                <Title>{title}</Title>
                {this.showFolderButton()}

                <OptionWrapper>
                    <SortOptions
                        id="sort-options-search-bar"
                        onSelect={(sortId: number | string) =>
                            this.props.onOrderChanged(sortId as PodcastGridOrder)
                        }
                        selectedOption={this.props.sort}
                        options={[
                            {
                                id: PodcastGridOrder.TITLE,
                                label: intl.formatMessage({ id: 'name' }),
                            },
                            {
                                id: PodcastGridOrder.RELEASE_DATE,
                                label: intl.formatMessage({ id: 'table-release-date' }),
                            },
                            {
                                id: PodcastGridOrder.DATE_ADDED,
                                label: intl.formatMessage({ id: 'date-added' }),
                            },
                            {
                                id: PodcastGridOrder.USER_SORTED,
                                label: intl.formatMessage({ id: 'drag-and-drop' }),
                            },
                        ]}
                        iconColor={theme.legacyScss['theme-text-primary']}
                        iconHoverColor={theme.legacyScss['theme-text-primary']}
                    />
                </OptionWrapper>

                <OptionSpacer />
                <OptionWrapper>
                    <button
                        {...tooltipAndLabel(
                            `${intl.formatMessage({
                                id: 'toggle-layout',
                            })} (${intl.formatMessage(
                                { id: 'current-option' },
                                { option: this.getLayoutTypeAsString() },
                            )})`,
                        )}
                        data-tooltip-content={intl.formatMessage({ id: 'toggle-layout' })}
                        onClick={this.switchLayout}
                    >
                        {this.getLayoutIcon()}
                    </button>
                </OptionWrapper>
                <OptionWrapper>
                    <button
                        role="switch"
                        aria-checked={badges === PodcastBadges.OFF}
                        {...tooltipAndLabel(intl.formatMessage({ id: 'show-unplayed-badges' }))}
                        onClick={this.onBadgesClick}
                    >
                        {badges === PodcastBadges.OFF ? badgeSvg() : badgeRedSvg()}
                    </button>
                </OptionWrapper>
            </OptionsHeaderWrapper>
        );
    }
}

export default injectIntl(withTheme(GridOptions) as React.ComponentType<Omit<Props, 'theme'>>);
