import LogoLink from 'components/LogoLink/LogoLink';
import { NavigationItem, NavigationItems } from 'helper/NavigationHelper';
import { useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import key from 'keymaster';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FeatureLock from '../../../components/FeatureLock';
import ShowOnMobile from '../../../components/responsive/MobileWrapper';
import { ProfileMenu } from '../../../components/SecondaryNav/ProfileMenu';
import { getChaptersOpen, hasPaidSubscription } from '../../../redux/reducers/selectors';
import { userIsLoggedIn } from '../../../redux/reducers/selectors/user.selectors';
import {
    LinkIcon,
    LinkNotch,
    LinkText,
    NavigationLink,
    NavigationLinkWrapper,
    NavigationWrapper,
    ScrollingCover,
    Spaces,
} from './Navigation.styled';

const Link = ({
    navigationItem,
    onLinkClick,
    onClick,
    ...props
}: {
    navigationItem: NavigationItem;
    onLinkClick?: () => void;
    onClick?: (e: React.MouseEvent) => void;
}) => {
    const location = useLocation();
    const formatMessage = useFormatMessage();
    const { icon, path, text } = navigationItem;
    const selected = location.pathname.startsWith(path);

    const handleClick = (e: React.MouseEvent) => {
        if (onClick) {
            e.preventDefault();
            onClick(e);
        }
        onLinkClick?.();
    };

    return (
        <NavigationLinkWrapper $selected={selected}>
            <NavigationLink
                {...props}
                to={path}
                $selected={selected}
                aria-label={formatMessage(text)}
                onClick={handleClick}
            >
                <LinkNotch
                    $selected={selected}
                    width="8"
                    height="46"
                    viewBox="1 0 8 54"
                    aria-hidden={true}
                    className="nav-notch"
                >
                    <path d="M0,0 C0,0 4.14113188e-13,2 3,2 C4.75181535,2 5,2 5,2 L5.00860596,2 C6.66070727,2 8,3.34205759 8,4.99139404 L8,5 L8,49 L8,49.008606 C8,50.6607073 6.65794241,52 5.00860596,52 L5,52 C5,52 5,52 3,52 C1,52 0,54 0,54 L0,0 Z" />
                </LinkNotch>

                {icon && <LinkIcon id={icon} size={32} aria-hidden={true} />}

                <LinkText>{formatMessage(text)}</LinkText>
            </NavigationLink>
        </NavigationLinkWrapper>
    );
};

const COMMON_SHORTCUT_ITEMS = [NavigationItems.DISCOVER];

const LOGGED_IN_SHORTCUT_ITEMS = [
    NavigationItems.PODCASTS,
    NavigationItems.NEW_RELEASES,
    NavigationItems.IN_PROGRESS,
    NavigationItems.STARRED,
];

const SUBSCRIBER_SHORTCUT_ITEMS = [NavigationItems.BOOKMARKS];

interface NavigationProps {
    isVisible?: boolean;
    onLinkClick?: () => void;
}

const Navigation = ({ isVisible = true, onLinkClick }: NavigationProps) => {
    const navigate = useNavigate();
    const isUpNextOpen = useSelector(state => state.upNext.open);
    const isChaptersOpen = useSelector(getChaptersOpen);
    const isSubscriber = useSelector(hasPaidSubscription);
    const isLoggedIn = useSelector(userIsLoggedIn);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [isExiting, setIsExiting] = useState(false);

    const shouldShowFilesItem =
        useSelector(state => state.uploadedFiles?.data?.account?.totalFiles > 0) && isSubscriber;

    useEffect(() => {
        const SHORTCUT_ITEMS = [
            ...COMMON_SHORTCUT_ITEMS,
            ...(isLoggedIn ? LOGGED_IN_SHORTCUT_ITEMS : []),
            ...(isSubscriber ? SUBSCRIBER_SHORTCUT_ITEMS : []),
        ];

        SHORTCUT_ITEMS.forEach(({ id, path }) => {
            key(id.toString(), () => {
                const result = navigate(path);
                if (result instanceof Promise) {
                    result.catch(error => {
                        console.error('Navigation error:', error);
                    });
                }
            });
        });

        return () => {
            SHORTCUT_ITEMS.forEach(({ id }) => key.unbind(id.toString()));
        };
    }, [navigate]);

    const isInactive = isUpNextOpen || isChaptersOpen;

    const formatMessage = useFormatMessage();

    const handleCloseProfileMenu = () => {
        setIsExiting(true);
    };

    const handleOnNavigate = () => {
        setIsExiting(true);
        onLinkClick?.();
    };

    useEffect(() => {
        if (isExiting) {
            const timeout = setTimeout(() => {
                setShowProfileMenu(false);
                setIsExiting(false);
            }, 300);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isExiting]);

    return (
        <NavigationWrapper aria-label="Navigation Side Menu" $isVisible={isVisible}>
            <LogoLink isInactive={isInactive} />
            <ScrollingCover />
            <Spaces />
            <FeatureLock
                title={formatMessage('feature-podcasts-unavailable-title')}
                description={formatMessage('feature-podcasts-unavailable-description')}
                redirect={NavigationItems.PODCASTS.path}
                source="podcasts"
            >
                <Link navigationItem={NavigationItems.PODCASTS} onLinkClick={onLinkClick} />
            </FeatureLock>
            <Link navigationItem={NavigationItems.DISCOVER} onLinkClick={onLinkClick} />
            <Spaces $n={4} />
            <FeatureLock
                title={formatMessage('feature-new-releases-unavailable-title')}
                description={formatMessage('feature-new-releases-unavailable-description')}
                redirect={NavigationItems.NEW_RELEASES.path}
                source="new_releases"
            >
                <Link navigationItem={NavigationItems.NEW_RELEASES} onLinkClick={onLinkClick} />
            </FeatureLock>
            <FeatureLock
                title={formatMessage('feature-in-progress-unavailable-title')}
                description={formatMessage('feature-in-progress-unavailable-description')}
                redirect={NavigationItems.IN_PROGRESS.path}
                source="in_progress"
            >
                <Link navigationItem={NavigationItems.IN_PROGRESS} onLinkClick={onLinkClick} />
            </FeatureLock>
            <FeatureLock
                title={formatMessage('feature-starred-unavailable-title')}
                description={formatMessage('feature-starred-unavailable-description')}
                redirect={NavigationItems.STARRED.path}
                source="starred"
            >
                <Link navigationItem={NavigationItems.STARRED} onLinkClick={onLinkClick} />
            </FeatureLock>
            <FeatureLock
                requires="subscription"
                title={formatMessage('feature-bookmarks-unavailable-title')}
                description={formatMessage('feature-bookmarks-unavailable-description')}
                redirect={NavigationItems.BOOKMARKS.path}
                source="bookmarks"
            >
                <Link navigationItem={NavigationItems.BOOKMARKS} onLinkClick={onLinkClick} />
            </FeatureLock>
            <FeatureLock
                title={formatMessage('feature-history-unavailable-title')}
                description={formatMessage('feature-history-unavailable-description')}
                redirect={NavigationItems.HISTORY.path}
                source="history"
            >
                <Link navigationItem={NavigationItems.HISTORY} onLinkClick={onLinkClick} />
            </FeatureLock>
            {shouldShowFilesItem && (
                <>
                    <Spaces $n={4} />
                    <Link
                        navigationItem={NavigationItems.UPLOADED_FILES}
                        onLinkClick={onLinkClick}
                    />
                </>
            )}
            <ShowOnMobile>
                {isLoggedIn && (
                    <>
                        <Spaces $n={4} />
                        <Link
                            navigationItem={NavigationItems.PROFILE}
                            onClick={() => setShowProfileMenu(true)}
                        />
                        {showProfileMenu && (
                            <ProfileMenu
                                className={`profile-menu${isExiting ? ' exiting' : ''}`}
                                onNavigate={handleOnNavigate}
                                onDismiss={handleCloseProfileMenu}
                            />
                        )}
                    </>
                )}

                {!isLoggedIn && (
                    <>
                        <Spaces $n={4} />
                        <Link navigationItem={NavigationItems.CREATE_ACCOUNT} />
                        <Link navigationItem={NavigationItems.SIGN_IN} />
                    </>
                )}
            </ShowOnMobile>
        </NavigationWrapper>
    );
};

export default Navigation;
