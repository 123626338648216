import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import useQueryRedirect from './useQueryRedirect';

const useNavigateOrRedirect = () => {
    const navigate = useNavigate();
    const queryRedirect = useQueryRedirect();

    const navigateOrRedirect = useCallback(
        (url: string) => {
            if (queryRedirect) {
                navigate(queryRedirect, { replace: true });
            } else {
                navigate(url, { replace: true });
            }
        },
        [navigate, queryRedirect],
    );

    return navigateOrRedirect;
};

export default useNavigateOrRedirect;
