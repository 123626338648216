import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import {
    StyledLegacyNotificationBanner,
    StyledLegacyNotificationLink,
} from './LegacyNotificationBanner.styled';

const LegacyNotificationBanner = () => {
    const formatMessage = useFormatMessage();

    return (
        <StyledLegacyNotificationBanner>
            <div>
                {formatMessage('outdated-app', {
                    downloadLink: (caption: string) => (
                        <StyledLegacyNotificationLink
                            href="https://pocketcasts.com/downloads"
                            target="_blank"
                        >
                            {caption}
                        </StyledLegacyNotificationLink>
                    ),
                })}
            </div>
        </StyledLegacyNotificationBanner>
    );
};

export default LegacyNotificationBanner;
