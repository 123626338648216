import { connect } from 'react-redux';
import { MAX_DAYS_FOR_EXPIRY_COUNTDOWN } from '../../../model/subscription';
import { RootState } from '../../../redux/reducers';
import { getSubscriptionTier } from '../../../redux/reducers/selectors';
import DynamicAccountIcon from './DynamicAccountIcon';

/**
 * Order of precedence:
 * If it's the large icon, show the large version, which includes the expiring countdown.
 * If it's the small icon:
 *     If files are uploading, show the Uploading Files version
 *     If the user's subscription is expiring soon, show the expiring version
 *     Otherwise show the basic version
 */
const mapStateToProps = (state: RootState) => {
    // Subscription details
    const subscriptionAutoRenews = state.subscription.data && state.subscription.data.autoRenewing;
    const subscriptionExpiryDate = state.subscription.data && state.subscription.data.expiryDate;

    const currentDate = new Date();
    const expiryDate = new Date(state.subscription.data && state.subscription.data.expiryDate);
    const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // File upload details
    const { filesOrder, files } = state.uploadManager;
    const isUploadingFiles = filesOrder.length >= 1;

    const { uploadProgress, uploadTotal, hasUploadError, hasIncompleteUploads } = filesOrder.reduce(
        (result: Record<string, unknown>, current: string) => ({
            uploadTotal: (result.uploadTotal as number) + files[current].size,
            uploadProgress: (result.uploadProgress as number) + files[current].progress,
            hasUploadError: (result.hasUploadError || !!files[current].error) as boolean,
            hasIncompleteUploads: (result.hasIncompleteUploads ||
                !files[current].complete) as boolean,
        }),
        {
            uploadProgress: 0,
            uploadTotal: 0,
            hasUploadError: false,
            hasIncompleteUploads: false,
        },
    );

    const uploadProgressPercent = Math.min(100, Math.max(0, (100 * uploadProgress) / uploadTotal));

    const uploadProps = isUploadingFiles
        ? {
              hasUploadError,
              uploadsAreAllComplete: !hasIncompleteUploads,
              uploadProgressPercent,
          }
        : {};

    return {
        tier: getSubscriptionTier(state),
        shouldShowBasicAccountIcon:
            subscriptionAutoRenews ||
            !subscriptionExpiryDate ||
            diffDays > MAX_DAYS_FOR_EXPIRY_COUNTDOWN,
        percentDepleted: (-100 / MAX_DAYS_FOR_EXPIRY_COUNTDOWN) * diffDays + 100,
        isUploadingFiles,
        ...uploadProps,
    };
};

const DynamicAccountIconComponent = connect(mapStateToProps)(DynamicAccountIcon);

export { DynamicAccountIconComponent as DynamicAccountIcon };
