export const LOCAL_STORAGE_KEY_EMAIL = 'email';
export const LOCAL_STORAGE_KEY_SUBSCRIPTION_DATA = 'subscriptionData';

export const LOCAL_STORAGE_KEY_AUTOPLAY_CONFIG = 'autoplayConfig';

export const LOCAL_STORAGE_KEY_LANGUAGE = 'language';
export const LOCAL_STORAGE_KEY_MUTED = 'muted';
export const LOCAL_STORAGE_KEY_SPEED = 'speed';
export const LOCAL_STORAGE_KEY_THEME = 'theme';
export const LOCAL_STORAGE_KEY_VOLUME = 'volume';

export const LOCAL_STORAGE_KEY_FILES_SORT_COLUMN = 'files_sort_column';
export const LOCAL_STORAGE_KEY_FILES_SORT_ORDER = 'files_sort_order';
export const LOCAL_STORAGE_KEY_FILES = {
    COLUMN_NAME: 'name',
    COLUMN_SIZE: 'size',
    COLUMN_DURATION: 'duration',
    COLUMN_DATE: 'date',

    ORDER_ASCENDING: 'ascending',
    ORDER_DESCENDING: 'descending',
};

export const LOCAL_STORAGE_KEY_LOGS = 'logs';
export const LOCAL_STORAGE_KEY_ENABLE_FOLDERS = 'enableFolders';
export const LOCAL_STORAGE_KEY_PERFORMANCE_MODE = 'performanceMode';
export const LOCAL_STORAGE_KEY_SEARCH_HISTORY = 'searchHistory';

export const LOCAL_STORAGE_KEY_CORS_CHECKED_URLS = 'corsCheckedUrls';

/**
 * Local Storage key: Is "Up Next" shuffled?
 */
export const LOCAL_STORAGE_KEY_SHUFFLED = 'enableShuffle';
