import PodcastImage from 'components/PodcastImage';
import { PodcastImagePlaceholder } from 'components/PodcastImagePlaceholder';
import { useSelector } from 'hooks/react-redux-typed';
import React from 'react';
import { getEpisodeImageUrl } from '../../redux/reducers/selectors';
import { FitImage } from './EpisodeImage.styled';

type EpisodeWithImage = {
    imageUrl?: string;
    podcastUuid?: string;
    title?: string;
    uuid: string;
};

type Props = {
    episode: EpisodeWithImage;
    borderRadius?: number;
    className?: string;
};

const EpisodeImage = ({ episode, borderRadius, className }: Props) => {
    const showNotesImage = useSelector(state => getEpisodeImageUrl(state, episode.uuid));
    const imageUrl = episode.imageUrl ?? showNotesImage;

    if (imageUrl) {
        return (
            <FitImage
                src={imageUrl}
                placeholder={<PodcastImagePlaceholder />}
                alt={episode.title}
                borderRadius={borderRadius}
                className={className}
            />
        );
    }
    return (
        <PodcastImage
            uuid={episode.podcastUuid}
            title={episode.title}
            borderRadius={borderRadius}
            className={className}
        />
    );
};

export default EpisodeImage;
