import { useEffect } from 'react';
import { NavigationItems } from '../helper/NavigationHelper';
import * as fromEpisodeActions from '../redux/actions/episode.actions';
import { getEpisode } from '../redux/reducers/selectors/episode.selectors';
import { useDispatch, useSelector } from './react-redux-typed';
import usePodcastDetails from './usePodcastDetails';

const useEpisodeDetails = (podcastUuid: string, episodeUuid: string) => {
    const dispatch = useDispatch();
    const { podcast, loadFailed, isLoading } = usePodcastDetails(podcastUuid);
    const episode = podcast?.episodes?.find(e => e.uuid === episodeUuid);
    const episodeUrl = `${NavigationItems.PODCASTS.path}/${podcast?.uuid}/${episode?.uuid}`;
    const episodeSync = useSelector(getEpisode);

    useEffect(() => {
        dispatch(
            fromEpisodeActions.Actions.openEpisode(
                { uuid: episodeUuid, podcastUuid },
                { eventSource: 'episode_screen' },
            ),
        );
    }, [dispatch, episodeUuid, podcastUuid]);

    return {
        episode: {
            ...episode,
            ...episodeSync,
        },
        loadFailed,
        isLoading,
        episodeUrl,
    };
};

export default useEpisodeDetails;
