export const clearLocalStorageExcept = (preservedKeys: string[]): void => {
    // Backup preserved values
    const backup: Record<string, string | null> = {};
    preservedKeys.forEach(key => {
        backup[key] = localStorage.getItem(key);
    });

    // Clear localStorage
    localStorage.clear();

    // Restore preserved values
    preservedKeys.forEach(key => {
        if (backup[key] !== null) {
            localStorage.setItem(key, backup[key] as string);
        }
    });
};

export const clearLocalStorage = () => clearLocalStorageExcept(['free']);
