import styled from 'styled-components';
import { SubscriptionTier } from '../../model/types';
import { patronColor, plusColor } from '../../styles/colors';
import { defaultFontStack } from '../../styles/ModernStyle.styled';

export const Wrapper = styled.span<{ $width: number; $height: number; $tier: SubscriptionTier }>`
    font-family: ${defaultFontStack};
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: ${props => (props.$height / 16) * 14}px;
    color: ${props => props.theme.legacyScss['theme-background']};
    background: ${props => props.theme.legacyScss['theme-text-primary']};
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.24);
    border-radius: 100px;
    padding: ${props => (props.$height / 16) * 4}px ${props => (props.$width / 16) * 8}px;
    line-height: 1.43;
    svg {
        flex-shrink: 0;
        margin-right: 0.25em;
        color: ${props => (props.$tier === 'Patron' ? patronColor : plusColor)};
        width: ${props => (props.$width / 16) * 14}px;
        height: ${props => (props.$height / 16) * 14}px;
    }
`;
