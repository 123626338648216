import {
    EpisodeShowNotes,
    Folder,
    PodcastCacheParsed,
    PodcastListPodcast,
    PodcastListPositions,
    PodcastRating,
    PodcastSyncInfo,
    PodcastTintColors,
    TodoFixmeMigrationType,
    TracksEventSource,
} from 'model/types';
import { PodcastListRearrangeAction } from 'pages/PodcastsPage/model';
import { action } from '../api';
import { ActionsUnion, createAction } from './action-creators';

export const fetchPodcastRating = (payload: { uuid: string; forceRefetch?: boolean }) =>
    action('PODCAST_FETCH_RATING', payload);

export const fetchPodcastRatingSucceeded = (payload: {
    uuid: string;
    rating: PodcastRating | null;
}) => action('PODCAST_FETCH_RATING_SUCCEEDED', payload);

export const fetchPodcastRatingFailed = (payload: { uuid: string }) =>
    action('PODCAST_FETCH_RATING_FAILED', payload);

export const fetchPodcastShowNotes = (uuid: string) => action('PODCAST_FETCH_SHOW_NOTES', { uuid });

export const fetchPodcastShowNotesSucceeded = ({
    uuid,
    episodeShowNotes,
}: {
    uuid: string;
    episodeShowNotes: EpisodeShowNotes[];
}) => action('PODCAST_FETCH_SHOW_NOTES_SUCCEEDED', { uuid, episodeShowNotes });

export enum ActionTypes {
    DOWNLOAD_SUBSCRIBED_PODCASTS = '@podcasts/DOWNLOAD_SUBSCRIBED_PODCASTS',
    DOWNLOAD_SUBSCRIBED_PODCASTS_FAILED = '@podcasts/DOWNLOAD_SUBSCRIBED_PODCASTS_FAILED',
    DOWNLOAD_SUBSCRIBED_PODCASTS_SUCCESS = '@podcasts/DOWNLOAD_SUBSCRIBED_PODCASTS_SUCCESS',

    DOWNLOAD_PODCAST = '@podcasts/DOWNLOAD_PODCAST',
    ADD_PODCAST = '@podcasts/ADD_PODCAST',

    SUBSCRIBE_TO_PODCAST = '@podcasts/SUBSCRIBE_TO_PODCAST',
    SUBSCRIBE_TO_PODCAST_FAILED = '@podcasts/SUBSCRIBE_TO_PODCAST_FAILED',
    UNSUBSCRIBE_FROM_PODCAST = '@podcasts/UNSUBSCRIBE_FROM_PODCAST',

    UPDATE_PODCAST_COLORS = '@podcasts/UPDATE_PODCAST_COLORS',
    UPDATE_PODCASTS_COLORS = '@podcasts/UPDATE_PODCASTS_COLORS',

    PODCAST_LIST_REARRANGE = '@podcasts/PODCAST_LIST_REARRANGE',
    PODCAST_LIST_UPDATE_POSITIONS = '@podcasts/PODCAST_LIST_UPDATE_POSITIONS',
}

export const Actions = {
    addPodcast: (podcastCache: PodcastCacheParsed, podcastSync?: PodcastSyncInfo) =>
        createAction(ActionTypes.ADD_PODCAST, { podcastCache, podcastSync }),

    downloadPodcast: (podcastUuid: string, includeEpisodes?: boolean) =>
        createAction(ActionTypes.DOWNLOAD_PODCAST, { podcastUuid, includeEpisodes }),

    downloadSubscribedPodcasts: () => createAction(ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS),

    downloadSubscribedPodcastsSuccess: (podcasts: PodcastListPodcast[], folders: Folder[]) =>
        createAction(ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS_SUCCESS, {
            podcasts,
            folders,
        }),

    downloadSubscribedPodcastsFailed: () =>
        createAction(ActionTypes.DOWNLOAD_SUBSCRIBED_PODCASTS_FAILED),

    updatePodcastColors: (uuid: string, colors: PodcastTintColors) =>
        createAction(ActionTypes.UPDATE_PODCAST_COLORS, { uuid, colors }),

    updatePodcastsColors: (uuidToColors: Record<string, PodcastTintColors>) =>
        createAction(ActionTypes.UPDATE_PODCASTS_COLORS, { uuidToColors }),

    subscribeToPodcastFailed: (podcast: TodoFixmeMigrationType, loadFailed: boolean) =>
        createAction(ActionTypes.SUBSCRIBE_TO_PODCAST_FAILED, { podcast, loadFailed }),

    subscribeToPodcast: (
        podcast: TodoFixmeMigrationType,
        tracksProperties: { eventSource: TracksEventSource },
    ) => createAction(ActionTypes.SUBSCRIBE_TO_PODCAST, { podcast, tracksProperties }),

    unsubscribeFromPodcast: (
        podcastUuid: string,
        tracksProperties: { eventSource: TracksEventSource },
    ) => createAction(ActionTypes.UNSUBSCRIBE_FROM_PODCAST, { podcastUuid, tracksProperties }),

    rearrangePodcastList: (actions: PodcastListRearrangeAction[]) =>
        createAction(ActionTypes.PODCAST_LIST_REARRANGE, { actions }),

    updatePodcastListPositions: (positions: PodcastListPositions) =>
        createAction(ActionTypes.PODCAST_LIST_UPDATE_POSITIONS, { positions }),

    fetchPodcastRating,
    fetchPodcastRatingSucceeded,
    fetchPodcastRatingFailed,
    fetchPodcastShowNotes,
    fetchPodcastShowNotesSucceeded,
};

export type Actions = ActionsUnion<typeof Actions>;
