import { formatTime } from 'helper/TimeHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import { AnimatedPlayButton } from 'pages/LoggedInPageChrome/PlayerControls/AnimatedPlayButton';
import React, {
    ChangeEvent,
    FocusEvent,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useDispatch } from '../../../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import { getPlayerIsPlaying } from '../../../../redux/reducers/selectors';
import { ShareProps } from '../SharePopup';
import {
    AnimatedPlayButtonWrapper,
    EditPlayPositionIndicator,
    TimelineWrapper,
    WaveFormContainer,
    WaveFormSvg,
} from '../SharePopup.styled';
import { useAudioControl } from '../hooks/useAudioControl';
import { useWaveform } from '../hooks/useWaveform';
import { timestampToSeconds } from '../util';
import {
    EndHandle,
    Gripper,
    OverlayHandle,
    StartHandle,
    TimeSteppers,
    Trimmer,
} from './ClipEditor.styled';
import { TimeInput } from './TimeInput';

interface Props extends ShareProps {
    onUpdateStartTime: (startTime: number) => void;
    onUpdateEndTime: (endTime: number) => void;
    initialStartTime: number | null;
    initialEndTime: number | null;
}

function ClipEditor({
    audioUrl,
    duration,
    shareTime,
    isPlaying,
    playedUpTo,
    onUpdateStartTime,
    onUpdateEndTime,
    initialStartTime,
    initialEndTime,
}: Props) {
    const theme = useTheme();
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();

    const waveFormContainerRef: RefObject<HTMLDivElement> = useRef(null);
    const waveFormSvgRef: RefObject<SVGSVGElement> = useRef(null);

    useWaveform({ ref: waveFormSvgRef, url: audioUrl, theme, duration });

    const isDurationOverHour = duration > 3600;
    const defaultStart =
        initialStartTime !== null ? initialStartTime : isPlaying ? playedUpTo : (shareTime ?? 0);
    let defaultEnd;

    if (initialEndTime !== null) {
        defaultEnd = initialEndTime;
    } else {
        defaultEnd = Math.min(duration, defaultStart + 30);
    }

    const [start, setStart] = useState((defaultStart / duration) * 100);
    const [end, setEnd] = useState((defaultEnd / duration) * 100);

    const [startTime, setStartTime] = useState(defaultStart);
    const [endTime, setEndTime] = useState(defaultEnd);

    const defaultStartTime = formatTime(defaultStart);
    const defaultEndTime = formatTime(defaultEnd);

    const [inputStartTime, setInputStartTime] = useState(defaultStartTime);
    const [inputEndTime, setInputEndTime] = useState(defaultEndTime);

    const [dragging, setDragging] = useState<string | null>(null);

    const { isClipPlaying, handlePlayClicked, handlePauseClicked, playPosition } = useAudioControl({
        audioUrl,
        startTime,
        endTime,
        duration,
        type: 'edit',
    });

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            if (dragging) {
                handlePauseClicked();

                const waveFormContainer = waveFormContainerRef.current;
                const svg = waveFormSvgRef.current;

                if (!waveFormContainer || !svg) {
                    return;
                }

                const containerRect = waveFormContainer.getBoundingClientRect();
                const percent = ((event.clientX - containerRect.left) / containerRect.width) * 100;

                const updateTimes = (newStart: number, newEnd: number) => {
                    setStart(newStart);
                    setEnd(newEnd);

                    const newStartTime = (newStart / 100) * duration;
                    const newEndTime = (newEnd / 100) * duration;

                    setStartTime(newStartTime);
                    setEndTime(newEndTime);
                    setInputStartTime(formatTime(newStartTime));
                    setInputEndTime(formatTime(newEndTime));
                };

                if (dragging === 'start' && percent < end) {
                    const newStart = Math.max(0, Math.min(percent, end));
                    updateTimes(newStart, end);
                } else if (dragging === 'end' && percent > start) {
                    const newEnd = Math.min(100, Math.max(percent, start));
                    updateTimes(start, newEnd);
                } else if (dragging === 'overlay') {
                    const width = end - start;

                    let newStart = percent - width / 2;
                    let newEnd = percent + width / 2;

                    if (newStart < 0) {
                        newStart = 0;
                        newEnd = width;
                    } else if (newEnd > 100) {
                        newStart = 100 - width;
                        newEnd = 100;
                    }

                    updateTimes(newStart, newEnd);
                }
            }
        },
        [dragging, start, end, duration, handlePauseClicked],
    );

    const handleMouseUp = useCallback(() => {
        setDragging(null);
    }, []);

    const handleMouseDown = useCallback((handle: string) => {
        setDragging(handle);
    }, []);

    const handleStartTimeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setInputStartTime(event.target.value);
            handlePauseClicked();
        },
        [handlePauseClicked],
    );

    const handleEndTimeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setInputEndTime(event.target.value);
            handlePauseClicked();
        },
        [handlePauseClicked],
    );

    const handleStartTimeBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const newStartTime = timestampToSeconds(event.target.value);
            if (newStartTime <= endTime - 1 && newStartTime >= 0) {
                setStartTime(Math.max(0, Math.min(newStartTime, duration)));
                setStart((newStartTime / duration) * 100);
                setInputStartTime(formatTime(newStartTime));
            } else {
                setInputStartTime(formatTime(startTime));
            }
        },
        [duration, endTime, startTime],
    );

    const handleEndTimeBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const newEndTime = timestampToSeconds(event.target.value);
            if (newEndTime >= startTime + 1) {
                setEndTime(Math.max(0, Math.min(newEndTime, duration)));
                setEnd((newEndTime / duration) * 100);
                setInputEndTime(formatTime(newEndTime));
            } else {
                setInputEndTime(formatTime(endTime));
            }
        },
        [duration, endTime, startTime],
    );

    const adjustTime = useCallback(
        (time: string, isStartTime: boolean, adjustment: number) => {
            handlePauseClicked();
            let seconds = timestampToSeconds(time) + adjustment;
            seconds = Math.max(seconds, 0);
            if (!isStartTime) {
                seconds = Math.max(seconds, startTime + 1);
            } else {
                seconds = Math.min(seconds, endTime - 1);
            }
            const formattedTime = formatTime(seconds);
            if (isStartTime) {
                setStartTime(seconds);
                setStart((seconds / duration) * 100);
            } else {
                setEndTime(seconds);
                setEnd((seconds / duration) * 100);
            }
            return formattedTime;
        },
        [duration, handlePauseClicked, startTime],
    );

    const playerIsPlaying = useSelector(getPlayerIsPlaying);

    const incrementTime = useCallback(
        (time: string, isStartTime: boolean) => adjustTime(time, isStartTime, 1),
        [adjustTime],
    );

    const decrementTime = useCallback(
        (time: string, isStartTime: boolean) => adjustTime(time, isStartTime, -1),
        [adjustTime],
    );

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    useEffect(() => {
        onUpdateStartTime(startTime);
    }, [startTime, onUpdateStartTime]);

    useEffect(() => {
        onUpdateEndTime(endTime);
    }, [endTime, onUpdateEndTime]);

    const handlePlay = () => {
        if (playerIsPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'clip_sharing' }));
        }

        handlePlayClicked();
    };

    return (
        <>
            <TimelineWrapper>
                <WaveFormContainer ref={waveFormContainerRef}>
                    <WaveFormSvg ref={waveFormSvgRef} xmlns="http://www.w3.org/2000/svg" />
                    <EditPlayPositionIndicator
                        style={{ left: `${playPosition}%` }}
                        $isClipPlaying={isClipPlaying}
                    />
                    <Trimmer
                        style={{
                            left: `calc(${start}% - 16px)`,
                            width: `calc(${end - start}% + 36px)`,
                        }}
                    >
                        <StartHandle onMouseDown={() => handleMouseDown('start')}>
                            <Gripper className="left-gripper" />
                        </StartHandle>
                        <EndHandle onMouseDown={() => handleMouseDown('end')}>
                            <Gripper className="right-gripper" />
                        </EndHandle>
                        <OverlayHandle onMouseDown={() => handleMouseDown('overlay')} />
                    </Trimmer>
                </WaveFormContainer>
                <AnimatedPlayButtonWrapper>
                    <AnimatedPlayButton
                        playing={isClipPlaying}
                        onPlayClicked={handlePlay}
                        onPauseClicked={handlePauseClicked}
                    />
                </AnimatedPlayButtonWrapper>
            </TimelineWrapper>
            <TimeSteppers>
                {formatMessage('share-cut-clip', {
                    startTime: (
                        <TimeInput
                            isDurationOverHour={isDurationOverHour}
                            value={inputStartTime}
                            onChange={handleStartTimeChange}
                            onBlur={handleStartTimeBlur}
                            onIncrement={() =>
                                setInputStartTime(incrementTime(inputStartTime, true))
                            }
                            onDecrement={() =>
                                setInputStartTime(decrementTime(inputStartTime, true))
                            }
                        />
                    ),
                    endTime: (
                        <TimeInput
                            isDurationOverHour={isDurationOverHour}
                            value={inputEndTime}
                            onChange={handleEndTimeChange}
                            onBlur={handleEndTimeBlur}
                            onIncrement={() => setInputEndTime(incrementTime(inputEndTime, false))}
                            onDecrement={() => setInputEndTime(decrementTime(inputEndTime, false))}
                        />
                    ),
                })}
            </TimeSteppers>
        </>
    );
}

export default ClipEditor;
