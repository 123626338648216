import { DateText } from 'components';
import PodcastImage from 'components/PodcastImage';
import useFormatMessage from 'hooks/useFormatMessage';
import { EpisodeSearchResult } from 'model/types';
import React, { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router';
import { SearchResultItem } from '.';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../redux/actions/player.actions';
import { getPlayingEpisode } from '../../redux/reducers/selectors';
import urls from '../../urls';

type Props = {
    episode: EpisodeSearchResult;
    onClear?: () => void;
    onClick?: () => void;
};

const EpisodeSearchResultItem = ({ episode, onClear, onClick }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const playingEpisode = useSelector(getPlayingEpisode);
    const isPlaying = playingEpisode?.uuid === episode.uuid;
    const label = `${formatMessage('episode')}. ${episode.title}. ${episode.podcast_title}`;
    const navigate = useNavigate();

    const handleClick: MouseEventHandler = e => {
        e.preventDefault();
        onClick?.();
        navigate(urls.episodePath(episode.podcast_uuid, episode.uuid));
    };

    const handlePlayPauseClick: MouseEventHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        if (isPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'search' }));
        } else {
            dispatch(
                fromPlayerActions.Actions.playEpisode(episode.uuid, episode.podcast_uuid, {
                    eventSource: 'search',
                }),
            );
        }
        onClick?.();
    };

    return (
        <SearchResultItem
            image={<PodcastImage uuid={episode.podcast_uuid} />}
            title={episode.title}
            description={episode.podcast_title}
            smallText={<DateText date={episode.published} />}
            onClear={onClear}
            onClick={handleClick}
            onPlayPauseClick={handlePlayPauseClick}
            isPlaying={isPlaying}
            aria-label={label}
        />
    );
};

export default EpisodeSearchResultItem;
