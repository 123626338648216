import styled from 'styled-components';
import { Typography } from '../../../styles';
import { Row } from '../../EpisodesTable/ListEpisodes.styled';

export const EpisodeTypeText = styled.span`
    ${Row} & {
        ${Typography.Desktop.H65}
        padding-right: 8px;
        color: ${props => props.theme.legacyScss['theme-text-secondary']};
    }
`;

const AccentedEpisodeTypeText = styled(EpisodeTypeText)`
    ${Typography.Desktop['H80-C']}
    text-transform: uppercase;
    padding: 1px 8px 0 0;
    color: ${props => props.color};
`;

export const Bonus = styled(AccentedEpisodeTypeText)``;
export const Trailer = styled(AccentedEpisodeTypeText)``;
export const SeasonTrailer = styled(AccentedEpisodeTypeText)``;
export const Season = styled(EpisodeTypeText)``;
