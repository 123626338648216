import React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { isMacApp } from '../../helper/Browser';
import { NavigationItems } from '../../helper/NavigationHelper';
import useIsBeta from '../../hooks/useIsBeta';
import { PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION } from '../../model/page';
import { DARK_THEME } from '../../model/theme/theme-dark';
import { gridSize } from '../../styles';
import { Logo } from '../Logo';
import Beta from '../Logo/Beta';
import { BetaLogoWrapper } from '../Logo/Beta.styled';
import { SLIDING_MODAL_ANIMATION_TIME_IN_MS } from '../SlidingModal/SlidingModal.styled';

const StyledLogoLink = styled(Link)`
    position: sticky;
    top: 0;
    left: 0;
    height: ${8 * gridSize}px;
    padding-left: ${3 * gridSize}px;
    flex-shrink: 0;

    ${isMacApp() && `padding-top: ${3 * gridSize}px;`}

    display: flex;
    align-items: center;

    text-decoration: none;

    z-index: 21;

    svg.logo {
        width: 32px;
        height: 32px;
        @media (min-width: ${PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION}px) {
            width: 40px;
            height: 40px;
        }
    }

    svg.logo path {
        transition: fill ${SLIDING_MODAL_ANIMATION_TIME_IN_MS}ms ease;
    }
`;

interface LogoLinkProps {
    isInactive?: boolean;
}

const LogoLink: React.FC<LogoLinkProps> = ({ isInactive = false }) => {
    const [betaFeaturesEnabled] = useIsBeta();

    return (
        <StyledLogoLink className="logo-link" to={NavigationItems.PODCASTS.path} aria-hidden="true">
            <BetaLogoWrapper>
                <Logo
                    className="logo"
                    color={isInactive ? '#2d2e2f' : undefined}
                    lineColor={isInactive ? DARK_THEME.tokens['contrast-03'] : undefined}
                />
                {betaFeaturesEnabled && <Beta />}
            </BetaLogoWrapper>
        </StyledLogoLink>
    );
};

export default LogoLink;
