import Gravatar from 'components/Gravatar';
import { Navigation } from 'components/Navigation';
import { LOCAL_STORAGE_KEY_EMAIL } from 'model/local-storage';
import { isDarkTheme } from 'model/theme';
import React from 'react';
import { FocusOn } from 'react-focus-on';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { useSelector } from '../../hooks/react-redux-typed';
import useFormatMessage from '../../hooks/useFormatMessage';
import useTracks from '../../hooks/useTracks';
import { getTheme } from '../../redux/reducers/selectors';
import { userIsLoggedIn } from '../../redux/reducers/selectors/user.selectors';
import urls from '../../urls';
import { Icon } from '../Icon';
import LogoLink from '../LogoLink/LogoLink';
import { SimpleAccountIcon } from './DynamicAccountIcon/SimpleAccountIcon';
import { ProfileMenu } from './ProfileMenu';
import { SearchBar } from './SearchBar';
import {
    CreateAccountButton,
    GetTheAppButton,
    HamburgerButton,
    NavBarWrapper,
    NavButtonLink,
    ProfileIconWrapper,
    ProfileWrapper,
    RightSideContainer,
    RoundedGravatar,
    SearchWrapper,
    SecondaryNavLinks,
} from './SecondaryNav.styled';

interface SecondaryNavProps {
    onHamburgerClick?: () => void;
    showNavigation?: boolean;
}

const SecondaryNav = ({ onHamburgerClick, showNavigation }: SecondaryNavProps) => {
    const [showProfileMenu, setShowProfileMenu] = React.useState(false);
    const [showSearch, setShowSearch] = React.useState(false);
    const email = localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL);
    const isLoggedIn = useSelector(userIsLoggedIn);
    const formatMessage = useFormatMessage();
    const theme = useSelector(getTheme);
    const { recordEvent, setDefaultEventSource } = useTracks();
    const location = useLocation();

    const Outline = () => (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 23.999999999999996 1.5 A 22.5 22.5 0 1 0 24.003926990797055 1.5000003426945945"
            stroke={isDarkTheme(theme) ? '#5F6568' : '#B8C3C9'}
            fill="none"
            strokeWidth="3"
        ></path>
    );

    const createAccountClicked = () => {
        setDefaultEventSource('create_account_button');
        recordEvent('create_account_clicked');
    };

    const loginClicked = () => {
        setDefaultEventSource('log_in_button');
        recordEvent('log_in_clicked');
    };

    const handleHamburgerClick = () => {
        onHamburgerClick?.();
    };

    return (
        <NavBarWrapper>
            <LogoLink />
            <Navigation />
            <RightSideContainer>
                {!showSearch && (
                    <GetTheAppButton to="https://pocketcasts.com/get?source=webplayer&campaign=nav">
                        <FormattedMessage id="get-the-app" />
                    </GetTheAppButton>
                )}
                <SearchWrapper $isLoggedIn={isLoggedIn} $isExpanded={showSearch}>
                    <SearchBar onSearchVisibilityChange={setShowSearch} />
                </SearchWrapper>
            </RightSideContainer>
            {!isLoggedIn && (
                <SecondaryNavLinks className="secondary-nav-links">
                    <CreateAccountButton
                        data-testid="create-account-button"
                        className="create-account-button"
                        kind="primary"
                        to={urls.registerPath({ redirect: location.pathname })}
                        onClick={createAccountClicked}
                    >
                        {formatMessage('create-account')}
                    </CreateAccountButton>
                    <NavButtonLink kind="secondary" to={urls.signInPath()} onClick={loginClicked}>
                        {formatMessage('log-in')}
                    </NavButtonLink>
                </SecondaryNavLinks>
            )}
            {isLoggedIn && (
                <FocusOn
                    enabled={showProfileMenu}
                    scrollLock={false}
                    noIsolation
                    onClickOutside={() => setShowProfileMenu(false)}
                    onEscapeKey={() => setShowProfileMenu(false)}
                >
                    <ProfileWrapper>
                        <ProfileIconWrapper
                            aria-label="Open Profile Menu"
                            role="button"
                            onClick={() => setShowProfileMenu(true)}
                        >
                            <RoundedGravatar width="50" height="50" viewBox="0 0 48 48" fill="none">
                                <Gravatar
                                    asSvgImage
                                    email={email || ''}
                                    fallback={
                                        <SimpleAccountIcon asSvgImage width={48} height={48} />
                                    }
                                    size={48}
                                ></Gravatar>
                                <Outline />
                            </RoundedGravatar>
                        </ProfileIconWrapper>
                        {showProfileMenu && (
                            <ProfileMenu onNavigate={() => setShowProfileMenu(false)} />
                        )}
                    </ProfileWrapper>
                </FocusOn>
            )}

            <HamburgerButton onClick={handleHamburgerClick} $isOpen={showNavigation}>
                <Icon id={showNavigation ? 'close' : 'drag-handle'} />
            </HamburgerButton>
        </NavBarWrapper>
    );
};

export default SecondaryNav;
