import { EpisodeCardFeature } from 'components/EpisodeCard';
import usePodcastColors from 'hooks/usePodcastColors';
import { ListData } from 'model/types';
import React from 'react';
import * as fromPlayerActions from '../../../redux/actions/player.actions';

import { getPodcastTint } from 'model/theme';
import { useNavigate } from 'react-router';
import { useSelector } from '../../../hooks/react-redux-typed';
import { getTheme } from '../../../redux/reducers/selectors';
import urls from '../../../urls';

type Props = {
    list: ListData;
    onEpisodeClick?: (podcastUuid: string, episodeUuid: string) => void;
    onEpisodePlay?: (podcastUuid: string, episodeUuid: string) => void;
    onPodcastClick: (uuid: string) => void;
    playingEpisodeUuid: string;
    playEpisode: typeof fromPlayerActions.Actions.playEpisode;
    pauseEpisode: typeof fromPlayerActions.Actions.pause;
};

const DiscoverSingleEpisode = ({
    list,
    onEpisodeClick,
    onEpisodePlay,
    onPodcastClick,
    playingEpisodeUuid,
    playEpisode,
    pauseEpisode,
}: Props) => {
    const episode = list.episodes && list.episodes[0];
    const theme = useSelector(getTheme);
    const colors = usePodcastColors(episode.podcast_uuid);
    const tint = getPodcastTint(colors, theme) || undefined;
    const isPlaying = playingEpisodeUuid === episode.uuid;
    const navigate = useNavigate();

    const handleEpisodeClick = () => {
        onEpisodeClick?.(episode.podcast_uuid, episode.uuid);
        navigate(urls.episodePath(episode.podcast_uuid, episode.uuid));
    };

    const handlePlayPauseClick = () => {
        if (isPlaying) {
            pauseEpisode({ eventSource: 'discover' });
            return;
        }
        onEpisodePlay && onEpisodePlay(episode.podcast_uuid, episode.uuid);
        playEpisode(episode.uuid, episode.podcast_uuid, { eventSource: 'discover' });
    };

    if (!episode) {
        return null;
    }

    return (
        <EpisodeCardFeature
            podcastUuid={episode.podcast_uuid}
            podcastTitle={episode.podcast_title}
            title={episode.title}
            published={episode.published}
            duration={episode.duration}
            type={episode.type}
            label={list.title}
            text={list.description}
            color={tint}
            isPlaying={isPlaying}
            onClick={handleEpisodeClick}
            onPlayPauseClick={handlePlayPauseClick}
            onPodcastClick={() => onPodcastClick(episode.podcast_uuid)}
        />
    );
};

export default DiscoverSingleEpisode;
