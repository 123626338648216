import React from 'react';

import { Icon } from 'components/Icon';
import { SlideUpButtonWrapper } from './SlideUpButton.styled';

type Props = {
    isOpen: boolean;
    icon: React.ReactElement;
    onOpen?: () => void;
    onClose?: () => void;
};

export default function SlideUpButton({ isOpen, icon, onOpen, onClose, ...props }: Props) {
    const onTogglePopup = () => {
        if (isOpen) {
            onClose?.();
        } else {
            onOpen?.();
        }
    };

    return (
        <SlideUpButtonWrapper onClick={onTogglePopup} $isOpen={isOpen} {...props}>
            <span>{icon}</span>
            <span>
                <Icon id="chevron-down" />
            </span>
        </SlideUpButtonWrapper>
    );
}
