import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router';
import styled from 'styled-components';
import { CenteredPageChrome } from '../../components/CenteredPageChrome';
import { ChooseYourPlan } from '../../components/ChooseYourPlan';
import { FinePrintText } from '../../components/FinePrintText';
import { PlanBadge } from '../../components/PlanBadge';
import { SubscribeToPlanButton } from '../../components/SubscribeToPlanButton';
import { SubscribeToPlanButtonLink } from '../../components/SubscribeToPlanButtonLink';
import { getLocalizedSubscriptionPrice } from '../../helper/PaddleCheckoutHelper';
import { usePaddle } from '../../hooks/usePaddle';
import { usePaddleProducts } from '../../hooks/usePaddleProducts';
import useQueryRedirect from '../../hooks/useQueryRedirect';
import { getIsSubscriptionLoading, hasPaidSubscription } from '../../redux/reducers/selectors';
import { getJustSignedUp } from '../../redux/reducers/selectors/user.selectors';
import { fontFamilyRetina, gridSize } from '../../styles';
import urls from '../../urls';
import FeaturesShowcase from './FeaturesShowcase';

const StyledArticle = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 ${gridSize}px;
`;

const StyledHeader = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledTitle = styled.h1`
    font-family: ${fontFamilyRetina};
    color: ${props => props.theme.tokens['primary-text-01']};
    font-size: 36px;
    font-weight: 700;
    text-wrap: balance;
    max-width: 792px;
    text-align: center;
    margin-top: ${gridSize * 3}px;
    margin-bottom: ${gridSize * 1.5}px;
`;

const StyledSmall = styled.small`
    color: ${props => props.theme.tokens['primary-text-02']};
    font-size: 16px;
    font-weight: 400;
`;

const StyledMain = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledFeaturesShowcaseWrapper = styled.section`
    margin-top: 38px;
    width: 100%;
`;

const StyledSubscribeToPlanButtonWrapper = styled.div`
    min-width: 336px;
`;

const StyledSubscribeSectionWrapper = styled.section`
    margin-top: ${5 * gridSize}px;
    display: flex;
    gap: ${gridSize * 3}px;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const StyledChooseYourPlanTitle = styled.span`
    display: inline-block;
    text-wrap: balance;
    max-width: 420px;
    margin: 0;
    font-size: 36px;
`;

const StyledChooseYourPlanWrapper = styled.div`
    margin-top: 225px;
`;

const SubscribePage = () => {
    const products = usePaddleProducts();
    const { pathname } = useLocation();
    const yearlyPlusProduct = products['plus-yearly'];
    const plusPrice = yearlyPlusProduct ? getLocalizedSubscriptionPrice(yearlyPlusProduct) : null;
    const doSubscribeFlow = usePaddle();
    const isSubscriber = useSelector(hasPaidSubscription);
    const justSignedUp = useSelector(getJustSignedUp);
    const isSubscriptionLoading = useSelector(getIsSubscriptionLoading);
    const queryRedirect = useQueryRedirect();
    const subscribeSuccessRedirectPath =
        queryRedirect ?? (justSignedUp ? urls.discoverPath : urls.profilePath);
    const skipLinkUrl = queryRedirect || urls.startPath;

    return (
        <CenteredPageChrome>
            <StyledArticle>
                <StyledHeader>
                    <PlanBadge tier="Plus" size={20} />
                    <StyledTitle>
                        {!isSubscriber && <FormattedMessage id="subscribe-header-title" />}
                        {isSubscriber && <FormattedMessage id="subscribe-header-success-title" />}
                    </StyledTitle>
                    <StyledSmall>
                        {!isSubscriber && plusPrice && (
                            <FormattedMessage
                                id="subscribe-header-conditions"
                                values={{ price: plusPrice }}
                            />
                        )}

                        {isSubscriber && (
                            <FormattedMessage id="subscribe-header-success-subtitle" />
                        )}
                    </StyledSmall>
                </StyledHeader>
                <StyledMain>
                    <StyledFeaturesShowcaseWrapper>
                        <FeaturesShowcase />
                    </StyledFeaturesShowcaseWrapper>

                    <StyledSubscribeSectionWrapper>
                        <StyledSubscribeToPlanButtonWrapper>
                            {!isSubscriber && (
                                <SubscribeToPlanButton
                                    loading={isSubscriptionLoading}
                                    tier="Plus"
                                    onClick={() => doSubscribeFlow('plus-yearly')}
                                >
                                    <FormattedMessage id="subscribe-button-title" />
                                </SubscribeToPlanButton>
                            )}
                            {isSubscriber && (
                                <SubscribeToPlanButtonLink
                                    tier="Plus"
                                    to={subscribeSuccessRedirectPath}
                                >
                                    <FormattedMessage id="continue" />
                                </SubscribeToPlanButtonLink>
                            )}
                        </StyledSubscribeToPlanButtonWrapper>
                        {!isSubscriber && (
                            <FinePrintText>
                                <FormattedMessage
                                    id="subscribe-skip-message"
                                    values={{
                                        skipLink: text => <Link to={skipLinkUrl}>{text}</Link>,
                                        plansLink: text => (
                                            <a href={`${pathname}#our-plans`} rel="noreferrer">
                                                {text}
                                            </a>
                                        ),
                                    }}
                                />
                            </FinePrintText>
                        )}
                    </StyledSubscribeSectionWrapper>
                    {!isSubscriber && (
                        <StyledChooseYourPlanWrapper id="our-plans">
                            <ChooseYourPlan
                                onSubscribe={doSubscribeFlow}
                                title={
                                    <StyledChooseYourPlanTitle>
                                        <FormattedMessage id="subscribe-choose-plan-title" />
                                    </StyledChooseYourPlanTitle>
                                }
                            />
                        </StyledChooseYourPlanWrapper>
                    )}
                </StyledMain>
            </StyledArticle>
        </CenteredPageChrome>
    );
};

export default SubscribePage;
