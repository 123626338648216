import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { FOLDER_COLORS } from 'styles/colors';
import { useSelector } from '../../../../hooks/react-redux-typed';
import { getFolderColorValues } from '../../../../redux/reducers/selectors';
import { ColorOption, Fieldset } from './FolderColorSelector.styled';

export type Props = {
    value: number;
    onChange: (color: number) => void;
};

const FolderColorSelector = ({ onChange, value }: Props) => {
    const formatMessage = useFormatMessage();
    const folderColorValues = useSelector(getFolderColorValues);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(e.currentTarget.value, 10));
    };

    return (
        <Fieldset>
            <legend>{formatMessage('color')}</legend>
            {FOLDER_COLORS.map(({ id }, index) => (
                <label key={`${id}-${index}`}>
                    <input
                        type="radio"
                        name="folder-color-selector--color"
                        value={index}
                        onChange={handleChange}
                        checked={value === index}
                        aria-label={formatMessage(id)}
                    />
                    <ColorOption color={folderColorValues[index]} />
                </label>
            ))}
        </Fieldset>
    );
};

export default FolderColorSelector;
