import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as fromTracksActions from '../../../../redux/actions/tracks.actions';
import * as fromUploadManagerActions from '../../../../redux/actions/upload-manager.actions';
import * as fromUploadedFilesActions from '../../../../redux/actions/uploaded-files.actions';
import EditFileModal from './view';

EditFileModal.propTypes = {
    imageDropZoneIsShowing: PropTypes.bool,
};

const mapStateToProps = state => {
    const file = state.uploadedFiles.fileToEdit;

    return {
        file,
        imageUploadData: state.uploadManager.images[file.uuid],
    };
};

const mapDispatchToProps = dispatch => ({
    onConfirm: file =>
        dispatch(
            fromUploadedFilesActions.Actions.requestUpdateFile(file.uuid, {
                title: file.title,
                color: file.color,
            }),
        ),
    onClose: () => dispatch(fromUploadedFilesActions.Actions.closeEditFileDialog()),
    onAbortImageUpload: uuid =>
        dispatch(fromUploadManagerActions.Actions.abortImageInUploadManager(uuid)),
    recordEvent: (event, properties) =>
        dispatch(fromTracksActions.Actions.recordEvent(event, properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFileModal);
