import styled, { css } from 'styled-components';

export const fontFamilyRetina = "'Retina', helvetica, arial, sans-serif";
export const fontFamilyRetinaNarrow = "'RetinaNarrow', helvetica, arial, sans-serif";

export const H10 = styled.h1`
    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.8px;
`;

export const H20 = styled.h2`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 36px;
    font-weight: bold;
    line-height: 144%;
    letter-spacing: -0.8px;
    padding: 0;
    margin: 0;
`;

export const H30 = styled.h3`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 28px;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: -0.8px;
    padding: 0;
    margin: 0;
`;

export const H40 = styled.h4`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 24px;
    font-weight: 600;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H50 = styled.h5`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 20px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H60 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 18px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H65 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 16px;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: -0.01em;
    padding: 0;
    margin: 0;
`;

export const H70 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H70B = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    padding: 0;
    margin: 0;
`;

export const B50 = styled.h5`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 20px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B60 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 18px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B65 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B70 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    padding: 0;
    margin: 0;
`;

export const C80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
`;

export const BC80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
`;

// New Web Player font mixins (XL, MD and XS) - see figma for the source of truth
// https://www.figma.com/file/Y0zV3nt9whlClLWQmyfq1fhe/%F0%9F%93%96-New-Web-Player-Library?node-id=443%3A61&viewport=603%2C727%2C0.7606203556060791

export const Typography = {
    Desktop: {
        H10: css`
            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 48px */

            letter-spacing: -0.8px;
        `,
        'H10-M': css`
            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 48px */

            letter-spacing: -0.8px;
        `,
        H20: css`
            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 43px */

            letter-spacing: -0.8px;
        `,
        'H20-LN': css`
            /* XL/H20-LN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 300;
            font-size: 36px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 43px */

            letter-spacing: -0.8px;
        `,
        'H20-MN': css`
            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 43px */

            letter-spacing: -0.8px;
        `,
        H30: css`
            /* XL/H30 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 34px */

            letter-spacing: -0.8px;
        `,
        'H30-M': css`
            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 34px */

            letter-spacing: -0.8px;
        `,
        H40: css`
            /* XL/H40 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 29px */
        `,
        'H40-MN': css`
            /* XL/H40-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 120%;
            margin: 0;
            padding: 0;
        `,
        H50: css`
            /* XL/H50 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H50-MN': css`
            /* XL/H50-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H50-B': css`
            /* XL/H50-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        H60: css`
            /* XL/H60 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H60-N': css`
            /* XL/H60-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H60-B': css`
            /* XL/H60-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        H65: css`
            /* XL/H65 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            margin: 0;
            padding: 0;

            letter-spacing: -0.01em;
        `,
        'H65-B': css`
            /* XL/H65-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 23px */

            letter-spacing: -0.01em;
        `,
        H70: css`
            /* XL/H70 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H70-B': css`
            /* XL/H70-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H70-TB': css`
            /* XL/H70-TB */

            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
        `,
        'H70-TBC': css`
            /* XL/H70-TBC */

            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
            text-transform: uppercase;
        `,
        H80: css`
            /* XL/H80 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */
        `,
        'H80-N': css`
            /* XL/H80-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H80-B': css`
            /* XL/H80-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
        `,
        'H80-C': css`
            /* XL/H80-C */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
        'H80-BC': css`
            /* XL/H80-BC */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
    },
    Tablet: {
        H10: css`
            /* MD/H10 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 46px */

            letter-spacing: -0.8px;
        `,
        'H10-M': css`
            /* MD/H10-M */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 46px */

            letter-spacing: -0.8px;
        `,
        H20: css`
            /* MD/H20 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 38px */

            letter-spacing: -0.8px;
        `,
        'H20-LN': css`
            /* MD/H20-LN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 38px */

            letter-spacing: -0.8px;
        `,
        'H20-MN': css`
            /* MD/H20-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 38px */

            letter-spacing: -0.8px;
        `,
        H30: css`
            /* MD/H30 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 31px */

            letter-spacing: -0.8px;
        `,
        'H30-M': css`
            /* MD/H30-M */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 31px */

            letter-spacing: -0.8px;
        `,
        H40: css`
            /* MD/H40 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        'H40-MN': css`
            /* MD/H40-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 26px */
        `,
        H50: css`
            /* MD/H50 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        'H50-MN': css`
            /* MD/H50-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */

            font-feature-settings:
                'tnum' on,
                'lnum' on;
        `,
        'H50-B': css`
            /* MD/H50-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        H60: css`
            /* MD/H60 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 23px */
        `,
        'H60-N': css`
            /* MD/H60-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 23px */
        `,
        'H60-B': css`
            /* MD/H60-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 23px */
        `,
        H65: css`
            /* MD/H65 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 22px */

            letter-spacing: -0.01em;
        `,
        'H65-B': css`
            /* MD/H65-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 22px */

            letter-spacing: -0.01em;
        `,
        H70: css`
            /* MD/H70 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */
        `,
        'H70-B': css`
            /* MD/H70-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */
        `,
        'H70-TB': css`
            /* MD/H70-TB */

            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
        `,
        'H70-TBC': css`
            /* MD/H70-TBC */
            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
            text-transform: uppercase;
        `,
        H80: css`
            /* MD/H80 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */
        `,
        'H80-N': css`
            /* MD/H80-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 17px */
        `,
        'H80-B': css`
            /* MD/H80-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */
        `,
        'H80-C': css`
            /* MD/H80-C */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
        'H80-BC': css`
            /* MD/H80-BC */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
    },
    Mobile: {
        H10: css`
            /* XS/H10 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 46px */

            letter-spacing: -0.8px;
        `,
        'H10-M': css`
            /* XS/H10-M */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 46px */

            letter-spacing: -0.8px;
        `,
        H20: css`
            /* XS/H20 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 38px */

            letter-spacing: -0.8px;
        `,
        'H20-LN': css`
            /* XS/H20-LN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 300;
            font-size: 28px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 38px */

            letter-spacing: -0.8px;
        `,
        'H20-MN': css`
            /* XS/H20-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 38px */

            letter-spacing: -0.8px;
        `,
        H30: css`
            /* XS/H30 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 31px */

            letter-spacing: -0.8px;
        `,
        'H30-M': css`
            /* XS/H30-M */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 31px */

            letter-spacing: -0.8px;
        `,
        H40: css`
            /* XS/H40 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        'H40-MN': css`
            /* XS/H40-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* or 26px */
        `,
        H50: css`
            /* XS/H50 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        'H50-MN': css`
            /* XS/H50-MN */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */

            font-feature-settings:
                'tnum' on,
                'lnum' on;
        `,
        'H50-B': css`
            /* XS/H50-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 26px */
        `,
        H60: css`
            /* XS/H60 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 23px */
        `,
        'H60-N': css`
            /* XS/H60-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 23px */
        `,
        'H60-B': css`
            /* XS/H60-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 23px */
        `,
        H65: css`
            /* XS/H65 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 22px */

            letter-spacing: -0.01em;
        `,
        'H65-B': css`
            /* XS/H65-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 22px */

            letter-spacing: -0.01em;
        `,
        H70: css`
            /* XS/H70 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */
        `,
        'H70-B': css`
            /* XS/H70-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */
        `,
        'H70-TB': css`
            /* XS/H70-TB */

            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
        `,
        'H70-TBC': css`
            /* XS/H70-TBC */
            font-family: ${fontFamilyRetinaNarrow};
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 20px */

            letter-spacing: 0.03em;
            text-transform: uppercase;
        `,
        H80: css`
            /* XS/H80 */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */
        `,
        'H80-N': css`
            /* XS/H80-N */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* or 17px */
        `,
        'H80-B': css`
            /* XS/H80-B */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */
        `,
        'H80-C': css`
            /* XS/H80-C */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
        'H80-BC': css`
            /* XS/H80-BC */

            font-family: ${fontFamilyRetina};
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 144%;
            margin: 0;
            padding: 0;
            /* identical to box height, or 17px */

            letter-spacing: 0.02em;
            text-transform: uppercase;
        `,
    },
};
export type Typography = typeof Typography;
