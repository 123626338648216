import { USE_CHROMIUM_MEDIA_CONTROLS } from '../settings';
import { isElectronApp, isMacApp, isWindowsApp } from './Browser';
import { Logger } from './Logger';
import * as PodcastHelper from './PodcastHelper';
export function clearMediaSession() {
    if (isWindowsApp() || isMacApp()) {
        return;
    }
    if ('mediaSession' in navigator) {
        try {
            navigator.mediaSession.metadata = null;

            // Media keys are handled by Electron depending on the platform
            if (isElectronApp()) {
                return;
            }

            navigator.mediaSession.setActionHandler('play', null);
            navigator.mediaSession.setActionHandler('pause', null);
            navigator.mediaSession.setActionHandler('seekbackward', null);
            navigator.mediaSession.setActionHandler('seekforward', null);
        } catch {
            Logger.log('Failed to clear Media Session');
        }
    }
}

export function createGlobalMediaSession({
    episode,
    podcast,
    onPlay = () => {},
    onPause = () => {},
    seekForward = () => {},
    seekBackwards = () => {},
}) {
    if (isWindowsApp() || isMacApp()) {
        return;
    }
    if ('mediaSession' in navigator && episode && podcast) {
        try {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: episode.title,
                artist: podcast.author,
                album: podcast.title,
                artwork: [
                    {
                        src: PodcastHelper.getImageUrl(96, podcast.uuid),
                        sizes: '96x96',
                    },
                    {
                        src: PodcastHelper.getImageUrl(128, podcast.uuid),
                        sizes: '128x128',
                    },
                    {
                        src: PodcastHelper.getImageUrl(192, podcast.uuid),
                        sizes: '192x192',
                    },
                    {
                        src: PodcastHelper.getImageUrl(256, podcast.uuid),
                        sizes: '256x256',
                    },
                    {
                        src: PodcastHelper.getImageUrl(384, podcast.uuid),
                        sizes: '384x384',
                    },
                    {
                        src: PodcastHelper.getImageUrl(512, podcast.uuid),
                        sizes: '512x512',
                    },
                ],
            });

            // In Apps < v2.0.8
            // Media keys are handled by Electron ,depending on the platform
            if (!USE_CHROMIUM_MEDIA_CONTROLS && isElectronApp()) {
                return;
            }

            navigator.mediaSession.setActionHandler('play', onPlay);
            navigator.mediaSession.setActionHandler('pause', onPause);
            navigator.mediaSession.setActionHandler('previoustrack', seekBackwards);
            navigator.mediaSession.setActionHandler('nexttrack', seekForward);
        } catch {
            Logger.log(
                'Failed to set up Media Session - check that the browser supports this feature properly.',
            );
        }
    }
}
