import { connect } from 'react-redux';
import * as fromModalActions from '../../redux/actions/modal.actions';
import Modal from './Modal';

export default Modal;

// Also export a pre-connected modal that knows how to close itself, since this is usually the use case anyways
export const ConnectedModal = connect(null, {
    onClose: fromModalActions.Actions.hideModal,
})(Modal);

export type ModalFunctionChild = {
    closeModal: () => void;
};
