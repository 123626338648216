import { isFuture, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { DateText } from '../..';
import { MOBILE_WIDTH } from '../../../model/page';
import { TextColumn } from '../ListEpisodes.styled';

interface Props {
    published?: string;
    alignRight?: boolean;
    inactive?: boolean;
}

const StyledTextColumn = styled(TextColumn)`
    .episode-row & {
        @media (max-width: ${MOBILE_WIDTH}px) {
            grid-column: 1 / 2;
            grid-row: 1;
        }
    }
`;

export const EpisodeDate: React.FC<Props> = ({ published, alignRight, inactive }) => {
    const date = published
        ? isFuture(parseISO(published))
            ? new Date().toUTCString()
            : published
        : '';

    return (
        <StyledTextColumn $align={alignRight ? 'right' : 'left'} $inactive={inactive}>
            <DateText date={date} showToday={true} />
        </StyledTextColumn>
    );
};
