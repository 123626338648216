import { safeSpeed } from 'helper/SpeedHelper';
import {
    LOCAL_STORAGE_KEY_LANGUAGE,
    LOCAL_STORAGE_KEY_PERFORMANCE_MODE,
    LOCAL_STORAGE_KEY_SPEED,
    LOCAL_STORAGE_KEY_THEME,
} from 'model/local-storage';
import { BookmarksSortOrder, globalSettingsToMigrate } from 'model/types';
import { THEME } from '../../model/theme';
import {
    PodcastBadges,
    PodcastGridLayouts,
    PodcastGridOrder,
} from '../../pages/PodcastsPage/model';
import { ActionTypes, Actions } from '../actions/settings.actions';
import { getDisplayedTheme } from './selectors';

export const INITIAL_STATE = {
    gridLayout:
        localStorage.getItem('podcastGridLayout') === null
            ? PodcastGridLayouts.GRID_LARGE
            : parseInt(localStorage.getItem('podcastGridLayout') ?? '', 10),
    gridOrder:
        localStorage.getItem('podcastGridOrder') === null
            ? PodcastGridOrder.RELEASE_DATE
            : parseInt(localStorage.getItem('podcastGridOrder') ?? '', 10),
    bookmarksSortOrder:
        localStorage.getItem('bookmarksSortOrder') === null
            ? BookmarksSortOrder.NEWEST_TO_OLDEST
            : parseInt(localStorage.getItem('bookmarksSortOrder') ?? '', 10),
    badges:
        localStorage.getItem('badgesOn') === null
            ? PodcastBadges.OFF
            : parseInt(localStorage.getItem('badgesOn') ?? '', 10) === 0
              ? PodcastBadges.OFF
              : PodcastBadges.NEWEST_EPISODE,
    filesAfterPlayingDeleteCloud:
        localStorage.getItem('filesAfterPlayingDeleteCloud') === null ||
        JSON.parse(localStorage.getItem('filesAfterPlayingDeleteCloud') ?? ''),
    filesAutoUpNext:
        localStorage.getItem('filesAutoUpNext') === null ||
        JSON.parse(localStorage.getItem('filesAutoUpNext') ?? ''),
    showArchived:
        localStorage.getItem('showArchived') === null ||
        JSON.parse(localStorage.getItem('showArchived') ?? ''),
    intelligentResumption:
        localStorage.getItem('intelligentResumption') === null ||
        JSON.parse(localStorage.getItem('intelligentResumption') ?? ''),
    autoPlayEnabled:
        localStorage.getItem('autoPlayEnabled') === null ||
        JSON.parse(localStorage.getItem('autoPlayEnabled') ?? ''),
    remoteSkipChapters:
        localStorage.getItem('remoteSkipChapters') === null ||
        JSON.parse(localStorage.getItem('remoteSkipChapters') ?? ''),
    theme: parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_THEME) ?? THEME.system.toString(), 10),

    /*
    true means we should perform track and send metrics.
    false means the user has opted out of tracking.
    See https://github.com/Automattic/pocket-casts-webplayer/issues/2565
    */
    privacyAnalytics: true,

    preferredColorScheme: THEME.light,
    skipForward:
        localStorage.getItem('skipForwardSecs') === null
            ? 45
            : parseInt(localStorage.getItem('skipForwardSecs') ?? '', 10),
    skipBack:
        localStorage.getItem('skipBackSecs') === null
            ? 10
            : parseInt(localStorage.getItem('skipBackSecs') ?? '', 10),
    playbackSpeed:
        localStorage.getItem(LOCAL_STORAGE_KEY_SPEED) === null
            ? 1
            : safeSpeed(parseFloat(localStorage.getItem(LOCAL_STORAGE_KEY_SPEED) ?? '')),
    webpSupported: false,
    performanceModeOn:
        parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_PERFORMANCE_MODE) ?? '0', 10) === 1,
    recommendationsOn:
        localStorage.getItem('recommendationsOn') === null
            ? true
            : parseInt(localStorage.getItem('recommendationsOn') ?? '', 10) === 1,
    showRecommendations: true,
    isCastConnected: false,
    language:
        localStorage.getItem(LOCAL_STORAGE_KEY_LANGUAGE) || window.navigator.language || 'en-US',
    region: undefined as string | undefined,
    keyboardShortcutsOpen: false,
    marketingOptIn: false,
    autoArchivePlayed: undefined as boolean | undefined,
    autoArchiveIncludesStarred: undefined as boolean | undefined,
    changed: {} as { [key: string]: boolean },
};

export type SettingsState = typeof INITIAL_STATE;

export default (state: SettingsState = INITIAL_STATE, action: Actions): SettingsState => {
    switch (action.type) {
        case ActionTypes.SAVE_PODCAST_GRID_LAYOUT:
            localStorage.setItem('podcastGridLayout', String(action.payload.gridLayout));
            return { ...state, gridLayout: action.payload.gridLayout };
        case ActionTypes.SAVE_PRIVACY_ANALYTICS:
            return { ...state, privacyAnalytics: action.payload.privacyAnalytics };
        case ActionTypes.SAVE_PODCAST_GRID_BADGES_ON:
            localStorage.setItem('badgesOn', String(action.payload.badges));
            return { ...state, badges: action.payload.badges };
        case ActionTypes.SAVE_PODCAST_GRID_ORDER:
            localStorage.setItem('podcastGridOrder', String(action.payload.gridOrder));
            return { ...state, gridOrder: action.payload.gridOrder };
        case ActionTypes.SAVE_BOOKMARKS_SORT_ORDER:
            localStorage.setItem('bookmarksSortOrder', String(action.payload.bookmarksSortOrder));
            return { ...state, bookmarksSortOrder: action.payload.bookmarksSortOrder };
        case ActionTypes.SAVE_LANGUAGE:
            localStorage.setItem(LOCAL_STORAGE_KEY_LANGUAGE, action.payload.language);
            return { ...state, language: action.payload.language };
        case ActionTypes.SAVE_REGION:
            return { ...state, region: action.payload.region };
        case ActionTypes.SAVE_SKIP_FORWARD:
            localStorage.setItem('skipForwardSecs', String(action.payload.skipForward));
            return { ...state, skipForward: action.payload.skipForward };
        case ActionTypes.SAVE_SKIP_BACK:
            localStorage.setItem('skipBackSecs', String(action.payload.skipBack));
            return { ...state, skipBack: action.payload.skipBack };
        case ActionTypes.SAVE_REMOTE_SKIP_CHAPTERS:
            localStorage.setItem('remoteSkipChapters', String(action.payload.remoteSkipChapters));
            return { ...state, remoteSkipChapters: action.payload.remoteSkipChapters };
        case ActionTypes.SAVE_PLAYBACK_SPEED:
            localStorage.setItem(LOCAL_STORAGE_KEY_SPEED, String(action.payload.playbackSpeed));
            return { ...state, playbackSpeed: safeSpeed(action.payload.playbackSpeed) };
        case ActionTypes.SAVE_INTELLIGENT_RESUMPTION:
            localStorage.setItem(
                'intelligentResumption',
                String(action.payload.intelligentResumption),
            );
            return { ...state, intelligentResumption: action.payload.intelligentResumption };
        case ActionTypes.SAVE_AUTOPLAY_ENABLED:
            localStorage.setItem('autoPlayEnabled', String(action.payload.autoPlayEnabled));
            return { ...state, autoPlayEnabled: action.payload.autoPlayEnabled };
        case ActionTypes.SAVE_FILES_AFTER_PLAYING_DELETE_CLOUD:
            localStorage.setItem(
                'filesAfterPlayingDeleteCloud',
                String(action.payload.filesAfterPlayingDeleteCloud),
            );
            return {
                ...state,
                filesAfterPlayingDeleteCloud: action.payload.filesAfterPlayingDeleteCloud,
            };
        case ActionTypes.SAVE_FILES_AUTO_UP_NEXT:
            localStorage.setItem('filesAutoUpNext', String(action.payload.filesAutoUpNext));
            return { ...state, filesAutoUpNext: action.payload.filesAutoUpNext };
        case ActionTypes.SAVE_AUTO_ARCHIVE_PLAYED:
            return {
                ...state,
                autoArchivePlayed: action.payload.autoArchivePlayed,
            };
        case ActionTypes.SAVE_AUTO_ARCHIVE_INCLUDES_STARRED:
            return {
                ...state,
                autoArchiveIncludesStarred: action.payload.autoArchiveIncludesStarred,
            };
        case ActionTypes.IMPORT_SETTINGS:
            if (typeof action.payload.settings?.theme === 'number') {
                localStorage.setItem(
                    LOCAL_STORAGE_KEY_THEME,
                    action.payload.settings.theme.toString(),
                );
            }
            if (action.payload.settings?.language) {
                localStorage.setItem(LOCAL_STORAGE_KEY_LANGUAGE, action.payload.settings.language);
            }

            // https://pocketcastsp2.wordpress.com/2024/03/07/changing-the-scope-of-named-settings
            // eslint-disable-next-line no-case-declarations
            const settings = { ...action.payload.settings };
            globalSettingsToMigrate.forEach(key => {
                if (action.payload.settings[`${key}Global`] !== undefined) {
                    settings[key] = action.payload.settings[`${key}Global`];
                    delete settings[`${key}Global`];
                }
            });

            return { ...state, ...settings };
        case ActionTypes.TOGGLE_THEME: {
            const actualTheme = getDisplayedTheme(state.theme);
            switch (actualTheme) {
                case THEME.light:
                    localStorage.setItem(LOCAL_STORAGE_KEY_THEME, THEME.dark.toString());
                    return { ...state, theme: THEME.dark };
                default:
                case THEME.dark:
                    localStorage.setItem(LOCAL_STORAGE_KEY_THEME, THEME.light.toString());
                    return { ...state, theme: THEME.light };
            }
        }
        case ActionTypes.UPDATE_THEME:
            localStorage.setItem(LOCAL_STORAGE_KEY_THEME, action.payload.theme.toString());
            return { ...state, theme: action.payload.theme };
        case ActionTypes.UPDATE_PREFERRED_COLOR_SCHEME:
            return { ...state, preferredColorScheme: action.payload.preferredColorScheme };
        case ActionTypes.SAVE_WEBP_SUPPORTED:
            return { ...state, webpSupported: action.payload.supported };
        case ActionTypes.SAVE_SHOW_ARCHIVED:
            localStorage.setItem('showArchived', String(action.payload.showArchived));
            return { ...state, showArchived: action.payload.showArchived };
        case ActionTypes.CHROME_CAST_CONNECTED:
            return { ...state, isCastConnected: true };
        case ActionTypes.CHROME_CAST_DISCONNECTED:
            return { ...state, isCastConnected: false };
        case ActionTypes.TOGGLE_RECOMMENDATIONS: {
            const on = !state.recommendationsOn;
            localStorage.setItem('recommendationsOn', `${on ? 1 : 0}`);
            return { ...state, recommendationsOn: on };
        }
        case ActionTypes.TOGGLE_PERFORMANCE_MODE: {
            const on = !state.performanceModeOn;
            localStorage.setItem(LOCAL_STORAGE_KEY_PERFORMANCE_MODE, `${on ? 1 : 0}`);
            return { ...state, performanceModeOn: on };
        }
        case ActionTypes.HIDE_RECOMMENDATIONS:
            return { ...state, showRecommendations: false };
        case ActionTypes.SHOW_RECOMMENDATIONS:
            return { ...state, showRecommendations: true };
        case ActionTypes.OPEN_KEYBOARD_SHORTCUTS:
            return { ...state, keyboardShortcutsOpen: true };
        case ActionTypes.CLOSE_KEYBOARD_SHORTCUTS:
            return { ...state, keyboardShortcutsOpen: false };
        case ActionTypes.OPEN_CLOSE_KEYBOARD_SHORTCUTS:
            return { ...state, keyboardShortcutsOpen: !state.keyboardShortcutsOpen };
        default:
            return state;
    }
};
