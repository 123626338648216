import { Icon } from 'components/Icon';
import { Switch } from 'components/form/Switch';
import { isElectronApp } from 'helper/Browser';
import { formatTime } from 'helper/TimeHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import RoundedButton from '../../../buttons/RoundedButton';
import { ShareProps } from '../SharePopup';
import { Actions, ShareButtons, TimeOption } from '../SharePopup.styled';
import {
    formatTimeForUrl,
    getEmailUrl,
    getFacebookUrl,
    getLinkedInUrl,
    getTumblrUrl,
    getTwitterUrl,
} from '../util';

function ShareOptions({
    episodeUuid,
    url,
    shareTime,
    playedUpTo,
    isPlaying,
    loading,
    eventSource,
    episodeTitle,
    podcastTitle,
    recordEvent,
    addFlag,
}: ShareProps) {
    const [isTimeAdded, setIsTimeAdded] = useState(false);

    const inputRef = createRef<HTMLInputElement>();
    const formatMessage = useFormatMessage();

    const selectText = useCallback(() => {
        setTimeout(() => {
            if (inputRef.current && document.activeElement !== inputRef.current) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }, 200);
    }, [inputRef]);

    useEffect(() => {
        if (url) {
            selectText();
        }
    }, [url, selectText]);

    useEffect(() => {
        if (shareTime) {
            setIsTimeAdded(true);
        } else {
            setIsTimeAdded(false);
        }
    }, [shareTime]);

    const recordShareEvent = useCallback(
        (action: string) => {
            let type = 'podcast';
            if (isTimeAdded) {
                type = 'current_time';
            } else if (url.includes('t=')) {
                type = 'clip';
            } else if (episodeUuid) {
                type = 'episode';
            }

            recordEvent('podcast_shared', {
                source: eventSource,
                type,
                action,
            });
        },
        [isTimeAdded, episodeUuid, eventSource, recordEvent, url],
    );

    const onTimeAddedChanged = useCallback(() => {
        setIsTimeAdded(!isTimeAdded);
    }, [isTimeAdded]);

    const onCopyLink = useCallback(() => {
        recordShareEvent('url');

        inputRef.current?.select();
        if (inputRef.current?.value) {
            navigator.clipboard.writeText(inputRef.current?.value);
            if (isElectronApp() && window?.electron?.copyToClipboard) {
                window.electron.copyToClipboard({ text: inputRef.current?.value });
            }
        }
        addFlag(formatMessage('link-copied'));
    }, [addFlag, formatMessage, inputRef, recordShareEvent]);

    const showCurrentTime = isPlaying && !shareTime;
    const currentTime = shareTime || playedUpTo;
    const formattedUrl = isTimeAdded ? `${url}?t=${formatTimeForUrl(currentTime)}` : url;

    return (
        <>
            {showCurrentTime && (
                <TimeOption>
                    <Switch on={isTimeAdded} onChange={onTimeAddedChanged} color="#03A9F4" />
                    <label htmlFor="currentTime">
                        {formatMessage('share-at-current-time', {
                            currentTime: formatTime(currentTime),
                        })}
                    </label>
                </TimeOption>
            )}

            <ShareButtons>
                <div className="link">
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder={loading ? formatMessage('getting-link') : ''}
                        value={formattedUrl}
                        readOnly={true}
                        onCopy={() => recordShareEvent('url')}
                    />
                </div>

                <Actions>
                    <RoundedButton onClick={onCopyLink} title={formatMessage('copy-link')}>
                        {formatMessage('copy-link')}
                    </RoundedButton>

                    <div className="social">
                        <a
                            className="tumblr grow"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getTumblrUrl(url)}
                            onClick={() => recordShareEvent('tumblr')}
                        >
                            <Icon id="tumblr" />
                        </a>
                        <a
                            className="facebook grow"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFacebookUrl(url)}
                            onClick={() => recordShareEvent('facebook')}
                        >
                            <Icon id="facebook" />
                        </a>
                        <a
                            className="twitter grow"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getTwitterUrl(url, episodeTitle || podcastTitle)}
                            onClick={() => recordShareEvent('twitter')}
                        >
                            <Icon id="twitter" />
                        </a>
                        <a
                            className="linkedin grow"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getLinkedInUrl(url)}
                            onClick={() => recordShareEvent('linkedin')}
                        >
                            <Icon id="linkedin" />
                        </a>
                        <a
                            className="email grow"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getEmailUrl(url)}
                            onClick={() => recordShareEvent('email')}
                        >
                            <Icon id="email" />
                        </a>
                    </div>
                </Actions>
            </ShareButtons>
        </>
    );
}

export default ShareOptions;
