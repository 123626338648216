import { Link } from 'react-router';
import styled from 'styled-components';
import { blue50, blueTextLinkMixin, gridSize } from '../../../styles';

export const SignedInWrapper = styled.div`
    width: 100%;
    padding: ${4 * gridSize}px;
    border: 1px solid ${props => props.theme.legacyScss['theme-line']};
    border-radius: 10px;
    margin-bottom: ${3 * gridSize}px;
    p {
        margin: 0 0 ${2 * gridSize}px;
    }
    button {
        font-size: 14px;
        font-weight: 700;
    }
`;

export const SSOButtons = styled.div`
    width: 100%;
    margin: ${2 * gridSize}px 0 ${3 * gridSize}px;
    display: grid;
    grid-template-columns: 1fr;
    gap: ${2 * gridSize}px;
    // The auto-fill and minmax below make the buttons side by side as long as they can both
    // be wider than 260px. If the space is too small, they'll stack on top of each other instead.
    // It allows the form to be dropped in various contexts and the buttons can look great, without
    // custom media queries for each placement.
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const TermsAgreement = styled.small`
    font-size: 14px;
    font-weight: 500px;
    margin-top: ${4 * gridSize}px;
    a {
        color: ${blue50};
        white-space: nowrap;
    }

    color: ${props => props.theme.tokens['secondary-text-01']};
    line-height: 1.7;
    display: inline-block;
    text-align: center;
    margin-top: -8px;
    margin-bottom: 12px;
    a {
        color: ${props => props.theme.tokens['secondary-text-01']};
        font-weight: 700;
    }
`;

export const ForgotPasswordLink = styled(Link)`
    font-size: 14px;
    float: right;
    color: ${props => props.theme.tokens['primary-text-01']};
    opacity: 0.7;
    transition: opacity 100ms linear;
    &:hover {
        opacity: 0.8;
    }

    color: ${props => props.theme.tokens['secondary-text-02']};
    opacity: 1;
    font-weight: 500;
    margin-top: -${2 * gridSize}px;
`;

export const StyledForgotPasswordLinkWrapper = styled.div`
    // We need this because of the broad selector in ModernStyle applying an underline to every link
    a {
        text-decoration: none;
    }
`;

export const StyledToggleLoginRegister = styled.div`
    a {
        ${blueTextLinkMixin};
        font-weight: 700;
    }
    font-weight: 400;
    text-align: center;
`;

export const StyledFormErrorWrapper = styled.div`
    padding-top: ${1 * gridSize}px;
`;
