import formatCurrency from '@automattic/format-currency';
import { Button } from 'components/Button';
import { FinePrintText } from 'components/FinePrintText';
import { LoaderSquare } from 'components/LoaderSquare';
import { PlanBadge } from 'components/PlanBadge';
import { isMobile } from 'helper/Browser';
import { getCorrectFreeTrialMessage } from 'helper/GiftDaysHelper';
import { getLocalizedSubscriptionPrice } from 'helper/PaddleCheckoutHelper';
import { isInfoOfferAvailableForCountry } from 'helper/PlansHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import { useGeolocation } from 'hooks/useGeolocation';
import { POCKET_CASTS_PLUS } from 'model/external-links';
import { PaddlePlan, PaddleProduct, SubscriptionTier } from 'model/types';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_50_PERCENT_BY_DEFAULT } from 'settings';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import useTracks from '../../hooks/useTracks';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import { getDefaultTracksSource, isShowingLightTheme } from '../../redux/reducers/selectors';
import PlanFeatureList from '../PlanFeatureList';
import { SubscribeToPlanButton } from '../SubscribeToPlanButton';
import QRCodeSrcWhite from './mobile-download-qr-white.png';
import QRCodeSrc from './mobile-download-qr.png';
import { PLAN_VARIABLES } from './plans';
import { PlanPrice } from './PlanSelector';
import {
    DownloadModal,
    FreeTrialBadge,
    Interval,
    IntervalSelector,
    PlanSelectorItemsRow,
    PriceRowContainer,
    SplitPlanSelectorFooter,
    SplitPlanWrapper,
    SplitPlans,
    Wrapper,
} from './PlanSelector.styled';

export const TrialBadge = ({ trialDays, tier }: { trialDays: number; tier: SubscriptionTier }) =>
    trialDays > 0 ? (
        <FreeTrialBadge $tier={tier}>{getCorrectFreeTrialMessage(trialDays)}</FreeTrialBadge>
    ) : null;

const PlanPanel = ({
    plan,
    product,
    onSubscribe,
}: {
    plan: PaddlePlan;
    product: PaddleProduct;
    onSubscribe: (plan: PaddlePlan) => void;
}) => {
    const { tier, perIntervalText } = PLAN_VARIABLES[plan];
    const formatMessage = useFormatMessage();
    const { countryCode, hasFetchedLocation } = useGeolocation();

    const dispatch = useDispatch();

    // During checkout, we need to know if we offered them an intro offer or not
    // so we can apply the correct discount code or trial days.
    useEffect(() => {
        const offerIntroDiscount = isInfoOfferAvailableForCountry(countryCode);
        dispatch(fromSubscriptionActions.Actions.saveIntroOfferEligibility(offerIntroDiscount));
    }, [countryCode, dispatch]);

    if (!hasFetchedLocation) {
        return <LoaderSquare />;
    }

    const offerHalfOff =
        plan === 'plus-yearly' &&
        USE_50_PERCENT_BY_DEFAULT &&
        isInfoOfferAvailableForCountry(countryCode);
    const priceString = getLocalizedSubscriptionPrice(product);
    const halfOffPriceString = product
        ? formatCurrency(product.subscription.price.gross / 2, product.currency)
        : '';

    const trialDays = product?.subscription.trial_days ?? 0;

    return (
        <Wrapper $isPatron={tier === 'Patron'}>
            <PlanSelectorItemsRow>
                <PlanBadge tier={tier} />
                {offerHalfOff && (
                    <FreeTrialBadge $tier={tier}>
                        {formatMessage('switch-to-discount-text')}
                    </FreeTrialBadge>
                )}
                <TrialBadge tier={tier} trialDays={trialDays} />
            </PlanSelectorItemsRow>

            {offerHalfOff ? (
                <PriceRowContainer>
                    <PlanPrice
                        price={halfOffPriceString}
                        interval={perIntervalText}
                        fullPrice={priceString}
                    />
                </PriceRowContainer>
            ) : (
                <PlanPrice price={priceString} interval={perIntervalText} />
            )}

            <PlanFeatureList tier={tier} plan={plan} />
            <SplitPlanSelectorFooter>
                <SubscribeToPlanButton tier={tier} onClick={() => onSubscribe(plan)}>
                    {plan === 'plus-yearly' && formatMessage('subscribe-button-title')}
                    {plan !== 'plus-yearly' && formatMessage('subscribe-to-tier', { tier })}
                </SubscribeToPlanButton>
            </SplitPlanSelectorFooter>
        </Wrapper>
    );
};

const FinePrintLinks = ({ onClick }: { onClick?: (link: string) => void }) => {
    const formatMessage = useFormatMessage();
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const isLightTheme = useSelector(isShowingLightTheme);

    return (
        <FinePrintText>
            <FormattedMessage
                id="learn-more-or-listen-free"
                values={{
                    plusLink: (text: ReactNode) => (
                        <a
                            href={POCKET_CASTS_PLUS}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => onClick?.('pocket_casts_plus')}
                        >
                            {text}
                        </a>
                    ),
                    mobileLink: (text: ReactNode) => (
                        <Button
                            kind="text"
                            onClick={() => {
                                onClick?.('listen_on_mobile');
                                if (isMobile()) {
                                    window.open('https://pocketcasts.com/get', '_blank');
                                } else {
                                    setShowDownloadModal(true);
                                }
                            }}
                        >
                            {text}
                        </Button>
                    ),
                }}
            />
            {showDownloadModal && (
                <DownloadModal
                    horizontalInsetPx={32}
                    onClose={() => setShowDownloadModal(false)}
                    title={formatMessage('download-pocket-casts-mobile')}
                    useModernStyle
                >
                    <img src={isLightTheme ? QRCodeSrc : QRCodeSrcWhite} alt="QR Code" />
                    {formatMessage('mobile-qr-instructions')}
                </DownloadModal>
            )}
        </FinePrintText>
    );
};

export type Props = {
    products: Partial<Record<PaddlePlan, PaddleProduct>>;
    onSubscribe: (plan: PaddlePlan) => void;
};

const SplitPlanSelector = ({ products, onSubscribe }: Props) => {
    const [planInterval, setPlanInterval] = useState<'monthly' | 'yearly'>('yearly');
    const plusPlan = planInterval === 'monthly' ? 'plus-monthly' : 'plus-yearly';
    const patronPlan = planInterval === 'monthly' ? 'patron-monthly' : 'patron-yearly';

    const plusProduct = products[plusPlan];
    const patronProduct = products[patronPlan];
    const { recordEvent } = useTracks();
    const source = useSelector(getDefaultTracksSource);

    const handlePlanIntervalChange = (interval: 'monthly' | 'yearly') => {
        recordEvent('plus_promotion_subscription_frequency_changed', { value: interval });
        setPlanInterval(interval);
    };

    return (
        <SplitPlanWrapper>
            <IntervalSelector>
                <label>
                    <input
                        type="radio"
                        name="split-plan-selector--interval"
                        value="yearly"
                        checked={planInterval === 'yearly'}
                        onChange={() => handlePlanIntervalChange('yearly')}
                    />
                    <Interval>
                        <FormattedMessage id="yearly" />
                    </Interval>
                </label>
                <label>
                    <input
                        type="radio"
                        name="split-plan-selector--interval"
                        value="monthly"
                        checked={planInterval === 'monthly'}
                        onChange={() => handlePlanIntervalChange('monthly')}
                    />
                    <Interval>
                        <FormattedMessage id="monthly" />
                    </Interval>
                </label>
            </IntervalSelector>

            {plusProduct && patronProduct && (
                <SplitPlans>
                    <PlanPanel plan={plusPlan} product={plusProduct} onSubscribe={onSubscribe} />
                    <PlanPanel
                        plan={patronPlan}
                        product={patronProduct}
                        onSubscribe={onSubscribe}
                    />
                </SplitPlans>
            )}

            <FinePrintLinks
                onClick={link =>
                    recordEvent('select_payment_frequency_link_tapped', { link, source })
                }
            />
        </SplitPlanWrapper>
    );
};

export default SplitPlanSelector;
