import { LOCAL_STORAGE_KEY_MUTED, LOCAL_STORAGE_KEY_SPEED } from 'model/local-storage';
import {
    AutoplayConfig,
    EpisodeChapter,
    RecommendedEpisode,
    TodoFixmeMigrationType,
    TracksEventSource,
} from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    LOAD_EPISODE = '@player/LOAD_EPISODE',
    PLAY_EPISODE = '@player/PLAY_EPISODE',
    EPISODE_FINISHED = '@player/EPISODE_FINISHED',
    PAUSE = '@player/PAUSE',
    PLAY = '@player/PLAY',
    SEEK_TO = '@player/SEEK_TO',
    CLOSE_PLAYER = '@player/CLOSE_PLAYER',
    PLAYER_FAILED = '@player/PLAYER_FAILED',
    LOAD_UNPLAYABLE_FILE = '@player/LOAD_UNPLAYABLE_FILE',
    UPDATE_AUDIO_URL = '@player/UPDATE_AUDIO_URL',
    UPDATE_IS_ACCORDION_EPISODE = '@player/UPDATE_IS_ACCORDION_EPISODE',

    SAVE_PROGRESS = '@player/SAVE_PROGRESS',
    SAVE_PROGRESS_SUCCESS = '@player/SAVE_PROGRESS_SUCCESS',

    UPDATE_PLAYED_UP_TO = '@player/UPDATE_PLAYED_UP_TO',
    UPDATE_DURATION = '@player/UPDATE_DURATION',
    UPDATE_PLAYING_STATUS = '@player/UPDATE_PLAYING_STATUS',
    UPDATE_SPEED = '@player/UPDATE_SPEED',
    UPDATE_VOLUME = '@player/UPDATE_VOLUME',
    UPDATE_MUTED = '@player/UPDATE_MUTE',
    UPDATE_BUFFERING = '@player/UPDATE_BUFFERING',
    UPDATE_VIDEO_MODE = '@player/UPDATE_VIDEO_MODE',
    UPDATE_VIDEO_SIZE = '@player/UPDATE_VIDEO_SIZE',
    UPDATE_CHAPTERS = '@player/UPDATE_CHAPTERS',
    UPDATE_CHAPTERS_DESELECTION = '@player/UPDATE_CHAPTERS_DESELECTION',
    SAVE_CHAPTERS_DESELECTION = '@player/SAVE_CHAPTERS_DESELECTION',
    OPEN_CHAPTERS = '@player/OPEN_CHAPTERS',
    CLOSE_CHAPTERS = '@player/CLOSE_CHAPTERS',

    LOADING_RECOMMENDED_EPISODES = '@player/LOADING_RECOMMENDED_EPISODES',
    LOADED_RECOMMENDED_EPISODES = '@player/LOADED_RECOMMENDED_EPISODES',

    DOWNLOAD_RECOMMENDATIONS = '@player/DOWNLOAD_RECOMMENDATIONS',
}

export const Actions = {
    pause: (tracksProperties: { eventSource: TracksEventSource }) =>
        createAction(ActionTypes.PAUSE, { tracksProperties }),

    play: () => createAction(ActionTypes.PLAY),

    playEpisode: (
        episodeUuid: string,
        podcastUuid: string,
        tracksProperties: { eventSource: TracksEventSource | null },
        options?: { seekTo?: number; autoplay?: AutoplayConfig | null; paused?: boolean },
    ) =>
        createAction(ActionTypes.PLAY_EPISODE, {
            episodeUuid,
            podcastUuid,
            tracksProperties,
            options,
        }),

    loadEpisode: (episode: TodoFixmeMigrationType, isPlaying: boolean) =>
        createAction(ActionTypes.LOAD_EPISODE, { episode, isPlaying }),

    loadUnplayableFile: (episode: TodoFixmeMigrationType) =>
        createAction(ActionTypes.LOAD_UNPLAYABLE_FILE, { episode }),

    closePlayer: () => createAction(ActionTypes.CLOSE_PLAYER),

    playerFailed: (errorMessage: string) =>
        createAction(ActionTypes.PLAYER_FAILED, { errorMessage }),

    loadingRecommendedEpisodes: () => createAction(ActionTypes.LOADING_RECOMMENDED_EPISODES),

    loadedRecommendedEpisodes: (episodes: RecommendedEpisode[]) =>
        createAction(ActionTypes.LOADED_RECOMMENDED_EPISODES, { episodes }),

    seekTo: (timeSecs: number) => createAction(ActionTypes.SEEK_TO, { seekTo: timeSecs }),

    saveProgress: (episodeUuid: string, podcastUuid: string, playedUpTo: number) =>
        createAction(ActionTypes.SAVE_PROGRESS, { episodeUuid, podcastUuid, playedUpTo }),

    episodeFinished: (episode: TodoFixmeMigrationType, podcastUuid: string) =>
        createAction(ActionTypes.EPISODE_FINISHED, { episode, podcastUuid }),

    updateSpeed: (speed: number) => {
        const normalizedSpeed = Math.round(speed * 10) / 10;
        localStorage.setItem(LOCAL_STORAGE_KEY_SPEED, String(normalizedSpeed));
        return createAction(ActionTypes.UPDATE_SPEED, { speed: normalizedSpeed });
    },

    updatePlayedUpTo: (episodeUuid: string, playedUpTo: number) =>
        createAction(ActionTypes.UPDATE_PLAYED_UP_TO, { episodeUuid, playedUpTo }),

    updateBuffering: (buffering: boolean) =>
        createAction(ActionTypes.UPDATE_BUFFERING, { buffering }),

    updateDuration: (episodeUuid: string, podcastUuid: string, duration: number) =>
        createAction(ActionTypes.UPDATE_DURATION, { episodeUuid, podcastUuid, duration }),

    updateVolume: (volume: number) => {
        localStorage.setItem('volume', String(volume));
        return createAction(ActionTypes.UPDATE_VOLUME, { volume });
    },

    updateMuted: (muted: boolean) => {
        localStorage.setItem(LOCAL_STORAGE_KEY_MUTED, muted ? 'true' : 'false');
        return createAction(ActionTypes.UPDATE_MUTED, { muted });
    },

    updateVideoMode: (mode: number) => createAction(ActionTypes.UPDATE_VIDEO_MODE, { mode }),

    updateVideoSize: (width: number, height: number) =>
        createAction(ActionTypes.UPDATE_VIDEO_SIZE, { width, height }),

    updatePlayingStatus: (playingStatus: number) =>
        createAction(ActionTypes.UPDATE_PLAYING_STATUS, { playingStatus }),

    updateChapters: (chapters: EpisodeChapter[]) =>
        createAction(ActionTypes.UPDATE_CHAPTERS, { chapters }),

    updateDeselectedChapters: (deselectedChapters: number[]) =>
        createAction(ActionTypes.UPDATE_CHAPTERS_DESELECTION, { deselectedChapters }),

    saveChaptersDeselection: (
        podcastUuid: string,
        episodeUuid: string,
        deselectedChapters: number[],
    ) =>
        createAction(ActionTypes.SAVE_CHAPTERS_DESELECTION, {
            podcastUuid,
            episodeUuid,
            deselectedChapters,
        }),

    updateAudioUrl: (audioUrl: string) => createAction(ActionTypes.UPDATE_AUDIO_URL, { audioUrl }),

    updateIsAccordionEpisode: (isAccordionEpisode: boolean) =>
        createAction(ActionTypes.UPDATE_IS_ACCORDION_EPISODE, { isAccordionEpisode }),

    downloadRecommendations: () => createAction(ActionTypes.DOWNLOAD_RECOMMENDATIONS),

    saveProgressSuccess: (
        episodeUuid: string,
        lastSentPlayedUpTo: number,
        lastSentPlayingStatus?: number,
    ) =>
        createAction(ActionTypes.SAVE_PROGRESS_SUCCESS, {
            episodeUuid,
            lastSentPlayedUpTo,
            lastSentPlayingStatus,
        }),

    openChapters: () => createAction(ActionTypes.OPEN_CHAPTERS),

    closeChapters: () => createAction(ActionTypes.CLOSE_CHAPTERS),
};

export type Actions = ActionsUnion<typeof Actions>;
