import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    CLEAR_SUBSCRIPTION_PURCHASE_FAILURE = '@payments/CLEAR_SUBSCRIPTION_PURCHASE_FAILURE',
    RETRY_PURCHASE = '@payments/RETRY_PURCHASE',
}

export const Actions = {
    clearSubscriptionPurchaseFailure: () =>
        createAction(ActionTypes.CLEAR_SUBSCRIPTION_PURCHASE_FAILURE),

    retryPurchase: () => createAction(ActionTypes.RETRY_PURCHASE),
};

export type Actions = ActionsUnion<typeof Actions>;
