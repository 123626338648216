import { v4 as uuidv4 } from 'uuid';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    FLAG_ADD = '@flags/FLAG_ADD',
    FLAG_REMOVE = '@flagsFLAG_REMOVE',
}

type FlagMessage = JSX.Element | string;

export const Actions = {
    addFlag: (message: FlagMessage, { id = uuidv4(), persistent = false } = {}) =>
        createAction(ActionTypes.FLAG_ADD, {
            id,
            message,
            persistent,
        }),

    removeFlag: (id: string) => createAction(ActionTypes.FLAG_REMOVE, { id }),
};

export type Actions = ActionsUnion<typeof Actions>;
