import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { cleanForFileName } from '../../../helper/StringHelper';
import { UPLOADED_FILES_PODCAST_UUID } from '../../../model/uploaded-files';
import FeatureLock from '../../FeatureLock';
import { ImageTextOption } from '../../popup/ImageTextOption';
import { PopupMenu } from '../../popup/PopupMenu';
import { PopupMenuClass, PopupMenuVariant } from '../../popup/PopupMenu/PopupMenu';
import { EpisodeActionProps, getEpisodeActions } from '../actions/episodeActions';

const StyledDownloadLink = styled.a`
    text-decoration: none;
    color: inherit;
`;

interface Props extends EpisodeActionProps {
    showSyncActions: boolean;
    episodePageOnlyActions?: boolean;
    popupVariant?: PopupMenuVariant;
}

export const MobileEpisodeActions: React.FC<Props> = ({
    showSyncActions,
    episodePageOnlyActions = false,
    popupVariant = PopupMenuVariant.DEFAULT,
    ...actionProps
}) => {
    const actions = getEpisodeActions(showSyncActions, episodePageOnlyActions);
    const intl = useIntl();
    const popupMenuRef = React.useRef<PopupMenuClass>(null);

    const downloadFileName =
        actionProps.podcastUuid === UPLOADED_FILES_PODCAST_UUID || !actionProps.title
            ? null // File name is instead set by downloadUploadedFile() or we depend on basic one set by the app
            : cleanForFileName(actionProps.title);

    return (
        <PopupMenu
            ref={popupMenuRef}
            aria-label={intl.formatMessage({ id: 'episode-actions' })}
            positionRelativeToWindow={true}
            popupVariant={popupVariant}
        >
            {actions.map(action => {
                if (!action.isVisible(actionProps)) {
                    return null;
                }

                const actionContent = (
                    <ImageTextOption
                        key={action.id}
                        icon={action.icon}
                        text={intl.formatMessage({ id: action.label })}
                        onClick={e => {
                            e.stopPropagation();
                            action.onClick(actionProps);
                            popupMenuRef.current?.closeMenu();
                        }}
                    />
                );

                return (
                    <FeatureLock
                        key={action.id}
                        source={action.featureLockSource}
                        title={
                            action.featureLockTitle
                                ? intl.formatMessage({ id: action.featureLockTitle })
                                : undefined
                        }
                        description={
                            action.featureLockDescription
                                ? intl.formatMessage({ id: action.featureLockDescription })
                                : undefined
                        }
                        enabled={action.featureLockSource !== undefined}
                    >
                        {action.id === 'download-file' ? (
                            <StyledDownloadLink
                                href={actionProps.url}
                                download
                                target="_blank"
                                rel="noopener noreferrer"
                                tabIndex={0}
                                data-filename={downloadFileName}
                            >
                                {actionContent}
                            </StyledDownloadLink>
                        ) : (
                            actionContent
                        )}
                    </FeatureLock>
                );
            })}
        </PopupMenu>
    );
};
