import '@babel/polyfill';
import * as Sentry from '@sentry/browser';
import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { store } from 'store';
import { locale } from 'util/locale';
import App from './App';
import { getPlatformName } from './helper/Browser';
import { watchForErrors } from './helper/GlobalErrorHelper';
import { beforeBreadcrumbHook, beforeSendHook } from './helper/SentryHelper';
import {
    acceptAllClicked,
    acceptSelectionClicked,
    analyitcsLoaded,
    cookiesBannerShown,
} from './redux/actions/analytics.actions';
import { electronMonitorMessages, electronSetLocale } from './redux/actions/electron.actions';

import {
    DEPLOYMENT_ENV,
    IS_PRERENDER,
    PADDLE_ENVIRONMENT,
    PADDLE_VENDOR_ID,
    RELEASE_ID,
    SENTRY_DSN,
    isNotProduction,
} from './settings';
import './util/a8c-analytics';

import { Router } from 'react-router';
import 'scss/style.scss';
import IntlProvider from './components/IntlProvider';
import { getBrowserHistory } from './customHistory';

export const CustomBrowserRouter = ({ children }: { children: ReactNode }) => {
    const history = getBrowserHistory();
    const [state, setState] = useState({
        action: history?.action,
        location: history.location,
    });

    useLayoutEffect(() => {
        return history.listen(({ location, action }) => {
            setState({ location, action });
        });
    }, [history]);

    return (
        <Router location={state.location} navigator={history}>
            {children}
        </Router>
    );
};

async function setup() {
    window.document.addEventListener('a8c-analytics:loaded', event => {
        const { detail } = event;
        store.dispatch(analyitcsLoaded(detail.maybeFireTrackers));
    });

    window.document.addEventListener('a8c-analytics:cookies-banner-shown', () => {
        store.dispatch(cookiesBannerShown());
    });

    window.document.addEventListener('a8c-analytics:accept-all-clicked', () => {
        store.dispatch(acceptAllClicked());
    });

    window.document.addEventListener('a8c-analytics:accept-selection-clicked', event => {
        const { detail } = event;
        store.dispatch(acceptSelectionClicked(detail.buckets.analytics));
    });

    window.addEventListener('DOMContentLoaded', () => {
        store.dispatch(electronMonitorMessages());
        store.dispatch(electronSetLocale(locale));
    });

    watchForErrors();

    Sentry.init({
        enabled: !IS_PRERENDER,
        dsn: SENTRY_DSN,
        release: RELEASE_ID,
        environment: DEPLOYMENT_ENV,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: isNotProduction ? 0 : 0.05,
        initialScope: {
            contexts: { platform: { name: getPlatformName() } },
        },
        debug: false,
        maxBreadcrumbs: 100,
        beforeSend: beforeSendHook,
        beforeBreadcrumb: beforeBreadcrumbHook,
    });

    window.Paddle?.Setup({
        vendor: PADDLE_VENDOR_ID,
        debug: false,
        enableTracking: false,
    });

    window.Paddle?.Environment.set(PADDLE_ENVIRONMENT);

    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <div>
            <Provider store={store}>
                <IntlProvider locale={locale}>
                    <CustomBrowserRouter>
                        <App />
                    </CustomBrowserRouter>
                </IntlProvider>
            </Provider>
        </div>,
    );
}

setup();
