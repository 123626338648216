import React from 'react';
import styled from 'styled-components';
import { DurationText } from '../..';
import { PlayingStatus } from '../../../helper/PlayingStatus';
import { MOBILE_WIDTH } from '../../../model/page';
import { gridSize } from '../../../styles';
import { TextColumn } from '../ListEpisodes.styled';
import { UpNextRow } from '../ListUpNextEpisodes.styled';

export const EpisodeDurationText = styled(TextColumn)`
    ${UpNextRow} & {
        padding-left: ${gridSize * 0.5}px;
    }

    .episode-row & {
        @media (max-width: ${MOBILE_WIDTH}px) {
            grid-column: 1 / 2;
            grid-row: 3;
        }
    }
`;

interface Props {
    duration: number;
    playedUpToSecs?: number;
    playingStatus?: number;
    format?: 'short' | 'long';
    inactive?: boolean;
}

export const EpisodeDuration: React.FC<Props> = ({
    duration,
    playedUpToSecs = null,
    playingStatus = PlayingStatus.NOT_PLAYED,
    format = 'short',
    inactive,
}) => {
    return (
        <EpisodeDurationText $inactive={inactive}>
            <DurationText
                durationSecs={duration}
                playedUpToSecs={playedUpToSecs}
                playingStatus={playingStatus}
                format={format}
            />
        </EpisodeDurationText>
    );
};
