import { Header } from 'components';
import { Icon } from 'components/Icon';
import { isElectronApp } from 'helper/Browser';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { createRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { TrackOnMount } from '../../components/Tracks';
import { useGetReferralCode } from '../../hooks/useGetReferralCode';
import useTracks from '../../hooks/useTracks';
import {
    Actions,
    CopyLinkButton,
    GuestPassDescription,
    GuestPassImageWrapper,
    GuestPassTitle,
    GuestPassWrapper,
    InvitePageWrapper,
    LinkInputWrapper,
    ShareButtons,
    ShareWrapper,
    SocialButtons,
} from './InvitePage.styled';
import GuestPassImage from './guest-pass.svg';

export type Props = {
    addFlag: (message: string) => void;
};

function InvitePage({ addFlag }: Props) {
    const formatMessage = useFormatMessage();
    const inputRef = createRef<HTMLInputElement>();
    const { recordEvent } = useTracks();

    const getEmailUrl = (url: string) => {
        const subject = formatMessage('gift-plus-email-subject');
        const body = formatMessage('gift-plus-email-body');
        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}${encodeURIComponent('\n\n')}${encodeURIComponent(url)}`;
    };

    const onCopyLink = useCallback(() => {
        inputRef.current?.select();
        if (inputRef.current?.value) {
            recordEvent('referral_pass_shared');
            navigator.clipboard.writeText(inputRef.current?.value);
            if (isElectronApp() && window?.electron?.copyToClipboard) {
                window.electron.copyToClipboard({ text: inputRef.current?.value });
            }
        }
        addFlag(formatMessage('link-copied'));
    }, [inputRef, addFlag, formatMessage, recordEvent]);

    const referralCode = useGetReferralCode();

    return (
        <InvitePageWrapper>
            <TrackOnMount event="referral_share_screen_shown" />
            <Helmet>
                <title>{formatMessage('invite-friends')}</title>
            </Helmet>
            <Header title={formatMessage('invite-friends')} />

            <GuestPassWrapper>
                <ShareWrapper>
                    <GuestPassTitle>
                        {formatMessage('gift-plus', {
                            passLength: formatMessage('gift-plus-duration'),
                        })}
                    </GuestPassTitle>
                    <GuestPassDescription>
                        {formatMessage('gift-plus-description', {
                            passLength: formatMessage('gift-plus-duration'),
                        })}
                    </GuestPassDescription>

                    <ShareButtons>
                        <LinkInputWrapper>
                            <input
                                name="code"
                                placeholder={formatMessage('loading-referral-code')}
                                ref={inputRef}
                                type="text"
                                defaultValue={referralCode?.url}
                                readOnly={true}
                            />
                        </LinkInputWrapper>

                        <Actions>
                            <CopyLinkButton
                                disabled={!referralCode}
                                onClick={onCopyLink}
                                title={formatMessage('copy-link')}
                            >
                                {formatMessage('copy-link')}
                            </CopyLinkButton>

                            <SocialButtons>
                                <a
                                    aria-disabled={!referralCode}
                                    className="email grow"
                                    target="_blank"
                                    tabIndex={!referralCode ? -1 : 0}
                                    rel="noopener noreferrer"
                                    onClick={() => recordEvent('referral_pass_shared')}
                                    href={referralCode ? getEmailUrl(referralCode?.url) : ''}
                                >
                                    <Icon id="email" />
                                </a>
                            </SocialButtons>
                        </Actions>
                    </ShareButtons>
                </ShareWrapper>

                <GuestPassImageWrapper>
                    <img alt="" height={204} width={298} src={GuestPassImage} />
                    <span>
                        {formatMessage('gift-plus-card-text', {
                            passLengthAdjective: formatMessage('gift-plus-duration-adjective'),
                        })}
                    </span>
                </GuestPassImageWrapper>
            </GuestPassWrapper>
        </InvitePageWrapper>
    );
}

export default InvitePage;
