import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../../../hooks/react-redux-typed';
import { THEME } from '../../../../model/theme';
import * as fromSettingsActions from '../../../../redux/actions/settings.actions';
import { RootState } from '../../../../redux/reducers';
import {
    DarkThemeOption,
    LightThemeOption,
    SystemThemeOption,
    ThemeOptionText,
    ThemeOptionWrapper,
    ThemeToggleContainer,
} from './ThemeToggle.styled';

function ThemeToggle() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const currentTheme = useSelector((state: RootState) => state.settings.theme);
    const [selectedTheme, setSelectedTheme] = useState(currentTheme);

    const onThemeChanged = (theme: number) => {
        if (theme !== selectedTheme) {
            setSelectedTheme(theme);
            dispatch(fromSettingsActions.Actions.updateTheme(theme));

            // If the system theme was toggled on or off, record it
            if (theme === THEME.system || selectedTheme === THEME.system) {
                recordEvent('settings_appearance_follow_system_theme_toggled', {
                    enabled: theme === THEME.system,
                });
            }

            // If the theme was changed to light or dark, record it
            if (theme !== THEME.system) {
                recordEvent('settings_appearance_theme_changed', {
                    value: `default_${THEME[theme]}`,
                });
            }
        }
    };

    useEffect(() => {
        // This is a rare case - but if our settings are updated from the server - this will update the control
        if (selectedTheme !== currentTheme) {
            setSelectedTheme(currentTheme);
        }
    }, [currentTheme]);

    return (
        <ThemeToggleContainer>
            <ThemeOptionWrapper data-testid="theme-option-wrapper">
                <LightThemeOption
                    data-testid="theme-option-light"
                    onClick={() => onThemeChanged(THEME.light)}
                    $currentTheme={selectedTheme}
                    selected={selectedTheme === THEME.light}
                    aria-pressed={selectedTheme === THEME.light}
                >
                    <ThemeOptionText title={formatMessage('theme-toggle-light')}>
                        {formatMessage('theme-toggle-light')}
                    </ThemeOptionText>
                </LightThemeOption>
                <DarkThemeOption
                    data-testid="theme-option-dark"
                    onClick={() => onThemeChanged(THEME.dark)}
                    $currentTheme={selectedTheme}
                    selected={selectedTheme === THEME.dark}
                    aria-pressed={selectedTheme === THEME.dark}
                >
                    <ThemeOptionText title={formatMessage('theme-toggle-dark')}>
                        {formatMessage('theme-toggle-dark')}
                    </ThemeOptionText>
                </DarkThemeOption>
                <SystemThemeOption
                    data-testid="theme-option-system"
                    onClick={() => onThemeChanged(THEME.system)}
                    $currentTheme={selectedTheme}
                    selected={selectedTheme === THEME.system}
                    aria-pressed={selectedTheme === THEME.system}
                >
                    <ThemeOptionText title={formatMessage('theme-toggle-match-system')}>
                        {formatMessage('theme-toggle-match-system')}
                    </ThemeOptionText>
                </SystemThemeOption>
            </ThemeOptionWrapper>
        </ThemeToggleContainer>
    );
}

export default ThemeToggle;
