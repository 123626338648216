import { ActionTypes, Actions } from '../actions/intl.actions';

const INITIAL_STATE = {
    locale: 'en',
    messages: {},
};

export type IntlState = typeof INITIAL_STATE;

export default (state: IntlState = INITIAL_STATE, action: Actions): IntlState => {
    switch (action.type) {
        case ActionTypes.INTL_UPDATE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
