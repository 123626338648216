import styled from 'styled-components';
import { blueTextLinkMixin, gridSize } from 'styles';

const HORIZONTAL_MARGIN_SIZE = 24;

export const Form = styled.form`
    width: 452px;
    height: 70vh;
    min-height: 400px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

export const FormSection = styled.div<{ $fullWidth?: boolean }>`
    width: 500px;
    margin-bottom: ${2 * gridSize}px;
    min-height: 0;

    ${props =>
        props.$fullWidth &&
        `
            margin-left: -${HORIZONTAL_MARGIN_SIZE}px;
            margin-right: -${HORIZONTAL_MARGIN_SIZE}px;
        `}
`;

export const Footer = styled.div`
    margin: auto 0 ${2 * gridSize}px;
    display: flex;
    flex-wrap: wrap;
    button[kind='primary'] {
        padding: 0 ${3 * gridSize}px;
        margin-left: auto;
    }
    button[type='button'] {
        ${blueTextLinkMixin}
    }
`;

export const ErrorMessage = styled.div`
    width: 100%;
    flex-shrink: 0;
    margin: ${gridSize}px 0 ${2 * gridSize}px;
`;
