import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

type DiscountsState = {
    couponCode?: string;
    guestPassCode?: string;
    hasDiscount: boolean;
};

const useQueryDiscounts = () => {
    const location = useLocation();
    const [discounts, setDiscounts] = useState<DiscountsState>({ hasDiscount: false });

    useEffect(() => {
        const { code: couponCodeFromQuery, redeem: guestPassCodeFromQuery } = queryString.parse(
            location.search,
        );
        const couponCode = couponCodeFromQuery?.toString();
        const guestPassCode = guestPassCodeFromQuery?.toString();

        setDiscounts({ couponCode, guestPassCode, hasDiscount: !!couponCode || !!guestPassCode });
    }, [location]);

    return discounts;
};

export default useQueryDiscounts;
