import { buttonMixin } from 'components/Button/Button.styled';
import { Link } from 'react-router';
import styled from 'styled-components';

export const StyledLink = styled(Link)<{
    disabled: boolean;
    kind: string;
    width?: number;
    height: number;
}>`
    ${buttonMixin}
    text-decoration: none !important;
`;
