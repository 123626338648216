import styled from 'styled-components';
import { Typography } from '../../../styles';

export const Spons0redBadge = styled.div`
    ${Typography.Desktop['H70-TBC']};
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const FreshPickBadge = styled.div`
    ${Typography.Desktop['H70-TBC']};
    color: ${props => props.theme.tokens['support-02']};
`;

export const DiscoverSingleTitle = styled.p`
    ${Typography.Desktop.H30};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const DiscoverSingleDescription = styled.div`
    ${Typography.Desktop.H70};
    max-height: 42px;
    overflow: hidden;
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const DiscoverSingleText = styled.div`
    flex: 1;
    height: 104px;
    padding-left: 32px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 112px);
    justify-content: space-evenly;
`;

export const DiscoverSingleWrapper = styled.div`
    cursor: pointer;
    display: flex;
`;

export const ImageWrapper = styled.div`
    position: relative;
    width: 112px;
    .sub5cribe-button {
        position: absolute;
        right: 4px;
        bottom: 4px;
        visibility: hidden;
        ${DiscoverSingleWrapper}:hover &, &.sub5cribed {
            visibility: visible;
        }
    }
`;
