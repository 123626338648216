import styled, { css } from 'styled-components';
import { Typography } from 'styles';

export const Trimmer = styled.div`
    position: relative;
    top: -48px;
    height: 48px;
    bottom: 0;
    border: 5px solid ${props => props.theme.legacyScss['theme-text-link']};
    border-radius: 8px;
`;

export const Handle = styled.button`
    width: 12px;
    height: 100%;
    background-color: ${props => props.theme.legacyScss['theme-text-link']};
    position: absolute;
    cursor: ew-resize;
`;

export const StartHandle = styled(Handle)`
    left: 0;
`;

export const EndHandle = styled(Handle)`
    right: 0;
`;

export const OverlayHandle = styled.button`
    position: absolute;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    height: 100%;
    cursor: grab;
`;

export const Gripper = styled.div`
    width: 1.5px;
    height: 35px;
    background-color: #0a8bc7;
    border-radius: 8px;
    position: absolute;
    top: 2px;

    &.left-gripper {
        left: 3px;
    }

    &.right-gripper {
        right: 3px;
    }
`;

export const TimeSteppers = styled.div`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-02']};
    margin-top: 24px;

    display: flex;
    align-items: center;

    input {
        height: 48px;
        width: 92px;
        border: 1px solid ${props => props.theme.tokens['primary-field-03']};
        background-color: ${props => props.theme.tokens['primary-field-02']};
        color: ${props => props.theme.tokens['primary-text-02']};
        border-radius: 4px;
        padding: 16px;
        margin-left: 6px;
        margin-right: 6px;
    }

    svg {
        color: ${props => props.theme.tokens['primary-icon-02']};

        &:hover {
            color: ${props => props.theme.tokens['primary-icon-02-active']};
        }
    }
`;

interface InputContainerProps {
    $isDurationOverHour: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
    position: relative;
    display: inline-block;
    ${props =>
        props.$isDurationOverHour &&
        css`
            input {
                width: 114px;
            }
        `}
`;

export const StepperButtons = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
`;

export const IncrementButton = styled.button`
    cursor: pointer;
    ${Typography.Desktop['H50-B']};
    position: relative;
    top: 0px;
`;

export const DecrementButton = styled.button`
    cursor: pointer;
    ${Typography.Desktop['H50-B']};
    position: relative;
    bottom: -2px;
`;
