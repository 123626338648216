import styled, { css } from 'styled-components';

// This is the 'dangerously' set html
const ShowNotesContent = css`
    a {
        overflow-wrap: break-word;
        text-decoration: underline;
        word-wrap: break-word;
        word-break: break-all;

        color: var(--tint-color);
        &:hover {
            opacity: 0.8;
        }

        transition: all 0.1s ease;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 5px 0;
    }
    h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        letter-spacing: 0.4px;
    }

    h2 {
        font-size: 20px;
        line-height: 21px;
        font-weight: 500;
        letter-spacing: 0.3px;
    }

    h3 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.3px;
    }

    h4 {
        font-size: 14px;
        line-height: 20px;
    }

    h5 {
        font-size: 14px;
        line-height: 20px;
    }

    h6 {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.3px;
    }

    p {
        letter-spacing: 0.2px;
        margin: 0 0 10px 0;
    }

    li {
        letter-spacing: 0.2px;
    }

    img {
        width: auto !important;
        height: auto !important;
        max-width: 340px;
        max-height: 340px;
        padding: 20px;
    }

    img[src*='coverart'],
    img[src*='CoverArt'],
    img[src*='COVERART'],
    img[src*='feeds.feedburner.com'],
    img[src*='album_art'] {
        display: none;
    }

    pre {
        white-space: pre-wrap;
    }
`;

export const ShowNotesWrapper = styled.div<{ $downloaded: boolean; $noHtml: boolean }>`
    color: ${props => props.theme.tokens['primary-text-01']};
    font-size: 14.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;
    word-wrap: break-word;
    padding-bottom: 20px;

    transition: opacity 300ms ease-in-out;

    ${props => props.$downloaded && `opacity: 1`};
    ${props => !props.$downloaded && `opacity: 0`};
    ${props => props.$noHtml && `white-space: pre-wrap`};

    ${ShowNotesContent}
`;
