import { Icon } from 'components/Icon';
import { TrackOnMount } from 'components/Tracks';
import { ExpiresDateMessage } from 'components/messages';
import { SubscriptionTier } from 'model/types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavigationItems } from '../../../helper/NavigationHelper';
import { LOCAL_STORAGE_KEY_EMAIL } from '../../../model/local-storage';
import { MAX_DAYS_FOR_EXPIRY_COUNTDOWN } from '../../../model/subscription';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import ShowOnMobile from '../../responsive/MobileWrapper';
import { DynamicAccountIcon } from '../DynamicAccountIcon';
import {
    BackLink,
    EmailText,
    FilesFullText,
    FilesMenuItemSubTextWrapper,
    ProfileChevronWrapper,
    ProfileDroplist,
    ProfileItemTextWrapper,
    ProfileMenuItemButton,
    ProfileMenuItemImage,
    ProfileMenuItemImageLarge,
    ProfileMenuItemLink,
    ProfileMenuItemText,
    ProfileSubtitleText,
    ProfileSubtitleWarn,
    Separator,
    ThemeMenuItemTopWrapper,
    ThemeMenuItemWrapper,
} from './ProfileMenu.styled';
import { ThemeToggle } from './ThemeToggle';

type Props = {
    uploadedFilesSuspended: boolean;
    onNavigate: () => void;
    fetchUploadedFilesUsage: () => void;
    uploadedFilesPercent: string;
    uploadedFilesPercentExact: number;
    uploadedFilesNumber: number;
    recordEvent: typeof fromTracksActions.Actions.recordEvent;
    signOut: () => void;
    subscriptionAutoRenewing: boolean;
    subscriptionExpiryDate: string;
    toggleTheme: () => void;
    tier?: SubscriptionTier;
    isSubscriber: boolean;
    className?: string;
    onDismiss?: () => void;
};

export class ProfileMenu extends Component<Props> {
    componentDidMount() {
        if (
            !this.props.uploadedFilesSuspended &&
            (!this.props.uploadedFilesPercent || !this.props.uploadedFilesNumber)
        ) {
            this.props.fetchUploadedFilesUsage();
        }
    }

    handleDismiss = () => {
        this.props.onDismiss?.();
    };

    renderProfileSubtitle() {
        const { subscriptionAutoRenewing, subscriptionExpiryDate, tier } = this.props;

        if (!tier) {
            return null;
        }

        if (subscriptionExpiryDate) {
            if (subscriptionAutoRenewing) {
                return <ProfileSubtitleText>Pocket Casts {tier}</ProfileSubtitleText>;
            }

            const currentDate = new Date();
            const expiryDate = new Date(subscriptionExpiryDate);
            const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= MAX_DAYS_FOR_EXPIRY_COUNTDOWN) {
                return (
                    <ProfileSubtitleWarn>
                        <ExpiresDateMessage expiryDate={expiryDate} />
                    </ProfileSubtitleWarn>
                );
            }
        }

        return <ProfileSubtitleText>Pocket Casts {tier}</ProfileSubtitleText>;
    }

    renderItems() {
        const {
            recordEvent,
            uploadedFilesPercent,
            uploadedFilesPercentExact,
            uploadedFilesNumber,
            uploadedFilesSuspended,
            isSubscriber,
        } = this.props;

        const email = localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL);

        return (
            <>
                <TrackOnMount event="profile_shown" />
                <ShowOnMobile>
                    <Separator className="separator" />
                    <BackLink onClick={this.handleDismiss}>
                        <Icon id="arrow-right" />
                        <FormattedMessage id="back" />
                    </BackLink>
                    <Separator className="separator" />
                </ShowOnMobile>
                <ProfileMenuItemLink
                    onClick={() => {
                        this.props.onNavigate();
                        recordEvent('profile_account_button_tapped');
                    }}
                    to={NavigationItems.PROFILE.path}
                >
                    <ProfileMenuItemImageLarge>
                        <DynamicAccountIcon large email={email} />
                    </ProfileMenuItemImageLarge>
                    <ProfileItemTextWrapper>
                        {this.renderProfileSubtitle()}
                        <EmailText>{email}</EmailText>
                    </ProfileItemTextWrapper>
                    <ProfileChevronWrapper>
                        <Icon id="chevron-down" />
                    </ProfileChevronWrapper>
                </ProfileMenuItemLink>
                <Separator className="separator" />
                {!uploadedFilesSuspended && (
                    <ProfileMenuItemLink
                        onClick={this.props.onNavigate}
                        to={NavigationItems.UPLOADED_FILES.path}
                    >
                        <ProfileMenuItemImage>
                            <Icon id="files" />
                        </ProfileMenuItemImage>
                        <ProfileMenuItemText>
                            <FormattedMessage id="files" />
                            {!Number.isNaN(uploadedFilesNumber) &&
                            !Number.isNaN(uploadedFilesPercent) ? (
                                <FilesMenuItemSubTextWrapper>
                                    {uploadedFilesNumber}{' '}
                                    <FormattedMessage
                                        id={uploadedFilesNumber === 1 ? 'file' : 'files'}
                                    />
                                    &nbsp;/&nbsp;
                                    <FilesFullText $percentage={uploadedFilesPercentExact}>
                                        {uploadedFilesPercent}% <FormattedMessage id="full" />
                                    </FilesFullText>
                                </FilesMenuItemSubTextWrapper>
                            ) : (
                                <FilesMenuItemSubTextWrapper>&nbsp;</FilesMenuItemSubTextWrapper>
                            )}
                        </ProfileMenuItemText>
                    </ProfileMenuItemLink>
                )}
                <ProfileMenuItemLink
                    onClick={this.props.onNavigate}
                    to={NavigationItems.STATS.path}
                >
                    <ProfileMenuItemImage>
                        <Icon id="stats" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="stats" />
                    </ProfileMenuItemText>
                </ProfileMenuItemLink>
                <Separator className="separator" />
                <ThemeMenuItemWrapper>
                    <ThemeMenuItemTopWrapper>
                        <ProfileMenuItemImage>
                            <Icon id="theme" />
                        </ProfileMenuItemImage>
                        <ProfileMenuItemText>
                            <FormattedMessage id="theme-toggle" />
                        </ProfileMenuItemText>
                    </ThemeMenuItemTopWrapper>
                    <ThemeToggle />
                </ThemeMenuItemWrapper>
                <Separator className="separator" />
                {isSubscriber && (
                    <ProfileMenuItemLink
                        onClick={this.props.onNavigate}
                        to={NavigationItems.INVITE.path}
                    >
                        <ProfileMenuItemImage>
                            <Icon id="gift" />
                        </ProfileMenuItemImage>
                        <ProfileMenuItemText>
                            <FormattedMessage id="invite-friends" />
                        </ProfileMenuItemText>
                    </ProfileMenuItemLink>
                )}
                <ProfileMenuItemLink
                    onClick={() => {
                        this.props.onNavigate();
                        recordEvent('profile_settings_button_tapped');
                    }}
                    to={NavigationItems.SETTINGS.path}
                >
                    <ProfileMenuItemImage>
                        <Icon id="settings" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="settings" />
                    </ProfileMenuItemText>
                </ProfileMenuItemLink>
                <ProfileMenuItemButton
                    onClick={() => {
                        this.props.onNavigate();
                        this.props.signOut();
                    }}
                >
                    <ProfileMenuItemImage>
                        <Icon id="sign-out" />
                    </ProfileMenuItemImage>
                    <ProfileMenuItemText>
                        <FormattedMessage id="sign-out" />
                    </ProfileMenuItemText>
                </ProfileMenuItemButton>
            </>
        );
    }

    render() {
        return (
            <ProfileDroplist className={this.props.className}>{this.renderItems()}</ProfileDroplist>
        );
    }
}

export default ProfileMenu;
