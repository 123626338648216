import { Icon } from 'components/Icon';
import React from 'react';
import { StyledArrowButton } from './ArrowButton.styled';

type Props = {
    width?: number;
    height?: number;
    onClick?: () => void;
    color?: string;
    label: string;
    fontSize?: number;
};
function ArrowButton({
    width = 24,
    height = 24,
    onClick = () => ({}),
    color = '#03A9F4',
    label = '',
    fontSize = 20,
    ...rest
}: Props) {
    return (
        <StyledArrowButton
            className="arrow-button"
            fontSize={fontSize}
            $accent={color}
            width={width}
            height={height}
            onClick={onClick}
            {...rest}
        >
            <span>{label}</span>
            <div className="arrow">
                <Icon id="arrow-right" size={width} />
            </div>
        </StyledArrowButton>
    );
}

export default ArrowButton;
