import { useLocation } from 'react-router';

const useQueryRedirect = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get('redirect');

    return redirectPath || undefined;
};

export default useQueryRedirect;
