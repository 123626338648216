import { isElectronApp } from 'helper/Browser';

import {
    playerPause,
    playerPlay,
    playerPlayPause,
    playerSkipBack,
    playerSkipForward,
    seekPlayerTo,
} from 'pages/LoggedInPageChrome/PlayerControls/PlayerControls';
import { Action } from '../actions';
import {
    electronMiniPlayerLoadEpisode,
    electronMiniPlayerOpened,
    electronMiniPlayerPause,
    electronMiniPlayerPlay,
    electronMiniPlayerSeekTo,
    electronSyncingState,
    electronSyncingStateDone,
} from '../actions/electron.actions';
import * as fromPlayerActions from '../actions/player.actions';
import * as fromUserActions from '../actions/user.actions';
import { registerMiddleware } from '../api';
import { RootState } from '../reducers';
import { getEpisodeImageUrl } from '../reducers/selectors';
import { isElectronSyncing, isMiniPlayerOpen } from '../reducers/selectors/electron.selectors';

registerMiddleware('ELECTRON_MONITOR_MESSAGES', async (action, store) => {
    if (!isElectronApp()) {
        return;
    }

    const setupElectronReceiver = <T extends keyof ElectronEventMap>(
        message: T,
        handler: (args: ElectronEventMap[T]) => void,
    ) => window?.electron?.receive(message, handler);

    /*
     * This dispatches an action, but sets a special flag in the state to indicate that
     * the state is currently being synced between the main and mini players. This is used to prevent
     * the main and mini players from sending messages back and forth to each other, which would
     * create an infinite loop of messages. This is a workaround for the fact that the main and mini
     * players are separate windows, and therefore separate instances of the app, and therefore separate
     * instances of the Redux store.
     *
     * Note that Redux recommends not dispatching actions sequentially to avoid multiple UI updates,
     * however, we should be OK here since isSyncing is not used for UI updates, and only to block
     * further message processing.
     * See https://redux.js.org/style-guide/#avoid-dispatching-many-actions-sequentially
     */
    const syncStateFromOtherWindow = (action: Action) => {
        store.dispatch(electronSyncingState());
        store.dispatch(action);
        store.dispatch(electronSyncingStateDone());
    };

    setupElectronReceiver('player:seekTo', ({ playedUpTo }) =>
        seekPlayerTo(playedUpTo, 'miniplayer'),
    );

    setupElectronReceiver('player:playPause', () => playerPlayPause('media_session'));

    setupElectronReceiver('player:play', () => playerPlay('miniplayer'));
    setupElectronReceiver('player:pause', () => playerPause('miniplayer'));
    setupElectronReceiver('player:skipBack', () => playerSkipBack('miniplayer'));
    setupElectronReceiver('player:skipForward', () => playerSkipForward('miniplayer'));

    setupElectronReceiver('miniPlayer:seekTo', ({ playedUpTo }) =>
        syncStateFromOtherWindow(electronMiniPlayerSeekTo(playedUpTo)),
    );
    setupElectronReceiver('miniPlayer:play', () =>
        syncStateFromOtherWindow(electronMiniPlayerPlay()),
    );
    setupElectronReceiver('miniPlayer:pause', () =>
        syncStateFromOtherWindow(electronMiniPlayerPause()),
    );
    setupElectronReceiver('miniPlayer:loadEpisode', ({ episode }) =>
        syncStateFromOtherWindow(electronMiniPlayerLoadEpisode(episode)),
    );
    setupElectronReceiver('miniPlayer:setOpened', ({ isOpened }) =>
        syncStateFromOtherWindow(electronMiniPlayerOpened(isOpened)),
    );
});

const shouldSyncMiniPlayerState = (state: RootState) => {
    return isElectronApp() && isMiniPlayerOpen(state) && !isElectronSyncing(state);
};

const shouldSyncMainPlayerState = (state: RootState) => {
    return isElectronApp() && !isElectronSyncing(state);
};

registerMiddleware('ELECTRON_SET_LOCALE', action => {
    if (isElectronApp() && window?.electron?.setLocale) {
        const { locale } = action.payload;
        window.electron.setLocale({ locale });
    }
});

// Sync from mini player to main player

registerMiddleware('ELECTRON_MINI_PLAYER_OPENED', (action, store) => {
    if (!isElectronApp()) {
        return;
    }

    if (window?.electron?.currentWindow === 'miniPlayer') {
        store.dispatch(fromUserActions.Actions.userReturned());
    }

    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.miniPlayer.setOpened({ isOpened: action.payload.isOpened });
    }
});

registerMiddleware('ELECTRON_MINI_PLAYER_SEEK_TO', (action, store) => {
    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.player.seekTo({
            playedUpTo: action.payload.playedUpTo,
        });
    }
});

registerMiddleware('ELECTRON_MINI_PLAYER_PLAY', (action, store) => {
    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.player.play();
    }
});

registerMiddleware('ELECTRON_MINI_PLAYER_PAUSE', (action, store) => {
    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.player.pause();
    }
});

registerMiddleware('ELECTRON_MINI_PLAYER_SKIP_BACK', (action, store) => {
    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.player.skipBack();
    }
});

registerMiddleware('ELECTRON_MINI_PLAYER_SKIP_FORWARD', (action, store) => {
    if (shouldSyncMainPlayerState(store.getState())) {
        window?.electron?.player.skipForward();
    }
});

// Sync from main player to mini player

registerMiddleware(fromPlayerActions.ActionTypes.PLAY, (action, store) => {
    if (shouldSyncMiniPlayerState(store.getState())) {
        window?.electron?.miniPlayer.play();
    }

    if (isElectronApp() && window?.electron?.setPlayingStatus) {
        window.electron.setPlayingStatus({ playing: true });
    }
});

registerMiddleware(fromPlayerActions.ActionTypes.PAUSE, (action, store) => {
    if (shouldSyncMiniPlayerState(store.getState())) {
        window?.electron?.miniPlayer.pause();
    }

    if (isElectronApp() && window?.electron?.setPlayingStatus) {
        window.electron.setPlayingStatus({ playing: false });
    }
});

registerMiddleware(fromPlayerActions.ActionTypes.UPDATE_PLAYED_UP_TO, (action, store) => {
    if (shouldSyncMiniPlayerState(store.getState())) {
        window?.electron?.miniPlayer.seekTo({
            playedUpTo: action.payload.playedUpTo,
        });
    }
});

registerMiddleware(fromPlayerActions.ActionTypes.LOAD_EPISODE, (action, store) => {
    const currentEpisodeUrl = store.getState().player.episode?.url;
    const urlParts = new URL(currentEpisodeUrl);

    // Electron does not prompt nor handle basic auth in URLs, so we need to pass them explicitly
    if (isElectronApp() && urlParts.username && urlParts.password) {
        const urlWithoutAuth =
            urlParts.origin + urlParts.pathname + urlParts.search + urlParts.hash;

        window?.electron?.setBasicAuthCredentials({
            username: urlParts.username,
            password: urlParts.password,
            url: urlWithoutAuth,
        });
    }

    if (shouldSyncMiniPlayerState(store.getState())) {
        const { episode } = action.payload;
        const state = store.getState();

        const showNotesImage = getEpisodeImageUrl(state, episode.uuid);
        const imageUrl = episode.imageUrl ?? showNotesImage;

        window?.electron?.miniPlayer.loadEpisode({
            episode: {
                ...episode,
                imageUrl,
            },
        });
    }
});
