import { BookmarksListResponse, BookmarksListResult } from 'model/types';
import { createAction } from './action-creators';

export const fetchBookmarks = () => createAction('FETCH_BOOKMARKS');

export const fetchBookmarksSuccess = (response: BookmarksListResponse) =>
    createAction('FETCH_BOOKMARKS_SUCCESS', { response });

export const fetchBookmarksFailed = () => createAction('FETCH_BOOKMARKS_FAILED');

export const saveBookmarks = (bookmarks: BookmarksListResult[]) =>
    createAction('SAVE_BOOKMARKS', { bookmarks });
