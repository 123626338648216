import { connect } from 'react-redux';
import * as fromUploadedFilesActions from '../../redux/actions/uploaded-files.actions';
import { RootState } from '../../redux/reducers';
import { AppDispatch } from '../../store';
import UploadedFilesPage from './view';

const mapStateToProps = (state: RootState) => ({
    isLoading: state.uploadedFiles.isLoading,
    filesOrder: state.uploadedFiles.data.filesOrder || [],
    account: state.uploadedFiles.data.account,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchUploadedFilesData: () =>
        dispatch(fromUploadedFilesActions.Actions.fetchUploadedFilesData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadedFilesPage);
