import { PopupMenuWrapped } from 'components/popup/PopupMenu/PopupMenuWrapped';
import styled from 'styled-components';
import { ImageTextOption } from '../../../../components';

export const StyledPopupMenu = styled(PopupMenuWrapped)`
    min-width: 240px;
    padding-bottom: 12px;

    .more-actions-menu-item {
        border-bottom: 0;

        svg {
            color: ${props => props.theme.tokens['primary-icon-02']};
            position: relative;
            top: initial;
            left: initial;
        }

        .option-title {
            font-size: 14px;
            font-weight: 500;
            color: ${props => props.theme.tokens['primary-text-02']};
            padding: 0;
            line-height: 16px;
            margin-left: 16px;
        }
    }
`;

export const MoreMenuImageTextOption = styled(ImageTextOption)`
    height: inherit;
    display: flex;
    align-items: center;
    padding: 12px 48px 12px 24px;
`;
