import styled from 'styled-components';
import { visuallyHidden } from 'styles';

export const NumberInput = styled.input`
    ${visuallyHidden}
`;

export const Wrapper = styled.div`
    color: ${props => props.theme.tokens['primary-interactive-01']};
    display: flex;
    width: 280px;
    justify-content: space-around;
    cursor: pointer;
    border-radius: 8px;

    svg {
        transition: transform 100ms ease-out;
    }
    ${NumberInput}:focus-visible + & {
        svg {
            animation: pulse 2s ease-in-out infinite;
            transform: scale(1.05);
        }
    }
    @keyframes pulse {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 0.8;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
`;
