import * as EpisodeHelper from 'helper/EpisodeHelper';
import { EpisodeSortOrder, sortPodcastListItems } from 'helper/PodcastHelper';
import { getThemeFromId } from 'model/theme';
import { EpisodeWithSyncInfo, Folder } from 'model/types';
import { PodcastBadges, PodcastListFolders } from 'pages/PodcastsPage/model';
import { createSelector } from 'reselect';
import { FOLDER_COLORS } from 'styles/colors';
import { RootState } from '..';
import { getEpisodeSyncByUuid } from './episode-sync.selectors';
import { getGridOrder, getSettings, getTheme } from './settings.selectors';
import { hasPaidSubscription } from './subscription.selectors';

export const getSubscribedPodcastUuids = (state: RootState) => state.podcasts.subscribedUuids;

export const getUuidToPodcast = (state: RootState) => state.podcasts.uuidToPodcast;

export const getSubscribedPodcasts = createSelector(
    [getSubscribedPodcastUuids, getUuidToPodcast],
    (subscribedPodcastUuids, uuidToPodcast) =>
        subscribedPodcastUuids.map(uuid => uuidToPodcast[uuid]).filter(podcast => !!podcast),
);

export const getPodcastByUuid = (state: RootState, podcastUuid: string) =>
    state.podcasts.uuidToPodcast[podcastUuid];

export const getPodcastsByUuid = createSelector(
    [getUuidToPodcast, (state, podcastsUuids) => podcastsUuids],
    (uuidToPodcasts, podcastUuids) =>
        Object.values(uuidToPodcasts).filter(({ uuid }) => podcastUuids.includes(uuid)),
);

export const getPodcastLoadFailed = (state: RootState) => state.podcast.loadFailed;

export const getUuidToColors = (state: RootState) => state.podcasts.uuidToColors;

export const getColorsByUuid = (state: RootState, podcastUuid: string) =>
    getUuidToColors(state)[podcastUuid];

export const getIsSubscribedPodcastsLoaded = (state: RootState) => state.podcasts.isLoaded;

export const getIsSubscribedPodcastsLoading = (state: RootState) => state.podcasts.isLoading;

export const getAutoStartFrom = (state: RootState, podcastUuid: string): number => {
    const { uuidToPodcast } = state.podcasts;
    if (
        uuidToPodcast[podcastUuid] &&
        state.podcasts.subscribedUuids.includes(podcastUuid) &&
        uuidToPodcast[podcastUuid].autoStartFrom &&
        Number.isInteger(uuidToPodcast[podcastUuid].autoStartFrom) &&
        uuidToPodcast[podcastUuid].autoStartFrom > 0
    ) {
        return uuidToPodcast[podcastUuid].autoStartFrom;
    }
    return 0;
};

export const getAutoSkipLast = (state: RootState, podcastUuid: string) => {
    const { uuidToPodcast } = state.podcasts;
    if (
        uuidToPodcast[podcastUuid] &&
        state.podcasts.subscribedUuids.includes(podcastUuid) &&
        uuidToPodcast[podcastUuid].autoSkipLast &&
        Number.isInteger(uuidToPodcast[podcastUuid].autoSkipLast) &&
        uuidToPodcast[podcastUuid].autoSkipLast > 0
    ) {
        return uuidToPodcast[podcastUuid].autoSkipLast;
    }
    return 0;
};

export const getAutoArchivePlayed = (state: RootState, podcastUuid: string) => {
    const { uuidToPodcast } = state.podcasts;
    if (
        uuidToPodcast[podcastUuid] &&
        state.podcasts.subscribedUuids.includes(podcastUuid) &&
        uuidToPodcast[podcastUuid].settings.autoArchive.changed &&
        uuidToPodcast[podcastUuid].settings.autoArchivePlayed.changed
    ) {
        return (
            Boolean(uuidToPodcast[podcastUuid].settings.autoArchive.value) &&
            Boolean(uuidToPodcast[podcastUuid].settings.autoArchivePlayed.value)
        );
    }

    return Boolean(state.settings.autoArchivePlayed);
};

/**
 * This function returns folders of sorted PodcastListItems, including a special 'home' folder for the
 * top level. This can be used to display the home folder or user folders for the Podcasts Page. It
 * can also be used to get the current sorted state, which can then be manipulated when re-ordering
 * items for drag and drop sorting.
 *
 * @returns PodcastListFolders An object representing sorted items in folders, { home: [...], [folderUuid]: [...], ...}
 */
export const getPodcastListFolders = (state: RootState): PodcastListFolders => {
    const podcasts = getSubscribedPodcasts(state);
    const folders = Object.values(getFolders(state));
    const gridOrder = getGridOrder(state);
    const { badges } = state.settings;

    // Create empty folders, including the hard-coded home folder
    const list = { home: [] } as PodcastListFolders;
    folders.forEach(folder => {
        list[folder.uuid] = [];
    });

    // Add all podcasts to their respective folder
    podcasts.forEach(podcast => {
        const folder = list[podcast.folderUuid] ?? list.home;
        folder.push({
            podcast,
            uuid: podcast.uuid,
            title: podcast.title,
            dateAdded: podcast.dateAdded,
            lastEpisodePublished: podcast.lastEpisodePublished,
            sortPosition: podcast.sortPosition,
            showUnplayedBadge: podcast.unplayed && badges === PodcastBadges.NEWEST_EPISODE,
        });
    });

    folders.forEach(folder => {
        // Sort the folder's podcasts according to its sort type
        sortPodcastListItems(list[folder.uuid], folder.sortType);

        // Add this folder to the home folder
        list.home.push({
            folder,
            podcastUuids: list[folder.uuid].map(podcast => podcast.uuid),
            uuid: folder.uuid,
            title: folder.name,
            dateAdded: folder.dateAdded,
            // Use the latest published date from all items in this folder
            lastEpisodePublished:
                list[folder.uuid]
                    .map(item => item.lastEpisodePublished)
                    .sort()
                    .pop() || '',
            // Show unplayed if any podcast in the folder is unplayed
            showUnplayedBadge: list[folder.uuid].some(podcast => podcast.showUnplayedBadge),
            sortPosition: folder.sortPosition,
        });
    });

    sortPodcastListItems(list.home, gridOrder);

    return list;
};

export const getPodcastRating = (state: RootState, uuid: string) =>
    state.podcasts.uuidToRating[uuid];

export const getUserPodcastRating = (state: RootState, uuid: string) =>
    state.podcasts.uuidToUserRating[uuid];

export const getSortedEpisodesForPodcast = (state: RootState, uuid: string) => {
    const podcast = getPodcastByUuid(state, uuid);
    if (!podcast) {
        return null;
    }
    if (!podcast.episodes) {
        return null;
    }
    return EpisodeHelper.sort(
        podcast.episodes,
        podcast.episodesSortOrder ?? EpisodeSortOrder.NEWEST_TO_OLDEST,
    );
};

export const getSortedEpisodesWithSyncForPodcast = (
    state: RootState,
    uuid: string,
): EpisodeWithSyncInfo[] | null => {
    const episodes = getSortedEpisodesForPodcast(state, uuid);

    if (!episodes) {
        return episodes;
    }

    return episodes.map(episode => ({
        ...episode,
        ...getEpisodeSyncByUuid(state, episode.uuid),
    }));
};

export const getShowArchivedForPodcast = (state: RootState, uuid: string) => {
    const podcast = getPodcastByUuid(state, uuid);
    const settings = getSettings(state);

    if (!podcast || !podcast.settings) {
        return settings.showArchived;
    }

    if (!podcast.settings.showArchived || podcast.settings.showArchived.changed === false) {
        return settings.showArchived;
    }

    return podcast.settings.showArchived.value;
};

export const getFolders = (state: RootState) => (hasPaidSubscription(state) ? state.folders : []);

export const getFolder = (state: RootState, uuid: string) =>
    getFolders(state).find(folder => folder.uuid === uuid);

export const getFoldersByUuid = (state: RootState) => {
    const result: Record<string, Folder> = {};
    getFolders(state).forEach(folder => {
        result[folder.uuid] = folder;
    });
    return result;
};

export const getFoldersByPodcastUuid = createSelector(
    [getSubscribedPodcasts, getFoldersByUuid],
    (podcasts, foldersByUuid) => {
        const result: Record<string, Folder> = {};
        podcasts.forEach(podcast => {
            result[podcast.uuid] = foldersByUuid[podcast.folderUuid];
        });
        return result;
    },
);

export const getPodcastUuidsByFolderUuid = createSelector(
    [getFolders, getSubscribedPodcasts],
    (folders, podcasts) => {
        const result: Record<string, string[]> = {};
        // Create empty arrays for each folder, in case it has no podcasts
        folders.forEach(folder => {
            result[folder.uuid] = [];
        });
        // Place each podcast in its folder
        podcasts.forEach(podcast => {
            if (podcast.folderUuid) {
                result[podcast.folderUuid] = result[podcast.folderUuid] || [];
                result[podcast.folderUuid].push(podcast.uuid);
            }
        });
        return result;
    },
);

export const getPodcastUuidsForFolder = (state: RootState, folderUuid: string) => {
    return getPodcastUuidsByFolderUuid(state)[folderUuid] || [];
};

export const getFolderForPodcast = (state: RootState, podcastUuid: string) =>
    getFoldersByPodcastUuid(state)[podcastUuid];

export const getFolderColorValues = createSelector([getTheme], themeId => {
    const theme = getThemeFromId(themeId);
    return FOLDER_COLORS.map(({ themeToken }) => theme.tokens[themeToken]);
});
