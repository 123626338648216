import { Icon } from 'components/Icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from '../../hooks/react-redux-typed';
import * as fromModalActions from '../../redux/actions/modal.actions';
import * as fromUserActions from '../../redux/actions/user.actions';
import { Wrapper } from './GenericErrorMessage.styled';

const GenericErrorMessage = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(fromModalActions.Actions.hideModal());
        dispatch(fromUserActions.Actions.openSupport());
    };
    return (
        <Wrapper role="alert">
            <Icon id="cancel" />
            <FormattedMessage
                id="unexpected-error"
                values={{
                    contactUsLink: (
                        <button type="button" onClick={handleClick}>
                            <FormattedMessage id="contact-us" />
                        </button>
                    ),
                }}
            />
        </Wrapper>
    );
};

export default GenericErrorMessage;
