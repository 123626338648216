import { createSelector } from 'reselect';
import { RootState } from '..';
import {
    DiscoverExpandedStyle,
    DiscoverSummaryStyle,
    DiscoverType,
    ListData,
} from '../../../model/types';
import { getSettingsRegion } from './settings.selectors';
// TK
export const getDiscoverList = (state: RootState, name: string) => state.discover.lists[name];

export const getRegionCodeToken = (state: RootState) =>
    state.discover.contentSpec && state.discover.contentSpec.region_code_token;

export const getDiscoverContentSpec = (state: RootState) => state.discover.contentSpec;

export const getDiscoverRegion = createSelector(
    [getSettingsRegion, getDiscoverContentSpec],
    (region, contentSpec) => {
        if (contentSpec?.regions) {
            return contentSpec.regions[region];
        }
        return undefined;
    },
);

const getRegionsMap = (state: RootState) =>
    state.discover.contentSpec && state.discover.contentSpec.regions;

export const getRegionsArray = createSelector([getRegionsMap], regionsMap => {
    if (regionsMap) {
        return Object.keys(regionsMap)
            .map(regionCode => {
                return regionsMap[regionCode];
            })
            .sort((a, b) => {
                // Ensure that the regions are sorted automatically by name
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });
    }
    return [];
});

export const getDiscoverLayouts = (state: RootState) =>
    state.discover.contentSpec && state.discover.contentSpec.layout;

export const getDiscoverLayoutById = (state: RootState, id: string) => {
    const layouts = getDiscoverLayouts(state);
    if (layouts) {
        return layouts.find(layout => layout.type === id);
    }
    return null;
};

export const getDiscoverCategory = (state: RootState, id: string) => {
    const categories = getDiscoverLayoutById(state, DiscoverType.CATEGORIES);
    if (categories && Array.isArray(categories.data)) {
        return categories.data.find(category => `${category.id}` === `${id}`);
    }
    return null;
};

export const getCategoryList = (state: RootState, id: string) => state.discover.categories[id];

export const getDiscoverCategoryPageDetails = createSelector(
    [
        (state, categoryId) => getCategoryList(state, categoryId),
        state => getDiscoverLayoutById(state, DiscoverType.CATEGORIES),
        getDiscoverLayouts,
        getSettingsRegion,
    ],
    (categoryList, categoryLayout, discoverContentJsonLayout, region) => {
        return {
            list: categoryList,
            title: categoryList ? categoryList.title : null,
            category: categoryList,
            pageHeaderDescription: {
                summaryType: categoryLayout ? categoryLayout.summary_style : null,
                expandedType: categoryLayout ? categoryLayout.expanded_style : null,
                data: categoryLayout,
            },
            ranked: categoryLayout
                ? categoryLayout.expanded_style === DiscoverExpandedStyle.RANKED_LIST
                : false,
            isCategory: true,
            region,
            discoverContentJsonLayout,
            listId: '',
        };
    },
);

export const getDiscoverListPageDetails = (state: RootState, { listId }: { listId: string }) => {
    let ranked = false;
    const list = state.discover.lists[listId];
    let title = list?.title;
    let pageHeaderDescription: {
        summaryType: DiscoverSummaryStyle;
        expandedType: DiscoverExpandedStyle;
        data?: ListData;
    } = {
        summaryType: DiscoverSummaryStyle.SMALL_LIST,
        expandedType: DiscoverExpandedStyle.PLAIN_LIST,
    };
    let curated = false;
    const layout = state.discover.contentSpec?.layout;
    const contentBlock = layout
        ? layout.find(layoutObj => layoutObj.source.includes(`${listId}.json`))
        : undefined;
    if (contentBlock) {
        if (contentBlock.expanded_style === DiscoverExpandedStyle.RANKED_LIST) {
            ranked = true;
        }
        curated = !!contentBlock.curated;
        title = contentBlock.title;
        pageHeaderDescription = {
            summaryType: contentBlock.summary_style,
            expandedType: contentBlock.expanded_style,
            data: DiscoverSummaryStyle.COLLECTION ? (contentBlock.data as ListData) : undefined,
        };
    }
    return {
        list,
        title,
        pageHeaderDescription,
        listId,
        ranked,
        discoverContentJsonLayout: state.discover.contentSpec?.layout,
        region: getSettingsRegion(state),
        curated,
    };
};
