import styled, { css, keyframes } from 'styled-components';

export const ClearButton = styled.button`
    margin-left: 5px !important;
`;

const flyIn = keyframes`
    from {
        transform: translateY(7px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const ShuffleButton = styled.button<{ $active: boolean }>`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    &:hover svg {
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }

    svg {
        pointer-events: none;
        transition: color 0.3s;
        color: ${props =>
            props.$active
                ? props.theme.tokens['primary-icon-02-selected']
                : props.theme.tokens['primary-icon-02']};
    }
`;

export const ShuffleCircleIcon = styled.div<{ $active: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition:
        opacity 0.3s,
        transform 0.3s;

    ${props =>
        props.$active &&
        css`
            animation: ${flyIn} 0.3s forwards;
        `}

    svg {
        width: 28px;
        height: 28px;
    }
`;
