import {
    DynamicPlayPauseCircle,
    Props as DynamicPlayPauseCircleProps,
} from 'components/DynamicPlayPauseCircle';
import styled, { css } from 'styled-components';
import { MOBILE_WIDTH, VIEWPORT_MINIMUM_WIDTH } from '../../../model/page';

interface Props extends DynamicPlayPauseCircleProps {
    variant?: 'default' | 'upNext';
}

const variants = {
    default: css`
        color: ${props => props.theme.tokens['player-contrast-02']};

        &:disabled {
            color: ${props => props.theme.tokens['secondary-text-02']};
        }

        &:hover {
            color: ${props => props.theme.tokens['primary-interactive-01']};
        }
    `,
    upNext: css`
        color: ${props => props.theme.tokens['player-contrast-02']};

        &:hover {
            color: ${props => props.theme.tokens['primary-interactive-01']};
        }
    `,
};

export const EpisodePlayButton = styled(DynamicPlayPauseCircle)<Props>`
    &:disabled {
        cursor: not-allowed;
    }

    ${props => variants[props.variant ?? 'default']}

    .episode-row & {
        @media (max-width: ${MOBILE_WIDTH}px) {
            grid-column: 2 / 3;
            grid-row: 1 / span 3;
            display: flex;
            align-items: center;
            justify-self: end;
            align-self: center;
        }

        @media (min-width: ${MOBILE_WIDTH}px) and (max-width: ${VIEWPORT_MINIMUM_WIDTH}px) {
            display: flex;
            grid-column: 4 / 5;
            grid-row: 1;
        }
    }
`;
