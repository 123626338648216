import styled from 'styled-components';
import { gridSize, Typography } from 'styles';
import { MOBILE_WIDTH, VIEWPORT_MINIMUM_WIDTH } from '../../model/page';
import { PodcastImage } from '../form/RatePodcastForm/RatePodcastForm.styled';

export const TableWrapper = styled.div`
    padding-bottom: 50px;
    // This is necessary for the div to be full-width inside search results
    // and maybe other places as well.
    grid-column: 1 / -1;
`;

export const Row = styled.div`
    ${Typography.Desktop.H70}
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
    border-bottom: 1px solid ${props => props.theme.legacyScss['theme-line']};
    cursor: pointer;
    align-items: center;
    height: 72px;
`;

export const PreviewRow = styled(Row)`
    padding: 0;
    display: grid;
    grid-template-columns: 52px minmax(320px, 1fr) 108px 72px 198px 28px;
    gap: ${gridSize * 1.5}px;
    align-items: center;
`;

export const PodcastRow = styled(PreviewRow)`
    grid-template-columns: minmax(320px, 1fr) 108px 72px 198px 28px;
`;

export const PodcastEpisodeRow = styled(PreviewRow)`
    grid-template-columns: minmax(320px, 1fr) 108px 72px 198px 28px;

    @media (min-width: ${MOBILE_WIDTH}px) and (max-width: ${VIEWPORT_MINIMUM_WIDTH}px) {
        grid-template-columns: minmax(320px, 1fr) 108px 72px 30px 28px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        grid-template-columns: minmax(0, 1fr) auto;
        grid-template-rows: auto auto;
        align-items: center;
        gap: 4px;
        height: auto;
        padding: 8px 0;
    }
`;

export const TextColumn = styled.div<{ $inactive?: boolean; $align?: 'left' | 'right' }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: ${props => props.$align || 'left'};
    color: ${props =>
        props.$inactive
            ? props.theme.legacyScss['theme-text-disabled']
            : props.theme.legacyScss['theme-text-secondary']};
`;

export const EpisodeThumbnail = styled(PodcastImage)<{ $small?: boolean }>`
    width: ${props => (props.$small ? '46px' : '52px')};
    height: ${props => (props.$small ? '46px' : '52px')};
    flex-shrink: 0;
    margin: 0;
`;

export const NoEpisodesMessage = styled.div`
    margin-top: 24px;
    text-align: center;
    color: ${props => props.theme.legacyScss['theme-text-secondary']};
`;
