import { useLocation } from 'react-router';

const useNavigation = () => {
    const location = useLocation();
    return {
        canGoBack: location.state?.canGoBack ?? false,
        canGoForward: location.state?.canGoForward ?? false,
    };
};

export default useNavigation;
