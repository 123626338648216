import { EpisodeSortOrder } from 'helper/PodcastHelper';
import {
    APILoginResponse,
    Bookmark,
    EpisodeAndPodcastUuidsArray,
    Folder,
    OAuthProvider,
    PaddleProductId,
    PodcastListPodcast,
    PodcastListPositions,
    SSOAuthTokens,
    UnsavedFolder,
    UpNextEpisode,
    globalSettingsToMigrate,
} from 'model/types';
import { PodcastGridOrder } from 'pages/PodcastsPage/model';
import { v4 as uuidv4 } from 'uuid';
import { PlayingStatus } from '../helper/PlayingStatus';
import { signOutIfHttpResponseIs401402403 } from '../helper/SignOutHelper';
import * as Settings from '../settings';
import { getAccessToken, setTokenData } from './auth';

enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

type SyncAPIFolder = {
    folderUuid: string;
    name: string;
    color: number;
    dateAdded: string;
    podcastsList?: string[];
    podcastsSortType: PodcastGridOrder;
    sortPosition: number;
};

type APIExportFeedResponse = {
    message: null;
    result: Record<string, string>;
    status: 'ok' | 'error';
};

type APIImportPodcastResponse = {
    result: string[];
    status: 'ok' | 'error';
};

const convertFolderFromAPI = ({
    folderUuid,
    name,
    color,
    dateAdded,
    podcastsSortType,
    sortPosition,
}: SyncAPIFolder): Folder => ({
    uuid: folderUuid,
    name,
    color,
    dateAdded,
    sortType: podcastsSortType,
    sortPosition,
});

const convertFolderToAPI = ({
    uuid,
    name,
    color,
    dateAdded,
    sortType,
    sortPosition,
}: Folder): SyncAPIFolder => ({
    folderUuid: uuid,
    name,
    color,
    dateAdded,
    podcastsSortType: sortType,
    sortPosition,
});

export function fetchWithRetry({
    request,
    retries = 3,
    retryDelay = 2000,
    initialDelay = 0,
}: {
    request: () => Promise<Response>;
    retries?: number;
    retryDelay?: number;
    initialDelay?: number;
}): Promise<Response> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const fetchRetry = async (retriesLeft: number) => {
                try {
                    const res = await request();
                    resolve(res);
                } catch (error) {
                    if (retriesLeft === 0) {
                        reject(error);
                    } else {
                        setTimeout(() => {
                            fetchRetry(retriesLeft - 1);
                        }, retryDelay);
                    }
                }
            };
            return fetchRetry(retries);
        }, initialDelay);
    });
}

function convertUpNextResponse(response: { episodes: UpNextEpisode[] }) {
    const episodes = {} as Record<string, UpNextEpisode>;
    const responseEpisodes = response.episodes || [];

    for (const episode of responseEpisodes) {
        episodes[episode.uuid] = episode;
        episodes[episode.uuid].podcastUuid = episode.podcast;
    }

    return {
        ...response,
        order: responseEpisodes.map(episode => episode.uuid),
        episodes,
    };
}

export const api = {
    createUserWithEmailAndPassword(email: string, password: string) {
        return fetch(`${Settings.API_URL}/user/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                password,
                scope: 'webplayer',
            }),
        })
            .catch(ex => {
                console.error('Register exception ', ex);
                throw new Error('Sorry, sign up failed. Please try again later.');
            })
            .then(response => {
                if (response.status !== 200 && response.status !== 400) {
                    throw new Error('Sorry, sign up failed. Please try again later.');
                }

                return response.json().then(json => {
                    if (json.token) {
                        setTokenData({ accessToken: json.token });
                    }

                    return json;
                });
            });
    },

    signInWithEmailAndPassword(email: string, password: string) {
        return fetch(`${Settings.API_URL}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                password,
                scope: 'webplayer',
            }),
        })
            .catch(ex => {
                throw new Error(ex);
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                }

                return response.json();
            })
            .then(json => {
                const { token, uuid, email } = json;
                setTokenData({ accessToken: token });
                return { token, uuid, email };
            });
    },

    signInWithPocketCasts(email: string, password: string) {
        return fetch(`${Settings.API_URL}/user/login_pocket_casts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, scope: 'webplayer' }),
        })
            .catch(ex => {
                throw new Error(ex);
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                }

                return response.json() as Promise<APILoginResponse>;
            })
            .then(json => {
                const { accessToken, expiresIn, refreshToken, tokenType } = json;
                setTokenData({ accessToken, expiresIn, refreshToken, tokenType });
                return json;
            });
    },

    signInWithOAuth(provider: OAuthProvider, idToken: string) {
        return fetch(`${Settings.API_URL}/user/login_${provider}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idToken, scope: 'webplayer' }),
        })
            .catch(ex => {
                throw new Error(ex);
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                }

                return response.json() as Promise<APILoginResponse>;
            })
            .then(json => {
                const { accessToken, expiresIn, refreshToken, tokenType } = json;
                setTokenData({ accessToken, expiresIn, refreshToken, tokenType });
                return json;
            });
    },

    refreshAuthTokens(refreshToken: string) {
        return fetch(`${Settings.API_URL}/user/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ grantType: 'refresh_token', refreshToken }),
        })
            .catch(ex => {
                throw new Error(ex);
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                }

                return response.json() as Promise<SSOAuthTokens>;
            })
            .then(json => {
                const { accessToken, expiresIn, refreshToken, tokenType } = json;
                setTokenData({ accessToken, expiresIn, refreshToken, tokenType });
                return json;
            });
    },

    sendPasswordResetEmail(email: string) {
        return fetch(`${Settings.API_URL}/user/forgot_password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
            }),
        })
            .catch(ex => {
                console.error('Password reset exception ', ex);
                throw new Error('Sorry, password reset failed. Please try again later.');
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Sorry, login failed. Please try again later.');
                }
                return response.json();
            });
    },

    fetchWithAuthentication: async (
        method: HTTP_METHOD,
        path: string,
        data?: Record<string, undefined>,
    ) => {
        return fetch(`${Settings.API_URL}${path}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessToken()}`,
            },
            body: data ? JSON.stringify(data) : undefined,
        })
            .then(signOutIfHttpResponseIs401402403)
            .then(response => {
                if (!response.ok) {
                    return Promise.reject(Error(`${response.status}`));
                }
                return response;
            })
            .then(response => response.text())
            .then(responseText => {
                // If the response is blank, return null...
                if (responseText === '') {
                    return null;
                }
                // ... otherwise parse it as JSON and return that.
                return JSON.parse(responseText);
            });
    },

    postWithAuthentication(path: string, data = {}) {
        return api.fetchWithAuthentication(HTTP_METHOD.POST, path, data);
    },

    putWithAuthentication(path: string, data = {}) {
        return api.fetchWithAuthentication(HTTP_METHOD.PUT, path, data);
    },

    deleteWithAuthentication(path: string, data = {}) {
        return api.fetchWithAuthentication(HTTP_METHOD.DELETE, path, data);
    },

    getWithAuthentication: async (path: string) =>
        api.fetchWithAuthentication(HTTP_METHOD.GET, path),

    /**
     * Get the user's subscription information
     */
    getSubscriptionInformation() {
        return api.getWithAuthentication('/subscription/status');
    },

    /**
     * Purchase the Web Player
     */
    purchaseWebPlayer({
        transactionId,
        email,
        productId,
        subscriptionId,
    }: {
        transactionId: string;
        email: string;
        productId: PaddleProductId;
        subscriptionId: number;
    }) {
        return fetchWithRetry({
            request: () =>
                api.postWithAuthentication('/subscription/purchase/web', {
                    transactionId,
                    email,
                    productId: Number(productId),
                    subscriptionId,
                }),
            initialDelay: 3000,
        });
    },

    async validateGuestPass(guestPass: string) {
        const queryStringParameters = new URLSearchParams({
            code: guestPass,
            platform: 'web',
        }).toString();

        const endpointWithQueryString = `/referrals/validate?${queryStringParameters}`;

        try {
            const response = await api.getWithAuthentication(endpointWithQueryString);
            return { url: response.details, error: false };
        } catch {
            return { error: true };
        }
    },

    async generateReferralCode() {
        return api.getWithAuthentication('/referrals/code');
    },

    /**
     * Subscribe to podcast
     * https://github.com/shiftyjelly/pocketcasts-api#subscribe-to-podcast
     */
    subscribeToPodcast(uuid: string) {
        return api.postWithAuthentication('/user/podcast/subscribe', { uuid });
    },

    unsubscribeFromPodcast(uuid: string) {
        return api.postWithAuthentication('/user/podcast/unsubscribe', { uuid });
    },

    /**
     * List of podcasts & folders the user is subscribed to.
     * https://github.com/shiftyjelly/pocketcasts-api#get-podcasts
     */
    fetchPodcasts(): Promise<{ podcasts: PodcastListPodcast[]; folders: Folder[] }> {
        return api
            .postWithAuthentication('/user/podcast/list', { v: 1 })
            .then(({ podcasts, folders }) => ({
                podcasts,
                folders: folders?.map(convertFolderFromAPI),
            }));
    },

    /**
     * Returns all the sync information for the podcast, including episodes with bookmark data.
     * https://github.com/shiftyjelly/pocketcasts-api#get-episodes
     */
    fetchUserPodcastEpisodes(uuid: string) {
        return api
            .postWithAuthentication('/user/podcast/episodes/bookmarks', { uuid })
            .then(json => ({
                autoStartFrom: json.autoStartFrom,
                episodesSortOrder: json.episodesSortOrder ?? EpisodeSortOrder.NEWEST_TO_OLDEST,
                episodes: json.episodes,
                autoSkipLast: json.autoSkipLast,
            }));
    },

    /**
     * Returns all the sync information for the episode.
     * https://github.com/shiftyjelly/pocketcasts-api/tree/develop/api#get-episode
     */
    fetchUserEpisode(uuid: string) {
        return api.postWithAuthentication('/user/episode', { uuid });
    },

    /**
     * Search for podcasts
     */
    fetchSearchResults(term: string) {
        return api.postWithAuthentication('/discover/search', { term });
    },

    /**
     * Get the user's bookmarks
     */
    fetchBookmarks() {
        return api.postWithAuthentication('/user/bookmark/list');
    },

    /**
     * Up Next list
     * https://github.com/shiftyjelly/pocketcasts-api#up-next-list
     */
    fetchUpNextList(serverModified: number) {
        return api
            .postWithAuthentication('/up_next/list', {
                version: Settings.API_VERSION,
                model: Settings.API_MODEL,
                serverModified: serverModified || 0,
                showPlayStatus: true,
            })
            .then(json => convertUpNextResponse(json));
    },

    /**
     * Get New Releases, In Progress and Starred
     * https://github.com/shiftyjelly/pocketcasts-api#up-next-list
     */
    fetchFilter(filterPath: string) {
        return api.postWithAuthentication(filterPath, {}).then(json => json.episodes || []);
    },

    addFeedByUrl(url: string, pollUuid: string | null) {
        return fetch(`${Settings.PODCAST_URL}/author/add_feed_url`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                url,
                poll_uuid: pollUuid,
                public_option: 'no',
            }),
        })
            .catch(ex => {
                throw new Error(`Unable to add feed url: ${ex.message}.`);
            })
            .then(signOutIfHttpResponseIs401402403)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error(`Unable to add feed url ${url} (${response.status}).`);
            });
    },

    saveDuration(episodeUuid: string, podcastUuid: string, duration: number) {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            duration,
        };
        return api.postWithAuthentication('/sync/update_episode', data);
    },

    /**
     * Save progress
     * https://github.com/shiftyjelly/pocketcasts-api#save-progress
     */
    saveProgress(episodeUuid: string, podcastUuid: string, playedUpTo: number | string) {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            status: PlayingStatus.IN_PROGRESS,
            position: Math.floor(+playedUpTo).toString(),
        };
        return api.postWithAuthentication('/sync/update_episode', data);
    },

    savePlayingStatus(episodeUuid: string, podcastUuid: string, playingStatus: number) {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            status: playingStatus,
        };
        return api.postWithAuthentication('/sync/update_episode', data);
    },

    saveArchive(episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray, isArchived: boolean) {
        const data = {
            episodes: episodeUuidAndPodcastUuids,
            archive: isArchived,
        };
        return api.postWithAuthentication('/sync/update_episodes_archive', data);
    },

    savePlayingStatusAndProgress(
        episodeUuid: string,
        podcastUuid: string,
        playingStatus: number,
        playedUpTo: number,
    ) {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            status: playingStatus,
            position: Math.floor(+playedUpTo).toString(),
        };
        return api.postWithAuthentication('/sync/update_episode', data);
    },

    /**
     * Add stats independently of episode progress
     */
    addStats(
        timeSilenceRemoval: number,
        timeSkipping: number,
        timeIntroSkipping: number,
        timeVariableSpeed: number,
        timeListened: number,
    ) {
        const data = {
            deviceId: 'web',
            deviceType: 0,
            timeSilenceRemoval,
            timeSkipping,
            timeIntroSkipping,
            timeVariableSpeed,
            timeListened,
        };
        return api.postWithAuthentication('/user/stats/add', data);
    },

    /**
     * Star or unstar an episode
     * https://github.com/shiftyjelly/pocketcasts-api#star-episode
     */
    saveEpisodeStar(episodeUuid: string, podcastUuid: string, star: boolean) {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            star,
        };
        return api.postWithAuthentication('/sync/update_episode_star', data);
    },

    saveDeselectedChapters: async (
        podcastUuid: string,
        episodeUuid: string,
        deselectedChapters: number[],
    ) => {
        const data = {
            uuid: episodeUuid,
            podcast: podcastUuid,
            deselectedChapters: deselectedChapters.join(','),
        };
        return api.postWithAuthentication('/sync/update_episode_deselect_chapters', data);
    },

    sendSupportFeedback(email: string, subject: string, message: string, debug: string) {
        const data = { email, subject, message, debug };
        return api.postWithAuthentication('/support/feedback', data);
    },

    /**
     * Download settings
     * https://github.com/shiftyjelly/pocketcasts-api/tree/develop/api#named-settings
     */
    fetchSettings() {
        return api
            .postWithAuthentication('/user/named_settings/update', { m: 'web' })
            .then(settings => api.cleanSettings(settings));
    },

    saveSettings(settings: Record<string, unknown>) {
        // https://pocketcastsp2.wordpress.com/2024/03/07/changing-the-scope-of-named-settings
        globalSettingsToMigrate.forEach(setting => {
            if (settings[setting] !== undefined) {
                settings[`${setting}Global`] = settings[setting];
                delete settings[setting];
            }
        });
        return api
            .postWithAuthentication('/user/named_settings/update', {
                m: 'web',
                settings,
            })
            .then(settings => api.cleanSettings(settings));
    },

    cleanSettings(settings: Record<string, { value: unknown; changed: boolean }>) {
        const cleanSettings = {
            changed: {},
        } as Record<string, unknown> & { changed: Record<string, boolean> };
        Object.keys(settings).forEach(setting => {
            cleanSettings[setting] = settings[setting].value;
            cleanSettings.changed[setting] = settings[setting].changed;
        });
        return cleanSettings;
    },

    /**
     * Generate share link
     * https://github.com/shiftyjelly/pocketcasts-api#create-share-link
     */
    fetchShareLink(episodeUuid: string | undefined, podcastUuid: string) {
        const data = {
            episode: episodeUuid,
            podcast: podcastUuid,
        };
        return api.postWithAuthentication('/podcasts/share_link', data).then(json => json.url);
    },

    /**
     * Get full sync data for the episode, including bookmarks
     */
    getEpisodeSyncData({ uuid, podcast }: { uuid: string; podcast: string }) {
        return api
            .postWithAuthentication('/user/podcast/episode/bookmarks', {
                uuid,
                podcast,
            })
            .then(syncData => {
                // If episode doesn't have sync data, the uuid to will be blank, so always set it
                if (!syncData || syncData.uuid === '') {
                    return {};
                }
                if (syncData?.duration === 0) {
                    delete syncData.duration;
                }
                return syncData;
            })
            .catch(() => {
                return {};
            });
    },

    fetchEpisodeRecommendations() {
        return api
            .postWithAuthentication('/discover/recommend_episodes')
            .then(json => json.episodes || []);
    },

    historyDo(action: number, episode: UpNextEpisode | undefined) {
        const data = { action } as {
            action: number;
            episode: string;
            podcast?: string;
            title: string;
            url: string;
            published?: string;
        };
        if (episode) {
            // clear action does not have an episode.
            data.podcast = episode.podcastUuid;
            data.episode = episode.uuid;
            data.title = episode.title;
            data.url = episode.url;
            data.published = episode.published;
        }
        return api.postWithAuthentication('/history/do', data);
    },

    upNextPlay(urlPath: string, episode: UpNextEpisode) {
        const data = {
            version: Settings.API_VERSION,
            episode: {
                uuid: episode.uuid,
                title: episode.title,
                url: episode.url,
                podcast: episode.podcastUuid ?? episode.podcast,
                published: episode.published,
            },
        };
        return api.postWithAuthentication(urlPath, data).then(json => convertUpNextResponse(json));
    },

    upNextPlayNow(episode: UpNextEpisode) {
        return api.upNextPlay('/up_next/play_now', episode);
    },

    upNextPlayNext(episode: UpNextEpisode) {
        return api.upNextPlay('/up_next/play_next', episode);
    },

    upNextPlayLast(episode: UpNextEpisode) {
        return api.upNextPlay('/up_next/play_last', episode);
    },

    upNextRemove(episodeUuids: string[]) {
        return api
            .postWithAuthentication('/up_next/remove', {
                version: Settings.API_VERSION,
                uuids: episodeUuids,
            })
            .then(json => convertUpNextResponse(json));
    },

    upNextRearrange(order: string[], episodes: Record<string, UpNextEpisode>) {
        const now = new Date().getTime();

        const changeEpisodes: UpNextEpisode[] = order
            .filter(uuid => !!episodes[uuid])
            .map(uuid => {
                const episode = episodes[uuid];
                return {
                    uuid,
                    title: episode.title,
                    url: episode.url,
                    podcast: episode.podcastUuid ?? episode.podcast,
                    published: episode.published,
                };
            });

        const data = {
            version: Settings.API_VERSION,
            upNext: {
                changes: [
                    {
                        action: 5,
                        modified: now,
                        episodes: changeEpisodes,
                    },
                ],
            },
        };
        return api
            .postWithAuthentication('/up_next/sync', data)
            .then(json => convertUpNextResponse(json));
    },

    updatePodcast({
        podcastUuid,
        episodesSortOrder,
        autoStartFrom,
        autoSkipLast,
        playbackEffects,
        playbackSpeed,
        showArchived,
        autoArchive,
        autoArchivePlayed,
    }: {
        podcastUuid: string;
        episodesSortOrder?: number;
        autoStartFrom?: number;
        autoSkipLast?: number;
        playbackEffects?: boolean;
        playbackSpeed?: number;
        showArchived?: boolean;
        autoArchive?: boolean;
        autoArchivePlayed?: string;
    }) {
        const data = {
            uuid: podcastUuid,
        } as Record<string, unknown>;

        // 0 is a valid sort order
        if (episodesSortOrder !== null && episodesSortOrder !== undefined) {
            data.episodesSortOrder = episodesSortOrder;
        }

        if (autoStartFrom != null) {
            data.autoStartFrom = autoStartFrom;
        }

        if (autoSkipLast != null) {
            data.autoSkipLast = autoSkipLast;
        }

        if (playbackEffects != null) {
            data.playbackEffects = playbackEffects;
        }

        if (playbackSpeed != null) {
            data.playbackSpeed = playbackSpeed;
        }

        if (showArchived != null) {
            data.showArchived = showArchived;
        }

        if (autoArchive != null) {
            data.autoArchive = autoArchive;
        }

        if (autoArchivePlayed != null) {
            data.autoArchivePlayed = autoArchivePlayed;
        }

        return api.postWithAuthentication('/user/podcast/update', data);
    },

    fetchStats() {
        const timeToInt = (name: string, json: Record<string, string | number>) => {
            if (json[name]) {
                json[name] = +Number(json[name]).toFixed();
            }
        };
        return api.postWithAuthentication('/user/stats/summary').then(json => {
            timeToInt('timeSilenceRemoval', json);
            timeToInt('timeSkipping', json);
            timeToInt('timeIntroSkipping', json);
            timeToInt('timeVariableSpeed', json);
            timeToInt('timeListened', json);
            return json;
        });
    },

    refreshEpisodeList(podcastUuid: string, lastEpisodeUuid: string, location: string | undefined) {
        const url =
            location ??
            `${Settings.PODCAST_URL}/api/v1/update_podcast/?podcast_uuid=${encodeURIComponent(podcastUuid)}&last_episode_uuid=${encodeURIComponent(lastEpisodeUuid)}&_=${Date.now()}`;
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            cache: 'no-store',
        })
            .catch(ex => {
                throw new Error(`Unable to refresh podcast: ${ex.message}.`);
            })
            .then(signOutIfHttpResponseIs401402403)
            .then(response => {
                return {
                    status: response.status,
                    retryAfter: response.headers.get('Retry-After'),
                    location: response.headers.get('Location'),
                };
            });
    },

    changeEmail(email: string, password: string) {
        const data = {
            email,
            password,
            scope: 'webplayer',
        };

        return api.postWithAuthentication('/user/change_email', data);
    },

    changePassword(oldPassword: string, newPassword: string) {
        const data = {
            old_password: oldPassword,
            new_password: newPassword,
            scope: 'webplayer',
        };

        return api.postWithAuthentication('/user/change_password', data);
    },

    resetPassword(password: string, resetPasswordToken: string) {
        const data = {
            reset_password_token: resetPasswordToken,
            password,
            scope: 'webplayer',
        };

        return fetch(`${Settings.API_URL}/user/reset_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => Promise.all([response.json(), Promise.resolve(response.status)]))
            .then(datas => {
                if (datas[1] !== 200) {
                    return Promise.reject(Error(`${datas[1]} ${datas[0].message}`.trim()));
                }

                return datas[0];
            });
    },

    validatePromoCode(code: string) {
        return fetch(`${Settings.API_URL}/subscription/promo/validate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
            }),
        }).then(response => response.json().then(data => ({ status: response.status, data })));
    },

    redeemPromoCode: async (code: string) => {
        return fetch(`${Settings.API_URL}/subscription/promo/redeem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessToken()}`,
            },
            body: JSON.stringify({
                code,
            }),
        }).then(response => response.json().then(data => ({ status: response.status, data })));
    },

    createFolder(folder: UnsavedFolder, podcasts: string[]) {
        // Add a uuid and dateAdded before saving
        const newFolder = { ...folder, uuid: uuidv4(), dateAdded: new Date().toISOString() };

        return (
            api
                .postWithAuthentication('/user/folders', {
                    version: Settings.API_VERSION,
                    model: Settings.API_MODEL,
                    folder: convertFolderToAPI(newFolder),
                    podcasts,
                })
                // The API response is empty, but we'll send back the created folder including its new UUID
                .then(() => newFolder)
        );
    },

    updateFolder(folder: Folder, podcasts: string[]) {
        return (
            api
                .putWithAuthentication(`/user/folder/${folder.uuid}`, {
                    version: Settings.API_VERSION,
                    model: Settings.API_MODEL,
                    folder: convertFolderToAPI(folder),
                    podcasts,
                })
                // The API response is empty, but we'll send back the updated folder for confirmation
                .then(() => folder)
        );
    },

    deleteFolder(folderUuid: string) {
        return api.deleteWithAuthentication(`/user/folder/${folderUuid}`, {
            version: Settings.API_VERSION,
            model: Settings.API_MODEL,
        });
    },

    updatePodcastListPositions(positions: PodcastListPositions) {
        return api.postWithAuthentication(`/user/sort`, {
            version: Settings.API_VERSION,
            model: Settings.API_MODEL,
            podcasts: Object.keys(positions.podcasts).map(uuid => ({
                uuid,
                position: positions.podcasts[uuid],
            })),
            folders: Object.keys(positions.folders).map(uuid => ({
                uuid,
                position: positions.folders[uuid],
            })),
        });
    },

    addBookmark(
        bookmark: Pick<Bookmark, 'episodeUuid' | 'podcastUuid' | 'time' | 'title'>,
    ): Promise<Bookmark> {
        return api.postWithAuthentication('/user/bookmark/add', bookmark);
    },

    deleteBookmark(bookmarkUuid: string): Promise<void> {
        return api.postWithAuthentication('/user/bookmark/delete', { bookmarks: [bookmarkUuid] });
    },

    editBookmark({ bookmarkUuid, title }: Bookmark): Promise<Bookmark> {
        return api.putWithAuthentication(`/user/bookmark/${bookmarkUuid}`, { title });
    },

    getPodcastFeed(uuids: string[]) {
        return fetch(
            `${Settings.PODCAST_URL}/import/export_feed_urls
        `,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uuids: uuids.join(','),
                }),
            },
        ).then(async response => {
            if (response.status !== 200) {
                throw new Error(`${response.status}`);
            }
            const apiResponse: APIExportFeedResponse = await response.json();
            return apiResponse.result;
        });
    },

    importOPML(opmlContent: string) {
        // Malformed XML can cause the request to hang, so abort after 10 seconds
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), 10 * 1000);

        return fetch(
            `${Settings.PODCAST_URL}/import/opml
        `,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    opml_file: opmlContent,
                }),
                signal: controller.signal,
            },
        )
            .then(async response => {
                if (response.status !== 200) {
                    throw new Error(`${response.status}`);
                }
                const apiResponse: APIImportPodcastResponse = await response.json();
                return apiResponse.result;
            })
            .finally(() => clearTimeout(id));
    },

    deleteAccount() {
        return api.postWithAuthentication('/user/delete_account');
    },

    ratePodcast(podcastUuid: string, podcastRating: number) {
        return api.postWithAuthentication('/user/podcast_rating/add', {
            podcastUuid,
            podcastRating,
        });
    },

    fetchUserPodcastRating(podcastUuid: string) {
        return api.postWithAuthentication('/user/podcast_rating/show', { podcastUuid });
    },
};
