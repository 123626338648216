import { Button } from 'components/Button';
import Input from 'components/Input';
import useFormatMessage from 'hooks/useFormatMessage';
import { Bookmark } from 'model/types';
import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { gridSize } from 'styles';
import { useDispatch } from '../../../../hooks/react-redux-typed';
import useTracks from '../../../../hooks/useTracks';
import { editBookmark } from '../../../../redux/actions/episode-sync.actions';
import { TrackOnMount, TrackOnUnmount } from '../../../Tracks';

export type Props = {
    bookmark: Bookmark;
    onSubmit?: () => void;
    eventSource: string;
};

const Form = styled.form`
    button {
        width: 100%;
        margin-top: ${gridSize}px;
    }
`;

const EditBookmarkForm = ({ bookmark, onSubmit, eventSource }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const [title, setTitle] = React.useState(bookmark.title);
    const { recordEvent } = useTracks();

    const handleTitleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setTitle(e.target.value);
    };

    const handleSubmit = () => {
        recordEvent('bookmark_edit_form_submitted', { source: eventSource });
        dispatch(editBookmark({ ...bookmark, title }));
        onSubmit?.();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <TrackOnMount event="bookmark_edit_form_shown" properties={{ source: eventSource }} />
            <TrackOnUnmount
                event="bookmark_edit_form_dismissed"
                properties={{ source: eventSource }}
            />
            <Input type="text" value={title} onChange={handleTitleChange} />
            <Button kind="primary" type="submit">
                {formatMessage('change-title')}
            </Button>
        </Form>
    );
};

export default EditBookmarkForm;
