import React, { useEffect } from 'react';
import { FocusOn } from 'react-focus-on';
import { Portal } from 'react-portal';
import { onKeyDownEnter } from '../../../../helper/UiHelper';
import { usePopupContextMenu } from '../../../../hooks/usePopupContextMenu';
import { PopupBackgroundBase, PopupBase } from '../../../../styles/popups';
import { MenuPopupWindow, MenuTitle, PopupChoiceItem } from './PopupMenuContextMenu.styled';

type Props = {
    onClosed: (e: Event | React.SyntheticEvent) => void;
    onValueChanged?: (value: unknown) => void;
    anchorEl: Element;
    selectedValue?: unknown;
    ariaAttributes: Record<string, string>;
    positionRelativeToWindow?: boolean;
    children: React.ReactNode;
    color?: string;
    horizontalPosition?: 'left' | 'right' | 'center';
    itemClassName?: string;
    popupClassName?: string;
    offsets?: Record<string, number>;
    menuTitle?: string;
    maxHeight?: number;
};

function PopupMenuContextMenu({
    onClosed,
    anchorEl,
    ariaAttributes,
    children,
    color,
    positionRelativeToWindow,
    horizontalPosition = 'left',
    offsets: offsetsProp,
    menuTitle,
    maxHeight,
    popupClassName,
    itemClassName,
}: Props) {
    const { popupWindowRef, frameRef, offsets, menuItemRefs } = usePopupContextMenu({
        onClosed,
        anchorEl,
        offsets: {
            top: -30,
            ...offsetsProp,
        },
        horizontalPosition,
        size: Array.isArray(children) ? children.filter(truthy => truthy).length : 1,
        positionRelativeToWindow,
    });

    const onScroll = (e: MouseEvent) => e.stopPropagation();

    useEffect(() => {
        if (maxHeight) {
            popupWindowRef.current.addEventListener('wheel', onScroll);
        }

        return () => {
            if (maxHeight && popupWindowRef.current) {
                popupWindowRef.current.removeEventListener('wheel', onScroll);
            }
        };
    }, []);

    return (
        <Portal node={document && document.getElementById('modal-root')}>
            <PopupBase>
                <FocusOn ref={frameRef} scrollLock={false} noIsolation>
                    <MenuPopupWindow
                        ref={popupWindowRef as React.MutableRefObject<HTMLDivElement>}
                        aria-labelledby={ariaAttributes['aria-labelledby']}
                        $offsets={offsets}
                        style={
                            maxHeight
                                ? {
                                      maxHeight: `${maxHeight}px`,
                                      overflowY: 'auto',
                                  }
                                : {}
                        }
                        className={popupClassName}
                    >
                        {menuTitle && <MenuTitle>{menuTitle}</MenuTitle>}
                        {(Array.isArray(children) ? children : [children])
                            .filter(child => child)
                            .map((child, index) => {
                                const onClick = (e: React.SyntheticEvent | Event) => {
                                    e.preventDefault();
                                    child.props.onClick(e);
                                };

                                const isTextItem = !child.type.name?.includes('ImageTextSwitch');

                                const onKeyDown = (e: React.KeyboardEvent) => {
                                    e.preventDefault();
                                    if (e.key === 'Enter') {
                                        child.props.onClick(e);
                                        if (isTextItem) {
                                            onClosed(e);
                                        }
                                    }
                                };

                                return (
                                    <PopupChoiceItem
                                        $itemIndex={index}
                                        key={`menuitem-${index}`}
                                        onKeyDown={onKeyDown}
                                        ref={
                                            menuItemRefs[
                                                index
                                            ] as React.MutableRefObject<HTMLDivElement>
                                        }
                                        role={isTextItem ? 'menuitem' : 'menuitemcheckbox'}
                                        aria-checked={!isTextItem ? null : child.props.on}
                                        className={itemClassName}
                                    >
                                        {React.cloneElement(child, {
                                            color: child.props.color || color,
                                            onClose: onClosed,
                                            tabIndex: -1,
                                            onClick,
                                        })}
                                    </PopupChoiceItem>
                                );
                            })}
                    </MenuPopupWindow>
                    <PopupBackgroundBase
                        onKeyPress={event => onKeyDownEnter(event, onClosed)}
                        onClick={onClosed || null}
                    />
                </FocusOn>
            </PopupBase>
        </Portal>
    );
}

export default PopupMenuContextMenu;
