import { useEffect, useState } from 'react';
import * as fromUserActions from '../redux/actions/user.actions';
import {
    getIsSubscribedPodcastsLoaded,
    getIsSubscribedPodcastsLoading,
    getSubscribedPodcastUuids,
} from '../redux/reducers/selectors';
import { userIsLoggedIn } from '../redux/reducers/selectors/user.selectors';
import { useDispatch, useSelector } from './react-redux-typed';

/**
 * This hook gathers all the data needed to decide if the user is subscribed to any podcasts.
 * It returns a boolean value that indicates whether the user is subscribed to any podcasts.
 *
 * If the user's podcast data has not been loaded yet, it will return undefined
 *
 */
const useIsSubscribedToPodcasts = () => {
    const dispatch = useDispatch();
    const isSubscribedPodcastsLoaded = useSelector(getIsSubscribedPodcastsLoaded);
    const isSubscribedPodcastsLoading = useSelector(getIsSubscribedPodcastsLoading);
    const subscribedPodcastUuids = useSelector(getSubscribedPodcastUuids);
    const isLoggedIn = useSelector(userIsLoggedIn);

    const [isSubscribedToPodcasts, setIsSubscribedToPodcasts] = useState<boolean | undefined>();

    // If Podcast data has not been loaded and steps have not been chosen, refresh all the user's info
    useEffect(() => {
        if (!isSubscribedPodcastsLoading && !isSubscribedPodcastsLoaded && isLoggedIn) {
            dispatch(fromUserActions.Actions.userReturned());
        }
    }, [dispatch, isLoggedIn, isSubscribedPodcastsLoaded, isSubscribedPodcastsLoading]);

    // If the user podcasts have loaded, set the state
    useEffect(() => {
        if (isSubscribedPodcastsLoaded) {
            setIsSubscribedToPodcasts(subscribedPodcastUuids.length > 0);
        }
    }, [isSubscribedPodcastsLoaded, subscribedPodcastUuids.length]);

    return isSubscribedToPodcasts;
};

export default useIsSubscribedToPodcasts;
