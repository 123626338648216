import { EpisodeWithImage } from 'model/types';
import { Action } from '../actions';

type ElectronState = {
    isSyncing: boolean;
    isMiniPlayerOpen: boolean;

    miniPlayer: {
        episode: EpisodeWithImage | null;
        playedUpTo: number;
        isPlaying: boolean;
    };
};

export const INITIAL_STATE: ElectronState = {
    isSyncing: false,
    isMiniPlayerOpen: false,

    miniPlayer: {
        episode: null,
        playedUpTo: 0,
        isPlaying: false,
    },
};

export default (state: ElectronState = INITIAL_STATE, action: Action): ElectronState => {
    switch (action.type) {
        case 'ELECTRON_MINI_PLAYER_OPENED':
            return {
                ...state,
                isMiniPlayerOpen: true,
            };
        case 'ELECTRON_MINI_PLAYER_SEEK_TO': {
            return {
                ...state,
                miniPlayer: {
                    ...state.miniPlayer,
                    playedUpTo: action.payload.playedUpTo,
                },
            };
        }
        case 'ELECTRON_MINI_PLAYER_PLAY': {
            return {
                ...state,
                miniPlayer: {
                    ...state.miniPlayer,
                    isPlaying: true,
                },
            };
        }
        case 'ELECTRON_MINI_PLAYER_PAUSE': {
            return {
                ...state,
                miniPlayer: {
                    ...state.miniPlayer,
                    isPlaying: false,
                },
            };
        }
        case 'ELECTRON_MINI_PLAYER_LOAD_EPISODE':
            return {
                ...state,
                miniPlayer: {
                    ...state.miniPlayer,
                    episode: action.payload.episode,
                    playedUpTo: action.payload.episode.playedUpTo,
                },
            };
        case 'ELECTRON_SYNCING_STATE':
            return {
                ...state,
                isSyncing: true,
            };
        case 'ELECTRON_SYNCING_STATE_DONE':
            return {
                ...state,
                isSyncing: false,
            };
        default:
            return state;
    }
};
