import { TodoFixmeMigrationType, UploadedFile } from 'model/types';
import { sortFilesAlgorithm } from '../../helper/UploadedFilesHelper';
import { Action } from '../actions';
import { ActionTypes, Actions } from '../actions/uploaded-files.actions';

interface UploadedFilesState {
    isLoading: boolean;
    serverError?: string;
    data: TodoFixmeMigrationType;
    suspended: boolean;
    fileToDelete: null | TodoFixmeMigrationType;
    fileToEdit: null | TodoFixmeMigrationType;
}

export const INITIAL_STATE: UploadedFilesState = {
    isLoading: true,
    serverError: undefined,
    data: {},

    suspended: false,
    fileToDelete: null,
    fileToEdit: null,
};

const updateFileInState = (
    state: UploadedFilesState,
    episodeUuid: string,
    changes: Partial<UploadedFile>,
) => {
    const file = state.data.files[episodeUuid];

    if (!file) {
        return state;
    }

    return {
        ...state,
        data: {
            ...state.data,
            files: {
                ...state.data.files,
                [episodeUuid]: { ...file, ...changes },
            },
        },
    };
};

export default (state: UploadedFilesState = INITIAL_STATE, action: Actions | Action) => {
    switch (action.type) {
        case ActionTypes.FILES_FETCH_REQUEST:
            return { ...state, isLoading: true, serverError: undefined };
        case ActionTypes.FILES_FETCH_SUCCESS: {
            const { account, lastModified } = action.payload;
            const filesOrder = action.payload.files.map(file => file.uuid);
            const files = action.payload.files.reduce(
                (result, current) => ({
                    ...result,
                    [current.uuid]: { ...current },
                }),
                {},
            );

            const sortedFilesOrder = sortFilesAlgorithm(filesOrder, files);

            return {
                ...state,
                isLoading: false,
                serverError: undefined,
                data: {
                    filesOrder: sortedFilesOrder,
                    files,
                    account,
                    lastModified,
                },
                suspended: false,
            };
        }
        case ActionTypes.FILES_FETCH_FAILURE: {
            const suspended = action.payload.error && action.payload.error.message === '401';
            return {
                ...state,
                isLoading: false,
                serverError: action.payload.error?.message,
                data: {},
                suspended,
            };
        }
        case ActionTypes.FILE_FETCH_SUCCESS: {
            const {
                uuid,
                title,
                size,
                contentType,
                color,
                imageUrl,
                artworkImageUrl,
                playedUpTo,
                playingStatus,
                duration,
                published,
                lastModified,
            } = action.payload;

            const fileToEditUuid = state.fileToEdit && state.fileToEdit.uuid;
            const fileToEdit =
                fileToEditUuid !== uuid
                    ? state.fileToEdit
                    : {
                          ...state.fileToEdit,
                          title,
                          size,
                          contentType,
                          color,
                          imageUrl,
                          artworkImageUrl,
                          playedUpTo,
                          playingStatus,
                          duration,
                          published,
                          lastModified,
                      };

            return {
                ...state,
                data: {
                    ...state.data,
                    files: {
                        ...state.data.files,
                        [uuid]: {
                            ...state.data.files[uuid],
                            title,
                            size,
                            contentType,
                            color,
                            imageUrl,
                            artworkImageUrl,
                            playedUpTo,
                            playingStatus,
                            duration,
                            published,
                            lastModified,
                        },
                    },
                },
                fileToEdit,
            };
        }
        case ActionTypes.FILE_FETCH_FAILURE: {
            return { ...state, isLoading: false, serverError: action.payload.message };
        }
        case ActionTypes.FILES_USAGE_SUCCESS: {
            const { totalSize, usedSize, totalFiles } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    account: {
                        totalSize,
                        usedSize,
                        totalFiles,
                    },
                },
            };
        }
        case ActionTypes.FILE_DELETE_CONFIRMATION_SHOW:
            return {
                ...state,
                fileToDelete: {
                    uuid: action.payload.uuid,
                    title: action.payload.title,
                },
            };
        case ActionTypes.FILE_DELETE_CONFIRMATION_DISMISS:
            return { ...state, fileToDelete: null };
        case ActionTypes.FILE_LOAD_FOR_EDITING:
            return { ...state, fileToEdit: action.payload.file };
        case ActionTypes.FILE_CLOSE_EDIT_DIALOG:
            return { ...state, fileToEdit: null };
        case ActionTypes.FILES_UPDATE_SUCCESS: {
            const { fileUpdates } = action.payload;

            const newFiles = JSON.parse(JSON.stringify(state.data.files));

            Object.keys(fileUpdates).forEach(uuid => {
                newFiles[uuid] = {
                    ...newFiles[uuid],
                    ...fileUpdates[uuid],
                };
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    filesOrder: state.data.filesOrder,
                    files: newFiles,
                    account: state.data.account,
                },
            };
        }
        case ActionTypes.FILE_DELETE_SUCCESS: {
            const uuidToDelete = action.payload.uuid;

            const remainingFilesOrder = state.data.filesOrder.filter(
                (uuid: string) => uuid !== uuidToDelete,
            );
            const fileSize = state.data.files[uuidToDelete].size;
            const newFiles = JSON.parse(JSON.stringify(state.data.files));

            delete newFiles[uuidToDelete];

            return {
                ...state,
                data: {
                    ...state.data,
                    filesOrder: remainingFilesOrder,
                    files: newFiles,
                    account: {
                        totalSize: state.data.account.totalSize,
                        usedSize: (
                            Number(state.data.account.usedSize) - Number(fileSize)
                        ).toString(),
                        totalFiles: (Number(state.data.account.totalFiles) - 1).toString(),
                    },
                },
            };
        }
        case ActionTypes.FILES_SORT: {
            const newFilesOrder = sortFilesAlgorithm(
                state.data.filesOrder,
                state.data.files,
                action.payload.sortColumn,
                action.payload.sortOrder,
            );

            return {
                ...state,
                data: {
                    ...state.data,
                    filesOrder: newFilesOrder,
                },
            };
        }
        case 'BOOKMARK_ADD_SUCCEEDED': {
            const { episodeUuid } = action.payload.bookmark;
            const file: UploadedFile | undefined = state.data.files[episodeUuid];

            return updateFileInState(state, episodeUuid, {
                bookmarks: file?.bookmarks.concat(action.payload.bookmark),
            });
        }
        case 'BOOKMARK_EDIT_SUCCEEDED': {
            const { episodeUuid } = action.payload.bookmark;
            const file: UploadedFile | undefined = state.data.files[episodeUuid];

            return updateFileInState(state, episodeUuid, {
                bookmarks: file?.bookmarks.map(bookmark =>
                    bookmark.bookmarkUuid === action.payload.bookmark.bookmarkUuid
                        ? { ...bookmark, ...action.payload.bookmark }
                        : bookmark,
                ),
            });
        }
        case 'BOOKMARK_DELETE_SUCCEEDED': {
            const { episodeUuid } = action.payload.bookmark;
            const file: UploadedFile | undefined = state.data.files[episodeUuid];

            return updateFileInState(state, episodeUuid, {
                bookmarks: file?.bookmarks.filter(
                    bookmark => bookmark.bookmarkUuid !== action.payload.bookmark.bookmarkUuid,
                ),
            });
        }
        default:
            return state;
    }
};
