/*
 * This file contains constants related to page constraints, layout and events.
 */
export const CONTENT_DIV_ID = 'content';

export const ESCAPE_KEY_PRESSED_EVENT = 'KEY_ESCAPE';
export const ESCAPE_KEY_CODE = 27;

export const PAGE_MAX_WIDTH = 1555;
export const PAGE_MAX_WIDTH_FOR_SMALL_NAVIGATION = 1200;

export const VIEWPORT_MINIMUM_WIDTH = 960;

export const PLAYER_HEIGHT = 122;

export const MOBILE_WIDTH = 768;
