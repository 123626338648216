import styled, { css } from 'styled-components';
import { Typography, black, white } from '../../../styles';
import { Popup } from '../Popup';

export const CloseButtonWrapper = styled.div`
    button {
        top: 28px;
        right: 32px;
    }
`;

export const Actions = styled.div`
    display: flex;

    button,
    a {
        margin-right: 8px;
    }
`;

export const PrimaryButton = styled.button`
    background: ${props => props.theme.legacyScss['theme-text-link']};
    color: ${props => props.theme.tokens['primary-ui-01']};
    ${Typography.Desktop['H70-B']};
    height: 48px;
    padding: 15px 32px;
    border-radius: 58px;

    &:hover {
        opacity: 0.8;
    }
`;

export const SecondaryButton = styled.button`
    ${Typography.Desktop['H65-B']};
    color: ${props => props.theme.legacyScss['theme-text-link']};
    &:disabled {
        color: ${props => props.theme.tokens['primary-text-02']};
    }
`;

export const ShareInfo = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
`;

export const ShareText = styled.div`
    display: flex;
    flex-direction: column;
    width: 550px;
    margin-left: 24px;
`;

export const ModalTitle = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    ${Typography.Desktop['H80-BC']}
    margin-bottom: 8px;
`;

export const ShareButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

export const TimeOption = styled.div`
    font-size: 14.4px;
    font-weight: 500;
    letter-spacing: -0.17px;
    label {
        color: ${props => props.theme.tokens['primary-text-02']};
        padding-left: 7px;
    }
    display: flex;
    margin-bottom: 12px;
`;

export const SharePopupWrapper = styled(Popup)`
    .popup-window {
        position: relative;
        padding: 32px;

        .header {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: ${props => props.theme.legacyScss['theme-text-secondary']};
        }
        .share-popup-image {
            min-width: 120px;
            height: 120px;
            width: 120px;
        }
        .title {
            ${Typography.Desktop['H20-LN']}
            font-weight: 700;
            max-height: 88px;
            overflow: hidden;
            text-overflow: ellipsis;
            &.long {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            color: ${props => props.theme.legacyScss['theme-text-primary']};
        }
        .desc {
            ${Typography.Desktop.H60}
            font-weight: 400;
            color: ${props => props.theme.legacyScss['theme-text-secondary']};
            margin-top: 8px;
        }
        .link {
            input {
                width: 304px;
                height: 48px;
                border-radius: 4px;
                outline: 0;
                padding: 8px;
                margin-right: 8px;
                color: ${props => props.theme.tokens['primary-text-02']};
                border: 1px solid ${props => props.theme.tokens['primary-field-03']};
                background-color: ${props => props.theme.tokens['primary-field-02']};
            }
        }
        .social {
            width: 168px;
            display: flex;
            .tumblr,
            .facebook,
            .twitter,
            .linkedin,
            .email {
                min-width: 48px;
                height: 48px;
                border-radius: 60px;
                background: ${props => props.theme.legacyScss['theme-text-link']};
                color: ${props => props.theme.tokens['primary-ui-01']};
                fill: ${props => props.theme.tokens['primary-ui-01']};
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center;

                &:hover {
                    opacity: 0.8;
                }
            }

            .linkedin svg {
                margin-left: 8px;
                margin-top: 4px;
            }
        }
    }
    .popup-background {
        background: ${props => props.theme.legacyScss['fixme-popup-background']};
        cursor: pointer;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fade-and-slide-in {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        animation: fade-in 200ms ease;
    }

    .popup-background {
        background: ${props => props.theme.legacyScss['fixme-popup-background']};
        cursor: pointer;

        @media (prefers-reduced-motion: no-preference) {
            animation: fade-and-slide-in 200ms ease;
            animation-fill-mode: backwards;
        }
    }
`;

export const CreateClipOptions = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 24px;
    padding-top: 24px;

    border-top: 1px solid ${props => props.theme.tokens['secondary-ui-02']};
`;

export const WaveFormSvg = styled.svg`
    display: block;
    height: 48px;
`;

export const WaveFormContainer = styled.div`
    height: 48px;
    position: relative;
    user-select: none;
`;

export const TimelineWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const AnimatedPlayButtonWrapper = styled.div`
    margin-left: 12px;
    button {
        width: 48px;
        height: 48px;
        border-radius: 60px;
        background: ${props => props.theme.legacyScss['theme-text-link']};
        color: ${props => props.theme.tokens['primary-ui-01']};
        display: inline-block;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
            opacity: 0.8;
        }
    }

    path {
        fill: ${props => props.theme.tokens['primary-ui-01']};
    }
`;

export const PlayPositionIndicator = styled.div<{ $isClipPlaying: boolean }>`
    position: absolute;
    z-index: 10;
    width: 2px;

    background-color: ${black};
    opacity: 0.5;

    ${props =>
        props.theme.name === 'dark' &&
        css`
            background-color: ${white};
            opacity: 0.8;
        `}

    transition: left 0.5s linear;
    display: ${props => (props.$isClipPlaying ? 'initial' : 'none')};
`;

export const ViewPlayPositionIndicator = styled(PlayPositionIndicator)`
    height: 47px;
    top: 0px;
`;

export const EditPlayPositionIndicator = styled(PlayPositionIndicator)`
    height: 38px;
    top: 5px;
`;
