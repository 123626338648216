import Gravatar from 'components/Gravatar';
import { SubscriberConfetti } from 'components/SubscriberConfetti';
import {
    ExpiresOnDateMessage,
    FirstPaymentOnMessage,
    NextBillingDateMessage,
} from 'components/messages';
import useFormatMessage from 'hooks/useFormatMessage';
import { PaddleStatus, PaymentFrequency, Subscription, SubscriptionPlatform } from 'model/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModernStyle } from 'styles/ModernStyle.styled';
import { SubscribeToPlanButtonLink } from '../../../components/SubscribeToPlanButtonLink';
import useTracks from '../../../hooks/useTracks';
import { MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP, platformsById } from '../../../model/subscription';
import { getSubscriptionTier } from '../../../redux/reducers/selectors';
import urls from '../../../urls';
import { GiftDaysText } from './GiftDaysText';
import {
    BulletWrapper,
    CombinedDateWrapper,
    CombinedSubscriptionWrapper,
    DateWrapper,
    DetailsBox,
    EmailWrapper,
    FirstPaymentDateWrapper,
    FreeWrapper,
    GravatarWrapper,
    LogoWrapper,
    NextBillingDateWrapper,
    NonSubscriberSectionWrapper,
    PictureBox,
    PlanBadge,
    PlatformWrapper,
    PriceWrapper,
    ProfileInformationBox,
    ProfileInformationPlanFeatureList,
    ProfileInformationWrapper,
    UnlockFeaturesWrapper,
} from './ProfileInformation.styled';
import InvertedPlusAccountIcon from './inverted-plus-account-icon.svg';

type Props = {
    subscription: Subscription;
    withinExpiryCountdown: boolean;
    email: string;
};

const PatronLogo = () => (
    <svg width={12} height={12} viewBox="0 0 12 12">
        <path
            d="M11.6498 2.53543C10.8148 0.91543 8.83485 0.28043 7.21485 1.11043C6.91985 1.25543 6.65485 1.45043 6.41985 1.67543L5.98985 2.06543L5.57635 1.68543C4.82635 0.94043 3.76635 0.60493 2.73135 0.77993C1.69635 0.93993 0.806349 1.58993 0.336349 2.51993C-0.298651 3.75993 -0.0536507 5.26993 0.941349 6.24993L5.62785 11.0749C5.81785 11.2699 6.13285 11.2749 6.33285 11.0799C6.33285 11.0749 6.33785 11.0699 6.33785 11.0699L11.0178 6.24993C12.0128 5.26493 12.2628 3.74993 11.6278 2.50693L11.6498 2.53543Z"
            fill="currentColor"
        />
    </svg>
);

const PlusLogo = () => (
    <svg width={14} height={14} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1H10V6H15.5L14.5 10H10V15H6V10H0.5L1.5 6H6V1Z"
            fill="currentColor"
        />
    </svg>
);

export function ProfileInformation({ subscription, withinExpiryCountdown, email }: Props) {
    const hasSubscription = !!subscription;
    const { platform, frequency, autoRenewing, webStatus, expiryDate, giftDays, nextPayment } =
        subscription || {};

    const isPaddleTrial = webStatus === PaddleStatus.trialing;

    const formatMessage = useFormatMessage();
    const [confettiKey, setConfettiKey] = useState<number>();

    const tier = useSelector(getSubscriptionTier);

    const isGift = platform === SubscriptionPlatform.gift;

    const { recordEvent, setDefaultEventSource } = useTracks();

    function getPriceString() {
        if (platform === SubscriptionPlatform.gift) {
            if (withinExpiryCountdown) {
                return formatMessage('please-set-up-your-subscription');
            }

            return formatMessage('thanks-for-your-support');
        }

        if (!autoRenewing) {
            return formatMessage('payment-cancelled');
        }

        if (nextPayment) {
            const priceString = `${nextPayment.currency} ${nextPayment.amount}`;

            if (frequency === PaymentFrequency.monthly) {
                return `${priceString} / ${formatMessage('month')}`;
            }

            if (frequency === PaymentFrequency.annual) {
                return `${priceString} / ${formatMessage('year')}`;
            }
        }

        // If the platform is not Paddle we cannot know the price of the subscription
        if (platform !== SubscriptionPlatform.Paddle) {
            return formatMessage('subscription');
        }

        return '';
    }

    function renderExpiryLine() {
        const date = new Date(expiryDate);

        if (giftDays >= MIN_GIFT_DAYS_FOR_LIFETIME_MEMBERSHIP) {
            return (
                <CombinedDateWrapper>
                    <FreeWrapper>
                        <GiftDaysText days={giftDays} />
                    </FreeWrapper>
                </CombinedDateWrapper>
            );
        }

        if (isPaddleTrial) {
            return (
                <CombinedDateWrapper>
                    <FreeWrapper>
                        <GiftDaysText freeTrial />
                        <BulletWrapper>&nbsp;•&nbsp;</BulletWrapper>
                    </FreeWrapper>
                    <FirstPaymentDateWrapper>
                        <FirstPaymentOnMessage expiryDate={date} />
                    </FirstPaymentDateWrapper>
                </CombinedDateWrapper>
            );
        }

        if (autoRenewing) {
            return (
                <CombinedDateWrapper>
                    <NextBillingDateWrapper>
                        <NextBillingDateMessage billingDate={date} />
                    </NextBillingDateWrapper>
                </CombinedDateWrapper>
            );
        }

        return (
            <CombinedDateWrapper>
                {isGift && (
                    <FreeWrapper>
                        <GiftDaysText freeTrial />
                        <BulletWrapper>&nbsp;•&nbsp;</BulletWrapper>
                    </FreeWrapper>
                )}
                <DateWrapper>
                    <ExpiresOnDateMessage expiryDate={date} />
                </DateWrapper>
            </CombinedDateWrapper>
        );
    }

    const platformName = platformsById(platform);
    const priceString = getPriceString();

    const subscribeToPlanClicked = () => {
        setDefaultEventSource('profile');
        recordEvent('profile_upgrade_to_plus');
    };

    return (
        <ProfileInformationWrapper>
            {confettiKey && <SubscriberConfetti tier={tier} key={confettiKey} />}
            <ProfileInformationBox>
                <PictureBox $tier={tier}>
                    <GravatarWrapper $tier={tier}>
                        <Gravatar
                            email={email}
                            size={120}
                            fallback={
                                <img
                                    width={120}
                                    height={120}
                                    alt=""
                                    src={InvertedPlusAccountIcon}
                                />
                            }
                        />
                    </GravatarWrapper>
                    <EmailWrapper title={email} $tier={tier}>
                        {email}
                    </EmailWrapper>
                </PictureBox>
                {!hasSubscription && (
                    <NonSubscriberSectionWrapper>
                        <UnlockFeaturesWrapper>
                            {formatMessage('unlock-features-and-more')}
                        </UnlockFeaturesWrapper>
                        <ProfileInformationPlanFeatureList plan="plus-yearly" tier="Plus" />
                        <SubscribeToPlanButtonLink
                            tier="Plus"
                            to={urls.subscribePath({ redirect: urls.profilePath })}
                            onClick={subscribeToPlanClicked}
                        >
                            {formatMessage('try-plus')}
                        </SubscribeToPlanButtonLink>
                    </NonSubscriberSectionWrapper>
                )}
                {hasSubscription && (
                    <DetailsBox>
                        {/**
                         * Add a little delight — if the user clicks the Plus logo, reset confettiKey
                         * to trigger <SubscriberConfetti> to re-mount and rain down!
                         */}
                        <LogoWrapper onClick={() => setConfettiKey(Math.random())}>
                            <ModernStyle>
                                <PlanBadge $tier={tier}>
                                    {tier === 'Patron' ? <PatronLogo /> : <PlusLogo />}
                                    {tier}
                                </PlanBadge>
                            </ModernStyle>
                        </LogoWrapper>
                        <>
                            <CombinedSubscriptionWrapper>
                                <PriceWrapper>{priceString}&nbsp;</PriceWrapper>
                                {!isGift && autoRenewing && priceString && (
                                    <PlatformWrapper>
                                        {formatMessage('via')} {platformName}
                                    </PlatformWrapper>
                                )}
                            </CombinedSubscriptionWrapper>
                            {renderExpiryLine()}
                        </>
                    </DetailsBox>
                )}
            </ProfileInformationBox>
        </ProfileInformationWrapper>
    );
}

export default ProfileInformation;
