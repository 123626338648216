import { connect } from 'react-redux';
import * as fromPlayerActions from '../../../../redux/actions/player.actions';
import { AppDispatch } from '../../../../store';
import Accordion from './Accordion';

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateEpisode: (
        episodeUuid: string,
        podcastUuid: string,
        audioUrl: string,
        duration: number,
        isFullEpisode?: boolean,
    ) => {
        dispatch(fromPlayerActions.Actions.updateAudioUrl(audioUrl));
        dispatch(fromPlayerActions.Actions.updateDuration(episodeUuid, podcastUuid, duration));
        dispatch(fromPlayerActions.Actions.updateIsAccordionEpisode(!isFullEpisode));
        dispatch(fromPlayerActions.Actions.updatePlayedUpTo(episodeUuid, 0));
    },
});
const AccordionConnected = connect(null, mapDispatchToProps)(Accordion);

export { AccordionConnected as Accordion };
