import { Icon } from 'components/Icon';
import React from 'react';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import { ShareButtonWrapper } from './ShareButton.styled';

type Props = {
    onClick: (e: React.SyntheticEvent) => void;
    color: string;
    className?: string;
};

const ShareButton = ({ color, onClick, className }: Props) => {
    const onKeyDown = (e: React.KeyboardEvent) => {
        onKeyDownEnter(e, () => onClick(e));
    };

    return (
        <ShareButtonWrapper
            tabIndex={0}
            role="button"
            aria-label="Share Episode"
            className={className}
            onKeyDown={onKeyDown}
            onClick={onClick}
            $fillColor={color}
        >
            <Icon id="share" />
        </ShareButtonWrapper>
    );
};

export default ShareButton;
