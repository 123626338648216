import { useEffect, useState } from 'react';
import { api } from '../services/api';

interface ReferralCode {
    code: string;
    url: string;
}

export function useGetReferralCode() {
    const [referralCode, setReferralCode] = useState<ReferralCode | null>(null);

    useEffect(() => {
        if (referralCode) {
            return;
        }
        const generateGuestPass = async () => {
            const response = await api.generateReferralCode();
            if (response?.code && response?.url) {
                setReferralCode({ code: response.code, url: response.url });
            }
        };
        generateGuestPass();
    }, [referralCode]);

    return referralCode;
}
