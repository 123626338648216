import {
    EpisodeSearchResult,
    PodcastSearchResult,
    SearchHistoryItem,
    SearchResponse,
} from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    SET_SEARCH_TERM = '@search/SET_SEARCH_TERM',
    FETCH_SEARCH_RESULTS = '@search/FETCH_SEARCH_RESULTS',
    FETCH_EPISODES_SEARCH_RESULTS_SUCCESS = '@search/FETCH_EPISODE_SEARCH_RESULTS_SUCCESS',
    FETCH_PODCASTS_SEARCH_RESULTS_SUCCESS = '@search/FETCH_PODCAST_SEARCH_RESULTS_SUCCESS',
    FETCH_SEARCH_RESULTS_SUCCESS = '@search/FETCH_SEARCH_RESULTS_SUCCESS',
    FETCH_SEARCH_RESULTS_FAILED = '@search/FETCH_SEARCH_RESULTS_FAILED',
    ADD_PODCAST_BY_URL = '@search/ADD_PODCAST_BY_URL',
    ADD_PODCAST_BY_URL_FAILED = '@search/ADD_PODCAST_BY_URL_FAILED',
    ADD_PODCAST_BY_URL_LOADING = '@search/ADD_PODCAST_BY_URL_LOADING',
    ADD_PODCAST_BY_URL_SUCCESS = '@search/ADD_PODCAST_BY_URL_SUCCESS',
    SEARCH_HISTORY_ITEM_ADD = '@search/SEARCH_HISTORY_ITEM_ADD',
    SEARCH_HISTORY_ITEM_REMOVE = '@search/SEARCH_HISTORY_ITEM_REMOVE',
    SEARCH_HISTORY_CLEAR = '@search/SEARCH_HISTORY_CLEAR',
}

export const Actions = {
    setSearchTerm: (term: string) => createAction(ActionTypes.SET_SEARCH_TERM, { term }),

    fetchSearchResults: (term: string) => createAction(ActionTypes.FETCH_SEARCH_RESULTS, { term }),

    fetchEpisodesSearchResultsSuccess: (term: string, episodes: EpisodeSearchResult[]) =>
        createAction(ActionTypes.FETCH_EPISODES_SEARCH_RESULTS_SUCCESS, { term, episodes }),

    fetchPodcastsSearchResultsSuccess: (term: string, podcasts: PodcastSearchResult[]) =>
        createAction(ActionTypes.FETCH_PODCASTS_SEARCH_RESULTS_SUCCESS, { term, podcasts }),

    fetchSearchResultsSuccess: (term: string, response: SearchResponse) =>
        createAction(ActionTypes.FETCH_SEARCH_RESULTS_SUCCESS, { term, response }),

    fetchSearchResultsFailed: () => createAction(ActionTypes.FETCH_SEARCH_RESULTS_FAILED),

    addPodcastByUrl: (url: string) => createAction(ActionTypes.ADD_PODCAST_BY_URL, { url }),

    addPodcastByUrlFailed: (errorMessage: string) =>
        createAction(ActionTypes.ADD_PODCAST_BY_URL_FAILED, { errorMessage }),

    addPodcastByUrlLoading: () => createAction(ActionTypes.ADD_PODCAST_BY_URL_LOADING),

    addPodcastByUrlSuccess: (uuid: string) =>
        createAction(ActionTypes.ADD_PODCAST_BY_URL_SUCCESS, { uuid }),

    addHistoryItem: (item: SearchHistoryItem) =>
        createAction(ActionTypes.SEARCH_HISTORY_ITEM_ADD, { item }),

    removeHistoryItem: (item: SearchHistoryItem) =>
        createAction(ActionTypes.SEARCH_HISTORY_ITEM_REMOVE, { item }),

    clearHistory: () => createAction(ActionTypes.SEARCH_HISTORY_CLEAR),
};

export type Actions = ActionsUnion<typeof Actions>;
