import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { shareDurationToSeconds } from '../helper/TimeHelper';

const parseTimeParam = (timeParam: string | null) => {
    if (!timeParam) {
        return [];
    }

    if (timeParam.includes(',')) {
        return timeParam.split(',').map(Number);
    }

    return [shareDurationToSeconds(timeParam)];
};

const useUrlClipParam = () => {
    const [searchParams] = useSearchParams();
    const timeParam = searchParams.get('t');
    const [startTime, endTime] = parseTimeParam(timeParam);
    const isClip = startTime !== undefined && endTime !== undefined;
    const navigate = useNavigate();

    const clearClipFromUrl = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('t');
        navigate(
            location.pathname + (searchParams.toString() ? `?${searchParams.toString()}` : ''),
        );
    }, [navigate]);

    return {
        clearClipFromUrl,
        startTime,
        endTime,
        isClip,
    };
};

export default useUrlClipParam;
