import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { dialogButtonMixin } from '../../components/Button/Button.styled';
import { EpisodeImage } from '../../components/EpisodeImage';
import { MobileEpisodeActions } from '../../components/EpisodesTable/buttons/MobileEpisodeActions';
import { useEpisodeActions } from '../../components/EpisodesTable/useEpisodeActions';
import { EpisodeTypeText } from '../../components/format/EpisodeTypeText';
import { Icon } from '../../components/Icon';
import {
    EpisodeSeason,
    EpisodeTitle,
    Header,
    ImageLink,
    PodcastLink,
    TitleBox,
} from '../../components/popup/EpisodePopup/EpisodePopup.styled';
import { PopupMenuVariant } from '../../components/popup/PopupMenu/PopupMenu';
import ShowOnMobile from '../../components/responsive/MobileWrapper';
import { useSelector } from '../../hooks/react-redux-typed';
import useFormatMessage from '../../hooks/useFormatMessage';
import { MOBILE_WIDTH } from '../../model/page';
import { FullEpisode, Podcast, PodcastTintColors } from '../../model/types';
import { UPLOADED_FILES_PODCAST_UUID } from '../../model/uploaded-files';
import { isEpisodeInUpNext } from '../../redux/reducers/selectors/up-next.selectors';
import { gridSize } from '../../styles';

const EpisodePageHeader = styled(Header)<{ $colors: PodcastTintColors }>`
    --tint-color: ${props => props.theme.getLightOrDarkTint(props.$colors)};
    margin: 0;
    padding-top: 16px;
    padding-bottom: 22px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const EpisodePageTitle = styled(TitleBox)`
    flex: 1;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
        text-align: center;
        margin: 0;
    }
`;

const EpisodePageTitleDetails = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
    }
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    gap: ${gridSize}px;
    margin-bottom: 12px;
    font-weight: 500;
    color: ${props => props.theme.tokens['primary-text-02']};

    a {
        color: ${props => props.theme.tokens['primary-text-02']};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        justify-content: center;
        gap: 0;
    }
`;

const PlayButton = styled(Button)<{ isPlus?: boolean }>`
    ${dialogButtonMixin}
    width: fit-content;
    margin-top: auto;
`;

const ActionsContainer = styled.div`
    margin-top: auto;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: flex;
        align-items: center;
        gap: ${gridSize}px;
        width: 100%;
        justify-content: center;
    }
`;

const PlayButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${gridSize}px;
`;

type Props = {
    episode: FullEpisode;
    podcastUrl: string;
    podcast: Podcast;
    tintColors: PodcastTintColors;
    showPlayButton?: boolean;
    onPlayEpisode?: () => void;
};

const EpisodeHeader = ({
    podcastUrl,
    podcast,
    episode,
    tintColors,
    showPlayButton,
    onPlayEpisode,
}: Props) => {
    const location = useLocation();
    const formatMessage = useFormatMessage();
    const actions = useEpisodeActions('episode_detail');
    const isInUpNext = useSelector(state => isEpisodeInUpNext(state, episode.uuid));
    const isEpisodeStarred = episode.starred;
    const isUploadedFile = episode.podcastUuid === UPLOADED_FILES_PODCAST_UUID;
    const isInHistory = useSelector(
        state => state.filter.episodes.history?.some(e => e.uuid === episode.uuid) ?? false,
    );

    return (
        <EpisodePageHeader $colors={tintColors}>
            <ImageLink to={podcastUrl}>
                <EpisodeImage episode={episode} borderRadius={8} />
            </ImageLink>
            <EpisodePageTitle className="EpisodePageTitle">
                <EpisodePageTitleDetails>
                    <TitleRow>
                        <PodcastLink
                            to={podcastUrl}
                            state={{ curatedListId: location.state?.curatedListId }}
                            data-testid="podcast-title"
                        >
                            {podcast.title}
                        </PodcastLink>
                        <EpisodeSeason>
                            <EpisodeTypeText
                                season={episode.season}
                                number={episode.number}
                                separator="·"
                                separatorPosition="left"
                            />
                        </EpisodeSeason>
                    </TitleRow>
                    <EpisodeTitle $titleLength={episode.title?.length} id="dialog_title">
                        {episode.title}
                    </EpisodeTitle>
                    <ActionsContainer>
                        {showPlayButton && (
                            <PlayButton onClick={onPlayEpisode}>
                                <PlayButtonContent>
                                    <Icon id="play-icon" />
                                    <div>{formatMessage('play-episode')}</div>
                                </PlayButtonContent>
                            </PlayButton>
                        )}
                        <ShowOnMobile>
                            <MobileEpisodeActions
                                popupVariant={PopupMenuVariant.BORDERED}
                                episodeUuid={episode.uuid}
                                podcastUuid={episode.podcastUuid}
                                title={episode.title}
                                duration={episode.duration}
                                url={episode.url}
                                isDeleted={episode.isDeleted}
                                playingStatus={episode.playingStatus}
                                isInUpNext={isInUpNext}
                                inHistory={isInHistory}
                                isUploadedFile={isUploadedFile}
                                isStarred={isEpisodeStarred}
                                actions={actions}
                                onRemoveFromHistory={actions.deleteHistory}
                                showSyncActions={true}
                                episodePageOnlyActions={true}
                            />
                        </ShowOnMobile>
                    </ActionsContainer>
                </EpisodePageTitleDetails>
            </EpisodePageTitle>
        </EpisodePageHeader>
    );
};

export default EpisodeHeader;
