import React, { useEffect, useRef, useState } from 'react';
import { USE_HTML_DESCRIPTIONS } from '../../settings';
import { blue50 } from '../../styles';
import {
    MoreButton,
    TextContent,
    TextContentWrapper,
    TextWithReadMoreContainer,
} from './TextWithReadMore.styled';
import { sanitizeReadMoreHTML } from './sanitizeReadMoreHTML';

type Props = {
    text: string;
    html?: string;
    lines: number;
    className?: string;
    color?: string;
    onExpand?: () => void;
};

export function TextWithReadMore({
    text,
    html,
    className,
    color = blue50,
    onExpand,
    lines,
}: Props) {
    const [expanded, setExpanded] = useState(false);
    const [needsTruncation, setNeedsTruncation] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const content = contentRef.current;
        if (content) {
            const lineHeight = parseInt(window.getComputedStyle(content).lineHeight);
            const maxHeight = lineHeight * lines;
            setNeedsTruncation(content.scrollHeight > maxHeight);
        }
    }, [text, html, lines]);

    const onReadMoreClicked = () => {
        setExpanded(true);
        onExpand?.();
    };

    return (
        <TextWithReadMoreContainer className={className}>
            <TextContentWrapper $isExpanded={expanded} $lines={lines}>
                {!expanded && needsTruncation && (
                    <MoreButton color={color} onClick={onReadMoreClicked}>
                        <span>Read More</span>
                    </MoreButton>
                )}
                {USE_HTML_DESCRIPTIONS && html ? (
                    <TextContent
                        ref={contentRef}
                        dangerouslySetInnerHTML={{ __html: sanitizeReadMoreHTML(html) }}
                    />
                ) : (
                    <TextContent ref={contentRef}>{text}</TextContent>
                )}
            </TextContentWrapper>
        </TextWithReadMoreContainer>
    );
}

export default TextWithReadMore;
