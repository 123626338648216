import {
    OAuthProvider,
    PurchaseWebPlayerFailureActionPayload,
    RequestPurchaseActionPayload,
    SubscriptionData,
    UserStats,
} from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    SIGN_IN = '@user/SIGN_IN',
    SIGN_IN_WITH_OAUTH = '@user/SIGN_IN_WITH_OAUTH',
    SIGN_IN_WITHOUT_AUTHENTICATING = '@user/SIGN_IN_WITHOUT_AUTHENTICATING',
    SIGN_IN_SUCCESS = '@user/SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE = '@user/SIGN_IN_FAILURE',
    SIGN_IN_TRY_AGAIN = '@user/SIGN_IN_TRY_AGAIN',
    CLEAR_JUST_SIGNED_UP = '@user/CLEAR_JUST_SIGNED_UP',

    SIGN_IN_WITH_ACCESS_TOKEN = '@user/SIGN_IN_WITH_ACCESS_TOKEN',

    SIGN_OUT = '@user/SIGN_OUT',
    UN_AUTHENTICATE = '@user/UN_AUTHENTICATE',

    SIGN_UP = '@user/SIGN_UP',
    SIGN_UP_SUCCESS = '@user/SIGN_UP_SUCCESS',
    SIGN_UP_ERROR = '@user/SIGN_UP_ERROR',

    PURCHASE_WEB_PLAYER_REQUEST = '@user/PURCHASE_WEB_PLAYER_REQUEST',
    PURCHASE_WEB_PLAYER_SUCCESS = '@user/PURCHASE_WEB_PLAYER_SUCCESS',
    PURCHASE_WEB_PLAYER_FAILURE = '@user/PURCHASE_WEB_PLAYER_FAILURE',

    SHOW_CHANGE_EMAIL_MODAL = '@user/SHOW_CHANGE_EMAIL_MODAL',
    DISMISS_CHANGE_EMAIL_MODAL = '@user/DISMISS_CHANGE_EMAIL_MODAL',
    SHOW_CHANGE_PASSWORD_MODAL = '@user/SHOW_CHANGE_PASSWORD_MODAL',
    DISMISS_CHANGE_PASSWORD_MODAL = '@user/DISMISS_CHANGE_PASSWORD_MODAL',
    SHOW_DELETE_ACCOUNT_MODAL = '@user/SHOW_DELETE_ACCOUNT_MODAL',
    DISMISS_DELETE_ACCOUNT_MODAL = '@user/DISMISS_DELETE_ACCOUNT_MODAL',

    FORGOTTEN_PASSWORD = '@user/FORGOTTEN_PASSWORD',
    FORGOTTEN_PASSWORD_SENT = '@user/FORGOTTEN_PASSWORD_SENT',
    FORGOTTEN_PASSWORD_ERROR = '@user/FORGOTTEN_PASSWORD_ERROR',
    CLEAR_USER_SERVER_ERROR = '@user/CLEAR_USER_SERVER_ERROR',

    DOWNLOAD_STATS = '@user/DOWNLOAD_STATS',
    DOWNLOAD_STATS_SUCCESS = '@user/DOWNLOAD_STATS_SUCCESS',

    OPEN_SUPPORT = '@user/OPEN_SUPPORT',
    CLOSE_SUPPORT = '@user/CLOSE_SUPPORT',
    SEND_SUPPORT_MESSAGE = '@user/SEND_SUPPORT_MESSAGE',
    SUPPORT_SENDING = '@user/SUPPORT_SENDING',
    SUPPORT_SENT = '@user/SUPPORT_SENT',
    SUPPORT_SEND_FAILED = '@user/SUPPORT_SEND_FAILED',

    EMAIL_CHANGE_REQUEST = '@user/EMAIL_CHANGE_REQUEST',
    EMAIL_CHANGE_RESPONSE = '@user/EMAIL_CHANGE_RESPONSE',
    EMAIL_CHANGE_CANCEL = '@user/EMAIL_CHANGE_CANCEL',

    ACCOUNT_DELETE_REQUEST = '@user/ACCOUNT_DELETE_REQUEST',
    ACCOUNT_DELETE_RESPONSE = '@user/ACCOUNT_DELETE_RESPONSE',
    ACCOUNT_DELETE_CANCEL = '@user/ACCOUNT_DELETE_CANCEL',

    PASSWORD_CHANGE_REQUEST = '@user/PASSWORD_CHANGE_REQUEST',
    PASSWORD_CHANGE_RESPONSE = '@user/PASSWORD_CHANGE_RESPONSE',
    PASSWORD_CHANGE_CANCEL = '@user/PASSWORD_CHANGE_CANCEL',

    PASSWORD_RESET_REQUEST = '@user/PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_RESPONSE = '@user/PASSWORD_RESET_RESPONSE',

    USER_RETURNED = '@user/USER_RETURNED',
}

export const Actions = {
    signIn: ({ email, password }: { email: string; password: string }) =>
        createAction(ActionTypes.SIGN_IN, {
            email,
            password,
        }),

    signInWithOAuth: (provider: OAuthProvider, idToken: string) =>
        createAction(ActionTypes.SIGN_IN_WITH_OAUTH, { provider, idToken }),

    signInWithAccessToken: ({ token, email }: { token: string; email: string }) =>
        createAction(ActionTypes.SIGN_IN_WITH_ACCESS_TOKEN, {
            token,
            email,
        }),

    signInSuccess: ({ email, isNew }: { email: string; isNew: boolean }) =>
        createAction(ActionTypes.SIGN_IN_SUCCESS, { email, isNew }),

    signInFailure: (message: string) => createAction(ActionTypes.SIGN_IN_FAILURE, { message }),

    signInTryAgain: () => createAction(ActionTypes.SIGN_IN_TRY_AGAIN),

    unauthenticate: () => createAction(ActionTypes.UN_AUTHENTICATE),

    signOut: () => createAction(ActionTypes.SIGN_OUT),

    unAuthenticate: () => createAction(ActionTypes.UN_AUTHENTICATE),

    signUp: (email: string, password: string) =>
        createAction(ActionTypes.SIGN_UP, {
            email,
            password,
        }),

    signUpSuccess: (email: string) => createAction(ActionTypes.SIGN_UP_SUCCESS, { email }),

    signUpError: (message: string) => createAction(ActionTypes.SIGN_UP_ERROR, { message }),

    clearJustSignedUp: () => createAction(ActionTypes.CLEAR_JUST_SIGNED_UP),

    requestPurchaseWebPlayer: (transactionDetails: RequestPurchaseActionPayload) =>
        createAction(ActionTypes.PURCHASE_WEB_PLAYER_REQUEST, transactionDetails),

    purchaseWebPlayerSuccess: (subscriptionData: SubscriptionData) =>
        createAction(ActionTypes.PURCHASE_WEB_PLAYER_SUCCESS, { subscriptionData }),
    purchaseWebPlayerFailure: (transactionDetails: PurchaseWebPlayerFailureActionPayload) =>
        createAction(ActionTypes.PURCHASE_WEB_PLAYER_FAILURE, transactionDetails),

    showChangeEmailModal: () => createAction(ActionTypes.SHOW_CHANGE_EMAIL_MODAL),
    dismissChangeEmailModal: () => createAction(ActionTypes.DISMISS_CHANGE_EMAIL_MODAL),

    showDeleteAccountModal: () => createAction(ActionTypes.SHOW_DELETE_ACCOUNT_MODAL),
    dismissDeleteAccountModal: () => createAction(ActionTypes.DISMISS_DELETE_ACCOUNT_MODAL),

    showChangePasswordModal: () => createAction(ActionTypes.SHOW_CHANGE_PASSWORD_MODAL),
    dismissChangePasswordModal: () => createAction(ActionTypes.DISMISS_CHANGE_PASSWORD_MODAL),

    forgottenPassword: (email: string) => createAction(ActionTypes.FORGOTTEN_PASSWORD, { email }),

    forgottenPasswordSent: () => createAction(ActionTypes.FORGOTTEN_PASSWORD_SENT),

    forgottenPasswordError: (message: string) =>
        createAction(ActionTypes.FORGOTTEN_PASSWORD_ERROR, { message }),

    // This is a workaround event because errors for multiple pages are currently stored
    // in the same place in Redux state, and we want to clear it before navigating.
    clearUserError: () => createAction(ActionTypes.CLEAR_USER_SERVER_ERROR),

    downloadStats: () => createAction(ActionTypes.DOWNLOAD_STATS),
    downloadStatsSuccess: (stats: UserStats) =>
        createAction(ActionTypes.DOWNLOAD_STATS_SUCCESS, { stats }),

    openSupport: () => createAction(ActionTypes.OPEN_SUPPORT),
    closeSupport: () => createAction(ActionTypes.CLOSE_SUPPORT),
    sendingSupportMessage: () => createAction(ActionTypes.SUPPORT_SENDING),
    sentSupportMessage: () => createAction(ActionTypes.SUPPORT_SENT),
    sendingSupportMessageFailed: () => createAction(ActionTypes.SUPPORT_SEND_FAILED),
    sendSupportMessage: (message: string, reason: string, debug: string) =>
        createAction(ActionTypes.SEND_SUPPORT_MESSAGE, {
            message,
            reason,
            debug,
        }),

    requestEmailChange: (newEmail: string, password: string) =>
        createAction(ActionTypes.EMAIL_CHANGE_REQUEST, {
            newEmail,
            password,
        }),

    emailChangeResponse: (success: boolean, message: string, newEmail: string) =>
        createAction(ActionTypes.EMAIL_CHANGE_RESPONSE, {
            success,
            message,
            newEmail,
        }),
    cancelEmailChange: () => createAction(ActionTypes.EMAIL_CHANGE_CANCEL),

    requestAccountDelete: () => createAction(ActionTypes.ACCOUNT_DELETE_REQUEST),
    accountDeleteResponse: (success: boolean, message: string) =>
        createAction(ActionTypes.ACCOUNT_DELETE_RESPONSE, {
            success,
            message,
        }),
    cancelAccountDelete: () => createAction(ActionTypes.ACCOUNT_DELETE_CANCEL),

    requestPasswordChange: (oldPassword: string, newPassword: string) =>
        createAction(ActionTypes.PASSWORD_CHANGE_REQUEST, {
            oldPassword,
            newPassword,
        }),

    passwordChangeResponse: (success: boolean, message: string) =>
        createAction(ActionTypes.PASSWORD_CHANGE_RESPONSE, {
            success,
            message,
        }),
    cancelPasswordChange: () => createAction(ActionTypes.PASSWORD_CHANGE_CANCEL),

    requestResetPassword: (newPassword: string, resetPasswordToken: string) =>
        createAction(ActionTypes.PASSWORD_RESET_REQUEST, {
            newPassword,
            resetPasswordToken,
        }),

    resetPasswordResponse: (success: boolean, message: string) =>
        createAction(ActionTypes.PASSWORD_RESET_RESPONSE, {
            success,
            message,
        }),

    userReturned: () => createAction(ActionTypes.USER_RETURNED),
};

export type Actions = ActionsUnion<typeof Actions>;
