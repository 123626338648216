import { Icon } from 'components/Icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import {
    ButtonsWrapper,
    DeleteIconWrapper,
    Heading,
    Separator,
    SubtitleWrapper,
    VerticalSpace,
    WarningWrapper,
} from './styled';

const FileDeleteModal = ({ theme, title, uuid, onClose, onConfirm }) => {
    return (
        <Modal theme={theme} horizontalInsetPx={32} onClose={onClose}>
            <TrackOnMount event="user_file_delete_shown" />
            <TrackOnUnmount event="user_file_delete_dismissed" />

            <DeleteIconWrapper>
                <Icon id="delete" size={30} />
            </DeleteIconWrapper>
            <Heading>
                <FormattedMessage id="delete-file" />
            </Heading>
            <SubtitleWrapper>
                <FormattedMessage
                    id="file-delete-confirm"
                    values={{
                        fileName: `"${title}"`,
                    }}
                />
            </SubtitleWrapper>
            <WarningWrapper>
                <FormattedMessage id="file-delete-from-devices" />
            </WarningWrapper>
            <Separator />
            <ButtonsWrapper>
                <Button kind="secondary-muted" width={120} onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
                <VerticalSpace />
                <Button kind="primary-red" width={120} onClick={() => onConfirm(uuid)}>
                    <FormattedMessage id="delete" />
                </Button>
            </ButtonsWrapper>
        </Modal>
    );
};

export default FileDeleteModal;
