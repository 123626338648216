import React from 'react';
import { SubscribeButton } from '../../buttons/SubscribeButton';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import { Rank, Subtitle, Text, Title, WrapperLink } from './GridItem.styled';

export type Props = {
    image: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    onClick?: () => void;
    rank?: number;
    onSubscribe?: () => void;
    podcastUuid?: string;
};

const GridItem = ({ image, title, subtitle, onClick, rank, onSubscribe, podcastUuid }: Props) => {
    const onKeyDown = (e: React.KeyboardEvent) => {
        onKeyDownEnter(e, () => onClick?.());
    };
    return (
        <WrapperLink onClick={onClick} onKeyDown={onKeyDown}>
            {!!rank && <Rank>{rank}</Rank>}
            {image}
            <Text>
                <Title>{title || ''}</Title>
                <Subtitle>{subtitle || ''}</Subtitle>
                {podcastUuid && (
                    <SubscribeButton
                        circled
                        podcastUuid={podcastUuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                )}
            </Text>
        </WrapperLink>
    );
};

export default GridItem;
