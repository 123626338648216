import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SubscriptionTier } from '../../model/types';
import { ModernStyle } from '../../styles/ModernStyle.styled';
import { Button } from '../Button';
import { subscribeToPlanButtonMixin } from '../Button/Button.styled';

const StyledSubscribeToPlanButton = styled(Button)<{ $tier: SubscriptionTier }>`
    ${subscribeToPlanButtonMixin}
`;

type Props = Omit<ComponentProps<typeof StyledSubscribeToPlanButton>, '$tier'> & {
    tier: SubscriptionTier;
};

const SubscribeToPlanButton = ({ tier, children, ...props }: Props) => (
    <ModernStyle>
        <StyledSubscribeToPlanButton $tier={tier} {...props}>
            {children ?? <FormattedMessage id="subscribe-to-tier" values={{ tier }} />}
        </StyledSubscribeToPlanButton>
    </ModernStyle>
);

export default SubscribeToPlanButton;
