import React from 'react';
import styled from 'styled-components';
import { Episode } from '../../../model/types';
import { EpisodeTypeText } from '../../format/EpisodeTypeText';
import HideOnMobile from '../../responsive/HideOnMobile';
import ShowOnMobile from '../../responsive/MobileWrapper';
import { EpisodeDate } from './EpisodeDate';

const MobileContent = styled.div`
    && {
        > * {
            display: inline;
            padding: 0;
            font-size: 14px;
        }
    }
`;

interface EpisodeDateTypeWrapperProps {
    episode: Episode;
    inactive?: boolean;
}

export const EpisodeDateTypeWrapper: React.FC<EpisodeDateTypeWrapperProps> = ({
    episode,
    inactive,
}) => {
    return (
        <>
            <ShowOnMobile>
                <MobileContent>
                    <EpisodeTypeText
                        season={episode.season}
                        number={episode.number}
                        type={episode.type}
                        separator="·"
                    />
                    <EpisodeDate published={episode.published} inactive={inactive} />
                </MobileContent>
            </ShowOnMobile>
            <HideOnMobile>
                <EpisodeDate published={episode.published} inactive={inactive} />
            </HideOnMobile>
        </>
    );
};
