import { Icon } from 'components/Icon';
import React from 'react';
import { INPUT_PATTERN } from '../../constants';
import {
    DecrementButton,
    IncrementButton,
    InputContainer,
    StepperButtons,
} from '../ClipEditor.styled';

interface TimeInputProps {
    isDurationOverHour: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    onIncrement: () => void;
    onDecrement: () => void;
}

const TimeInput = ({
    isDurationOverHour,
    value,
    onChange,
    onBlur,
    onIncrement,
    onDecrement,
}: TimeInputProps) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                onIncrement();
                break;
            case 'ArrowDown':
                event.preventDefault();
                onDecrement();
                break;
            default:
                break;
        }
    };
    return (
        <InputContainer $isDurationOverHour={isDurationOverHour}>
            <input
                type="text"
                value={value}
                pattern={INPUT_PATTERN}
                title="HH:MM:SS"
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={handleKeyDown}
            />
            <StepperButtons>
                <IncrementButton onClick={onIncrement}>
                    <Icon id="stepper-up-sm" />
                </IncrementButton>
                <DecrementButton onClick={onDecrement}>
                    <Icon id="stepper-down-sm" />
                </DecrementButton>
            </StepperButtons>
        </InputContainer>
    );
};

export default TimeInput;
