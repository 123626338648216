import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { LoaderSquare } from '../../components';
import { ButtonLink } from '../../components/ButtonLink';
import { EpisodePopupToolbar } from '../../components/popup/EpisodePopup/EpisodePopupToolbar';
import { ShowNotes } from '../../components/popup/EpisodePopup/ShowNotes';
import { TrackOnMount, TrackOnUnmount } from '../../components/Tracks';
import { isMobile } from '../../helper/Browser';
import { NavigationItems } from '../../helper/NavigationHelper';
import { getSeekToSecondsFromHref } from '../../helper/TimeHelper';
import { useDispatch } from '../../hooks/react-redux-typed';
import useEpisodeDetails from '../../hooks/useEpisodeDetails';
import usePodcastDetails from '../../hooks/usePodcastDetails';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import useTracks from '../../hooks/useTracks';
import useUrlClipParam from '../../hooks/useUrlClipParam';
import { MOBILE_WIDTH } from '../../model/page';
import { TracksEventSource } from '../../model/types';
import * as fromPlayerActions from '../../redux/actions/player.actions';
import { gridSize, Typography } from '../../styles';
import EpisodeHeader from './EpisodeHeader';
import EpisodePageClipPopup from './EpisodePageClipPopup';

const EpisodeContainer = styled.div`
    padding: ${5 * gridSize}px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0 !important;

    && {
        @media (max-width: ${MOBILE_WIDTH}px) {
            padding: 0 16px 32px !important;
        }
    }
`;

export const EpisodePageShowNotes = styled(ShowNotes)`
    padding: 14px 0 14px 0;
    max-width: 680px;
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const SeeAllEpisodesButton = styled(ButtonLink)`
    width: fit-content;
    padding: 0 30px;
`;

const EpisodeDescription = styled.h2`
    ${Typography.Desktop['H50-B']}
    padding-top: 20px;
`;

const EpisodePageEpisodePopupToolbar = styled(EpisodePopupToolbar)`
    margin: 0;
    width: 100%;
    max-width: 680px;
`;

type PodcastDetailsProps = {
    podcastUuid: string;
    episodeUuid: string;
    onPlayEpisode?: () => void;
};

const EpisodeDetails = ({ podcastUuid, episodeUuid, onPlayEpisode }: PodcastDetailsProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { recordEvent } = useTracks();
    const podcastDetails = usePodcastDetails(podcastUuid);
    const episodeDetails = useEpisodeDetails(podcastUuid, episodeUuid);

    const handleShowNotesClick: MouseEventHandler = e => {
        if (e.target instanceof Element && e.target.closest?.('a')) {
            const seekTo = getSeekToSecondsFromHref(e.target.getAttribute('href'));

            if (seekTo !== null) {
                // Prevent seeking past the last 5 seconds of the episode
                const maxSeekTo = episodeDetails.episode.duration - 5;

                e.preventDefault();

                dispatch(
                    fromPlayerActions.Actions.playEpisode(
                        episodeDetails.episode.uuid,
                        episodeDetails.episode.podcastUuid,
                        { eventSource: episodeDetails.episode.eventSource },
                        { seekTo: Math.min(seekTo, maxSeekTo) },
                    ),
                );
            }

            recordEvent('episode_detail_show_notes_link_tapped', {
                episode_uuid: episodeDetails.episode.uuid,
                source: episodeDetails.episode.eventSource,
            });
        }
    };

    const handleSeeAllEpisodesButtonClick = () => {
        recordEvent('episode_detail_see_all_episodes_tapped');
    };

    const isLoading = podcastDetails.isLoading || episodeDetails.isLoading;
    const loadFailed = podcastDetails.loadFailed || episodeDetails.loadFailed;

    useScrollRestoration(!isLoading);

    if (isLoading) {
        return (
            <LoadingWrapper>
                <LoaderSquare />
            </LoadingWrapper>
        );
    }

    if (loadFailed || !podcastDetails.podcast || !episodeDetails.episode) {
        if (podcastDetails.podcast) {
            navigate(podcastDetails.podcastUrl);
            return;
        }
        return <p>{<FormattedMessage id="podcast-load-error" />}</p>;
    }

    return (
        <EpisodeContainer>
            <TrackOnMount
                event="episode_detail_shown"
                properties={{ source: episodeDetails.episode.eventSource }}
            />
            <TrackOnUnmount
                event="episode_detail_dismissed"
                properties={{ source: episodeDetails.episode.eventSource }}
            />
            <EpisodeHeader
                podcast={podcastDetails.podcast}
                podcastUrl={podcastDetails.podcastUrl}
                episode={episodeDetails.episode}
                tintColors={podcastDetails.colors}
                showPlayButton
                onPlayEpisode={onPlayEpisode}
            />
            <EpisodePageEpisodePopupToolbar
                episode={episodeDetails.episode}
                isUploadedFile={false}
            />
            <EpisodeDescription>
                <FormattedMessage id="episode-description" />
            </EpisodeDescription>
            <div onClick={handleShowNotesClick}>
                <EpisodePageShowNotes episodeUuid={episodeDetails.episode.uuid} />
            </div>
            <SeeAllEpisodesButton
                onClick={handleSeeAllEpisodesButtonClick}
                to={podcastDetails.podcastUrl}
                kind="secondary"
            >
                <FormattedMessage id="see-all-episodes" />
            </SeeAllEpisodesButton>
        </EpisodeContainer>
    );
};

const EpisodePage = () => {
    const { uuid: podcastUuid, episode: episodeUuid } = useParams<{
        uuid: string;
        episode: string;
    }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { startTime, endTime, isClip, clearClipFromUrl } = useUrlClipParam();

    const handleOnPlayEpisode = useCallback(
        ({ eventSource }: { eventSource: TracksEventSource }) => {
            if (isMobile()) {
                clearClipFromUrl();
            }

            if (podcastUuid && episodeUuid) {
                dispatch(
                    fromPlayerActions.Actions.playEpisode(episodeUuid, podcastUuid, {
                        eventSource,
                    }),
                );
            }
        },
        [dispatch, episodeUuid, podcastUuid, clearClipFromUrl],
    );

    const handleOnPlayClip = useCallback(() => {
        dispatch(
            fromPlayerActions.Actions.pause({
                eventSource: 'episode_detail',
            }),
        );
    }, [dispatch]);

    const handleOnCloseClip = useCallback(() => {
        clearClipFromUrl();
    }, [clearClipFromUrl]);

    useEffect(() => {
        if (podcastUuid && episodeUuid && startTime && !endTime) {
            dispatch(
                fromPlayerActions.Actions.playEpisode(
                    episodeUuid,
                    podcastUuid,
                    {
                        eventSource: 'episode_detail',
                    },
                    { seekTo: startTime, paused: true },
                ),
            );
        }
    }, [dispatch, endTime, episodeUuid, podcastUuid, startTime]);

    if (!podcastUuid || !episodeUuid) {
        if (podcastUuid) {
            navigate(`${NavigationItems.PODCASTS.path}/${podcastUuid}`);
            return;
        }
        return <p>{<FormattedMessage id="podcast-load-error" />}</p>;
    }

    return (
        <>
            <Helmet>
                <meta
                    name="apple-itunes-app"
                    content="app-id=414834813, app-clip-bundle-id=au.com.shiftyjelly.podcasts.Clip, app-clip-display=card"
                />
                <meta name="google-play-app" content="app-id=au.com.shiftyjelly.pocketcasts" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                />
            </Helmet>
            <EpisodeDetails
                onPlayEpisode={() => handleOnPlayEpisode({ eventSource: 'episode_detail' })}
                podcastUuid={podcastUuid}
                episodeUuid={episodeUuid}
            />
            {isClip && (
                <EpisodePageClipPopup
                    onPlayEpisode={() => handleOnPlayEpisode({ eventSource: 'episode_clip' })}
                    onPlayClip={handleOnPlayClip}
                    onClose={handleOnCloseClip}
                    podcastUuid={podcastUuid}
                    episodeUuid={episodeUuid}
                    startTime={startTime}
                    endTime={endTime}
                />
            )}
        </>
    );
};

export default EpisodePage;
