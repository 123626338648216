import { EpisodeType, Podcast } from '.';

export interface DiscoverCategory {
    id: number;
    name: string;
    title: string;
    icon: string;
    source: string;
    podcasts?: Podcast[];
}

export interface CategoryList {
    region: string;
    podcasts: Podcast[];
}

export interface ContentSpec {
    layout: DiscoverLayout[];
    regions: Record<string, DiscoverRegion>;
    region_code_token: string;
    region_name_token: string;
    default_region_code: string;
    published_date: string;
}

export enum DiscoverSummaryStyle {
    CAROUSEL = 'carousel',
    SMALL_LIST = 'small_list',
    LARGE_LIST = 'large_list',
    PILLS = 'pills',
    NETWORK = 'network',
    CATEGORY = 'category',
    SINGLE_EPISODE = 'single_episode',
    SINGLE_PODCAST = 'single_podcast',
    COLLECTION = 'collection',
}

export enum DiscoverExpandedStyle {
    PLAIN_LIST = 'plain_list',
    RANKED_LIST = 'ranked_list',
    NETWORK_LIST = 'network_list',
    DESCRIPTIVE = 'descriptive_list',
}

export enum DiscoverType {
    EPISODE_LIST = 'episode_list',
    PODCAST_LIST = 'podcast_list',
    NETWORK_LIST = 'network_list',
    CATEGORIES = 'categories',
}

export interface Collageimage {
    key: string;
    image_url: string;
}

export interface ListColors {
    onLightBackground: string;
    onDarkBackground: string;
}

export interface DiscoverLayout {
    id: string;
    uuid: string;
    title: string;
    type: string;
    category_id?: number;
    summary_style: DiscoverSummaryStyle;
    expanded_style: DiscoverExpandedStyle;
    expanded_top_item_label?: string;
    source: string;
    curated?: boolean;
    data?: ListData | DiscoverCategory[];
    regions: string[];
    listName: string;
    sponsored?: boolean;
    sponsored_podcasts?: Array<{ position: number; source: string }>;
    popular?: number[];
}

export interface DiscoverRegion {
    name: string;
    flag: string;
    code: string;
}

export interface PodcastDiscover {
    title: string;
    author: string;
    description: string;
    feed: string;
    uuid: string;
    itunes: string;
    website: string;
    paid: boolean;
    sponsoredListId?: string;
}

export interface EpisodeDiscover {
    uuid: string;
    url: string;
    published: string;
    duration: number;
    file_type: string;
    title: string;
    size: number;
    podcast_uuid: string;
    podcast_title: string;
    type: EpisodeType;
    season: number;
    number: number;
}

export interface ListData {
    title: string;
    description: string;
    datetime: string;
    list_id: string;
    collection_image: string;
    colors: ListColors;
    subtitle?: string;
    web_url?: string;
    web_title?: string;
    collage_images?: Collageimage[];
    podcasts: PodcastDiscover[];
    episodes: EpisodeDiscover[];
}
