import { TrackOnMount } from 'components/Tracks';
import { withAnalyticsContext } from 'context/AnalyticsContext';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Header } from '../../components';
import { FeatureLockMessage } from '../../components/FeatureLock';
import PlusUploadFilesIcon from '../../components/PlusUploadFilesIcon';
import useTracks from '../../hooks/useTracks';
import { hasPaidSubscription } from '../../redux/reducers/selectors';
import { gridSize } from '../../styles';
import FilesPageContent from './FilesPageContent';
import { UploadedFilesPageWrapper } from './styled';

type Props = {
    serverError: boolean;
    isLoading: boolean;
    filesOrder: string[];
    fetchUploadedFilesData: () => void;
    filesWebOpenEvent: () => void;
};

const ShadowBox = styled.div`
    border-radius: 8px;
    border: 1px solid ${props => props.theme.tokens['secondary-ui-02']};

    /* shadow/low */
    box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.25);
`;

const PlusUploadFilesIconWrapper = styled.div`
    padding-right: ${4 * gridSize}px;
`;

const UploadedFilesPage: FC<PropsWithChildren<Props>> = ({
    serverError,
    isLoading,
    filesOrder,
    fetchUploadedFilesData,
    filesWebOpenEvent,
}) => {
    const formatMessage = useFormatMessage();
    const { recordEvent, setDefaultEventSource } = useTracks();
    const isSubscriber = useSelector(hasPaidSubscription);

    useEffect(() => {
        fetchUploadedFilesData();
        filesWebOpenEvent();
    }, [fetchUploadedFilesData, filesWebOpenEvent]);

    const onFeatureLockSubscribeClick = () => {
        setDefaultEventSource('files');
        recordEvent('free_user_popup_subscribe_tapped', { source: 'files-page' });
    };

    return (
        <UploadedFilesPageWrapper>
            <TrackOnMount event="uploaded_files_shown" />
            <Helmet>
                <title>{formatMessage('files')}</title>
            </Helmet>
            <Header title={formatMessage('files')} />
            {!isSubscriber && (
                <ShadowBox>
                    <FeatureLockMessage
                        requires="subscription"
                        title={formatMessage('feature-files-unavailable-title')}
                        description={formatMessage('feature-files-unavailable-description')}
                        subscribeClick={onFeatureLockSubscribeClick}
                        image={
                            <PlusUploadFilesIconWrapper>
                                <PlusUploadFilesIcon />
                            </PlusUploadFilesIconWrapper>
                        }
                    />
                </ShadowBox>
            )}
            {isSubscriber && (
                <FilesPageContent
                    serverError={serverError}
                    isLoading={isLoading}
                    filesOrder={filesOrder}
                />
            )}
        </UploadedFilesPageWrapper>
    );
};

export default withAnalyticsContext(UploadedFilesPage);
