import styled from 'styled-components';
import { MOBILE_WIDTH } from '../../model/page';

const HideOnMobile = styled.div`
    @media (max-width: ${MOBILE_WIDTH}px) {
        display: none;
    }
`;

export default HideOnMobile;
