import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LoaderSquare } from '../../components';
import { Logo as PlusLogo } from '../../components/PocketcastsLogo';
import { LoginRegisterForm } from '../../components/form/LoginRegisterForm';
import { ModalTypes } from '../../helper/UiHelper';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import useTracks from '../../hooks/useTracks';
import * as fromFlagsActions from '../../redux/actions/flags.actions';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import * as fromUserActions from '../../redux/actions/user.actions';
import { RootState } from '../../redux/reducers';
import {
    getHasJustActivatedTrial,
    getIsRedeemingTrial,
    getIsValidatingTrial,
    hasPaidSubscription,
} from '../../redux/reducers/selectors';
import { getJustSignedUp } from '../../redux/reducers/selectors/user.selectors';
import urls from '../../urls';
import { PromoErrorModal } from './PromoErrorModal';
import { PromoExpiredModal } from './PromoExpiredModal';
import { TrialDescription, Wrapper } from './RedeemPromoPage.styled';

type RouteParams = {
    id: string;
};

function RedeemPromoPage() {
    const { setDefaultEventSource } = useTracks();
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const params = useParams<keyof RouteParams>() as RouteParams;
    const promoCode = params.id;
    const {
        showMiscelleneousErrorModal,
        errorMessage,
        validatedTrial,
        showExpiredInvalidCodeModal,
    } = useSelector((state: RootState) => {
        const { trial } = state.subscription;
        const serverError = state.user.errorMessage ?? undefined;
        const showMiscelleneousErrorModal = (() => {
            if (trial.errorMessage && !serverError) {
                return true;
            }
            return false;
        })();
        const showExpiredInvalidCodeModal = trial.isExpiredCode === true;
        return {
            ...trial,
            showMiscelleneousErrorModal,
            showExpiredInvalidCodeModal,
        };
    });

    const justSignedUp = useSelector(getJustSignedUp);
    const hasSubscription = useSelector(hasPaidSubscription);
    const hasJustActivatedTrial = useSelector(getHasJustActivatedTrial);
    const isValidatingTrial = useSelector(getIsValidatingTrial);
    const isRedeemingTrial = useSelector(getIsRedeemingTrial);
    const navigate = useNavigate();

    // Clear errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [],
    );

    useEffect(() => {
        setDefaultEventSource('redeem');
    }, [setDefaultEventSource]);

    useEffect(() => {
        dispatch(fromSubscriptionActions.Actions.validatePromoCodeRequest(promoCode));
    }, [dispatch, promoCode]);

    useEffect(() => {
        if (!hasJustActivatedTrial) {
            return;
        }

        if (justSignedUp) {
            navigate(urls.welcomePath());
        } else {
            navigate(urls.podcastsPath);
            dispatch(fromFlagsActions.Actions.addFlag(formatMessage('enjoy-a-free-trial')));
        }
    }, [dispatch, formatMessage, hasJustActivatedTrial, justSignedUp, navigate]);

    useEffect(() => {
        if (hasSubscription && !hasJustActivatedTrial && !isRedeemingTrial) {
            navigate(urls.podcastsPath);
            dispatch(
                fromSubscriptionActions.Actions.showModalGeneric(
                    ModalTypes.showAlreadyPlusSubscriberModal,
                ),
            );
        }
    }, [dispatch, hasJustActivatedTrial, hasSubscription, isRedeemingTrial, navigate]);

    if (isValidatingTrial) {
        return <LoaderSquare />;
    }

    if (showExpiredInvalidCodeModal) {
        return (
            <Wrapper>
                <PromoExpiredModal />
            </Wrapper>
        );
    }

    if (showMiscelleneousErrorModal) {
        return <PromoErrorModal message={errorMessage} />;
    }

    return (
        <Wrapper>
            <TrialDescription>
                <PlusLogo size="small" />
                <p>{validatedTrial.description || formatMessage('enjoy-a-free-trial')}</p>
            </TrialDescription>

            <LoginRegisterForm
                allowSignup
                onLoggedIn={() =>
                    dispatch(fromSubscriptionActions.Actions.redeemPromoRequest(promoCode))
                }
                isLoading={isRedeemingTrial}
                title={formatMessage('redeem-promo-headline')}
                submitButtonText={formatMessage('redeem-offer-and-start-free-trial')}
            />
        </Wrapper>
    );
}

export default RedeemPromoPage;
