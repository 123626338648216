import styled from 'styled-components';
import { Typography } from '../../styles';

export const TextWithReadMoreContainer = styled.div`
    ${Typography.Desktop['H50-B']};
    color: ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const TextContentWrapper = styled.div<{ $lines: number; $isExpanded: boolean }>`
    display: ${props => (props.$isExpanded ? 'block' : '-webkit-box')};
    -webkit-line-clamp: ${props => (props.$isExpanded ? 'none' : props.$lines)};
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease-out;
    position: relative;
`;

export const TextContent = styled.div`
    font-size: 14.4px;
    font-weight: 400;
    line-height: 20px;
    a {
        color: ${props => props.theme.tokens['primary-interactive-01']};
    }
`;

export const MoreButton = styled.button`
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: inherit;
    position: absolute;
    bottom: 0;
    right: 0;

    font-size: 14.4px;
    height: 1em;
    line-height: 1;
    isolation: isolate;

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: -2ch;
        width: calc(100% + 2ch);
        height: 100%;
        background: linear-gradient(
            to right,
            transparent,
            ${props => props.theme.legacyScss['theme-background']} 4ch
        );
    }

    color: ${props => props.color};

    padding-left: 1.1em;
    bottom: 0.25ch;
    font-weight: 400;

    &:hover {
        span {
            opacity: 0.7;
        }
    }
    span {
        color: ${({ color }) => color};
        cursor: pointer;
        font-weight: 500;
        padding-left: 5px;
    }
`;
