import * as Sentry from '@sentry/browser';
import { DEPLOYMENT_ENV, LISTS_URL, STATIC_URL } from 'settings';
import { isAnalyticsOptOutEnabled } from '../redux/reducers/selectors';
import { store } from '../store';

const hasName = (object: unknown): object is { name: string } => {
    return typeof object === 'object' && object !== null && 'name' in object;
};

const hasMessage = (object: unknown): object is { message: string } => {
    return typeof object === 'object' && object !== null && 'message' in object;
};

/*
 * Return the passed event to allow the Web Player to send it to Sentry.
 * Return null to drop the event.
 * https://docs.sentry.io/error-reporting/configuration/filtering/?platform=browser#before-send
 */
export const beforeSendHook = (event: Sentry.ErrorEvent, hint?: Sentry.EventHint) => {
    const error = hint?.originalException;
    const errorName = hasName(error) ? error.name : '';
    const errorMessage = hasMessage(error) ? error.message : error?.toString();

    // If the user has opted out of analytics, don't send the event to Sentry
    if (isAnalyticsOptOutEnabled(store.getState())) {
        console.info('SENTRY: Skipping event due to analytics opt-out', event, hint);
        return null;
    }

    if (DEPLOYMENT_ENV !== 'production') {
        console.error(
            `Sentry ${hint?.originalException || hint?.syntheticException}`,
            hint?.captureContext,
        );
        return null; // this drops the event and nothing will be sent to sentry
    }

    if (errorName === 'AbortError') {
        // AbortErrors happen for all kinds of reasons, mostly as a result of user action — like playing an
        // episode while another is playing, or pausing an episode while it's still preparing to play. In cases
        // where it's not user-initiated — like stopping because the media file could not be parsed — another
        // more relevant error is also thrown. So capturing these costs hundreds of events per day without benefit.
        return null;
    }

    // The old web player ran on Angular, and a number of users still seems to have browser
    // extensions installed that depend on it, resulting in this error message.
    if (errorMessage && errorMessage.match(/angular is not defined/i)) {
        return null;
    }

    return event;
};

export const beforeBreadcrumbHook = (breadcrumb: Sentry.Breadcrumb) => {
    if (breadcrumb.category === 'fetch') {
        // Filter out some noisy fetches that don't provide much diagnostic value
        const url: string = breadcrumb.data?.url || '';
        if (
            url.startsWith(`${STATIC_URL}/discover/images/metadata/`) ||
            url.startsWith(`${LISTS_URL}/`)
        ) {
            return null;
        }
    }
    return breadcrumb;
};
