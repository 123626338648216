import styled from 'styled-components';
import { gridSize, preventFlexboxOverflow, Typography, visuallyHidden } from 'styles';

const HORIZONTAL_PADDING = 3 * gridSize;

export const Fieldset = styled.fieldset`
    ${preventFlexboxOverflow}
    margin: 0 -${HORIZONTAL_PADDING}px;
    width: 400px;
    max-height: 260px;
    overflow-y: auto;
    border: 1px solid ${props => props.theme.tokens['primary-ui-05']};
    border-width: 1px 0;
    legend {
        ${visuallyHidden}
    }
`;

export const Label = styled.label`
    display: block;
    position: relative;
    input {
        ${visuallyHidden}
        position: absolute;
        height: 100%;
    }
`;

export const Option = styled.div<{ color?: string }>`
    ${Typography.Desktop.H70}
    /* color: ${props => props.color}; */
    display: flex;
    align-items: center;
    padding: ${gridSize}px ${HORIZONTAL_PADDING / 2}px ${gridSize}px ${HORIZONTAL_PADDING}px;
    cursor: pointer;
    &:hover,
    input:focus + & {
        background: ${props => props.theme.tokens['primary-ui-01-active']};
    }
`;

export const OptionText = styled.div`
    flex: 1;
    margin: 0 ${2 * gridSize}px;
    ${preventFlexboxOverflow}
`;

export const OptionTitle = styled.div`
    ${Typography.Desktop.H65}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const OptionCount = styled.div`
    color: ${props => props.theme.tokens['primary-text-02']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Checkbox = styled.div`
    width: 24px;
    height: 24px;
    border: 2px solid ${props => props.theme.tokens['primary-icon-01']};
    border-radius: 50%;
    transition:
        border 0.075s ease-out,
        transform 0.075s ease-out;
    position: relative;
    margin-left: auto;
    flex-shrink: 0;
    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        color: ${props => props.theme.tokens['primary-interactive-02']};
        transform: translate(-50%, -50%);
        display: none;
    }

    input:checked + ${Option} & {
        border-width: 12px;
        svg {
            display: block;
        }
    }
`;
