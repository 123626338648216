import React from 'react';
import { FormattedMessage } from 'react-intl';
import useFormatMessage from '../../../hooks/useFormatMessage';
import {
    POCKET_CASTS_PRIVACY_POLICY,
    POCKET_CASTS_TERMS_OF_USE,
} from '../../../model/external-links';
import { TermsAgreement } from './LoginRegisterForm.styled';

export const SignUpTerms = () => {
    const formatMessage = useFormatMessage();
    return (
        <TermsAgreement>
            <FormattedMessage
                id="terms-agreement-wrapper"
                values={{
                    terms: (
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={POCKET_CASTS_TERMS_OF_USE}
                        >
                            {formatMessage('terms-and-conditions')}
                        </a>
                    ),
                    privacy: (
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={POCKET_CASTS_PRIVACY_POLICY}
                        >
                            {formatMessage('privacy-policy')}
                        </a>
                    ),
                }}
            />
        </TermsAgreement>
    );
};
