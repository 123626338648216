import { TrackOnMount } from 'components/Tracks';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useCallback } from 'react';
import { Switch } from '../../../components/index';
import useTracks from '../../../hooks/useTracks';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import { getSettings } from '../../../redux/reducers/selectors';
import {
    FilesSettings,
    Setting,
    SettingSwitchWrapper,
    SettingTitle,
    SettingsFilesTitle,
    SettingsFilesWrapper,
} from './SettingsFiles.styled';

const SettingsFiles = () => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const { filesAfterPlayingDeleteCloud, filesAutoUpNext } = useSelector(getSettings);
    const { recordEvent } = useTracks();

    const onTogglFilesAfterPlayingDeleteCloud = useCallback(() => {
        dispatch(
            fromSettingsActions.Actions.saveFilesAfterPlayingDeleteCloud(
                !filesAfterPlayingDeleteCloud,
            ),
        );
        recordEvent('settings_files_delete_cloud_file_after_playing_toggled', {
            enabled: !filesAfterPlayingDeleteCloud,
        });
    }, [dispatch, filesAfterPlayingDeleteCloud, recordEvent]);

    const onTogglFilesAutoUpNext = useCallback(() => {
        dispatch(fromSettingsActions.Actions.saveFilesAutoUpNext(!filesAutoUpNext));
        recordEvent('settings_files_auto_add_up_next_toggled', { enabled: !filesAutoUpNext });
    }, [dispatch, filesAutoUpNext, recordEvent]);

    return (
        <SettingsFilesWrapper>
            <TrackOnMount event="settings_files_shown" />
            <SettingsFilesTitle>{formatMessage('files')}</SettingsFilesTitle>
            <FilesSettings>
                <Setting>
                    <SettingTitle>
                        {formatMessage('settings-add-new-files-to-up-next')}
                    </SettingTitle>
                    <SettingSwitchWrapper>
                        <Switch
                            on={filesAutoUpNext}
                            onChange={onTogglFilesAutoUpNext}
                            color="#03A9F4"
                        />
                    </SettingSwitchWrapper>
                </Setting>
                <Setting>
                    <SettingTitle>
                        {formatMessage('settings-delete-cloud-file-after-playing')}
                    </SettingTitle>
                    <SettingSwitchWrapper>
                        <Switch
                            on={filesAfterPlayingDeleteCloud}
                            onChange={onTogglFilesAfterPlayingDeleteCloud}
                            color="#03A9F4"
                        />
                    </SettingSwitchWrapper>
                </Setting>
            </FilesSettings>
        </SettingsFilesWrapper>
    );
};

export default SettingsFiles;
