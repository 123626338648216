import React, { FocusEvent, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SettingsEdit,
    SettingsLabel,
    SettingsTextContainer,
    SettingsUnit,
} from './SettingsNumberText.styled';

type Props = {
    value: string;
    maxCharacters?: number;
    title?: string;
    onSave: (val: number) => void;
    unit?: 'minutes' | 'seconds';
    defaultValue?: number;
    maxValue?: number;
    minValue?: number;
};

function SettingsNumberText({
    value: initialValue,
    title,
    onSave,
    unit = 'seconds',
    defaultValue = 30,
    maxValue,
    minValue,
}: Props) {
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const maxCharacters = maxValue ? maxValue.toString().length : 3;

    const parseEventValue = useCallback(
        (eventValue: string) => {
            let stringValue = eventValue;

            if (stringValue.length === 0) {
                stringValue = defaultValue.toString();
            }

            let numberValue = parseInt(eventValue, 10);

            if (numberValue < 0) {
                numberValue = -numberValue;
            }

            if (isNaN(numberValue)) {
                numberValue = defaultValue;
            }

            if (maxValue && numberValue > maxValue) {
                numberValue = maxValue;
            }

            if (minValue && numberValue < minValue) {
                numberValue = minValue;
            }

            return numberValue;
        },
        [defaultValue, maxValue, minValue],
    );

    const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            // Parse and validate the final value
            const newValue = parseEventValue(event.target.value) ?? defaultValue;

            // Set it as the new local value
            setValue(newValue.toString());

            // Persist the new value
            onSave(newValue);
        },
        [parseEventValue, defaultValue, onSave],
    );

    const handleChange = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const newValue = event.target.value;

            // If the new value doesn't look like a number, do not accept the input
            if (newValue.length > 0 && isNaN(Number(newValue))) {
                return;
            }

            // If the new value is longer than the max characters, do not accept the input
            if (newValue.length > maxCharacters) {
                return;
            }

            setValue(event.target.value);
        },
        [maxCharacters],
    );

    const UnitsMessage = () => {
        // React Intl requires these id's to be statically defined
        if (unit === 'minutes') {
            return <FormattedMessage id="minutes" />;
        }
        return <FormattedMessage id="seconds" />;
    };

    return (
        <SettingsTextContainer className="setting">
            <SettingsLabel>{title || ''}</SettingsLabel>
            <SettingsEdit $maxCharacters={maxCharacters}>
                <input
                    type="text"
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    ref={inputRef}
                />
                <SettingsUnit>
                    <UnitsMessage />
                </SettingsUnit>
            </SettingsEdit>
        </SettingsTextContainer>
    );
}

export default SettingsNumberText;
