export const getAudioDuration = (url: string) => {
    return new Promise<number>((resolve, reject) => {
        const audio = new Audio(url);

        audio.addEventListener('loadedmetadata', () => {
            resolve(audio.duration); // Duration in seconds
        });

        audio.addEventListener('error', () => {
            reject('Error loading audio file.');
        });

        audio.load(); // Start loading the metadata
    });
};
