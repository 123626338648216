import { zodResolver } from '@hookform/resolvers/zod';
import { BackButton } from 'components/BackButton';
import { Button } from 'components/Button';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { StyledForm, StyledFormError } from 'components/form/form.styled';
import Input from 'components/Input';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useId } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_ERRORS_DELAY, VALID_EMAIL_REGEX } from '../../settings';

type Props = {
    clearUserError: () => void;
    isLoading: boolean;
    isSent: boolean;
    error?: string;
    forgottenPassword: (email: string) => void;
};

type PasswordForgottenInputs = {
    email: string;
};

const schema = (intl: IntlShape) =>
    z
        .object({
            email: z
                .string({ required_error: intl.formatMessage({ id: 'field-required' }) })
                .regex(VALID_EMAIL_REGEX, intl.formatMessage({ id: 'invalid-email' })),
        })
        .required();

function PasswordForgottenPage({
    clearUserError,
    isLoading,
    isSent,
    error,
    forgottenPassword,
}: Props) {
    const formatMessage = useFormatMessage();

    const {
        register,
        handleSubmit,
        getFieldState,
        formState: { errors, isValid },
    } = useForm<PasswordForgottenInputs>({
        resolver: zodResolver(schema(useIntl())),
        mode: 'onChange',
        delayError: FORM_ERRORS_DELAY,
    });

    // Clear errors on unmount
    useEffect(
        () => () => {
            clearUserError?.();
        },
        [clearUserError],
    );

    const onSubmit: SubmitHandler<PasswordForgottenInputs> = data => {
        const { email } = data;
        forgottenPassword(email);
    };

    const emailInputId = useId();

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <TrackOnMount event="forgot_password_shown" />
            <TrackOnUnmount event="forgot_password_dismissed" />
            <Helmet>
                <title>{formatMessage('forgot-your-password')}</title>
            </Helmet>

            <BackButton buttonText={formatMessage('back')} />

            <h1>{formatMessage('forgot-password')}</h1>

            <p>
                {formatMessage('forgotten-password-subtitle-1')}{' '}
                {formatMessage('forgotten-password-subtitle-2')}
            </p>

            {isSent && (
                <CredentialNotification variant="success">
                    {formatMessage('forgotten-password-confirmation')}
                </CredentialNotification>
            )}

            {!isSent && error && <CredentialNotification>{error}</CredentialNotification>}

            <label htmlFor={emailInputId}>{formatMessage('email')}</label>
            <Input
                id={emailInputId}
                aria-label="Email"
                type="email"
                status={
                    getFieldState('email').isDirty ? (errors.email ? 'error' : 'ok') : undefined
                }
                {...register('email')}
            />
            <StyledFormError>{errors.email?.message}</StyledFormError>

            <Button type="submit" kind="primary" loading={isLoading} disabled={isValid === false}>
                {formatMessage('send')}
            </Button>
        </StyledForm>
    );
}

export default PasswordForgottenPage;
