import { EpisodeCardLarge } from 'components/EpisodeCard';
import { EpisodeDiscover } from 'model/types';
import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from '../../../hooks/react-redux-typed';
import * as fromPlayerActions from '../../../redux/actions/player.actions';
import { RootState } from '../../../redux/reducers';
import { getPlayingEpisode } from '../../../redux/reducers/selectors';
import urls from '../../../urls';

type Props = {
    episode: EpisodeDiscover;
    color?: string;
    onEpisodeClick?: (podcastUuid: string, episodeUuid: string) => void;
    onEpisodePlay?: (podcastUuid: string, episodeUuid: string) => void;
    onPodcastClick: (uuid: string) => void;
};

const DiscoverEpisodeLarge = ({
    episode,
    color,
    onEpisodeClick,
    onEpisodePlay,
    onPodcastClick,
}: Props) => {
    const isPlaying = useSelector(
        (state: RootState) => getPlayingEpisode(state)?.uuid === episode.uuid,
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openEpisode = () => {
        onEpisodeClick?.(episode.podcast_uuid, episode.uuid);
        navigate(urls.episodePath(episode.podcast_uuid, episode.uuid));
    };

    const playOrPause = () => {
        if (isPlaying) {
            dispatch(fromPlayerActions.Actions.pause({ eventSource: 'discover' }));
            return;
        }
        onEpisodePlay && onEpisodePlay(episode.podcast_uuid, episode.uuid);
        dispatch(
            fromPlayerActions.Actions.playEpisode(episode.uuid, episode.podcast_uuid, {
                eventSource: 'discover',
            }),
        );
    };

    return (
        <EpisodeCardLarge
            podcastUuid={episode.podcast_uuid}
            podcastTitle={episode.podcast_title}
            title={episode.title}
            published={episode.published}
            duration={episode.duration}
            type={episode.type}
            color={color}
            isPlaying={isPlaying}
            onClick={openEpisode}
            onPlayPauseClick={playOrPause}
            onPodcastClick={() => onPodcastClick(episode.podcast_uuid)}
        />
    );
};

export default DiscoverEpisodeLarge;
