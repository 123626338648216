import { useSelector } from 'hooks/react-redux-typed';
import React, { useEffect, useState } from 'react';
import cacheApi from 'services/cacheApi';
import { getEpisodeShowNotes } from '../../../../redux/reducers/selectors/episode-show-notes.selector';
import { ShowNotesWrapper } from './ShowNotes.styled';

type Props = {
    episodeUuid: string;
    className?: string;
};

function ShowNotes({ episodeUuid, className }: Props) {
    const showNotesHtml = useShowNotes(episodeUuid);
    const hasShowNotes = showNotesHtml.length > 0;
    const noHtml = hasShowNotes && showNotesHtml.indexOf('<') === -1;
    return (
        <ShowNotesWrapper
            className={className}
            $downloaded={hasShowNotes}
            $noHtml={noHtml}
            dangerouslySetInnerHTML={{ __html: showNotesHtml }}
        />
    );
}

function useShowNotes(episodeUuid: string) {
    const existingShowNotes = useSelector(state =>
        getEpisodeShowNotes(state, episodeUuid),
    )?.showNotes;
    const [showNotesHtml, setShowNotesHtml] = useState('');

    useEffect(() => {
        let isMounted = true;
        if (!existingShowNotes) {
            // There's no show notes existing in state for this episode, let's fetch them!
            setShowNotesHtml('');
            cacheApi.getShowNotes(episodeUuid).then(data => {
                if (isMounted) {
                    setShowNotesHtml(data as string);
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [episodeUuid, existingShowNotes]);
    return existingShowNotes ?? showNotesHtml;
}

export default ShowNotes;
