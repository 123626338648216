import { PodcastTintColors } from 'model/types';
import { Link } from 'react-router';
import styled from 'styled-components';
import { fontFamilyRetina, gridSize, preventFlexboxOverflow } from 'styles';
import { MOBILE_WIDTH } from '../../../model/page';

export const ImageLink = styled(Link)`
    width: 160px;
    flex-shrink: 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 16px 0;
    }
`;

export const PodcastLink = styled(Link)`
    cursor: pointer;
    max-width: 100%;

    font-family: ${fontFamilyRetina};
    font-style: normal;
    font-size: 14px;
    line-height: 144%;

    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        opacity: 0.7;
    }

    transition: all 0.1s ease;
`;

export const EpisodeDialog = styled.div`
    cursor: default;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 768px;
    min-height: 270px;
    max-height: 600px;
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    z-index: 1002;
    ${props => `background: ${props.theme.tokens['primary-ui-01']}`};
`;

export const EpisodePopupContainer = styled.div<{ $colors: PodcastTintColors }>`
    --tint-color: ${props => props.theme.getLightOrDarkTint(props.$colors)};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: ${5 * gridSize}px;
    z-index: 21;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.legacyScss['fixme-popup-background']};
    cursor: pointer;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fade-and-slide-in {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        animation: fade-in 200ms ease;

        ${EpisodeDialog} {
            animation: fade-and-slide-in 200ms ease;
            animation-fill-mode: backwards;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    margin: 32px 32px 16px 32px;
`;

export const TitleBox = styled.div`
    margin-left: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${preventFlexboxOverflow}
`;

export const EpisodeSeason = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 144%;

    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const EpisodeTitle = styled.h1<{ $titleLength: number }>`
    margin: 0 0 16px;
    font-family: ${fontFamilyRetina};
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.2px;
    overflow-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    max-width: 100%;
    text-wrap-style: pretty;

    ${props =>
        props.$titleLength > 200 &&
        `
    font-size: 24px;
    `}
    color: ${props => props.theme.tokens['primary-text-01']};
`;

export const ScrollBox = styled.div`
    // When ScrollBox is present we want it to take up as much space as it can
    height: 2000px;
    max-height: 100%;
    padding: 14px 32px 0 32px;
    overflow-y: auto;
    &:after {
        clear: both;
        content: '';
        display: block;
    }
`;

export const Separator = styled.div`
    position: relative;
    width: 100%;
    top: 0;
    border-bottom: 1px solid ${props => props.theme.tokens['primary-ui-05']};
`;
