import { useEffect } from 'react';

/**
 * This is a singleton that stores the current match path from React Router, e.g. "/podcasts/:uuid"
 *
 * See: https://v5.reactrouter.com/web/api/match
 *
 * The current match must be pulled from React Context, which is why we need a component
 * to watch for changes and store them somewhere globally accessible. We could store it in
 * Redux, but we don't expect components to change in reaction to the current match path
 * being updated, so the extra boilerplate doesn't seem worth it.
 */
let currentMatchPath: string | undefined;

/**
 * Any file can call getCurrentMatchPath() to get the route path that matches the
 * current URL,e.g. "/podcasts/:uuid"
 */
export const getCurrentMatchPath = () => currentMatchPath ?? window.location.pathname;

/**
 * This component renders nothing, but it watches for changes to the router's match path
 * and stores them on the singleton currentMatchPath.
 */
const RouteMatchMonitor = () => {
    // Update the singleton when the match path changes
    // TODO: Test this still works
    useEffect(() => {
        currentMatchPath = location.pathname;
    }, [location.pathname]);

    // Clear the singleton on unmount
    useEffect(
        () => () => {
            currentMatchPath = undefined;
        },
        [],
    );
    return null;
};

export default RouteMatchMonitor;
