import React from 'react';
import { useLocation } from 'react-router';
import { useSessionStorage } from 'react-use';
import { isMobile, isMobileAndroid, isMobileIos } from '../../helper/Browser';
import useFormatMessage from '../../hooks/useFormatMessage';
import useTracks from '../../hooks/useTracks';
import useUrlClipParam from '../../hooks/useUrlClipParam';
import {
    AndroidDownloadIcon,
    IosDownloadIcon,
} from '../../pages/SettingsPage/SettingsApps/SettingsApps.styled';
import { TrackOnMount } from '../Tracks';
import MockupImage from './images/app_mockup.webp';
import { MobileOverlayWrapper } from './MobileOverlay.styled';

export function MobileOverlay() {
    const isNoAndroidNoriOS = !isMobileAndroid() && !isMobileIos();
    const formatMessage = useFormatMessage();
    const [continueOnWeb, setContinueOnWeb] = useSessionStorage('continueOnWeb', false);
    const { recordEvent } = useTracks();
    const location = useLocation();
    const { isClip } = useUrlClipParam();

    if (!isMobile()) {
        return null;
    }

    if (isClip) {
        return null;
    }

    if (continueOnWeb) {
        return null;
    }

    // Don't show overlay on discover or podcasts pages
    if (location.pathname.startsWith('/discover') || location.pathname.startsWith('/podcasts/')) {
        return null;
    }

    return (
        <MobileOverlayWrapper>
            <TrackOnMount event="interstitial_shown" />
            <div className="app-image">
                <img src={MockupImage} alt={formatMessage('app-image-description')} />
            </div>
            <h1>{formatMessage('download-app')}</h1>
            <p className="description">{formatMessage('download-app-description')}</p>
            <p className="download-links">
                {(isMobileAndroid() || isNoAndroidNoriOS) && (
                    <AndroidDownloadIcon
                        onClick={() =>
                            recordEvent('interstitial_instal_app_button_tapped', {
                                store: 'google',
                            })
                        }
                        href="https://pocketcasts.com/get?source=webplayer&campaign=interstitial"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt={formatMessage('google-play-badge-alt')}
                            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                        />
                    </AndroidDownloadIcon>
                )}

                {(isMobileIos() || isNoAndroidNoriOS) && (
                    <IosDownloadIcon
                        onClick={() =>
                            recordEvent('interstitial_instal_app_button_tapped', {
                                store: 'apple',
                            })
                        }
                        href="https://pocketcasts.com/get?source=webplayer&campaign=interstitial"
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                )}
            </p>
            <p className="description">
                <button type="button" onClick={() => setContinueOnWeb(true)}>
                    {formatMessage('download-app-skip')}
                </button>
            </p>
        </MobileOverlayWrapper>
    );
}
