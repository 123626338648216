import { IconId } from 'components/Icon';
import { TranslatableStringKey } from 'translations/glotpress';
import urls from '../urls';

export type NavigationItem = {
    id: number;
    path: string;
    icon?: IconId;
    text: TranslatableStringKey;
};

export const NavigationItems: Record<string, NavigationItem> = {
    PODCASTS: {
        id: 1,
        path: urls.podcastsPath,
        icon: 'grid-small',
        text: 'podcasts',
    },
    DISCOVER: {
        id: 2,
        path: urls.discoverPath,
        icon: 'discover',
        text: 'discover',
    },
    NEW_RELEASES: {
        id: 3,
        path: urls.newReleasesPath,
        icon: 'sparkle',
        text: 'new-releases',
    },
    IN_PROGRESS: {
        id: 4,
        path: urls.inProgressPath,
        icon: 'in-progress',
        text: 'in-progress',
    },
    STARRED: {
        id: 5,
        path: urls.starredPath,
        icon: 'star-full-small',
        text: 'starred',
    },
    BOOKMARKS: {
        id: 6,
        path: urls.bookmarksPath,
        icon: 'bookmark',
        text: 'bookmarks',
    },
    HISTORY: {
        id: 7,
        path: urls.historyPath,
        icon: 'history',
        text: 'history',
    },
    SETTINGS: {
        id: 8,
        path: urls.settingsPath,
        text: 'settings',
    },
    PROFILE: {
        id: 9,
        path: urls.profilePath,
        icon: 'profile',
        text: 'profile',
    },
    STATS: {
        id: 10,
        path: urls.statsPath,
        text: 'stats',
    },
    UPLOADED_FILES: {
        id: 11,
        path: urls.uploadedFilesPath,
        icon: 'files',
        text: 'files',
    },
    INVITE: {
        id: 12,
        path: urls.invitePath,
        icon: 'gift',
        text: 'invite-friends',
    },
    CREATE_ACCOUNT: {
        id: 13,
        path: urls.registerPath(),
        icon: 'profile',
        text: 'create-account',
    },
    SIGN_IN: {
        id: 14,
        path: urls.signInPath(),
        icon: 'signin',
        text: 'sign-in',
    },
};
