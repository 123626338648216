import { RowContent as BaseRowContent, RowCell } from 'components/Row';
import styled from 'styled-components';
import { gridSize } from 'styles';
export const DurationWrapper = styled.div`
    margin-right: 88px;
`;

export const RowTitleContainer = styled.div`
    display: flex;
`;

export const SelectEpisode = styled.label`
    cursor: pointer;
    padding: 4px;
    margin-right: ${gridSize}px;
`;

export const TitleButton = styled.button`
    &:disabled {
        cursor: default;
    }
`;

export const RowContent = styled(BaseRowContent)`
    // This matches previous style for chapters
    // Additional 1px makes the border look a bit thicker
    // so that they don't collapse on each other.
    margin-top: -1px;
    overflow: visible;
`;

export const LastRowCell = styled(RowCell)`
    margin-right: 0;
`;
