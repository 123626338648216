import { Logger } from 'helper/Logger';
import { RootState } from '..';
import { getSettings } from './settings.selectors';

/**
 * We set user.email when the user has been authenticated, whether or not they have a paid plan.
 * So this value is a proxy for whether the user is logged in or not.
 */
export const userIsLoggedIn = (state: RootState) => !!state.user.email;

export const getDebugInfo = (state: RootState) => {
    const settings = getSettings(state);
    let debug = '';
    debug += `### User agent ###\n${navigator.userAgent}\n\n`;
    debug += `### Settings ###\n${JSON.stringify(settings, null, 2)}\n\n`;
    debug += `### Logs ###\n${Logger.getLogsAsString()}`;
    return debug;
};

export const getJustSignedUp = (state: RootState) => state.user.justSignedUp;

export const isDeleteAccountInProgress = (state: RootState) =>
    !!state.user.accountDeleteResult.inProgress;

export const getDeleteAccountErrorMessage = (state: RootState) =>
    state.user.accountDeleteResult.message;

export const getDeleteAccountSuccess = (state: RootState) =>
    !!state.user.accountDeleteResult.success;
