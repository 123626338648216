import React from 'react';
import { EpisodesTableProps } from '.';
import { PlayingStatus } from '../../helper/PlayingStatus';
import { Episode } from '../../model/types';
import { StarButton } from '../buttons/StarButton';
import { EpisodeTypeText } from '../format/EpisodeTypeText';
import HideOnMobile from '../responsive/HideOnMobile';
import VirtualTable from '../VirtualTable/VirtualTable';
import { EpisodeButtons } from './columns/EpisodeButtons';
import { EpisodeDateTypeWrapper } from './columns/EpisodeDateTypeWrapper';
import { EpisodeDuration } from './columns/EpisodeDuration';
import { EpisodeTitle, PrimaryLine, UpNextIcon } from './columns/EpisodeInfo';
import { EpisodeInfo } from './columns/EpisodeInfo.styled';
import { PodcastEpisodeRow, TableWrapper } from './ListEpisodes.styled';
import { useEpisodeActions } from './useEpisodeActions';
import { getFauxEpisodeSync, getPercentComplete } from './utils';

const ListPodcastEpisodes = ({
    episodes,
    eventSource: eventSourceProp,
    ...props
}: EpisodesTableProps) => {
    const eventSource = eventSourceProp ?? 'podcast_screen';
    const actions = useEpisodeActions(eventSource);
    const {
        autoplay,
        color,
        upNextEpisodes,
        showSyncActions = true,
        uuidToEpisodeSync,
        isPlaying,
        playerEpisodeUuid,
        playerPlayedUpTo,
        inHistory,
        onEpisodeClick,
    } = props;

    return (
        <TableWrapper>
            <VirtualTable
                items={episodes}
                renderItem={(episode: Episode) => {
                    const episodeSync = getFauxEpisodeSync(episode, uuidToEpisodeSync);
                    const isInUpNext = !!upNextEpisodes[episode.uuid];
                    const isInactive =
                        episodeSync.isDeleted ||
                        episodeSync.playingStatus === PlayingStatus.COMPLETED;
                    return (
                        <PodcastEpisodeRow
                            className="episode-row"
                            onClick={() => onEpisodeClick(episode)}
                            role="row"
                            tabIndex={0}
                        >
                            <EpisodeInfo>
                                <PrimaryLine>
                                    {isInUpNext && (
                                        <UpNextIcon id="up-next" size={24} aria-label="Up Next" />
                                    )}
                                    <HideOnMobile>
                                        <EpisodeTypeText
                                            season={episode.season}
                                            number={episode.number}
                                            type={episode.type}
                                        />
                                    </HideOnMobile>
                                    <EpisodeTitle $inactive={isInactive} $tintColor={color}>
                                        {episode.title}
                                    </EpisodeTitle>
                                    {showSyncActions && (
                                        <StarButton
                                            on={episodeSync.starred}
                                            onClick={e => {
                                                e.stopPropagation();
                                                actions.handleStarEpisode(
                                                    episode,
                                                    !episodeSync.starred,
                                                );
                                            }}
                                        />
                                    )}
                                </PrimaryLine>
                            </EpisodeInfo>
                            <EpisodeDateTypeWrapper episode={episode} inactive={isInactive} />
                            <EpisodeDuration
                                inactive={isInactive}
                                duration={episodeSync.duration}
                                playedUpToSecs={episodeSync.playedUpTo}
                                playingStatus={episodeSync.playingStatus}
                            />
                            <EpisodeButtons
                                episodeUuid={episode.uuid}
                                podcastUuid={'podcastUuid' in episode ? episode.podcastUuid : ''}
                                title={episode.title}
                                duration={episodeSync.duration}
                                url={episode.url}
                                isDeleted={episodeSync.isDeleted}
                                playingStatus={episodeSync.playingStatus}
                                isPlaying={isPlaying}
                                autoplay={autoplay}
                                actions={actions}
                                upNextEpisodes={upNextEpisodes}
                                showSyncActions={showSyncActions}
                                color={color}
                                isPlayerEpisode={playerEpisodeUuid === episode.uuid}
                                inHistory={inHistory ?? false}
                                percentComplete={getPercentComplete(
                                    playerPlayedUpTo ?? 0,
                                    episodeSync.playedUpTo,
                                    episode.uuid,
                                    episodeSync.duration,
                                    playerEpisodeUuid,
                                )}
                                isStarred={episodeSync.starred}
                            />
                        </PodcastEpisodeRow>
                    );
                }}
            />
        </TableWrapper>
    );
};

export default ListPodcastEpisodes;
