import { withRouter } from 'pages/PodcastPage/PodcastPage';
import { connect } from 'react-redux';
import * as fromEpisodeActions from '../../redux/actions/episode.actions';
import * as fromFilterActions from '../../redux/actions/filter.actions';
import * as fromHistoryActions from '../../redux/actions/history.actions';
import * as fromTracksActions from '../../redux/actions/tracks.actions';
import { getFilterEpisodesWithSync } from '../../redux/reducers/selectors/filter.selectors';
import FilterPage from './view';

const mapStateToProps = (state, ownProps) => ({
    episodes: getFilterEpisodesWithSync(state, ownProps.id),
    settings: state.settings,
    filter: state.filter,
    uuidToPodcast: state.podcasts.uuidToPodcast,
    upNext: state.upNext,
    player: state.player,
    inHistory: ownProps.id === 'history',
});

const mapDispatchToProps = dispatch => ({
    downloadFilter: id => dispatch(fromFilterActions.Actions.downloadFilter(id)),
    openEpisode: (episode, options) =>
        dispatch(fromEpisodeActions.Actions.openEpisode(episode, options)),
    showClearHistoryConfirmation: () => {
        dispatch(
            fromTracksActions.Actions.recordEvent('listening_history_clear_history_button_tapped'),
        );
        dispatch(fromHistoryActions.Actions.showClearHistoryConfirmation());
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterPage));
