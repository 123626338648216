import { Button } from 'components/Button';
import { ButtonLink } from 'components/ButtonLink';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { FormEventHandler } from 'react';
import * as fromUserActions from '../../redux/actions/user.actions';
import { getEmail, hasAutoRenewingSubscription } from '../../redux/reducers/selectors';
import {
    getDeleteAccountErrorMessage,
    getDeleteAccountSuccess,
    isDeleteAccountInProgress,
} from '../../redux/reducers/selectors/user.selectors';
import DeleteAccountFormStyled, {
    ButtonsWrapper,
    Separator,
    VerticalSpace,
} from './DeleteAccountForm.styled';

interface Props {
    onCancel?: () => void;
    showCancel?: boolean;
}

const DeleteAccountForm = ({ onCancel, showCancel }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const email = useSelector(getEmail);
    const isStillSubscribed = useSelector(hasAutoRenewingSubscription);
    const isDeleting = useSelector(isDeleteAccountInProgress);
    const isDeleted = useSelector(getDeleteAccountSuccess);
    const error = useSelector(getDeleteAccountErrorMessage);

    const handleFormSubmit: FormEventHandler = e => {
        e.preventDefault();
        dispatch(fromUserActions.Actions.requestAccountDelete());
    };

    if (isStillSubscribed) {
        return (
            <DeleteAccountFormStyled>
                <h1>{formatMessage('delete-account')}</h1>
                <p>
                    {formatMessage('delete-account-delete-subscription-description', {
                        email: <strong key="email">{email}</strong>,
                    })}
                </p>
                <ButtonLink onClick={onCancel} kind="secondary" to="/profile">
                    {formatMessage('go-to-my-profile')}
                </ButtonLink>
            </DeleteAccountFormStyled>
        );
    }

    return (
        <DeleteAccountFormStyled onSubmit={handleFormSubmit}>
            <h1>{formatMessage('delete-account')}</h1>
            <p>
                {formatMessage('delete-account-description', {
                    email: <strong key={email}>{email}</strong>,
                })}
            </p>

            {error && !isDeleting ? (
                <CredentialNotification>
                    {formatMessage('unexpected-error', {
                        contactUsLink: (
                            <a href="mailto:support@pocketcasts.com">
                                {formatMessage('contact-us')}
                            </a>
                        ),
                    })}
                </CredentialNotification>
            ) : null}

            <label>
                <input type="checkbox" required />
                {formatMessage('no-undo-acknowledgement')}
            </label>

            <Separator />

            {showCancel && (
                <ButtonsWrapper>
                    <Button
                        type="button"
                        onClick={onCancel}
                        kind="secondary"
                        disabled={isDeleting || isDeleted}
                    >
                        {formatMessage('cancel')}
                    </Button>
                    <VerticalSpace />
                    <Button kind="primary-red" loading={isDeleting || isDeleted}>
                        {formatMessage('delete-my-account')}
                    </Button>
                </ButtonsWrapper>
            )}
            {!showCancel && (
                <Button kind="primary-red" loading={isDeleting || isDeleted}>
                    {formatMessage('delete-my-account')}
                </Button>
            )}
        </DeleteAccountFormStyled>
    );
};

export default DeleteAccountForm;
