import styled, { css } from 'styled-components';
import { isSafari } from '../../helper/Browser';
import { SlidingModalBody } from '../SlidingModal/SlidingModal.styled';

export const TableWrapper = styled.div`
    flex: 1;
    min-height: 0;
    padding-bottom: 50px;
    overflow-y: auto;

    /* Scrollbar-gutter: stable doesn't seem to have any effect in Safari, so we workaround the
    layout shift caused by scrollbars by setting overflow-y: scroll. The scrollbars still don't
    show up in Safari, but the layout shift is avoided. */
    scrollbar-gutter: stable;
    ${isSafari() &&
    css`
        overflow-y: scroll;
    `}

    width: 100%;
    ${SlidingModalBody} & {
        padding-bottom: 0;
        scrollbar-color: ${props => props.theme.tokens['player-contrast-05']} transparent;
        &::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.tokens['primary-ui-05']};
        }
    }
`;

export const VirtualList = styled.div<{ $height: number }>`
    position: relative;
    width: calc(100% - 60px);
    ${isSafari() &&
    css`
        width: calc(100% - 60px + var(--scrollbar-width));
    `}

    height: ${({ $height }) => $height}px;
    flex: 1;
    min-height: 0;
`;

export const VirtualRowWrapper = styled.div<{ $isDragging?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: ${props => (props.$isDragging ? 'none' : 'transform 200ms ease')};
`;
