import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { PaddleDiscountedPlan, PaddlePlan, SubscriptionTier } from '../model/types';
import { USE_SLUMBER_STUDIOS_PARTNERSHIP } from '../settings';
import { gridSize } from '../styles';

const FeatureList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    gap: ${2 * gridSize}px;

    li {
        margin: 0;
        display: flex;
        align-items: baseline;
        line-height: 1.5;
        text-wrap: pretty;

        p {
            margin: 0;
        }

        a {
            color: ${props => props.theme.legacyScss['theme-text-primary']};
        }
    }
    svg {
        margin-right: ${2 * gridSize}px;
        position: relative;
        top: 3px;
        flex-shrink: 0;
    }
`;

const SmallIcon = ({ icon }: { icon: string }) => (
    <svg width={16} height={16} viewBox="0 0 16 16">
        <use xlinkHref={`#${icon}`} />
    </svg>
);

const PlanFeatureList = ({
    tier,
    plan,
    ...props
}: {
    tier: SubscriptionTier;
    plan: PaddlePlan | PaddleDiscountedPlan;
}) => {
    const slumberStudiosLink = 'https://slumberstudios.com';
    const frequency = ['plus-yearly', 'patron-yearly'].includes(plan) ? 'yearly' : 'monthly';

    if (tier === 'Patron') {
        return (
            <FeatureList {...props}>
                <li>
                    <SmallIcon icon="tick-sm" />
                    <FormattedMessage id="everything-in-plus" />
                </li>
                <li>
                    <SmallIcon icon="sparkle-sm" />
                    <FormattedMessage id="early-access-to-features" />
                </li>
                <li>
                    <SmallIcon icon="cloud-drive-sm" />
                    <FormattedMessage id="patron-cloud-storage" />
                </li>
                <li>
                    <SmallIcon icon="user-sm" />
                    <FormattedMessage id="supporters-profile-badge" />
                </li>
                <li>
                    <SmallIcon icon="mobile-device-sm" />
                    <FormattedMessage id="special-pocket-casts-app-icons" />
                </li>
                <li>
                    <SmallIcon icon="heart-outline-sm" />
                    <FormattedMessage id="our-undying-gratitude" />
                </li>
            </FeatureList>
        );
    }
    return (
        <FeatureList {...props}>
            <li>
                <SmallIcon icon="folder-sm" />
                <FormattedMessage id="folders-and-bookmarks" />
            </li>
            <li>
                <SmallIcon icon="check-rounded-sm" />
                <FormattedMessage id="preselect-chapters" />
            </li>
            <li>
                <SmallIcon icon="cloud-drive-sm" />
                <FormattedMessage id="plus-cloud-storage" />
            </li>
            <li>
                <SmallIcon icon="watch-sm" />
                <FormattedMessage id="watch-apps" />
            </li>
            <li>
                <SmallIcon icon="palette-sm" />
                <FormattedMessage id="extra-themes-and-icons" />
            </li>
            {USE_SLUMBER_STUDIOS_PARTNERSHIP && frequency === 'yearly' && (
                <li>
                    <SmallIcon icon="slumber-sm" />
                    <p>
                        <FormattedMessage
                            id="slumber-studios-benefit"
                            values={{
                                slumberStudiosLink: (text: ReactNode) => (
                                    <a
                                        href={slumberStudiosLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </li>
            )}
        </FeatureList>
    );
};

export default PlanFeatureList;
