import styled from 'styled-components';
import { MOBILE_WIDTH } from '../../model/page';

const ShowOnMobile = styled.div`
    display: none;

    @media (max-width: ${MOBILE_WIDTH}px) {
        display: block;
    }
`;

export default ShowOnMobile;
