import { LoaderSquare, PodcastImage } from 'components';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import { useWindowSize } from 'hooks/useWindowSize';
import { PodcastSearchResult } from 'model/types';
import { DiscoverLayout, DiscoverSummaryStyle, PodcastDiscover } from 'model/types/list.types';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import * as fromPodcastsActions from '../../redux/actions/podcasts.actions';
import * as fromSearchActions from '../../redux/actions/search.actions';
import {
    getDiscoverLayouts,
    getSubscribedPodcastUuids,
    getUuidToPodcast,
} from '../../redux/reducers/selectors';
import { getRawSearchResponse } from '../../redux/reducers/selectors/search.selectors';
import { USE_FOLLOW_NAMING } from '../../settings';
import { SubscribeButtonLabel } from './DiscoverScreen.styled';
import {
    DiscoverHeading,
    DiscoverLayoutWrapper,
    DiscoverPodcast,
    PodcastModal,
    PodcastModalHeader,
    SearchInput,
    SearchNoResults,
    SearchResult,
    SearchResultTitle,
    SubscribeButton,
} from './WelcomePage.styled';

// The list of which style of Discover layouts we want to show in onboarding
const ALLOWED_SUMMARY_STYLES = [
    DiscoverSummaryStyle.CAROUSEL,
    DiscoverSummaryStyle.LARGE_LIST,
    DiscoverSummaryStyle.SMALL_LIST,
];

const LayoutCarousel = ({
    layout,
    onSelectPodcast,
}: {
    layout: DiscoverLayout;
    onSelectPodcast: (podcast: PodcastDiscover) => void;
}) => {
    const { recordEvent } = useTracks();
    const formatMessage = useFormatMessage();
    const sliderRef = useRef<Slider>(null);
    const { windowWidth } = useWindowSize();
    const [mouseDownX, setMouseDownX] = useState<number>();

    const numSlides = windowWidth && windowWidth < 600 ? 4 : 5;

    if (!layout.data || !('podcasts' in layout.data) || layout.data.podcasts.length === 0) {
        return null;
    }
    const podcasts = layout.data.podcasts.filter(podcast => !podcast.sponsoredListId);

    return (
        <DiscoverLayoutWrapper>
            <h2>{layout.title}</h2>

            <Slider
                slidesToShow={numSlides}
                slidesToScroll={numSlides}
                arrows={false}
                beforeChange={() => {
                    recordEvent('recommendations_more_tapped', {
                        title: layout.title.toLowerCase(),
                        number_visible: numSlides,
                    });
                }}
                ref={sliderRef}
            >
                {podcasts.map(podcast => (
                    <DiscoverPodcast key={podcast.uuid}>
                        <button
                            onMouseDown={event => setMouseDownX(event.clientX)}
                            onClickCapture={event => {
                                // If the user moved the mouse during the click, they probably intended to
                                // swipe the carousel. In that case we don't want a click to be triggered,
                                // so we stop propagation here during the capture phase.
                                if (mouseDownX !== undefined && event.clientX !== mouseDownX) {
                                    event.stopPropagation();
                                }
                                setMouseDownX(undefined);
                            }}
                            onClick={() => onSelectPodcast(podcast)}
                        >
                            <PodcastImage uuid={podcast.uuid} />
                            <SubscribeButton
                                podcastUuid={podcast.uuid}
                                eventSource="recommendations"
                                circled
                            />
                            <h3>{podcast.title}</h3>
                        </button>
                    </DiscoverPodcast>
                ))}
            </Slider>

            <Button kind="secondary" onClick={() => sliderRef.current?.slickNext()}>
                {formatMessage('more-of-title', {
                    title: ['popular', 'featured', 'trending'].includes(layout.id ?? '')
                        ? layout.title
                        : 'Podcasts',
                })}
            </Button>
        </DiscoverLayoutWrapper>
    );
};

const SearchResults = ({
    term,
    onSelectPodcast,
}: {
    term: string;
    onSelectPodcast: (podcast: PodcastSearchResult) => void;
}) => {
    const formatMessage = useFormatMessage();
    const search = useSelector(state => getRawSearchResponse(state, term));

    if (!search?.podcasts || search.podcasts.length === 0) {
        return (
            <SearchNoResults>
                <h1>{formatMessage('find-podcasts-empty')}</h1>
                {formatMessage('find-podcasts-empty-desc-simple')}
            </SearchNoResults>
        );
    }
    return (
        <>
            {search.podcasts.map(podcast => (
                <SearchResult
                    key={podcast.uuid}
                    onClick={() => onSelectPodcast(podcast)}
                    style={{ position: 'relative' }}
                >
                    <PodcastImage uuid={podcast.uuid} />
                    <SearchResultTitle>
                        <h1>{podcast.title}</h1>
                        <p>{podcast.author}</p>
                    </SearchResultTitle>
                    <SubscribeButton podcastUuid={podcast.uuid} eventSource="recommendations" />
                </SearchResult>
            ))}
        </>
    );
};

const DiscoverScreen = () => {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const [selectedPodcast, setSelectedPodcast] = useState<PodcastDiscover | PodcastSearchResult>();
    const allLayouts = useSelector(getDiscoverLayouts) as DiscoverLayout[];
    const subscribedUuids = useSelector(getSubscribedPodcastUuids);
    const uuidToPodcast = useSelector(getUuidToPodcast);
    const { isSearching, term } = useSelector(state => state.search);
    const layouts = allLayouts?.filter(layout =>
        ALLOWED_SUMMARY_STYLES.includes(layout.summary_style),
    );

    const isSubscribed = selectedPodcast && subscribedUuids.includes(selectedPodcast.uuid);

    let selectedPodcastDescription: string | null = null;
    if (selectedPodcast) {
        selectedPodcastDescription =
            'description' in selectedPodcast
                ? selectedPodcast.description
                : uuidToPodcast[selectedPodcast.uuid]?.description;
    }

    // On unmount, make sure to clear the search term so results don't appear in the main UI
    useEffect(
        () => () => {
            dispatch(fromSearchActions.Actions.setSearchTerm(''));
        },
        [dispatch],
    );

    useEffect(() => {
        // If a podcast has been selected with no description, download the podcast details
        if (selectedPodcast && !selectedPodcastDescription) {
            dispatch(fromPodcastsActions.Actions.downloadPodcast(selectedPodcast.uuid));
        }
    }, [dispatch, selectedPodcast, selectedPodcastDescription]);

    return (
        <>
            <TrackOnMount event="recommendations_shown" />
            <TrackOnUnmount
                event="recommendations_dismissed"
                properties={{ subscriptions: subscribedUuids.length }}
            />
            <DiscoverHeading>
                <h1>{formatMessage('find-your-favourite-podcasts')}</h1>
                <p>{formatMessage('welcome-discover-instructions')}</p>
            </DiscoverHeading>

            <SearchInput>
                <Input
                    type="search"
                    placeholder={formatMessage('search')}
                    icon="search"
                    autoFocus
                    value={term}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch(fromSearchActions.Actions.setSearchTerm(event.target.value))
                    }
                />
            </SearchInput>

            {!layouts || isSearching ? (
                <LoaderSquare />
            ) : term ? (
                <SearchResults term={term} onSelectPodcast={setSelectedPodcast} />
            ) : (
                layouts.map(layout => (
                    <LayoutCarousel
                        layout={layout}
                        onSelectPodcast={setSelectedPodcast}
                        key={layout.id}
                    />
                ))
            )}
            {selectedPodcast && (
                <Modal useModernStyle onClose={() => setSelectedPodcast(undefined)}>
                    <PodcastModal>
                        <PodcastModalHeader>
                            <PodcastImage uuid={selectedPodcast.uuid} />
                            <div>
                                <h1>{selectedPodcast.title}</h1>
                                {selectedPodcast.author}
                            </div>
                        </PodcastModalHeader>
                        <Button
                            kind={isSubscribed ? 'secondary' : 'primary'}
                            onClick={() => {
                                if (isSubscribed) {
                                    dispatch(
                                        fromPodcastsActions.Actions.unsubscribeFromPodcast(
                                            selectedPodcast.uuid,
                                            { eventSource: 'recommendations' },
                                        ),
                                    );
                                } else {
                                    dispatch(
                                        fromPodcastsActions.Actions.subscribeToPodcast(
                                            selectedPodcast,
                                            { eventSource: 'recommendations' },
                                        ),
                                    );
                                }
                            }}
                            small
                        >
                            <SubscribeButtonLabel>
                                <Icon id={isSubscribed ? 'tick' : 'plus'} size={20} />
                                {USE_FOLLOW_NAMING
                                    ? formatMessage(isSubscribed ? 'following' : 'follow')
                                    : formatMessage(isSubscribed ? 'subscribed' : 'subscribe')}
                            </SubscribeButtonLabel>
                        </Button>
                        {selectedPodcastDescription ? (
                            <p>{selectedPodcastDescription}</p>
                        ) : (
                            <LoaderSquare />
                        )}
                    </PodcastModal>
                </Modal>
            )}
        </>
    );
};
export default DiscoverScreen;
