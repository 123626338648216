import { BookmarkCountIcon } from 'components/BookmarkCountIcon';
import { isAppleDevice } from 'helper/Browser';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import key, { KeyHandler } from 'keymaster';
import { ESCAPE_KEY_PRESSED_EVENT } from 'model/page';
import React, { useCallback, useEffect, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { addBookmark } from '../../../../redux/actions/episode-sync.actions';
import {
    getBookmarksForEpisode,
    getDefaultBookmarkTitleForEpisode,
} from '../../../../redux/reducers/selectors/episode-sync.selectors';
import { IconButton, Wrapper } from './AddBookmarkButton.styled';
import { BookmarkPopoverContent } from './BookmarkPopoverContent';

export type Props = {
    time: number;
    episodeUuid: string;
    podcastUuid: string;
};

const AddBookmarkButton = ({ time, episodeUuid, podcastUuid, ...props }: Props) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const bookmarks = useSelector(state => getBookmarksForEpisode(state, episodeUuid));
    const defaultBookmarkTitle = useSelector(state =>
        getDefaultBookmarkTitleForEpisode(state, episodeUuid, formatMessage('bookmark')),
    );
    const [addAtTimestamp, setAddAtTimestamp] = useState<number>();

    const closePopover = useCallback(() => setAddAtTimestamp(undefined), []);

    const handleAddBookmarkShortcut = useCallback<KeyHandler>(
        evt => {
            evt.preventDefault(); // CMD+B is used by some browsers to open the browser bookmarks
            dispatch(
                addBookmark(
                    { episodeUuid, podcastUuid, time, title: defaultBookmarkTitle },
                    { eventSource: 'keyboard_shortcut', showConfirmation: true },
                ),
            );
        },
        [episodeUuid, podcastUuid, time, defaultBookmarkTitle, dispatch],
    );

    useEffect(() => {
        const shortcut = isAppleDevice() ? '⌘+b' : 'ctrl+b';
        window.addEventListener(ESCAPE_KEY_PRESSED_EVENT, closePopover);
        key(shortcut, handleAddBookmarkShortcut);
        return () => {
            window.removeEventListener(ESCAPE_KEY_PRESSED_EVENT, closePopover);
            key.unbind(shortcut);
        };
    }, [closePopover, handleAddBookmarkShortcut]);

    const handleClick = () => {
        if (addAtTimestamp === undefined) {
            setAddAtTimestamp(time);
        } else {
            closePopover();
        }
    };

    return (
        <Wrapper {...props}>
            <IconButton onClick={handleClick} aria-label={formatMessage('add-bookmark')}>
                <BookmarkCountIcon count={bookmarks.length} />
            </IconButton>
            {addAtTimestamp !== undefined && (
                <FocusOn onEscapeKey={closePopover} onClickOutside={closePopover}>
                    <BookmarkPopoverContent
                        time={addAtTimestamp}
                        episodeUuid={episodeUuid}
                        podcastUuid={podcastUuid}
                        bookmarksCount={bookmarks.length}
                        onClose={closePopover}
                    />
                </FocusOn>
            )}
        </Wrapper>
    );
};

export default AddBookmarkButton;
