import { DiscoverPodcast, DiscoverSection, LoaderRound } from 'components';
import { EpisodesTable } from 'components/EpisodesTable';
import { FolderImage } from 'components/FolderImage';
import { Icon } from 'components/Icon';
import { GridItem } from 'components/discover/GridItem';
import { PodcastCount } from 'components/messages';
import { NavigationItems } from 'helper/NavigationHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import useTracks from 'hooks/useTracks';
import {
    EpisodeSearchResult,
    FolderSearchResult,
    PodcastSearchResult,
    SearchHistoryItem,
} from 'model/types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import * as fromSearchActions from '../../redux/actions/search.actions';
import { RootState } from '../../redux/reducers';
import { getPlayingEpisode, getUpNext } from '../../redux/reducers/selectors';
import {
    getMatchingSubscriptions,
    getSearchResponse,
} from '../../redux/reducers/selectors/search.selectors';
import urls from '../../urls';
import { NoResults, SearchHeading, SearchPageWrapper } from './SearchPage.styled';

export type Props = {
    expanded: boolean;
    term: string;
};

const MaybeSearchPage = () => {
    const location = useLocation();
    const term = qs.parse(location.search).q as string | undefined;
    const expandedQueryParam = qs.parse(location.search).expanded as string | undefined;
    const expanded = expandedQueryParam === 'true';

    if (!term) {
        return <Navigate to="/" />;
    }

    return (
        <SearchPage
            term={term}
            expanded={expanded}
            key={`${term}-${String(expanded)}`} // Reset state for the page whenever the term or expanded changes
        />
    );
};

const normalizeEpisodes = (episodes: EpisodeSearchResult[]) =>
    episodes.map(e => ({ ...e, podcastUuid: e.podcast_uuid, podcastTitle: e.podcast_title }));

const PodcastResults = ({
    podcasts,
    onItemClick,
    isExpanded,
    onSetExpanded,
}: {
    podcasts: (PodcastSearchResult | FolderSearchResult)[];
    onItemClick: (item: SearchHistoryItem) => void;
    isExpanded: boolean;
    onSetExpanded: (isExpanded: boolean) => void;
}) => {
    const formatMessage = useFormatMessage();
    const [numShown, setNumShown] = useState(podcasts.length);
    const showSeeAllButton = numShown < podcasts.length && !isExpanded;
    const navigate = useNavigate();

    const onGridItemClick = (item: FolderSearchResult) => {
        onItemClick({ folder: item });
        navigate(`${NavigationItems.PODCASTS.path}/folders/${item.uuid}`);
    };

    const onPodcastItemClick = (item: PodcastSearchResult) => {
        onItemClick({ podcast: item });
        navigate(`${NavigationItems.DISCOVER.path}/podcast/${item.uuid}`);
    };

    return (
        <DiscoverSection
            title={formatMessage('podcasts')}
            discoverFormat="grid"
            minWidth={152}
            maxRowCount={isExpanded ? -1 : 1}
            onChangeNumShown={setNumShown}
            onSeeAllClick={showSeeAllButton ? () => onSetExpanded(true) : undefined}
        >
            {podcasts.map(item =>
                'podcastUuids' in item ? (
                    <GridItem
                        key={item.uuid}
                        image={
                            <FolderImage
                                color={item.color}
                                name={item.name}
                                showName={false}
                                podcastUuids={item.podcastUuids}
                                sortType={item.sortType}
                            />
                        }
                        title={item.name}
                        subtitle={<PodcastCount count={item.podcastUuids.length} />}
                        onClick={() => onGridItemClick(item)}
                    />
                ) : (
                    <DiscoverPodcast
                        key={item.uuid}
                        podcast={item}
                        onClick={() => onPodcastItemClick(item)}
                    />
                ),
            )}
        </DiscoverSection>
    );
};

const EpisodeResults = ({
    episodes,
    onItemClick,
}: {
    episodes: EpisodeSearchResult[];
    onItemClick: (item: SearchHistoryItem) => void;
}) => {
    const formatMessage = useFormatMessage();
    const playingEpisode = useSelector(getPlayingEpisode);
    const upNext = useSelector(getUpNext);
    const navigate = useNavigate();

    const handleEpisodeClick = (episode: EpisodeSearchResult) => {
        onItemClick({ episode });

        navigate(urls.episodePath(episode.podcast_uuid, episode.uuid));
    };

    const handlePodcastClick = (podcast: { uuid: string }) => {
        onItemClick({
            podcast: {
                author: '',
                title: '',
                uuid: podcast.uuid,
            },
        });
    };

    const normalizedEpisodes = normalizeEpisodes(episodes);

    return (
        <DiscoverSection title={formatMessage('episodes')} discoverFormat="grid">
            <EpisodesTable
                episodes={normalizedEpisodes}
                upNextEpisodes={upNext.episodes}
                onEpisodeClick={handleEpisodeClick}
                onPodcastClick={handlePodcastClick}
                playerEpisodeUuid={playingEpisode?.uuid}
                isPlaying={!!playingEpisode}
                showSyncActions={false}
                eventSource="search_list"
            />
        </DiscoverSection>
    );
};

const SearchPage = ({ expanded, term }: Props) => {
    const formatMessage = useFormatMessage();
    const { recordSearchResultTapped } = useTracks();
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => getSearchResponse(state, term));
    const matchingSubscriptions = useSelector((state: RootState) =>
        getMatchingSubscriptions(state, term),
    );
    const isLoading = !response?.episodes || !response?.podcasts;

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            const timeout = setTimeout(() => setIsReady(true), 0);
            return () => clearTimeout(timeout);
        }
    }, [isLoading]);

    useScrollRestoration(isReady);

    const navigate = useNavigate();
    const location = useLocation();

    const setExpandPodcasts = (isExpanded: boolean) => {
        navigate(
            `${location.pathname}?${qs.stringify({
                q: term,
                expanded: isExpanded,
            })}`,
        );
    };

    const handleItemClick = (item: SearchHistoryItem) => {
        dispatch(fromSearchActions.Actions.addHistoryItem(item));
        recordSearchResultTapped(item);
    };

    useEffect(() => {
        if (isLoading) {
            // Only fetch remote results if we don't already have them cached when the component mounts or the term changes
            dispatch(fromSearchActions.Actions.fetchSearchResults(term));
        }
        dispatch(fromSearchActions.Actions.addHistoryItem({ term }));
        dispatch(fromSearchActions.Actions.setSearchTerm(term));
    }, [term, dispatch, isLoading]);

    const podcasts = matchingSubscriptions.concat(response?.podcasts || []);
    const episodes = response?.episodes || [];

    return (
        <SearchPageWrapper>
            <Helmet>
                <title>{formatMessage('showing-results-for', { searchTerm: term })}</title>
            </Helmet>
            <SearchHeading>
                <FormattedMessage
                    id="showing-results-for"
                    values={{ searchTerm: <strong>{term}</strong> }}
                />
                {isLoading && <LoaderRound />}
            </SearchHeading>
            {!isLoading && !podcasts.length && !episodes.length && (
                <NoResults>
                    <Icon id="search-alt" size={40} />
                    <h1>{formatMessage('find-podcasts-empty')}</h1>
                    <p>{formatMessage('find-podcasts-empty-desc')}</p>
                </NoResults>
            )}
            {!isLoading && podcasts.length > 0 && (
                <PodcastResults
                    podcasts={podcasts}
                    onItemClick={handleItemClick}
                    isExpanded={expanded && episodes.length > 0}
                    onSetExpanded={setExpandPodcasts}
                />
            )}
            {!isLoading && episodes.length > 0 && (
                <EpisodeResults episodes={episodes} onItemClick={handleItemClick} />
            )}
        </SearchPageWrapper>
    );
};

export default MaybeSearchPage;
