import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }
`;

const Title = styled.span`
    font-size: 18px;
    font-weight: 500;
`;

const SubscribeMessage = () => {
    return (
        <Wrapper>
            <Title>
                <FormattedMessage id="subscribe-banner-title" />
            </Title>
            <FormattedMessage id="subscribe-banner-subtitle" />
        </Wrapper>
    );
};

export default SubscribeMessage;
