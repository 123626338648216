import styled from 'styled-components';
import { yellow50 } from 'styles';
import { VIEWPORT_MINIMUM_WIDTH } from '../../../model/page';
import { Row } from '../../EpisodesTable/ListEpisodes.styled';

export const StarButtonWrapper = styled.span`
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: ${props => props.theme.tokens['primary-text-02']};
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
    &.on {
        opacity: 1;
        color: ${yellow50};
    }

    ${Row} & {
        margin: -3px 0 0 5px;
    }

    // When in an episode row,
    // Hide star button when not hovered and not "on"
    ${Row}:not(:hover) &:not(.on) {
        visibility: hidden;
    }

    .episode-row & {
        @media (max-width: ${VIEWPORT_MINIMUM_WIDTH}px) {
            margin: 0 8px 0 auto;

            &:not(.on) {
                display: none;
            }
        }
    }
`;
