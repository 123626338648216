import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TranslatableStringKey } from 'translations/glotpress';

type Values = {
    [key: string]: any;
};

const useFormatMessage = () => {
    const intl = useIntl();

    return useCallback(
        (id: TranslatableStringKey, values?: Values) => intl.formatMessage({ id }, values),
        [intl],
    );
};

export default useFormatMessage;
