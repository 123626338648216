import { AutoplayConfig, FilterEpisode } from 'model/types';
import { EpisodePopupSource } from '../reducers/episode.reducer';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    OPEN_EPISODE = '@episode/OPEN_EPISODE',
    CLOSE_EPISODE = '@episode/CLOSE_EPISODE',
    RECEIVE_EPISODE_SYNC = '@episode/RECEIVE_EPISODE_SYNC',
}

export const Actions = {
    openEpisode: <T extends { uuid: string; podcastUuid: string }>(
        episode: T,
        options: {
            eventSource: EpisodePopupSource;
            autoplayConfig?: AutoplayConfig;
        },
    ) => createAction(ActionTypes.OPEN_EPISODE, { episode, options }),

    closeEpisode: () => createAction(ActionTypes.CLOSE_EPISODE),

    receiveEpisodeSync: (syncData: FilterEpisode) =>
        createAction(ActionTypes.RECEIVE_EPISODE_SYNC, { syncData }),
};

export type Actions = ActionsUnion<typeof Actions>;
