import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { visuallyHidden } from 'styles';
import { UnplayedBadge } from '../PodcastsPage.styled';

export const AssistiveText = styled.div`
    ${visuallyHidden}
`;

export const Grid = styled.ol<{ $isLarge: boolean; $isSorting: boolean }>`
    display: grid;
    padding: 0;
    gap: ${props => (props.$isLarge ? 16 : 10)}px;
    grid-template-columns: repeat(${props => (props.$isLarge ? 7 : 10)}, minmax(0, 1fr));
    @media only screen and (min-width: 1360px) {
        grid-template-columns: repeat(${props => (props.$isLarge ? 8 : 12)}, minmax(0, 1fr));
    }

    ${props => props.$isSorting && `&, ${TileLink} { cursor: grabbing; }`}

    ${props =>
        !props.$isSorting &&
        // Only show tile hover effects when we're not in the middle of a sort
        `
            ${TileLink}:hover,
            ${TileLink}:focus {
                transform: scale(1.1);
            }
    `}
`;

export const TileWrapper = styled.li`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

export const TileLink = styled(Link)<{ $isDragging: boolean }>`
    display: block;
    position: relative;
    width: 100%;
    transition: transform 100ms ease-out;
    ${props =>
        props.$isDragging &&
        css`
            transform: scale(1.15);
            pointer-events: none;
            ${UnplayedBadge} {
                transform-origin: 95% 5%;
                @keyframes hide-to-corner {
                    0% {
                        transform: scale(1);
                    }
                    100% {
                        transform: scale(0);
                    }
                }
                animation: 100ms hide-to-corner ease-out;
                animation-fill-mode: both;
            }
            @keyframes wobble {
                0% {
                    transform: scale(1.15) rotate(0deg);
                    animation-timing-function: ease-out;
                }
                25% {
                    transform: scale(1.15) rotate(-1.5deg);
                    animation-timing-function: ease-in-out;
                }
                75% {
                    transform: scale(1.15) rotate(1.5deg);
                    animation-timing-function: ease-in;
                }
            }

            @media (prefers-reduced-motion: no-preference) {
                animation: wobble 600ms infinite;
            }
        `}
`;
