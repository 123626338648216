import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeEvery } from 'redux-saga/effects';
import * as fromFlagsActions from '../actions/flags.actions';
import * as fromPodcastActions from '../actions/podcast.actions';
import * as fromUserActions from '../actions/user.actions';

/*
 * Unlike the other Saga files, this file cuts across domains and contains watchers
 * for anything that needs to interact with the Flag system.
 */

function* flagEmailChangeResponse(
    action: ReturnType<typeof fromUserActions.Actions.emailChangeResponse>,
) {
    const { success } = action.payload;

    if (success) {
        yield put(
            fromFlagsActions.Actions.addFlag(<FormattedMessage id="email-change-successful" />),
        );
    }
}

function* flagPasswordChangeResponse(
    action: ReturnType<typeof fromUserActions.Actions.passwordChangeResponse>,
) {
    const { success } = action.payload;

    if (success) {
        yield put(
            fromFlagsActions.Actions.addFlag(<FormattedMessage id="password-change-successful" />),
        );
    }
}

function* flagRefreshEpisodeListResponse(
    action: ReturnType<typeof fromPodcastActions.Actions.refreshEpisodeListResponse>,
) {
    const { isLoading, newEpisodes } = action.payload;

    if (isLoading) {
        yield put(
            fromFlagsActions.Actions.addFlag(<FormattedMessage id="refreshing-episode-list" />, {
                id: 'refreshing-episode-list',
                persistent: true,
            }),
        );
    } else {
        yield put(fromFlagsActions.Actions.removeFlag('refreshing-episode-list'));
        yield put(
            fromFlagsActions.Actions.addFlag(
                <FormattedMessage id={newEpisodes ? 'new-episode-found' : 'no-episode-found'} />,
            ),
        );
    }
}

export function* watchEmailChangeResponse() {
    yield takeEvery(fromUserActions.ActionTypes.EMAIL_CHANGE_RESPONSE, flagEmailChangeResponse);
}

export function* watchRefreshEpisodeListResponse() {
    yield takeEvery(
        fromPodcastActions.ActionTypes.REFRESH_EPISODE_LIST_RESPONSE,
        flagRefreshEpisodeListResponse,
    );
}

export function* watchPasswordChangeResponse() {
    yield takeEvery(
        fromUserActions.ActionTypes.PASSWORD_CHANGE_RESPONSE,
        flagPasswordChangeResponse,
    );
}
