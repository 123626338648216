import { Icon } from 'components/Icon';
import useFormatMessage from 'hooks/useFormatMessage';
import { POCKET_CASTS_PLUS } from 'model/external-links';
import { Subscription, SubscriptionPlatform } from 'model/types';
import React, { useState } from 'react';
import { getAccessTokenProvider } from 'services/auth';
import { Switch } from '../../../components/form/Switch';
import { openUpdateDetailsPaddleCheckout } from '../../../helper/PaddleCheckoutHelper';
import { green } from '../../../styles';
import {
    Action,
    ActionWrapper,
    ActionsWrapper,
    DangerousAction,
    HelpText,
    IconWrapper,
    SuccessAction,
    SwitchWrapper,
    TextWrapper,
} from './ActionsColumn.styled';

type Props = {
    marketingOptIn: boolean;
    showBillingDetailsModal: (subscription: Subscription) => void;
    showCheckoutModalForGifted: () => void;
    subscriptionErrorOccurred: boolean;
    withinExpiryCountdown: boolean;
    showChangeEmailModal: () => void;
    showDeleteAccountModal: () => void;
    showCheckoutModalForCancelled: () => void;
    showSubscriptionCancelModal: (subscription: Subscription) => void;
    showChangePasswordModal: () => void;
    signOut: () => void;
    marketingUnsubscribeRequest: () => void;
    marketingSubscribeRequest: () => void;
    plusSubscription?: Subscription;
};

function ActionsColumn({
    marketingOptIn,
    showBillingDetailsModal,
    marketingUnsubscribeRequest,
    showCheckoutModalForGifted,
    marketingSubscribeRequest,
    subscriptionErrorOccurred,
    showCheckoutModalForCancelled,
    showChangeEmailModal,
    showDeleteAccountModal,
    showSubscriptionCancelModal,
    signOut,
    showChangePasswordModal,
    withinExpiryCountdown,
    plusSubscription,
}: Props) {
    const formatMessage = useFormatMessage();
    const [marketingOptInOptimistic, setMarketingOptimistic] = useState(marketingOptIn);

    const canSetupSubscription =
        plusSubscription?.platform === SubscriptionPlatform.gift && withinExpiryCountdown;

    const canChangePlusBilling = !canSetupSubscription && plusSubscription?.autoRenewing;

    const canCancelSubscription = plusSubscription?.autoRenewing;

    const canRenewPlusSubscription =
        !canSetupSubscription &&
        !canChangePlusBilling &&
        plusSubscription &&
        plusSubscription?.platform !== SubscriptionPlatform.gift;

    const isSocialLogin = ['APPLE', 'GOOGLE'].includes(getAccessTokenProvider() ?? '');

    const onMarketingClick = () => {
        if (marketingOptInOptimistic) {
            marketingUnsubscribeRequest();
        } else {
            marketingSubscribeRequest();
        }
        setMarketingOptimistic(!marketingOptInOptimistic);
    };

    const onCancelClick = () => {
        if (plusSubscription) {
            showSubscriptionCancelModal(plusSubscription);
        }
    };

    const onShowBillingModal = () => {
        if (plusSubscription) {
            showBillingDetailsModal(plusSubscription);
        }
    };

    const onRenewClicked = () => {
        if (subscriptionErrorOccurred) return;

        showCheckoutModalForCancelled();
    };

    const onBillingClicked = () => {
        if (subscriptionErrorOccurred) return;

        if (plusSubscription?.platform === SubscriptionPlatform.Paddle) {
            openUpdateDetailsPaddleCheckout(plusSubscription?.updateUrl);
        } else {
            onShowBillingModal();
        }
    };

    const onSetupSubscriptionClicked = () => {
        if (subscriptionErrorOccurred) return;

        showCheckoutModalForGifted();
    };

    return (
        <ActionsWrapper>
            {canSetupSubscription && (
                <ActionWrapper>
                    <SuccessAction
                        disabled={subscriptionErrorOccurred}
                        onClick={onSetupSubscriptionClicked}
                    >
                        <IconWrapper>
                            <Icon id="subscription" />
                        </IconWrapper>
                        <TextWrapper>{formatMessage('set-up-subscription')}</TextWrapper>
                    </SuccessAction>
                    <HelpText>
                        {formatMessage('subscription-free-subtitle')}
                        <br />
                        <a target="_blank" rel="noopener noreferrer" href={POCKET_CASTS_PLUS}>
                            {formatMessage('modal-learn-more-pcp')}
                        </a>
                    </HelpText>
                </ActionWrapper>
            )}
            {canRenewPlusSubscription && (
                <ActionWrapper>
                    <SuccessAction disabled={subscriptionErrorOccurred} onClick={onRenewClicked}>
                        <IconWrapper>
                            <Icon id="subscription" />
                        </IconWrapper>
                        <TextWrapper>{formatMessage('renew-subscription')}</TextWrapper>
                    </SuccessAction>
                </ActionWrapper>
            )}
            {!isSocialLogin && (
                <>
                    <ActionWrapper>
                        <Action onClick={showChangeEmailModal}>
                            <IconWrapper>
                                <Icon id="mail" />
                            </IconWrapper>
                            <TextWrapper>{formatMessage('change-email')}</TextWrapper>
                        </Action>
                    </ActionWrapper>
                    <ActionWrapper>
                        <Action onClick={showChangePasswordModal}>
                            <IconWrapper>
                                <Icon id="key" />
                            </IconWrapper>
                            <TextWrapper>{formatMessage('change-password')}</TextWrapper>
                        </Action>
                    </ActionWrapper>
                </>
            )}
            <ActionWrapper>
                <Action onClick={onMarketingClick}>
                    <IconWrapper>
                        <Icon id="news" />
                    </IconWrapper>
                    <TextWrapper>{formatMessage('pocket-casts-marketing')}</TextWrapper>
                </Action>
                <SwitchWrapper>
                    <Switch
                        on={marketingOptInOptimistic}
                        onChange={onMarketingClick}
                        color={green}
                    />
                </SwitchWrapper>
                <HelpText>{formatMessage('email-list-opt-in')}</HelpText>
            </ActionWrapper>
            {canCancelSubscription && (
                <ActionWrapper>
                    <Action onClick={onCancelClick}>
                        <IconWrapper>
                            <Icon id="cancel-subscription" />
                        </IconWrapper>
                        <TextWrapper>{formatMessage('cancel-subscription')}</TextWrapper>
                    </Action>
                </ActionWrapper>
            )}
            {canChangePlusBilling && (
                <ActionWrapper>
                    <Action disabled={subscriptionErrorOccurred} onClick={onBillingClicked}>
                        <IconWrapper>
                            <Icon id="subscription" />
                        </IconWrapper>
                        <TextWrapper>{formatMessage('change-billing-details')}</TextWrapper>
                    </Action>
                </ActionWrapper>
            )}
            <ActionWrapper>
                <DangerousAction onClick={signOut}>
                    <IconWrapper>
                        <Icon id="sign-out" />
                    </IconWrapper>
                    <TextWrapper>{formatMessage('sign-out')}</TextWrapper>
                </DangerousAction>
            </ActionWrapper>
            <ActionWrapper>
                <DangerousAction onClick={showDeleteAccountModal}>
                    <IconWrapper>
                        <Icon id="delete" />
                    </IconWrapper>
                    <TextWrapper>{formatMessage('delete-account')}</TextWrapper>
                </DangerousAction>
            </ActionWrapper>
        </ActionsWrapper>
    );
}

export default ActionsColumn;
