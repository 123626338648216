import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Logger } from 'helper/Logger';
import { DEPLOYMENT_ENV } from 'settings';

// Firebase needs to be able to lazy-load, so it only initializes once Google Analytics has loaded.
// We keep this flag so our exported functions know when it's safe to send data to Firebase.
let isInitialized = false;

export const initialize = () => {
    initializeApp({
        apiKey: 'AIzaSyD80qPp6WXuxtVf3PL8BrZMsxsqn164h8s',
        authDomain: 'singular-vector-91401.firebaseapp.com',
        projectId: 'singular-vector-91401',
        messagingSenderId: '910146533958',
        appId: '1:910146533958:web:a10c97924234c751806847',
        measurementId: 'G-9SPTRRQN6C',
    });
    isInitialized = true;
};

export const analytics = {
    logEvent: (eventName: string, eventData?: Record<string, unknown>) => {
        if (isInitialized) {
            const analytics = getAnalytics();
            logEvent(analytics, eventName, eventData);
        }
        if (DEPLOYMENT_ENV !== 'production') {
            Logger.log(`${eventName} => ${JSON.stringify(eventData)}`);
        }
    },
};
