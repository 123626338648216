import { PlanBadge } from 'components/PlanBadge';
import { TrackOnMount } from 'components/Tracks';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    DevicesScreenshot,
    Images,
    PageContent,
    PlanBadgeWrapper,
    PromoContent,
    Text,
    Wrapper,
} from './PromoChrome.styled';

import DevicesScreenshotSrc from './devices-screenshot.png';

export type Props = {
    children: React.ReactNode;
    promo?: React.ReactNode;
};

const DefaultPromo = () => {
    // Track when the images are loaded, so we can animate them in
    const [isDevicesImageLoaded, setIsDevicesImageLoaded] = useState(false);
    return (
        <PromoContent>
            <TrackOnMount event="promo_chrome_shown" properties={{ variant: 'default' }} />
            <Text>
                <PlanBadgeWrapper>
                    <PlanBadge tier="Plus" />
                </PlanBadgeWrapper>
                <h1>
                    <FormattedMessage id="everything-you-love" />
                </h1>
                <p>
                    <FormattedMessage id="subscribe-for-exclusive-access" />
                </p>
            </Text>
            <Images $isLoaded={isDevicesImageLoaded}>
                <DevicesScreenshot
                    src={DevicesScreenshotSrc}
                    width={1344}
                    height={1216}
                    onLoad={() => setIsDevicesImageLoaded(true)}
                />
            </Images>
        </PromoContent>
    );
};

const PromoChrome = ({ children, promo = <DefaultPromo /> }: Props) => {
    return (
        <Wrapper>
            <PageContent>{children}</PageContent>
            {promo}
        </Wrapper>
    );
};

export default PromoChrome;
