import styled from 'styled-components';
import { Typography } from '../../../styles';

export const WrapperLink = styled.div.attrs({ role: 'button', tabIndex: 0 })`
    text-decoration: none;
    width: 100%;
    cursor: pointer;

    .sub5cribe-button {
        position: absolute;
        right: 4px;
        bottom: 100%;
        margin-bottom: 4px;
        visibility: hidden;
        &.sub5cribed {
            visibility: visible;
        }
    }
    &:hover .sub5cribe-button {
        visibility: visible;
    }
`;

export const Rank = styled.div`
    ${Typography.Desktop.H70};
    color: ${props => props.theme.tokens['primary-text-02']};
    text-align: center;
`;

export const Title = styled.div`
    color: ${props => props.theme.tokens['primary-text-01']};
    overflow: hidden;
    ${Typography.Desktop.H70};
    padding-top: 10px;

    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
export const Subtitle = styled.div`
    overflow-wrap: break-word;
    ${Typography.Desktop.H70};
    padding-top: 2px;
    color: ${props => props.theme.tokens['primary-text-02']};

    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;

export const Text = styled.div`
    position: relative;
`;
