import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FolderImage } from 'components/FolderImage';
import { Icon } from 'components/Icon';
import { PodcastCount } from 'components/messages';
import { NavigationItems } from 'helper/NavigationHelper';
import { pauseKeyboardShortcuts, resumeKeyboardShortcuts } from 'helper/UiHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import React, { useEffect, useMemo, useState } from 'react';
import { DateText, PodcastImage } from '../../../components';
import { UnplayedBadge } from '../PodcastsPage.styled';
import { PodcastListItem } from '../model';
import {
    AssistiveText,
    ImageWrapper,
    List,
    RowHandle,
    RowLink,
    RowWrapper,
    Subtitle,
    Text,
    Title,
} from './ListOfPodcasts.styled';

type RowProps = {
    item: PodcastListItem;
    onItemClick: (item: PodcastListItem) => void;
};

const SortableRow = ({ item, onItemClick }: RowProps) => {
    let title;
    let subtitle;
    let image;
    let href = '';

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: item.uuid,
        attributes: {
            tabIndex: -1,
        },
    });

    if ('podcast' in item) {
        title = item.podcast.title;
        subtitle = <DateText date={item.podcast.lastEpisodePublished} showToday={true} />;
        image = <PodcastImage title={item.podcast.title} uuid={item.podcast.uuid} />;
        href = `${NavigationItems.PODCASTS.path}/${item.podcast.uuid}`;
    } else if ('folder' in item) {
        title = item.folder.name;
        subtitle = (
            <span>
                <PodcastCount count={item.podcastUuids.length} />
            </span>
        );
        image = (
            <FolderImage
                color={item.folder.color}
                name={item.folder.name}
                podcastUuids={item.podcastUuids}
                showName={false}
                sortType={item.folder.sortType}
            />
        );
        href = `${NavigationItems.PODCASTS.path}/folders/${item.folder.uuid}`;
    }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <RowWrapper
            {...attributes}
            {...listeners}
            style={style}
            ref={setNodeRef}
            $isDragging={isDragging}
        >
            <RowLink
                to={href}
                aria-label={title}
                aria-describedby="list-of-podcasts-dnd-instructions"
                onClick={() => onItemClick(item)}
                $isDragging={isDragging}
            >
                <ImageWrapper>
                    {image}
                    {item.showUnplayedBadge && <UnplayedBadge />}
                </ImageWrapper>
                <Text>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </Text>
            </RowLink>
            <RowHandle>
                <Icon id="drag-handle" />
            </RowHandle>
        </RowWrapper>
    );
};

type Props = {
    items: PodcastListItem[];
    onItemClick: (item: PodcastListItem) => void;
    onSortEnd: (oldPosition: number, newPosition: number) => void;
};

function ListOfPodcasts({ items, onItemClick, onSortEnd }: Props) {
    const formatMessage = useFormatMessage();
    const [isSorting, setIsSorting] = useState(false);
    const [liveText, setLiveText] = useState('');

    // Make sure on unmount, we always resume keyboard shortcuts
    useEffect(() => resumeKeyboardShortcuts, []);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 200,
                tolerance: 1000,
            },
        }),
    );

    const onDragStart = (event: DragStartEvent) => {
        const { active } = event;
        const index = items.findIndex(item => item.uuid === active.id);

        pauseKeyboardShortcuts();
        setIsSorting(true);
        setLiveText(
            `${items[index].title}, ${formatMessage('grabbed')}. ${formatMessage(
                'current-position',
                {
                    position: index + 1,
                    count: items.length,
                },
            )}. ${formatMessage('reorder-move-instructions')}`,
        );
    };

    const onDragOver = (event: DragOverEvent) => {
        const { over } = event;
        const newIndex = items.findIndex(item => item.uuid === over?.id);

        setLiveText(
            `${formatMessage('moved')}. ${formatMessage('current-position', {
                position: newIndex + 1,
                count: items.length,
            })}.`,
        );
    };

    const onDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        const oldIndex = items.findIndex(item => item.uuid === active.id);
        const newIndex = items.findIndex(item => item.uuid === over?.id);

        setIsSorting(false);
        onSortEnd(oldIndex, newIndex);
        resumeKeyboardShortcuts();
        if (oldIndex === newIndex) {
            setLiveText(
                `${items[oldIndex].title}, ${formatMessage('dropped-in-original-position')}.`,
            );
        } else {
            setLiveText(
                `${items[oldIndex].title}, ${formatMessage('dropped')}. ${formatMessage(
                    'final-position',
                    {
                        position: newIndex + 1,
                        count: items.length,
                    },
                )}.`,
            );
        }
    };

    const itemsWithIds = useMemo(() => items.map(item => ({ ...item, id: item.uuid })), [items]);

    return (
        <>
            <AssistiveText>
                <p id="list-of-podcasts-dnd-instructions">
                    {formatMessage('reorder-start-instructions')}
                </p>
                <p aria-live="assertive">
                    <span key={liveText}>{liveText}</span>
                </p>
            </AssistiveText>

            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext items={itemsWithIds} strategy={verticalListSortingStrategy}>
                    <List $isSorting={isSorting}>
                        {items &&
                            items.map((item: PodcastListItem) => (
                                <SortableRow
                                    key={'podcast' in item ? item.podcast.uuid : item.folder.uuid}
                                    item={item}
                                    onItemClick={onItemClick}
                                />
                            ))}
                    </List>
                </SortableContext>
            </DndContext>
        </>
    );
}

export default ListOfPodcasts;
