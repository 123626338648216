import React, { ComponentType, forwardRef, useId } from 'react';

/**
 * This is a Higher Order Component that wraps a component with a unique id.
 * It's just a way to be able to use React's useId hook in a class component.
 * @param WrappedComponent The component to wrap
 * @returns A new component that wraps the original component with a unique id
 */
const withUseId = <P extends object>(WrappedComponent: ComponentType<P>) => {
    const WithIdComponent = forwardRef<unknown, P>((props, ref) => {
        const id = useId();
        return <WrappedComponent {...(props as P & { id: string })} id={id} ref={ref} />;
    });

    return WithIdComponent;
};

export default withUseId;
