import { PlanBadge } from 'components/PlanBadge';
import { TermsAndConditions } from 'components/TermsAndConditions';
import { getCorrectFreeTrialMessage } from 'helper/GiftDaysHelper';
import { PaddlePlan, SubscriptionTier } from 'model/types';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { USE_PATRON } from 'settings';
import { TranslatableStringKey } from 'translations/glotpress';
import PlanFeatureList from '../PlanFeatureList';
import {
    DaysFreeWrapper,
    FlexLineBreak,
    FreeTrialBadge,
    FullPriceStrikethrough,
    PlanSelectorItemsRow,
    PlanSelectorTermsWrapper,
    Price,
    TextButton,
    Wrapper,
} from './PlanSelector.styled';
import { PLAN_VARIABLES } from './plans';

export type Props = {
    plan: PaddlePlan;
    price: ReactNode;
    trialDays: number;
    variant?: 'mini' | 'regular';
    isGuestPass?: boolean;
    showDisclaimers?: boolean;
    onChange: (newPlan: PaddlePlan) => void;
};

export const PlanPrice = ({
    price,
    fullPrice,
    interval,
    fullInterval,
    variant,
}: {
    price: ReactNode;
    fullPrice?: ReactNode;
    interval: TranslatableStringKey;
    fullInterval?: TranslatableStringKey;
    variant?: 'mini' | 'regular';
}) => (
    <Price $variant={variant ?? 'regular'} data-testid="plan-selector-price">
        <strong>{price}</strong>
        <span>
            /<FormattedMessage id={interval} />
        </span>
        {fullPrice && (
            <FullPriceStrikethrough>
                {fullPrice}/<FormattedMessage id={fullInterval ?? interval} />
            </FullPriceStrikethrough>
        )}
    </Price>
);

export const TrialBadge = ({ trialDays, tier }: { trialDays: number; tier: SubscriptionTier }) =>
    trialDays > 0 ? (
        <FreeTrialBadge $tier={tier}>{getCorrectFreeTrialMessage(trialDays)}</FreeTrialBadge>
    ) : null;

const PlanSelector = ({
    plan,
    onChange,
    price,
    trialDays,
    showDisclaimers,
    isGuestPass,
    variant = 'regular',
}: Props) => {
    const { otherIntervalPlan, otherTierPlan, perIntervalText, switchIntervalText, tier } =
        PLAN_VARIABLES[plan];
    const isPatron = ['patron-monthly', 'patron-yearly'].includes(plan);

    return (
        <Wrapper $variant={variant} $isPatron={isPatron}>
            <PlanSelectorItemsRow>
                <div>
                    <PlanBadge tier={tier} />
                </div>
                {!isGuestPass && (
                    <div>
                        <TrialBadge tier={tier} trialDays={trialDays} />
                    </div>
                )}
                <div>
                    {USE_PATRON && otherTierPlan && !isGuestPass && (
                        <TextButton kind="text" onClick={() => onChange(otherTierPlan)}>
                            <FormattedMessage id="change-plan" />
                        </TextButton>
                    )}
                </div>
            </PlanSelectorItemsRow>

            <FlexLineBreak />

            <PlanSelectorItemsRow>
                {!isGuestPass ? (
                    <PlanPrice price={price} interval={perIntervalText} variant={variant} />
                ) : (
                    <Price $variant={'regular'}>
                        <strong>
                            <DaysFreeWrapper>
                                <FormattedMessage
                                    id="n-days-free"
                                    values={{
                                        days: trialDays,
                                    }}
                                />
                            </DaysFreeWrapper>
                        </strong>
                        <span>
                            <FormattedMessage
                                id="then-price-interval"
                                values={{
                                    price: price,
                                    interval: <FormattedMessage id={perIntervalText} />,
                                }}
                            />
                        </span>
                    </Price>
                )}

                {otherIntervalPlan && switchIntervalText && !isGuestPass && (
                    <TextButton
                        kind="text"
                        onClick={() => onChange(otherIntervalPlan)}
                        data-testid="plan-selector-switch-plan-button"
                    >
                        <FormattedMessage id={switchIntervalText} />
                    </TextButton>
                )}
            </PlanSelectorItemsRow>
            <FlexLineBreak />

            {variant !== 'mini' && <PlanFeatureList tier={tier} plan={plan} />}

            {showDisclaimers && (
                <PlanSelectorTermsWrapper>
                    <TermsAndConditions />
                </PlanSelectorTermsWrapper>
            )}
        </Wrapper>
    );
};

export default PlanSelector;
