import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import styled from 'styled-components';
import { lightenColor } from '../../helper/ColorHelper';
import { gridSize } from '../../styles';
import { plusColor } from '../../styles/colors';

import { FormattedMessage } from 'react-intl';
import useFormatMessage from '../../hooks/useFormatMessage';
import FeaturesShowcaseCard from './FeaturesShowcaseCard';
import BookmarksIcon from './icons/bookmarks.svg';
import ContentIcon from './icons/content.svg';
import FoldersIcon from './icons/folders.svg';
import ShuffleIcon from './icons/shuffle.svg';
import StorageIcon from './icons/storage.svg';
import ThemesIcon from './icons/themes.svg';
import WearablesIcon from './icons/wearables.svg';
import BookmarksImage from './images/bookmarks.png';
import ContentImage from './images/content.png';
import FoldersImage from './images/folders.png';
import ShuffleImage from './images/shuffle.png';
import StorageImage from './images/storage.png';
import ThemesImage from './images/themes.png';
import WearableImage from './images/wearable.png';

const TabsList = styled(Tabs.List)`
    display: flex;
    overflow-x: auto;
    gap: ${1 * gridSize}px;
    padding: 0;
    max-width: 100%;
    padding: ${gridSize}px 0;

    @media (min-width: 768px) {
        gap: ${4 * gridSize}px;
    }
`;

const TabsTrigger = styled(Tabs.Trigger)`
    color: ${props => props.theme.tokens['primary-field-03']};
    font-weight: 700;
    padding: 4px 0;
    border: 2px solid transparent;

    &[data-state='active'],
    &:hover {
        border: 2px solid ${lightenColor(plusColor, 0.2)};
        border-radius: 8px;
    }
    transition: color 0.2s ease;
    min-width: 60px;

    @media (min-width: 768px) {
        &[data-state='active'],
        &:hover {
            border: 2px solid transparent;
            color: ${props => props.theme.tokens['primary-text-01']};
        }
    }
`;

const TabsContent = styled(Tabs.Content)`
    min-height: 23rem;
    background-color: ${props => props.theme.tokens['primary-ui-04']};
    border-radius: 60px;
    margin-top: ${3 * gridSize}px;
    padding: ${4 * gridSize}px ${1 * gridSize}px;
    width: 100%;
    max-width: 1000px;

    // Apply animation to the first child element of the tab content
    & > *:first-child {
        animation-delay: 0.01s;
        animation-duration: 2s;
        animation-name: slideUpFadeInSmaller;
        animation-fill-mode: forwards;
        opacity: 0;

        @keyframes slideUpFadeInSmaller {
            0% {
                opacity: 0;
                transform: translateY(0.5rem);
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    @media (min-width: 768px) {
        padding: 2px ${11 * gridSize}px;
    }
`;

const TabsRoot = styled(Tabs.Root)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FeatureLabel = styled.span`
    display: none;
    @media (min-width: 768px) {
        display: inline;
    }
`;

const FeatureIcon = styled.img`
    display: inline;
    @media (min-width: 768px) {
        display: none;
    }
`;

const FeatureImage = styled.img<{ $size?: 'small' | 'large'; $alignment?: 'top' | 'bottom' }>`
    max-width: 300px;
    display: block;
    height: auto;
    width: 100%;
    object-fit: contain;
    ${props => props.$alignment === 'bottom' && 'align-self: flex-end;'}
    ${props => props.$size === 'small' && 'max-width: 200px;'}
    ${props => props.$size === 'large' && 'max-width: 400px;'}
`;

const Features = {
    Folders: 'folders',
    Shuffle: 'shuffle',
    Bookmarks: 'bookmarks',
    Wearables: 'wearables',
    Storage: 'storage',
    Themes: 'themes',
    Content: 'content',
} as const;

const FeaturesShowcase = () => {
    const formatMessage = useFormatMessage();

    return (
        <TabsRoot defaultValue={Features.Folders}>
            <TabsList aria-label={formatMessage('plus-features')}>
                <TabsTrigger value={Features.Folders}>
                    <FeatureLabel>
                        <FormattedMessage id="folders" />
                    </FeatureLabel>
                    <FeatureIcon src={FoldersIcon} alt={formatMessage('folders')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Shuffle}>
                    <FeatureLabel>
                        <FormattedMessage id="up-next-shuffle" />
                    </FeatureLabel>
                    <FeatureIcon src={ShuffleIcon} alt={formatMessage('up-next-shuffle')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Bookmarks}>
                    <FeatureLabel>
                        <FormattedMessage id="bookmarks" />
                    </FeatureLabel>
                    <FeatureIcon src={BookmarksIcon} alt={formatMessage('bookmarks')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Wearables}>
                    <FeatureLabel>
                        <FormattedMessage id="wearables" />
                    </FeatureLabel>
                    <FeatureIcon src={WearablesIcon} alt={formatMessage('wearables')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Storage}>
                    <FeatureLabel>
                        <FormattedMessage id="cloud-storage" />
                    </FeatureLabel>
                    <FeatureIcon src={StorageIcon} alt={formatMessage('cloud-storage')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Themes}>
                    <FeatureLabel>
                        <FormattedMessage id="themes-and-icons" />
                    </FeatureLabel>
                    <FeatureIcon src={ThemesIcon} alt={formatMessage('themes-and-icons')} />
                </TabsTrigger>
                <TabsTrigger value={Features.Content}>
                    <FeatureLabel>
                        <FormattedMessage id="exclusive-content" />
                    </FeatureLabel>
                    <FeatureIcon src={ContentIcon} alt={formatMessage('exclusive-content')} />
                </TabsTrigger>
            </TabsList>
            <TabsContent value={Features.Folders}>
                <FeaturesShowcaseCard
                    title={formatMessage('folders')}
                    description={formatMessage('feature-folders-description')}
                    icon={<img src={FoldersIcon} alt={formatMessage('folders')} />}
                    image={
                        <FeatureImage
                            $alignment="bottom"
                            src={FoldersImage}
                            alt={formatMessage('folders')}
                        />
                    }
                />
            </TabsContent>
            <TabsContent value={Features.Shuffle}>
                <FeaturesShowcaseCard
                    title={formatMessage('up-next-shuffle')}
                    description={formatMessage('feature-shuffle-description')}
                    icon={<img src={ShuffleIcon} alt={formatMessage('up-next-shuffle')} />}
                    image={
                        <FeatureImage
                            src={ShuffleImage}
                            $size="large"
                            alt={formatMessage('up-next-shuffle')}
                        />
                    }
                />
            </TabsContent>
            <TabsContent value={Features.Bookmarks}>
                <FeaturesShowcaseCard
                    title={formatMessage('bookmarks')}
                    description={formatMessage('feature-bookmarks-description')}
                    icon={<img src={BookmarksIcon} alt={formatMessage('bookmarks')} />}
                    image={
                        <FeatureImage
                            $size="large"
                            $alignment="bottom"
                            src={BookmarksImage}
                            alt={formatMessage('bookmarks')}
                        />
                    }
                />
            </TabsContent>
            <TabsContent value={Features.Wearables}>
                <FeaturesShowcaseCard
                    title={formatMessage('feature-wearables-title')}
                    description={formatMessage('feature-wearables-description')}
                    icon={<img src={WearablesIcon} alt={formatMessage('wearables')} />}
                    image={
                        <FeatureImage
                            $size="small"
                            src={WearableImage}
                            alt={formatMessage('wearables')}
                        />
                    }
                />
            </TabsContent>
            <TabsContent value={Features.Storage}>
                <FeaturesShowcaseCard
                    title={formatMessage('plus-cloud-storage')}
                    description={formatMessage('feature-storage-description')}
                    icon={<img src={StorageIcon} alt={formatMessage('cloud-storage')} />}
                    image={<FeatureImage src={StorageImage} alt={formatMessage('cloud-storage')} />}
                />
            </TabsContent>
            <TabsContent value={Features.Content}>
                <FeaturesShowcaseCard
                    title={formatMessage('feature-content-title')}
                    description={formatMessage('feature-content-description')}
                    icon={<img src={ContentIcon} alt={formatMessage('content')} />}
                    image={<FeatureImage src={ContentImage} alt={formatMessage('content')} />}
                />
            </TabsContent>
            <TabsContent value={Features.Themes}>
                <FeaturesShowcaseCard
                    title={formatMessage('extra-themes-and-icons')}
                    description={formatMessage('feature-themes-description')}
                    icon={<img src={ThemesIcon} alt={formatMessage('themes-and-icons')} />}
                    image={
                        <FeatureImage src={ThemesImage} alt={formatMessage('themes-and-icons')} />
                    }
                />
            </TabsContent>
        </TabsRoot>
    );
};

export default FeaturesShowcase;
