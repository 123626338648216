import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import {
    PADDLE_SKU_PATRON_MONTHLY,
    PADDLE_SKU_PATRON_YEARLY,
    PADDLE_SKU_PLUS_MONTHLY,
    PADDLE_SKU_PLUS_YEARLY,
    PADDLE_SKU_PLUS_YEARLY_TRIAL,
    USE_50_PERCENT_BY_DEFAULT,
} from 'settings';
import { PaddleStatus, PlusPaidStatus, SubscriptionPlatform } from '../../../model/types';
import { RootState } from '../index';

export const getSubscriptionData = (state: RootState) => state.subscription.data || {};

export const hasAutoRenewingSubscription = (state: RootState) =>
    state.subscription.data?.autoRenewing;

export const hasFreeSubscription = (state: RootState) =>
    getSubscriptionData(state).paid === PlusPaidStatus.free;

export const hasPaidSubscription = (state: RootState) =>
    getSubscriptionData(state).paid === PlusPaidStatus.paid;

export const getSubscriptionTier = (state: RootState) =>
    hasPaidSubscription(state) ? getSubscriptionData(state).tier : undefined;

export const getSubscriptionPlatform = (state: RootState) => getSubscriptionData(state).platform;

export const getSubscriptionFrequency = (state: RootState) => getSubscriptionData(state).frequency;

export const getHasJustActivatedTrial = (state: RootState) =>
    state.subscription.trial.justActivatedTrial;

export const getIsValidatingTrial = (state: RootState) => state.subscription.trial.isValidating;

export const getIsRedeemingTrial = (state: RootState) => state.subscription.trial.isRedeeming;

export const hasLifetimePlus = (state: RootState) => {
    const subscription = getSubscriptionData(state);
    const tenYearsInDays = 10 * 365;
    return (
        subscription.platform === SubscriptionPlatform.gift &&
        subscription.giftDays > tenYearsInDays
    );
};

export const isGiftSubscription = (state: RootState) =>
    state.subscription &&
    state.subscription.data &&
    state.subscription.data.platform === SubscriptionPlatform.gift;

export const isOnPaddleFreeTrial = (state: RootState) => {
    if (!isEmpty(state.subscription.data)) {
        return state.subscription.data.webStatus === PaddleStatus.trialing;
    }
    return false;
};

export const getSubscriptions = (state: RootState) => {
    const { subscriptions } = state.subscription.data;
    if (subscriptions && Array.isArray(subscriptions)) {
        return subscriptions;
    }
    return [];
};

export const getPlusSubscription = (state: RootState) => {
    const { index } = state.subscription.data;
    const subscriptions = getSubscriptions(state);
    return subscriptions[index];
};

// Memoize getPaddleSKUs since it's creating a new object every time
export const getPaddleSKUs = createSelector(
    (state: RootState) => state,
    state => {
        const { web } = state.subscription.data;
        const { introOfferEligible } = state.subscription;

        const patronMonthly = web?.patronMonthly ?? PADDLE_SKU_PATRON_MONTHLY;
        const patronYearly = web?.patronYearly ?? PADDLE_SKU_PATRON_YEARLY;

        const plusMonthly = !introOfferEligible
            ? PADDLE_SKU_PLUS_MONTHLY
            : (web?.monthly ?? PADDLE_SKU_PLUS_MONTHLY);

        const plusYearly =
            USE_50_PERCENT_BY_DEFAULT || !introOfferEligible
                ? PADDLE_SKU_PLUS_YEARLY
                : (web?.yearly ?? PADDLE_SKU_PLUS_YEARLY_TRIAL);

        const switchToPocketCasts = PADDLE_SKU_PLUS_YEARLY;

        return {
            'patron-monthly': patronMonthly,
            'patron-yearly': patronYearly,
            'plus-monthly': plusMonthly,
            'plus-yearly': plusYearly,
            'switch-to-pocket-casts': switchToPocketCasts,
        };
    },
);

export const getIsSubscriptionLoading = (state: RootState) => state.subscription.isLoading;
