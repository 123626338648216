import React, { Component } from 'react';
import { Flag } from './Flag';
import { NotificationContainerWrapper } from './styled';
import UploadManager from './UploadManager';

export class NotificationContainer extends Component {
    render() {
        const { playerOpen, flags, filesAreUploading } = this.props;

        return (
            <NotificationContainerWrapper $playerOpen={playerOpen}>
                {flags.map(flag => {
                    const { id, status, message, persistent } = flag;
                    return (
                        <Flag
                            key={id}
                            id={id}
                            status={status}
                            message={message}
                            persistent={persistent}
                        />
                    );
                })}
                {filesAreUploading && <UploadManager />}
            </NotificationContainerWrapper>
        );
    }
}

export default NotificationContainer;
