import styled from 'styled-components';

export const MobileOverlayWrapper = styled.div`
    position: fixed;
    inset: 0;
    background: ${props => props.theme.tokens['secondary-ui-01']};
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: min(5%, 30px);
    flex-direction: column;
    container-type: size;

    h1 {
        font-size: 1.8em;
        color: ${props => props.theme.tokens['primary-text-01']};
    }

    p {
        color: ${props => props.theme.tokens['primary-ui-05-selected']};
    }

    .app-image {
        flex: 1;
        overflow: hidden;

        @container (max-height: 500px) {
            display: none;
        }

        img {
            width: auto;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .download-links {
        display: inline-flex;
        align-items: center;
    }

    .description {
        margin: 0;
    }
`;
