import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getShortLanguage, languages, normalisedUpdateIntlAction } from 'translations';
import { electronSetLocale } from '../../../redux/actions/electron.actions';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import { RootState } from '../../../redux/reducers';
import { AppDispatch } from '../../../store';
import { SettingsLanguageWrapper } from './SettingsLanguage.styled';

type Props = {
    language: string;
    saveLanguage: (language: string) => void;
    updateIntl: (language: string) => void;
    electronSetLocale: (language: string) => void;
};

class SettingsLanguage extends React.Component<Props> {
    render() {
        const selectedLanguage = getShortLanguage(this.props.language || undefined);

        return (
            <SettingsLanguageWrapper>
                <h1>
                    <FormattedMessage id="language" />
                </h1>
                <div>
                    <select onChange={this.onChange} value={selectedLanguage}>
                        {languages.map(language => (
                            <option value={language.code} key={language.code}>
                                {language.name}
                            </option>
                        ))}
                    </select>
                </div>
            </SettingsLanguageWrapper>
        );
    }

    onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let locale = event.target.value;

        // when the language chosen is the same as the browser use that as it include the locale e.g. en-AU
        if (locale === getShortLanguage(window.navigator.language)) {
            locale = window.navigator.language;
        }

        this.props.saveLanguage(locale);
        this.props.updateIntl(locale);
        this.props.electronSetLocale(locale);
        return true;
    };
}

const mapStateToProps = (state: RootState) => ({
    language: state.settings.language,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    saveLanguage: (code: string) => {
        dispatch(fromSettingsActions.Actions.saveLanguage(code));
        dispatch(
            fromTracksActions.Actions.recordEvent('settings_language_changed', { language: code }),
        );
    },
    updateIntl: (locale: string) => dispatch(normalisedUpdateIntlAction(locale)),
    electronSetLocale: (locale: string) => dispatch(electronSetLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsLanguage);
