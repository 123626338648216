import { NavigateBackShortcut, NavigateForwardShortcut } from 'components/KeyboardShortcut';
import { Tooltip } from 'components/Tooltip';
import useFormatMessage from 'hooks/useFormatMessage';
import { DARK_THEME } from 'model/theme/theme-dark';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Container, Label } from './NavigationTooltip.styled';

type Props = {
    id: string;
    direction: 'back' | 'forward';
};

const NavigationTooltip = ({ id, direction }: Props) => {
    const formatMessage = useFormatMessage();

    const label =
        direction === 'forward'
            ? formatMessage('navigation-go-forward')
            : formatMessage('navigation-go-back');

    return (
        <ThemeProvider theme={DARK_THEME}>
            <Tooltip id={id}>
                <Container>
                    <Label>{label}</Label>
                    {direction === 'forward' ? (
                        <NavigateForwardShortcut />
                    ) : (
                        <NavigateBackShortcut />
                    )}
                </Container>
            </Tooltip>
        </ThemeProvider>
    );
};

export default NavigationTooltip;
