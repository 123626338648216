import React from 'react';
import { NavigationItems } from '../../../helper/NavigationHelper';
import {
    EpisodeTitle,
    PodcastLink,
    PrimaryLine,
    SecondaryLine,
    UpNextIcon,
} from './EpisodeInfo.styled';

export const EpisodePodcastLink = ({
    isInactive,
    podcastUuid,
    onPodcastClick,
    children,
}: {
    isInactive?: boolean;
    podcastUuid: string;
    onPodcastClick: (params: { uuid: string }) => void;
    children: React.ReactNode;
}) => {
    return (
        <PodcastLink
            to={`${NavigationItems.PODCASTS.path}/${podcastUuid}`}
            $inactive={isInactive}
            onClick={e => {
                e.stopPropagation();
                onPodcastClick({ uuid: podcastUuid });
            }}
        >
            {children}
        </PodcastLink>
    );
};

export { EpisodeTitle, PodcastLink, PrimaryLine, SecondaryLine, UpNextIcon };
