import styled, { css } from 'styled-components';
import { Typography } from 'styles';

type ButtonProps = {
    $filled: boolean;
    $hasText: boolean;
    size: number;
};

export const Button = styled.button<ButtonProps>`
    ${Typography.Desktop['H70-B']}

    color: ${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.size}px;
    height: 0;
    padding: ${props => props.size / 2}px 0;

    // Make the button circular when there's no text, but pill-shaped when there is
    ${props =>
        props.$hasText
            ? css`
                  padding-left: ${props.size / 2}px;
                  padding-right: ${props.size / 2}px;
              `
            : css`
                  width: ${props.size}px;
              `}

    ${props =>
        props.$filled
            ? css`
                  background: ${props.color};
                  color: white;
                  &:hover {
                      opacity: 0.8;
                  }
              `
            : css`
                  box-shadow: inset 0 0 0 1px ${props.color};
                  &:hover {
                      background: ${props.color};
                      color: #fff;
                  }
              `}
`;

export const Text = styled.span`
    margin-right: 6px;
    transform: translateY(0.075em);
`;
