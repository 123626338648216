import styled from 'styled-components';

export const FriendlyLink = styled.a<{ $hoverColor: string }>`
    color: ${props => props.color};
    text-decoration: none !important;

    &:hover {
        color: ${props => props.$hoverColor};
    }

    transition: all 0.1s ease;
`;
