import { Icon } from 'components/Icon';
import React from 'react';
import { ButtonWrapper, Content, LoadingIcon, TextButton } from './Button.styled';

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    kind?: 'plus' | 'primary' | 'primary-red' | 'secondary' | 'secondary-muted' | 'text';
    width?: number;
    small?: boolean;
    loading?: boolean;
    children: React.ReactNode;
    'data-testid'?: string;
};

function Button({
    type,
    kind = 'primary',
    width,
    small = false,
    onBlur,
    onClick = () => ({}),
    onFocus,
    onMouseDown,
    disabled,
    loading = false,
    children,
    className,
    value,
    ...restProps
}: Props) {
    const isDisabled = !!(loading || disabled);

    if (kind === 'text') {
        return (
            <TextButton
                {...restProps}
                data-testid={restProps['data-testid'] || null}
                type={type}
                disabled={isDisabled}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                onMouseDown={onMouseDown}
                value={value}
                className={className}
            >
                {children}
            </TextButton>
        );
    }

    const height = small ? 37 : 48;

    return (
        <ButtonWrapper
            {...restProps}
            kind={kind}
            type={type}
            width={width}
            height={height}
            disabled={isDisabled}
            $isLoading={loading}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onMouseDown={onMouseDown}
            className={className}
            data-testid={restProps['data-testid'] || null}
            value={value}
        >
            <LoadingIcon>
                <Icon id="in-progress" aria-label="Working" />
            </LoadingIcon>

            <Content>{children}</Content>
        </ButtonWrapper>
    );
}

export default Button;
