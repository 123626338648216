import { ModalMonitor } from 'components/ModalMonitor';
import useFormatMessage from 'hooks/useFormatMessage';
import { TracksProperties } from 'model/types';
import React, { useCallback, useEffect, useState } from 'react';
import { USE_SHARING_CLIP_CREATION } from 'settings';
import { getAudioDuration } from '../../../helper/audio';
import useTracks from '../../../hooks/useTracks';
import PodcastImage from '../../PodcastImage';
import { TrackOnMount, TrackOnUnmount } from '../../Tracks';
import { CloseButton } from '../../buttons/CloseButton';
import { ClipEditor } from './ClipEditor';
import { ClipView } from './ClipView';
import { ShareOptions } from './ShareOptions';
import {
    CloseButtonWrapper,
    CreateClipOptions,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
    ShareInfo,
    SharePopupWrapper,
    ShareText,
} from './SharePopup.styled';

export type ShareProps = {
    url: string;
    audioUrl: string;
    color: string;
    podcastUuid: string;
    episodeUuid: string;
    duration: number;
    podcastTitle: string;
    episodeTitle: string;
    podcastAuthor: string;
    eventSource: string;
    isPlaying: boolean;
    playedUpTo: number;
    loading?: boolean;
    shareTime?: number;
    closeShare: () => void;
    recordEvent: (event: string, properties: TracksProperties) => void;
    addFlag: (message: string) => void;
};

function SharePopup(props: ShareProps) {
    const {
        podcastUuid,
        episodeUuid,
        episodeTitle,
        podcastTitle,
        podcastAuthor,
        closeShare,
        addFlag,
    } = props;
    const formatMessage = useFormatMessage();

    const [isClipCreationOpen, setIsClipCreationOpen] = useState(false);
    const [isClipViewOpen, setIsClipViewOpen] = useState(false);
    const [clipStartTime, setClipStartTime] = useState(0);
    const [clipEndTime, setClipEndTime] = useState(0);

    const [initialStartTime, setInitialStartTime] = useState<number | null>(null);
    const [initialEndTime, setInitialEndTime] = useState<number | null>(null);

    const [episodeDuration, setEpisodeDuration] = useState(props.duration);

    useEffect(() => {
        if (!episodeDuration) {
            getAudioDuration(props.audioUrl)
                .then(duration => {
                    setEpisodeDuration(duration);
                })
                .catch(e => console.warn('Could not get audio duration', e));
        }
    }, [episodeDuration, props.audioUrl]);

    const { recordEvent } = useTracks();

    const handleUpdateStartTime = useCallback((startTime: number) => {
        setClipStartTime(Math.floor(startTime));
    }, []);

    const handleUpdateEndTime = useCallback((endTime: number) => {
        setClipEndTime(Math.floor(endTime));
    }, []);

    const openClipCreation = useCallback(() => {
        recordEvent('share_create_clip_tapped', {
            podcast_uuid: podcastUuid,
            episode_uuid: episodeUuid,
        });
        setIsClipCreationOpen(true);
    }, []);

    const cancelClipCreation = useCallback(() => {
        setIsClipCreationOpen(false);
    }, []);

    const openClipEditor = useCallback(() => {
        setIsClipViewOpen(false);
        setIsClipCreationOpen(true);
        setInitialStartTime(clipStartTime);
        setInitialEndTime(clipEndTime);
    }, [clipStartTime, clipEndTime]);

    const createClip = useCallback(() => {
        setIsClipCreationOpen(false);
        setIsClipViewOpen(true);

        addFlag(formatMessage('clip-created'));
    }, []);

    const isPodcastShare = !episodeUuid;
    const title = isPodcastShare ? podcastTitle : episodeTitle;
    const desc = isPodcastShare ? podcastAuthor : podcastTitle;

    // The popup height is used to calculated modal position, so we need to adjust it based on the content we plan on showing
    const isClipCreation = USE_SHARING_CLIP_CREATION && isClipCreationOpen;
    const isClipView = USE_SHARING_CLIP_CREATION && isClipViewOpen;
    const isShareOptions = !isClipCreation && !isClipView;

    let modalTitle;
    if (isClipCreationOpen || isClipViewOpen) {
        modalTitle = formatMessage('share-clip');
    } else if (isPodcastShare) {
        modalTitle = formatMessage('share-podcast');
    } else {
        modalTitle = formatMessage('share-episode');
    }

    return (
        <SharePopupWrapper isOpen={true} onClosed={closeShare} width={776}>
            <TrackOnMount
                event="podcast_screen_share_shown"
                properties={{
                    podcast_uuid: podcastUuid,
                    episode_uuid: episodeUuid,
                }}
            />
            <TrackOnUnmount
                event="podcast_screen_share_dismissed"
                properties={{
                    podcast_uuid: podcastUuid,
                    episode_uuid: episodeUuid,
                }}
            />
            <ModalMonitor onCloseModals={closeShare} />

            <CloseButtonWrapper>
                <CloseButton onClick={closeShare} />
            </CloseButtonWrapper>

            <ShareInfo>
                <PodcastImage className="share-popup-image" uuid={podcastUuid} />
                <ShareText>
                    <ModalTitle>{modalTitle}</ModalTitle>
                    <div className="title">{title}</div>
                    <div className="desc">{desc}</div>
                </ShareText>
            </ShareInfo>

            {isClipCreation && (
                <ClipEditor
                    {...props}
                    duration={episodeDuration}
                    initialStartTime={initialStartTime}
                    initialEndTime={initialEndTime}
                    onUpdateStartTime={handleUpdateStartTime}
                    onUpdateEndTime={handleUpdateEndTime}
                />
            )}

            {isClipView && (
                <ClipView
                    {...props}
                    duration={episodeDuration}
                    url={`${props.url}?t=${clipStartTime},${clipEndTime}`}
                    audioUrl={`${props.audioUrl}?t=${clipStartTime},${clipEndTime}`}
                    startTime={clipStartTime}
                    endTime={clipEndTime}
                    isPlaying={false}
                />
            )}

            {isShareOptions && <ShareOptions {...props} />}

            {USE_SHARING_CLIP_CREATION && !isPodcastShare && (
                <CreateClipOptions>
                    {isClipCreation && (
                        <>
                            <TrackOnMount
                                event="share_clip_creation_form_shown"
                                properties={{
                                    podcast_uuid: podcastUuid,
                                    episode_uuid: episodeUuid,
                                }}
                            />
                            <PrimaryButton onClick={createClip}>
                                {formatMessage('create-clip')}
                            </PrimaryButton>
                            <SecondaryButton onClick={cancelClipCreation}>
                                {formatMessage('cancel')}
                            </SecondaryButton>
                        </>
                    )}

                    {isClipViewOpen && (
                        <SecondaryButton onClick={openClipEditor}>
                            {formatMessage('edit-clip')}
                        </SecondaryButton>
                    )}

                    {isShareOptions && (
                        <SecondaryButton disabled={!episodeDuration} onClick={openClipCreation}>
                            {formatMessage('create-clip')}
                        </SecondaryButton>
                    )}
                </CreateClipOptions>
            )}
        </SharePopupWrapper>
    );
}

export default SharePopup;
