import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/Button';
import Input from 'components/Input';
import { OAuthLoginButton } from 'components/OAuthLoginButton';
import { Separator } from 'components/Separator';
import { useDispatch, useSelector } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import isNaN from 'lodash/isNaN';
import React, { ReactNode, useEffect, useId, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import * as fromUserActions from '../../../redux/actions/user.actions';

import { Link } from 'react-router';
import { useSearchParam } from 'react-use';
import { z } from 'zod';
import useQueryRedirect from '../../../hooks/useQueryRedirect';
import { getEmail, getIsSubscriptionLoading } from '../../../redux/reducers/selectors';
import { FORM_ERRORS_DELAY, VALID_EMAIL_REGEX } from '../../../settings';
import urls from '../../../urls';
import { StyledForm, StyledFormError } from '../form.styled';
import {
    ForgotPasswordLink,
    SSOButtons,
    SignedInWrapper,
    StyledForgotPasswordLinkWrapper,
    StyledFormErrorWrapper,
    StyledToggleLoginRegister,
} from './LoginRegisterForm.styled';
import { SignUpTerms } from './SignUpTerms';

type Props = {
    allowSignup?: boolean;
    onLoggedIn?: () => void;
    isLoading?: boolean;
    title: ReactNode;
    submitButtonText: string;
};

type LoginFormInputs = {
    email: string;
    password: string;
};

const schema = (intl: IntlShape) =>
    z
        .object({
            email: z
                .string({ required_error: intl.formatMessage({ id: 'field-required' }) })
                .regex(VALID_EMAIL_REGEX, intl.formatMessage({ id: 'invalid-email' })),
            password: z
                .string({ required_error: intl.formatMessage({ id: 'field-required' }) })
                .min(6, intl.formatMessage({ id: 'password-restriction' }))
                .max(128, intl.formatMessage({ id: 'password-restriction-max' })),
        })
        .required();

function LoginRegisterForm({ allowSignup, onLoggedIn, isLoading, title, submitButtonText }: Props) {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const {
        register,
        handleSubmit,
        reset,
        getFieldState,
        formState: { errors, isValid },
    } = useForm<LoginFormInputs>({
        resolver: zodResolver(schema(useIntl())),
        mode: 'onChange',
        delayError: FORM_ERRORS_DELAY,
    });

    const accountEmail = useSelector(getEmail);
    const error = useSelector(state => {
        const { errorMessage } = state.user;
        if (!errorMessage) {
            return null;
        }

        if (errorMessage === '401' || errorMessage === '403') {
            return formatMessage(
                allowSignup ? 'signup-with-existing-email-failed' : 'login-failed',
            );
        }

        const errorString = isNaN(errorMessage) ? errorMessage : `Error: ${errorMessage}`;
        return `${formatMessage('login-request-failed')} (${errorString})`;
    });

    const isAuthLoading = useSelector(state => state.user.isFetching);
    const isSubscriptionLoading = useSelector(getIsSubscriptionLoading);
    const hasSubscriptionData = useSelector(
        state => Object.keys(state.subscription.data ?? {}).length > 0,
    );
    const isLoggedIn = !isSubscriptionLoading && hasSubscriptionData && !!accountEmail;
    const prevIsLoggedIn = useRef(false);

    useEffect(() => {
        if (prevIsLoggedIn.current) {
            return;
        }
        // As soon as the user goes from "not logged in" to "logged in", call the callback
        if (!prevIsLoggedIn.current && isLoggedIn) {
            onLoggedIn?.();
        }
        prevIsLoggedIn.current = isLoggedIn;
    }, [isLoggedIn, onLoggedIn]);

    const clearForm = () => {
        reset();
        dispatch(fromUserActions.Actions.unAuthenticate());
    };

    const onSubmit: SubmitHandler<LoginFormInputs> = data => {
        if (isLoggedIn) {
            // We're already logged in, don't log in again but do call the callback
            onLoggedIn?.();
        } else {
            const { email, password } = data;

            if (allowSignup) {
                dispatch(fromUserActions.Actions.signUp(email, password));
            } else {
                dispatch(fromUserActions.Actions.signIn({ email, password }));
            }
        }
    };

    const emailInputId = useId();
    const passwordInputId = useId();
    const redirect = useQueryRedirect();
    const variant = useSearchParam('variant');

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {accountEmail && (
                <SignedInWrapper>
                    <p>
                        {formatMessage('signed-in-as')} <strong>{accountEmail}</strong>
                    </p>
                    <Button kind="text" type="button" onClick={clearForm}>
                        {formatMessage('use-a-different-account')}
                    </Button>
                </SignedInWrapper>
            )}

            {!accountEmail && (
                <>
                    {typeof title === 'string' ? <h1 data-testid="title">{title}</h1> : title}
                    <div data-testid="emailField">
                        <label htmlFor={emailInputId}>{formatMessage('email')}</label>
                        <Input
                            id={emailInputId}
                            data-testid="emailFieldInput"
                            aria-label="Email"
                            type="email"
                            status={
                                getFieldState('email').isDirty
                                    ? errors.email
                                        ? 'error'
                                        : 'ok'
                                    : undefined
                            }
                            {...register('email')}
                        />
                        <StyledFormError data-testid="emailField-error">
                            {errors.email?.message}
                        </StyledFormError>
                    </div>

                    <div data-testid="passwordField">
                        <label htmlFor={passwordInputId}>{formatMessage('password')}</label>
                        <Input
                            id={passwordInputId}
                            data-testid="passwordFieldInput"
                            aria-label="Password"
                            type="password"
                            status={
                                getFieldState('password').isDirty
                                    ? errors.password
                                        ? 'error'
                                        : 'ok'
                                    : undefined
                            }
                            {...register('password')}
                        />
                        {!allowSignup && (
                            <StyledForgotPasswordLinkWrapper>
                                <ForgotPasswordLink to="/user/forgotten_password">
                                    {formatMessage('forgot-password')}
                                </ForgotPasswordLink>
                            </StyledForgotPasswordLinkWrapper>
                        )}
                        <StyledFormError data-testid="passwordField-error">
                            {errors.password?.message}
                        </StyledFormError>
                    </div>
                </>
            )}

            {error && (
                <StyledFormErrorWrapper>
                    <StyledFormError>{error}</StyledFormError>
                </StyledFormErrorWrapper>
            )}

            <Button
                type="submit"
                kind="primary"
                data-testid="submit-button"
                loading={isLoading || isAuthLoading || isSubscriptionLoading}
                disabled={isValid === false && !accountEmail}
            >
                {submitButtonText}
            </Button>

            {allowSignup && <SignUpTerms />}

            {!accountEmail && (
                <>
                    <Separator message="or" />
                    <SSOButtons>
                        <OAuthLoginButton provider="apple" />
                        <OAuthLoginButton provider="google" />
                    </SSOButtons>
                </>
            )}

            {!allowSignup && !isLoggedIn && (
                <StyledToggleLoginRegister>
                    <FormattedMessage
                        id="dont-have-an-account"
                        values={{
                            signupLink: link => (
                                <Link replace to={urls.registerPath({ redirect, variant })}>
                                    {link}
                                </Link>
                            ),
                        }}
                    />
                </StyledToggleLoginRegister>
            )}

            {allowSignup && !isLoggedIn && (
                <StyledToggleLoginRegister>
                    <FormattedMessage
                        id="already-have-an-account"
                        values={{
                            loginLink: link => (
                                <Link replace to={urls.signInPath({ redirect, variant })}>
                                    {link}
                                </Link>
                            ),
                        }}
                    />
                </StyledToggleLoginRegister>
            )}
        </StyledForm>
    );
}

export default LoginRegisterForm;
