import styled, { css } from 'styled-components';

export const SubscribeButtonWrapper = styled.div<{ $circled: boolean }>`
    color: ${props => props.theme.tokens['primary-ui-05-selected']};
    &:hover {
        opacity: 0.75;
    }
    &.sub5cribed {
        color: ${props => props.theme.tokens['support-02']};
        opacity: 1;
    }

    ${props =>
        props.$circled &&
        css`
            border-radius: 12px;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
            background: rgba(0, 0, 0, 0.4);
            color: #fff;
            &:hover {
                opacity: 1;
                color: ${props => props.theme.tokens['primary-ui-05-selected']};
                background: #fff;
            }
            &.sub5cribed {
                background: ${props => props.theme.tokens['support-02']};
                color: ${props => props.theme.legacyScss['theme-background']};
            }
        `}
`;

export const SubscribeButtonIconWrapper = styled.div<{ $circled: boolean }>`
    padding: 4px;
    ${props =>
        props.$circled &&
        css`
            padding: 2px;
        `}
`;
