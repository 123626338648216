import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import useFormatMessage from '../../hooks/useFormatMessage';
import urls from '../../urls';
import { BackButton } from '../BackButton';
import { CenteredPageChrome } from '../CenteredPageChrome';
import { Logo } from '../Logo';

const StyledAuthenticationContent = styled.div`
    max-width: 400px;
    width: 100%;
    padding: 0 22px;
`;

const StyledAuthenticationLogo = styled.div`
    display: flex;
`;

const StyledBackButton = styled(BackButton)`
    position: absolute;
    left: calc(min(80px, 6%));
    && {
        color: ${props => props.theme.tokens['primary-text-01']};
    }
`;

const AuthenticationChrome: FC<PropsWithChildren> = ({ children }) => {
    const formatMessage = useFormatMessage();

    return (
        <CenteredPageChrome>
            <StyledBackButton buttonText={formatMessage('back')} />
            <StyledAuthenticationLogo>
                <Link to={urls.startPath}>
                    <Logo />
                </Link>
            </StyledAuthenticationLogo>
            <StyledAuthenticationContent>{children}</StyledAuthenticationContent>
        </CenteredPageChrome>
    );
};

export default AuthenticationChrome;
