import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject } from 'redux';
import { AppDispatch } from 'store';
import { Actions as HistoryActions } from './actions/history.actions';
import { Actions as ModalActions } from './actions/modal.actions';
import { Actions as PlayerActions } from './actions/player.actions';
import { Actions as PodcastActions } from './actions/podcast.actions';
import { Actions as ShareActions } from './actions/share.actions';
import { Actions as UpNextActions } from './actions/up-next.actions';
type Action<A extends ActionCreatorsMapObject[keyof ActionCreatorsMapObject]> = (
    ...args: any[]
) => ReturnType<A>;

/**
 * Create a hook for the specified action.
 * For example:
 * ```
 * import { Actions } from 'redux/actions/podcast.actions';
 * const usePodcastActions = actionFactory(Actions);
 * const subscribe = usePodcastActions('subscribe');
 * subscribe('123');
 * ```
 */
export const actionFactory = <Actions extends ActionCreatorsMapObject>(actions: Actions) => {
    return <K extends keyof Actions>(actionKey: K) => {
        const dispatch = useDispatch<AppDispatch>();
        return useCallback(
            (...args: Parameters<Actions[K]>) => {
                const action = actions[actionKey] as Action<Actions[K]>;
                dispatch(action(...args));
            },
            [dispatch, actionKey],
        );
    };
};

/**
 * Export reusable hooks for various actions.
 */
export const useEpisodePodcastAction = actionFactory(PodcastActions);
export const useEpisodeShareAction = actionFactory(ShareActions);
export const useEpisodePlayerAction = actionFactory(PlayerActions);
export const useEpisodeUpNextAction = actionFactory(UpNextActions);
export const useEpisodeHistoryAction = actionFactory(HistoryActions);
export const useModalAction = actionFactory(ModalActions);
