import formatCurrency from '@automattic/format-currency';
import { LoaderSquare } from 'components';
import { PlanSelector } from 'components/PlanSelector';
import DiscountedPlanSelector from 'components/PlanSelector/DiscountedPlanSelector';
import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import { CredentialNotification } from 'components/form/CredentialNotification';
import { LoginRegisterForm } from 'components/form/LoginRegisterForm';
import {
    getLocalizedSubscriptionDiscountPrice,
    getLocalizedSubscriptionPrice,
} from 'helper/PaddleCheckoutHelper';
import { isInfoOfferAvailableForCountry } from 'helper/PlansHelper';
import * as TimeHelper from 'helper/TimeHelper';
import { Location } from 'history';
import useFormatMessage from 'hooks/useFormatMessage';
import { useGeolocation } from 'hooks/useGeolocation';
import { usePaddle } from 'hooks/usePaddle';
import useResize from 'hooks/useResize';
import { PaddleDiscountedPlan, PaddlePlan } from 'model/types';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router';
import { USE_50_PERCENT_BY_DEFAULT } from 'settings';
import { Logo } from '../../components/Logo';
import { useDispatch, useSelector } from '../../hooks/react-redux-typed';
import useNavigateOrRedirect from '../../hooks/useNavigateOrRedirect';
import usePaddleProduct from '../../hooks/usePaddleProduct';
import useQueryDiscounts from '../../hooks/useQueryDiscounts';
import useTracks from '../../hooks/useTracks';
import * as fromSubscriptionActions from '../../redux/actions/subscription.actions';
import * as fromUserActions from '../../redux/actions/user.actions';
import { RootState } from '../../redux/reducers';
import { getEmail, hasPaidSubscription } from '../../redux/reducers/selectors';
import { getJustSignedUp } from '../../redux/reducers/selectors/user.selectors';
import urls from '../../urls';
import {
    ContentWrapper,
    FinePrint,
    Form,
    Plans,
    RegisterPageWrapper,
    TWO_COLUMNS_BREAKPOINT,
} from './RegisterPage.styled';

const discountedPlan = 'switch-to-pocket-casts';

const defaultPlan = (location: Location) => {
    const { plan, redeem } = qs.parse(location.search);

    if (redeem) {
        return 'plus-yearly';
    }

    switch (plan) {
        case 'plus-monthly':
        case 'plus-yearly':
        case 'patron-monthly':
        case 'patron-yearly':
            return plan;
        case 'monthly':
            return 'plus-monthly';
        case discountedPlan:
            return plan;
        default:
            return 'plus-yearly';
    }
};

function RegisterPage() {
    const formatMessage = useFormatMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { windowWidth } = useResize();
    const [plan, setPlan] = useState<PaddlePlan | PaddleDiscountedPlan>(defaultPlan(location));
    const { couponCode, guestPassCode, hasDiscount } = useQueryDiscounts();
    const navigateOrRedirect = useNavigateOrRedirect();
    const { setDefaultEventSource } = useTracks();

    const { countryCode, hasFetchedLocation } = useGeolocation();

    const { isPurchasing, subscriptionPurchaseFailed } = useSelector((state: RootState) => ({
        isPurchasing: state.payments.isPurchasing,
        accountEmail: state.user.email,
        // Occurs when there is a delay in Paddle sending the purchase webhook to the server
        // (usually because the fraud score of the transaction is too high).
        subscriptionPurchaseFailed: state.payments.subscriptionPurchaseFailed,
    }));

    const product = usePaddleProduct(plan, { code: couponCode });
    const justSignedUp = useSelector(getJustSignedUp);

    const doSubscribeFlow = usePaddle({
        code: couponCode,
        onPaymentSuccess: () => {
            if (justSignedUp) {
                navigate(urls.welcomePath());
                return;
            }
            navigate(urls.startPath);
        },
    });
    const priceString = product ? getLocalizedSubscriptionPrice(product) : '';

    const isCouponCode = !!product?.applied_coupon?.code;
    const isGuestPass = !!guestPassCode;
    const offerIntroDiscount = isInfoOfferAvailableForCountry(countryCode);

    const offerHalfOffIntro =
        !isCouponCode &&
        !isGuestPass &&
        (plan === discountedPlan ||
            (plan === 'plus-yearly' && USE_50_PERCENT_BY_DEFAULT && offerIntroDiscount));
    const isDiscounted = plan === discountedPlan || isCouponCode || offerHalfOffIntro;

    const halfOffPriceString = product
        ? formatCurrency(product.subscription.price.gross / 2, product.currency)
        : '';

    const discountedPriceString =
        product && isCouponCode
            ? getLocalizedSubscriptionDiscountPrice(product)
            : halfOffPriceString;

    const trialDays = isGuestPass
        ? 60
        : isCouponCode || !offerIntroDiscount
          ? 0
          : (product?.subscription.trial_days ?? 0);
    const isTrial = trialDays > 0 && !offerHalfOffIntro && !isGuestPass;

    // Make sure the URL always contains the selected plan, so it doesn't get reset on
    // page refreshes and redirects from OAuth.
    useEffect(() => {
        // Update the current query string with the new plan, and re-stringify it
        const search = `?${qs.stringify({ ...qs.parse(location.search), plan })}`;

        // Do not update the location if it's the same we're at
        if (location.search === search) {
            return;
        }

        navigate(
            {
                pathname: location.pathname,
                search,
            },
            { replace: true },
        );
    }, [location, navigate, plan]);

    // During checkout, we need to know if we offered them an intro offer or not
    // so we can apply the correct discount code or trial days.
    useEffect(() => {
        const offerIntroDiscount = isInfoOfferAvailableForCountry(countryCode);
        dispatch(
            fromSubscriptionActions.Actions.saveIntroOfferEligibility(
                !couponCode && offerIntroDiscount,
            ),
        );
    }, [countryCode, couponCode, dispatch]);

    useEffect(() => {
        // TODO: Make a request to the server to validate the referral code (guestPassCode)
        if (guestPassCode) {
            dispatch(fromSubscriptionActions.Actions.saveGuestPassCode(guestPassCode));
        }
    }, [dispatch, guestPassCode]);

    // if plan === discountedPlan and offerIntroDiscount is false, set plan to yearly
    useEffect(() => {
        if (plan === discountedPlan && !offerIntroDiscount) {
            setPlan('plus-yearly');
        }
    }, [offerIntroDiscount, plan]);

    // Clear errors on unmount
    useEffect(
        () => () => {
            dispatch(fromUserActions.Actions.clearUserError());
        },
        [dispatch],
    );

    useEffect(() => {
        if (isGuestPass) {
            setDefaultEventSource('offer');
        }
    }, [setDefaultEventSource, isGuestPass]);

    const showCheckoutForm = !subscriptionPurchaseFailed;

    const hasSubscription = useSelector(hasPaidSubscription);
    const accountEmail = useSelector(getEmail);

    const handleLoginRegisterFormOnLoggedIn = () => {
        // If the user is signing up with a discount code and already has a subscription
        // we should show the modal that explains that the discount code is not applied.
        if (hasDiscount && hasSubscription && !justSignedUp) {
            navigateOrRedirect(urls.startPath);
            dispatch(
                fromSubscriptionActions.Actions.showModalGeneric('showAlreadyPlusSubscriberModal'),
            );
            return;
        }

        // If the user is signing up with a discount and didn't have a subscription, then
        // show the checkout modal so they can purchase a subscription with the applied discount
        if (hasDiscount && !hasSubscription) {
            doSubscribeFlow(plan);
            return;
        }

        // Finally, if this is a normal sign up (no discounts in the URL) then just redirect
        navigateOrRedirect(urls.welcomePath());
    };

    const formTitle = useMemo(() => {
        if (isGuestPass) {
            return formatMessage('guest-pass-headline-register', {
                passLengthAdjective: formatMessage('gift-plus-duration-adjective'),
            });
        }

        if (plan === discountedPlan) {
            return formatMessage('switch-to-signup-headline');
        }

        return formatMessage('setup-need-details');
    }, [isGuestPass, plan, formatMessage]);

    const formSubmitButtonText = useMemo(() => {
        if (isGuestPass) {
            if (accountEmail) {
                return formatMessage('claim-offer');
            }

            return formatMessage('create-account');
        }

        if (isTrial) {
            return formatMessage('start-free-trial-and-subscribe');
        }

        return formatMessage('subscribe');
    }, [accountEmail, formatMessage, isGuestPass, isTrial]);

    if (!hasFetchedLocation) {
        return <LoaderSquare />;
    }

    return (
        <RegisterPageWrapper>
            <TrackOnMount event="create_account_shown" properties={{ product: plan }} />
            <TrackOnUnmount event="create_account_dismissed" />
            {isGuestPass && <TrackOnMount event="referral_claim_screen_shown" />}
            <Helmet>
                <title>{formatMessage('sign-up')}</title>
            </Helmet>
            <div>
                <Link to={urls.startPath}>
                    <Logo />
                </Link>
            </div>
            <ContentWrapper>
                <Plans>
                    {isDiscounted ? (
                        <DiscountedPlanSelector
                            plan={plan}
                            onChange={setPlan}
                            couponCode={isCouponCode ? couponCode : ''}
                            product={product}
                            discountInterval={'first-year'}
                            discountPrice={discountedPriceString}
                            fullInterval={'year'}
                            fullPrice={priceString}
                            variant={windowWidth < TWO_COLUMNS_BREAKPOINT ? 'mini' : 'regular'}
                        />
                    ) : (
                        <PlanSelector
                            plan={plan}
                            price={priceString}
                            isGuestPass={isGuestPass}
                            trialDays={trialDays}
                            onChange={setPlan}
                            variant={windowWidth < TWO_COLUMNS_BREAKPOINT ? 'mini' : 'regular'}
                        />
                    )}
                </Plans>

                <Form>
                    {subscriptionPurchaseFailed && (
                        <CredentialNotification>
                            {formatMessage('payment-provider-delay-or-error-1')}{' '}
                            {formatMessage('payment-provider-delay-or-error-2')}
                        </CredentialNotification>
                    )}

                    {showCheckoutForm && (
                        <LoginRegisterForm
                            allowSignup
                            onLoggedIn={handleLoginRegisterFormOnLoggedIn}
                            isLoading={isPurchasing}
                            title={formTitle}
                            submitButtonText={formSubmitButtonText}
                        />
                    )}
                </Form>
                <FinePrint data-testid="checkout-footer">
                    {offerHalfOffIntro && (
                        <p>
                            {formatMessage('yearly-discount-fine-print', {
                                discountedPrice: halfOffPriceString,
                                fullPrice: priceString,
                            })}
                        </p>
                    )}

                    {isGuestPass && (
                        <>
                            <p>
                                {formatMessage('guest-pass-terms', {
                                    trialDays,
                                })}{' '}
                                &nbsp;
                                {formatMessage('trial-recurring-payments', {
                                    endOfTrialDate: TimeHelper.daysFromNow(trialDays),
                                })}
                            </p>
                            <p>
                                {formatMessage('cancel-trial-any-time')}{' '}
                                {formatMessage('cancel-trial-description')}
                            </p>
                        </>
                    )}

                    {isTrial && (
                        <>
                            <p>
                                {formatMessage('trial-recurring-payments', {
                                    endOfTrialDate: TimeHelper.daysFromNow(trialDays),
                                })}
                            </p>
                            <p>
                                {formatMessage('cancel-trial-any-time')}{' '}
                                {formatMessage('cancel-trial-description')}
                            </p>
                        </>
                    )}
                    <p>{formatMessage('taxes-may-apply')}</p>
                </FinePrint>
            </ContentWrapper>
        </RegisterPageWrapper>
    );
}

export default RegisterPage;
