import { EpisodeAndPodcastUuidsArray } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    OPEN_PODCAST = '@podcast/OPEN_PODCAST',
    OPEN_PODCAST_FAILED = '@podcast/OPEN_PODCAST_FAILED',
    CLOSE_PODCAST = '@podcast/CLOSE_PODCAST',
    RATE_PODCAST = '@podcast/RATE_PODCAST',
    FETCH_USER_RATING = '@podcast/FETCH_USER_RATING',
    FETCH_USER_RATING_SUCCEEDED = '@podcast/FETCH_USER_RATING_SUCCEEDED',
    FETCH_USER_RATING_FAILED = '@podcast/FETCH_USER_RATING_FAILED',

    SEARCH_EPISODES = '@podcast/SEARCH_EPISODES',
    SEARCH_EPISODES_SUCCESS = '@podcast/SEARCH_EPISODES_SUCCESS',
    SEARCH_EPISODES_FAILED = '@podcast/SEARCH_EPISODES_FAILED',
    CLEAR_SEARCH_EPISODES = '@podcast/CLEAR_SEARCH_EPISODES',
    MARK_AS_UNPLAYED = '@podcast/MARK_AS_UNPLAYED',
    MARK_AS_PLAYED = '@podcast/MARK_AS_PLAYED',
    MARK_AS_IN_PROGRESS = '@podcast/MARK_AS_IN_PROGRESS',
    STAR_EPISODE = '@podcast/STAR_EPISODE',
    DOWNLOAD_PODCAST_COLOR = '@podcast/DOWNLOAD_PODCAST_COLOR',
    UPDATE_EPISODE_ORDER = '@podcast/UPDATE_EPISODE_ORDER',
    UPDATE_AUTO_START_FROM = '@podcast/UPDATE_AUTO_START_FROM',
    UPDATE_AUTO_SKIP_LAST = '@podcast/UPDATE_AUTO_SKIP_LAST',
    UPDATE_PLAYBACK_EFFECTS = '@podcast/UPDATE_PLAYBACK_EFFECTS',
    UPDATE_PLAYBACK_SPEED = '@podcast/UPDATE_PLAYBACK_SPEED',
    SHOW_ARCHIVED = '@podcast/SHOW_ARCHIVED',
    REFRESH_EPISODE_LIST = '@podcast/REFRESH_EPISODE_LIST',
    REFRESH_EPISODE_LIST_RESPONSE = '@podcast/REFRESH_EPISODE_LIST_RESPONSE',
    UPDATE_AUTO_ARCHIVE = '@podcast/UPDATE_AUTO_ARCHIVE',
    UPDATE_AUTO_ARCHIVE_PLAYED = '@podcast/UPDATE_AUTO_ARCHIVE_PLAYED',
    ARCHIVE = '@podcast/ARCHIVE',
    UNARCHIVE = '@podcast/UNARCHIVE',

    ARCHIVE_ALL_CONFIRMATION_SHOW = '@podcast/ARCHIVE_ALL_CONFIRMATION_SHOW',
    ARCHIVE_ALL_CONFIRMATION_DISMISS = '@podcast/ARCHIVE_ALL_CONFIRMATION_DISMISS',

    ARCHIVE_ALL = '@podcast/ARCHIVE_ALL',
    UNARCHIVE_ALL = '@podcast/UNARCHIVE_ALL',
}

// Use null if the event should not be tracked. This is rare but can happen —
// for instance when Mark As Read triggers an episode Archive action.
export type EpisodeEventSource =
    | null
    | 'episode_detail'
    | 'filters'
    | 'listening_history'
    | 'podcast_screen'
    | 'starred'
    | 'player_more_actions'
    | 'up_next'
    | 'search_list'
    | 'user_file';

export type EpisodeTracksProperties = {
    eventSource: EpisodeEventSource;
};

export const Actions = {
    openPodcastFailed: () => createAction(ActionTypes.OPEN_PODCAST_FAILED),

    openPodcast: (uuid: string) => createAction(ActionTypes.OPEN_PODCAST, { uuid }),

    closePodcast: () => createAction(ActionTypes.CLOSE_PODCAST),

    searchEpisodes: (term: string, podcastUuid: string) =>
        createAction(ActionTypes.SEARCH_EPISODES, { term, podcastUuid }),

    searchEpisodesSuccess: (episodes: { uuid: string }[], term: string) =>
        createAction(ActionTypes.SEARCH_EPISODES_SUCCESS, { episodes, term }),

    searchEpisodesFailed: () => createAction(ActionTypes.SEARCH_EPISODES_FAILED),

    clearSearchEpisodes: () => createAction(ActionTypes.CLEAR_SEARCH_EPISODES),

    downloadPodcastColor: (uuid: string) =>
        createAction(ActionTypes.DOWNLOAD_PODCAST_COLOR, { uuid }),

    markAsPlayed: (
        episodeUuid: string,
        podcastUuid: string,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.MARK_AS_PLAYED, { episodeUuid, podcastUuid, tracksProperties }),

    ratePodcast: (uuid: string, userRating: number) =>
        createAction(ActionTypes.RATE_PODCAST, { uuid, userRating }),

    fetchUserRating: (uuid: string) => createAction(ActionTypes.FETCH_USER_RATING, { uuid }),

    fetchUserRatingSucceeded: (uuid: string, userRating: number) =>
        createAction(ActionTypes.FETCH_USER_RATING_SUCCEEDED, { uuid, userRating }),

    fetchUserRatingFailed: (uuid: string) =>
        createAction(ActionTypes.FETCH_USER_RATING_FAILED, { uuid }),

    archive: (
        episodeUuid: string,
        podcastUuid: string,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.ARCHIVE, { episodeUuid, podcastUuid, tracksProperties }),

    unarchive: (
        episodeUuid: string,
        podcastUuid: string,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.UNARCHIVE, { episodeUuid, podcastUuid, tracksProperties }),

    showArchiveAllConfirmation: (
        episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray,
        playedOnly: boolean,
    ) =>
        createAction(ActionTypes.ARCHIVE_ALL_CONFIRMATION_SHOW, {
            episodeUuidAndPodcastUuids: JSON.parse(JSON.stringify(episodeUuidAndPodcastUuids)),
            playedOnly,
        }),

    dismissArchiveAllConfirmation: () => createAction(ActionTypes.ARCHIVE_ALL_CONFIRMATION_DISMISS),

    archiveAll: (episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray) =>
        createAction(ActionTypes.ARCHIVE_ALL, { episodeUuidAndPodcastUuids }),

    unarchiveAll: (episodeUuidAndPodcastUuids: EpisodeAndPodcastUuidsArray) =>
        createAction(ActionTypes.UNARCHIVE_ALL, { episodeUuidAndPodcastUuids }),

    refreshEpisodeList: (podcastUuid: string, lastEpisodeUuid: string) =>
        createAction(ActionTypes.REFRESH_EPISODE_LIST, { podcastUuid, lastEpisodeUuid }),

    refreshEpisodeListResponse: (isLoading: boolean, newEpisodes: boolean) =>
        createAction(ActionTypes.REFRESH_EPISODE_LIST_RESPONSE, { isLoading, newEpisodes }),

    showArchived: (podcastUuid: string, showArchived: boolean) =>
        createAction(ActionTypes.SHOW_ARCHIVED, { podcastUuid, showArchived }),

    markAsInProgress: (episodeUuid: string, podcastUuid: string, playedUpTo: number) =>
        createAction(ActionTypes.MARK_AS_IN_PROGRESS, { episodeUuid, podcastUuid, playedUpTo }),

    markAsUnplayed: (
        episodeUuid: string,
        podcastUuid: string,
        tracksProperties: EpisodeTracksProperties,
    ) => createAction(ActionTypes.MARK_AS_UNPLAYED, { episodeUuid, podcastUuid, tracksProperties }),

    updateEpisodeOrder: (podcastUuid: string, episodesSortOrder: number) =>
        createAction(ActionTypes.UPDATE_EPISODE_ORDER, { podcastUuid, episodesSortOrder }),

    starEpisode: (
        episodeUuid: string,
        podcastUuid: string,
        starred: boolean,
        tracksProperties: EpisodeTracksProperties,
    ) =>
        createAction(ActionTypes.STAR_EPISODE, {
            episodeUuid,
            podcastUuid,
            starred,
            tracksProperties,
        }),

    updateAutoStartFrom: (podcastUuid: string, autoStartFrom: number) =>
        createAction(ActionTypes.UPDATE_AUTO_START_FROM, { podcastUuid, autoStartFrom }),

    updateAutoSkipLast: (podcastUuid: string, autoSkipLast: number) =>
        createAction(ActionTypes.UPDATE_AUTO_SKIP_LAST, { podcastUuid, autoSkipLast }),

    updatePlaybackEffects: (podcastUuid: string, playbackEffects: boolean) =>
        createAction(ActionTypes.UPDATE_PLAYBACK_EFFECTS, { podcastUuid, playbackEffects }),

    updatePlaybackSpeed: (podcastUuid: string, playbackSpeed: number) =>
        createAction(ActionTypes.UPDATE_PLAYBACK_SPEED, { podcastUuid, playbackSpeed }),

    updateAutoArchive: (podcastUuid: string, autoArchive: boolean) =>
        createAction(ActionTypes.UPDATE_AUTO_ARCHIVE, { podcastUuid, autoArchive }),

    updateAutoArchivePlayed: (podcastUuid: string, autoArchivePlayed: boolean) =>
        createAction(ActionTypes.UPDATE_AUTO_ARCHIVE_PLAYED, { podcastUuid, autoArchivePlayed }),
};

export type Actions = ActionsUnion<typeof Actions>;
