import {
    EpisodeYShortMessage,
    SeasonTrailerMessage,
    SeasonXEpisodeYShortMessage,
    SeasonXShortMessage,
} from 'components/messages';
import { EpisodeType } from 'model/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Bonus, Season, SeasonTrailer, Trailer } from './EpisodeTypeText.styled';

const Separator = styled.span`
    margin: 0 4px;
    color: var(--color-text-secondary);
`;

export type Props = {
    season?: number;
    number?: number;
    type?: EpisodeType;
    separator?: React.ReactNode;
    separatorPosition?: 'left' | 'right';
};

const EpisodeTypeText = ({
    season,
    number,
    type,
    separator,
    separatorPosition = 'right',
}: Props) => {
    let content: React.ReactNode = null;

    if (type === EpisodeType.BONUS) {
        content = (
            <Bonus>
                <FormattedMessage id="bonus" />
            </Bonus>
        );
    } else if (type === EpisodeType.TRAILER) {
        if (season && season > 0) {
            content = (
                <SeasonTrailer>
                    <SeasonTrailerMessage season={season} />
                </SeasonTrailer>
            );
        } else {
            content = (
                <Trailer>
                    <FormattedMessage id="trailer" />
                </Trailer>
            );
        }
    } else if (season && number) {
        content = (
            <Season>
                <SeasonXEpisodeYShortMessage season={season} episode={number} />
            </Season>
        );
    } else if (season) {
        content = (
            <Season>
                <SeasonXShortMessage season={season} />
            </Season>
        );
    } else if (number) {
        content = (
            <Season>
                <EpisodeYShortMessage episode={number} />
            </Season>
        );
    }

    if (!content) {
        return null;
    }

    return (
        <>
            {separator && separatorPosition === 'left' && <Separator>{separator}</Separator>}
            {content}
            {separator && separatorPosition === 'right' && <Separator>{separator}</Separator>}
        </>
    );
};

export default EpisodeTypeText;
