import { css } from 'styled-components';

import { blue40, blue60 } from '../styles/colors';

export const A11Y = {
    outlineOutset: css`
        outline: 2px solid ${props => (props.theme.name === 'light' ? blue60 : blue40)};
        outline-offset: 2px;
    `,
    outlineInset: css`
        outline: 2px solid ${props => (props.theme.name === 'light' ? blue60 : blue40)};
        outline-offset: -2px;
    `,
};

export type A11Y = typeof A11Y;
