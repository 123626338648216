import React from 'react';
import { useTheme } from 'styled-components';
import { EpisodePlayButton as EpisodePlayButtonStyled } from './EpisodePlayButton.styled';

type Props = {
    variant?: 'default' | 'upNext';
    size?: number;
    color?: string;
    isPlayerEpisode: boolean;
    isPlaying: boolean;
    isPlayed: boolean;
    percentComplete: number;
    onPlayClick: () => void;
    onPauseClick: () => void;
};

export const EpisodePlayButton: React.FC<Props> = ({
    variant = 'default',
    size = 28,
    color,
    percentComplete,
    isPlaying,
    isPlayed,
    isPlayerEpisode,
    onPlayClick,
    onPauseClick,
}) => {
    const theme = useTheme();
    return (
        <EpisodePlayButtonStyled
            variant={variant}
            size={size}
            color={color ?? theme.tokens['primary-icon-01']}
            playedColor={theme.tokens['primary-icon-02-disabled']}
            percent={percentComplete}
            isPlaying={isPlayerEpisode && isPlaying}
            isPlayed={isPlayed}
            onPlayClick={onPlayClick}
            onPauseClick={onPauseClick}
        />
    );
};
