import { BookmarkList } from 'components/BookmarkList';
import { Button } from 'components/Button';
import { AddBookmarkForm } from 'components/form/bookmarks/AddBookmarkForm';
import { ModalTypes } from 'helper/UiHelper';
import { useDispatch } from 'hooks/react-redux-typed';
import useFormatMessage from 'hooks/useFormatMessage';
import React from 'react';
import { AutoFocusInside } from 'react-focus-on';
import * as fromModalActions from '../../../../redux/actions/modal.actions';
import { Popover, RecentBookmarks, RecentBookmarksHeading } from './AddBookmarkButton.styled';

export type BookmarkPopoverContentProps = {
    time: number;
    episodeUuid: string;
    podcastUuid: string;
    bookmarksCount: number;
    onClose: () => void;
};

export const BookmarkPopoverContent = ({
    time,
    episodeUuid,
    podcastUuid,
    bookmarksCount,
    onClose,
}: BookmarkPopoverContentProps) => {
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const handleManageBookmarksClick = () => {
        dispatch(
            fromModalActions.Actions.showModal(ModalTypes.listBookmarks, {
                podcastUuid,
                episodeUuid,
            }),
        );
        onClose();
    };

    return (
        <Popover>
            {bookmarksCount > 0 && (
                <RecentBookmarks>
                    <RecentBookmarksHeading>
                        <h1>{formatMessage('recent-bookmarks')}</h1>
                        <Button kind="text" onClick={handleManageBookmarksClick}>
                            {formatMessage('manage-all-bookmarks')}
                        </Button>
                    </RecentBookmarksHeading>
                    <BookmarkList
                        podcastUuid={podcastUuid}
                        episodeUuid={episodeUuid}
                        showActions={false}
                        limit={3}
                        eventSource="add_bookmark_popup"
                    />
                </RecentBookmarks>
            )}
            <AutoFocusInside>
                <AddBookmarkForm
                    time={time}
                    episodeUuid={episodeUuid}
                    podcastUuid={podcastUuid}
                    onSubmit={onClose}
                />
            </AutoFocusInside>
        </Popover>
    );
};
