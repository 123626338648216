import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useRef, useState } from 'react';
import { TableWrapper, VirtualList, VirtualRowWrapper } from './VirtualSortableTable.styled';

interface VirtualSortableTableProps<T> {
    items: T[];
    getItemId: (item: T) => string;
    renderItem: (item: T, virtualRow: any) => React.ReactNode;
    renderDragOverlay?: (item: T) => React.ReactNode;
    onSortStart?: (event: DragStartEvent) => void;
    onSortOver?: (event: DragOverEvent) => void;
    onSortEnd?: (event: DragEndEvent) => void;
    estimateSize?: number;
    overscan?: number;
}

const VirtualSortableTable = <T,>({
    items,
    getItemId,
    renderItem,
    renderDragOverlay,
    onSortStart,
    onSortOver,
    onSortEnd,
    estimateSize = 82,
    overscan = 0,
}: VirtualSortableTableProps<T>) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const [activeId, setActiveId] = useState<string | null>(null);

    const virtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => estimateSize,
        overscan,
    });

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 200,
                tolerance: 1000,
            },
        }),
    );

    const handleDragStart = (event: DragStartEvent) => {
        const { active } = event;
        setActiveId(active.id as string);
        onSortStart?.(event);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            onSortEnd?.(event);
        }

        setActiveId(null);
    };

    const activeItem = activeId ? items.find(item => getItemId(item) === activeId) : null;

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragOver={onSortOver}
            onDragStart={handleDragStart}
            modifiers={[restrictToVerticalAxis]}
        >
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <TableWrapper ref={parentRef}>
                    <SortableContext
                        items={items.map(getItemId)}
                        strategy={verticalListSortingStrategy}
                    >
                        <VirtualList $height={virtualizer.getTotalSize()}>
                            {virtualizer.getVirtualItems().map(virtualRow => (
                                <VirtualRowWrapper
                                    key={virtualRow.key}
                                    $isDragging={activeId !== null}
                                    style={{
                                        transform: `translateY(${virtualRow.start}px)`,
                                    }}
                                >
                                    {renderItem(items[virtualRow.index], virtualRow)}
                                </VirtualRowWrapper>
                            ))}
                        </VirtualList>
                    </SortableContext>
                </TableWrapper>
            </div>
            <DragOverlay>
                {activeItem && renderDragOverlay && renderDragOverlay(activeItem)}
            </DragOverlay>
        </DndContext>
    );
};

export default VirtualSortableTable;
