import clamp from 'lodash/clamp';
import React from 'react';
import {
    MuteButton,
    VolumeBar,
    VolumeBarFill,
    VolumeBarTouch,
    VolumeKnob,
    VolumeSliderContainer,
} from './styled';

class VolumeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            seekingVolume: -1,
            isSeeking: false,
        };
    }

    onWheel = e => {
        const { volume } = this.props;
        if (e.deltaY > 0) {
            this.toggleVolume(volume - 0.05);
        } else {
            this.toggleVolume(volume + 0.05);
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.volume !== this.props.volume ||
            nextProps.muted !== this.props.muted ||
            nextState.seekingVolume !== this.props.seekingVolume ||
            nextState.isSeeking !== this.props.isSeeking
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.volume !== this.props.volume) {
            this.setState({ seekingVolume: -1 });
        }
    }

    componentWillUnmount() {
        this.unbindEvents();
    }

    render() {
        const { volume, muted } = this.props;
        const { seekingVolume, isSeeking } = this.state;

        let position = seekingVolume >= 0 ? seekingVolume : muted ? 0 : volume;
        if (position < 0) {
            position = 0;
        }
        const size = position * 55;
        return (
            <VolumeSliderContainer
                onWheel={this.onWheel}
                className="volume-slider"
                muted={muted}
                $seeking={isSeeking}
            >
                <MuteButton
                    data-testid="player-controls-mute-button"
                    type="button"
                    aria-label="Muted"
                    aria-checked={!muted}
                    role="switch"
                    muted={muted}
                    $position={position}
                    onClick={this.mutedClick.bind(this)}
                />
                <VolumeBar>
                    <VolumeBarFill
                        size={size}
                        ref={ref => {
                            this.bar = ref;
                        }}
                    />
                    <VolumeKnob
                        data-testid="player-controls-volume-slider"
                        role="slider"
                        aria-label="Player Volume"
                        tabIndex="0"
                        aria-orientation="vertical"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow={volume}
                        size={size}
                        $seeking={isSeeking}
                        onKeyDown={this.onKeyDown}
                        ref={ref => {
                            this.knob = ref;
                        }}
                    />
                </VolumeBar>
                <VolumeBarTouch onMouseDown={this.onSliderMouseDown.bind(this)} />
            </VolumeSliderContainer>
        );
    }

    onKeyDown = e => {
        const { volume } = this.props;
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowRight':
                this.toggleVolume(volume + 0.1);
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                this.toggleVolume(volume - 0.1);
                break;
            case 'Home':
                this.toggleVolume(0);
                break;
            case 'End':
                this.toggleVolume(1);
                break;
            // Should also support left and right
            default:
                return;
        }
        e.preventDefault();
        e.stopPropagation();
    };

    toggleVolume = amount => {
        const updatedVolume = clamp(amount, 0, 1);
        this.props.onVolumeChanged(updatedVolume);
        this.setState({ seekingVolume: updatedVolume });
    };

    mutedClick() {
        this.props.onMuteChanged(!this.props.muted);
    }

    onSliderMouseDown(event) {
        if (this.props.muted) {
            return;
        }
        event.preventDefault();
        this.onMouseMove(event);
        this.bindEvents();
        this.setState({ isSeeking: true });
    }

    bindEvent(type, method) {
        const methodBind = method.bind(this);
        document.addEventListener(type, methodBind, false);
        return () => {
            document.removeEventListener(type, methodBind, false);
        };
    }

    bindEvents() {
        this.unbindEvents();
        this.onMouseMoveUnbind = this.bindEvent('mousemove', this.onMouseMove);
        this.onMouseUpUnbind = this.bindEvent('mouseup', this.onMouseUp);
    }

    unbindEvents() {
        if (this.onMouseMoveUnbind) {
            this.onMouseMoveUnbind();
            this.onMouseMoveUnbind = null;
        }
        if (this.onMouseUpUnbind) {
            this.onMouseUpUnbind();
            this.onMouseUpUnbind = null;
        }
    }

    onMouseUp(event) {
        this.unbindEvents();
        this.mouseMove(event, false, true);
        this.setState({ isSeeking: false });
    }

    onMouseMove(event) {
        this.mouseMove(event, true, false);
    }

    mouseMove(event) {
        const trackX = this.bar.getBoundingClientRect().left;
        let position = event.pageX - trackX; // - (this.knob.offsetWidth / 2);
        if (position < 0) {
            position = 0;
        } else if (position > 55) {
            position = 55;
        }

        const volume = position === 0 ? 0 : position / 55;
        this.props.onVolumeChanged(volume);
        this.setState({ seekingVolume: volume });
    }
}

export default VolumeSlider;
