import { ReactNode } from 'react';
import styled from 'styled-components';
import { gridSize, Typography } from 'styles';
import { Popup } from '../Popup';

export const SupportPopupWrapper = styled(Popup)<{ children: ReactNode }>`
    font-size: 14px;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
    .popup-window {
        background: ${props => props.theme.legacyScss['theme-popup-background']};
    }
    .support-form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1 {
        font-weight: 700;
        font-size: 25px;
        letter-spacing: -0.33px;
        text-align: center;
        margin: 0 0 16px;
    }
    p {
        text-align: center;
        line-height: 21px;
        padding: 0px 24px 0px 24px;
        margin: 0 0 30px;
    }
    .support-form {
        margin-bottom: 16px;
        display: flex;
        text-wrap: balance;

        .reason {
            padding: 0 14px;
            cursor: pointer;
            input {
                margin-right: 6px;
            }
        }
    }
    textarea {
        font-size: 14px;
        height: 186px;
        width: 100%;
        margin-bottom: 8px;
        border-radius: 5px;
        resize: none;
        outline: none;
        padding: 8px;
        color: ${props => props.theme.legacyScss['theme-text-primary']};
        border: 1px solid ${props => props.theme.tokens['primary-ui-05']};
        background-color: transparent;
    }
    .sent {
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .send-failed {
        color: #f43e37;
        text-align: center;
        font-size: 14.4px;

        a {
            color: #03a9f4;
            &:hover {
                color: #50d0f1;
            }
            transition: all 0.1s ease;
        }
    }
    .mail-sent {
        width: 74px;
        height: 74px;
        background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2274%22%20height%3D%2274%22%20viewBox%3D%220%200%2074%2074%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22a%22%20x1%3D%22100%25%22%20x2%3D%220%25%22%20y1%3D%220%25%22%20y2%3D%22100%25%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%2307D4FA%22%2F%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%2303A9F4%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20cx%3D%2237%22%20cy%3D%2237%22%20r%3D%2237%22%20fill%3D%22url(%23a)%22%2F%3E%3Cg%20stroke%3D%22%23FFF%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%223%22%3E%3Cpath%20d%3D%22M44%2043l6%205.5L61%2036%22%2F%3E%3Cpath%20stroke-linejoin%3D%22round%22%20stroke-opacity%3D%22.5%22%20d%3D%22M37.554%2046.332l-7.456.652c-1.3.114-2.449-.865-2.564-2.185l-1.325-15.142c-.115-1.32.845-2.483%202.146-2.597l21.191-1.854c1.3-.114%202.449.864%202.564%202.185l.593%206.774%22%2F%3E%3Cpath%20opacity%3D%22.5%22%20d%3D%22M32.5%2032.72l7.715%203.34%206.232-4.56M19.5%2032c-2.667%200-5.167.667-7.5%202m7.5%204.3c-2.667%200-5.167.667-7.5%202m9.5%203.7c-2.667%200-5.167.667-7.5%202%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E')
            no-repeat;
    }
`;

export const Content = styled.div<{ $isBlurred: boolean }>`
    padding: 26px;
    height: 500px;
    transition:
        filter 100ms ease-out,
        background 100ms ease-out;
    ${props =>
        props.$isBlurred &&
        `
        filter: blur(2px);
        background: ${props.theme.tokens['primary-ui-06']};
        `}
`;

export const SendLogsToggle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    svg {
        margin-left: 6px;
    }
    button {
        display: flex;
        align-items: center;
        margin-right: auto;
        color: ${props => props.theme.tokens['primary-text-02']};
        &:hover {
            color: ${props => props.theme.tokens['primary-text-02-selected']};
        }
    }
`;

export const DebugLogWrapper = styled.div`
    background: ${props => props.theme.legacyScss['theme-popup-background']};
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 40px;
    right: 40px;
    left: 40px;
    bottom: 40px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;

    textarea {
        margin: 0;
        flex-grow: 1;
        border-radius: 0 0 4px 4px;
        border-width: 1px 0 0 0;
        border-color: ${props => props.theme.tokens['primary-ui-05']};
        padding: ${2 * gridSize}px;
        font-size: 12px;
        background: transparent;
    }

    .close-button {
        position: absolute;
        right: ${2 * gridSize}px;
        top: ${2 * gridSize}px;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
`;

export const DebugLogIntro = styled.div`
    ${Typography.Desktop.H70}
    margin: ${gridSize * 2}px ${gridSize * 5}px ${gridSize * 2}px ${gridSize * 2}px;
`;
