import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { MOBILE_WIDTH, VIEWPORT_MINIMUM_WIDTH } from '../../../model/page';
import { Icon } from '../../Icon';
import { ActionsColumn, ArchivedStatus, HoverButtons } from './HoverActions.styled';

interface Props {
    children: React.ReactNode;
    isDeleted?: boolean;
}

const StyledActionsColumn = styled(ActionsColumn)`
    .episode-row & {
        @media (max-width: ${MOBILE_WIDTH}px) {
            display: none;
        }

        @media (min-width: ${MOBILE_WIDTH}px) and (max-width: ${VIEWPORT_MINIMUM_WIDTH}px) {
            display: none;
        }
    }
`;

export const HoverActions: React.FC<Props> = ({ children, isDeleted }) => {
    return (
        <StyledActionsColumn>
            <HoverButtons>{children}</HoverButtons>
            {isDeleted && (
                <ArchivedStatus>
                    <Icon id="archive" size={20} />
                    <FormattedMessage id="archived" />
                </ArchivedStatus>
            )}
        </StyledActionsColumn>
    );
};
