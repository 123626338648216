import { Icon } from 'components/Icon';
import React, { useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { FormattedMessage, useIntl } from 'react-intl';
import FeatureLock from '../../../components/FeatureLock';
import { IconButton } from './AddBookmarkButton/AddBookmarkButton.styled';
import { BookmarkPopoverContent } from './AddBookmarkButton/BookmarkPopoverContent';
import { Shelf } from './PlayerControls.styled';

const PlayerShelf = ({ episode, openShare, archive, markAsPlayed, starEpisode, unstarEpisode }) => {
    const intl = useIntl();
    const [showBookmarkPopover, setShowBookmarkPopover] = useState(false);

    return (
        <>
            <Shelf>
                <FeatureLock
                    requires="subscription"
                    title={<FormattedMessage id="feature-bookmarks-unavailable-title" />}
                    description={<FormattedMessage id="feature-bookmarks-unavailable-description" />}
                    source="shelf_bookmark"
                >
                    <IconButton
                        onClick={() => setShowBookmarkPopover(!showBookmarkPopover)}
                        aria-label={intl.formatMessage({ id: 'bookmarks' })}
                    >
                        <Icon id="bookmark" />
                    </IconButton>
                </FeatureLock>
                <IconButton
                    onClick={() => openShare(
                        episode.uuid,
                        episode.podcastUuid,
                        episode.title,
                        episode.duration,
                        episode.url
                    )}
                    aria-label={intl.formatMessage({ id: 'share' })}
                >
                    <Icon id="share" />
                </IconButton>
                <FeatureLock
                    requires="account"
                    source="shelf_archive"
                >
                    <IconButton
                        onClick={() => archive(episode.uuid, episode.podcastUuid)}
                        aria-label={intl.formatMessage({ id: 'archive' })}
                    >
                        <Icon id="archive" />
                    </IconButton>
                </FeatureLock>
                <FeatureLock
                    requires="account"
                    source="shelf_mark_as_played"
                >
                    <IconButton
                        onClick={() => markAsPlayed(episode.uuid, episode.podcastUuid)}
                        aria-label={intl.formatMessage({ id: 'mark-as-played' })}
                    >
                        <Icon id="mark-as-played" />
                    </IconButton>
                </FeatureLock>
                <FeatureLock
                    requires="account"
                    source="shelf_star"
                >
                    <IconButton
                        onClick={() => {
                            if (episode.starred) {
                                unstarEpisode(episode.uuid, episode.podcastUuid);
                            } else {
                                starEpisode(episode.uuid, episode.podcastUuid);
                            }
                        }}
                        aria-label={intl.formatMessage({ id: episode.starred ? 'unstar' : 'star' })}
                    >
                        <Icon id={episode.starred ? 'star-full' : 'star-empty'} />
                    </IconButton>
                </FeatureLock>
            </Shelf>
            {showBookmarkPopover && (
                <FocusOn onEscapeKey={() => setShowBookmarkPopover(false)} onClickOutside={() => setShowBookmarkPopover(false)}>
                    <BookmarkPopoverContent
                        time={episode.playedUpTo}
                        episodeUuid={episode.uuid}
                        podcastUuid={episode.podcastUuid}
                        bookmarksCount={episode.bookmarks?.length || 0}
                        onClose={() => setShowBookmarkPopover(false)}
                    />
                </FocusOn>
            )}
        </>
    );
};

export default PlayerShelf;
