import { AnimatedPlayButton } from 'pages/LoggedInPageChrome/PlayerControls/AnimatedPlayButton';
import React, { RefObject, useRef } from 'react';
import { useTheme } from 'styled-components';
import { ShareOptions } from '../ShareOptions';
import { ShareProps } from '../SharePopup';
import {
    AnimatedPlayButtonWrapper,
    TimelineWrapper,
    ViewPlayPositionIndicator,
    WaveFormContainer,
    WaveFormSvg,
} from '../SharePopup.styled';
import { useAudioControl } from '../hooks/useAudioControl';
import { useWaveform } from '../hooks/useWaveform';
import { ClipViewContainer } from './ClipView.styled';

interface Props extends ShareProps {
    startTime: number;
    endTime: number;
}

function ClipView({ startTime, endTime, ...props }: Props) {
    const { audioUrl, duration } = props;
    const theme = useTheme();

    const waveFormContainerRef: RefObject<HTMLDivElement> = useRef(null);
    const waveFormSvgRef: RefObject<SVGSVGElement> = useRef(null);

    useWaveform({ ref: waveFormSvgRef, url: audioUrl, theme, duration });

    const { isClipPlaying, handlePlayClicked, handlePauseClicked, playPosition } = useAudioControl({
        audioUrl,
        startTime,
        endTime,
        duration,
    });

    return (
        <ClipViewContainer>
            <TimelineWrapper>
                <WaveFormContainer ref={waveFormContainerRef}>
                    <WaveFormSvg ref={waveFormSvgRef} xmlns="http://www.w3.org/2000/svg" />
                    <ViewPlayPositionIndicator
                        style={{ left: `${playPosition}%` }}
                        $isClipPlaying={isClipPlaying}
                    />
                </WaveFormContainer>
                <AnimatedPlayButtonWrapper>
                    <AnimatedPlayButton
                        playing={isClipPlaying}
                        onPlayClicked={handlePlayClicked}
                        onPauseClicked={handlePauseClicked}
                    />
                </AnimatedPlayButtonWrapper>
            </TimelineWrapper>
            <ShareOptions {...props} />
        </ClipViewContainer>
    );
}

export default ClipView;
