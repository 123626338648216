import { PodcastImage } from 'components';
import Checkbox from 'components/Checkbox';
import { Icon } from 'components/Icon';
import Input from 'components/Input';
import ScreenReaderText from 'components/format/ScreenReaderText';
import { MatchCount } from 'components/messages';
import { cleanForSort, compareDates } from 'helper/StringHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import useTracks from 'hooks/useTracks';
import LiquidMetal from 'liquidmetal';
import { Folder, PodcastListPodcast } from 'model/types';
import React, {
    ChangeEvent,
    ChangeEventHandler,
    KeyboardEvent,
    MouseEvent,
    RefObject,
    useEffect,
    useState,
} from 'react';
import { FocusOn, MoveFocusInside } from 'react-focus-on';
import { TranslatableStringKey } from 'translations/glotpress';
import { useSelector } from '../../../../hooks/react-redux-typed';
import {
    getFolderColorValues,
    getFoldersByPodcastUuid,
    getSubscribedPodcasts,
} from '../../../../redux/reducers/selectors';
import {
    EmptyMessage,
    Fieldset,
    FilterOptions,
    Image,
    LabelContent,
    PodcastAuthor,
    PodcastFolder,
    PodcastText,
    PodcastTitle,
    PodcastsWrapper,
    SortButton,
    SortOption,
    SortOptions,
} from './FolderPodcastsSelector.styled';

export type Props = {
    currentFolderUuid?: string;
    selected: string[];
    onChange: (podcastUuids: string[]) => void;
    innerRef?: RefObject<HTMLInputElement>;
    onFilteredPodcastsChange?: (podcasts: PodcastListPodcast[]) => void;
};

const SORT_BY_BEST_MATCH = 0;
const SORT_BY_PODCAST_NAME = 1;
const SORT_BY_RELEASE_DATE = 2;
const SORT_BY_DATE_ADDED = 3;

const SORT_LABELS: Record<number, string> = {
    [SORT_BY_BEST_MATCH]: 'best_match',
    [SORT_BY_PODCAST_NAME]: 'name',
    [SORT_BY_RELEASE_DATE]: 'episode_release_date',
    [SORT_BY_DATE_ADDED]: 'date_added',
};

const FolderPodcastsSelector = ({
    currentFolderUuid,
    innerRef,
    onChange,
    onFilteredPodcastsChange,
    selected,
}: Props) => {
    const formatMessage = useFormatMessage();
    const { recordEvent } = useTracks();
    const foldersByPodcastUuid = useSelector(getFoldersByPodcastUuid) as Record<string, Folder>;
    const folderColorValues = useSelector(getFolderColorValues) as string[];
    const podcasts = useSelector(getSubscribedPodcasts);

    const [searchTerm, setSearchTerm] = useState('');
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [sortBy, setSortBy] = useState(SORT_BY_PODCAST_NAME);

    const filterPodcasts = (search: string, sort: number) =>
        podcasts
            .map(podcast => ({
                podcast,
                matchScore: Math.max(
                    LiquidMetal.score(`${podcast.title}`, search),
                    LiquidMetal.score(`${podcast.author}`, search),
                ),
            }))
            .filter(({ matchScore }) => matchScore >= 0.5)
            .sort((a, b) => {
                // Podcasts in folders other than the current one, should be sorted last

                const aIsInAnotherFolder =
                    foldersByPodcastUuid[a.podcast.uuid] &&
                    foldersByPodcastUuid[a.podcast.uuid].uuid !== currentFolderUuid;
                const bIsInAnotherFolder =
                    foldersByPodcastUuid[b.podcast.uuid] &&
                    foldersByPodcastUuid[b.podcast.uuid].uuid !== currentFolderUuid;

                if (aIsInAnotherFolder && !bIsInAnotherFolder) {
                    return 1;
                }
                if (bIsInAnotherFolder && !aIsInAnotherFolder) {
                    return -1;
                }

                switch (sort) {
                    case SORT_BY_BEST_MATCH:
                        return a.matchScore >= b.matchScore ? -1 : 1;
                    case SORT_BY_PODCAST_NAME:
                        return cleanForSort(a.podcast.title) <= cleanForSort(b.podcast.title)
                            ? -1
                            : 1;
                    case SORT_BY_RELEASE_DATE:
                        return compareDates(
                            a.podcast.lastEpisodePublished,
                            b.podcast.lastEpisodePublished,
                            true,
                        );
                    case SORT_BY_DATE_ADDED:
                        return compareDates(a.podcast.dateAdded, b.podcast.dateAdded, false);
                    default:
                        return 1;
                }
            })
            .map(({ podcast }) => podcast);

    const [filteredPodcasts, setFilteredPodcasts] = useState<PodcastListPodcast[]>(
        filterPodcasts(searchTerm, sortBy),
    );

    useEffect(() => {
        if (onFilteredPodcastsChange) {
            onFilteredPodcastsChange(filteredPodcasts);
        }
    }, [filteredPodcasts]);

    const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.currentTarget;
        onChange(checked ? selected.concat(value) : selected.filter(uuid => uuid !== value));
    };

    // Pressing Enter in an <input> submits the parent form. This isn't desired for the search input.
    const preventFormSubmission = (e: KeyboardEvent<HTMLInputElement>) =>
        e.key === 'Enter' && e.preventDefault();

    const handleSortToggleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSortOptions(!showSortOptions);
    };

    const handleSortByChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSortBy = parseInt(e.currentTarget.value, 10);
        setSortBy(newSortBy);
        setFilteredPodcasts(filterPodcasts(searchTerm, newSortBy));
        recordEvent('folder_podcast_picker_filter_changed', { sort_order: SORT_LABELS[newSortBy] });
    };

    const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
        const newTerm = e.currentTarget.value;
        let newSortBy = sortBy;
        if (!searchTerm && !!newTerm) {
            // If a search term was just added, switch to sort by best match
            newSortBy = SORT_BY_BEST_MATCH;
            setSortBy(newSortBy);
            recordEvent('folder_podcast_picker_search_performed');
        } else if (!newTerm && sortBy === SORT_BY_BEST_MATCH) {
            // If there's no new term, and we're sorting by match, switch to sort by name instead
            newSortBy = SORT_BY_PODCAST_NAME;
            setSortBy(newSortBy);
        }
        if (!!searchTerm && !newTerm) {
            recordEvent('folder_podcast_picker_search_cleared');
        }
        setSearchTerm(newTerm);
        setFilteredPodcasts(filterPodcasts(newTerm, newSortBy));
    };

    const getPodcastAriaLabel = (podcast: PodcastListPodcast) => {
        let label = podcast.title;
        if (foldersByPodcastUuid[podcast.uuid]) {
            const currentlyInFolder = formatMessage('currently-in-folder', {
                folderName: foldersByPodcastUuid[podcast.uuid].name,
            });
            label = `${label}, ${currentlyInFolder}`;
        }
        return label;
    };

    const sortOptions: { value: number; id: TranslatableStringKey }[] = [
        { value: SORT_BY_PODCAST_NAME, id: 'name' },
        { value: SORT_BY_RELEASE_DATE, id: 'table-release-date' },
        { value: SORT_BY_DATE_ADDED, id: 'date-added' },
    ];
    if (searchTerm) {
        sortOptions.unshift({ value: SORT_BY_BEST_MATCH, id: 'best-match' });
    }

    return (
        <Fieldset>
            <legend>{formatMessage('podcasts')}</legend>

            {/* <ScreenReaderText aria-live="polite">
                <span key={searchTerm}>{filteredPodcasts.length} matches</span>
            </ScreenReaderText> */}

            <FilterOptions>
                <Input
                    type="search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder={formatMessage('search-podcasts')}
                    aria-label={formatMessage('search-podcasts')}
                    onKeyDown={preventFormSubmission}
                    onKeyUp={preventFormSubmission}
                    ref={innerRef}
                />
                <ScreenReaderText role="status">
                    {/* Use a key so that the element is re-mounted and re-announced when the search term changes, even if the count is the same */}
                    <span key={searchTerm}>
                        <MatchCount count={filteredPodcasts.length} />
                    </span>
                </ScreenReaderText>
                <FocusOn
                    enabled={showSortOptions}
                    scrollLock={false}
                    noIsolation
                    onClickOutside={() => setShowSortOptions(false)}
                    onEscapeKey={() => setShowSortOptions(false)}
                >
                    <SortButton kind="text" onClick={handleSortToggleClick}>
                        <Icon
                            id="sort"
                            aria-label={formatMessage(
                                showSortOptions ? 'close-sort-options' : 'show-sort-options',
                            )}
                        />
                    </SortButton>
                    {showSortOptions && (
                        <MoveFocusInside>
                            <SortOptions>
                                <legend>{formatMessage('sort-by')}</legend>
                                {sortOptions.map(({ value, id }) => (
                                    <label key={value}>
                                        <input
                                            type="radio"
                                            name="folder-podcasts-selector--sort-by"
                                            value={value}
                                            onChange={handleSortByChange}
                                            checked={sortBy === value}
                                        />
                                        <SortOption>
                                            {formatMessage(id)}
                                            <Icon id="tick" aria-hidden />
                                        </SortOption>
                                    </label>
                                ))}
                            </SortOptions>
                        </MoveFocusInside>
                    )}
                </FocusOn>
            </FilterOptions>
            <PodcastsWrapper>
                {filteredPodcasts.length === 0 && (
                    <EmptyMessage>{formatMessage('no-matching-podcasts')}</EmptyMessage>
                )}
                {filteredPodcasts.map(podcast => (
                    <LabelContent key={podcast.uuid}>
                        <Image>
                            <PodcastImage uuid={podcast.uuid} />
                        </Image>
                        <PodcastText>
                            {foldersByPodcastUuid[podcast.uuid] &&
                                foldersByPodcastUuid[podcast.uuid].uuid !== currentFolderUuid && (
                                    <PodcastFolder
                                        color={
                                            folderColorValues[
                                                foldersByPodcastUuid[podcast.uuid].color
                                            ]
                                        }
                                    >
                                        <Icon id="folder" size={16} />
                                        {foldersByPodcastUuid[podcast.uuid].name}
                                    </PodcastFolder>
                                )}
                            <PodcastTitle>{podcast.title}</PodcastTitle>
                            <PodcastAuthor>{podcast.author}</PodcastAuthor>
                        </PodcastText>
                        <Checkbox
                            value={podcast.uuid}
                            onChange={handleSelectionChange}
                            checked={selected.includes(podcast.uuid)}
                            onKeyDown={preventFormSubmission}
                            onKeyUp={preventFormSubmission}
                            aria-label={getPodcastAriaLabel(podcast)}
                        ></Checkbox>
                    </LabelContent>
                ))}
            </PodcastsWrapper>
            <ScreenReaderText role="status">
                <span key={selected.length}>
                    {formatMessage('selected-count', { count: selected.length })}
                </span>
            </ScreenReaderText>
        </Fieldset>
    );
};

export default FolderPodcastsSelector;
