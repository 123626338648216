import styled from 'styled-components';
import { PAGE_MAX_WIDTH } from '../../../model/page';
import { Typography } from '../../../styles/typography';

export const StyledDiscoverCollection = styled.section<{ $previousIsCollection: boolean }>`
    .separator {
        width: 80%;
    }
    + .separator {
        opacity: 0;
    }
    overflow: hidden;
    ${props => props.$previousIsCollection && `margin-top: 40px`};
`;

export const DiscoverCollectionContent = styled.div`
    height: 213px;
    padding-bottom: 32px;
    position: relative;
    display: flex;

    cursor: pointer;
`;

export const CollectionImage = styled.div`
    margin: 39px 25px 0 1px;
    width: 112px;
    flex-shrink: 0;
`;

export const PodcastCollectionText = styled.div`
    padding-top: 39px;
    padding-bottom: 32px;

    flex-grow: 1;
`;

export const CollectionSubtitle = styled.div<{ $accent: string }>`
    color: ${props => props.$accent};
    width: 100%;
    height: 29px;
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${PAGE_MAX_WIDTH}px) {
        padding-right: 0px;
    }

    p {
        display: flex;
        align-self: flex-center;
        wrap: nowrap;
        min-width: 100px;

        ${Typography.Desktop['H70-TBC']};

        padding-top: 2px;
        padding-bottom: 6px;
    }
    .arrow-button {
        display: flex;
        margin-right: 6px;
        height: 29px;
        align-self: flex-center;
    }
`;

export const CollectionTitle = styled.div`
    ${Typography.Desktop.H30}
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.tokens['primary-text-01']};
    padding-bottom: 4px;
    margin-bottom: 0;
`;

export const CollectionDescription = styled.div`
    ${Typography.Desktop.H70};
    height: 40px;
    padding-right: 40px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.tokens['primary-text-02']};
`;

export const CollageImageContainer = styled.div`
    height: 213px;
    max-width: 456px;
    @media only screen and (max-width: ${PAGE_MAX_WIDTH}px) {
        max-width: 300px;
    }
    text-align: right;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    img {
        overflow: hidden;
        height: 213px;
    }
`;
