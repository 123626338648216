import { Icon } from 'components/Icon';
import React from 'react';
import { withTheme } from 'styled-components';
import { tooltipAndLabel } from 'util/tooltip';
import * as UiHelper from '../../../helper/UiHelper';
import withUseId from '../../../helper/withUseId';
import { WebPlayerTheme } from '../../../model/theme';
import { PopupMenuWrapper } from './PopupMenu.styled';
import { PopupMenuContextMenu } from './PopupMenuContextMenu';

export enum PopupMenuVariant {
    DEFAULT = 'default',
    BORDERED = 'bordered',
}

export type Props = {
    color?: string;
    iconColor?: string;
    iconHoverColor?: string;
    rowHeight?: number;
    theme?: WebPlayerTheme;
    iconName?: string;
    iconNameOpen?: string;
    text?: string;
    wrapperClassName?: string;
    itemClassName?: string;
    popupClassName?: string;
    menuTitle?: string;
    horizontalPosition?: 'left' | 'right' | 'center';
    offsets?: Record<string, number>;
    maxHeight?: number;
    positionRelativeToWindow?: boolean;
    'aria-label'?: string;
    id: string;
    showTooltip?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    children?: React.ReactNode;
    popupVariant?: PopupMenuVariant;
};

type StateProps = {
    isOpen: boolean;
    mouseOver: boolean;
    windowHeight: number;
    windowWidth: number;
};

export class PopupMenuClass extends React.Component<Props, StateProps> {
    static defaultProps = {
        rowHeight: 51,
        iconName: 'disclosure',
    };

    buttonAnchor: React.MutableRefObject<HTMLButtonElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            mouseOver: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        };
        this.buttonAnchor = React.createRef() as React.MutableRefObject<HTMLButtonElement>;
    }

    componentDidUpdate(prevProps: Props, prevState: StateProps) {
        if (!this.state.isOpen && prevState.isOpen) {
            this.buttonAnchor.current.focus();
        }
    }

    getButtonSvgId = () => {
        if (this.state.isOpen && this.props.iconNameOpen) {
            switch (this.props.iconNameOpen) {
                case 'chevron-up-sm':
                    return 'chevron-up-sm';
                default:
                    return 'meatballs';
            }
        }

        switch (this.props.iconName) {
            case 'sort':
                return 'sort';
            case 'chevron-down-sm':
                return 'chevron-down-sm';
            case 'sparkle-sm':
                return 'sparkle-sm';
            case 'disclosure':
            default:
                return 'meatballs';
        }
    };

    tooltipOptions() {
        if (!this.props.showTooltip) {
            return {};
        }
        return tooltipAndLabel(this.props['aria-label']);
    }

    render() {
        const color = this.state.mouseOver
            ? this.props.theme?.tokens['action-01-active']
            : this.props.theme?.tokens['action-01'];
        return (
            <PopupMenuWrapper
                className={this.props.wrapperClassName}
                $popupVariant={this.props.popupVariant}
            >
                <button
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onKeyDown={this.onKeyDown}
                    onClick={this.onTogglePopup.bind(this)}
                    aria-label={this.props['aria-label']}
                    ref={this.buttonAnchor}
                    {...this.tooltipOptions()}
                >
                    {this.props.text && <span>{this.props.text}</span>}
                    <Icon id={this.getButtonSvgId()} color={color} />
                </button>

                {this.state.isOpen && (
                    <PopupMenuContextMenu
                        anchorEl={this.buttonAnchor.current as Element}
                        onClosed={this.onClose}
                        ariaAttributes={{
                            'aria-labelledby': this.props.id,
                        }}
                        color={this.props.color}
                        positionRelativeToWindow={this.props.positionRelativeToWindow}
                        horizontalPosition={this.props.horizontalPosition}
                        offsets={this.props.offsets}
                        menuTitle={this.props.menuTitle}
                        maxHeight={this.props.maxHeight}
                        popupClassName={this.props.popupClassName}
                        itemClassName={this.props.itemClassName}
                    >
                        {this.props.children}
                    </PopupMenuContextMenu>
                )}
            </PopupMenuWrapper>
        );
    }

    onKeyDown = (event: React.KeyboardEvent) => {
        UiHelper.onKeyDownEnter(event, () => {
            this.onTogglePopup(event);
        });
    };

    onMouseEnter = () => {
        this.setState({ mouseOver: true });
    };

    onMouseLeave = () => {
        this.setState({ mouseOver: false });
    };

    onTogglePopup = (event: Event | React.SyntheticEvent) => {
        UiHelper.stopPropagation(event);
        this.setState({ isOpen: !this.state.isOpen });
        this.state.isOpen ? this.props.onClose?.() : this.props.onOpen?.();
    };

    onClose = (event: Event | React.SyntheticEvent) => {
        if (event) UiHelper.stopPropagation(event);
        this.setState({ isOpen: false });
        this.props.onClose?.();
    };

    closeMenu = () => {
        this.setState({ isOpen: false });
        this.props.onClose?.();
    };

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({
            isOpen: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    };
}

export default withTheme(withUseId(PopupMenuClass)) as React.ComponentType<
    Omit<Props, 'theme' | 'id'> & { ref?: React.Ref<PopupMenuClass> }
>;
