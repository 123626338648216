import { connect } from 'react-redux';
import * as fromSettingsActions from '../../../redux/actions/settings.actions';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import * as fromUploadedFilesActions from '../../../redux/actions/uploaded-files.actions';
import * as fromUserActions from '../../../redux/actions/user.actions';
import { RootState } from '../../../redux/reducers';
import { getSubscriptionTier, hasPaidSubscription } from '../../../redux/reducers/selectors';
import ProfileMenu from './ProfileMenu';

// Unfortunately the account stats are returned as strings not numbers
export const mapStateToProps = (state: RootState) => {
    const usedSize = Number(
        state.uploadedFiles.data &&
            state.uploadedFiles.data.account &&
            state.uploadedFiles.data.account.usedSize,
    );
    const totalSize = Number(
        state.uploadedFiles.data &&
            state.uploadedFiles.data.account &&
            state.uploadedFiles.data.account.totalSize,
    );

    const percentageFullPrecise = (100 * usedSize) / totalSize;
    const uploadedFilesPercent = percentageFullPrecise.toFixed(0);

    const uploadedFilesNumber = Number(
        state.uploadedFiles.data &&
            state.uploadedFiles.data.account &&
            state.uploadedFiles.data.account.totalFiles,
    );

    return {
        uploadedFilesPercent,
        uploadedFilesPercentExact: percentageFullPrecise,
        uploadedFilesNumber,
        uploadedFilesSuspended: state.uploadedFiles.suspended,
        subscriptionAutoRenewing: state.subscription.data.autoRenewing,
        subscriptionExpiryDate: state.subscription.data.expiryDate,
        tier: getSubscriptionTier(state),
        isSubscriber: hasPaidSubscription(state),
    };
};

export const mapDispatchToProps = {
    recordEvent: fromTracksActions.Actions.recordEvent,
    toggleTheme: fromSettingsActions.Actions.toggleTheme,
    signOut: fromUserActions.Actions.signOut,
    fetchUploadedFilesUsage: fromUploadedFilesActions.Actions.fetchUploadedFilesUsage,
};

const ProfileMenuComponent = connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);

export { ProfileMenuComponent as ProfileMenu };
