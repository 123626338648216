import { SubscribeButton } from 'components/buttons/SubscribeButton';
import React from 'react';
import { onKeyDownEnter } from '../../../helper/UiHelper';
import { Image, Rank, Subtitle, Text, Title, WrapperLink } from './ListItem.styled';

export type Props = {
    image: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    onClick?: () => void;
    rank?: number;
    onSubscribe?: () => void;
    podcastUuid?: string;
};

const ListItem = ({ image, title, subtitle, onClick, rank, onSubscribe, podcastUuid }: Props) => {
    const onKeyDown = (e: React.KeyboardEvent) => {
        onKeyDownEnter(e, () => onClick?.());
    };
    return (
        <WrapperLink onClick={onClick} onKeyDown={onKeyDown}>
            {!!rank && <Rank>{rank}</Rank>}
            <Image>{image}</Image>
            <Text>
                <Title>{title || ''}</Title>
                <Subtitle>{subtitle || ''}</Subtitle>
                {podcastUuid && (
                    <SubscribeButton
                        podcastUuid={podcastUuid}
                        onSubscribe={onSubscribe}
                        eventSource="discover"
                    />
                )}
            </Text>
        </WrapperLink>
    );
};

export default ListItem;
