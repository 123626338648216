import { Icon } from 'components/Icon';
import React from 'react';
import { CloseButtonWrapper } from './CloseButton.styled';

type Props = {
    'aria-label'?: string;
    onClick: () => void;
    left?: boolean;
    margin?: number;
};

function CloseButton({ 'aria-label': ariaLabel = 'Close', onClick, left }: Props) {
    return (
        <CloseButtonWrapper $left={left} aria-label={ariaLabel} onClick={onClick}>
            <Icon id="cancel" />
        </CloseButtonWrapper>
    );
}

export default CloseButton;
