import React, { useCallback } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import { Portal } from 'react-portal';
import styled from 'styled-components';

type Props = {
    trigger?: React.ReactNode;
    children?: React.ReactNode;
    onClose?: () => void;
    isOpen?: boolean;
    isModal?: boolean;
};

const Content = styled(RadixPopover.Content)`
    @keyframes fade-and-slide-in {
        from {
            opacity: 0;
            transform: scale(0.9);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
    border-radius: 4px;
    z-index: 1001;
    background-color: ${props => props.theme.legacyScss['theme-popup-background']};
    color: ${props => props.theme.tokens['primary-text-01']};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
    will-change: transform, opacity;
    animation: fade-and-slide-in 250ms ease;
    animation-delay: 100ms;
    animation-fill-mode: backwards;
`;

const Overlay = styled.div`
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.15);
    animation: fade-in 200ms ease;
    z-index: 100;
`;

const Popover = ({ trigger, children, isModal, onClose, isOpen }: Props) => {
    const onOpenChange = useCallback(
        (isOpen: boolean) => {
            if (!isOpen && onClose) {
                onClose();
            }
        },
        [onClose],
    );

    return (
        <RadixPopover.Root open={isOpen} onOpenChange={onOpenChange} modal={isModal}>
            <Portal node={document && document.getElementById('modal-root')}>
                {isOpen && <Overlay />}
            </Portal>
            <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
            <RadixPopover.Portal>
                <Content
                    sideOffset={5}
                    align="start"
                    onOpenAutoFocus={e => {
                        e.preventDefault();
                        const firstInteractiveElement = (
                            e.currentTarget as HTMLElement
                        ).querySelector<HTMLElement>('button, a');
                        firstInteractiveElement?.focus();
                    }}
                >
                    {children}
                </Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};

Popover.Close = RadixPopover.Close;

export default Popover;
