/*
 * This file and directory contains our Theme model for the Web Player.
 * It is injected into the Player by the ThemeProvider component from styled-components, so
 * components should rarely have to explicitly know what theme is currently in use.
 *
 * There are still some legacy styles used in some areas of the app from /scss/config.scss.
 * These should be migrated as soon as possible as the Theme model can't be consumed by scss.
 */
import memoize from 'lodash/memoize';
import { isWindowsApp } from '../../helper/Browser';
import { DARK_THEME } from './theme-dark';
import { LIGHT_THEME } from './theme-light';

export type WebPlayerTheme = typeof LIGHT_THEME;
export type WebPlayerThemeToken = typeof LIGHT_THEME.tokens;

/*
 * These IDs must align with the server-side ID expected for each theme.
 */
export enum THEME {
    light = 0,
    dark = 1,

    /* Note that while system theme may be set, it ends up resulting in one of the above themes being set */
    system = 100,
}

export const prefersDarkThemeMediaQuery =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

export const prefersLightThemeMediaQuery =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: light)');

export const getPreferredWindowsTheme = () => {
    // If window.msController.getSystemTheme doesn't exist - we can't fetch the theme
    if (isWindowsApp() && window.msController.getSystemTheme) {
        const theme = window.msController.getSystemTheme();
        return theme ?? THEME.light;
    }
    return THEME.light;
};

export const getPreferredSystemTheme = (themeId: THEME): THEME => {
    if (themeId === THEME.system) {
        if (isWindowsApp()) {
            return getPreferredWindowsTheme();
        }
        if (prefersDarkThemeMediaQuery?.matches) {
            return THEME.dark;
        }
        if (prefersLightThemeMediaQuery?.matches) {
            return THEME.light;
        }
    }
    if (themeId !== THEME.system) {
        return themeId;
    }
    return THEME.light;
};

/*
 * This function provides direct access to theme objects outside of a styled component.
 * It is required for populating the top-level ThemeProvider object, but other than that
 * there should not be many reasons to depend on this function. Currently the only other
 * valid use case is adjustments to podcast colors based on theme.
 */
export const getThemeFromId: (id: THEME) => WebPlayerTheme = memoize((id: THEME) => {
    switch (id) {
        default:
        case THEME.light:
            return LIGHT_THEME;
        case THEME.dark:
            return DARK_THEME;
        case THEME.system:
            return getThemeFromId(getPreferredSystemTheme(id));
    }
});

/*
 * DEPRECATED
 * This function should be removed when the new Theme model is connected up properly.
 * There may be scope for it to be replaced with another function aware of multiple
 * themes, but again we shouldn't really need to depend on that at a JS level.
 */
export const isDarkTheme = (id: number) => {
    if (id === THEME.system) {
        return getPreferredSystemTheme(id) === THEME.dark;
    }
    return id === THEME.dark;
};

/*
 * DEPRECATED
 * This function should be removed when the new Theme model is connected up properly.
 * This could be handled differently server-side as well (it could detect the user's theme
 * or we could send it in the request), and just return a single set of correct colors.
 */
export const getPodcastTint = (colors: { darkTint: string; lightTint: string }, themeId: number) =>
    colors ? (isDarkTheme(themeId) ? colors.darkTint : colors.lightTint) : null;

export const getPodcastListTint = ({
    colors,
    themeId,
}: {
    colors?: { onDarkBackground: string; onLightBackground: string };
    themeId: number;
}) => (colors ? (isDarkTheme(themeId) ? colors.onDarkBackground : colors.onLightBackground) : null);
