import React from 'react';

const UpNextEmptyIcon = () => (
    <svg
        width="66"
        height="48"
        viewBox="0 0 66 48"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
    >
        <rect opacity="0.5" x="46" y="12" width="20" height="4" rx="2" />
        <rect opacity="0.5" x="46" y="32" width="20" height="4" rx="2" />
        <rect opacity="0.5" x="48" y="22" width="18" height="4" rx="2" />
        <rect y="4" width="44" height="40" rx="20" />
        <path
            d="M22 32.3975C25.835 32.3975 28.1553 29.3574 28.1553 24.2334C28.1553 19.0879 25.8027 16.1016 22 16.1016C18.1865 16.1016 15.8447 19.0879 15.8447 24.2227C15.8447 29.3682 18.165 32.3975 22 32.3975ZM22 29.7549C20.2275 29.7549 19.1318 27.8965 19.1318 24.2227C19.1318 20.5811 20.2383 18.7549 22 18.7549C23.7725 18.7549 24.8682 20.5703 24.8682 24.2227C24.8682 27.8965 23.7832 29.7549 22 29.7549Z"
            fill="#303336"
        />
    </svg>
);

export default UpNextEmptyIcon;
