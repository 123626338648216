import { KeyGroup } from 'components/KeyboardShortcut/KeyboardShortcut.styled';
import styled from 'styled-components';
import { Typography, gridSize } from 'styles';

export const Container = styled.div`
    ${Typography.Desktop.H70}
    overflow: auto;
    padding: 0 ${4 * gridSize}px 16px;
    color: ${props => props.theme.legacyScss['theme-text-primary']};
`;

export const Heading = styled.h2`
    text-align: center;
`;
export const Shortcut = styled.div<{ $disabled?: boolean }>`
    margin-bottom: ${2 * gridSize}px;
    align-items: center;
    display: flex;
    ${KeyGroup} {
        margin: -0.15em ${gridSize}px 0 0;
    }
    ${props => props.$disabled && `opacity: 0.25;`}
`;

export const Group = styled.div`
    display: inline-block;
    width: 50%;
    vertical-align: top;
`;

export const GroupHeading = styled.h3`
    margin: ${6 * gridSize}px 0 ${2 * gridSize}px;

    &:first-child {
        margin-top: ${2 * gridSize}px;
    }
`;
