import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { LoaderRound } from 'components/LoaderRound';
import {
    EpisodeSearchResultItem,
    FolderSearchResultItem,
    PodcastSearchResultItem,
    SearchResultItem,
    URLSearchResultItem,
} from 'components/SearchResultItem';
import * as StringHelper from 'helper/StringHelper';
import useFormatMessage from 'hooks/useFormatMessage';
import {
    EpisodeSearchResult,
    FolderSearchResult,
    PodcastSearchResult,
    SearchHistoryItem,
} from 'model/types';
import React from 'react';
import { Link } from 'react-router';
import urls from '../../urls';
import { Header, NoResults, ScrollingHistory, Wrapper } from './SearchResults.styled';

export type Props = {
    term: string;
    history?: SearchHistoryItem[];
    episodes?: EpisodeSearchResult[];
    podcasts?: PodcastSearchResult[];
    subscriptions?: (PodcastSearchResult | FolderSearchResult)[];
    isSearching?: boolean;
    maxResults?: number;
    onItemClick?: (item: SearchHistoryItem) => void;
    onHistoryItemClick?: (item: SearchHistoryItem) => void;
    onClearAllHistory?: () => void;
    onClearHistoryItem?: (item: SearchHistoryItem) => void;
};

const SearchResultsList = ({
    term,
    episodes = [],
    podcasts = [],
    subscriptions = [],
    isSearching,
    maxResults = 10,
    onItemClick = () => null,
}: Props) => {
    const formatMessage = useFormatMessage();

    // Show at most 3 subscriptions, but fewer if maxResults is very small
    const maxSubscriptions = Math.min(Math.floor(maxResults / 2), 3);
    const subscriptionList = subscriptions.slice(0, maxSubscriptions);
    const maxRemoteItems = maxResults - subscriptionList.length;

    // Determine how many Podcasts and Episodes to show. Start with a 50/50ish balance:
    let podcastCount = Math.ceil(maxRemoteItems / 2);
    if (podcasts.length < podcastCount) {
        // If there's not that many podcasts, leave room for more episodes:
        podcastCount = podcasts.length;
    } else if (episodes.length < maxRemoteItems - podcastCount) {
        // If there's not that many episodes, let there be more podcasts:
        podcastCount = maxRemoteItems - episodes.length;
    }

    const podcastList = podcasts.slice(0, podcastCount);
    const episodeList = episodes.slice(0, maxRemoteItems - podcastCount);
    const hasResults = podcastList.length + subscriptionList.length > 0;

    return (
        <section>
            {/*
             * For now we're showing subscriptions and podcasts combines as "Results".
             * Once Episode Search is working, we'll rename it to "Podcasts" and possibly
             * split the subscriptions into their own section.
             */}
            {(hasResults || isSearching) && (
                <Header>
                    <h1>
                        {formatMessage(
                            podcastList.length + subscriptionList.length > 0
                                ? 'podcasts'
                                : 'searching',
                        )}
                    </h1>
                    {isSearching ? (
                        <LoaderRound />
                    ) : (
                        (podcasts.length > podcastCount ||
                            subscriptions.length > maxSubscriptions) && (
                            <Link to={urls.searchPath(term, true)}>
                                {formatMessage('show-all')}
                                <Icon id="arrow-right" size={14} />
                            </Link>
                        )
                    )}
                </Header>
            )}

            {subscriptionList.map(item =>
                'podcastUuids' in item ? (
                    <FolderSearchResultItem
                        key={item.uuid}
                        folder={item}
                        subscribed
                        onClick={() => onItemClick({ folder: item })}
                    />
                ) : (
                    <PodcastSearchResultItem
                        key={item.uuid}
                        podcast={item}
                        subscribed
                        onClick={() => onItemClick({ podcast: item })}
                    />
                ),
            )}

            {podcastList.map(podcast => (
                <PodcastSearchResultItem
                    key={podcast.uuid}
                    podcast={podcast}
                    onClick={() => onItemClick({ podcast })}
                />
            ))}

            {episodeList.length > 0 && (
                <section>
                    <Header>
                        <h1>{formatMessage('episodes')}</h1>
                        {episodes.length > episodeList.length && (
                            <Link to={urls.searchPath(term)}>
                                {formatMessage('show-all')}
                                <Icon id="arrow-right" size={14} />
                            </Link>
                        )}
                    </Header>
                    {episodeList.map(episode => (
                        <EpisodeSearchResultItem
                            key={episode.uuid}
                            episode={episode}
                            onClick={() => onItemClick({ episode })}
                        />
                    ))}
                </section>
            )}
        </section>
    );
};

const SearchHistory = ({
    history,
    maxResults = 10,
    onClearAllHistory,
    onClearHistoryItem = () => null,
    onHistoryItemClick = () => null,
}: {
    history: SearchHistoryItem[];
    maxResults?: number;
    onClearAllHistory?: () => void;
    onClearHistoryItem?: (item: SearchHistoryItem) => void;
    onHistoryItemClick?: (item: SearchHistoryItem) => void;
}) => {
    const formatMessage = useFormatMessage();
    return (
        <section>
            <Header>
                <h1>{formatMessage('recent-searches')}</h1>
                <Button type="button" kind="text" onClick={onClearAllHistory}>
                    {formatMessage('up-next-clear')}
                </Button>
            </Header>
            <ScrollingHistory $maxResults={maxResults}>
                {history.map(item => {
                    if ('podcast' in item) {
                        return (
                            <PodcastSearchResultItem
                                key={item.podcast.uuid}
                                podcast={item.podcast}
                                subscribed={item.subscribed}
                                onClear={() => onClearHistoryItem(item)}
                                onClick={() => onHistoryItemClick(item)}
                            />
                        );
                    }
                    if ('episode' in item) {
                        return (
                            <EpisodeSearchResultItem
                                key={item.episode.uuid}
                                episode={item.episode}
                                onClear={() => onClearHistoryItem(item)}
                                onClick={() => onHistoryItemClick(item)}
                            />
                        );
                    }
                    if ('term' in item) {
                        return (
                            <SearchResultItem
                                key={`term-${item.term}`}
                                image={<Icon id="search" />}
                                title={item.term}
                                href={urls.searchPath(item.term)}
                                onClear={() => onClearHistoryItem(item)}
                                onClick={() => onHistoryItemClick(item)}
                                aria-label={`${formatMessage('search')}. ${item.term}`}
                            />
                        );
                    }
                    if ('folder' in item) {
                        return (
                            <FolderSearchResultItem
                                key={item.folder.uuid}
                                folder={item.folder}
                                subscribed
                                onClear={() => onClearHistoryItem(item)}
                                onClick={() => onHistoryItemClick(item)}
                            />
                        );
                    }
                    return null;
                })}
            </ScrollingHistory>
        </section>
    );
};

const SearchResults = ({
    history,
    isSearching,
    episodes,
    podcasts,
    subscriptions,
    term,
    maxResults,
    onItemClick,
    onHistoryItemClick,
    onClearAllHistory,
    onClearHistoryItem,
}: Props) => {
    const formatMessage = useFormatMessage();
    const resultCount =
        (episodes ?? []).length + (podcasts ?? []).length + (subscriptions ?? []).length;

    if (episodes || podcasts || subscriptions || isSearching) {
        if (resultCount > 0 || isSearching) {
            return (
                <Wrapper>
                    <SearchResultsList
                        term={term}
                        episodes={episodes}
                        podcasts={podcasts}
                        subscriptions={subscriptions}
                        isSearching={isSearching}
                        onItemClick={onItemClick}
                        maxResults={maxResults}
                    />
                </Wrapper>
            );
        }

        if (StringHelper.isUrl(term)) {
            return (
                <Wrapper>
                    <URLSearchResultItem url={term} />
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <NoResults>
                    <Icon id="search-alt" />
                    <h1>{formatMessage('find-podcasts-empty')}</h1>
                    <p>{formatMessage('find-podcasts-empty-desc')}</p>
                </NoResults>
            </Wrapper>
        );
    }

    if (history) {
        return (
            <Wrapper>
                <SearchHistory
                    history={history}
                    onClearAllHistory={onClearAllHistory}
                    onClearHistoryItem={onClearHistoryItem}
                    onHistoryItemClick={onHistoryItemClick}
                    maxResults={maxResults}
                />
            </Wrapper>
        );
    }

    return null;
};

export default SearchResults;
