import { TrackOnMount } from 'components/Tracks';
import { NavigationItems } from 'helper/NavigationHelper';
import { ModalTypes } from 'helper/UiHelper';
import { Folder, TracksProperties } from 'model/types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { api } from 'services/api';
import { ErrorMessage, LoaderSquare } from '../../components';
import { withAnalyticsContext } from '../../context/AnalyticsContext';
import useFormatMessage from '../../hooks/useFormatMessage';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import { RootState } from '../../redux/reducers';
import {
    getFolder,
    getPodcastListFolders,
    getPodcastUuidsForFolder,
} from '../../redux/reducers/selectors';
import { FolderEmptyState } from './FolderEmptyState';
import { GridOfPodcasts } from './GridOfPodcasts';
import { ListOfPodcasts } from './ListOfPodcasts';
import { OptionsHeader } from './OptionsHeader';
import { PodcastsEmptyState } from './PodcastsEmptyState';
import { PodcastsContentWrapper, PodcastsPageWrapper } from './PodcastsPage.styled';
import {
    PodcastBadges,
    PodcastGridLayouts,
    PodcastGridOrder,
    PodcastListItem,
    PodcastListRearrangeAction,
} from './model';

const TRACKS_SORT_ORDER = {
    [PodcastGridOrder.DATE_ADDED]: 'date_added',
    [PodcastGridOrder.TITLE]: 'name',
    [PodcastGridOrder.RELEASE_DATE]: 'episode_release_date',
    [PodcastGridOrder.USER_SORTED]: 'drag_and_drop',
};

const TRACKS_BADGE_TYPES = {
    [PodcastBadges.OFF]: 'off',
    [PodcastBadges.NEWEST_EPISODE]: 'only_latest_episode',
};

export type Props = {
    downloadSubscribedPodcasts: () => void;
    rearrangePodcastList: (actions: PodcastListRearrangeAction[]) => void;
    recordEvent: (event: string, properties?: TracksProperties) => void;
    savePodcastGridLayout: (layout: PodcastGridLayouts) => void;
    savePodcastGridBadgesOn: (badges: PodcastBadges) => void;
    savePodcastGridOrder: (order: PodcastGridOrder) => void;
    showModal: (modalType: ModalTypes, data?: Record<string, unknown>) => void;
    updateFolder: (folder: Folder, podcastUuids: string[]) => void;
    loadFailed: boolean;
    isLoading: boolean;
    gridLayout: PodcastGridLayouts;
    gridOrder: PodcastGridOrder;
    badges: PodcastBadges;
    podcastsWebOpenEvent: () => void;
} & WrappedComponentProps;

type RouteParams = {
    folderUuid: string;
};

export const PodcastsPage: React.FC<Props> = ({
    downloadSubscribedPodcasts,
    podcastsWebOpenEvent,
    recordEvent,
    savePodcastGridLayout,
    savePodcastGridBadgesOn,
    savePodcastGridOrder,
    updateFolder,
    rearrangePodcastList,
    showModal,
    loadFailed,
    isLoading,
    gridLayout,
    gridOrder,
    badges,
}) => {
    const formatMessage = useFormatMessage();
    const { folderUuid } = useParams<RouteParams>();
    const folder = useSelector((state: RootState) =>
        folderUuid ? getFolder(state, folderUuid) : undefined,
    );

    const podcastListFolders = useSelector(getPodcastListFolders);

    const items = podcastListFolders[folderUuid ?? 'home'] || [];

    const podcastsInFolder = useSelector((state: RootState) =>
        folderUuid ? getPodcastUuidsForFolder(state, folderUuid) : [],
    );

    useScrollRestoration(!isLoading);

    useEffect(() => {
        downloadSubscribedPodcasts();
        podcastsWebOpenEvent();
    }, [downloadSubscribedPodcasts, podcastsWebOpenEvent]);

    const handleItemClick = (item: PodcastListItem) => {
        recordEvent(
            'podcast' in item ? 'podcasts_list_podcast_tapped' : 'podcasts_list_folder_tapped',
        );
    };

    const gridLayoutChanged = (layout: PodcastGridLayouts) => {
        savePodcastGridLayout(layout);
        recordEvent('podcasts_list_layout_changed', {
            layout: PodcastGridLayouts[layout].toLowerCase(),
        });
    };

    const showBadgesChanged = (badges: PodcastBadges) => {
        savePodcastGridBadgesOn(badges);
        recordEvent('podcasts_list_badges_changed', {
            type: TRACKS_BADGE_TYPES[badges],
        });
    };

    const onOrderChanged = (newOrder: PodcastGridOrder) => {
        if (folder) {
            const updatedFolder = { ...folder, sortType: newOrder };
            api.updateFolder(updatedFolder, podcastsInFolder);
            updateFolder(updatedFolder, podcastsInFolder);
            recordEvent('folder_sort_by_changed', {
                sort_by: TRACKS_SORT_ORDER[newOrder],
            });
        } else {
            savePodcastGridOrder(newOrder);
            recordEvent('podcasts_list_sort_order_changed', {
                sort_by: TRACKS_SORT_ORDER[newOrder],
            });
        }
    };

    const handleSortEnd = (oldPosition: number, newPosition: number) => {
        if (oldPosition === newPosition) {
            return;
        }

        const item = items[oldPosition];

        rearrangePodcastList([['MOVE', item.uuid, folder?.uuid || 'home', newPosition]]);

        if (folder && folder.sortType !== PodcastGridOrder.USER_SORTED) {
            const updatedFolder = {
                ...folder,
                sortType: PodcastGridOrder.USER_SORTED,
            };
            api.updateFolder(updatedFolder, podcastsInFolder);
            updateFolder(updatedFolder, podcastsInFolder);
        }
        if (!folder && gridOrder !== PodcastGridOrder.USER_SORTED) {
            savePodcastGridOrder(PodcastGridOrder.USER_SORTED);
        }

        recordEvent('podcasts_list_reordered');
    };

    const renderContent = () => {
        if (isLoading && items.length === 0) {
            return renderLoading();
        }

        if (loadFailed) {
            return renderError();
        }

        if (items.length === 0) {
            return renderEmptyState();
        }

        const LayoutComponent =
            gridLayout === PodcastGridLayouts.LIST ? ListOfPodcasts : GridOfPodcasts;

        return (
            <>
                {renderOptionsHeader()}
                <LayoutComponent
                    items={items}
                    onItemClick={handleItemClick}
                    onSortEnd={handleSortEnd}
                />
            </>
        );
    };

    const renderLoading = () => <LoaderSquare />;

    const renderError = () => (
        <ErrorMessage contact description={formatMessage('podcasts-loading-error')} />
    );

    const renderEmptyState = () => {
        if (folder) {
            return (
                <>
                    {renderOptionsHeader()}
                    <FolderEmptyState folderUuid={folder.uuid} />
                </>
            );
        }
        return <PodcastsEmptyState />;
    };

    const getOptionsHeaderTitle = () => (folder ? folder.name : formatMessage('podcasts'));

    const renderOptionsHeader = () => (
        <OptionsHeader
            folder={folder}
            title={getOptionsHeaderTitle()}
            layout={gridLayout}
            badges={badges}
            sort={folder ? folder.sortType : gridOrder}
            onOrderChanged={onOrderChanged}
            onLayoutChanged={gridLayoutChanged}
            onBadgesChanged={showBadgesChanged}
            onCreateFolderClick={() => {
                recordEvent('podcasts_list_folder_button_tapped');
                showModal(ModalTypes.createFolder, { eventSource: 'podcasts_list' });
            }}
            onEditFolderDetailsClick={() =>
                showModal(ModalTypes.editFolderDetails, { folderUuid: folder?.uuid })
            }
            onEditFolderPodcastsClick={() =>
                showModal(ModalTypes.editFolderPodcasts, { folderUuid: folder?.uuid })
            }
        />
    );

    if (!isLoading && folderUuid && !folder) {
        // All data has loaded, but the requested folder doesn't exist — redirect to Podcasts home
        return <Navigate to={NavigationItems.PODCASTS.path} />;
    }

    return (
        <PodcastsPageWrapper>
            {!isLoading &&
                (folder ? (
                    <TrackOnMount
                        event="folder_shown"
                        properties={{
                            sort_order: TRACKS_SORT_ORDER[folder.sortType],
                            number_of_podcasts: podcastsInFolder.length,
                        }}
                        key="folder_shown"
                    />
                ) : (
                    <TrackOnMount
                        event="podcasts_list_shown"
                        properties={{
                            sort_order: TRACKS_SORT_ORDER[gridOrder],
                            badge_type: TRACKS_BADGE_TYPES[badges],
                            layout: PodcastGridLayouts[gridLayout].toLowerCase(),
                            number_of_podcasts: items.filter(item => 'podcast' in item).length,
                            number_of_folders: items.filter(item => 'folder' in item).length,
                        }}
                        key="podcasts_list_shown"
                    />
                ))}
            <Helmet>
                <title>
                    {folder
                        ? `${folder.name} ${formatMessage('folder')}`
                        : formatMessage('podcasts')}
                </title>
            </Helmet>
            <PodcastsContentWrapper>{renderContent()}</PodcastsContentWrapper>
        </PodcastsPageWrapper>
    );
};

export default withAnalyticsContext(PodcastsPage);
