import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoaderSquare } from '../../components';
import FilesEmptyState from './FilesEmptyState';
import FilesList from './FilesList';
import { UploadedFilesErrorWrapper } from './styled';

type Props = {
    serverError: boolean;
    isLoading: boolean;
    filesOrder: string[];
};

const FilesPageContent: FC<PropsWithChildren<Props>> = ({ serverError, isLoading, filesOrder }) => {
    if (serverError) {
        return (
            <UploadedFilesErrorWrapper>
                <FormattedMessage id="files-loading-issue" />
            </UploadedFilesErrorWrapper>
        );
    }

    if (isLoading && !filesOrder) {
        return <LoaderSquare />;
    }

    if (filesOrder.length === 0) {
        return <FilesEmptyState />;
    }

    return <FilesList />;
};

export default FilesPageContent;
