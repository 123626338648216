import { connect } from 'react-redux';
import * as fromTracksActions from '../../../redux/actions/tracks.actions';
import { RootState } from '../../../redux/reducers';
import { getFolderColorValues } from '../../../redux/reducers/selectors';
import OptionsHeader from './OptionsHeader';

const mapStateToProps = (state: RootState) => ({
    folderColorValues: getFolderColorValues(state),
});

const mapDispatchToProps = {
    recordEvent: fromTracksActions.Actions.recordEvent,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OptionsHeader);
export { ConnectedComponent as OptionsHeader };
