import { TrackOnMount, TrackOnUnmount } from 'components/Tracks';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '../../../../components/Button';
import Input from '../../../../components/Input/index';
import Modal from '../../../../components/Modal';
import { DurationStringFromSeconds } from '../../../../helper/DurationHelper';
import { getSizeStringFromBytes } from '../../../../helper/UploadedFilesHelper';
import { getUploadedFileIconUrl } from '../../../../model/uploaded-files';
import { IMAGE_DROP_ZONE_ID_PREFIX as imageDropZoneIdPrefix } from '../../ImageDropZone';
import FileColorPicker from './FileColorPicker';
import FileEditModalTooltip from './FileEditModalTooltip';
import ImageUploadProgressBox from './ImageUploadProgressBox';
import ReplaceImageButton from './ReplaceImageButton';
import {
    ButtonSpacer,
    ButtonsWrapper,
    FileInfoField,
    FileInfoWrapper,
    ImageAndOverlayWrapper,
    ImageOverlay,
    ImageWrapper,
    ReplaceImageButtonWrapper,
    Separator,
    TitleEditFieldWrapper,
} from './styled';

export class EditFileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editableTitle: props.file.title,
            editableColor: props.file.color,
            replaceButtonHover: false,
        };
    }

    componentDidUpdate(prevProps) {
        // If a file's image is updated, we should push the color back to whatever the server tells
        // us (should be 0, but we don't hardcode 0 to cater for image upload race conditions).
        const prevArtwork = prevProps.file.artworkImageUrl;
        const thisArtwork = this.props.file.artworkImageUrl;

        if (prevArtwork !== thisArtwork) {
            this.setState({
                editableColor: this.props.file.color,
            });
        }

        if (!prevProps.imageUploadData && this.props.imageUploadData) {
            this.setState({
                replaceButtonHover: false,
            });
        }
    }

    replaceButtonEnter = () => {
        this.setState({ replaceButtonHover: true });
    };

    replaceButtonLeave = () => {
        this.setState({ replaceButtonHover: false });
    };

    closeThisModal = () => {
        this.props.onAbortImageUpload(this.props.file.uuid);
        this.props.onClose();
    };

    saveFile = () => {
        const { file, recordEvent } = this.props;
        const { editableTitle, editableColor } = this.state;

        const newTitle = editableTitle.trim();

        const newFile = {
            ...file,
            color: editableColor,
            title: newTitle.length > 0 ? newTitle : file.title,
        };

        this.props.onConfirm(newFile);

        recordEvent('user_file_edit_save');

        // Rather than call this.closeThisModal(), we might as well let images upload
        // in the background so the user doesn't have to sit and stare at the modal.
        this.props.onClose();
    };

    titleChange = event => {
        this.setState({
            editableTitle: event.target.value,
        });
    };

    getImageUrlForColorPicker = () => {
        const { file } = this.props;
        return file.artworkImageUrl;
    };

    getImageUrlForGiantArtwork = () => {
        const { theme, file } = this.props;
        const { editableColor } = this.state;

        if (editableColor === 0) {
            return file.artworkImageUrl || file.imageUrl;
        }

        return getUploadedFileIconUrl(theme, editableColor);
    };

    colorSelected = colorId => {
        this.setState({
            editableColor: colorId,
        });
    };

    abortImageUpload = () => {
        this.props.onAbortImageUpload(this.props.file.uuid);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.saveFile();
    };

    renderGiantImageOrUploadProgress() {
        const { intl, imageUploadData } = this.props;
        const { editableColor } = this.state;

        if (imageUploadData) {
            const errorIfExists = imageUploadData.failed
                ? imageUploadData.error
                    ? imageUploadData.error
                    : intl.formatMessage({ id: 'image-upload-failed' })
                : null;

            const isComplete = imageUploadData.complete;
            const percent = imageUploadData.progress / (imageUploadData.size || 1);

            return (
                <ImageUploadProgressBox
                    onCancel={this.abortImageUpload}
                    errorIfExists={errorIfExists}
                    shouldShowCancelButton={!(isComplete || percent >= 99.9)}
                />
            );
        }

        return (
            <ImageAndOverlayWrapper>
                <ImageWrapper>
                    <img width={200} height={200} src={this.getImageUrlForGiantArtwork()} alt="" />
                </ImageWrapper>
                <ImageOverlay id={`${imageDropZoneIdPrefix}-wrapper`}>
                    {editableColor === 0 && (
                        <ReplaceImageButtonWrapper>
                            <ReplaceImageButton
                                onMouseEnter={this.replaceButtonEnter}
                                onMouseLeave={this.replaceButtonLeave}
                            />
                            {this.state.replaceButtonHover && (
                                <FileEditModalTooltip
                                    top={-54}
                                    right={-66}
                                    text={intl.formatMessage({ id: 'replace-image' })}
                                />
                            )}
                        </ReplaceImageButtonWrapper>
                    )}
                </ImageOverlay>
            </ImageAndOverlayWrapper>
        );
    }

    render() {
        const { intl, file, imageDropZoneIsShowing } = this.props;
        const { editableTitle, editableColor } = this.state;

        return (
            <Modal
                horizontalInsetPx={32}
                onClose={this.closeThisModal}
                blurred={imageDropZoneIsShowing}
            >
                {this.renderGiantImageOrUploadProgress()}
                <form onSubmit={this.handleSubmit}>
                    <TrackOnMount event="user_file_edit_shown" />
                    <TrackOnUnmount event="user_file_edit_dismissed" />
                    <FileColorPicker
                        fileUuid={file.uuid}
                        selectedColor={editableColor}
                        image={this.getImageUrlForColorPicker()}
                        onColorSelect={this.colorSelected}
                    />
                    <TitleEditFieldWrapper>
                        <Input
                            icon="edit"
                            noAuxiliaryIcon
                            width={496}
                            placeholder={intl.formatMessage({ id: 'file-title-placeholder' })}
                            state={editableTitle.trim().length === 0 ? 'error' : 'none'}
                            value={editableTitle}
                            onChange={this.titleChange}
                        />
                    </TitleEditFieldWrapper>
                    <FileInfoWrapper>
                        <FileInfoField>
                            <DurationStringFromSeconds
                                durationSecsStrOrNum={file.duration}
                                remaining={false}
                                short={false}
                            />
                        </FileInfoField>
                        <FileInfoField>&nbsp;&nbsp;•&nbsp;&nbsp;</FileInfoField>
                        <FileInfoField>{getSizeStringFromBytes(file.size)}</FileInfoField>
                    </FileInfoWrapper>
                    <Separator />
                    <ButtonsWrapper>
                        <Button width={120} height={48} type="submit">
                            {intl.formatMessage({ id: 'confirm' })}
                        </Button>
                        <ButtonSpacer />
                        <Button
                            kind="secondary"
                            width={120}
                            height={48}
                            onClick={this.closeThisModal}
                        >
                            {intl.formatMessage({ id: 'cancel' })}
                        </Button>
                    </ButtonsWrapper>
                </form>
            </Modal>
        );
    }
}

export default injectIntl(EditFileModal);
