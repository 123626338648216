import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { Link } from 'react-router';
import styled from 'styled-components';
import { A11Y, gridSize, preventFlexboxOverflow, Typography, visuallyHidden } from 'styles';

export const WrapperLink = styled(Link)`
    ${Typography.Desktop.H70}
    width: 100%;
    display: flex;
    padding: ${gridSize}px ${2 * gridSize}px;
    text-decoration: none;
    color: ${props => props.theme.tokens['primary-text-02']};
    text-align: left;
    &:hover,
    &:focus {
        background: ${props => props.theme.legacyScss['theme-background-hover']};
        outline: none;
    }
    &:focus-visible {
        ${A11Y.outlineInset}
        outline-offset: -3px;
    }
`;

export const Image = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.div`
    margin-left: ${1.5 * gridSize}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    ${preventFlexboxOverflow}
`;

export const SmallText = styled.div`
    ${Typography.Desktop['H80-BC']}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Title = styled.div`
    ${Typography.Desktop['H70-B']}
    color: ${props => props.theme.tokens['primary-text-01']};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Description = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PlayButton = styled.div`
    margin-left: ${gridSize}px;
    align-self: center;
`;

export const ClearButton = styled(Button)`
    height: 40px;
    margin-left: ${gridSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex-shrink: 0;
    color: ${props => props.theme.tokens['primary-icon-02']};
    &:hover {
        color: ${props => props.theme.tokens['primary-icon-02-active']};
    }
`;

export const SubscribedIcon = styled(Icon)`
    color: ${props => props.theme.tokens['support-02']};
    align-self: center;
    margin-left: ${gridSize}px;
    flex-shrink: 0;
`;

export const AssistiveText = styled.div`
    ${visuallyHidden}
`;
