import React from 'react';
import { formatTime } from '../../../helper/TimeHelper';
import { TimeTextWrapper } from './TimeText.styled';

export type Props = {
    className?: string;
    timeSecs: number;
};

function TimeText({ timeSecs = 0, className, ...props }: Props) {
    return (
        <TimeTextWrapper {...props} className={className}>
            {formatTime(timeSecs)}
        </TimeTextWrapper>
    );
}

export default TimeText;
