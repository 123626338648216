import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAudio } from 'react-use';
import styled from 'styled-components';
import { CloseButton } from '../../components';
import RoundedButton from '../../components/buttons/RoundedButton';
import HorizontalDivider from '../../components/HorizontalDivider';
import {
    AnimatedPlayButtonWrapper,
    CloseButtonWrapper,
    SharePopupWrapper,
} from '../../components/popup/SharePopup/SharePopup.styled';
import useEpisodeDetails from '../../hooks/useEpisodeDetails';
import usePodcastDetails from '../../hooks/usePodcastDetails';
import useTracks from '../../hooks/useTracks';
import { gridSize } from '../../styles';
import { AnimatedPlayButton } from '../LoggedInPageChrome/PlayerControls/AnimatedPlayButton';
import { SeekBar } from '../LoggedInPageChrome/PlayerControls/SeekBar';
import EpisodeHeader from './EpisodeHeader';

export const EpisodeAnimatedPlayButtonWrapper = styled(AnimatedPlayButtonWrapper)`
    margin-left: 0;
`;

const PlayerControls = styled.div`
    display: flex;
    gap: ${2 * gridSize}px;
`;

const SeekBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 13px;
`;

const SeekBarWrapper = styled.div`
    position: relative;
`;

const EpisodeSeekBar = styled(SeekBar)`
    .current-time,
    .time-remaining {
        color: ${props => props.theme.tokens['primary-text-01']};
    }

    .tracks {
        .track-bar {
            background-color: ${props => props.theme.tokens['primary-ui-05']};
        }
    }
`;

type Props = {
    episodeUuid: string;
    podcastUuid: string;
    startTime: number;
    endTime: number;
    onPlayEpisode: () => void;
    onPlayClip: () => void;
    onClose?: () => void;
};

const EpisodePageClipPopup = ({
    podcastUuid,
    episodeUuid,
    startTime,
    endTime,
    onPlayEpisode,
    onPlayClip,
    onClose,
}: Props) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const podcastDetails = usePodcastDetails(podcastUuid);
    const episodeDetails = useEpisodeDetails(podcastUuid, episodeUuid);
    const duration = endTime - startTime;
    const { recordEvent } = useTracks();

    const [audio, audioState, audioControls] = useAudio({
        src: `${episodeDetails.episode?.url}#t=${startTime},${endTime}`,
        autoPlay: false,
        onTimeUpdate: (e: React.SyntheticEvent) => {
            const { currentTime } = e.target as HTMLMediaElement;
            if (currentTime >= endTime) {
                audioControls.pause();
                audioControls.seek(startTime);
            }
        },
    });

    useEffect(() => {
        recordEvent(isOpen ? 'episode_clip_shown' : 'episode_clip_dismissed');
    }, [isOpen, recordEvent]);

    const handleOnClosed = () => {
        audioControls.pause();
        setIsOpen(false);
        onClose?.();
    };

    const handlePlayFullEpisodeButtonClick = () => {
        recordEvent('episode_clip_play_full_episode');
        audioControls.pause();
        setIsOpen(false);
        onPlayEpisode();
    };

    const handleOnUserSeeked = (time: number) => {
        recordEvent('episode_clip_seek');
        audioControls.seek(time + startTime);
    };

    const handlePlayClipButtonClick = () => {
        recordEvent('episode_clip_play');
        onPlayClip();
        audioControls.play();
    };

    const handlePauseClipbuttonClick = () => {
        recordEvent('episode_clip_pause');
        audioControls.pause();
    };

    if (!podcastDetails.podcast || !episodeDetails.episode) {
        return audio;
    }

    // When not starting from 0, show the buffering state until the audio has caught up to the start time.
    const relativePosition = Math.max(0, audioState.time - startTime);
    const isBuffering = audioState.time < startTime;

    // The pause check here is to keep showing the play button and prevent play/pause flickering
    // when the user is seeking to a new position.
    const isPlaying = audioState.playing || !audioState.paused;

    return (
        <>
            {audio}
            <SharePopupWrapper
                isOpen={isOpen}
                onClosed={handleOnClosed}
                width={776}
                data-testid="episode-clip-popup"
            >
                <CloseButtonWrapper>
                    <CloseButton onClick={handleOnClosed} />
                </CloseButtonWrapper>
                <EpisodeHeader
                    tintColors={podcastDetails.colors}
                    podcastUrl={podcastDetails.podcastUrl}
                    podcast={podcastDetails.podcast}
                    episode={episodeDetails.episode}
                />
                <PlayerControls>
                    <EpisodeAnimatedPlayButtonWrapper>
                        <AnimatedPlayButton
                            disabled={isBuffering}
                            playing={isPlaying}
                            onPlayClicked={handlePlayClipButtonClick}
                            onPauseClicked={handlePauseClipbuttonClick}
                        />
                    </EpisodeAnimatedPlayButtonWrapper>
                    <SeekBarContainer>
                        <SeekBarWrapper>
                            <EpisodeSeekBar
                                buffering={isBuffering}
                                playedUpTo={relativePosition}
                                duration={duration}
                                color={podcastDetails.colors.background}
                                onUserSeeked={handleOnUserSeeked}
                            />
                        </SeekBarWrapper>
                    </SeekBarContainer>
                </PlayerControls>
                <HorizontalDivider />
                <RoundedButton onClick={handlePlayFullEpisodeButtonClick}>
                    <FormattedMessage id="play-full-episode" />
                </RoundedButton>
            </SharePopupWrapper>
        </>
    );
};

export default EpisodePageClipPopup;
