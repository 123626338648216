import { CheckoutModalType, RedeemPromoSuccessResponse, SubscriptionData } from 'model/types';
import { ActionsUnion, createAction } from './action-creators';

export enum ActionTypes {
    SUBSCRIPTION_FETCH_REQUEST = '@subscription/SUBSCRIPTION_FETCH_REQUEST',
    SUBSCRIPTION_FETCH_SUCCESS = '@subscription/SUBSCRIPTION_FETCH_SUCCESS',
    SUBSCRIPTION_FETCH_FAILURE = '@subscription/SUBSCRIPTION_FETCH_FAILURE',

    SUBSCRIPTION_FETCH_AFTER_CANCEL = '@subscription/SUBSCRIPTION_FETCH_AFTER_CANCEL',

    MARKETING_SUBSCRIBE_REQUEST = '@subscription/MARKETING_SUBSCRIBE_REQUEST',
    MARKETING_SUBSCRIBE_SUCCESS = '@subscription/MARKETING_SUBSCRIBE_SUCCESS',
    MARKETING_SUBSCRIBE_FAILURE = '@subscription/MARKETING_SUBSCRIBE_FAILURE',

    MARKETING_UNSUBSCRIBE_REQUEST = '@subscription/MARKETING_UNSUBSCRIBE_REQUEST',
    MARKETING_UNSUBSCRIBE_SUCCESS = '@subscription/MARKETING_UNSUBSCRIBE_SUCCESS',
    MARKETING_UNSUBSCRIBE_FAILURE = '@subscription/MARKETING_UNSUBSCRIBE_FAILURE',

    FREE_GIFT_EXPERIENCE_ACKNOWLEDGE = '@subscription/FREE_GIFT_EXPERIENCE_ACKNOWLEDGE',

    SAVE_GUEST_PASS_CODE = '@subscription/SAVE_GUEST_PASS_CODE',

    SHOW_SUBSCRIPTION_CANCEL_MODAL = '@subscription/SHOW_SUBSCRIPTION_CANCEL_MODAL',
    HIDE_SUBSCRIPTION_CANCEL_MODAL = '@subscription/HIDE_SUBSCRIPTION_CANCEL_MODAL',

    SHOW_BILLING_DETAILS_MODAL = '@subscription/SHOW_BILLING_DETAILS_MODAL',
    HIDE_BILLING_DETAILS_MODAL = '@subscription/HIDE_BILLING_DETAILS_MODAL',

    SHOW_FAREWELL_MODAL = '@subscription/SHOW_FAREWELL_MODAL',
    HIDE_FAREWELL_MODAL = '@subscription/HIDE_FAREWELL_MODAL',

    SHOW_CHECKOUT_MODAL = '@subscription/SHOW_CHECKOUT_MODAL',
    HIDE_CHECKOUT_MODAL = '@subscription/HIDE_CHECKOUT_MODAL',

    SHOW_THANK_YOU_MODAL = '@subscription/SHOW_THANK_YOU_MODAL',
    HIDE_THANK_YOU_MODAL = '@subscription/HIDE_THANK_YOU_MODAL',

    SHOW_MODAL_GENERIC = '@subscription/SHOW_MODAL_GENERIC',
    HIDE_MODAL_GENERIC = '@subscription/HIDE_MODAL_GENERIC',

    SAVE_INTRO_OFFER_ELIGIBILITY = '@subscription/SAVE_INTRO_OFFER_ELIGIBILITY',

    REDEEM_PROMO_REQUEST = '@subscription/REDEEM_PROMO_REQUEST',
    REDEEM_PROMO_SUCCESS = '@subscription/REDEEM_PROMO_SUCCESS',

    VALIDATE_PROMO_SUCCESS = '@subscription/VALIDATE_PROMO_SUCCESS',

    VALIDATE_PROMO_REQUEST = '@subscription/VALIDATE_PROMO_REQUEST',
    VALIDATE_PROMO_ERROR = '@subscription/VALIDATE_PROMO_ERROR',
    VALIDATE_PROMO_EXPIRED_INVALID = '@subscription/VALIDATE_PROMO_EXPIRED_INVALID',
}

export const Actions = {
    fetchSubscriptionData: () => createAction(ActionTypes.SUBSCRIPTION_FETCH_REQUEST),

    fetchSubscriptionDataAfterCancel: (hideFarewellModalAfterCancelling: boolean) =>
        createAction(ActionTypes.SUBSCRIPTION_FETCH_AFTER_CANCEL, {
            hideFarewellModalAfterCancelling,
        }),

    subscriptionFetchSuccess: (subscriptionData: SubscriptionData) =>
        createAction(ActionTypes.SUBSCRIPTION_FETCH_SUCCESS, subscriptionData),

    subscriptionFetchFailure: (errorMessage: string) =>
        createAction(ActionTypes.SUBSCRIPTION_FETCH_FAILURE, {
            errorMessage,
        }),

    marketingSubscribeRequest: () => createAction(ActionTypes.MARKETING_SUBSCRIBE_REQUEST),
    marketingSubscribeSuccess: () => createAction(ActionTypes.MARKETING_SUBSCRIBE_SUCCESS),
    marketingSubscribeFailure: () => createAction(ActionTypes.MARKETING_SUBSCRIBE_FAILURE),

    marketingUnsubscribeRequest: () => createAction(ActionTypes.MARKETING_UNSUBSCRIBE_REQUEST),
    marketingUnsubscribeSuccess: () => createAction(ActionTypes.MARKETING_UNSUBSCRIBE_SUCCESS),
    marketingUnsubscribeFailure: () => createAction(ActionTypes.MARKETING_UNSUBSCRIBE_FAILURE),

    acknowledgeFreeGiftExperience: () => createAction(ActionTypes.FREE_GIFT_EXPERIENCE_ACKNOWLEDGE),

    showFarewellModal: () => createAction(ActionTypes.SHOW_FAREWELL_MODAL),
    hideFarewellModal: () => createAction(ActionTypes.HIDE_FAREWELL_MODAL),

    showCheckoutModal: (modalType: CheckoutModalType) =>
        createAction(ActionTypes.SHOW_CHECKOUT_MODAL, { modalType }),
    hideCheckoutModal: () => createAction(ActionTypes.HIDE_CHECKOUT_MODAL),

    showThankYouModal: (welcomeBack = false) =>
        createAction(ActionTypes.SHOW_THANK_YOU_MODAL, {
            welcomeBack,
        }),
    hideThankYouModal: () => createAction(ActionTypes.HIDE_THANK_YOU_MODAL),

    showModalGeneric: (modalName: string, data?: unknown) =>
        createAction(ActionTypes.SHOW_MODAL_GENERIC, {
            modalName,
            data,
        }),
    hideModalGeneric: (modalName: string) =>
        createAction(ActionTypes.HIDE_MODAL_GENERIC, {
            modalName,
        }),

    saveGuestPassCode: (guestPassCode: string) =>
        createAction(ActionTypes.SAVE_GUEST_PASS_CODE, { guestPassCode }),

    saveIntroOfferEligibility: (eligible: boolean) =>
        createAction(ActionTypes.SAVE_INTRO_OFFER_ELIGIBILITY, { introOfferEligible: eligible }),

    redeemPromoRequest: (promoCode: string) =>
        createAction(ActionTypes.REDEEM_PROMO_REQUEST, { promoCode }),

    validatePromoCodeRequest: (promoCode: string) =>
        createAction(ActionTypes.VALIDATE_PROMO_REQUEST, {
            promoCode,
        }),
    redeemPromoSuccess: (response: RedeemPromoSuccessResponse) =>
        createAction(ActionTypes.REDEEM_PROMO_SUCCESS, response),

    validatePromoSuccess: (response: RedeemPromoSuccessResponse) =>
        createAction(ActionTypes.VALIDATE_PROMO_SUCCESS, response),

    validatePromoCodeError: (errorMessage?: string) =>
        createAction(ActionTypes.VALIDATE_PROMO_ERROR, errorMessage),

    validatePromoCodeExpiredOrInvalid: () =>
        createAction(ActionTypes.VALIDATE_PROMO_EXPIRED_INVALID),
};

export type Actions = ActionsUnion<typeof Actions>;
