import styled from 'styled-components';

export const ButtonWrapper = styled.button`
    cursor: pointer;
    width: 92px;
    height: 92px;

    &:disabled {
        background: ${props => props.theme.tokens['primary-field-03']};
        color: ${props => props.theme.tokens['primary-text-02']};
    }
`;
