import classNames from 'classnames';
import { IconId } from 'components/Icon';
import React, { ComponentProps, useRef } from 'react';
import {
    Button,
    Content,
    ExpandedIcon,
    Icon,
    IconWrapper,
    Wrapper,
} from './ExpandingIconButton.styled';

const ICON_SIZE = 20;

export type Props = {
    alignFlyout?: 'left' | 'right';
    forceIsExpanded?: boolean;
    icon: IconId;
    expandedIcon?: IconId;
} & ComponentProps<typeof Button>;

const ExpandingIconButton = ({
    alignFlyout,
    children,
    className,
    expandedIcon,
    forceIsExpanded,
    icon,
    ...buttonProps
}: Props) => {
    const contentRef = useRef<HTMLSpanElement>(null);

    const buttonClassNames = classNames(className, {
        'is-expanded': forceIsExpanded === true,
        'is-collapsed': forceIsExpanded === false,
    });

    return (
        <Wrapper $alignFlyout={alignFlyout}>
            <Button className={buttonClassNames} {...buttonProps}>
                <IconWrapper>
                    {expandedIcon && <ExpandedIcon id={expandedIcon} size={ICON_SIZE} />}
                    <Icon id={icon} size={ICON_SIZE} />
                </IconWrapper>
                <Content>
                    <span ref={contentRef}>{children}</span>
                </Content>
            </Button>
        </Wrapper>
    );
};

export default ExpandingIconButton;
