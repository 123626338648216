import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TooltipWrapper } from './Tooltip.styled';

const Tooltip = ({ ...props }) => (
    <TooltipWrapper>
        <ReactTooltip
            className="tooltip"
            globalCloseEvents={{
                clickOutsideAnchor: true,
                escape: true,
                scroll: true,
                resize: true,
            }}
            closeEvents={{
                click: true,
                mouseout: true,
                blur: true,
            }}
            openEvents={{
                focus: true,
                mouseenter: true,
                mouseover: true,
            }}
            delayShow={500}
            place="bottom"
            {...props}
        />
    </TooltipWrapper>
);

export default Tooltip;
