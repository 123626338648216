import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { MOBILE_WIDTH } from '../../../model/page';
import { Typography } from '../../../styles';
import { Icon } from '../../Icon';
import { Row } from '../ListEpisodes.styled';

export const PrimaryLine = styled.div`
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
`;

export const SecondaryLine = styled.div`
    padding-top: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const EpisodeTitle = styled.span<{ $tintColor?: string; $inactive?: boolean }>`
    ${Typography.Desktop.H65}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props =>
        props.$inactive
            ? props.theme.legacyScss['theme-text-disabled']
            : props.theme.legacyScss['theme-text-primary']};
    ${Row}:hover & {
        color: ${props => props.$tintColor || props.theme.legacyScss['theme-text-link']};
    }
`;

export const PodcastLink = styled(Link)<{ $inactive?: boolean }>`
    text-decoration: none;
    color: var(--tint-color);
    ${props =>
        props.$inactive &&
        css`
            color: ${props.theme.legacyScss['theme-text-disabled']};
        `}
    &:hover {
        text-decoration: underline;
    }
`;

export const UpNextIcon = styled(Icon)`
    margin: -0.15em 8px 0 0;
    color: ${props => props.theme.tokens['primary-icon-01']};

    ${PrimaryLine} & {
        flex-shrink: 0;
    }
`;

export const EpisodeInfo = styled.div`
    .episode-row & {
        @media (max-width: ${MOBILE_WIDTH}px) {
            grid-column: 1 / 2;
            grid-row: 2;
        }
    }
`;
