import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FeatureLock from '../../../components/FeatureLock';
import { Icon } from '../../../components/Icon';
import { EpisodeCount } from '../../../components/messages';
import { DurationStringFromSeconds } from '../../../helper/DurationHelper';
import { ModalTypes } from '../../../helper/UiHelper';
import useTracks from '../../../hooks/useTracks';
import { EpisodeSyncInfo } from '../../../model/types';
import { useEpisodeUpNextAction, useModalAction } from '../../../redux/action-hooks';
import { getUpNext } from '../../../redux/reducers/selectors/up-next.selectors';
import { USE_UP_NEXT_SHUFFLE } from '../../../settings';
import { ClearButton, ShuffleButton, ShuffleCircleIcon } from './UpNextToolbar.styled';
export function UpNextToolbar({
    uuids,
    episodeSync,
}: {
    uuids: string[];
    episodeSync: Record<string, EpisodeSyncInfo>;
}) {
    const upNextState = useSelector(getUpNext);
    const isShuffled = upNextState.isShuffleEnabled;
    const toggleShuffle = useEpisodeUpNextAction('toggleShuffle');

    const timeRemainingInSeconds = Object.values(episodeSync).reduce(
        (sum, sync) => sum + (sync.duration || 0) - (sync.playedUpTo || 0),
        0,
    ) as number;
    const timeRemainingString = DurationStringFromSeconds({
        durationSecsStrOrNum: timeRemainingInSeconds,
        short: true,
    });

    const clearUpNext = useEpisodeUpNextAction('clearUpNext');
    const showModal = useModalAction('showModal');
    const tracks = useTracks();

    const onShuffleClick = () => {
        toggleShuffle();
        tracks.recordEvent('up_next_shuffle_enabled', { value: !isShuffled });
    };

    const handleClearUpNextClick = () => {
        if (uuids.length > 2) {
            showModal(ModalTypes.confirmClearUpNext);
        } else {
            clearUpNext(uuids);
        }
    };

    const LegacyToolbar = (
        <>
            <EpisodeCount count={uuids.length} />
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <FormattedMessage id="total-time-remaining" values={{ time: timeRemainingString }} />
            <button onClick={handleClearUpNextClick}>
                <FormattedMessage id="up-next-clear" />
            </button>
        </>
    );
    const UpdatedToolbar = (
        <>
            <EpisodeCount count={uuids.length} />
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <FormattedMessage id="total-time-remaining" values={{ time: timeRemainingString }} />
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
            <ClearButton onClick={handleClearUpNextClick}>
                <FormattedMessage id="up-next-clear" />
            </ClearButton>
            <FeatureLock source="up_next_shuffle" requires="subscription">
                <ShuffleButton $active={isShuffled} onClick={onShuffleClick} aria-label="Shuffle">
                    <Icon id="shuffle" size={28} />
                    <ShuffleCircleIcon $active={isShuffled}>
                        <Icon id="shuffle-circle" size={12} />
                    </ShuffleCircleIcon>
                </ShuffleButton>
            </FeatureLock>
        </>
    );

    return uuids.length > 0 ? <>{USE_UP_NEXT_SHUFFLE ? UpdatedToolbar : LegacyToolbar}</> : null;
}
