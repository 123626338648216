import { useEffect } from 'react';
import { NavigationItems } from '../helper/NavigationHelper';
import { Podcast } from '../model/types';
import * as fromPodcastActions from '../redux/actions/podcast.actions';
import {
    getColorsByUuid,
    getPodcastByUuid,
    getPodcastLoadFailed,
} from '../redux/reducers/selectors';
import { useDispatch, useSelector } from './react-redux-typed';

const usePodcastDetails = (podcastUuid: string) => {
    const dispatch = useDispatch();
    const podcast = useSelector<Podcast | undefined>(state => getPodcastByUuid(state, podcastUuid));
    const loadFailed = useSelector(getPodcastLoadFailed);
    const colors = useSelector(state => getColorsByUuid(state, podcastUuid));

    useEffect(() => {
        dispatch(fromPodcastActions.Actions.openPodcast(podcastUuid));
    }, [dispatch, podcastUuid]);

    const podcastUrl = `${NavigationItems.PODCASTS.path}/${podcast?.uuid}`;

    return {
        podcast,
        loadFailed,
        isLoading: !podcast && !loadFailed,
        podcastUrl,
        colors,
    };
};

export default usePodcastDetails;
