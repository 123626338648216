import '@babel/polyfill';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { getTranslations } from 'translations';
import localStorageMiddleware from './redux/local-storage.middleware';
import { registeredMiddleware } from './redux/middleware';
import { rootReducer } from './redux/reducers';
import sagas from './redux/sagas/index';
import { locale } from './util/locale';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [localStorageMiddleware, sagaMiddleware, ...registeredMiddleware];

const initialState = {
    intl: {
        locale,
        messages: getTranslations(locale),
    },
};

export const store = createStore(
    rootReducer,
    initialState as any,
    composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;
